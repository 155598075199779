import React from 'react';
import { useSelector } from 'react-redux';
import RRU from 'react-redux-utils';
import { useTranslation } from 'react-i18next';
import { Button, Intent } from '@blueprintjs/core';

import * as config from 'app/config';
import * as utils from 'app/utils';
import Page from 'app/widgets/Page';
import SelectableGrid from 'app/widgets/Grid/SelectableGrid';
import FixingIREditModal from './FixingIREditModal';
import InputFilter from 'app/widgets/Filters/InputFilter';
import LocalizedDateFilter from 'app/widgets/Filters/LocalizedDateFilter';
import ComboFilter from 'app/widgets/Filters/ComboFilter';
import {
  AgencyFilter,
  ContragentFilter,
  TradeNetworkFilter,
  TradepointProjectFilter,
  BranchFilter,
} from 'app/widgets/Filters';
import { BINDING_IR } from 'app/api';
import { exportData, moveToArchive } from 'app/state/actionCreators/fixingIR';
import { dispatch } from 'app/state';
import * as selectors from 'app/state/reducers';
import { toLocaleDateString } from 'app/utils';
import store from 'app/state';
import * as grids from 'app/state/actionCreators/grids';

export default React.memo(() => {
  const { t } = useTranslation();
  const [showDialog, toggleShowDialog] = utils.useToggle();
  const showAgencyFilter = RRU.useSelector(selectors.canViewAgencies);
  const { filters, selected } = useSelector((state) => state.grids[BINDING_IR] ?? grids.INITIAL_STATE);

  const disabledDelete = !filters || !!filters.contragent_id__eq || !selected.length;

  const COMBO_FILTER_ITEMS =
    Object.entries(config.BINDING_IR_COMBO_ITEMS).map(([value, title]) => ({ value, title: t(title) }));

  const OVERHEAD_BACKWALL_ITEMS =
    Object.entries(config.OVERHEAD_BACKWALL_SHOW_TYPES).map(([value, title]) => ({ value, title: t(title) }));

  const onClickDownload = () => {
    dispatch(exportData(filters));
  }

  const onDelete = () => {
    dispatch(moveToArchive(selected));
  }

  const onCloseEditDialog = (changed) => {
    if (changed) {
      store.dispatch(grids.fetch(BINDING_IR));
    }
    toggleShowDialog();
  }

  const needestRender = (value) => t(config.BINDING_IR_COMBO_ITEMS[value]);
  const overheadBackwallRender = (value) => t(config.OVERHEAD_BACKWALL_SHOW_TYPES[value]);

  return (
    <Page title={t('fixing ir')}>
      <SelectableGrid
        path={BINDING_IR}
      >
        {showAgencyFilter && (
          <SelectableGrid.Filter
            acceptor={AgencyFilter}
            path="agency_id__eq"
            transient
            multi={false}
            autoSelectFirst
          />
        )}
        <SelectableGrid.Filter
          acceptor={ContragentFilter}
          multi={false}
          dependsOn="agency_id__eq"
          path="contragent_id__eq"
          autoSelectFirst
        />
        <SelectableGrid.Filter acceptor={TradeNetworkFilter} path="network_id__in" dependsOn="contragent_id__eq" />
        <SelectableGrid.Filter acceptor={BranchFilter} path="branch_id__in" dependsOn="contragent_id__eq" />
        <SelectableGrid.Filter acceptor={TradepointProjectFilter} path="project_id__in" dependsOn="contragent_id__eq" />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="tradepoint_code__ilike"
          name={t('point code')}
          placeholder={t('search by point code')}
        />
        <SelectableGrid.Filter
          acceptor={LocalizedDateFilter}
          path="date_from__ge"
          name={t('date from second')}
          defaultValue={new Date()}
        />
        <SelectableGrid.Filter
          acceptor={LocalizedDateFilter}
          path="date_to__le"
          name={t('date by')}
          defaultValue={new Date()}
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="ir_code__ilike"
          name={t('code for IR')}
          placeholder={t('search by code for IR')}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="photo_before__eq"
          emptiable
          name={t('photo before')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="photo_after__eq"
          emptiable
          name={t('photo after')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="questionary_check__eq"
          emptiable
          name={t('questionnaire')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="checkout_check__eq"
          emptiable
          name={t('checkout before')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="checkout_after_check__eq"
          emptiable
          name={t('checkoutAfter')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="planogram_check__eq"
          emptiable
          name={t('planogramsBefore')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="planogram_after_check__eq"
          emptiable
          name={t('planogramsAfter')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="backwall_check__eq"
          emptiable
          name={t('backwall')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="overhead_check__eq"
          emptiable
          name={t('overhead')}
          items={COMBO_FILTER_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="overhead_backwall_show_as__eq"
          emptiable
          name={t('overhead pos')}
          items={OVERHEAD_BACKWALL_ITEMS}
        />
        <SelectableGrid.Filter
          acceptor={ComboFilter}
          path="inspector_cloud__eq"
          emptiable
          name={t('ic')}
          items={COMBO_FILTER_ITEMS}
        />

        <SelectableGrid.Actions>
          <Button
            onClick={onClickDownload}
            minimal
            icon="download"
            text={t('download')}
            intent={Intent.SUCCESS}
          />
          <Button
            icon="add"
            text={t('create fixed ir')}
            intent={Intent.SUCCESS}
            onClick={toggleShowDialog}
          />
          <Button
            intent={Intent.DANGER}
            icon="delete"
            disabled={!disabledDelete}
            onClick={onDelete}
            minimal
          >
            {t('delete')}
          </Button>
        </SelectableGrid.Actions>

        <SelectableGrid.Column path="contragent" title={t('contractor')} sortable />
        <SelectableGrid.Column path="tradepoint_code" title={t('code tt')} sortable />
        <SelectableGrid.Column path="address" title={t('address')} sortable />
        <SelectableGrid.Column path="branch" title={t('affiliate')} sortable />
        <SelectableGrid.Column path="city" title={t('city')} sortable />
        <SelectableGrid.Column path="network" title={t('network')} sortable />
        <SelectableGrid.Column path="ir_code" title={t('code for IR')} sortable />
        <SelectableGrid.Column
          path="active_from_date"
          title={t('date from second')}
          sortable
          valueRenderer={toLocaleDateString}
        />
        <SelectableGrid.Column path="active_to_date" title={t('date by')} sortable valueRenderer={toLocaleDateString} />
        <SelectableGrid.Column
          path="photo_before"
          title={t('photo before')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="photo_after"
          title={t('photo after')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="questionary_check"
          title={t('questionnaire')}
          sortable
          valueRenderer={needestRender}
        />

        <SelectableGrid.Column
          path="backwall_check"
          title={t('backwall')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="overhead_check"
          title={t('overhead')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="overhead_backwall_show_as"
          title={t('overhead pos')}
          sortable
          valueRenderer={overheadBackwallRender}
        />
        <SelectableGrid.Column
          path="checkout_check"
          title={t('checkout before')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="checkout_after_check"
          title={t('checkoutAfter')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="planogram_check"
          title={t('planogramBefore')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="planogram_after_check"
          title={t('planogramAfter')}
          sortable
          valueRenderer={needestRender}
        />
        <SelectableGrid.Column
          path="inspector_cloud"
          title={t('ic')}
          sortable
          valueRenderer={needestRender}
        />
      </SelectableGrid>

      {showDialog && <FixingIREditModal onClose={onCloseEditDialog} />}
    </Page>
  )
});
