import React from 'react';
import { Link } from 'react-router-dom';
import { Colors } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import {
  AgencyContragentFilter,
  TradeNetworkFilter,
  UserTaskIdFilter,
  TradepointProjectFilter,
  BranchFilter,
  UserFilter,
  BoolFilter,
} from 'app/widgets/Filters';
import { USER_TASK_CHECKS } from 'app/api';
import { toLocaleString, toLocaleDateString } from 'app/utils';

function renderStatus(checked, rowData, t = (s) => s) {
  return (
    <Link
      to={`/tasks/${rowData.id}/check`}
      style={{
        color: rowData.last_time ? Colors.GREEN1 : Colors.RED1,
        textDecoration: 'underline',
      }}
    >
      {checked ? t('checked') : t('not checked')}
    </Link>
  );
}

export default React.memo(withTranslation()(({ t }) => (
  <Page title={t('visit check')}>
    <Grid path={USER_TASK_CHECKS}>
      <Grid.Filter
        acceptor={AgencyContragentFilter}
        path="contragent_id__eq"
        multi={false}
        autoSelectFirst
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="code__ilike"
        name={t('point code')}
        placeholder={t('search by point code')}
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="address__ilike"
        name={t('address')}
        placeholder={t('search by address')}
      />
      <Grid.Filter
        acceptor={BranchFilter}
        path="branch_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={TradeNetworkFilter}
        path="network_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={TradepointProjectFilter}
        path="project_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={UserFilter}
        path="user_id__in"
        dependsOn="contragent_id__eq"
        name={t('executor')}
        placeholder={t('search by executor')}
        filters={{ roles__contains: ['merch'] }}
      />
      <Grid.Filter
        acceptor={UserFilter}
        path="check_manager_id__in"
        dependsOn="contragent_id__eq"
        name={t('checker')}
        placeholder={t('search by checker')}
        filters={{ roles__overlap: ['admin', 'agency_admin', 'check_manager', 'chief_of_check_manager'] }}
      />
      <Grid.Filter
        acceptor={UserTaskIdFilter}
        path="id__eq"
      />
      <Grid.Filter
        acceptor={DaterangeFilter}
        path="date__between"
        name={t('date of visit')}
        shortcuts="weekToWeek"
        defaultValue={[new Date(), new Date()]}
      />
      <Grid.Filter
        acceptor={DaterangeFilter}
        path="last_time__between"
        name={t('check date')}
        shortcuts="weekToWeek"
        withTime
      />
      <Grid.Filter
        acceptor={BoolFilter}
        path="checked__eq"
        name={t('status')}
        trueTitle={t('checked')}
        falseTitle={t('not checked')}
      />

      <Grid.Column path="contragent" title={t('supplier')} sortable />
      <Grid.Column path="id" title={t('lowercase visit id')} />
      <Grid.Column path="date" title={t('date of visit')} valueRenderer={toLocaleDateString} />
      <Grid.Column path="code" title={t('tt code')} sortable />
      <Grid.Column path="address" title={t('address')} sortable />
      <Grid.Column path="trade_network" title={t('commercial network')} sortable />
      <Grid.Column path="branch" title={t('affiliate')} sortable />
      <Grid.Column path="tradepoint_project" title={t('extra project')} sortable />
      <Grid.Column path="manager_fullname" title={t('head of visitor')} sortable />
      <Grid.Column path="check_manager_fullname" title={t('checker')} sortable />
      <Grid.Column path="last_time" title={t('check date')} sortable valueRenderer={toLocaleString} />
      <Grid.Column path="checked" title={t('status')} valueRenderer={(c, r) => renderStatus(c, r, t)} />
    </Grid>
  </Page>
)));
