import * as teamUser from './teamUser';
import * as questionaryTradepointRefs from './questionaryTradepointRefs';
import * as questionary from './questionary';
import * as importTasks from './importTasks';
import * as productProblems from './productProblems';
import * as questionaryCategories from './questionaryCategories';
import * as rescheduleReasons from './rescheduleReasons';
import * as grids from './grids';
import * as user from './user';
import * as planogramTradepointRefs from './planogramTradepointRefs';

const DIRECT_ACTIONS = {
  teamUser,
  questionaryTradepointRefs,
  questionary,
  importTasks,
  productProblems,
  grids,
  user,
  planogramTradepointRefs,
  questionaryCategories,
  rescheduleReasons,
};

export default DIRECT_ACTIONS;
