import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Intent } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import SelectableGrid from 'app/widgets/Grid/SelectableGrid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import { AgencyContragentFilter } from 'app/widgets/Filters';
import * as api from 'app/api';
import * as grids from 'app/state/actionCreators/grids';
import { exportData, removePlanograms } from 'app/state/actionCreators/planogram';
import store from 'app/state';

function renderName(name, rowData) {
  return (
    <Link to={`/planograms/${rowData.id}`}>
      {name}
    </Link>
  );
}

function onDelete() {
  const { selected } = store.getState().grids[api.PLANOGRAMS];
  store.dispatch(removePlanograms(selected));
}

export default React.memo(() => {
  const { t } = useTranslation();
  const { filters, selected } = useSelector((state) => state.grids[api.PLANOGRAMS] ?? grids.INITIAL_STATE);

  const downloadData = () => {
    store.dispatch(exportData(filters));
  }

  return (
    <Page title={t('planograms')}>
      <SelectableGrid path={api.PLANOGRAMS}>
        <SelectableGrid.Filter
          acceptor={AgencyContragentFilter}
          path="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="name__ilike"
          name={t('name')}
          placeholder={t('search by name')}
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="text__ilike"
          name={t('description')}
          placeholder={t('search by text')}
        />

        <SelectableGrid.Actions>
          <Link to="/planograms/new">
            <Button intent={Intent.SUCCESS} icon="add">
              {t('create')}
            </Button>
          </Link>
          <Button
            intent={Intent.SUCCESS}
            icon="download"
            onClick={downloadData}
            minimal
          >
            {t('download')}
          </Button>
          <Button
            intent={Intent.DANGER}
            icon="delete"
            disabled={!selected.length}
            onClick={onDelete}
            minimal
          >
            {t('delete')}
          </Button>
        </SelectableGrid.Actions>

        <SelectableGrid.Column path="name" title={t('name')} sortable valueRenderer={renderName} />
        <SelectableGrid.Column path="contragent" title={t('contractor')} sortable />
        <SelectableGrid.Column path="type" title={t('type')} sortable />
        <SelectableGrid.Column path="text" title={t('description')} />
      </SelectableGrid>
    </Page>
  )
});
