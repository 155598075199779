import { Intent } from '@blueprintjs/core';
import _ from 'lodash';

import { t } from 'i18n';
import * as api from 'app/api';
import * as utils from 'app/utils';
import alerts from 'app/widgets/alerts';
import { dispatch, getState } from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import * as schedule from 'app/state/actionCreators/schedule';
import * as selectors from 'app/state/reducers';

const NUM_STATIC_COLUMNS = 14;

function canEditShedule() {
  return selectors.canEditShedule(getState());
}

function canRestoredTasksEditor() {
  return selectors.canRestoredTasksEditor(getState());
}

export function scheduleDates(state) {
  const date__between = state.grids[api.SCHEDULE]?.filters.date__between;
  return utils.dateRange(date__between?.[0], date__between?.[1]);
}

function getUser(item) {
  return _.filter([
    item.user,
    item.user_phone && `(${item.user_phone})`,
  ]).join(' ');
}

async function deleteAllNewTasks(item) {
  const taskIds = _.values(item.tasks).filter(t => t.status === 'new').map(t => t.id);
  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deleteTasks(taskIds),
    cancelButtonText: t('cancel'),
    confirmButtonText: t('delete'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: [
      t('delete user all new visits'),
      getUser(item),
      t('by address'),
      item.address,
    ].join(' ') + '?',
  });
  if (confirmed && !error) {
    dispatch(grids.fetch(api.SCHEDULE));
  }
}

function getRowContextMenu(row) {
  if (!canEditShedule()) {
    return null;
  }

  const item = getState().grids[api.SCHEDULE].data[row];
  return [{
    text: t('delete user all tt visits'),
    icon: 'trash',
    onClick: () => deleteAllNewTasks(item),
  }];
}

function getEmptyDateContextMenu(item, date) {
  if (!canRestoredTasksEditor()) {
    return null;
  }

  return [{
    text: t('create filled visit'),
    icon: 'add',
    onClick: () => utils.history.push('/history/create', {
      fromSchedule: true,
      contragentId: item.contragent_id,
      userId: item.user_id,
      tradepointId: item.tradepoint_id,
      date,
    }),
  }];
}

async function resetTask(item, task) {
  const description = [
    t('usersVisit'),
    getUser(item),
    t('by address'),
    item.address,
    t('on'),
    utils.toLocaleDateString(task.date)
  ].join(' ');

  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.resetTask(task.id),
    confirmButtonText: t('confirm'),
    intent: Intent.PRIMARY,
    icon: 'reset',
    content: (
      <>
        <p>{description}.</p>
        <p>{t('visitWillBeReset')}</p>
        <p><strong>{t('haveToRedownloadOnMobile')}</strong></p>
      </>
    ),
  });
  if (confirmed && !error) {
    dispatch(grids.setScheduleTaskStatus(task.id, 'new'));
  }
}

async function deleteTask(item, task) {
  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deleteTasks([task.id]),
    confirmButtonText: t('delete'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: [
      t('delete'),
      t(task.task_type === 'merchandising' ? 'visitAccusative' : 'auditAccusative', { count: 1 }),
      t('user s'),
      getUser(item),
      t('by address'),
      item.address,
      t('on'),
      `${utils.toLocaleDateString(task.date)}?`,
    ].join(' '),
  });
  if (!confirmed || error) {
    return;
  }

  dispatch(grids.deleteScheduleTask(task.id));
}

async function deleteTasks(tasks) {
  if (tasks.length === 1) {
    return deleteTask(tasks[0], tasks[0]);
  }

  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deleteTasks(tasks.map(t => t.id)),
    confirmButtonText: t('delete'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: [
      t('delete'),
      tasks.length,
      t(
        tasks[0].task_type === 'merchandising' ? 'visitAccusative' : 'auditAccusative',
        { count: tasks.length },
      ),
    ].join(' ') + '?',
  });
  if (confirmed && !error) {
    dispatch(grids.fetch(api.SCHEDULE));
  }
}

function getStartedTaskContextMenu(item, task) {
  const canReset = selectors.canResetStartedVisit(getState());
  if (!canReset) {
    return null;
  }

  const canComplete = selectors.canCompleteStartedVisit(getState());
  return _.filter([
    canComplete && task.status === 'INWORK' && {
      text: `${t('convert to status')} ${t('done')}`,
      icon: 'saved',
      onClick: () => dispatch(schedule.openCompleteVisitDialog(task)),
      tooltip: t('visit in work alert'),
    },
    task.status === 'COMPLETED' && {
      text: t('view report'),
      icon: 'info-sign',
      onClick: () => utils.history.push(`/tasks/${task.id}/result`),
    },
    {
      text: `${t('convert to status')} ${t('a new')}`,
      icon: 'reset',
      onClick: () => resetTask(item, task),
      tooltip: task.status === 'INWORK' ? t('visit in work alert') : undefined,
    },
    {
      text: t('delete'),
      icon: 'trash',
      onClick: () => deleteTask(item, task),
      tooltip: task.status === 'INWORK' ? t('visit in work alert') : undefined,
    },
  ]);
}

function getNewTaskContextMenu(item, task) {
  if (!canEditShedule()) {
    return null;
  }

  return _.filter([
    task.task_type === 'merchandising' && {
      text: t('edit'),
      icon: 'edit',
      onClick: () => schedule.openEditDialog([{ ...item, ...task }]),
    },
    {
      text: t('delete'),
      icon: 'trash',
      onClick: () => deleteTask(item, task),
    },
  ]);
}

function getTaskInfo(items, dates, row, column) {
  const item = items[row];
  if (column < NUM_STATIC_COLUMNS) {
    return { item };
  }

  const date = dates[column - NUM_STATIC_COLUMNS];
  const isoDate = utils.ISODate(date);
  return { item, date, task: item.tasks[isoDate] };
}

function getDateContextMenu(row, column) {
  const { item, date, task } = getTaskInfo(
    getState().grids[api.SCHEDULE].data,
    scheduleDates(getState()),
    row,
    column,
  );
  if (!task) {
    return getEmptyDateContextMenu(item, date);
  }

  if (task.status !== 'new') {
    return getStartedTaskContextMenu(item, task);
  }

  return getNewTaskContextMenu(item, task);
}

function getMultipleCellsContextMenu(regions) {
  if (!canEditShedule()) {
    return null;
  }
  const canReset = selectors.canResetStartedVisit(getState());

  const items = getState().grids[api.SCHEDULE].data;
  const dates = scheduleDates(getState());
  const allRows = _.range(items.length);
  const allColumns = _.range(NUM_STATIC_COLUMNS, NUM_STATIC_COLUMNS + dates.length);
  const toEditTasks = [];
  const toDeleteTasks = [];
  for (const r of regions) {
    const rows = r.rows ? _.range(r.rows[0], r.rows[1] + 1) : allRows;
    const columns = r.cols ? _.range(r.cols[0], r.cols[1] + 1) : allColumns;
    for (const row of rows) {
      for (const column of columns) {
        const { task, item } = getTaskInfo(items, dates, row, column);
        if (task?.status === 'new' && task.task_type === 'merchandising') {
          toEditTasks.push({ ...item, ...task });
        }

        if (task && (canReset || task.status === 'new')) {
          toDeleteTasks.push({ ...item, ...task });
        }
      }
    }
  }

  const contextMenu = _.filter([
    _.uniq(toEditTasks.map(t => t.contragent_id)).length === 1 && {
      text: [
        t('edit'),
        toEditTasks.length,
        t('visitAccusative', { count: toEditTasks.length }),
      ].join(' '),
      icon: 'edit',
      onClick: () => schedule.openEditDialog(toEditTasks),
    },
    toDeleteTasks.length && {
      text: [
        t('delete'),
        toDeleteTasks.length,
        t(
          toDeleteTasks[0].task_type === 'audit' ? 'auditAccusative' : 'visitAccusative',
          { count: toDeleteTasks.length },
        ),
      ].join(' '),
      icon: 'trash',
      onClick: () => deleteTasks(toDeleteTasks),
    },
  ]);

  return contextMenu.length ? contextMenu : null;
}

export function getContextMenu(context) {
  const regions = context.getRegions();
  const target = context.getTarget();

  const singleRow = regions.length === 1 && !!regions[0].rows && regions[0].rows[0] === regions[0].rows[1];
  const singleCell = singleRow && !!regions[0].cols && regions[0].cols[0] === regions[0].cols[1];

  // Выделение по 1 строке
  // нажали на колонку левее графика
  if (singleRow && target.cols[0] < NUM_STATIC_COLUMNS) {
    return getRowContextMenu(target.rows[0]);
  }

  // Выделение по 1 ячейке в графике
  if (singleCell) {
    return getDateContextMenu(target.rows[0], target.cols[0]);
  }

  return getMultipleCellsContextMenu(regions);
}
