import React from 'react';
import { Intent } from '@blueprintjs/core';
import _ from 'lodash';

import i18n from 'i18n';
import * as api from 'app/api';
import { toLocaleDateString } from 'app/utils';
import { dispatch } from 'app/state';
import * as schedule from 'app/state/actionCreators/schedule';
import * as grids from 'app/state/actionCreators/grids';
import alerts from 'app/widgets/alerts';
import { showError } from 'app/widgets/toaster';

export function handleCloseDialog(dialogKey, created) {
  dispatch(schedule.closeDialog(dialogKey));
  if (created) {
    dispatch(grids.fetch(api.SCHEDULE));
  }
}

const EXCEPTION_RENDERS = {
  OverlappingTasksError: tasks => (
    <>
      <div>{i18n.t('thereAreVisitForSelectedDates')}:</div>
      <ul>
        {tasks.map(t => (
          <li key={t.id}>
            {[`№ ${t.id}`, toLocaleDateString(t.date), t.tradepoint_code, t.user].join(' — ')}
          </li>
        ))}
      </ul>
      <div>{i18n.t('removeVisitsAndContinueQuestion')}</div>
    </>
  ),
  OverlappingTradepointIntervalError: tasks => (
    <>
      <div>{i18n.t('datesOverlapTradepointIntervals')}:</div>
      <ul>
        {tasks.map((t) => (
          <li key={t.id}>
            {[
              '№',
                t.id,
                `(${toLocaleDateString(t.date)},`,
                `${t.tradepoint_code},`,
                `${t.user})`,
                '—',
                toLocaleDateString(t.next_date),
            ].join(' ')}
          </li>
        ))}
      </ul>
    </>
  ),
};

export async function handleSaveWithConflicts(action, dialogKey) {
  let error;
  try {
    await dispatch(action(false));
    handleCloseDialog(dialogKey, true);
    return;
  } catch (e) {
    if (!_.has(EXCEPTION_RENDERS, e.type)) {
      showError(e.message);
      return;
    }
    error = e;
  }

  const confirmed = await alerts.show({
    confirmButtonText: i18n.t('continue'),
    intent: Intent.DANGER,
    content: EXCEPTION_RENDERS[error.type](error.detail),
  });

  if (!confirmed) {
    return;
  }

  try {
    await dispatch(action(true));
    handleCloseDialog(dialogKey, true);
  } catch (e) {
    showError(e.message);
  }
}
