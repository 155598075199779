import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Intent, Switch } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import store from 'app/state';
import * as api from 'app/api';
import * as grids from 'app/state/actionCreators/grids';
import * as utils from 'app/utils';
import * as config from 'app/config';
import * as toaster from 'app/widgets/toaster';
import alerts from 'app/widgets/alerts';
import Page from 'app/widgets/Page';
import SelectableGrid from 'app/widgets/Grid/SelectableGrid';
import * as Filters from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';

function renderCode(code, rowData) {
  return (
    <Link to={`/questionary/${rowData.id}/edit`}>
      {code}
    </Link>
  );
}

const renderType = (type, rowData) => i18n.t(config.QUEST_TYPES[type]);

const renderVisibility = (visibility, rowData) => i18n.t(config.VISIBILITY_RESTRICTIONS[visibility]);

const renderMandatoryType = (type, rowData) => i18n.t(config.MANDATORY_PERIOD_TYPE_ITEMS[type]);

async function exportQuestionaries(setExporting) {
  const { filters, selected } = store.getState().grids[api.QUESTIONARIES];
  setExporting(true);
  try {
    await api.exportQuestionaries({ ...filters, id__in: selected.length ? selected : undefined });
  } catch (e) {
    toaster.showError(e.message || i18n.t('error'));
  } finally {
    setExporting(false);
  }
}

async function exportWithQuestions(setExporting) {
  const { filters, selected } = store.getState().grids[api.QUESTIONARIES];
  setExporting(true);
  try {
    await api.exportQuestionariesWithQuestions({
      ...filters,
      questionary_id__in: selected.length ? selected : undefined,
    });
  } catch (e) {
    toaster.showError(e.message || i18n.t('error'));
  } finally {
    setExporting(false);
  }
}

async function onClickDelete() {
  const { filters, selected } = store.getState().grids[api.QUESTIONARIES];

  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deactivateQuestionaries({ filter: { ...filters, id__in: selected } }),
    cancelButtonText: i18n.t('no'),
    confirmButtonText: i18n.t('yes'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: i18n.t('sure to delete questionaries'),
  });
  if (confirmed && !error) {
    store.dispatch(grids.fetch(api.QUESTIONARIES));
  }
}

export default React.memo(() => {
  const { t } = useTranslation();
  const [exporting, setExporting] = React.useState(false);
  const hasSelected = !!RRU.useSelector(`grids.${api.QUESTIONARIES}.selected.length`);

  const onClickExport = RRU.useCallbackArgs(exportQuestionaries, setExporting);
  const onClickExportWithQuestions = RRU.useCallbackArgs(exportWithQuestions, setExporting);

  return (
    <Page wide title={t('questionnaires')}>
      <SelectableGrid path={api.QUESTIONARIES} selectTitle={t('selectForRemoval')}>
        <SelectableGrid.Filter
          acceptor={Filters.AgencyContragentFilter}
          path="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={Filters.QuestionaryCategoryFilter}
          path="category_id__in"
          dependsOn="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="name__fuzzy_like"
          icon="search"
          name={t('name of questionnaire')}
          placeholder={t('search by name of questionnaire')}
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="code__ilike"
          icon="search"
          name={t('id of questionnaire')}
          placeholder={t('search by id of questionnaire')}
        />
        <SelectableGrid.Filter
          acceptor={Switch}
          path="has_mandatory_period"
          label={t('with a mandatory period')}
        />

        <SelectableGrid.Actions>
          <Link to="/questionary/new">
            <Button intent={Intent.PRIMARY} icon="add">
              {t('add questionnaire')}
            </Button>
          </Link>
          <Button
            intent={Intent.SUCCESS}
            icon="download"
            onClick={onClickExport}
            minimal
            disabled={exporting}
          >
            {t('download')}
          </Button>
          <Button
            intent={Intent.SUCCESS}
            icon="download"
            onClick={onClickExportWithQuestions}
            minimal
            disabled={exporting}
          >
            {t('download with questions')}
          </Button>
          <Button
            intent={Intent.DANGER}
            icon="delete"
            disabled={!hasSelected}
            onClick={onClickDelete}
            minimal
          >
            {t('delete')}
          </Button>
        </SelectableGrid.Actions>

        <SelectableGrid.Column
          path="contragent"
          title={t('contractor')}
          sortable
        />
        <SelectableGrid.Column
          path="name"
          title={t('questionnaire name')}
          sortable
        />
        <SelectableGrid.Column
          path="code"
          title={t('id of questionnaire')}
          sortable valueRenderer={renderCode}
        />
        <SelectableGrid.Column
          path="type"
          title={t('questionnaire type')}
          sortable
          valueRenderer={renderType}
        />
        <SelectableGrid.Column
          path="visibility_restriction"
          title={t('visibility')}
          sortable
          valueRenderer={renderVisibility}
        />
        <SelectableGrid.Column
          path="category"
          title={t('category')}
          sortable
        />
        <SelectableGrid.Column
          path="mandatory_period_start_date"
          title={t('date of start')}
          sortable
          valueRenderer={utils.toLocaleDateString}
        />
        <SelectableGrid.Column
          path="mandatory_period_days"
          title={t('days count')}
          sortable
        />
        <SelectableGrid.Column
          path="mandatory_period_count"
          title={t('visit count')}
          sortable
        />
        <SelectableGrid.Column
          path="mandatory_period_type"
          title={t('display type')}
          sortable
          valueRenderer={renderMandatoryType}
        />
      </SelectableGrid>
    </Page>
  )
});
