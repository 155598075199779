import { combineReducers } from 'redux';
import task from './task';
import questions from './questions';
import save from './save';
import fetch from './fetch';

export default combineReducers({
  task,
  questions,
  save,
  fetch,
});
