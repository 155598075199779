import _ from 'lodash';
import { ROLES_WEIGHT } from 'app/config/rolesHierarchy';

import * as ACTIONS from 'app/state/actions';

export const INITIAL_STATE = {
  fetching: true,
  error: null,
  user: null,

  loggedIn: false,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.USER_FETCH_START:
      return _.assign(state, { fetching: true });

    case ACTIONS.USER_FETCH_DONE:
      return _.assign(state, {
        fetching: false, error: null, user: action.user, loggedIn: true,
      });

    case ACTIONS.USER_FETCH_ERROR:
      return _.assign(state, { fetching: false, error: action.error });

    case ACTIONS.LOGIN:
      return _.assign(state, { loggedIn: true });

    case ACTIONS.LOGOUT_DONE:
      return {
        ...INITIAL_STATE,
        loggedIn: false,
        fetching: false,
      };

    default:
      return state;
  }
};

export const needHideVisitUsers = (state) => (
  // пользователю нужно скрыть исполнителя и руководителя в ленте
  !!state.user?.rights.includes('webapp_forbid_view_task_user')
);

export const needHideVisitMerch = (state) => (
  // пользователю нужно в ленте показать только начальника
  // была смена логики. Раньше при наличие этого флага в ленте вместо
  // исполнителя показывался руководитель, теперь показывается только
  // руководитель. В остальных местах по-старому
  // TODO refactor?
  !!state.user?.rights.includes('webapp_show_parent_view_task_user')
);

export const hasRole = (state, ...roleCodes) => !!state.user?.roles.some((r) => roleCodes.includes(r));

export const isAdmin = (state) => hasRole(state, 'admin');

export const isAgencyAdmin = (state) => hasRole(state, 'agency_admin');

export const isAdminOrAgencyAdmin = (state) => isAdmin(state) || isAgencyAdmin(state);

export const isContragent = (state) => hasRole(state, 'contragent');

export const isAgencyContragent = (state) => hasRole(state, 'agency_contragent');

export const hasRightCreateAudit = (state) => !!(
  state.user
  && state.user.roles
  && state.user.roles.some((role) => ROLES_WEIGHT[role] >= ROLES_WEIGHT.supervisor)
);

export const hasRight = (state, right) => !!(
  state.user
  && state.user.rights
  && state.user.rights.indexOf(right) !== -1
);

export const canRestoredTasksEditor = (state) => hasRight(state, 'webapp_restored_tasks_editor');
export const canRestoredTasksMark = (state) => hasRight(state, 'webapp_restored_tasks_mark');
export const canViewFakeGPS = (state) => hasRight(state, 'webapp_tasks_fake_gps_view');
export const canViewFakePhoto = (state) => hasRight(state, 'webapp_tasks_fake_photos_view');
export const canSetPassword = (state) => hasRight(state, 'webapp_users_set_password');
export const canEditShedule = (state) => hasRight(state, 'webapp_schedule_edit');
export const canResetStartedVisit = (state) => hasRight(state, 'webapp_can_reset_started_visit');
export const canCompleteStartedVisit = (state) => hasRight(state, 'webapp_can_complete_started_visit');
export const canCreateAudit = (state) => hasRightCreateAudit(state);
export const canEditProductProblem = (state) => hasRight(state, 'webapp_product_problems_edit');
export const canEditProduct = (state) => hasRight(state, 'webapp_products_edit');
export const canEditProductBrands = (state) => hasRight(state, 'webapp_product_brands_edit');
export const canEditProductCategories = (state) => hasRight(state, 'webapp_product_categories_edit');
export const canEditQuestionaryCategories = (state) => hasRight(state, 'webapp_questionary_categories_edit');
export const canEditRescheduleReasons = (state) => hasRight(state, 'webapp_reschedule_reasons_edit');
export const canEditBranches = (state) => hasRight(state, 'webapp_branches_edit');
export const canEditTradepointProjects = (state) => hasRight(state, 'webapp_tradepoint_projects_edit');
export const canEditTradepointTerritories = (state) => hasRight(state, 'webapp_tradepoint_territories_edit');
export const canEditTradepointFormats = (state) => hasRight(state, 'webapp_tradepoint_formats_edit');

export const canViewAgencies = (state) => !!state.user.user && isAdmin(state.user);

export const canImportQustionaries = (state) => {
  // todo within contragent settings!!!
  const CONTRAGENTS_ID = [84];
  let canImport = 0;
  const userRoles = state.user && state.user.roles;
  const userContragents = state.user && state.user.contragents;

  if (userRoles) {
    userRoles.forEach((role) => {
      if ((canImport === 0) && ROLES_WEIGHT[role] >= ROLES_WEIGHT.supervisor) {
        canImport += 1;
      }
    });
  }

  if (userContragents) {
    userContragents.forEach((contr) => {
      if (CONTRAGENTS_ID.includes(contr.contragent_id)) {
        canImport += 1;
      }
    });
  }

  return (canImport >= 2);
};

export const needHidePhotoExport = (state) => {
  const userContragent = state.user && state.user.contragents && state.user.contragents[0];
  const isRequiredContragent = userContragent && userContragent.contragent_id === 97;
  return isRequiredContragent ? isAgencyContragent(state) : false;
};

export const showCheckEquipment = (state) => {
  const userContragent = state.user && state.user.contragents && state.user.contragents[0];
  const isRequiredContragent = userContragent && userContragent.contragent_id === 28;
  const agencyAdminOpen = isAgencyAdmin(state) && state.user?.agencyId === 1;
  return isRequiredContragent || agencyAdminOpen || isAdmin(state);
};

export const canEditTeamUser = (state) => {
  if (!state.user.user || !state.user.user.rights || !state.teamUser.user) {
    return false;
  }
  // есть права на редактирование
  if (state.user.user.rights.indexOf('webapp_users_edit') === -1) {
    return false;
  }
  // админ может редактировать всех
  if (isAdmin(state.user) || isAgencyAdmin(state.user)) {
    return true;
  }

  // пользователь без начальника может редактировать себя
  if (!state.user.user.parentId) {
    return true;
  }

  // пользователь c начальником, может не может редактировать себя
  return state.user.user.id !== state.teamUser.user.id;
};

export const hasSetting = (state, setting) => !!(
  state.user?.contragents.some(c => !!c.settings[setting])
);

export const showCashboxFeedFilter = (state) => {
  const agencyAdminOpen = isAgencyAdmin(state) && state.user?.agencyId === 1;
  return (
    isAdmin(state)
    || agencyAdminOpen
    || hasSetting(state, 'contragent_count_cashboxes_in_tasks_result')
  );
};

export const hideFeedTaskType = (state) => (
  hasRole(state, 'contragent') && hasSetting(state, 'contragent_feed_task_type_restriction')
);
