import { useState } from 'react';
import _ from 'lodash';

import i18n from 'i18n';
import * as utils from 'app/utils';
import { dispatch } from 'app/state';
import * as questionary from 'app/state/actionCreators/questionary';
import FancySelect from 'app/widgets/FancySelect';
import Form from 'app/widgets/Form';
import { LocalizedISODateInput } from 'app/widgets/LocalizedDateInput';
import { NUMBER_TYPES_NAMES } from 'app/config/questionTypes';

function resetOnFieldTypeChange({ lastFieldType, fieldType, setLastFieldType }) {
  if (lastFieldType !== fieldType) {
    dispatch(questionary.resetEditQuestionDefaultAnswer());
    setLastFieldType(fieldType);
  }
}

// FIXME нельзя локализовывать, завязано много логики на конкретные значения
const BOOL_ITEMS = [
  { label: 'Да', id: true },
  { label: 'Нет', id: false },
];

function makeSelectItems({ variants, field_type }) {
  return field_type === 'BOOLEAN' ? BOOL_ITEMS : (variants ?? []).map((id) => ({ id, label: id }));
}

export default function useDefaultAnswerInput(question) {
  const [lastFieldType, setLastFieldType] = useState(question.field_type);
  utils.useEffectWithDeps(resetOnFieldTypeChange, { lastFieldType, fieldType: question.field_type, setLastFieldType });

  const selectItems = utils.useMemoWithDeps(makeSelectItems, _.pick(question, 'variants', 'field_type'));

  if (question.field_type === 'TEXT') {
    return {};
  }

  if (question.field_type === 'DATE') {
    return {
      acceptor: LocalizedISODateInput,
    };
  }

  if (['SELECT', 'MULTICOMBO', 'BOOLEAN'].includes(question.field_type)) {
    return {
      acceptor: FancySelect,
      multi: question.field_type === 'MULTICOMBO',
      data: selectItems,
    };
  }

  if (question.field_type in NUMBER_TYPES_NAMES) {
      return {
        acceptor: Form.NumberInput,
        placeholder: [i18n.t('enter'), i18n.t(NUMBER_TYPES_NAMES[question.field_type])].join(' '),
        min: question.min_value,
        max: question.max_value,
      };
  }

  return null;
};
