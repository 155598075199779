import React from 'react';
import { Spinner, H3, Card, Elevation } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import Page from 'app/widgets/Page';
import { showError } from 'app/widgets/toaster';
import * as api from 'app/api';
import * as utils from 'app/utils';
import store from 'app/state';

import styles from './OpenIDLoginScreen.module.css';

async function handleAuthResolved({ user, t }) {
  const redirectPage = utils.history.location.state?.from ?? '/feed';
  if (user) {
    utils.history.replace(redirectPage);
    return;
  }

  const searchParams = new URLSearchParams(window.location.search);
  if (!searchParams.get('code')) {
    utils.history.replace({ pathname: "/login", state: { from: redirectPage } });
    return;
  }

  try {
    const user = await api.loginOpenID(searchParams.get('code'));
    store.setIn('user', { user, loggedIn: true });
    utils.history.replace(redirectPage);
  } catch (e) {
    showError(e.message || t('authorizationError'));
    utils.history.replace({ pathname: "/login", state: { from: redirectPage } });
  }

}

export default
function OpenIDLoginScreen() {
  const { t } = useTranslation();
  const user = RRU.useSelector('user.user');
  RRU.useAsyncEffectOptions(handleAuthResolved, { user }, { t });

  return (
    <Page fullScreen>
      <Card className={styles.loginCard} elevation={Elevation.TWO}>
        <H3 className={styles.headerTitle}>{t('login to website')}</H3>

        <Spinner />
      </Card>
    </Page>
  );
}
