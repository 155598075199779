import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner, Button, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import styles from './EditPhoto.module.css';

const EditPhoto = ({
  uuid,
  fetching,
  onDelete,
  onSelect,
  thumbnail,
  onPreview,
}) => {
  const { t } = useTranslation();

  if (fetching) {
    return (
      <div className={styles.wrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <input
        type="image"
        key={uuid}
        className={styles.resultPhoto}
        src={thumbnail}
        alt={`photo_${uuid}.jpg`}
      />
      <Button
        title={t('delete')}
        minimal
        className={styles.deleteButton}
        icon="trash"
        onClick={onDelete}
        intent={Intent.PRIMARY}
      />
      <label
        title={t('change')}
        className={classNames(
          styles.editButton,
          'bp4-button',
          'bp4-minimal',
          'bp4-intent-primary',
          'bp4-icon-edit',
        )}
        htmlFor={`file-input-${uuid}`}
      >
        <input
          id={`file-input-${uuid}`}
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              const file = e.target.files[0];
              onSelect(file);
            }
          }}
        />
      </label>
      <Button
        title={t('view')}
        minimal
        className={styles.previewButton}
        icon="eye-open"
        onClick={onPreview}
        intent={Intent.PRIMARY}
      />
    </div>
  );
};

EditPhoto.propTypes = {
  uuid: PropTypes.string.isRequired,
  fetching: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  thumbnail: PropTypes.string,
  onPreview: PropTypes.func.isRequired,
};

export default EditPhoto;
