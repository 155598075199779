import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Intent, Classes, Dialog } from '@blueprintjs/core';
import RRU from 'react-redux-utils';
import _ from 'lodash';

import {
  BranchSelect,
  TradepointSelect,
  TradeNetworkSelect,
  TradepointProjectSelect,
  QuestionarySelect,
} from 'app/widgets/ReferenceSelect';
import Form from 'app/widgets/Form';
import store from 'app/state';

import styles from './PhotoExportScreen.module.css';

function ExtendedQuestionarySelectDialog({ onClose }) {
  const { t } = useTranslation();
  const filters = RRU.useSelector('photoExport.filters');
  const [value, setValue] = React.useState(_.pick(
    filters,
    'contragent_id__eq',
    'network_id__in',
    'project_id__in',
    'branch_id__in',
    'tradepoint_id__in',
  ));
  const onFieldChange = React.useCallback((k, v) => setValue(old => ({ ...old, [k]: v })), []);
  const onClickSave = RRU.useCallbackArgs(
    ids => {
      store.setOne('photoExport.filters.questionary_id__in', ids);
      onClose();
    },
    value.questionary_id__in,
  );

  return (
    <Dialog
      isOpen
      title={t('selectQuestionaryByGeography')}
      onClose={onClose}
      enforceFocus={false}
      canOutsideClickClose
    >
      <div className={Classes.DIALOG_BODY}>
        <Form className={styles.qselForm} value={value} onFieldChange={onFieldChange}>
          <Form.Item
            name="network_id__in"
            label={t('network')}
            dependsOn="contragent_id__eq"
            acceptor={TradeNetworkSelect}
          />
          <Form.Item
            name="project_id__in"
            label={t('project')}
            dependsOn="contragent_id__eq"
            acceptor={TradepointProjectSelect}
          />
          <Form.Item
            name="branch_id__in"
            label={t('affiliate')}
            dependsOn="contragent_id__eq"
            acceptor={BranchSelect}
          />
          <Form.Item
            name="tradepoint_id__in"
            label={t('tradepoints')}
            dependsOn="contragent_id__eq network_id__in? project_id__in? branch_id__in?"
            acceptor={TradepointSelect}
          />
          <Form.Item
            name="questionary_id__in"
            label={t('questionnaire')}
            dependsOn="contragent_id__eq network_id__in? project_id__in? branch_id__in? tradepoint_id__in?"
            acceptor={QuestionarySelect}
          />
        </Form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon="cross"
            onClick={onClose}
            text={t('cancel')}
          />
          <Button
            disabled={!value.questionary_id__in}
            intent={Intent.PRIMARY}
            onClick={onClickSave}
            text="OK"
          />
        </div>
      </div>
    </Dialog>
  );
}

ExtendedQuestionarySelectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default
function ExtendedQuestionarySelect(props) {
  const [showDialog, toggleShowDialog] = RRU.useToggle();
  const contragentSelected = !!RRU.useSelector('photoExport.filters.contragent_id__eq');

  return (
    <span className={styles.extendedQuestionarySelect}>
      <Button icon="globe" onClick={toggleShowDialog} disabled={!contragentSelected} />
      <QuestionarySelect {...props} />

      {showDialog && <ExtendedQuestionarySelectDialog onClose={toggleShowDialog} />}
    </span>
  );
}

