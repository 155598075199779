import React, { useEffect } from 'react';
import { Dialog, Classes, Button, Intent, Spinner, FormGroup, Position } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import { getAuditTeam, getAuditTradepoints, getSupervisors } from 'app/api/index';
import { useRequest } from 'app/utils';
import store from 'app/state';
import * as schedule from 'app/state/actionCreators/schedule';
import SimpleSelect from 'app/widgets/SimpleSelect';
import FancySelect from 'app/widgets/FancySelect';
import * as ReferenceSelect from 'app/widgets/ReferenceSelect';
import UpdatedDateInput from './UpdatedDateInput';
import { showError } from 'app/widgets/toaster';

import styles from './CreateVisitDialog.module.css';
import * as common from './common';

function closeDialog() {
  common.handleCloseDialog('createAuditDialog', false);
}

function handleSave() {
  common.handleSaveWithConflicts(schedule.createAudit, 'createAuditDialog');
}

const getSelectItems = (data) => data.map(
  (c) => ({ title: c.fullname, key: c.id, value: c.id }),
);

const getTPSelectItems = (tradepoints) => tradepoints.map((tp) => ({
  label: `${tp.network || tp.name || ''} - ${tp.address || ''}`,
  id: tp.task_id,
}));

function handleChangeForm(form, changes) {
  store.dispatch(schedule.setCreateAuditDialogForm({ ...form, ...changes }));
}

export default React.memo(() => {
  const isOpen = RRU.useSelector((state) => state.schedule.createAuditDialog.isOpen);
  const form = RRU.useSelector((state) => state.schedule.createAuditDialog.form);
  const fetching = RRU.useSelector((state) => state.schedule.createAuditDialog.fetching);
  const changed = RRU.useSelector((state) => state.schedule.createAuditDialog.formChanged);
  const { t } = useTranslation();
  const reqToFill = t('required to fill');
  const onChangeForm = RRU.useCallbackArgs(handleChangeForm, form);

  const [{ data: supervisors, fetching: fetchingSupervisors }, fetchSupervisors] = useRequest(getSupervisors);
  useEffect(() => {
    if (isOpen && form.contragentId) {
      fetchSupervisors(form.contragentId);
    }
  }, [isOpen, form.contragentId, fetchSupervisors]);

  const [{ data: merchandisers, fetching: fetchingMerchendisers }, fetchAuditTeam] = useRequest(getAuditTeam);
  useEffect(() => {
    if (isOpen && form.date) {
      fetchAuditTeam(form);
    }
  }, [isOpen, form.date, fetchAuditTeam]);

  const [{ data: tradepoints, fetching: fetchingTradepoints }, fetchAuditTradepoints] = useRequest(
    getAuditTradepoints,
    {
      onSuccess: (data) => onChangeForm({ taskIds:  data?.data?.map(t => t.task_id) }),
      onError: (e) => showError(e.message || t('error get list of tt')),
    },
  );
  useEffect(() => {
    if (isOpen && form.merchId) {
      fetchAuditTradepoints({
        ...form,
        onlyNew: true,
      });
    }
  }, [isOpen, form.merchId]);

  const invalid = (
    !form.date
    || !form.merchId
    || !form.taskIds?.length
    || !form.supervisorId
    || !form.contragentId
  );

  const supervisorsItems = getSelectItems(supervisors?.data ?? []);
  const merchandisersItems = getSelectItems(merchandisers?.data ?? []);
  const tradepointsItems = getTPSelectItems(tradepoints?.data ?? []);
  return (
    <Dialog
      icon="edit"
      style={{ width: 550 }}
      title={t('creating a joint visit')}
      isOpen={isOpen}
      onClose={closeDialog}
      enforceFocus={false}
      canOutsideClickClose={!changed}
    >
      {
        fetching
          ? (
            <div className={Classes.DIALOG_BODY}>
              <Spinner />
            </div>
          )
          : (
            <div className={Classes.DIALOG_BODY}>
              <FormGroup
                inline
                label={`${t('contractor')}:`}
                className={styles.detailsRow}
                helperText={!form.contragentId ? reqToFill : null}
                intent={!form.contragentId ? Intent.DANGER : Intent.NONE}
              >
                <ReferenceSelect.ContragentSelect
                  fill
                  value={form.contragentId}
                  onChange={(id) => onChangeForm({ contragentId: id })}
                  multi={false}
                  autoSelectFirst
                />
              </FormGroup>
              <FormGroup
                inline
                label={`${t('super visor')}:`}
                className={styles.detailsRow}
                helperText={!form.supervisorId ? reqToFill : null}
                intent={!form.supervisorId ? Intent.DANGER : Intent.NONE}
              >
                <SimpleSelect
                  emptiable
                  disabled={!form.contragentId || fetching}
                  fetching={fetchingSupervisors}
                  items={supervisorsItems}
                  value={form.supervisorId}
                  onChange={(id) => onChangeForm({
                    supervisorId: id,
                    date: undefined,
                    taskIds: [],
                    merchId: undefined,
                  })}
                  menuAlign={Position.BOTTOM_RIGHT}
                />
              </FormGroup>
              <FormGroup
                inline
                label={`${t('supervisor')}:`}
                className={styles.detailsRow}
              >
                <ReferenceSelect.UserSelect
                  fill
                  disabled={!form.supervisorId}
                  fetchDisabled={!form.supervisorId}
                  filters={{ subordinate_id__eq: form.supervisorId }}
                  value={form.managerId}
                  onChange={(id) => onChangeForm({ managerId: id })}
                  multi={false}
                  autoSelectFirst
                />
              </FormGroup>
              <FormGroup
                inline
                label={`${t('date of visit')}:`}
                className={styles.detailsRow}
                helperText={!form.date ? reqToFill : null}
                intent={!form.date ? Intent.DANGER : Intent.NONE}
              >
                <UpdatedDateInput
                  contragentId={form.contragentId}
                  supervisorId={form.supervisorId}
                  value={form.date}
                  onChange={(date) => onChangeForm({ date: date || undefined })}
                />
              </FormGroup>
              <FormGroup
                inline
                label={`${t('executor')}:`}
                className={styles.detailsRow}
                helperText={!form.merchId ? reqToFill : null}
                intent={!form.merchId ? Intent.DANGER : Intent.NONE}
              >
                <SimpleSelect
                  emptiable
                  filterable
                  disabled={!form.date || !form.contragentId || !form.supervisorId}
                  fetching={fetchingMerchendisers}
                  items={merchandisersItems}
                  value={form.merchId}
                  onChange={(id) => onChangeForm({ taskIds: [], merchId: id })}
                  menuAlign={Position.BOTTOM_RIGHT}
                />
              </FormGroup>
              <FormGroup
                inline
                label={`${t('tradepoint')}:`}
                className={[styles.detailsRow, styles.filtersGroup]}
                helperText={!form.taskIds ? reqToFill : null}
                intent={!form.taskIds ? Intent.DANGER : Intent.NONE}
              >
                <FancySelect
                  fill
                  fetching={fetchingTradepoints || fetching}
                  disabled={!form.merchId}
                  data={tradepointsItems}
                  value={form.taskIds}
                  onChange={(value) => onChangeForm({ taskIds: value })}
                />
              </FormGroup>
            </div>
          )
      }
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={closeDialog}>{t('cancel')}</Button>
          <Button
            disabled={fetching || invalid}
            icon="tick"
            intent={Intent.PRIMARY}
            onClick={handleSave}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});
