import i18n from 'i18n';

export function formatDistance(distance) {
  const kilomenters = Math.floor(distance / 1000);
  const meters = Math.floor(distance - kilomenters * 1000);

  let result = '';
  if (kilomenters > 0) {
    result += `${kilomenters} ${i18n.t('km')}. `;
  }
  result += `${meters} ${i18n.t('m')}.`;

  return result;
}

export const distance = (location1, location2) => {
  if (!location1 || !location2) {
    return 0;
  }

  const lat1 = location1.latitude;
  const lon1 = location1.longitude;
  const lat2 = location2.latitude;
  const lon2 = location2.longitude;

  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist = (
    Math.sin(radlat1) * Math.sin(radlat2)
    + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  );
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344 * 1000;
  return dist;
};

/* eslint-disable import/no-anonymous-default-export */
export default {
  formatDistance,
  distance,
};
