import React from 'react';
import _ from 'lodash';

export function memoizeOnChildren(f) {
  const state = {
    lastChildren: undefined,
    lastResult: undefined,
  };
  function wrapper() {
    const { children } = this.props;
    if (children !== state.lastChildren) {
      state.lastChildren = children;
      state.lastResult = f(React.Children.toArray(children));
    }

    return state.lastResult;
  }

  return wrapper;
}

export function memoizeOne(f) {
  let lastResult;
  let lastArgs;
  function wrapper(...args) {
    if (_.every(_.zipWith(args, lastArgs, _.eq))) {
      return lastResult;
    }
    lastResult = f(...args);
    lastArgs = args;
    return lastResult;
  }

  return wrapper;
}
