import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Intent, Tag } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import _ from 'lodash';

import { rolesToNames } from 'app/state/reducers';
import * as utils from 'app/utils';

import styles from './UserScreen.module.css';

export default React.memo(() => {
  const user = useSelector((state) => state.teamUser.user);
  const rolesMap = useSelector((state) => state.roles.rolesMap);
  const { t } = useTranslation();

  const lastUserTask = _.values(user.contragents).find(c => !!c.user_task_id);

  return (
    <div className={styles.details}>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('userIdAkaLogin')} (ID):</div>
        <div className={styles.detailsValue}>{user.id}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('surname')}:</div>
        <div className={styles.detailsValue}>{user.lastname || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('first name')}:</div>
        <div className={styles.detailsValue}>{user.firstname || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('middle name')}:</div>
        <div className={styles.detailsValue}>{user.secondname || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('phone number')}:</div>
        <div className={styles.detailsValue}>{user.phone || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('additional phone number')}:</div>
        <div className={styles.detailsValue}>{user.home_phone || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('email')}:</div>
        <div className={styles.detailsValue}>{user.email || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('roles')}:</div>
        <div className={styles.detailsValue}>{rolesToNames(user?.roles || [], rolesMap) || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('createTime')}:</div>
        <div className={styles.detailsValue}>{utils.toLocaleDateString(user.create_time)}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('lastVisit')}:</div>
        <div className={styles.detailsValue}>
          {lastUserTask
            ? (
              <Link to={`/tasks/${lastUserTask.user_task_id}/result`}>
                {utils.toLocaleDateString(lastUserTask.user_task_date)}
              </Link>
            )
            : '-'
          }
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('snils')}:</div>
        <div className={styles.detailsValue}>{user.snils || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('group')}:</div>
        <div className={styles.detailsValue}>{user.group || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('merchandising type')}:</div>
        <div className={styles.detailsValue}>{user.exclusive ? t('exclusive') : t('combined')}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('project')}:</div>
        <div className={styles.detailsValue}>{user.project || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('commercial network')}:</div>
        <div className={styles.detailsValue}>{user.network || '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('multiManager')}:</div>
        <div className={styles.detailsValue}>
          <Tag round minimal>{user.multi_manager ? t('yes') : t('no')}</Tag>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('blocked')}:</div>
        <div className={styles.detailsValue}>
          {
            user.active
              ? <Tag round minimal>{t('no')}</Tag>
              : <Tag round rightIcon={<Icon icon="lock" iconSize={10} />} intent={Intent.DANGER}>{t('yes')}</Tag>
          }
        </div>
      </div>
    </div>
  );
});
