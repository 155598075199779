import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { H2 } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import { reportFieldType } from 'app/proptyping';
import * as ACTIONS from 'app/state/actions';
import {
  fetchFields as fetchFieldsAC,
  fetchData as fetchDataAC,
  setContragent as setContragentAC,
  setFilters as setFiltersAC,
  setSort as setSortAC,
  setFilterVisible as setFilterVisibleAC,
  setNeedReload as setNeedReloadAC,
  reorderFields as reorderFieldsAC,
  toggleField as toggleFieldAC,
} from 'app/state/actionCreators/reports/dynamic';

import DataTableWrapper from './DataTable';
import FiltersCard from './Filters';
import ActionBar from './ActionBar';
import { formatFilters } from './common';

let prevCounter = 0;
let counter = 0;

const ReportDynamicScreen = ({
  fields,
  data,
  contragentId,
  filters,
  fetching,
  fieldsFetching,
  fileFetching,
  error,
  sort,
  setSort,
  fetchFields,
  fetchData,
  setContragent,
  setFilters,
  reorderFields,
  toggleField,
  filtersVisible,
  fieldsReportType,
  needReload,
  setNeedReload,
  setFilterVisible,
  location,
  clearData,
}) => {
  const { t } = useTranslation();
  const reportType = location.pathname.replace('/reports/', '');
  useEffect(
    () => { contragentId && fetchFields(contragentId); },
    [contragentId, t],
  );

  const [answersOnly, setAnswersOnly] = useState(false);

  const checkedColumns = fields.filter((f) => f.checked).map((f) => f.field);
  useEffect(() => {
    if (
      fields
      && filters
      && contragentId
      && filters.user_tasks_date
      && checkedColumns
      && checkedColumns.length
      && fieldsReportType === reportType
      && counter >= 1
      && (prevCounter !== counter)
    ) {
      // Костыль, для предотвращения запроса
      // кроме инициализированных пользователем
      prevCounter = counter;
      fetchData(formatFilters({ contragentId, filters, fields }), checkedColumns, answersOnly);
    }
  }, [
    fields,
    filters,
    sort,
    fieldsReportType,
    reportType,
    answersOnly,
  ]);

  useEffect(() => {
    // доп костыль для корректной работы при переходах
    // между разными страницами с отчетами
    if (fieldsReportType && reportType && fieldsReportType !== reportType) {
      prevCounter = 0;
      counter = 0;
    }
  }, []);

  const getTitle = ({ pathname }) => {
    const reportType = pathname.replace('/reports/', '');
    if (reportType === 'visits') {
      return t('visit report');
    }
    if (reportType === 'signals') {
      return t('alert report');
    }
    if (reportType === 'questionaries') {
      return t('questionnaire report');
    }
    return t('the reports');
  };

  const title = getTitle(location);

  const setFiltersLocal = (newFilters) => {
    const activeFilters = fields.filter((f) => f.field === 'user_tasks_date' || f.checked).map((f) => f.field);
    counter += 1;
    setFilters(_.pick(newFilters, activeFilters));
  };

  const setSortLocal = (newSort) => {
    counter += 1;
    setSort(newSort);
  };

  useEffect(() => () => {
    clearData();
    counter = 0;
  }, []);

  return (
    <Page wide>
      <H2>{title}</H2>
      <ActionBar
        fields={fields}
        toggleField={toggleField}
        filters={formatFilters({ contragentId, filters, fields })}
        filtersVisible={filtersVisible}
        setFilterVisible={setFilterVisible}
        answersOnly={answersOnly}
        setAnswersOnly={setAnswersOnly}
        reportType={reportType}
      />
      <FiltersCard
        fields={fields}
        filters={filters}
        contragentId={contragentId}
        fieldsFetching={fieldsFetching}
        setFilters={setFiltersLocal}
        setContragent={setContragent}
        filtersVisible={filtersVisible}
        needReload={needReload}
        setNeedReload={setNeedReload}
        fetching={fetching}
        fileFetching={fileFetching}
        answersOnly={answersOnly}
      />
      <DataTableWrapper
        data={data}
        fields={fields}
        fetching={fetching}
        error={error}
        reorderFields={reorderFields}
        sort={sort}
        setSort={setSortLocal}
      />
    </Page>
  );
};

ReportDynamicScreen.propTypes = {
  fields: PropTypes.arrayOf(reportFieldType).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  contragentId: PropTypes.number,
  filters: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  fieldsFetching: PropTypes.bool.isRequired,
  fileFetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  sort: PropTypes.shape({
    code: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }).isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  fieldsReportType: PropTypes.string.isRequired,
  needReload: PropTypes.bool.isRequired,
  setNeedReload: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  reorderFields: PropTypes.func.isRequired,
  toggleField: PropTypes.func.isRequired,
  fetchFields: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  setContragent: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setFilterVisible: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

ReportDynamicScreen.defaultProps = {
  error: null,
  contragentId: null,
};

const mapStateToProps = (state) => ({
  fields: state.reports.dynamic.fields,
  data: state.reports.dynamic.data,
  contragentId: state.reports.dynamic.contragentId,
  filters: state.reports.dynamic.filters,
  fetching: state.reports.dynamic.fetching || state.reports.dynamic.fieldsFetching,
  fieldsFetching: state.reports.dynamic.fieldsFetching,
  fileFetching: state.reports.dynamic.fileFetching,
  error: state.reports.dynamic.error || state.reports.dynamic.fieldsError,
  sort: state.reports.dynamic.sort,
  filtersVisible: state.reports.dynamic.filtersVisible,
  needReload: state.reports.dynamic.needReload,
  fieldsReportType: state.reports.dynamic.fieldsReportType,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const reportType = ownProps.location.pathname.replace('/reports/', '');
  return {
    setSort: (sort) => dispatch(setSortAC(sort)),
    fetchFields: (contragentId) => dispatch(fetchFieldsAC(reportType, contragentId)),
    fetchData: (...args) => dispatch(fetchDataAC(reportType, ...args)),
    clearData: () => dispatch({ type: ACTIONS.REPORT_CLEAR_DATA }),
    setContragent: (contragentId) => dispatch(setContragentAC(contragentId)),
    setFilters: (filters) => dispatch(setFiltersAC(filters)),
    reorderFields: (oldIndex, newIndex) => dispatch(reorderFieldsAC(oldIndex, newIndex)),
    toggleField: (code, checked) => dispatch(toggleFieldAC(code, checked)),
    setNeedReload: (needReload) => dispatch(setNeedReloadAC(needReload)),
    setFilterVisible: (visible) => dispatch(setFilterVisibleAC(visible)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDynamicScreen);
