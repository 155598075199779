import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import ReportPage from 'app/widgets/ReportPage';
import Form from 'app/widgets/Form';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';
import {
  AgencySelect,
  ContragentSelect,
  TradeNetworkSelect,
  TradepointProjectSelect,
  BranchSelect,
} from 'app/widgets/ReferenceSelect';
import { canViewAgencies } from 'app/state/reducers';
import { useSelectorMemo } from 'app/utils';

const INITIAL_FILTERS = { date__between: [moment().subtract(7, 'days').toDate(), new Date()] };

export default React.memo(() => {
  const { t } = useTranslation();
  const showAgencyFilter = useSelectorMemo(canViewAgencies);

  return (
    <ReportPage
      title={t('equipment report')}
      backgroundExportTaskType="EQUIPMENT_REPORT"
      initialFilters={INITIAL_FILTERS}
    >
        {showAgencyFilter && (
          <Form.Item
            name="agency_id__eq"
            label={t('agency')}
            acceptor={AgencySelect}
            autoSelectFirst
            multi={false}
          />
        )}
        <Form.Item
          name="contragent_id__eq"
          label={t('contractor')}
          dependsOn="agency_id__eq"
          acceptor={ContragentSelect}
          autoSelectFirst
          multi={false}
        />
        <Form.Item
          name="date__between"
          label={t('visit period')}
          acceptor={LocalizedDateRangeInput}
          shortcuts="months"
        />
        <Form.Item
          name="branch_id__in"
          label={t('affiliate')}
          dependsOn="contragent_id__eq"
          acceptor={BranchSelect}
        />
        <Form.Item
          name="network_id__in"
          label={t('network')}
          dependsOn="contragent_id__eq"
          acceptor={TradeNetworkSelect}
        />
        <Form.Item
          name="project_id__in"
          label={t('project')}
          dependsOn="contragent_id__eq"
          acceptor={TradepointProjectSelect}
        />
    </ReportPage>
  );
});
