import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';
import { transformContragents } from 'app/state/transform/contragentsInbound';

export const INITIAL_STATE = {
  data: {
    ids: [],
    byId: {},
  },
  fetching: false,
  error: false,
  totalItems: 0,
  openCreateDialog: {
    isOpen: false,
  }
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.CONTRAGENTS_FETCH_START:
      return _.set(state, 'fetching', true);

    case ACTIONS.CONTRAGENTS_FETCH_DONE: {
      try {
        return _.assign(state, {
          fetching: false,
          error: null,
          totalItems: action.data.rowcount,
          data: transformContragents(action.data.data),
        });
      } catch (e) {
        return _.set(state, 'error', e);
      }
    }

    case ACTIONS.CONTRAGENTS_FETCH_ERROR:
      return _.assign(state, {
        fetching: false,
        error: action.error,
      });

    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export const getContragent = (state, id) => state.data.byId[id];

export const getContragents = (state) => state.data.ids.map((id) => getContragent(state, id));

export const isOnlyOneContragent = (state) => state.data.ids && (state.data.ids.length === 1);

export const getContragentId = (state) => state.data.ids && state.data.ids[0];

export const hideCrisisManager = (state) => {
  if (isOnlyOneContragent(state)) {
    const contrId = getContragentId(state);
    const contr = getContragent(state, contrId);
    return contr && contr.settings && contr.settings.hide_crisis_manager;
  }
  return false;
};

export const getFetching = (state) => state.fetching;

export const getError = (state) => state.error;

export const getDialogIsOpen = (state) => state.contragents.openCreateDialog.isOpen;
