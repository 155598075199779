import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import * as utils from 'app/utils';

async function resolveTask({ id, currentTask, setError }) {
  if (currentTask?.group_id === id) {
    utils.history.replace(`/tasks/${currentTask.id}/result`);
    return;
  }

  try {
    const taskId = await api.getTaskGroupVisitId(id);
    utils.history.replace(`/tasks/${taskId}/result`);
  } catch (e) {
    setError(e);
  }
}

export default
function TaskGroupResultScreen() {
  const { t } = useTranslation();
  const currentTask = RRU.useSelector('task.data');
  const { id } = useParams();
  const [error, setError] = React.useState(null);
  RRU.useAsyncEffectOptions(resolveTask, { id }, { currentTask, setError });

  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }

  return <Spinner />;
}
