import React, { useMemo, useRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import { dispatch, getState } from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import * as utils from 'app/utils';
import { AgencyContragentFilter } from 'app/widgets/Filters';

import ActionsBar from './ActionsBar';
import Pagination from './Pagination';
import { Filters, Filter } from './Filters';
import styles from './Grid.module.css';

const Actions = utils.makePlaceholderComponentType('AbstractGrid.Actions');

function splitChildren({ children }) {
  let actions = null;
  const filters = [];
  const others = [];
  for (const child of React.Children.toArray(children)) {
    if (child.type === Actions) {
      actions = child;
    } else if (child.type === Filter) {
      filters.push(child);
    } else {
      others.push(child);
    }
  }

  return [actions, filters, others];
}

function fetchOnFiltersChange({ path, fetchDone, ...fetchParams }) {
  // fetchDone - это ref
  // eslint-disable-next-line no-param-reassign
  fetchDone.current = true;
  dispatch(grids.resetPage(path));
  dispatch(grids.fetch(path, fetchParams));
}

function getFilters(path) {
  return (getState().grids[path] ?? grids.INITIAL_STATE).filters;
}

function computeInitialFilters(path, filterChildren) {
  const filters = getFilters(path);
  const result = {};
  for (const filter of filterChildren) {
    const { path: key, defaultValue, autoSelectFirst, acceptor } = filter.props;
    if (!_.isNil(defaultValue)) {
      result[key] = defaultValue;
    } else if ((autoSelectFirst || acceptor === AgencyContragentFilter) && _.has(filters, key)) {
      result[key] = filters[key];
    }
  }
  return result;
}

function filtersReady(path, filterChildren) {
  const filters = getFilters(path);
  // Фильтры не готовы если есть <Filter> для которого
  return !filterChildren.some((f) => (
    !f.props.onChange // не указан кастомный onChange
    && !filters[f.props.path] // и нет значения
    && (
      f.props.autoSelectFirst // и указан автовыбор (нужно подождать пока он загрузится)
      || f.props.acceptor === AgencyContragentFilter // или это AgencyContragentFilter (который всегда ждём)
    )
  ));
}

function init(path, filterChildren, initialSort, initialPageSize) {
  dispatch(grids.checkInit({
    path,
    filters: computeInitialFilters(path, filterChildren),
    sort: initialSort,
    pagination: { currentPage: 1, itemsPerPage: initialPageSize },
  }));
}

function onMounted(path, filterChildren, fetchBound) {
  const ready = filtersReady(path, filterChildren);
  const forceReload = utils.history.location.state?.reload;
  const needLoad = !getState().grids[path]?.data.length;
  if (ready && (forceReload || needLoad)) {
    fetchBound();
  }

  if (forceReload) {
    utils.history.replace(utils.history.location);
  }
}

function handleFilterChange({ path, fetchDone, debouncedFetch, filterChildren, transientFilters }, key, value) {
  const changed = dispatch(grids.setFilter(path, key, value));
  if (!changed || transientFilters.includes(key)) {
    return;
  }

  if (fetchDone.current || filtersReady(path, filterChildren)) {
    debouncedFetch();
  }
}

function handleClearFilters({ path, filterChildren, ...fetchParams }) {
  dispatch(grids.setFilters(path, computeInitialFilters(path, filterChildren)));
  dispatch(grids.resetPage(path));
  dispatch(grids.fetch(path, fetchParams));
}

const AbstractGrid = React.memo(React.forwardRef(function AbstractGrid(
  { path, raw, initialSort, initialPageSize, showTotal, showItemsPerPage, footerPagination, children },
  ref,
) {
  const [actionsChild, filterChildren, otherChildren] = splitChildren({ children });
  const transientFilters = RRU.useEqualObject(
    filterChildren.filter(f => f.props.transient).map(f => f.props.path),
  );

  const fetchDone = useRef(false);
  const fetchBound = utils.useCallbackWithDeps(
    fetchOnFiltersChange,
    { path, fetchDone, transientFilters, raw },
  );
  const debouncedFetch = useMemo(() => _.debounce(fetchBound, 200), [fetchBound]);
  utils.useInit(init, path, filterChildren, initialSort, initialPageSize);
  useEffect(() => onMounted(path, filterChildren, fetchBound), []);

  const [filtersVisible, toggleFiltersVisible] = utils.useToggle();

  const onFilterChange = utils.useCallbackWithDeps(
    handleFilterChange,
    { path, fetchDone, debouncedFetch, filterChildren, transientFilters },
  );
  const onClearFilters = utils.useCallbackWithDeps(
    handleClearFilters,
    { path, filterChildren, transientFilters, raw },
  );

  useImperativeHandle(
    ref,
    () => ({
      setFilter: onFilterChange,
      fetch: (options) => dispatch(grids.fetch(path, { raw, transientFilters, ...options })),
    }),
    [onFilterChange],
  );

  const filters = useSelector((state) => (state.grids[path] ?? grids.INITIAL_STATE).filters);
  const hasData = useSelector((state) => !!state.grids[path]?.data.length);

  return (
    <>
      <ActionsBar
        hasFilters={filterChildren.length > 0}
        filtersVisible={filtersVisible}
        toggleFiltersVisible={toggleFiltersVisible}
        {..._.omit(actionsChild?.props, 'children')}
      >
        {actionsChild?.props.children}
        <Pagination
          path={path}
          fetchOptions={{ raw, transientFilters }}
          showTotal={showTotal}
          showItemsPerPage={showItemsPerPage}
        />
      </ActionsBar>

      <Filters
        filters={filters}
        visible={filtersVisible}
        setFilter={onFilterChange}
        clearFilters={fetchDone.current ? onClearFilters : null}
      >
        {filterChildren}
      </Filters>

      {otherChildren}

      {hasData && footerPagination && (
        <div className={styles.actionsBar}>
          <Pagination
            path={path}
            fetchOptions={{ raw, transientFilters }}
            showTotal={showTotal}
            showItemsPerPage={showItemsPerPage}
          />
        </div>
      )}
    </>
  );
}));

AbstractGrid.Actions = Actions;
AbstractGrid.Filter = Filter;

AbstractGrid.ActionsBar = ActionsBar;
AbstractGrid.Filters = Filters;

AbstractGrid.propTypes = {
  path: PropTypes.string.isRequired,
  raw: PropTypes.bool,
  initialSort: PropTypes.object,
  initialPageSize: PropTypes.number,

  showTotal: PropTypes.bool,
  showItemsPerPage: PropTypes.bool,
  footerPagination: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

AbstractGrid.defaultProps = {
  raw: false,
  initialSort: null,
  initialPageSize: 20,
  showTotal: true,
  showItemsPerPage: true,
  footerPagination: false,
};

export default AbstractGrid;
