import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from '@blueprintjs/core';
import { useDebounce } from 'app/utils';
import styles from './Filters.module.css';

const InputFilter = ({
  icon,
  name,
  placeholder,
  value,
  onChange,
  withDebounce,
  onlyDigits,
}) => {
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);

  const onChangeValue = (e) => {
    const val = e.target.value;
    if (!onlyDigits) {
      setStateValue(val);
    }
    if (onlyDigits && ((/^\d*$/.test(val)))) {
      setStateValue(val);
    }
  };

  useEffect(
    () => {
      if (!withDebounce && value !== stateValue) {
        onChange(stateValue || null);
      }
    },
    [stateValue],
  );
  useEffect(
    () => {
      if (withDebounce && value !== debouncedValue) {
        onChange(debouncedValue || null);
      }
    },
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );
  return (
    <div className={styles.filtersGroup}>
      <div className={styles.filtersLabel}>
        {name}
        {':'}
      </div>
      <InputGroup
        leftIcon={icon}
        placeholder={placeholder || name}
        value={stateValue || ''}
        onChange={onChangeValue}
      />
    </div>
  );
};

InputFilter.propTypes = {
  value: PropTypes.any,
  withDebounce: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  onlyDigits: PropTypes.bool,
};

InputFilter.defaultProps = {
  value: undefined,
  placeholder: null,
  withDebounce: true,
  icon: 'filter',
  onlyDigits: false,
};

export default InputFilter;
