import { getEventList, getTradepoint, exportEvents } from 'app/api';
import * as ACTIONS from 'app/state/actions';

import { $setIn, resolveAborting } from './index';

const parseEvent = (event) => ({
  ...event,
  expired_time: event.expired_time ? new Date(event.expired_time.slice(0, -1)) : null,
  end_time: event.end_time ? new Date(event.end_time.slice(0, -1)) : null,
  create_date: event.create_date ? new Date(event.create_date) : null,
  start_time: event.start_time ? new Date(event.start_time.slice(0, -1)) : null,
  result: {
    ...event.result,
    photos: event.result?.photos?.map((photo) => ({
      ...photo,
      date: photo.date ? new Date(photo.date) : null,
      create_photo_time: photo.create_photo_time ? new Date(photo.create_photo_time) : null,
    })),
  },
});

export const fetchEvents = (filters, pagination) => async (dispatch, getState) => {
  try {
    const { result, aborted } = await resolveAborting(
      getEventList(filters, pagination),
      'events',
      dispatch,
      getState,
    );
    if (aborted) {
      return;
    }

    const totalPages = Math.max(1, Math.ceil(result.rowcount / pagination.itemsPerPage));
    dispatch($setIn('events', {
      error: null,
      events: result.data.map(parseEvent),
      totalItems: result.rowcount,
      totalPages,
    }));
  } catch (error) {
    dispatch($setIn('events', {
      error,
    }));
  }
};

export const fetchTradepoint = (tradepointId) => async (dispatch) => {
  dispatch({ type: ACTIONS.EVENT_TRADEPOINT_FETCH_START });
  try {
    const tradepoint = await getTradepoint(tradepointId);
    dispatch({
      type: ACTIONS.EVENT_TRADEPOINT_FETCH_DONE,
      payload: {
        id: tradepointId,
        name: tradepoint.network,
        network: tradepoint.network,
        address: tradepoint.address,
        ...tradepoint,
      },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.EVENT_TRADEPOINT_FETCH_ERROR, error });
  }
};

export const setFilters = (filters) => (
  { type: ACTIONS.EVENT_LIST_SET_FILTERS, payload: { filters } }
);

export const clearFilters = () => (
  { type: ACTIONS.EVENT_LIST_CLEAR_FILTERS }
);

export const setPagination = (pagination) => (
  { type: ACTIONS.EVENT_LIST_SET_PAGINATION, payload: { pagination } }
);

export const setFilterVisible = (filtersVisible) => (
  { type: ACTIONS.EVENT_LIST_SET_FILTER_VISIBLE, payload: { filtersVisible } }
);

export const exportData = (filters) => async (dispatch) => {
  dispatch({ type: ACTIONS.EVENT_EXPORT_START });
  try {
    await exportEvents(filters);
    dispatch({ type: ACTIONS.EVENT_EXPORT_DONE });
  } catch (error) {
    dispatch({ type: ACTIONS.EVENT_EXPORT_ERROR, error });
  }
};
