import * as ACTIONS from 'app/state/actions';
import { getKladr } from 'app/api';

export const fetchKladrAction = (id) => async (dispatch) => {
  dispatch({ type: ACTIONS.KLADR_FETCH_START });
  try {
    const kladr = await getKladr(id);
    dispatch({
      type: ACTIONS.KLADR_FETCH_DONE,
      payload: {
        kladr,
      },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.KLADR_FETCH_ERROR, error });
  }
};

export const updateKladrAction = (kladr) => (
  { type: ACTIONS.KLADR_UPDATE_KLADR, payload: { kladr } }
);
