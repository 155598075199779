import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Position } from '@blueprintjs/core';
import { getContragentMerchandisers } from 'app/api';
import { useRequest } from 'app/utils';
import SimpleSelect from './SimpleSelect';

const getUserSelectItems = (users) => users.map(
  (u) => ({ title: `${u.fullname} (${u.phone || u.email || '-'})`, key: u.id, value: u.id }),
);

const MerchSelect = ({
  contragentId,
  disabled,
  value,
  onChange,
}) => {
  const [{
    fetching,
    error,
    data: merchandisers,
  }, fetchMerchandisers] = useRequest(getContragentMerchandisers);
  useEffect(() => {
    if (contragentId) {
      fetchMerchandisers(contragentId);
    }
  }, [fetchMerchandisers, contragentId]);

  return (
    <SimpleSelect
      emptiable
      filterable
      disabled={disabled}
      items={getUserSelectItems(merchandisers || [])}
      value={value}
      onChange={onChange}
      menuAlign={Position.BOTTOM_RIGHT}
      fetching={fetching}
      error={error}
    />
  );
};

MerchSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  contragentId: PropTypes.number,
  disabled: PropTypes.bool,
};

MerchSelect.defaultProps = {
  value: undefined,
  contragentId: undefined,
  disabled: false,
};

export default MerchSelect;
