import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H3,
  Button,
  FormGroup,
  InputGroup,
  Card,
  Elevation,
  Intent,
  Tooltip,
  Toaster,
  Position,
  Checkbox,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import * as qs from 'qs';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import * as api from 'app/api';
import { isEmail } from 'app/utils/email';
import { fetchUser } from 'app/state/actionCreators/user';
import styles from './LoginScreen.module.css';

function download(route) {
  const a = document.createElement('a')
  a.href = route;
  a.download = '';
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

class LoginScreen extends Component {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      login: '',
      password: '',
      fetching: false,
      error: false,
      policiesAcceptance: (
        JSON.parse(window.localStorage.getItem('policiesAcceptance')) ?? {
          cookiesPolicyVersion: null,
          personalDataPolicyVersion: null,
        }
      ),
    };

    this.toaster = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;
    const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    if (params.login) {
      this.setState({ login: params.login });
    }
  }

  policiesAccepted() {
    const { policiesAcceptance } = this.state;
    return (
      !!policiesAcceptance.cookiesPolicyVersion
      && !!policiesAcceptance.personalDataPolicyVersion
    );
  }

  onChangePoliciesAccepted = () => {
    const accepted = !this.policiesAccepted();
    const policiesAcceptance = {
      cookiesPolicyVersion: accepted ? 1 : null,
      personalDataPolicyVersion: accepted ? 1 : null,
    };
    window.localStorage.setItem('policiesAcceptance', JSON.stringify(policiesAcceptance));
    this.setState({ policiesAcceptance });
  };

  onLockClick() {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  }

  async onSubmit() {
    const { login, password, policiesAcceptance } = this.state;
    const { history, getUser } = this.props;
    const req = {
      password,
      cookies_policy_accepted_version: policiesAcceptance.cookiesPolicyVersion,
      personal_data_policy_accepted_version: policiesAcceptance.personalDataPolicyVersion,
    };

    if (isEmail(login)) {
      req.email = login;
    } else {
      req.phone = login;
    }

    this.setState({ fetching: true, error: null });
    try {
      await api.login(req);

      const downloadRoute = new URLSearchParams(window.location.search).get('download');
      if (downloadRoute) {
        download(downloadRoute);
      }

      await getUser();
      this.setState({ fetching: false });

      const next = history.location.state?.from?.pathname || '/feed';
      history.push({ pathname: next });
    } catch (error) {
      this.setState({ fetching: false, error });
      if (error.message) {
        this.toaster.current.show({
          intent: Intent.DANGER,
          message: error.detail || error.message,
          icon: 'error',
        });
      }
    }
  }

  errorForField(fieldName) {
    const { error } = this.state;
    if (error && error.errors && error.errors[fieldName]) {
      return error.errors[fieldName][0];
    }
    return null;
  }

  render() {
    const { t } = this.props;
    const { showPassword, login, password, fetching } = this.state;
    const policiesAccepted = this.policiesAccepted();
    const loginErrorText = this.errorForField('email') || this.errorForField('phone');
    const passwordErrorText = this.errorForField('password');

    const showPasswordButton = (
      <Tooltip content={`${showPassword ? t('hide') : t('show')} ${t('lowercase password')}`}>
        <Button
          onClick={() => this.onLockClick()}
          icon={showPassword ? 'eye-off' : 'eye-open'}
          intent={Intent.WARNING}
          minimal
        />
      </Tooltip>
    );

    return (
      <Page fullScreen>
        <Card className={styles.loginCard} elevation={Elevation.TWO}>
          <form
            className={styles.loginForm}
            onSubmit={(e) => { this.onSubmit(); e.preventDefault(); }}
          >
            <div>
              <span className={styles.header}>
                <H3 className={styles.headerTitle}>{t('login to website')}</H3>
              </span>
              <span className="bp3-text-muted">{t('auth to get access')}</span>
            </div>

            <div>
              <FormGroup
                label={t('phone or email')}
                helperText={loginErrorText}
                intent={loginErrorText ? Intent.DANGER : null}
              >
                <InputGroup
                  value={login}
                  onChange={(e) => this.setState({ login: e.target.value })}
                  large
                />
              </FormGroup>

              <FormGroup
                label={t('password')}
                helperText={passwordErrorText}
                intent={passwordErrorText ? Intent.DANGER : null}
              >
                <InputGroup
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  type={showPassword ? 'text' : 'password'}
                  rightElement={showPasswordButton}
                  large
                />

                <Link to="/recovery">
                  <Button text={`${t('forgot password')}?`} intent={Intent.PRIMARY} minimal />
                </Link>
              </FormGroup>

              <FormGroup>
                <Checkbox checked={policiesAccepted} onChange={this.onChangePoliciesAccepted}>
                  {t('cookiesNote')}
                  {' '}
                  <a href="/cookies-policy.html" target="_blank">{t('cookiesPolicy')}</a>.
                </Checkbox>
              </FormGroup>
            </div>

            <div className={styles.buttonGroup}>
              <Button
                disabled={!policiesAccepted || !login || !password}
                text={t('login')}
                intent={Intent.PRIMARY}
                loading={fetching}
                large
                type="submit"
              />
            </div>
          </form>
        </Card>

        <Toaster ref={this.toaster} position={Position.TOP} />
      </Page>
    );
  }
}

LoginScreen.propTypes = {
  getUser: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(fetchUser()),
});

export default connect(null, mapDispatchToProps)(withTranslation()(LoginScreen));
