import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Checkbox, Spinner, Switch } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

import * as api from 'app/api';
import { dispatch } from 'app/state';
import { toggleTradepointsBindings } from 'app/state/actionCreators/grids';
import { showError } from 'app/widgets/toaster';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import {
  AgencyContragentFilter,
  TradeNetworkFilter,
  BranchFilter,
  TradepointProjectFilter,
  QuestionaryFilter,
  PlanogrammFilter,
} from 'app/widgets/Filters';

import styles from './QuestionaryMultiBindingScreen.module.css';
import { withTranslation } from 'react-i18next';

function renderCode(code, rowData) {
  return (
    <Link to={`/tradepoint/${rowData.id}`}>
      {code}
    </Link>
  );
}

class QuestionaryMultiBinding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      questionaryId: null,
      bindingsInProgress: {},
    };
    this.grid = React.createRef();
  }

  setQuestionaryId = (questionaryId) => {
    const { filters } = this.props;
    if (filters.questionary_id__eq) {
      this.grid.current.setFilter('questionary_id__eq', questionaryId);
    }
    this.setState({ questionaryId });
  }

  toggleBindingInProgress = (bindingKey) => {
    let { bindingsInProgress } = this.state;
    if (bindingsInProgress[bindingKey]) {
      bindingsInProgress = _.omit(bindingsInProgress, bindingKey);
    } else {
      bindingsInProgress = { ...bindingsInProgress, [bindingKey]: true };
    }
    this.setState({ bindingsInProgress });
  }

  requestToggleBinding = async ({ refs, requestBind }) => {
    const { t } = this.props;
    try {
      const func = requestBind ? api.bindQuestionariesToTradepoints : api.unbindQuestionariesFromTradepoints;
      await func(refs);
      dispatch(toggleTradepointsBindings(refs));
    } catch (e) {
      showError(e.message || t('binding change error'));
    }
  }

  toggleBinding = async ({ tradepointId, bound }) => {
    const { questionaryId } = this.state;

    const bindingKey = `${tradepointId}_${questionaryId}`;
    this.toggleBindingInProgress(bindingKey);

    await this.requestToggleBinding({ refs: [{ tradepointId, questionaryId }], requestBind: !bound });

    this.toggleBindingInProgress(bindingKey);
  }

  toggleAll = async () => {
    const { data } = this.props;
    const { questionaryId } = this.state;
    const requestBind = data.some((t) => !t.questionaries[questionaryId]);

    const refs = [];
    const bindingsInProgress = {};
    for (const t of data) {
      const bound = !!t.questionaries[questionaryId];
      if (requestBind ^ bound) {
        refs.push({ tradepointId: t.id, questionaryId });
        bindingsInProgress[`${t.id}_${questionaryId}`] = true;
      }
    }

    this.setState({ bindingsInProgress });
    await this.requestToggleBinding({ refs, requestBind });
    this.setState({ bindingsInProgress: {} });
  }

  toggleOnlyBoundFilter = () => {
    const { filters } = this.props;
    const { questionaryId } = this.state;

    this.grid.current.setFilter('questionary_id__eq', filters.questionary_id__eq ? undefined : questionaryId);
  }

  renderBinding = (dummy, rowData) => {
    const { bindingsInProgress, questionaryId } = this.state;
    const bindingKey = `${rowData.id}_${questionaryId}`;
    if (bindingsInProgress[bindingKey]) {
      return <Spinner size={15} className={styles.bindingSpinner} />;
    }

    const bound = !!rowData.questionaries[questionaryId];
    return (
      <Checkbox
        className={styles.bindingCheckbox}
        checked={bound}
        onChange={() => this.toggleBinding({ tradepointId: rowData.id, bound })}
      />
    );
  }

  renderBindingTitle = () => {
    const { data, t } = this.props;
    const { questionaryId, bindingsInProgress } = this.state;
    const numBound = data.filter((t) => !!t.questionaries[questionaryId]).length;
    const allBound = numBound === data.length;
    const allUnbound = numBound === 0;

    return (
      <Checkbox
        className={styles.toggleAllCheckbox}
        disabled={!questionaryId || !_.isEmpty(bindingsInProgress)}
        checked={allBound}
        indeterminate={!allBound && !allUnbound}
        onClick={this.toggleAll}
      >
        {t('pinned')}
      </Checkbox>
    );
  }

  planogramChangeHandler = (newValue, isSelectAll) => {
    if (isSelectAll) {
      this.grid.current.setFilter('has_planogram_bindings__eq', newValue || undefined);
      this.grid.current.setFilter('planogram_id__in', undefined);
      return;
    }
    this.grid.current.setFilter('has_planogram_bindings__eq', undefined);
    this.grid.current.setFilter('planogram_id__in', newValue);
  }

  render() {
    const { t } = this.props;
    const { questionaryId } = this.state;

    return (
      <Page title={t('questionaries pinning')}>
        <Grid ref={this.grid} path={api.TRADEPOINTS_BINDINGS}>
          <Grid.Filter
            acceptor={AgencyContragentFilter}
            path="contragent_id__eq"
          />
          <Grid.Filter
            acceptor={InputFilter}
            path="code__ilike"
            icon="search"
            name={t('point code')}
            placeholder={t('search by point code')}
          />
          <Grid.Filter
            acceptor={InputFilter}
            path="address__ilike"
            icon="search"
            name={t('address')}
            placeholder={t('search by address')}
          />
          <Grid.Filter
            acceptor={BranchFilter}
            path="branch_id__in"
            dependsOn="contragent_id__eq"
          />
          <Grid.Filter
            acceptor={TradeNetworkFilter}
            path="network_id__in"
            dependsOn="contragent_id__eq"
          />
          <Grid.Filter
            acceptor={TradepointProjectFilter}
            path="project_id__in"
            dependsOn="contragent_id__eq"
          />
          <Grid.Filter
            acceptor={QuestionaryFilter}
            path="questionaryId"
            value={questionaryId}
            onChange={this.setQuestionaryId}
            dependsOn="contragent_id__eq"
            autoSelectFirst
            multi={false}
          />
          <Grid.Filter
            acceptor={PlanogrammFilter}
            path="planogram_id__in"
            selectAllPath="has_planogram_bindings__eq"
            dependsOn="contragent_id__eq"
            onChange={this.planogramChangeHandler}
          />
          <Grid.Filter
            acceptor={Switch}
            path="questionary_id__eq"
            disabled={!questionaryId}
            label={t('only pinned')}
            onChange={this.toggleOnlyBoundFilter}
          />

          <Grid.Column path="binding" title={this.renderBindingTitle()} valueRenderer={this.renderBinding} />
          <Grid.Column path="code" title={t('point code')} sortable valueRenderer={renderCode} />
          <Grid.Column path="address" title={t('address')} sortable />
          <Grid.Column path="contragent" title={t('contractor')} sortable />
          <Grid.Column path="network" title={t('network')} sortable />
          <Grid.Column path="project" title={t('extra project')} sortable />
          <Grid.Column path="ir_code" title={t('code for IR')} sortable />
          <Grid.Column path="branch" title={t('affiliate')} sortable />
          <Grid.Column path="name" title={t('name of tt')} sortable />
          <Grid.Column path="latitude" title={t('latitude')} sortable />
          <Grid.Column path="longitude" title={t('longitude')} sortable />
        </Grid>
      </Page>
    );
  }
}

QuestionaryMultiBinding.propTypes = {
  filters: PropTypes.shape({
    questionary_id__eq: PropTypes.number,
  }).isRequired,
  data: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.grids[api.TRADEPOINTS_BINDINGS]?.filters ?? {},
  data: state.grids[api.TRADEPOINTS_BINDINGS]?.data ?? [],
});

export default connect(mapStateToProps)(withTranslation()(QuestionaryMultiBinding));
