export const ROLES_WEIGHT = Object.fromEntries([
  'pilot_tester',
  'general_user',
  'test_user',
  'merch',
  'supervisor',
  'teamleader',
  'regional_manager',
  'merch_director',
  'check_manager',
  'chief_of_check_manager',
  'contragent',
  'agency_contragent',
  'agency_admin',
  'admin',
].map((key, index) => [key, index]));

export default ROLES_WEIGHT;
