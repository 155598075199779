import React from 'react';
import { Intent, Callout, Divider } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';

import Filters from './Filters';
import Settings from './Settings';
import styles from './PhotoExportScreen.module.css';

export default
function PhotoExportScreen() {
  const { t } = useTranslation();
  return (
    <Page title={t('download photos')}>
      <Filters />

      <Callout intent={Intent.PRIMARY} className={styles.message}>
        {t('select visit dates no more than 1 month')}.
        <br />
        {t('the file size can be up to')} <b>2Gb</b> {t('for 1 day')}!
      </Callout>

      <Divider />

      <Settings />
    </Page>
  );
}
