import { getTeamUsers, exportTeam } from 'app/api';
import * as ACTIONS from 'app/state/actions';

import { $setIn, resolveAborting } from './index';

export const fetchTeamUsers = (contragentId) => async (dispatch, getState) => {
  try {
    const { result, aborted } = await resolveAborting(
      getTeamUsers(contragentId),
      'team',
      dispatch,
      getState,
    );
    if (aborted) {
      return;
    }

    dispatch($setIn('team', {
      error: null,
      users: result.data,
    }));
  } catch (error) {
    dispatch($setIn('team', {
      error,
    }));
  }
};

export const setFilters = (filters) => (
  { type: ACTIONS.TEAM_USERS_SET_FILTERS, filters }
);

export const setContragent = (contragentId) => (
  { type: ACTIONS.TEAM_USERS_SET_CONTRAGENT, contragentId }
);

export const exportTeamData = () => async (dispatch) => {
  dispatch({ type: ACTIONS.TEAM_EXPORT_START });
  try {
    await exportTeam();
    dispatch({ type: ACTIONS.TEAM_EXPORT_DONE });
  } catch (error) {
    dispatch({ type: ACTIONS.TEAM_EXPORT_ERROR, error });
  }
};

export default fetchTeamUsers;
