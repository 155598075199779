import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  H2,
  Card,
  Button,
  Elevation,
  FormGroup,
  TextArea,
  Intent,
  FileInput,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import {
  uploadImageAC,
  sendFeedbackAC,
  clearFeedbackAC,
} from 'app/state/actionCreators/feedback';
import Page from 'app/widgets/Page';
import SimpleSelect from 'app/widgets/SimpleSelect';
import styles from './FeedbackScreen.module.css';

const FeedbackScreen = ({
  imageUploading,
  imageError,
  imageUUID,
  sending,
  error,
  reportId,
  uploadImage,
  sendFeedback,
  clearFeedback,
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [feedbackTypeCode, setFeedbackTypeCode] = useState(null);

  useEffect(() => { clearFeedback(); }, []);

  const feedbackTypesMap = {
    WRONG_ADDRESS_PROGRAM: t('incorrect address program'),
    WRONG_QUESTIONARY: t('incorrect composition of questionnaires'),
    MISSING_VISITS: t('missing visits'),
    WEB_PROBLEM: t('problem in web interface'),
    OTHER: t('other'),
  };

  const feedbackTypes = Object.keys(feedbackTypesMap).map((k) => ({
    title: feedbackTypesMap[k],
    key: k,
    value: k,
  }));

  const firstParagraph = t('first feedback paragraph');
  const secondParagraph1 = t('second feedback paragraph one');
  const secondParagraph2 = t('second feedback paragraph two');
  const thirdParagraph1 = t('third feedback paragraph one');
  const thirdParagraph2 = t('third feedback paragraph two');
  const thirdParagraph3 = t('third feedback paragraph three');
  const thirdParagraph4 = t('third feedback paragraph four');
  const thirdParagraph5 = t('third feedback paragraph five');
  const placeholder = `${firstParagraph}\n\n${secondParagraph1}${secondParagraph2}\n\n${
    thirdParagraph1}${thirdParagraph2}${thirdParagraph3}\n${thirdParagraph4}${thirdParagraph5}`;

  if (reportId) {
    return (
      <Page>
        <div className={styles.header}>
          <H2>{t('message sent')}</H2>
        </div>
        <Card elevation={Elevation.TWO} className={styles.card}>
          <div>
            {t('thank you for feedback')}
            <br />
            {t('application number')}
            {' '}
            <b>{reportId}</b>
            .
            <br />
            {t('all applications are considered in order of priority')},
            {t('our specialist will contact you')}.
          </div>
        </Card>
      </Page>
    );
  }

  if (error || imageError) {
    if (error) {
      return (
        <Page>
          <NonIdealState
            title={t('error')}
            description={(error || imageError).message}
            icon="error"
          />
        </Page>
      );
    }
  }

  return (
    <Page>
      <div className={styles.header}>
        <H2>{t('report a bug')}</H2>
      </div>
      <Card elevation={Elevation.TWO} className={styles.card}>
        <FormGroup label={`${t('problem type')}:`} fill>
          <SimpleSelect
            items={feedbackTypes}
            value={feedbackTypeCode}
            onChange={(value) => setFeedbackTypeCode(value)}
          />
        </FormGroup>
        <FormGroup label={`${t('message text')}:`} fill>
          <TextArea
            fill
            className={styles.textarea}
            placeholder={placeholder}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </FormGroup>
        { imageUploading ? <Spinner /> : null }
        { !imageUploading && imageUUID && (
          <img
            className={styles.img}
            alt={t('image')}
            src={`/api/tasks/photos/${imageUUID}`}
          />
        )}
        { !imageUploading ? (
          <FileInput
            large
            className={styles.fileinput}
            text={`${t('attach an image')}...`}
            buttonText={t('select')}
            onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                const file = e.target.files[0];
                uploadImage(file);
              }
            }}
            inputProps={{ accept: 'image/*' }}
          />
        ) : null }
        <div>
          <Button
            loading={sending}
            disabled={sending || imageUploading || !feedbackTypeCode || !message}
            intent={Intent.PRIMARY}
            onClick={() => {
              const feedbackType = {
                code: feedbackTypeCode,
                name: feedbackTypesMap[feedbackTypeCode],
              };
              sendFeedback({ message, feedbackType, imageUUID });
            }}
            icon="envelope"
          >
            {t('send')}
          </Button>
        </div>
      </Card>
    </Page>
  );
};

FeedbackScreen.propTypes = {
  imageUploading: PropTypes.bool.isRequired,
  imageError: PropTypes.instanceOf(Error),
  imageUUID: PropTypes.string,
  sending: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  reportId: PropTypes.number,
  uploadImage: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  clearFeedback: PropTypes.func.isRequired,
};

FeedbackScreen.defaultProps = {
  imageError: null,
  imageUUID: undefined,
  error: null,
  reportId: undefined,
};

const mapStateToProps = (state) => ({
  imageUploading: state.feedback.imageUploading,
  imageError: state.feedback.imageError,
  imageUUID: state.feedback.imageUUID,
  sending: state.feedback.sending,
  error: state.feedback.error,
  reportId: state.feedback.reportId,
});

const mapDispatchToProps = (dispatch) => ({
  uploadImage: (image) => dispatch(uploadImageAC(image)),
  sendFeedback: ({
    message,
    feedbackType,
    imageUUID,
  }) => dispatch(sendFeedbackAC({
    message,
    feedbackType,
    imageUUID,
  })),
  clearFeedback: () => dispatch(clearFeedbackAC())
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackScreen);
