import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  H2,
  NonIdealState,
  Spinner,
  Card,
  Elevation,
  Button,
  FormGroup,
  Intent,
  InputGroup,
  TextArea,
  Position,
  Alert,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from 'app/widgets/Page';
import { showSuccess, showError } from 'app/widgets/toaster';
import SimpleSelect from 'app/widgets/SimpleSelect';
import { useRequest } from 'app/utils';
import {
  getContragents,
  createPlanogram,
  editPlanogram,
  deletePlanogram,
} from 'app/api';
import { planogramType, contragentType } from 'app/proptyping';
import {
  fetchPlanogramAction,
  updatePlanogramAction,
  newPlanogramAction,
} from 'app/state/actionCreators/planogram';
import { transformPlanogramOut } from 'app/state/transform/planogram';
import PlanogramPhotos from './PlanogramPhotos';
import styles from './index.module.css';

const PLANOGRAM_ICONS = [
  {
    code: 'fem_hygiene',
    text: 'feminine hygiene',
    iconCode: 'female',
    charCode: '\uE9A4',
    style: {
      fontFamily: 'Fontisto',
    },
  },
  {
    code: 'fabric_care',
    text: 'fabric care',
    iconCode: 'washing-machine',
    charCode: '\uDB81\uDF2A',
    style: {
      fontFamily: 'MaterialCommunityIcons',
    },
  },
  {
    code: 'child_hygiene',
    text: 'children s hygiene',
    iconCode: 'child-friendly',
    charCode: '\uEB42',
    style: {
      fontFamily: 'MaterialIcons',
    },
  },
  {
    code: 'body_care',
    text: 'body care',
    iconCode: 'soap',
    charCode: '\uF1b2',
    style: {
      fontFamily: 'MaterialIcons',
    },
  },
  {
    code: 'teeth_care',
    text: 'oral care',
    iconCode: 'tooth',
    charCode: '\u{0F08C3}',
    style: {
      fontFamily: 'MaterialCommunityIcons',
    },
  },
  {
    code: 'shaving',
    text: 'shaving products',
    iconCode: 'flat-brush',
    charCode: '\uF19F',
    style: {
      fontFamily: 'Entypo',
    },
  },
  {
    code: 'cleaning',
    text: 'cleaning products',
    iconCode: 'cleaning-services',
    charCode: '\uF0FF',
    style: {
      fontFamily: 'MaterialIcons',
    },
  },
  {
    code: 'hair_care',
    text: 'hair care',
    iconCode: 'bathtub',
    charCode: '\uF2CD',
    style: {
      fontFamily: 'FontAwesome',
    },
  },
];

const defaultIcon = {
  iconCode: 'book',
  charCode: '\uF18D',
  style: {
    fontFamily: 'Ionicons',
  },
};

const PlanogrammIcon = ({ icon }) => {
  const iconItem = PLANOGRAM_ICONS.find((x) => x.code === icon) || defaultIcon;
  const { charCode, style } = iconItem;
  return (
    <div className={styles.planogrammIcon} style={style}>{charCode}</div>
  );
};

PlanogrammIcon.propTypes = {
  icon: PropTypes.string,
};

PlanogrammIcon.defaultProps = {
  icon: null,
};

const getContragentSelectItems = (contragents) => contragents.map(
  (c) => ({ title: c.fullname, key: c.id, value: c.id }),
);

const PlanogramForm = ({
  planogram,
  updatePlanogram,
  contragents,
  t,
}) => {
  const iconItems = PLANOGRAM_ICONS.map((icon) => ({
    key: icon.code,
    title: t(icon.text),
    value: icon.code,
    icon: <span style={icon.style}>{icon.charCode}</span>,
  }));
  return (
    <div className={styles.details}>
      <PlanogrammIcon icon={planogram.icon} />
      <FormGroup
        inline
        label={`${t('name')}:`}
        labelFor="planogram-name"
        className={styles.detailsRow}
        helperText={!planogram.name || !planogram.name.trim() ? t('required to fill') : null}
        intent={!planogram.name || !planogram.name.trim() ? Intent.DANGER : Intent.NONE}
      >
        <InputGroup
          id="planogram-name"
          value={planogram.name || ''}
          onChange={(e) => updatePlanogram({ name: e.target.value })}
        />
      </FormGroup>
      <FormGroup
        inline
        label={`${t('contractor')}:`}
        className={styles.detailsRow}
        helperText={!planogram.contragent_id ? t('required to fill') : null}
        intent={!planogram.contragent_id ? Intent.DANGER : Intent.NONE}
      >
        <SimpleSelect
          emptiable
          items={getContragentSelectItems(contragents || [])}
          value={planogram.contragent_id}
          onChange={(id) => updatePlanogram({ contragent_id: id })}
          menuAlign={Position.BOTTOM_RIGHT}
        />
      </FormGroup>
      <FormGroup
        inline
        label={`${t('icon')}:`}
        className={styles.detailsRow}
      >
        <SimpleSelect
          emptiable
          items={iconItems}
          value={planogram.icon}
          onChange={(icon) => updatePlanogram({ icon })}
          menuAlign={Position.BOTTOM_RIGHT}
        />
      </FormGroup>
      <PlanogramPhotos photos={planogram.images ?? []} />
      { !planogram.images?.length && (
        <div className="bp4-form-group bp4-intent-danger">
          <div className="bp4-form-helper-text">
            {t('required to fill')}
          </div>
        </div>
      ) }
      <FormGroup
        fill
        label={`${t('description')}:`}
        className={styles.textAreaRow}
        helperText={!planogram.text || !planogram.text.trim() ? t('required to fill') : null}
        intent={!planogram.text || !planogram.text.trim() ? Intent.DANGER : Intent.NONE}
      >
        <TextArea
          fill
          className={styles.textArea}
          value={planogram.text}
          onChange={(e) => updatePlanogram({ text: e.target.value })}
        />
      </FormGroup>
    </div>
  );
};

PlanogramForm.propTypes = {
  planogram: planogramType.isRequired,
  updatePlanogram: PropTypes.func.isRequired,
  contragents: PropTypes.arrayOf(contragentType),
  t: PropTypes.func.isRequired,
};

PlanogramForm.defaultProps = {
  contragents: null,
};

const usePlanogramEditScreen = ({
  match,
  history,
  fetching,
  planogram,
  newPlanogram,
  fetchPlanogram,
}) => {
  const { id } = match.params;
  const { t } = useTranslation();
  const [{ data: contragentsResp }, fetchContragents] = useRequest(getContragents);
  const contragents = contragentsResp && contragentsResp.data;
  const isNew = id === 'new';
  const [{ fetching: creating }, doCreate] = useRequest(createPlanogram, {
    onSuccess: () => {
      showSuccess(t('planogram saved'));
      history.push('/planograms');
    },
    onError: (e) => showError(e.message || t('planogram saved error')),
  });
  const [{ fetching: saving }, doSave] = useRequest(editPlanogram, {
    onSuccess: () => {
      showSuccess(t('planogram saved'));
      history.push('/planograms');
    },
    onError: (e) => showError(e.message || t('planogram saved error')),
  });
  const [{ fetching: deleting }, doDelete] = useRequest(deletePlanogram, {
    onSuccess: () => {
      showSuccess(t('planogram removed'));
      history.push('/planograms');
    },
    onError: (e) => showError(e.message || t('planogram removed error')),
  });
  const loading = fetching || creating || saving || deleting;
  const onSave = () => {
    if (isNew) {
      doCreate(transformPlanogramOut(planogram));
    } else {
      doSave(id, transformPlanogramOut(planogram));
    }
  };
  const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const onDelete = () => setDeleteAlertOpen(true);
  const onDeleteConfirmed = () => {
    setDeleteAlertOpen(false);
    doDelete(id);
  };
  const onDeleteCanceled = () => setDeleteAlertOpen(false);
  useEffect(() => {
    fetchContragents();
  }, [fetchContragents]);
  useEffect(() => {
    if (isNew) {
      newPlanogram();
    } else {
      fetchPlanogram(id);
    }
  }, [id]);

  const invalid = (
    loading
    || !planogram.name || !planogram.name.trim()
    || !planogram.text || !planogram.text.trim()
    || !planogram.contragent_id
    || !planogram.images || !planogram.images.length
  );
  return {
    isNew,
    isDeleteAlertOpen,
    onSave,
    onDelete,
    onDeleteConfirmed,
    onDeleteCanceled,
    invalid,
    loading,
    contragents,
  };
};

const PlanogramEditScreen = ({
  history,
  match,
  planogram,
  fetching,
  error,
  fetchPlanogram,
  updatePlanogram,
  newPlanogram,
}) => {
  const { t } = useTranslation();
  const {
    isNew,
    isDeleteAlertOpen,
    onSave,
    onDelete,
    onDeleteConfirmed,
    onDeleteCanceled,
    invalid,
    loading,
    contragents,
  } = usePlanogramEditScreen({
    match,
    history,
    fetching,
    planogram,
    newPlanogram,
    fetchPlanogram,
  });

  return (
    <Page>
      <H2>
        {t('planogram')}
        {' '}
        {planogram && planogram.name}
      </H2>

      {error && <NonIdealState title={t('error')} description={error.message} icon="error" />}
      {loading && <Spinner />}
      {!error && !loading && (
        <Card elevation={Elevation.TWO}>
          <PlanogramForm
            planogram={planogram}
            updatePlanogram={updatePlanogram}
            contragents={contragents}
            t={t}
          />
          <div className={styles.buttonGroup}>
            { !isNew && (
              <Button
                disabled={loading}
                icon="trash"
                intent={Intent.DANGER}
                onClick={onDelete}
              >
                {t('delete')}
              </Button>
            )}
            <div className={styles.spacer} />
            <Link to="/planograms">
              <Button
                icon="cross"
                className={styles.cancelButton}
              >
                {t('cancel')}
              </Button>
            </Link>
            <Button
              disabled={invalid}
              icon="tick"
              intent={Intent.PRIMARY}
              onClick={onSave}
            >
              {t('save')}
            </Button>
          </div>
        </Card>
      )}

      <Alert
        cancelButtonText={t('cancel')}
        confirmButtonText={t('delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDeleteAlertOpen}
        onCancel={onDeleteCanceled}
        onConfirm={onDeleteConfirmed}
      >
        <p>{t('sure to delete planogram')}?</p>
      </Alert>
    </Page>
  );
};

PlanogramEditScreen.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  planogram: planogramType.isRequired,
  fetchPlanogram: PropTypes.func.isRequired,
  updatePlanogram: PropTypes.func.isRequired,
  newPlanogram: PropTypes.func.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func,
  }).isRequired,
};

PlanogramEditScreen.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  fetching: state.planogram.fetching,
  error: state.planogram.error,
  planogram: state.planogram.planogram,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlanogram: (id) => dispatch(fetchPlanogramAction(id)),
  updatePlanogram: (planogram) => dispatch(updatePlanogramAction(planogram)),
  newPlanogram: () => dispatch(newPlanogramAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanogramEditScreen);
