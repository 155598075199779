import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { getAuditActiveDates } from 'app/api/index';
import { useRequest } from 'app/utils';
import { LocalizedDateInput } from 'app/widgets/LocalizedDateInput';
import { showError } from 'app/widgets/toaster';
import { ISODate } from 'app/utils/date';

const UpdatedDateInput = React.memo(({ value, contragentId, supervisorId, onChange }) => {
  const now = new Date();
  const { t } = useTranslation();
  const [activeMonthFirstDate, setActiveMonthFirstDate] = useState(new Date());
  const [fetchingDates, setFetchingDates] = useState(false);
  const [disabledDays, setDisabledDays] = useState([]);

  const [{ data: activeDates }, fetchActiveDates] = useRequest(
    getAuditActiveDates,
    {
      onError: (e) => showError(e.message || t('error get list of ad')),
    }
  );
  useEffect(() => {
    if (!contragentId || !supervisorId) {
      return;
    }
    setFetchingDates(true);
    fetchActiveDates({
      contragentId: contragentId,
      supervisorId: supervisorId,
      date: activeMonthFirstDate,
    });
  }, [contragentId, supervisorId, activeMonthFirstDate]);
  useEffect(() => {
    if (!activeDates) {
      return;
    }
    const flatActiveDates = activeDates.map((d) => ISODate(d.date));
    const days = []
    const dateStart = moment(activeMonthFirstDate).startOf('month');
    const dateEnd = moment(activeMonthFirstDate).endOf('month');
    while (dateEnd.diff(dateStart, 'days') >= 0) {
      if (flatActiveDates.indexOf(ISODate(dateStart)) === -1) {
        days.push(new Date(dateStart));
      }
      dateStart.add(1, 'days');
    }
    setDisabledDays(days);
    setFetchingDates(false);
  }, [activeDates]);

  return (
    <LocalizedDateInput
      disabled={!contragentId || !supervisorId}
      value={value}
      minDate={now}
      onChange={!fetchingDates && onChange}
      dayPickerProps={{
        onMonthChange: setActiveMonthFirstDate,
        disabledDays: fetchingDates ? () => true : disabledDays
      }}
    />
  );
});

UpdatedDateInput.propTypes = {
  value: PropTypes.object,
  contragentId: PropTypes.number,
  supervisorId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default UpdatedDateInput;
