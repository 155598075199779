import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'app/state';
import * as utils from 'app/utils';

import Page from 'app/widgets/Page';
import { setFilters, fetchQuestionaries, fetchTradepoints } from 'app/state/actionCreators/questionaryMultiBinding';
import FiltersWrapper from './Filters';
import { QUESTIONARIES, TRADEPOINTS } from 'app/api';
import SubordinateGrid from './SubordinateGrid';
import Grid from 'app/widgets/Grid';
import Toolbar from './Toolbar';
import styles from './QuestionaryBindingTradepointsScreen.module.css';

const QuestionaryBindingTradepointsScreen = () => {
  const { t } = useTranslation();
  const [filtersVisible, setFiltersVisible] = useState(true);
  const filters = utils.useSelectorMemo((state) => state.questionaryMultiBinding.filters);

  const clearFilters = () => {
    dispatch(setFilters({contragent_id__eq: filters.contragent_id__eq}));
  };

  const setLocalFilters = (value) => {
    dispatch(setFilters(value));
  };

	return (
    <Page title={t('multiple binding')}>
      <FiltersWrapper
        filters={filters}
        filtersVisible={filtersVisible}
        setFilters={setLocalFilters}
        setFiltersVisible={setFiltersVisible}
        clearFilters={clearFilters}
      />
      <Toolbar filters={filters} />
      <div className={styles.gridsWrapper}>
        <SubordinateGrid path={QUESTIONARIES} fetchFunc={fetchQuestionaries}>
          <Grid.Column path="id" title={t('id of questionnaire')} sortable />
          <Grid.Column path="name" title={t('name of questionnaire')} sortable />
        </SubordinateGrid>

        <SubordinateGrid path={TRADEPOINTS} fetchFunc={fetchTradepoints}>
          <Grid.Column path="code" title={t('point code')} sortable />
          <Grid.Column path="address" title={t('address')} sortable />
          <Grid.Column path="contragent" title={t('contractor')} sortable />
          <Grid.Column path="network" title={t('network')} sortable />
          <Grid.Column path="project" title={t('extra project')} sortable />
          <Grid.Column path="ir_code" title={t('code for IR')} sortable />
        </SubordinateGrid>
      </div>
    </Page>
	);
};

export default QuestionaryBindingTradepointsScreen;
