import i18n from 'i18n';
import store from 'app/state';
import * as api from 'app/api';
import { showError, showSuccess } from 'app/widgets/toaster';

function switchStatus(importTasks, id) {
  return importTasks.map(i => (
    i.id === id
      ? { ...i, status: 'CANCELED' }
      : i
  ));
}

export async function cancelImportTask(id) {
  store.setOne(`importTasks.cancelsInProgress.${id}`, true);
  try {
    await api.cancelImportTask(id);
    store.update(`grids.${api.IMPORT_TASKS}.data`, switchStatus, id);
    showSuccess(i18n.t('importTaskCanceled'));
    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.unset(`importTasks.cancelsInProgress.${id}`);
  }
}
