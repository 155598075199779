import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumericInput } from '@blueprintjs/core';
import _ from 'lodash';

import i18n from 'i18n';
import * as utils from 'app/utils';

function validateLimits(value, min, max) {
  if (!_.isNil(min) && value < min) {
    return i18n.t('greaterOrEqualX', { x: min });
  }
  if (!_.isNil(max) && value > max) {
    return i18n.t('lessOrEqualX', { x: max });
  }
  return null;
}

function handleValueChange({ setLastValue, setTextValue, onChange, min, max, integer }, newValue, newTextValue) {
  let correctNewTextValue = newTextValue;
  if (integer && correctNewTextValue.includes('.')) {
    correctNewTextValue = correctNewTextValue.split('.')[0];
  }
  setTextValue(correctNewTextValue);

  let correctNewValue = null;
  if (correctNewTextValue) {
    correctNewValue = Number(correctNewTextValue);
  }
  if (_.isNaN(correctNewValue)) {
    correctNewValue = null;
  }
  setLastValue(correctNewValue);
  if (integer && correctNewValue !== null) {
    correctNewValue = Math.round(correctNewValue);
  }

  let error;
  if (correctNewTextValue && correctNewValue === null) {
    error = i18n.t('invalidNumberFormat');
  }
  if (!error && !_.isNil(correctNewValue)) {
    error = validateLimits(correctNewValue, min, max);
  }

  onChange(correctNewValue, error);
}

function maybeUpdateValue({ value, lastValue, setLastValue, setTextValue }) {
  if (value !== lastValue) {
    setLastValue(value);
    setTextValue(_.toString(value));
  }
}

function revalidate({ value, onChange, min, max }) {
  if (_.isNil(value)) {
    return;
  }
  const error = validateLimits(value, min, max);
  onChange(value, error);
}

const NumberInput = React.memo(({ value, onChange, min, max, integer, ...props }) => {
  const [lastValue, setLastValue] = useState(value);
  const [textValue, setTextValue] = useState(_.toString(value));

  const onValueChange = utils.useCallbackWithDeps(
    handleValueChange,
    { setLastValue, setTextValue, onChange, min, max, integer },
  );

  utils.useEffectWithDeps(maybeUpdateValue, { value, lastValue, setLastValue, setTextValue });
  useEffect(() => revalidate({ value, onChange, min, max }), [min, max]);

  return (
    <NumericInput
      {...props}
      min={min}
      max={max < min ? undefined : max}
      value={textValue}
      onValueChange={onValueChange}
    />
  );
});

NumberInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  integer: PropTypes.bool.isRequired,
};

NumberInput.defaultProps = {
  value: null,
  min: undefined,
  max: undefined,
  integer: false,
};

export default NumberInput;
