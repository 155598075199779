import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import ReportPage from 'app/widgets/ReportPage';
import Form from 'app/widgets/Form';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';
import * as RefSel from 'app/widgets/ReferenceSelect';
import { canViewAgencies } from 'app/state/reducers';

const INITIAL_FILTERS = { date__between: [moment().subtract(7, 'days').toDate(), new Date()] };

export default React.memo(() => {
  const { t } = useTranslation();
  const showAgencyFilter = RRU.useSelector(canViewAgencies);

  return (
    <ReportPage
      title={t('workingTimeReport')}
      backgroundExportTaskType="VISITS_WORKING_TIME_REPORT"
      initialFilters={INITIAL_FILTERS}
    >
        {showAgencyFilter && (
          <Form.Item
            name="agency_id__eq"
            label={t('agency')}
            acceptor={RefSel.AgencySelect}
            autoSelectFirst
            multi={false}
          />
        )}
        <Form.Item
          name="contragent_id__eq"
          label={t('contractor')}
          dependsOn="agency_id__eq"
          acceptor={RefSel.ContragentSelect}
          autoSelectFirst
          multi={false}
        />
        <Form.Item
          name="date__between"
          label={t('visit period')}
          acceptor={LocalizedDateRangeInput}
          shortcuts="months"
        />
        <Form.Item
          name="id__eq"
          label={t('num of visit')}
          placeholder={t('search by num of visit')}
        />
        <Form.Item
          name="tradepoint_code__ilike"
          label={t('point code')}
          placeholder={t('search by point code')}
        />
        <Form.Item
          name="network_id__in"
          label={t('network')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.TradeNetworkSelect}
        />
        <Form.Item
          name="project_id__in"
          label={t('project')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.TradepointProjectSelect}
        />
        <Form.Item
          name="user_id__in"
          label={t('executor')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.UserSelect}
          placeholder={t('search by executor')}
          filters={{ roles__overlap: ['merch'] }}
        />
    </ReportPage>
  );
});
