import { downloadBiReport } from 'app/api';
import * as ACTIONS from 'app/state/actions';

export const downloadReport = (filters, onError) => async (dispatch) => {
  dispatch({ type: ACTIONS.REPORTS_BI_FETCH_START });
  try {
    await downloadBiReport(filters);
  } catch (error) {
    onError(error);
  }
  dispatch({ type: ACTIONS.REPORTS_BI_FETCH_DONE });
};

export const setFilters = (filters) => (
  { type: ACTIONS.REPORTS_BI_SET_FILTERS, payload: { filters } }
);
