import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getAuditTeam } from 'app/api';
import { useRequest } from 'app/utils';
import FancySelect from 'app/widgets/FancySelect';

import styles from 'app/widgets/Filters/Filters.module.css';

const MerchOnDateFilter = ({
  contragentId,
  supervisorId,
  date,
  disabled,
  value,
  onChange,
}) => {
  const [{ fetching, data: merchandisers }, fetchMerchandisers] = useRequest(getAuditTeam);
  const { t } = useTranslation();
  useEffect(() => {
    if (contragentId && date && supervisorId) {
      fetchMerchandisers({ contragentId, supervisorId, date });
    }
  }, [fetchMerchandisers, contragentId, date, supervisorId]);

  const merchList = merchandisers?.data.map((u) => ({
    label: u.fullname,
    code: u.phone || u.email,
    id: u.id,
  })) ?? [];

  return (
    <div className={styles.filtersGroup}>
      <div className={styles.filtersLabel}>
        {t('executor')}:
      </div>
      <FancySelect
        fill
        multi={false}
        placeholder={`${t('search by executor')}:`}
        disabled={disabled}
        data={merchList || []}
        fetching={fetching}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

MerchOnDateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  contragentId: PropTypes.number,
  supervisorId: PropTypes.number,
  date: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool.isRequired,
};

MerchOnDateFilter.defaultProps = {
  value: undefined,
  contragentId: undefined,
  supervisorId: undefined,
  date: undefined,
};

export default MerchOnDateFilter;
