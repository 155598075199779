import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner, NonIdealState, Button } from '@blueprintjs/core';
import { Cell, Column, Table, RenderMode } from '@blueprintjs/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { reportFieldType } from 'app/proptyping';

import styles from './ReportDynamicScreen.module.css';

const getSortIcon = (field, sort) => {
  if (sort.code === field.field) {
    if (sort.direction === 'asc') {
      return 'sort-asc';
    }
    return 'sort-desc';
  }
  return 'sort';
};

const getNextSortState = (field, sort) => {
  if (sort.code === field.field) {
    if (sort.direction === 'asc') {
      return {
        code: field.field,
        direction: 'desc',
      };
    }
    return {
      code: null,
      direction: 'asc',
    };
  }
  return {
    code: field.field,
    direction: 'asc',
  };
};

const DataTable = ({
  data,
  fields,
  reorderFields,
  sort,
  setSort,
  t,
}) => {
  const formatVal = (val, filterType, field) => {
    let formated = val;
    if (filterType === 'bool') {
      if (formated === true) {
        formated = t('yes');
      } else if (val === false) {
        formated = t('no');
      }
    } else if (filterType === 'date') {
      if (val) {
        formated = moment.utc(val).format('DD.MM.YYYY H:mm');
      }
    } else if (field === 'user_tasks_date') {
      if (val) {
        formated = moment(val).format('DD.MM.YYYY');
      }
    }
    return formated;
  };

  const getCellClipboardData = (fields, data) => (row, col) => {
    const field = fields[col];
    const val = formatVal(data[row][field.field], field.filter_type);
    return val;
  };

  const renderField = (field, filterType) => (rowNumber) => {
    const val = formatVal(data[rowNumber][field], filterType, field);
    return (
      <Cell>
        {val}
      </Cell>
    );
  };

  return (
    <Table
      numRows={data.length}
      enableColumnReordering
      minColumnWidth={100}
      renderMode={RenderMode.BATCH}
      onColumnsReordered={(oldIndex, newIndex) => reorderFields(oldIndex, newIndex)}
      getCellClipboardData={getCellClipboardData(fields.filter((f) => f.checked), data)}
    >
      {fields.filter((f) => f.checked).map((f) => (
        <Column
          key={f.field}
          nameRenderer={(name) => (
            // TODO: remove 'bp-table-text-no-measure' at the next line
            // after blueprintjs autosize bug fixed
            <div className={classNames(styles.columnHeaderWrapper, 'bp-table-text-no-measure')}>
              <div className={styles.columnHeaderText}>
                {name}
                {' '}
              </div>
              {
                f.sortable
                  ? (
                    <Button
                      small
                      minimal
                      icon={getSortIcon(f, sort)}
                      className={classNames(styles.sortButton, 'bp-table-text-no-measure')}
                      onClick={() => { setSort(getNextSortState(f, sort)); }}
                    />
                  )
                  : null
              }
            </div>
          )}
          name={f.name}
          cellRenderer={renderField(f.field, f.filter_type)}
        />
      ))}
    </Table>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(reportFieldType).isRequired,
  reorderFields: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    code: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }).isRequired,
  setSort: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const DataTableWrapper = ({
  data,
  fields,
  fetching,
  error,
  reorderFields,
  sort,
  setSort,
}) => {
  const { t } = useTranslation();
  if (fetching) {
    return <Spinner />;
  }
  if (error) {
    const errorMessage = error?.response?.status === 504 ? t('report timeout error') : error.message;
    return <NonIdealState title={t('error')} description={errorMessage} icon="error" />;
  }
  if (!data || !data.length) {
    return <NonIdealState title={t('empty')} description={t('no data found')} icon="list" />;
  }
  if (!fields || !fields.filter((f) => f.checked).length) {
    return <NonIdealState title={t('empty')} description={t('No columns selected')} icon="list" />;
  }
  return (
    <DataTable
      data={data}
      fields={fields}
      reorderFields={reorderFields}
      sort={sort}
      setSort={setSort}
      t={t}
    />
  );
};

DataTableWrapper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(reportFieldType).isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  reorderFields: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    code: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }).isRequired,
  setSort: PropTypes.func.isRequired,
};

DataTableWrapper.defaultProps = {
  error: null,
};

export default DataTableWrapper;
