import i18n from 'i18n';
import * as api from 'app/api';
import { endOfDay, ISODateTime } from 'app/utils/date';
import { showSuccess, showError } from 'app/widgets/toaster';

import { $setIn } from './index';

export const newEvent = (defaults) => {
  const initial = {
    contragent_id__eq: defaults?.contragent_id,
    default_expired_time: defaults?.expired_time,
    expired_time: defaults?.expired_time,
    text: defaults?.text ?? '',
    photo_required: defaults?.photo_required ?? false,
    tradepoint_ids: defaults?.tradepoint_id && [defaults.tradepoint_id],
  };
  return $setIn('newEvent', {
    event: initial,
    eventValid: false,
    eventInitial: initial,
  });
};

export const createEvent = () => async (dispatch, getState) => {
  const { contragent_id__eq, tradepoint_ids, expired_time, text, photo_required } = getState().newEvent.event;

  dispatch($setIn('newEvent', { fetching: true }));
  try {
    await api.createEvent({
      contragent_id: contragent_id__eq,
      tradepoint_ids,
      expired_time: ISODateTime(endOfDay(expired_time)),
      text,
      photo_required,
    });
    showSuccess(i18n.t('eventCreated'));
    return true;
  } catch (error) {
    showError(error.message || i18n.t('eventCreationError'));
    return false;
  } finally {
    dispatch($setIn('newEvent', { fetching: false }));
  }
};
