import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Callout, Intent, H4 } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import FancySelect from 'app/widgets/FancySelect';
import { dispatch } from 'app/state';
import { fetch } from 'app/state/actionCreators/references';
import * as config from 'app/config';
import * as utils from 'app/utils';
import * as api from 'app/api';

function handleNewFilters({ path, filters, fetchDisabled, canUseCached, setState }) {
  if (canUseCached) {
    return;
  }

  if (fetchDisabled) {
    setState({
      data: [],
      fetching: false,
      error: null,
    });
    return;
  }

  dispatch(fetch(path, filters));
  setState({
    data: [],
    fetching: true,
    error: null,
  });
}

function handleNewCached({ cached, canUseCached, setState }) {
  if (!canUseCached) {
    return;
  }

  setState({
    data: cached.data,
    fetching: false,
    error: cached.error,
  });
}

function useCachedData({ path, filters, fetchDisabled }) {
  const cached = utils.useSelectorMemo((state) => state.references[path]?.last);
  const canUseCached = _.isEqual(cached?.filters, filters);

  const [state, setState] = useState({
    data: canUseCached ? cached.data : [],
    fetching: !fetchDisabled && !canUseCached,
    error: canUseCached ? cached.error : null,
  });

  useEffect(
    () => handleNewFilters({ path, filters, fetchDisabled, canUseCached, setState }),
    [filters],
  );
  useEffect(
    () => handleNewCached({ cached, canUseCached, setState }),
    [cached, canUseCached],
  );

  return state;
}

function applyPreprocessItems({ preprocessItems, data }) {
  return preprocessItems ? preprocessItems(data) : data;
}

const ReferenceSelect = React.memo(({ path, filters, fetchDisabled, preprocessItems, ...props }) => {
  const { t } = useTranslation();
  const memoizedFilters = utils.useDeepEqualValue(filters);
  const largeFilters = _.some(filters, (v) => _.isArray(v) && v.length > 100);
  const { data, fetching, error } = useCachedData({
    path,
    filters: memoizedFilters,
    fetchDisabled: fetchDisabled || largeFilters,
  });
  const preprocessedData = utils.useMemoWithDeps(applyPreprocessItems, { preprocessItems, data });

  if (error) {
    return <Callout intent={Intent.DANGER}><H4>{ error.message || t('error') }</H4></Callout>;
  }

  if (largeFilters) {
    props.disabled = true;
    props.title = t('sample condition too large');
  }

  return <FancySelect {...props} data={preprocessedData} fetching={fetching} />;
});

ReferenceSelect.name = 'ReferenceSelect';

ReferenceSelect.propTypes = {
  path: PropTypes.string.isRequired,
  filters: PropTypes.object,
  fetchDisabled: PropTypes.bool,
  preprocessItems: PropTypes.func,
};

ReferenceSelect.defaultProps = {
  filters: {},
  fetchDisabled: false,
  preprocessItems: null,
};

export default ReferenceSelect;

function makeSelect(path, dativeCaseEntityName) {
  const Component = React.memo((props) => {
    const { t } = useTranslation();
    return (
      <ReferenceSelect
        path={path}
        placeholder={`${t('search by')} ${t(dativeCaseEntityName)}`}
        {...props}
      />
    );
  });
  Component.name = `ReferenceSelect.${path}`;
  return Component;
}

export const QuestionarySelect = makeSelect(api.QUESTIONARIES_REFERENCES, 'dativeQuestionnaire');
export const QuestionaryTitleSelect = makeSelect(api.QUESTIONARY_TITLES_REFERENCES, 'dativeSubtitle');
export const QuestionSelect = makeSelect(api.QUESTIONS_REFERENCES, 'dativeQuestion');
export const TradepointSelect = makeSelect(api.TRADEPOINTS_REFERENCES, 'dativeTT');
export const TradeNetworkSelect = makeSelect(api.TRADE_NETWORKS_REFERENCES, 'dativeNetwork');
export const TradepointProjectSelect = makeSelect(api.TRADEPOINT_PROJECTS_REFERENCES, 'dativeExtra project');
export const TradepointFormatSelect = makeSelect(api.TRADEPOINT_FORMATS_REFERENCES, 'dativeFormat');
export const TradepointTerritorySelect = makeSelect(api.TRADEPOINT_TERRITORIES_REFERENCES, 'dativeTerritory');
export const BranchSelect = makeSelect(api.BRANCHES_REFERENCES, 'dativeAffiliate');
export const ContragentSelect = makeSelect(api.CONTRAGENTS_REFERENCES, 'dativeContractor');
export const AgencySelect = makeSelect(api.AGENCIES_REFERENCES, 'dativeAgency');
export const UserSelect = makeSelect(api.USERS_REFERENCES, 'dativeUser');
export const RoleSelect = makeSelect(api.ROLES_REFERENCES, 'dativeRole');
export const UserTaskCheckQuestionSelect = makeSelect(api.USER_TASK_CHECKS_QUESTIONS_REFERENCES, 'dativeQuestion');
export const PlanogramSelect = makeSelect(api.PLANOGRAM_REFERENCES, 'dativePlanogram');
export const AstixActivitySelect = makeSelect(api.ASTIX_ACTIVITIES_REFERENCES, 'dativeActivity');
export const ProductCategorySelect = makeSelect(api.PRODUCT_CATEGORIES_REFERENCES, 'dativeCategory');
export const QuestionaryCategorySelect = makeSelect(api.QUESTIONARY_CATEGORIES_REFERENCES, 'dativeCategory');
export const VisitProblemSelect = makeSelect(api.VISIT_PROBLEMS_REFERENCES, 'dativeProblem');
export const ProductBrandSelect = makeSelect(api.PRODUCT_BRANDS, 'dativeBrands');
export const RescheduleReasonSelect = makeSelect(api.RESCHEDULE_REASONS_REFERENCES, 'dativeReason');

function localizeData({ t, data }) {
  return _.toPairs(data).map(([id, label]) => ({ id, label: t(label) }));
}

function makeConstSelect(data, dativeCaseEntityName) {
  const Component = React.memo((props) => {
    const { t } = useTranslation();
    const localizedData = RRU.useMemoOptions(localizeData, { t, data });
    const placeholder = dativeCaseEntityName && `${t('search by')} ${t(dativeCaseEntityName)}`;

    return (
      <FancySelect
        placeholder={placeholder}
        multi={false}
        data={localizedData}
        {...props}
      />
    );
  });
  Component.name = `ReferenceSelect.${dativeCaseEntityName}`;
  return Component;
}

export const TaskTypeSelect = makeConstSelect(config.TASK_TYPES);
export const VisitTypeSelect = makeConstSelect(_.omit(config.TASK_TYPES, 'event'));
export const FixingIRTypesSelect = makeConstSelect(config.BINDING_IR_COMBO_ITEMS);
export const OverheadBackwallShowSelect = makeConstSelect(config.OVERHEAD_BACKWALL_SHOW_TYPES);
