import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Classes, Dialog, Button, Intent, FormGroup, InputGroup } from '@blueprintjs/core';

import * as utils from 'app/utils';
import { dispatch } from 'app/state';
import Form from 'app/widgets/Form';
import { ContragentSelect, ProductBrandSelect } from 'app/widgets/ReferenceSelect';
import { newRecord, setRecordField, saveProductRecord } from 'app/state/actionCreators/references';
import styles from './RecordModal.module.css';

const RecordModal = React.memo(({ path, onClose, record }) => {
  const { t } = useTranslation();
  const editRecord = utils.useSelectorMemo((state) => state.references[path] && state.references[path].editRecord);
  const contragent_id = utils.useSelectorMemo((state) => state.grids[path].filters.contragent_id__eq);

  useEffect(() => {
    dispatch(newRecord(path, record));
  }, [record]);

  useEffect(() => {
    onFieldChange('contragent_id', contragent_id);
  }, [contragent_id]);

  const onFieldChange = (key, value) => {
    dispatch(setRecordField(path, key, value));
  };

  const onClickSave = async () => {
    try {
      await saveProductRecord(path);
      onClose(true);
    } catch {}
  };

  const filled = (field) => {
    return editRecord && !!editRecord[field];
  };

  const preprocessBrands = (data) => {
    return data.map((x) => ({
      label: x.name,
      id: x.id,
    }));
  };

  const formValid = filled('contragent_id') && filled('name') && filled('code') && filled('brand_id');

  return (
    <Dialog
      icon="edit"
      title={t(`${editRecord && editRecord.id ? 'edit' : 'create'} row`)}
      isOpen
      canOutsideClickClose={false}
      onClose={onClose}
    >
      <Form value={editRecord} onFieldChange={onFieldChange}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label={t('contractor')}
            labelFor="contragent_id"
            intent={!filled('contragent_id') ? Intent.DANGER : Intent.NONE}
          >
            <ContragentSelect
              id="contragent_id"
              className={styles.contragentCombo}
              defaultValue={contragent_id}
              value={editRecord && editRecord.contragent_id ? editRecord.contragent_id : contragent_id}
              onChange={(val) => onFieldChange('contragent_id', val)}
              multi={false}
            />
          </FormGroup>
          <FormGroup
            label={t('denomination')}
            labelFor="name"
          >
            <InputGroup
              id='name'
              defaultValue={editRecord && editRecord.name}
              onChange={(e) => onFieldChange('name', e.target.value)}
              intent={!filled('name') ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>
          <FormGroup
            label={t('product code')}
            labelFor="code"
          >
            <InputGroup
              id='code'
              defaultValue={editRecord && editRecord.code}
              onChange={(e) => onFieldChange('code', e.target.value)}
              intent={!filled('code') ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>
          
          <FormGroup
            label={t('product brand')}
            labelFor="brand_id"
          >
            <ProductBrandSelect
              id="brand_id"
              filters={{
                contragent_id__eq: editRecord && editRecord.contragent_id ? editRecord.contragent_id : contragent_id,
              }}
              className={styles.contragentCombo}
              defaultValue={editRecord && editRecord.brand_id}
              value={editRecord && editRecord.brand_id}
              onChange={(val) => onFieldChange('brand_id', val)}
              multi={false}
              preprocessItems={preprocessBrands}
              intent={!filled('brand_id') ? Intent.DANGER : Intent.NONE}
            />
          </FormGroup>
        </div>
      </Form>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={t('cancel')} onClick={() => onClose(false)} />
          <Button text={t('save')} disabled={!formValid} intent={Intent.PRIMARY} onClick={onClickSave} />
        </div>
      </div>
    </Dialog>
  );
});

RecordModal.propTypes = {
  path: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.number,
    contragent_id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default RecordModal;
