import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { eventType } from 'app/proptyping';
import Event from './Event';

export default
function EventList({ events, fetching, error, ...props }) {
  const { t } = useTranslation();
  if (fetching) {
    return <Spinner />;
  }
  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }
  if (!events || !events.length) {
    return <NonIdealState title={t('empty')} description={t('no tasks with cond')} icon="list" />;
  }
  return (
    <div>
      {events.map(e => <Event key={e.id} event={e} {...props} />)}
    </div>
  );
};

EventList.propTypes = {
  events: PropTypes.arrayOf(eventType).isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
};

EventList.defaultProps = {
  error: null,
};
