import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import i18n from 'i18n';
import {
  getTask,
  uploadImage,
  getQuestionariesByIds,
  createRestoredTask,
  editRestoredTask,
} from 'app/api';
import { ISODate, ISODateTime } from 'app/utils/date';
import { showError, showSuccess } from 'app/widgets/toaster';
import * as ACTIONS from 'app/state/actions';

const transformTask = (task, questionaries) => {
  const reuploadPhotos = [];

  const questions = questionaries.reduce(
    (accumulator, questionary) => accumulator.concat(questionary.questions),
    [],
  ).filter((q) => (
    (q.result.data !== null && q.result.data !== undefined)
    || (q.result.photos && q.result.photos.length > 0)
  )).map((q) => ({
    id: q.id,
    data: q.result.data === undefined ? null : q.result.data,
    photos: (q.result.photos && q.result.photos.map((photo) => {
      // костыль
      // при копировании, фотки которые были в копируемом визите, нужно перезалить
      if (photo.needReupload) {
        reuploadPhotos.push(photo.uuid);
      }
      return photo.uuid;
    })) || [],
  }));
  const startDate = moment(task.startDate);
  const endDate = moment(task.endDate);
  const startDateTime = moment(task.startDateTime)
    .year(startDate.year())
    .month(startDate.month())
    .date(startDate.date());
  const endDateTime = moment(task.endDateTime)
    .year(endDate.year())
    .month(endDate.month())
    .date(endDate.date());

  if (task.shopPhoto.needReupload) {
    reuploadPhotos.push(task.shopPhoto.uuid);
  }

  const data = {
    userId: task.userId,
    managerId: task.managerId,
    tradepointId: task.shop.id,
    questionaryIds: questionaries.map((q) => q.id),
    date: ISODate(task.date),

    task_data: {
      status: task.status || 'COMPLETED',
      startDate: ISODateTime(startDateTime),
      startUtcOffset: task.startUtcOffset,
      endDate: ISODateTime(endDateTime),
      startLocation: task.startLocation,
      endLocation: task.endLocation,

      data: {
        device: task.device,
        shop: {
          id: task.shop.id,
          network: task.shop.network,
          address: task.shop.address,
          comment: task.shopComment,
          photos: task.shopPhoto.uuid ? [task.shopPhoto.uuid] : [],
          problems: task.shopProblem ? [{
            code: task.shopProblem,
            data: null,
          }] : [],
        },
        groups: [],
        products: [],
        questions,
      },
    },
    reupload_photos: reuploadPhotos,
  };
  return data;
};

const parseTaskQuestionaries = (
  dataQuestions,
  resultQuestions,
  photos,
  isCopying,
) => {
  const questionaries = {};
  const resultQuestionsMap = {};
  for (const q of (resultQuestions ?? [])) {
    resultQuestionsMap[q.id] = q;
  }

  dataQuestions.forEach((q) => {
    if (!questionaries[q.code]) {
      questionaries[q.code] = {
        id: q.questionaryId,
        code: q.code,
        contragentCode: q.contragentCode,
        name: q.name,
        questions: [],
      };
    }
    const questionType = q.result.type;
    const required = q.result.required === 'да';
    const photoRequired = q.result.photoRequired === 'да';
    const photoCount = q.result.photoCount && Number(q.result.photoCount);
    questionaries[q.code].questions.push({
      id: q.id,
      text: q.text,
      type: questionType,
      titles: [
        q.title,
        q.subtitle || undefined,
        q.subsubtitle || undefined,
        q.swipesubtitle || undefined,
      ],
      result: {
        required,
        photoRequired,
        photoCount,
        data: resultQuestionsMap[q.id] && resultQuestionsMap[q.id].data,
        variants: q.result.variants || [],
        photos: ((resultQuestionsMap[q.id] && resultQuestionsMap[q.id].photos) || []).map(uuid => ({
          uploading: false,
          uuid,
          key: photos.find(p => p.id === uuid)?.key,
          needReupload: isCopying && !!uuid,
        })),
        limits: {
          min: q.result.limits.min,
          max: q.result.limits.max,
        },
        hasDefault: q.result.hasDefault,
        defaultAnswer: q.result.defaultAnswer,
      },
    });
  });
  return Object.values(questionaries);
};

const parseTask = (data, isCopying) => {
  const questionaries = parseTaskQuestionaries(
    data.data.questions,
    data.result.questions,
    data.photos,
    isCopying,
  );
  const shopPhoto = data.photos.find(p => p.id === data.result.shop?.photos[0]);
  const shopProblem = (
    data.result.shop?.problems.length
      ? data.result.shop.problems[0].code
      : null
  );

  const task = {
    contragentId: data.contragentId,
    userId: data.userId,
    managerId: data.managerId,
    date: data.data.task_date ? moment(data.data.task_date, 'DD.MM.YYYY').toDate() : null,

    questionaryIds: [],

    status: data.status,
    startDate: new Date(data.startDate && data.startDate.slice(0, -1)),
    startDateTime: new Date(data.startDate && data.startDate.slice(0, -1)),
    startUtcOffset: data.startUtcOffset,
    endDate: new Date(data.endDate && data.endDate.slice(0, -1)),
    endDateTime: new Date(data.endDate && data.endDate.slice(0, -1)),
    taskLocation: data.data.shop.info.geo ?? {
      latitude: null,
      longitude: null,
    },
    startLocation: data.startLocation,
    endLocation: data.endLocation,
    shop: {
      id: data.data.shop.id,
      network: data.data.shop.info.network,
      address: data.data.shop.info.address,
    },
    device: data.result.device,
    shopComment: data.result.shop?.comment,
    shopPhoto: {
      uploading: false,
      uuid: shopPhoto?.id,
      key: shopPhoto?.key,
      needReupload: isCopying && !!shopPhoto,
    },
    shopProblem,
  };
  return { task, questionaries };
};

const parseQuestionariesDefaults = (data) => data.map((questionary) => ({
  ...questionary,
  questions: questionary.questions.map((question) => ({
    ...question,
    result: {
      ...question.result,
      data: question.result.hasDefault ? question.result.defaultAnswer : undefined,
    },
  })),
}));

export const fetchTaskAC = (id, isCopying) => async (dispatch) => {
  dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_FETCH_START });
  try {
    const responce = await getTask(id);
    const { task, questionaries } = parseTask(responce, isCopying);
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_FETCH_DONE, payload: { task } });
    dispatch({
      type: ACTIONS.CREATE_RESTORED_TASK_LOAD_QUESTIONS_DONE,
      payload: { questionaries },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_FETCH_ERROR, error });
  }
};

export const markPhotosForReupload = () => ({
  type: ACTIONS.CREATE_RESTORED_TASK_MARK_PHOTOS_FOR_REUPLOAD,
});

export const editTaskAC = ({
  id,
  task,
  questionaries,
  onSuccess,
}) => async (dispatch) => {
  const data = transformTask(task, questionaries);
  dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_EDIT_START });
  try {
    await editRestoredTask(id, data);
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_EDIT_DONE });
    if (onSuccess) {
      onSuccess();
    }
    showSuccess(i18n.t('visitUpdated'));
  } catch (error) {
    showError(error.message || i18n.t('visitSavingError'));
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_EDIT_ERROR, error });
  }
};

export const fetchQuestionsAC = (questionaryIds) => async (dispatch) => {
  dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_LOAD_QUESTIONS_START });
  try {
    const responce = await getQuestionariesByIds(questionaryIds);
    const questionaries = parseQuestionariesDefaults(responce.data);
    dispatch({
      type: ACTIONS.CREATE_RESTORED_TASK_LOAD_QUESTIONS_DONE,
      payload: { questionaries },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_LOAD_QUESTIONS_ERROR, error });
  }
};

function getPhotoParams(getState) {
  const { restoredTaskEditor } = getState();
  const { startDateTime, endDateTime, taskLocation } = restoredTaskEditor.task;
  const { latitude, longitude } = taskLocation;

  if (!latitude || !longitude) {
    throw new Error(i18n.t('tradepointCoordinatesNotSupplied'));
  }

  const diff = moment(endDateTime).diff(startDateTime, 'seconds');
  const create_date = ISODateTime(moment(startDateTime).add(Math.round(Math.random() * diff), 'seconds'));

  return { latitude, longitude, create_date };
}

export const uploadShopPhotoAC = (image) => async (dispatch, getState) => {
  const imageUUID = uuidv4();
  dispatch({
    type: ACTIONS.CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_START,
    payload: { uuid: imageUUID },
  });

  try {
    const response = await uploadImage({ image, uuid: imageUUID, ...getPhotoParams(getState) });
    dispatch({
      type: ACTIONS.CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_DONE,
      payload: { uuid: imageUUID, key: response.key },
    });
  } catch (error) {
    showError(error.message || i18n.t('photoSavingError'));
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_ERROR, error });
  }
};

export const uploadQuestionPhotoAC = (
  image,
  questionaryId,
  questionId,
  onUploadStart,
  onUploadDone,
  replaceUuid,
) => async (dispatch, getState) => {
  const imageUUID = uuidv4();
  if (onUploadStart) {
    onUploadStart();
  }
  dispatch({
    type: ACTIONS.CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_START,
    payload: {
      uuid: imageUUID,
      questionaryId,
      questionId,
      replaceUuid,
    },
  });
  try {
    const response = await uploadImage({ image, uuid: imageUUID, ...getPhotoParams(getState) });
    dispatch({
      type: ACTIONS.CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_DONE,
      payload: {
        uuid: imageUUID,
        key: response.key,
        questionaryId,
        questionId,
      },
    });
  } catch (error) {
    showError(error.message || i18n.t('photoSavingError'));
    dispatch({
      type: ACTIONS.CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_ERROR,
      error,
      payload: {
        uuid: imageUUID,
        questionaryId,
        questionId,
      },
    });
  }
  if (onUploadDone) {
    onUploadDone();
  }
};

export const removeQuestionPhotoAC = (questionaryId, questionId, imageUuid) => ({
  type: ACTIONS.CREATE_RESTORED_TASK_REMOVE_QUESTION_PHOTO,
  payload: {
    questionaryId,
    questionId,
    uuid: imageUuid,
  },
});

export const createTaskAC = ({ task, questionaries, onSuccess }) => async (dispatch) => {
  const data = transformTask(task, questionaries);
  dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_CREATE_START });
  try {
    await createRestoredTask(data);
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_CREATE_DONE });
    if (onSuccess) {
      onSuccess();
    }
    showSuccess(i18n.t('visitCreated'));
  } catch (error) {
    showError(error.message || i18n.t('visitSavingError'));
    dispatch({ type: ACTIONS.CREATE_RESTORED_TASK_CREATE_ERROR, error });
  }
};

export const newTaskAC = () => ({ type: ACTIONS.CREATE_RESTORED_TASK_NEW });

export const clearQuestionsAC = () => ({ type: ACTIONS.CREATE_RESTORED_TASK_CLEAR_QUESTIONS });

export const clearShopPhotoAC = () => ({ type: ACTIONS.CREATE_RESTORED_TASK_CLEAR_SHOP_PHOTO });

export const updateTaskAC = (patch) => ({
  type: ACTIONS.CREATE_RESTORED_TASK_UPDATE,
  payload: patch,
});

export const updateQuestionAC = (questionaryId, questionId, data) => ({
  type: ACTIONS.CREATE_RESTORED_TASK_UPDATE_QUESTION,
  payload: {
    questionaryId,
    questionId,
    data,
  },
});
