import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Position, MenuItem, MenuDivider, Button, ButtonGroup, Spinner, Tag, Intent } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import _ from 'lodash';
import { useTranslation, Translation } from 'react-i18next';

import { useCallbackWithDeps, useMemoWithDeps, classNames, filterSomewhatFuzzy } from 'app/utils';

import styles from './FancySelect.module.css';

const SELECT_ALL = Symbol('SELECT_ALL');

function highlightSearch(item, key, matches) {
  const string = item[key];
  const keyMatches = matches?.[key];
  if (!keyMatches) {
    return string;
  }

  const parts = [];
  let i = 0;
  for (const [first, last] of keyMatches) {
    if (first > i) {
      parts.push(string.substring(i, first));
    }
    const part = string.substring(first, last);
    parts.push(<strong className={styles.highlight} key={first}>{part}</strong>);
    i = last;
  }

  if (i < string.length) {
    parts.push(string.substring(i));
  }

  return parts;
}

function computeValidValue({ multi, data, value }) {
  if (!value) {
    // null is valid
    return value;
  }

  if (!multi) {
    // singular is valid if present in data
    return data.some((item) => item.id === value) ? value : null;
  }

  const newValue = data.filter((item) => value.includes(item.id)).map((item) => item.id);
  if (newValue.length === value.length) {
    return value;
  }

  // not every id from `value` is present in data
  return newValue.length ? newValue : null;
}

function handleDataChange({ multi, autoSelectFirst, data, fetching, value, onChange, defaultValue }) {
  if (fetching) {
    return;
  }

  let newValue = computeValidValue({ multi, data, value });
  if (newValue === value && (newValue || !autoSelectFirst)) {
    // if value hasn't changed and it's not null or we don't need to autoselect
    // do nothing
    return;
  }

  if (!autoSelectFirst || !data.length) {
    // new value is null, but we either don't need to autoselect, or there is nothing to select from
    onChange(newValue);
    return;
  }

  if (defaultValue) {
    if (data.some((item) => item.id === defaultValue)) {
      onChange(defaultValue);
      return;
    }
  }

  newValue = multi ? [data[0].id] : data[0].id;
  onChange(newValue);
}

function getSelectedItems({ multi, value, data }) {
  if (_.isNil(value)) {
    return [];
  }

  return data.filter((item) => (multi ? value.includes(item.id) : value === item.id));
}

function renderSelectAll({ allSelected }, handleClick, modifiers) {
  return (
    <React.Fragment key="SELECT_ALL">
      <MenuItem
        active={modifiers.active}
        icon={allSelected ? 'tick' : 'blank'}
        onClick={handleClick}
        text={<Translation>{(t) => t('select all')}</Translation>}
        shouldDismissPopover={false}
      />
      <MenuDivider />
    </React.Fragment>
  );
}

function renderItem({ multi, value, itemTextComponent }, itemOrSearchResult, { handleClick, modifiers }) {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  let item;
  let matches;
  if (itemOrSearchResult.matches) {
    item = itemOrSearchResult.item;
    matches = itemOrSearchResult.matches;
  } else {
    item = itemOrSearchResult;
  }

  if (item.id === SELECT_ALL) {
    return renderSelectAll(item, handleClick, modifiers);
  }

  let icon;
  if (multi) {
    icon = value?.includes(item.id) ? 'tick' : 'blank';
  }

  let text = highlightSearch(item, 'label', matches);
  if (itemTextComponent && !item.special) {
    text = React.createElement(itemTextComponent, { text, item });
  }

  let label;
  if (item.code !== item.label) {
    label = highlightSearch(item, 'code', matches);
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={item.disabled}
      icon={icon}
      key={item.id}
      label={label}
      onClick={handleClick}
      text={text}
      shouldDismissPopover={!multi}
    />
  );
}

function filterItems({ value, multi, labelText, selectAllValue }, query, items) {
  let itemsToShow = items;

  if (query.trim()) {
    itemsToShow = filterSomewhatFuzzy({ items: itemsToShow, query, keys: ['label', 'code'] });
  }

  const filteredItems = itemsToShow;

  if (itemsToShow.length > 20 && items.length > 100) {
    const label = `${labelText} ${itemsToShow.length})`;
    const notification = {
      label,
      id: 'notification',
      disabled: true,
      special: true,
    };
    itemsToShow = [...itemsToShow.slice(0, 20), notification];
  }

  if (multi && filteredItems.length) {
    const allSelected = (
      selectAllValue ||
      (value?.length === filteredItems.length
      && filteredItems.every((item) => value.includes(item.item?.id ?? item.id)))
    );
    itemsToShow = [{ id: SELECT_ALL, allSelected, filteredItems }, ...itemsToShow];
  }

  return itemsToShow;
}

function handleSelectAll(onChange, { allSelected, filteredItems }) {
  if (allSelected) {
    onChange(null, true);
  } else {
    onChange(filteredItems.map((item) => item.item?.id ?? item.id), true);
  }
}

function handleItemSelect({ onChange, multi, value, selectAllPath }, itemOrSearchResult) {
  let item = itemOrSearchResult;
  if (itemOrSearchResult.matches) {
    item = itemOrSearchResult.item;
  }

  if (item.id === SELECT_ALL) {
    if (selectAllPath) {
      if (item.allSelected) {
        onChange(false, true);
      } else {
        onChange(true, true);
      }
      return;
    }
    handleSelectAll(onChange, item);
    return;
  }

  if (!multi) {
    if (value !== item.id) {
      onChange(item.id);
    }
    return;
  }

  const newValue = value ? [...value] : [];
  const index = newValue.indexOf(item.id);
  if (index === -1) {
    newValue.push(item.id);
  } else {
    newValue.splice(index, 1);
  }

  onChange(newValue.length ? newValue : null);
}

function handleClear({ onChange }, e) {
  onChange(null);
  e.stopPropagation();
}

function itemDisabled(item) {
  return item.disabled;
}

function getButtonText(selectedItems, notChosenText) {
  if (!selectedItems.length) {
    return notChosenText;
  }
  if (!_.isString(selectedItems[0].label)) {
    return selectedItems[0].label;
  }

  const parts = [];
  let totalLength = 0;
  for (const item of selectedItems) {
    let part = item.label || '""';
    if (item.code && item.code !== item.label) {
      part += ` (${item.code})`;
    }
    parts.push(part);
    totalLength += part.length;

    if (totalLength + 2 * (totalLength.length - 1) >= 120) {
      break;
    }
  }

  return parts.join(', ');
}

const FancySelect = React.memo(({
  multi,
  autoSelectFirst,
  disabled,
  clearable,

  placeholder,
  fill,
  title,
  className,
  intent,
  popoverPosition,
  itemTextComponent,

  data,
  fetching,

  value,
  onChange,
  defaultValue,

  selectAllPath,
  selectAllValue,
}) => {
  useEffect(() => handleDataChange(
    { multi, autoSelectFirst, data, fetching, value, onChange, defaultValue }
  ), [data]);
  const { t } = useTranslation();
  const textNotFound = t('not found');
  const labelText = `${t('specify search')} (${t('shown')} 20 ${t('from')}`;
  const notChosenText = t('not chosen');

  const selectedItems = useMemoWithDeps(getSelectedItems, { multi, data, value });

  const itemRenderer = useCallbackWithDeps(renderItem, { multi, itemTextComponent, value });
  const itemListPredicate = useCallbackWithDeps(filterItems, { multi, value, labelText, selectAllValue });
  const onItemSelect = useCallbackWithDeps(handleItemSelect, { multi, value, onChange, selectAllPath });
  const onClickClear = useCallbackWithDeps(handleClear, { onChange });

  let countTag;
  if (multi && selectedItems.length) {
    countTag = <Tag round intent={Intent.DANGER}>{selectedItems.length}</Tag>;
  }

  const buttonText = getButtonText(selectedItems, notChosenText);

  const NO_RESULTS = <MenuItem disabled text={textNotFound} />;

  return (
    <Select
      disabled={disabled || fetching}
      className={className}
      filterable={data.length > 10}
      items={data}
      fill={fill}
      itemRenderer={itemRenderer}
      itemListPredicate={itemListPredicate}
      itemDisabled={itemDisabled}
      onItemSelect={onItemSelect}
      closeOnSelect={!multi}
      noResults={NO_RESULTS}
      inputProps={{ placeholder: placeholder ?? t('searchingDots') }}
      popoverProps={{ position: popoverPosition }}
    >
      <ButtonGroup fill={fill} alignText="left">
        <Button
          className={classNames(
            styles.selectButton,
            intent === Intent.DANGER && styles.selectButtonDanger,
          )}
          icon={countTag}
          rightIcon={fetching ? <Spinner size={Spinner.SIZE_SMALL} /> : 'caret-down'}
          text={buttonText}
          disabled={disabled || fetching}
          fill={fill}
          title={title}
        />
        {(clearable || multi) && !_.isNil(value) && <Button icon="small-cross" onClick={onClickClear} />}
      </ButtonGroup>
    </Select>
  );
});

const IdType = PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired;

FancySelect.propTypes = {
  multi: PropTypes.bool,
  autoSelectFirst: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,

  placeholder: PropTypes.string,
  fill: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  intent: PropTypes.string,
  popoverPosition: PropTypes.string,
  itemTextComponent: PropTypes.elementType,

  data: PropTypes.arrayOf(PropTypes.shape({
    id: IdType,
    label: PropTypes.node.isRequired,
  })).isRequired,
  fetching: PropTypes.bool,

  value: PropTypes.oneOfType([
    IdType,
    PropTypes.arrayOf(IdType),
  ]),
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([
    IdType,
    PropTypes.arrayOf(IdType),
  ]),
  selectAllPath: PropTypes.string,
  selectAllValue: PropTypes.bool,
};

FancySelect.defaultProps = {
  multi: true,
  autoSelectFirst: false,
  disabled: false,
  clearable: false,

  fill: false,
  title: null,
  className: null,
  intent: Intent.NONE,
  popoverPosition: Position.BOTTOM_RIGHT,
  itemTextComponent: null,

  fetching: false,
  value: null,
  defaultValue: null,
};

export default FancySelect;
