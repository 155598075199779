import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  uploadQuestionPhotoAC,
  removeQuestionPhotoAC,
} from 'app/state/actionCreators/restoredTaskEditor';
import Photos from 'app/widgets/Photos';

const TaskPhotos = ({
  photos,
  removePhoto,
  uploadPhoto,
  questionaryId,
  questionId,
  onUploadStart,
  onUploadDone,
}) => (
  <Photos
    photos={photos}
    removePhoto={(uuid) => removePhoto(questionaryId, questionId, uuid)}
    uploadPhoto={(image) => uploadPhoto(
      image,
      questionaryId,
      questionId,
      onUploadStart,
      onUploadDone,
    )}
    replacePhoto={(image, replaceUuid) => uploadPhoto(
      image,
      questionaryId,
      questionId,
      onUploadStart,
      onUploadDone,
      replaceUuid,
    )}
    getPreview={({ key }) => `/files/previews/${key}`}
    getSrc={({ key }) => `/files/${key}`}
  />
);

TaskPhotos.propTypes = {
  questionaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  questionId: PropTypes.number.isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    key: PropTypes.string,
    uploading: PropTypes.bool,
    needReupload: PropTypes.bool,
  })).isRequired,
  removePhoto: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  uploadPhoto: uploadQuestionPhotoAC,
  removePhoto: removeQuestionPhotoAC,
};

export default connect(null, mapDispatchToProps)(TaskPhotos);
