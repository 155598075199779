import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Intent, Spinner } from '@blueprintjs/core';
import moment from 'moment';
import RRU from 'react-redux-utils';

import * as utils from 'app/utils';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';
import {
  AgencySelect,
  ContragentSelect,
  BranchSelect,
  TradepointSelect,
  TradeNetworkSelect,
  TradepointProjectSelect,
  QuestionaryTitleSelect,
  QuestionSelect,
  UserSelect,
  TaskTypeSelect,
} from 'app/widgets/ReferenceSelect';
import Form, { ReduxForm } from 'app/widgets/Form';
import { needHideVisitUsers, needHideVisitMerch, canViewAgencies } from 'app/state/reducers';
import { createTask, clearFilters } from 'app/state/actionCreators/photoExport';

import ExtendedQuestionarySelect from './ExtendedQuestionarySelect';
import styles from './PhotoExportScreen.module.css';

const FiltersForm = React.memo(() => {
  const { t } = useTranslation();
  const showAgencyFilter = RRU.useSelector(canViewAgencies);
  const showParentUser = RRU.useSelector(needHideVisitMerch);
  const hideVisitMerch = RRU.useSelector(needHideVisitUsers);

  const validateEndTime = ([start, end]) => {
    if (!start || !end) {
      return t('visit date not specified');
    }
    if (moment(end).diff(start, 'days') > 31) {
      return t('period more than a month');
    }
    return true;
  };

  return (
    <ReduxForm inline path="photoExport.filters">
      <Form.Item
        name="end_time__between"
        label={t('date of visit')}
        required
        acceptor={LocalizedDateRangeInput}
        validator={validateEndTime}
        shortcuts="months"
      />
      {showAgencyFilter && (
        <Form.Item
          name="agency_id__eq"
          label={t('agency')}
          acceptor={AgencySelect}
          autoSelectFirst
          multi={false}
        />
      )}
      <Form.Item
        name="contragent_id__eq"
        label={t('contractor')}
        required
        dependsOn="agency_id__eq"
        acceptor={ContragentSelect}
        autoSelectFirst
        multi={false}
      />
      {showParentUser && (
        <Form.Item
          name="supervisor_id"
          label={t('supervisor')}
          dependsOn="contragent_id__eq"
          acceptor={UserSelect}
          filters={{ roles__overlap: ['supervisor', 'teamleader'] }}
          multi={false}
          clearable
        />
      )}
      {!hideVisitMerch && !showParentUser && (
        <Form.Item
          name="user_id__eq"
          label={t('executor')}
          dependsOn="contragent_id__eq"
          acceptor={UserSelect}
          filters={{ roles__overlap: ['merch', 'supervisor', 'contragent', 'agency_contragent'] }}
          multi={false}
          clearable
        />
      )}
      <Form.Item
        name="task_type__eq"
        label={t('type of visit')}
        acceptor={TaskTypeSelect}
        clearable
      />
      <Form.Item
        name="network_id__in"
        label={t('network')}
        dependsOn="contragent_id__eq"
        acceptor={TradeNetworkSelect}
      />
      <Form.Item
        name="project_id__in"
        label={t('project')}
        dependsOn="contragent_id__eq"
        acceptor={TradepointProjectSelect}
      />
      <Form.Item
        name="branch_id__in"
        label={t('affiliate')}
        dependsOn="contragent_id__eq"
        acceptor={BranchSelect}
      />
      <Form.Item
        name="tradepoint_id__in"
        label={t('tradepoints')}
        dependsOn="contragent_id__eq branch_id__eq? network_id__eq? project_id__eq?"
        acceptor={TradepointSelect}
      />
      <Form.Item
        name="ir_code"
        label={t('extra code tt for ir')}
        leftIcon="filter"
        placeholder={t('search by extra code tt for ir')}
      />
      <Form.Item
        name="ir_type"
        label={t('type of visit for ir')}
        leftIcon="filter"
        placeholder={t('search by type of visit for ir')}
      />
      <Form.Item
        name="questionary_id__in"
        label={t('questionnaire')}
        dependsOn="contragent_id__eq"
        acceptor={ExtendedQuestionarySelect}
      />
      <Form.Item
        name="questionary_title__in"
        label={t('subtitle')}
        dependsOn="questionary_id__in"
        acceptor={QuestionaryTitleSelect}
      />
      <Form.Item
        name="question_id__in"
        label={t('question')}
        dependsOn="questionary_title__in"
        acceptor={QuestionSelect}
      />
    </ReduxForm>
  );
});

async function handleClickCreateTask() {
  utils.handleCreateBackgroundExportTaskAction(createTask());
}

export default React.memo(() => {
  const { t } = useTranslation();
  const onClickClear = utils.useDispatchCallback(clearFilters, []);
  const onClickCreateTask = React.useCallback(handleClickCreateTask, []);

  const fetching = RRU.useSelector('photoExport.fetching');
  const valid = RRU.useSelector('photoExport.filtersValid');

  return (
    <div className={styles.interactive}>
      <FiltersForm />
      <div className={styles.actions}>
        <Button
          onClick={onClickClear}
          minimal
          icon="filter-remove"
          text={t('clear')}
          intent={Intent.DANGER}
          className={styles.clearFiltersButton}
        />
        <Button
          onClick={onClickCreateTask}
          minimal
          disabled={fetching || !valid}
          text={t('download')}
          icon="download"
          rightIcon={fetching && <Spinner size={Spinner.SIZE_SMALL} />}
          intent={Intent.SUCCESS}
          className={styles.downloadButton}
        />
      </div>
    </div>
  );
});
