import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { extendStore } from 'react-redux-utils/immer';

import rootReducer from './reducers';
import * as selectors from './reducers';

const store = createStore(rootReducer, applyMiddleware(thunk));
store.selectors = selectors;
extendStore(store);

// У нас используется только один store в приложении
// поэтому можно сэкономить себе время и дёргать
// dispatch напрямую без привязок
// Не совсем совпадает с линией партии, но хак небольшой
// а копипасты экономит кучу
// NB: привязки значений никто не отменял
export const { dispatch, getState } = store;

export default store;
