import React from 'react';
import PropTypes from 'prop-types';

import { LocalizedDateInput } from 'app/widgets/LocalizedDateInput';
import { useCallbackWithDeps } from 'app/utils';

function handleValueChange({ onChange }, newValue) {
  onChange(newValue);
}

const DateInput = React.memo(({ onChange, intent, inputProps, ...props }) => {
  const onChangeWrapper = useCallbackWithDeps(handleValueChange, { onChange });

  return <LocalizedDateInput {...props} onChange={onChangeWrapper} inputProps={{ ...inputProps, intent }} />;
});

DateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  intent: PropTypes.string,
  inputProps: PropTypes.object,
};

DateInput.defaultProps = {
  intent: null,
  inputProps: null,
};

export default DateInput;
