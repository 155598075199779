import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DataTable from 'app/widgets/DataTable';
import { getColumn } from 'app/widgets/DataTable/utils';
import { kpiType } from 'app/proptyping';
import {
  setSort as setSortAC,
} from 'app/state/actionCreators/kpi';

const TeamTable = ({
  contragentId,
  kpi,
  sort,
  setSort,
  t
}) => {
  const getColumns = () => [
    {
      title: t('user'),
      field: 'user_fullname',
      width: 200,
      valueRenderer: (value, data) => (
        <Link
          to={(data.depth
              ? `/reports/kpi/contragent/${contragentId}/team/${data.user_id}`
              : `/reports/kpi/contragent/${contragentId}/user/${data.user_id}`
          )}
        >
          {value}
        </Link>
      ),
    },
    {
      title: t('date'),
      field: 'user_tasks_date',
      valueRenderer: (value) => (value ? moment(value).format('DD.MM.YYYY') : null),
    },
    { title: t('genitive scheduled visits'), field: 'total_scheduled_visits', width: 190 },
    { title: t('genitive actual visits'), field: 'processed_scheduled_visits', width: 190 },
    { title: t('genitive unscheduled visits'), field: 'processed_unscheduled_visits', width: 190 },
    { title: t('date'), field: 'total_alert_visits', width: 190 },
    { title: t('genitive alerts done'), field: 'processed_alert_visits', width: 190 },
    { title: t('genitive signals'), field: 'total_signals', width: 190 },
    { title: t('genitive signals done'), field: 'processed_signals', width: 190 },
  ].map(getColumn({ sort, setSort }));
  return (
    <DataTable
      columns={getColumns()}
      data={kpi}
      loadingRowCount={kpi.length}
    />
  )
};

TeamTable.propTypes = {
  contragentId: PropTypes.number,
  kpi: PropTypes.arrayOf(kpiType).isRequired,
  setSort: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

TeamTable.defaultProps = {
  contragentId: null,
};

const mapStateToProps = (state) => ({
  contragentId: state.kpi.contragentId,
  kpi: state.kpi.kpi,
  sort: state.kpi.sort,
});

const mapDispatchToProps = (dispatch) => ({
  setSort: ({ field, direction }) => dispatch(setSortAC({ field, direction })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamTable);
