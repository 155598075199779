import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import { makePlaceholderComponentType, classNames } from 'app/utils';
import store from 'app/state';

import Item from './Item';
import NumberInput from './NumberInput';
import DateInput from './DateInput';
import styles from './Form.module.css';

function reportValidChange({ onValidChange, errors }) {
  onValidChange?.(_.isEmpty(errors));
}

function setFieldError(setErrors, name, error) {
  if (error) {
    setErrors((errors) => ({ ...errors, [name]: error }));
  } else {
    setErrors((errors) => _.omit(errors, name));
  }
}

const ItemPlaceholder = makePlaceholderComponentType('FormItem');

function parseDependsOnItem(item) {
  let [filterKey, formKey] = item.split('=');

  const optional = filterKey.endsWith('?');
  if (optional) {
    filterKey = _.trim(filterKey, '?');
  }

  formKey = formKey ?? filterKey;

  return {
    filterKey,
    formKey,
    required: !optional,
  };
}

function dependsOnArray(dependsOn) {
  if (!dependsOn) {
    return [];
  }
  const parts = _.flatMap(dependsOn.split(','), (s) => s.trim().split(/\s+/));
  return parts.map(parseDependsOnItem);
}

export function getDependentItemProps({ value, childrenByName, dependsOn, filters, disabled }) {
  let extendedSubFilters = { ...filters };
  let allRequiredSubFiltersDefined = true;
  for (const subFilter of dependsOnArray(dependsOn)) {
    if (_.isNil(value?.[subFilter.formKey])) {
      allRequiredSubFiltersDefined &&= (!subFilter.required || !childrenByName[subFilter.formKey]);
    } else {
      extendedSubFilters[subFilter.filterKey] = value[subFilter.formKey];
    }
  }
  if (_.isEmpty(extendedSubFilters)) {
    extendedSubFilters = undefined;
  }

  return {
    filters: extendedSubFilters,
    fetchDisabled: !allRequiredSubFiltersDefined,
    disabled: disabled || !allRequiredSubFiltersDefined,
  };
}

function completeItem({ element, inline, errors, onErrorChange, childrenByName, props }) {
  if (!element || element.type !== ItemPlaceholder) {
    return element;
  }

  const { value, onFieldChange, ...commonProps } = props;
  const { name, dependsOn, filters, disabled } = element.props;
  const dependentItemProps = getDependentItemProps({ value, childrenByName, dependsOn, filters, disabled });
  return (
    <Item
      inline={!inline}
      value={value?.[name]}
      onFieldChange={onFieldChange}
      error={errors[name]}
      onErrorChange={onErrorChange}
      {...commonProps}
      {...element.props}
      {...dependentItemProps}
    />
  );
}

const Form = React.memo(({ className, inline, onValidChange, children, ...props }) => {
  const [errors, setErrors] = useState({});
  RRU.useEffectOptions(reportValidChange, { onValidChange, errors });
  const onErrorChange = RRU.useCallbackArgs(setFieldError, setErrors);
  const childrenByName = _.fromPairs(React.Children.toArray(children).map((c) => [c?.props.name, c]));

  return (
    <div className={classNames(className, inline && styles.inline)}>
      {React.Children.map(
        children,
        (element) => completeItem({ element, inline, errors, onErrorChange, childrenByName, props }),
      )}
    </div>
  );
});

Form.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,

  value: PropTypes.object,
  onFieldChange: PropTypes.func.isRequired,
  onValidChange: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Form.defaultProps = {
  className: null,
  inline: false,
  value: null,
  onValidChange: null,
};

Form.Item = ItemPlaceholder;
Form.NumberInput = NumberInput;
Form.DateInput = DateInput;

export default Form;

function checkFormChanged({ path, value }) {
  const initialValue = _.get(store.getState(), `${path}Initial`);
  store.setOne(`${path}Changed`, !_.isEqual(value, initialValue));
}

function setReduxField(path, key, value) {
  store.setOne(`${path}.${key}`, value);
}

function setReduxValid(path, valid) {
  store.setOne(`${path}Valid`, valid);
}

export const ReduxForm = React.memo(({ path, ...props }) => {
  const value = RRU.useSelector(path);

  RRU.useEffectOptions(checkFormChanged, { path, value });

  const onFieldChange = RRU.useCallbackArgs(setReduxField, path);
  const onValidChange = RRU.useCallbackArgs(setReduxValid, path);

  return <Form value={value} onFieldChange={onFieldChange} onValidChange={onValidChange} {...props} />;
});

ReduxForm.propTypes = {
  path: PropTypes.string.isRequired,
};
