import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import TaskResult from 'app/widgets/TaskResult';

import CheckForm from './CheckForm';
import styles from './UserTaskCheckScreen.module.css';

export default React.memo(() => {
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Page flexible title={t('a visit check')}>
      <div className={styles.container}>
        <Card className={styles.taskCard}>
          <TaskResult id={id} showRestoredTasksMark={false} />
        </Card>

        <Card className={styles.checkCard}>
          <CheckForm />
        </Card>
      </div>
    </Page>
  );
});
