import moment from 'moment';
import _ from 'lodash';

import { ISODate } from 'app/utils/date';

export function formatFilters({ contragentId, fields, filters }) {
  const formated = _.cloneDeep(filters);
  fields.forEach((f) => {
    if (f.filter_type === 'date' && filters[f.field]) {
      if (!filters[f.field][0] && !filters[f.field][1]) {
        formated[f.field] = undefined;
      }
      if (filters[f.field][0]) {
        formated[f.field][0] = ISODate(filters[f.field][0]);
      }
      if (filters[f.field][1]) {
        formated[f.field][1] = ISODate(moment(filters[f.field][1]).add(1, 'days'));
      }
    } else if (f.filter_type === 'number' && filters[f.field]) {
      if (
        !filters[f.field][0]
        && filters[f.field][0] !== 0
        && !filters[f.field][1]
        && filters[f.field][1] !== 0
      ) {
        formated[f.field] = undefined;
      }
    }
  });
  if (filters.user_tasks_date) {
    if (filters.user_tasks_date[0]) {
      formated.user_tasks_date[0] = ISODate(filters.user_tasks_date[0]);
    }
    if (filters.user_tasks_date[1]) {
      formated.user_tasks_date[1] = ISODate(moment(filters.user_tasks_date[1]).add(1, 'days').toDate());
    }
  }
  formated.contragent_id = contragentId;
  return formated;
}
