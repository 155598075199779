import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { H2, Breadcrumbs } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { dispatch } from 'app/state';
import { fetchUser, newUser } from 'app/state/actionCreators/teamUser';
import Page from 'app/widgets/Page';
import BreadcrumbRenderer from 'app/widgets/BreadcrumbRenderer';

import UserCard from './UserCard';
import styles from './UserEditScreen.module.css';

export default React.memo(() => {
  const { id } = useParams();
  const { t } = useTranslation();
  const isNew = id === 'new';
  const userId = isNew ? null : parseInt(id, 10);

  useEffect(() => {
    if (isNew) {
      dispatch(newUser());
    } else {
      dispatch(fetchUser(userId));
    }
  }, [userId, isNew]);

  const user = useSelector((state) => state.teamUser.user);
  const breadcrumbItems = isNew
    ? [
      { text: t('team'), href: '/team' },
      { text: t('new user') },
    ]
    : [
      { text: t('team'), href: '/team' },
      { text: user?.fullname || user?.phone || user?.email || t('user'), href: `/user/${userId}` },
      { text: t('editing') },
    ];

  return (
    <Page>
      <div className={styles.header}>
        <H2>{ isNew ? t('creating user') : t('editing user') }</H2>
      </div>
      <Breadcrumbs items={breadcrumbItems} breadcrumbRenderer={BreadcrumbRenderer} />
      <UserCard />
    </Page>
  );
});
