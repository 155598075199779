import React from 'react';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import Page from 'app/widgets/Page';
import * as selectors from 'app/state/reducers';
import SingleColumnReference from 'app/widgets/SingleColumnReference';

export default
function BranchesListScreen() {
  const { t } = useTranslation();
  const canEdit = RRU.useSelector(selectors.canEditBranches);

  return (
    <Page title={t('branches')}>
      <SingleColumnReference canEdit={canEdit} path="/branches" />
    </Page>
  );
}
