import React from 'react';
import { TileLayer} from 'react-leaflet';
import L from 'leaflet';

import defaultIconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import defaultIconUrl from 'leaflet/dist/images/marker-icon.png';
import defaultShadowUrl from 'leaflet/dist/images/marker-shadow.png';

import startIconRetinaUrl from 'app/assets/icons/marker-start-icon-2x.png';
import startIconUrl from 'app/assets/icons/marker-start-icon.png';
import endIconRetinaUrl from 'app/assets/icons/marker-end-icon-2x.png';
import endIconUrl from 'app/assets/icons/marker-end-icon.png';
import taskIconRetinaUrl from 'app/assets/icons/marker-task-icon-2x.png';
import taskIconUrl from 'app/assets/icons/marker-task-icon.png';

export const defaultIcon = L.icon({
  iconRetinaUrl: defaultIconRetinaUrl,
  iconUrl: defaultIconUrl,
  shadowUrl: defaultShadowUrl,
  iconAnchor: [12.5, 41],
  iconSize: new L.Point(25, 41),
});
L.Marker.prototype.options.icon = defaultIcon;

export const startIcon = new L.Icon({
  iconRetinaUrl: startIconRetinaUrl,
  iconUrl: startIconUrl,
  shadowUrl: defaultShadowUrl,
  iconAnchor: [12.5, 41],
  iconSize: new L.Point(25, 41),
});

export const endIcon = new L.Icon({
  iconRetinaUrl: endIconRetinaUrl,
  iconUrl: endIconUrl,
  shadowUrl: defaultShadowUrl,
  iconAnchor: [12.5, 41],
  iconSize: new L.Point(25, 41),
});

export const taskIcon = new L.Icon({
  iconRetinaUrl: taskIconRetinaUrl,
  iconUrl: taskIconUrl,
  shadowUrl: defaultShadowUrl,
  iconAnchor: [12.5, 41],
  iconSize: new L.Point(25, 41),
});

const ATTRIBUTION_2GIS =
  `<a
    href="https://dev.2gis.ru/?utm_source=copyright&amp;utm_medium=map&amp;utm_campaign=partners"
    target="_blank"
    rel="noreferrer"
    class="dg-attribution__link"
    dir="ltr"
  >
    <span id="selection_index15" class="selection_index"></span>
    2GIS
  </a>`;

/* osm:
  <TileLayer
    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  />
*/
export const TILE_LAYER = (
  <TileLayer
    attribution={ATTRIBUTION_2GIS}
    url="http://tile2.maps.2gis.com/tiles?x={x}&y={y}&z={z}"
  />
);
