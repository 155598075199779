import React, { useEffect } from 'react';
import { Button, Classes, Dialog, Intent, Switch, TextArea } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import * as questionary from 'app/state/actionCreators/questionary';
import * as config from 'app/config';
import { dispatch } from 'app/state';
import * as selects from 'app/widgets/ReferenceSelect';
import { LocalizedISODateInput } from 'app/widgets/LocalizedDateInput';
import FancySelect from 'app/widgets/FancySelect';
import Form, { ReduxForm } from 'app/widgets/Form';

import { contragentSettingSelector } from './common';
import useDefaultAnswerInput from './defaultAnswer';
import VariantsInput from './VariantsInput';
import styles from './QuestionaryEditScreen.module.css';

const MAX_REQUIRED_PHOTOS = 10;

const MIN_TYPES = {
  PERCENT: 0,
  NUMBER: 0,
  INTEGER: 0,
  PRICE: 1,
};

const MAX_TYPES = {
  PERCENT: 100,
  NUMBER: 1000,
  INTEGER: 1000,
  PRICE: 100000,
};

const QuestionEditor = React.memo(() => {
  const { t } = useTranslation();
  const question = utils.useSelectorMemo((state) => state.questionary.editQuestion);
  const valid = utils.useSelectorMemo((state) => state.questionary.editQuestionValid);
  const changed = utils.useSelectorMemo((state) => state.questionary.editQuestionChanged);
  const contragentId = utils.useSelectorMemo((state) => state.questionary.questionary.contragent_id);
  const showAstixSelects = utils.useSelectorMemo(contragentSettingSelector('reports_contragent_astix_send'));
  const defaultAnswerProps = useDefaultAnswerInput(question);
  const onClose = utils.useDispatchCallback(questionary.resetEditQuestion);
  const onClickSave = utils.useDispatchCallback(questionary.saveQuestion);
  const QUESTION_TYPE_ITEMS = _.toPairs(config.QUESTION_TYPES).map(([id, label]) => ({ id, label: t(label) }));

  useEffect(() => {
    dispatch(questionary.setEditQuestionField({
      planogram_ids: question.planograms.map((x) => x.id),
      planogram_forced: _.some(question?.planograms, ['forced', true]),
    }));
  }, []);

  const validateText = (value) => {
    if ((value?.length ?? 0) < 5) {
      return t('minimum 5 characters');
    }
    if ((value?.length ?? 0) > 500) {
      return t('max500Characters');
    }
    return true;
  };

  return (
    <Dialog
      icon="edit"
      title={!question.id ? t('new question') : t('edit question')}
      isOpen
      canOutsideClickClose={!changed}
      onClose={onClose}
    >
      <ReduxForm className={utils.classNames(Classes.DIALOG_BODY, styles.questionForm)} path="questionary.editQuestion">
        <Form.Item
          label={t('text of question')}
          name="text"
          acceptor={TextArea}
          validator={validateText}
          placeholder={`${t('enter question text')}...`}
        />

        {question.field_type !== 'PHOTO' && (
          <Form.Item
            label={t('mandatory question')}
            name="required"
            acceptor={Switch}
          />
        )}

        <Form.Item
          label={`${t('category')} ${t('product')} `}
          name="product_category_ids"
          acceptor={selects.ProductCategorySelect}
          filters={{ contragent_id__eq: contragentId }}
        />

        {showAstixSelects && (
          <Form.Item
            label={`${t('activity type')} Astix `}
            name="activity_type_id"
            acceptor={selects.AstixActivitySelect}
            multi={false}
            clearable
          />
        )}

        <Form.Item
          label={t('planograms')}
          name="planogram_ids"
          acceptor={selects.PlanogramSelect}
          multi={true}
          clearable
          filters={{ contragent_id__eq: contragentId }}
        />

        <Form.Item
          label={t('force display planograms')}
          name="planogram_forced"
          acceptor={Switch}
        />

        <Form.Item
          label={t('date from second')}
          name="start_time"
          acceptor={LocalizedISODateInput}
        />

        <Form.Item
          label={t('date by')}
          name="end_time"
          acceptor={LocalizedISODateInput}
        />

        <Form.Item
          label={t('answer type')}
          name="field_type"
          acceptor={FancySelect}
          data={QUESTION_TYPE_ITEMS}
          multi={false}
        />

        {config.isNumericType(question.field_type) && (
          <Form.Item
            label={t('min value')}
            name="min_value"
            acceptor={Form.NumberInput}
            placeholder={t('enter the number')}
            min={MIN_TYPES[question.field_type]}
            max={MAX_TYPES[question.field_type]}
          />
        )}
        {config.isNumericType(question.field_type) && (
          <Form.Item
            label={t('max value')}
            name="max_value"
            acceptor={Form.NumberInput}
            placeholder={t('enter the number')}
            min={question.min ?? MIN_TYPES[question.field_type]}
            max={MAX_TYPES[question.field_type]}
          />
        )}

        {config.isSelectType(question.field_type) && (
          <Form.Item
            label={t('answer options')}
            name="variants"
            className={styles.variantsFormItem}
            required={['SELECT', 'MULTICOMBO'].includes(question.field_type)}
            acceptor={VariantsInput}
          />
        )}

        {!config.isTypeWithoutDefault(question.field_type) && (
          <Form.Item
            label={t('default value')}
            name="has_default"
            acceptor={Switch}
          />
        )}

        {defaultAnswerProps && (
          <Form.Item
            label={t('default value')}
            name="default_answer"
            disabled={!question.has_default}
            required={question.has_default}
            {...defaultAnswerProps}
          />
        )}

        <Form.Item
          label={t('mandatory photo')}
          name="photo_required"
          acceptor={Switch}
        />

        <Form.Item
          label={t('number of photos')}
          name="photo_count"
          required={question.field_type === 'PHOTO' || question.photo_required}
          acceptor={Form.NumberInput}
          placeholder={t('enter the number')}
          min={question.field_type === 'PHOTO' || question.photo_required ? 1 : 0}
          max={MAX_REQUIRED_PHOTOS}
        />

        <Form.Item
          label={t('forbidPhotoRemoval')}
          name="preserve_photos"
          acceptor={Switch}
        />
      </ReduxForm>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={t('cancel')} onClick={onClose} />
          <Button text={t('save')} disabled={!valid} intent={Intent.PRIMARY} onClick={onClickSave} />
        </div>
      </div>
    </Dialog>
  );
});

export default QuestionEditor;
