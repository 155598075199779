import FeedScreen from '../containers/FeedScreen';
import LoginScreen from '../containers/LoginScreen';
import OpenIDLoginScreen from '../containers/OpenIDLoginScreen';

import RestoredTaskEditorScreen from '../containers/RestoredTaskEditorScreen';
import TeamScreen from '../containers/TeamScreen';
import UserScreen from '../containers/UserScreen';
import UserEditScreen from '../containers/UserEditScreen';
import TasksScreen from '../containers/TasksScreen';
import UserTodayTasksScreen from '../containers/TasksScreen/UserTodayTasksScreen';
import TradepointScreen from '../containers/TradepointScreen';
import TradepointEditScreen from '../containers/TradepointEditScreen';
import PasswordRecoveryScreen from '../containers/PasswordRecoveryScreen';
import QuestionariesListScreen from '../containers/QuestionariesListScreen';
import QuestionaryBindingScreen from '../containers/QuestionaryBindingScreen';
import QuestionaryMultiBindingScreen from '../containers/QuestionaryMultiBindingScreen';
import QuestionaryEditScreen from '../containers/QuestionaryEditScreen';
import ScheduleScreen from '../containers/ScheduleScreen';
import ReportDynamicScreen from '../containers/ReportDynamicScreen';
import BackgroundExportTasksScreen from '../containers/BackgroundExportTasksScreen';
import TaskResultScreen from '../containers/TaskResultScreen';
import TaskGroupResultScreen from '../containers/TaskGroupResultScreen';
import TradepointListScreen from '../containers/TradepointListScreen';
import ImportTasksScreen from '../containers/ImportTasksScreen';
import ImportTaskScreen from '../containers/ImportTaskScreen';
import ReportBiScreen from '../containers/ReportBiScreen';
import ReportJtiScreen from '../containers/ReportJtiScreen';
import ReportDiageoScreen from '../containers/ReportDiageoScreen';
import ReportDiageoPilotScreen from '../containers/ReportDiageoPilotScreen';
import PhotoExportScreen from '../containers/PhotoExportScreen';
import ContragentListScreen from '../containers/ContragentListScreen';
import ContragentEditScreen from '../containers/ContragentEditScreen';
import KpiScreen from '../containers/KpiScreen';
import EventsScreen from '../containers/EventsScreen';
import NewTradepointEventsScreen from '../containers/EventsScreen/NewTradepointEventsScreen';
import FeedbackScreen from '../containers/FeedbackScreen';
import ProductGroupListScreen from '../containers/ProductGroupListScreen';
import PlanogramListScreen from '../containers/PlanogramListScreen';
import PlanogramEditScreen from '../containers/PlanogramEditScreen';
import KladrEditScreen from '../containers/KladrEditScreen';
import UserTaskCheckScreen from '../containers/UserTaskCheckScreen';
import UserTaskChecksScreen from '../containers/UserTaskChecksScreen';
import UserTaskCheckQuestionsScreen from '../containers/UserTaskCheckQuestionsScreen';
import ReportUserTaskChecksScreen from '../containers/ReportUserTaskChecksScreen';
import ReportEquipmentScreen from '../containers/ReportEquipmentScreen';
import ReportWorkingTime from '../containers/ReportWorkingTime';
import ReportTerritoryWorkingTime from '../containers/ReportTerritoryWorkingTime';
import ReportRescheduleReasons from '../containers/ReportRescheduleReasons';
import ReportJtiEquipmentIdentification from '../containers/ReportJtiEquipmentIdentification';
import VisitProblemsListScreen from '../containers/VisitProblemsListScreen';
import ProductProblemsListScreen from '../containers/ProductProblemsListScreen';
import QuestionaryCategoriesListScreen from '../containers/QuestionaryCategoriesListScreen';
import RescheduleReasonsListScreen from '../containers/RescheduleReasonsListScreen';
import FixingIntelligenceRetailScreen from '../containers/FixingIntelligenceRetailScreen';
import TradeNetworksListScreen from '../containers/TradeNetworksListScreen';
import EquipmentListScreen from '../containers/EquipmentListScreen';
import BranchesListScreen from '../containers/BranchesListScreen';
import GeographyTerritoryListScreen from '../containers/GeographyTerritoryListScreen';
import GeographyFormatsListScreen from '../containers/GeographyFormatsListScreen';
import GeographyProjectsListScreen from '../containers/GeographyProjectsListScreen';
import ProductBrandsListScreen from '../containers/ProductBrandsListScreen';
import ProductCategoriesListScreen from '../containers/ProductCategoriesListScreen';
import ProductsListScreen from '../containers/ProductsListScreen';
import QuestionaryBindingTradepointsScreen from '../containers/QuestionaryBindingTradepointsScreen';

const routes = [
  {
    // Лента
    path: '/feed',
    exact: true,
    name: 'feed',
    icon: 'pulse',
    component: FeedScreen,
  },
  {
    // Создание заполненного визита под КМ
    path: '/history/create',
    exact: true,
    component: RestoredTaskEditorScreen,
    requiredRight: 'webapp_restored_tasks_editor',
  },
  {
    // Редактирование визита под КМ
    path: '/tasks/:id/edit',
    exact: true,
    component: RestoredTaskEditorScreen,
    requiredRight: 'webapp_restored_tasks_editor',
  },
  {
    // Копирование визита под КМ
    path: '/tasks/:copyId/copy',
    exact: true,
    component: RestoredTaskEditorScreen,
    requiredRight: 'webapp_restored_tasks_editor',
  },
  {
    // Проверка визита под ЧМ
    path: '/tasks/:id/check',
    exact: true,
    component: UserTaskCheckScreen,
    requiredRight: 'webapp_user_task_checks',
  },
  {
    // Команда
    path: '/team',
    name: 'team',
    icon: 'people',
    component: TeamScreen,
    requiredRight: 'webapp_users',
  },
  {
    // Просмотр пользователя
    path: '/user/:id',
    exact: true,
    component: UserScreen,
    requiredRight: 'webapp_users',
  },
  {
    // Редактирование пользователя
    path: '/user/:id/edit',
    exact: true,
    component: UserEditScreen,
    requiredRight: 'webapp_users_edit',
  },
  {
    // Маршруты пользователя на сегодня
    path: '/tasks/user/:id/today',
    exact: true,
    component: UserTodayTasksScreen,
    requiredRight: 'webapp_schedule',
  },
  {
    // Задани
    path: '/events',
    exact: true,
    name: 'events pinning',
    icon: 'notifications',
    component: EventsScreen,
    requiredRight: 'webapp_events',
  },
  {
    // Новые задания в ТТ
    path: '/events/tradepoint/:id',
    exact: true,
    icon: 'notifications',
    component: NewTradepointEventsScreen,
    requiredRight: 'webapp_events',
  },
  {
    // Торговая точка
    path: '/tradepoint/:id',
    component: TradepointScreen,
    exact: true,
    requiredRight: 'webapp_tradepoints',
  },
  {
    // Торговая точка
    path: '/tradepoint/:id/edit',
    component: TradepointEditScreen,
    exact: true,
    requiredRight: 'webapp_tradepoints',
  },
  {
    // КЛАДР
    path: '/kladr/:id/edit',
    component: KladrEditScreen,
    exact: true,
    requiredRight: 'webapp_kladr_edit',
  },
  {
    // Опросники
    path: '/questionaries',
    exact: true,
    name: 'questionnaires',
    icon: 'manually-entered-data',
    component: QuestionariesListScreen,
    requiredRight: 'webapp_quests',
  },
  {
    // Назначение опросников
    path: '/questionaries/binding',
    exact: true,
    name: 'shop pinning',
    icon: 'add-to-artifact',
    component: QuestionaryBindingScreen,
    requiredRight: 'webapp_quests',
  },
  {
    // Множественное назначение опросников
    path: '/questionaries/binding/multi',
    exact: true,
    name: 'questionaries pinning',
    icon: 'filter-list',
    component: QuestionaryMultiBindingScreen,
    requiredRight: 'webapp_quests',
  },
  {
    // Множественное назначение опросников
    path: '/questionaries/binding/tradepoints',
    exact: true,
    name: 'multiple binding',
    icon: 'filter-list',
    component: QuestionaryBindingTradepointsScreen,
    requiredRight: 'webapp_quests',
  },
  {
    // Редактировать анкету
    path: '/questionary/:id',
    component: QuestionaryEditScreen,
    requiredRight: 'webapp_quests',
  },

  {
    // Маршруты
    path: '/tasks',
    exact: true,
    name: 'routes',
    icon: 'flag',
    component: TasksScreen,
    requiredRight: 'webapp_routes',
  },
  {
    // График
    path: '/schedule',
    name: 'visiting schedule',
    icon: 'calendar',
    component: ScheduleScreen,
    requiredRight: 'webapp_schedule',
  },
  {
    // Проверки
    path: '/user-task-checks',
    name: 'visit check',
    icon: 'form',
    component: UserTaskChecksScreen,
    requiredRight: 'webapp_user_task_checks',
  },

  {
    // Импорты
    path: '/import-tasks',
    name: 'imports',
    icon: 'upload',
    component: ImportTasksScreen,
    exact: true,
    requiredRight: 'webapp_imports',
  },

  {
    // Импорт
    path: '/import-tasks/:id',
    component: ImportTaskScreen,
    exact: true,
    requiredRight: 'webapp_imports',
  },

  {
    // Точки
    path: '/tradepoints',
    name: 'tradepoints',
    icon: 'shop',
    component: TradepointListScreen,
    requiredRight: 'webapp_tradepoints',
  },
  {
    // Контрагенты
    path: '/contragents',
    name: 'contractors',
    icon: 'briefcase',
    component: ContragentListScreen,
    requiredRight: 'webapp_contragents',
    exact: true,
  },
  {
    // Нстройки Контрагента
    path: '/contragents/:id',
    component: ContragentEditScreen,
    requiredRight: 'webapp_contragents_edit',
    exact: true,
  },
  {
    // Группы товаров
    exact: true,
    path: '/product-groups',
    name: 'product groups',
    icon: 'new-grid-item',
    component: ProductGroupListScreen,
    requiredRight: 'webapp_products',
  },
  {
    // Планограммы
    exact: true,
    path: '/planograms',
    name: 'planograms',
    icon: 'manual',
    component: PlanogramListScreen,
    requiredRight: 'webapp_planogram_edit',
  },
  {
    // Планограмма
    exact: true,
    path: '/planograms/:id',
    component: PlanogramEditScreen,
    requiredRight: 'webapp_planogram_edit',
  },
  {
    exact: true,
    path: '/visit-problems',
    name: 'visit problems',
    icon: 'warning-sign',
    component: VisitProblemsListScreen,
    requiredRight: 'webapp_visit_problems',
  },
  {
    exact: true,
    path: '/product-problems',
    name: 'product problems',
    icon: 'warning-sign',
    component: ProductProblemsListScreen,
    requiredRight: 'webapp_product_problems',
  },
  {
    exact: true,
    path: '/questionary-categories',
    name: 'questionaryCategories',
    icon: 'warning-sign',
    component: QuestionaryCategoriesListScreen,
    requiredRight: 'webapp_questionary_categories',
  },
  {
    exact: true,
    path: '/reschedule-reasons',
    name: 'rescheduleReasons',
    icon: 'outdated',
    component: RescheduleReasonsListScreen,
    requiredRight: 'webapp_reschedule_reasons',
  },
  {
    // Вопросы для проверки
    path: '/user-task-check-questions',
    name: 'questions to check visits',
    icon: 'form',
    component: UserTaskCheckQuestionsScreen,
    requiredRight: 'webapp_user_task_check_questions',
  },
  {
    // География - Филиалы
    path: '/geography/branches',
    name: 'branches',
    icon: 'globe',
    component: BranchesListScreen,
    requiredRight: 'webapp_branches',
  },
  {
    // География - Форматы
    path: '/geography/formats',
    name: 'formats',
    icon: 'globe',
    component: GeographyFormatsListScreen,
    requiredRight: 'webapp_tradepoint_formats',
  },
  {
    // География - Доп. проекты
    path: '/geography/projects',
    name: 'tradepointProjects',
    icon: 'globe',
    component: GeographyProjectsListScreen,
    requiredRight: 'webapp_tradepoint_projects',
  },
  {
    // География - Территории
    path: '/geography/territories',
    name: 'territories',
    icon: 'globe',
    component: GeographyTerritoryListScreen,
    requiredRight: 'webapp_tradepoint_territories',
  },
  {
    // Товары
    path: '/products',
    name: 'products',
    icon: 'cube',
    component: ProductsListScreen,
    requiredRight: 'webapp_products',
  },
  {
    // Бренды
    path: '/product/brands',
    name: 'product brands',
    icon: 'star-empty',
    component: ProductBrandsListScreen,
    requiredRight: 'webapp_product_brands',
  },
  {
    // Категории товаров
    path: '/product/categories',
    name: 'product categories',
    icon: 'list-detail-view',
    component: ProductCategoriesListScreen,
    requiredRight: 'webapp_product_categories',
  },
  {
    // Закрепление IR
    exact: true,
    path: '/ir-tradepoint',
    name: 'fixing ir',
    icon: 'pin',
    component: FixingIntelligenceRetailScreen,
    requiredRight: 'webapp_ir',
  },

  {
    // Отчет BI
    path: '/reports/bi',
    name: 'bi report',
    icon: 'th-filtered',
    component: ReportBiScreen,
    requiredRight: 'webapp_reports',
  },
  {
    // Отчет JTI
    path: '/reports/jti',
    name: 'jti report',
    icon: 'th-filtered',
    component: ReportJtiScreen,
    requiredRight: 'webapp_reports_jti',
  },
  {
    // Отчет Diageo
    path: '/reports/diageo',
    name: 'diageo report',
    icon: 'th-filtered',
    component: ReportDiageoScreen,
    requiredRight: 'webapp_reports_diageo',
  },
  {
    // Отчет Diageo
    path: '/reports/diageo-pilot',
    name: 'diageo-pilot report',
    icon: 'th-filtered',
    component: ReportDiageoPilotScreen,
    requiredRight: 'webapp_reports_diageo_pilot',
  },
  {
    // Отчет по визитам
    path: '/reports/visits',
    name: 'visit report',
    icon: 'th-filtered',
    component: ReportDynamicScreen,
    requiredRight: 'webapp_reports_visits',
  },
  {
    // Отчет по алертам
    path: '/reports/signals',
    name: 'alert report',
    icon: 'th-filtered',
    component: ReportDynamicScreen,
    requiredRight: 'webapp_reports',
  },
  {
    // Отчет по опросникам
    path: '/reports/questionaries',
    name: 'questionnaire report',
    icon: 'th-filtered',
    component: ReportDynamicScreen,
    requiredRight: 'webapp_reports',
  },
  {
    // Очередь выгрузки отчётов
    path: '/background-export-tasks',
    name: 'exportsQueue',
    icon: 'export',
    component: BackgroundExportTasksScreen,
    requiredRight: ['webapp_reports', 'webapp_reports_visits', 'webapp_export_photo'],
  },
  {
    // Отчет по провервкам визитов
    path: '/reports/user-task-checks',
    name: 'visit inspection report',
    icon: 'form',
    component: ReportUserTaskChecksScreen,
    requiredRight: 'webapp_reports_user_task_checks',
  },
  {
    // Отчет по оборудованию
    path: '/reports/equipment',
    name: 'equipment report',
    icon: 'oil-field',
    component: ReportEquipmentScreen,
    requiredRight: 'webapp_reports',
  },
  {
    // Отчет по времени
    path: '/reports/working-time',
    name: 'workingTimeReport',
    icon: 'history',
    component: ReportWorkingTime,
    requiredRight: 'webapp_reports',
  },
  {
    // Отчет по времени
    path: '/reports/territory-working-time',
    name: 'territoryWorkingTimeReport',
    icon: 'history',
    component: ReportTerritoryWorkingTime,
    requiredRight: 'webapp_reports',
  },
  {
    path: '/reports/reschedule-reasons',
    name: 'rescheduleReasonsReport',
    icon: 'outdated',
    component: ReportRescheduleReasons,
    requiredRight: 'webapp_reports',
  },
  {
    path: '/reports/jti-equipment-identification',
    name: 'jtiEquipmentIdentificationReport',
    icon: 'oil-field',
    component: ReportJtiEquipmentIdentification,
    requiredRight: 'webapp_reports_jti',
  },
  {
    // KPI по команде
    path: '/reports/kpi',
    name: 'kpi by team',
    icon: 'th-filtered',
    component: KpiScreen,
    requiredRight: 'kpi_view',
    exact: true,
  },
  {
    // KPI по команде
    path: '/reports/kpi/contragent/:contragentId/team/:id',
    name: 'kpi team',
    icon: 'th-filtered',
    component: KpiScreen,
    requiredRight: 'kpi_view',
  },
  {
    // KPI пользователя
    path: '/reports/kpi/contragent/:contragentId/user/:id',
    name: 'kpi user',
    icon: 'th-filtered',
    component: KpiScreen,
    requiredRight: 'kpi_view',
  },
  {
    // Выгрузка фотографий
    path: '/reports/photos',
    name: 'download photos',
    icon: 'media',
    component: PhotoExportScreen,
    requiredRight: 'webapp_export_photo',
  },
  {
    // Торговые сети
    path: '/geography/networks',
    name: 'tradeNetworks',
    icon: 'filter-list',
    component: TradeNetworksListScreen,
    requiredRight: 'webapp_trade_networks',
  },
  {
    // Оборудование
    path: '/equipment',
    name: 'equipment',
    icon: 'oil-field',
    component: EquipmentListScreen,
    requiredRight: 'webapp_equipments',
  },
  {
    // Вход
    path: '/login',
    exact: true,
    component: LoginScreen,
  },
  {
    // Вход
    path: '/login/oauth',
    component: OpenIDLoginScreen,
  },
  {
    // Восстановить пароль
    path: '/recovery',
    component: PasswordRecoveryScreen,
  },
  {
    // Сообщить об ошибке
    path: '/feedback',
    name: 'report a bug',
    icon: 'envelope',
    component: FeedbackScreen,
  },
  {
    // Главная
    path: '/',
    exact: true,
    redirect: '/feed',
  },
  {
    path: '/tasks/:id/result',
    name: 'visit result',
    icon: null,
    component: TaskResultScreen,
  },
  {
    path: '/task-groups/:id/result',
    name: 'visit result',
    icon: null,
    component: TaskGroupResultScreen,
  },
];

export default routes;
