import React from 'react';
import PropTypes from 'prop-types';
import { Classes, Button, FormGroup, Dialog, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import actions from 'app/state/directActions';
import { QuestionarySelect } from 'app/widgets/ReferenceSelect';

import styles from './QuestionaryBindingScreen.module.css';

function handleClickBind({ tradepoint, questionaryIds, onClose }) {
  actions.questionaryTradepointRefs.toggleBindings({
    tradepointId: tradepoint.id,
    questionaryIds,
    bound: false,
  });

  onClose();
}

export default
function BindingDialog({ tradepoint, onClose }) {
  const { t } = useTranslation();
  const [questionaryIds, setQuestionaryIds] = React.useState(null);
  const onClickBind = RRU.useCallbackOptions(handleClickBind, { tradepoint, questionaryIds, onClose });
  const changed = questionaryIds !== null;

  return (
    <Dialog
      icon="edit"
      title={t('pin questionnaires')}
      isOpen
      onClose={onClose}
      enforceFocus={false}
      canOutsideClickClose={!changed}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label={`${t('questionnaires')}:`} inline className={styles.fieldTitle}>
          <QuestionarySelect
            fill
            inline={false}
            value={questionaryIds}
            onChange={setQuestionaryIds}
            filters={{ contragent_id__eq: tradepoint.contragent_id }}
          />
        </FormGroup>

        <p>{t('tradepoint')}: {tradepoint.network} {tradepoint.address} {tradepoint.code}</p>

        <p>{t('contractor')}: {tradepoint.contragent}</p>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={t('cancel')} onClick={onClose} />

          <Button
            text={t('pin')}
            disabled={!questionaryIds?.length}
            intent={Intent.PRIMARY}
            onClick={onClickBind}
          />
        </div>
      </div>
    </Dialog>
  );
}

BindingDialog.propTypes = {
  tradepoint: PropTypes.shape({
    network: PropTypes.string,
    address: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    contragent_id: PropTypes.number.isRequired,
    contragent: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
