import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { H2, Button, Breadcrumbs, Intent } from '@blueprintjs/core';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { dispatch } from 'app/state';
import * as selectors from 'app/state/reducers';
import { fetchUser } from 'app/state/actionCreators/teamUser';
import { fetchRoles } from 'app/state/actionCreators/roles';
import { logoutTeamUser } from 'app/api';
import { useCallbackWithDeps } from 'app/utils';
import Page from 'app/widgets/Page';
import BreadcrumbRenderer from 'app/widgets/BreadcrumbRenderer';
import { showSuccess, showError } from 'app/widgets/toaster';

import UserCard from './UserCard';
import styles from './UserScreen.module.css';

export default React.memo(() => {
  const { id } = useParams();
  const { t } = useTranslation();
  const userId = parseInt(id, 10);
  useEffect(() => { dispatch(fetchUser(userId)); }, [userId]);
  useEffect(() => { dispatch(fetchRoles()); }, []);

  const isCurrentUser = useSelector((state) => selectors.getUserId(state) === userId);
  const user = useSelector((state) => state.teamUser.user);
  const canEdit = useSelector(selectors.canEditTeamUser);
  const error = useSelector((state) => state.teamUser.error);
  const fetching = useSelector((state) => state.teamUser.fetching || state.roles.fetching);
  const readyForShow = !fetching && !error;

  const isSomeAdmin = useSelector(selectors.isAdminOrAgencyAdmin);
  const canLogout = useSelector((state) => selectors.hasRole(
    state,
    'admin', 'agency_admin', 'merch_director', 'regional_manager', 'teamleader', 'supervisor',
  ));
  const [logginOut, setLogginOut] = useState(false);

  const logout = async ({ userId, setLogginOut }) => {
    setLogginOut(true);
    try {
      await logoutTeamUser(userId);
      showSuccess(t('sessions removed'));
    } catch (error) {
      showError(error.message || t('error'));
    }
    setLogginOut(false);
  };

  const onLogoutClick = useCallbackWithDeps(logout, { userId, setLogginOut });

  const breadcrumbItems = [
    { text: t('team'), href: '/team' },
    { text: user?.fullname || user?.phone || user?.email || '-' },
  ];

  return (
    <Page>
      <div className={styles.header}>
        <H2>{t('view user')}</H2>
        {readyForShow && canEdit && (!isCurrentUser || isSomeAdmin) && (
          <Link to={`/user/${userId}/edit`}>
            <Button icon="edit" intent={Intent.PRIMARY} text={t('edit')} />
          </Link>
        )}
        {readyForShow && canEdit && isCurrentUser && !isSomeAdmin && (
          <Button
            icon="edit"
            intent={Intent.PRIMARY}
            text={t('edit')}
            disabled
            title={t('not allow to change your data in the card')}
          />
        )}
        {readyForShow && canLogout && (
          <Button
            icon="log-out"
            intent={Intent.DANGER}
            loading={logginOut}
            onClick={onLogoutClick}
            text={t('sign out from all devices')}
          />
        )}
      </div>
      <Breadcrumbs items={breadcrumbItems} breadcrumbRenderer={BreadcrumbRenderer} />
      <UserCard />
    </Page>
  );
});
