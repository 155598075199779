import { makeEmptyReducer } from './utils';

export const INITIAL_STATE = {
  questionary: {},
  questionaryValid: false,
  fetching: false,
  error: null,

  editQuestion: null,
  editQuestionValid: false,
  questions: {},
  groups: [],
};

export default makeEmptyReducer(INITIAL_STATE);
