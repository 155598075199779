import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import { getState } from 'app/state';
import * as selectors from 'app/state/reducers';
import { getPhotoTimeStr } from 'app/state/reducers/task';
import Lightbox from 'app/widgets/Lightbox';
import PhotoPreview from 'app/widgets/Photos/PhotoPreview';
import ImageDescription from 'app/widgets/ImageDescription';
import NoCamera from 'app/assets/no-camera.svg';

import styles from './TaskResult.module.css';

function renderImage(idx, image, toggleLightbox, t) {
  const canViewFakePhoto = selectors.canViewFakePhoto(getState());

  return (
    <span key={image.id} className={styles.resultCardPhotoWrapper}>
      <PhotoPreview
        className={styles.resultPhoto}
        src={image.thumbnail}
        alt={image.title}
        onClick={() => toggleLightbox(idx)}
      />
      {canViewFakePhoto && image.isFake && (
        <Icon
          htmlTitle={t('image changed')}
          icon="error"
          intent={Intent.WARNING}
          className={styles.resultCardPhotoWarning}
        />
      )}
    </span>
  );
}

function makeLightboxPhoto({ task, photo, hideCreatePhotoTime, t }) {
  const title = _.compact([
    photo.title,
    photo.moment === 'before' && `[${t('photo before')}]`,
    photo.moment === 'after' && `[${t('photo after')}]`,
  ]).join(' ');

  return {
    id: photo.id,
    src: photo.preview ? photo.original : NoCamera,
    title,
    description: {
      address: task.data.shop.info.address,
      network: task.data.shop.info.network,
      date: getPhotoTimeStr({ photo, hideTime: hideCreatePhotoTime }),
      coordinates: photo.coordinates || task.startLocation,
    },
    thumbnail: photo.preview || NoCamera,
    isFake: photo.is_fake,
  };
}

const Photos = React.memo(function Photos({ uuids }) {
  const { t } = useTranslation();
  const task = utils.useSelectorMemo('task.data');
  const filteredPhotos = uuids
    ? _.compact(uuids.map((uuid) => task.photos.find((p) => p.id === uuid)))
    : task.photos;
  const hideCreatePhotoTime = utils.useSelectorMemo((state) => selectors.hasRight(
    state,
    'webapp_contragent_hide_create_photo_time',
  ));

  const photos = filteredPhotos.map((photo) => makeLightboxPhoto({ task, photo, hideCreatePhotoTime, t }));

  return (
    <div className={styles.resultPhotos}>
      <Lightbox
        thumbnailWidth="150px"
        thumbnailHeight="150px"
        images={photos}
        onErrorSrc={NoCamera}
        renderImageFunc={(idx, image, toggle) => renderImage(idx, image, toggle, t)}
        renderDescriptionFunc={ImageDescription}
      />
    </div>
  );
});

Photos.propTypes = {
  uuids: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default Photos;
