import React, { useRef, useState } from 'react';
import { Spinner, NonIdealState, Button, Intent, Switch } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import Page from 'app/widgets/Page';
import AbstractGrid from 'app/widgets/Grid/AbstractGrid';
import * as Filters from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';
import store from 'app/state';
import * as selectors from 'app/state/reducers';

import TradepointCard from './TradepointCard';
import styles from './QuestionaryBindingScreen.module.css';

const INITIAL_SORT = { field: 'code', direction: 'asc' };

function onClickDownload() {
  const { filters, sort } = store.getState().grids[api.TRADEPOINTS_BINDINGS];
  const exportFilters = {
    ...filters,
    has_questionary_bindings__eq: undefined,
    no_bindings: filters.has_questionary_bindings__eq === false ? true : undefined,
  };
  api.download(api.QUESTIONARY_TRADEPOINT_REFS_REPORT, { filters: exportFilters, sort });
}

export default
function QuestionaryBindingScreen() {
  const { t } = useTranslation();
  const grid = useRef();
  const showAgencyFilter = RRU.useSelector(selectors.canViewAgencies);
  const [noBindings, setNoBindings] = useState(false);

  const { data = [], fetching = true } = RRU.useSelector(`grids.${api.TRADEPOINTS_BINDINGS}`) ?? {};

  const toggleOnlyBoundFilter = (e) => {
    setNoBindings(!noBindings);

    grid.current.setFilter('has_questionary_bindings__eq', !noBindings ? false : undefined);
  }

  const changePlanogram = (newValue, isSelectAll) => {
    if (isSelectAll) {
      grid.current.setFilter('has_planogram_bindings__eq', newValue || undefined);
      grid.current.setFilter('planogram_id__in', undefined);
      return;
    }
    grid.current.setFilter('has_planogram_bindings__eq', undefined);
    grid.current.setFilter('planogram_id__in', newValue);
  }

  return (
    <Page title={t('shop pinning')}>
      <AbstractGrid
        path={api.TRADEPOINTS_BINDINGS}
        initialSort={INITIAL_SORT}
        initialPageSize={20}
        showTotal
        showItemsPerPage={false}
        ref={grid}
      >
        <AbstractGrid.Actions>
          <Button
            minimal
            icon="download"
            intent={Intent.SUCCESS}
            text={t('download')}
            onClick={onClickDownload}
          />
        </AbstractGrid.Actions>

        {showAgencyFilter && (
          <AbstractGrid.Filter
            acceptor={Filters.AgencyFilter}
            path="agency_id__eq"
            transient
            multi={false}
            autoSelectFirst
          />
        )}
        <AbstractGrid.Filter
          acceptor={Filters.ContragentFilter}
          path="contragent_id__eq"
          dependsOn="agency_id__eq"
          multi={false}
          autoSelectFirst
        />

        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="code__ilike"
          icon="search"
          name={t('search by point code')}
        />
        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="address__ilike"
          icon="search"
          name={t('search by address')}
        />
        <AbstractGrid.Filter
          acceptor={Filters.QuestionaryFilter}
          path="questionary_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Filters.PlanogrammFilter}
          path="planogram_id__in"
          dependsOn="contragent_id__eq"
          selectAllPath="has_planogram_bindings__eq"
          onChange={changePlanogram}
        />
        <AbstractGrid.Filter
          acceptor={Filters.TradepointProjectFilter}
          path="project_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Filters.TradeNetworkFilter}
          path="network_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Switch}
          path="no_bindings"
          label={t('without questionnaires')}
          onChange={toggleOnlyBoundFilter}
          checked={noBindings}
        />

        <div className={styles.content}>
          {fetching && <Spinner />}
          {!fetching && !data.length && (
            <NonIdealState title={t('empty')} description={t('no points found')} icon="list" />
          )}
          {!fetching && data.map(t => <TradepointCard key={t.id} tradepoint={t} />)}
        </div>

      </AbstractGrid>
    </Page>
  );
}
