import React, { PureComponent } from 'react';
import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import SimpleSelect from '../SimpleSelect';
import styles from './PageSelect.module.css';

function createIntRange(minInclusive, maxInclusive) {
  const array = [];
  for (let i = minInclusive; i <= maxInclusive; i += 1) {
    array.push(i);
  }
  return array;
}

const transformItemsPerPage = (perPage) => ({
  key: perPage,
  value: perPage,
  title: `${perPage}`,
});

class PageSelect extends PureComponent {
  onPageClick(pageNumber) {
    const { onPageSelect } = this.props;
    onPageSelect(pageNumber);
  }

  renderPageButton(page) {
    const { currentPage } = this.props;
    const isActive = page === currentPage;
    return (
      <Button
        key={page}
        onClick={() => this.onPageClick(page)}
        intent={isActive ? Intent.SUCCESS : null}
      >
        {page.toString()}
      </Button>
    );
  }

  renderItemsPerPageChanger() {
    const {
      onChangeItemsPerPage,
      itemsPerPageItems,
      currentItemsPerPage,
      t,
    } = this.props;
    return (
      <>
        <div className={styles.itemsPerPageLabel}>
          {t('on page')}:
        </div>
        <div style={{ alignSelf: 'center' }}>
          <SimpleSelect
            items={itemsPerPageItems.map(transformItemsPerPage)}
            value={currentItemsPerPage}
            onChange={onChangeItemsPerPage}
          />
        </div>
        <div className={styles.spacer} />
      </>
    );
  }

  renderTotal() {
    const { totalLabel, total, t } = this.props;
    return (
      <div className={styles.total}>
        {t(totalLabel) + ':'}
        {' '}
        {total}
      </div>
    );
  }

  render() {
    const {
      minPage,
      maxPage,
      currentPage,
      numPagesAround,
      showItemsPerPageChanger,
      showTotal,
      className,
    } = this.props;
    const isFirstPage = currentPage === minPage;
    const hasMaxPage = !!maxPage;
    const isLastPage = hasMaxPage && currentPage === maxPage;

    const minPageAtLeftFromCurrent = Math.max(minPage + 1, currentPage - numPagesAround);
    const maxPageRightFromCurrent = hasMaxPage
      ? Math.min(maxPage - 1, currentPage + numPagesAround)
      : currentPage + 1;
    const pagesRange = createIntRange(minPageAtLeftFromCurrent, maxPageRightFromCurrent);

    const showEllipsisLeft = minPageAtLeftFromCurrent > minPage + 1;
    const showEllipsisRight = !hasMaxPage || maxPageRightFromCurrent < maxPage - 1;

    return (
      <div className={classnames(className, styles.wrapper)}>
        {showTotal && this.renderTotal()}
        {showItemsPerPageChanger && this.renderItemsPerPageChanger()}
        <ButtonGroup>
          <Button onClick={() => this.onPageClick(currentPage - 1)} icon="arrow-left" disabled={isFirstPage} />
          {this.renderPageButton(minPage)}
          {showEllipsisLeft && <Button disabled>...</Button>}
          {pagesRange.map((page) => this.renderPageButton(page))}
          {showEllipsisRight && <Button disabled>...</Button>}
          {hasMaxPage && minPage !== maxPage && this.renderPageButton(maxPage)}
          <Button onClick={() => this.onPageClick(currentPage + 1)} icon="arrow-right" disabled={isLastPage} />
        </ButtonGroup>
      </div>
    );
  }
}

PageSelect.propTypes = {
  minPage: PropTypes.number,
  maxPage: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  numPagesAround: PropTypes.number,
  onPageSelect: PropTypes.func,
  onChangeItemsPerPage: PropTypes.func,
  currentItemsPerPage: PropTypes.number,
  itemsPerPageItems: PropTypes.arrayOf(PropTypes.number),
  showItemsPerPageChanger: PropTypes.bool,
  total: PropTypes.number,
  showTotal: PropTypes.bool,
  totalLabel: PropTypes.string,
  className: PropTypes.string,
  t: PropTypes.func,
};

PageSelect.defaultProps = {
  minPage: 1,
  numPagesAround: 1,
  maxPage: null,
  onPageSelect: () => {},
  onChangeItemsPerPage: () => {},
  currentItemsPerPage: 20,
  showItemsPerPageChanger: false,
  itemsPerPageItems: [20, 50, 100],
  total: 1,
  showTotal: false,
  totalLabel: 'total',
  className: null,
  t: () => {},
};

export default withTranslation()(PageSelect);
