import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { H2, HTMLTable, Button } from '@blueprintjs/core';

import * as utils from 'app/utils';
import Form from 'app/widgets/Form';
import FancySelect from 'app/widgets/FancySelect';

import styles from '../TaskResult.module.css';

const AssortmentRow = ({ row }) => {
  const { t } = useTranslation();

  return (
    <tr>
      <td>{row.name}</td>
      <td>{row.category_name}</td>
      <td className={row.facing_fact ? styles.irAssortmentSuccess : styles.irAssortmentError}>
        {row.facing_fact ? 'OK' : t('NOT FOUND')}
      </td>
      <td>{row.price} руб</td>
    </tr>
  );
};

AssortmentRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category_name: PropTypes.string,
    facing_fact: PropTypes.number,
    price: PropTypes.string,
  }).isRequired,
};

function getNextSort(field, sort) {
  if (sort.field !== field) {
    return {
      field,
      direction: 'asc',
    };
  }
  if (sort.direction === 'desc') {
    return {};
  }
  return {
    field,
    direction: 'desc',
  };
}

function onSort(field, setSort) {
  setSort((sort) => getNextSort(field, sort));
}

function getSortIcon(field, sort) {
  if (sort.field === field) {
    return `sort-${sort.direction}`;
  }
  return 'sort';
}

function handleFiltersFieldChange({ setFilters }, key, value) {
  setFilters((filters) => ({ ...filters, [key]: value }));
}

function extractCategories({ assortment }) {
  return (
    _.uniq(_.map(assortment, (row) => row.category_name))
      .sort()
      .map((id) => ({ id, label: id }))
  );
}

function computeOsa({ assortment, category }) {
  if (!assortment?.length) {
    return null;
  }

  let categoryAssortment = assortment;
  if (category) {
    categoryAssortment = _.filter(assortment, { category_name: category });
  }

  const ratio = categoryAssortment.filter(p => !!p.facing_fact && !!p.facing_plan).length / categoryAssortment.length;
  return _.round(100 * ratio);
}

function processAssortment({ assortment, filters, sort }) {
  if (!assortment?.length) {
    return [];
  }

  let result = assortment.slice();

  if (filters.category) {
    result = _.filter(result, { category_name: filters.category });
  }
  if (!_.isNil(filters.result)) {
    result = result.filter((row) => !!row.facing_fact === filters.result);
  }
  if (filters.name) {
    const name = filters.name.trim().toLowerCase();
    result = result.filter((row) => row.name.toLowerCase().includes(name));
  }

  if (sort.field) {
    result = _.sortBy(result, sort.field);
    if (sort.direction === 'desc') {
      result = _.reverse(result);
    }
  }
  return result;
}

const IrAssortment = ({ assortment }) => {
  const { t } = useTranslation();

  const RESULTS = [
    { id: true, label: 'OK' },
    { id: false, label: t('NOT FOUND'), },
  ];

  const [filters, setFilters] = useState({});
  const onFiltersFieldChange = utils.useCallbackWithDeps(handleFiltersFieldChange, { setFilters });

  const [sort, setSort] = useState({});
  const onSortByName = useCallback(() => onSort('name', setSort), []);
  const onSortByCategoryName = useCallback(() => onSort('category_name', setSort), []);
  const onSortByResult = useCallback(() => onSort('facing_fact', setSort), []);

  const categories = utils.useMemoWithDeps(extractCategories, { assortment });

  const osa = utils.useMemoWithDeps(computeOsa, { assortment, category: filters.category });

  const processedAssortment = utils.useMemoWithDeps(processAssortment, { assortment, sort, filters });

  if (!assortment?.length) {
    return null;
  }

  return (
    <>
      <H2>{t('store matrix')}</H2>

      <Form value={filters} onFieldChange={onFiltersFieldChange} inline>
        <Form.Item
          name="name"
          label={t('product name')}
        />
        <Form.Item
          name="category"
          label={t('product group')}
          acceptor={FancySelect}
          data={categories}
          multi={false}
          clearable
        />
        <Form.Item
          name="result"
          label={t('recognition result')}
          acceptor={FancySelect}
          data={RESULTS}
          multi={false}
          clearable
        />
      </Form>

      {osa !== null && <div>OSA, % = {osa}%</div>}

      <HTMLTable className={styles.productTable}>
        <thead>
          <tr>
            <th>
              <div className={styles.irProductHead}>
                <div>{t('product name')}</div>
                <Button small minimal icon={getSortIcon('name', sort)} onClick={onSortByName} />
              </div>
            </th>

            <th style={{ width: '200px' }}>
              <div className={styles.irProductHead}>
                <div>{t('group')}</div>
                <Button small minimal icon={getSortIcon('category_name', sort)} onClick={onSortByCategoryName} />
              </div>
            </th>

            <th style={{ width: '150px' }}>
              <div className={styles.irProductHead}>
                <div>{t('recognition result')}</div>
                <Button small minimal icon={getSortIcon('facing_fact', sort)} onClick={onSortByResult} />
              </div>
            </th>

            <th style={{ width: '150px' }}>
              <div className={styles.irProductHead}>
                <div>{t('price')}</div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {processedAssortment.map((row) => <AssortmentRow row={row} key={row.id} />)}
        </tbody>
      </HTMLTable>
    </>
  );
};

IrAssortment.propTypes = {
  assortment: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category_name: PropTypes.string,
    facing_fact: PropTypes.number,
  })).isRequired,
};

export default IrAssortment;
