import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  uploadPhotoAction,
  removePhotoAction,
} from 'app/state/actionCreators/planogram';
import Photos from 'app/widgets/Photos';

const PlanogramPhotos = ({
  photos,
  removePhoto,
  uploadPhoto,
  replacePhoto,
}) => (
  <Photos
    photos={photos}
    removePhoto={removePhoto}
    uploadPhoto={uploadPhoto}
    replacePhoto={replacePhoto}
    getPreview={({ key }) => `/files/${key}`}
    getSrc={({ key }) => `/files/${key}`}
  />
);

PlanogramPhotos.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    key: PropTypes.string,
    size: PropTypes.number,
    uploading: PropTypes.bool,
  })).isRequired,
  removePhoto: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
  replacePhoto: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  uploadPhoto: (image) => dispatch(uploadPhotoAction(
    image,
  )),
  replacePhoto: (image, replaceUuid) => dispatch(uploadPhotoAction(
    image,
    replaceUuid,
  )),
  removePhoto: (uuid) => dispatch(removePhotoAction(
    uuid,
  )),
});

export default connect(null, mapDispatchToProps)(PlanogramPhotos);
