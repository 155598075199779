import i18n from 'i18n';
import { downloadJtiReport, downloadJtiReportGrouped } from 'app/api';
import { showError } from 'app/widgets/toaster';

import { $setIn } from '../index';

export const downloadReport = () => async (dispatch, getState) => {
  dispatch($setIn('reports.jti', { fetching: true }));
  const { filters } = getState().reports.jti;
  try {
    await downloadJtiReport(filters);
  } catch (e) {
    showError(e.message || i18n.t('error'));
  }
  dispatch($setIn('reports.jti', { fetching: false }));
};

export const downloadReportGrouped = () => async (dispatch, getState) => {
  dispatch($setIn('reports.jti', { fetching: true }));
  const { filters } = getState().reports.jti;
  try {
    await downloadJtiReportGrouped(filters);
  } catch (e) {
    showError(e.message || i18n.t('error'));
  }
  dispatch($setIn('reports.jti', { fetching: false }));
};
