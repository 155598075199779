import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  H3,
  Button,
  FormGroup,
  InputGroup,
  Card,
  Elevation,
  Intent,
  Toaster,
  Dialog,
  Position,
  Classes,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';

import * as api from 'app/api';
import { isEmail } from 'app/utils/email';
import styles from './PasswordRecoveryScreen.module.css';

class PasswordRecoveryScreen extends Component {
  constructor() {
    super();
    this.state = {
      login: '',
      fetching: false,
      error: false,
      completedDialogOpen: false,
    };

    this.toaster = React.createRef();
  }

  onCompletedDialogClose() {
    const { login } = this.state;
    const { history } = this.props;
    this.setState({ completedDialogOpen: false });
    history.push(`/login?login=${login}`);
  }

  async onSignupClick() {
    if (!this.canRegister()) {
      return;
    }

    const { login } = this.state;

    const req = {};
    if (isEmail(login)) {
      req.email = login;
    } else {
      req.phone = login;
    }

    this.setState({ fetching: true, error: null, completedDialogOpen: false });
    try {
      await api.signup(req);
      this.setState({ fetching: false, completedDialogOpen: true });
    } catch (error) {
      this.setState({ fetching: false, error, completedDialogOpen: false });
      this.toaster.current.show({ intent: Intent.DANGER, message: error.message, icon: 'error' });
    }
  }

  errorForField(fieldName) {
    const { error } = this.state;
    if (error && error.errors && error.errors[fieldName]) {
      return error.errors[fieldName][0];
    }
    return null;
  }

  canRegister() {
    const { login } = this.state;
    return login && login.length > 5;
  }

  renderCompleteDialog() {
    const { t } = this.props;
    const { login, completedDialogOpen } = this.state;

    return (
      <Dialog
        icon="info-sign"
        title={t('password recovery')}
        isOpen={completedDialogOpen}
        onClose={() => this.onCompletedDialogClose()}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            {t('we sent your password')}
            {isEmail(login) ? ` ${t('in an email message to')}: ` : ` ${t('in an sms message to number')}: `}
            <strong>{login}</strong>
          </p>
          <p>{t('use it to login to the site')}.</p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => this.onCompletedDialogClose()} intent={Intent.SUCCESS}>
              {t('good')}
            </Button>
            <Button onClick={() => this.onSignupClick()} minimal>{t('send again')}</Button>
          </div>
        </div>
      </Dialog>
    );
  }

  render() {
    const { t } = this.props;
    const { login, fetching } = this.state;
    const loginErrorText = this.errorForField('email') || this.errorForField('phone');

    return (
      <Page fullScreen>
        <Card
          className={styles.loginCard}
          elevation={Elevation.TWO}
        >

          <div>
            <H3 className={styles.header}>{t('password recovery')}</H3>
          </div>

          <div className={styles.form}>
            <FormGroup
              label={t('phone or email')}
              helperText={loginErrorText}
              intent={loginErrorText ? Intent.DANGER : null}
            >
              <InputGroup
                value={login}
                onChange={(e) => this.setState({ login: e.target.value })}
                large
              />
            </FormGroup>
          </div>

          <div className={styles.buttonGroup}>
            <Button
              text={t('restore password')}
              onClick={() => this.onSignupClick()}
              disabled={!this.canRegister()}
              intent={Intent.PRIMARY}
              loading={fetching}
              large
            />

            <Link to="/login">
              <Button text={t('login')} intent={Intent.PRIMARY} large minimal />
            </Link>
          </div>

        </Card>

        <Toaster ref={this.toaster} position={Position.TOP} />

        {this.renderCompleteDialog()}
      </Page>
    );
  }
}

PasswordRecoveryScreen.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PasswordRecoveryScreen);
