import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Intent } from '@blueprintjs/core';
import SelectableGrid from 'app/widgets/Grid/SelectableGrid';
import { Filter } from 'app/widgets/Grid/Filters';
import * as utils from 'app/utils';
import * as selectors from 'app/state/reducers';
import { AgencyFilter, ContragentFilter } from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';
import { exportData, moveToArchive } from 'app/state/actionCreators/references';
import store from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import RecordModal from './RecordModal';

const Column = utils.makePlaceholderComponentType('Grid.Column');
const Actions = utils.makePlaceholderComponentType('AbstractGrid.Actions');

const SingleColumnReference = React.memo(({ path, children, modal, canEdit, ...props }) => {
  const { t } = useTranslation();
  const [editingRecord, setEditingRecord] = useState(null);
  const { filters, selected } = useSelector((state) => state.grids[path] ?? grids.INITIAL_STATE);
  const showAgencyFilter = utils.useSelectorMemo(selectors.canViewAgencies);
  const [showDialog, toggleShowDialog] = utils.useToggle();
  const EditRecordModal = modal;

  const splitChildren = ({ children }) => {
    let actions = null;
    const columns = [];
    const filters = [];
    for (const child of React.Children.toArray(children)) {
      if (child.type === Column) {
        columns.push(child);
      } else if (child.type === Actions) {
        actions = child;
      } else if (child.type === Filter) {
        filters.push(child);
      }
    }

    return [columns, actions, filters];
  };

  const onClickDownload = () => {
    store.dispatch(exportData(path, filters));
  };

  const renderActions = (dummy, rowData) => (
    <Button icon="edit" small onClick={() => {
      setEditingRecord(rowData);
      toggleShowDialog();
    }} />
  );

  const onCloseEditDialog = (changed) => {
    if (changed) {
      store.dispatch(grids.fetch(path, { transientFilters: ['agency_id__eq'] }));
    }
    toggleShowDialog();
    setEditingRecord(null);
  };

  const onDelete = () => {
    store.dispatch(moveToArchive(path, selected));
  };

  const [
    columnChildren,
    actionsChild,
    filtersChildren
  ] = utils.useMemoWithDeps(splitChildren, { children });

  return (
    <>
      <SelectableGrid path={path} {...props}>
        {showAgencyFilter && (
          <SelectableGrid.Filter
            acceptor={AgencyFilter}
            path="agency_id__eq"
            transient
            multi={false}
            autoSelectFirst
          />
        )}
        <SelectableGrid.Filter
          acceptor={ContragentFilter}
          multi={false}
          dependsOn="agency_id__eq"
          path="contragent_id__eq"
          autoSelectFirst
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="name__ilike"
          name={t('denomination')}
          placeholder={t('search by denomination')}
        />
        {filtersChildren.map((filter, i) => (
          <SelectableGrid.Filter key={i} {...filter.props} />
        ))}
        <SelectableGrid.Actions>
          <Button
            onClick={onClickDownload}
            minimal
            icon="download"
            text={t('download')}
            intent={Intent.SUCCESS}
          />
          {canEdit && (
            <Button
              icon="add"
              text={t('create row')}
              intent={Intent.SUCCESS}
              onClick={toggleShowDialog}
            />
          )}
          {actionsChild?.props?.children}
          {canEdit && (
            <Button
              intent={Intent.DANGER}
              icon="delete"
              disabled={!selected.length}
              onClick={onDelete}
              minimal
            >
              {t('delete')}
            </Button>
          )}
        </SelectableGrid.Actions>


        <SelectableGrid.Column path="name" title={t('denomination')} sortable />
        {columnChildren.map((column, i) => (
          <SelectableGrid.Column key={i} {...column.props} />
        ))}
        {canEdit && (
          <SelectableGrid.Column path="actions" title={t('actions')} valueRenderer={renderActions} width={100} />
        )}
      </SelectableGrid>

      {showDialog && (
        <EditRecordModal
          onClose={onCloseEditDialog}
          path={path}
          record={editingRecord}
        />
      )}
    </>
  );
});

SingleColumnReference.Actions = Actions;
SingleColumnReference.Filter = Filter;
SingleColumnReference.Column = Column;

SingleColumnReference.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  modal: PropTypes.object,
  canEdit: PropTypes.bool.isRequired,
};

SingleColumnReference.defaultProps = {
  modal: RecordModal,
  canEdit: false,
};

export default SingleColumnReference;
