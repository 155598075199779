import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  H2,
  Intent,
  Button,
  Checkbox,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import * as contragents from 'app/state/actionCreators/contragents';
import { canViewAgencies } from 'app/state/reducers';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import { CONTRAGENTS } from 'app/api';
import { hasRight } from 'app/state/reducers';
import NewContragentDialog from './widgets/NewContragentDialog';
import { AgencyFilter, ContragentFilter, MerchandisingFilter } from 'app/widgets/Filters';

const ContragentList = ({
  canEdit,
}) => {
  const { t } = useTranslation();
  const showAgencyFilter = utils.useSelectorMemo(canViewAgencies);
  const onClickCreate = utils.useDispatchCallback(contragents.openCreateDialog);

  const renderCode = (value, data) => {
    return (
      <Link to={`/contragents/${data.id}`}>
        {value}
      </Link>
    );
  }

  const renderType = (value) => (!!value ? t('exclusive') : t('combined'));

  const renderHideCrisisManager = (value) => {
    const bound = !value;
    return (
      <Checkbox
        checked={bound}
        disabled
      />
    );
  }

  return (
    <Page>
      <H2>{t('contractors')}</H2>
      <Grid path={CONTRAGENTS}>
        {showAgencyFilter && (
          <Grid.Filter
            acceptor={AgencyFilter}
            path="agency_id__eq"
            // transient
            multi={false}
            autoSelectFirst
          />
        )}
        <Grid.Filter
          acceptor={ContragentFilter}
          multi={true}
          dependsOn="agency_id__eq"
          path="id__eq"
        />
        <Grid.Filter acceptor={MerchandisingFilter} name={t('merchandising type')} path="exclusive__eq" />

        {canEdit && (
          <Grid.Actions>
            <Button
              icon="briefcase"
              text={t('create contractor')}
              intent={Intent.PRIMARY}
              onClick={onClickCreate}
            />
          </Grid.Actions>
        )}

        <Grid.Column path="code" title={t('contractor code')} sortable valueRenderer={renderCode} />
        <Grid.Column path="fullname" title={t('contractor name')} sortable />
        <Grid.Column path="agencyName" title={t('agency')} sortable />
        <Grid.Column path="exclusive" title={t('merchandising type')} sortable valueRenderer={renderType} />
        <Grid.Column
          path="settings.hide_crisis_manager"
          title={t('crisis manager functionality')}
          sortable
          valueRenderer={renderHideCrisisManager}
        />
      </Grid>

      <NewContragentDialog />
    </Page>
  );
};

ContragentList.propTypes = {
  canEdit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  canEdit: hasRight(state, 'webapp_contragents_edit'),
});

export default connect(mapStateToProps)(ContragentList);
