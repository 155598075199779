import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Intent,
  Position,
  Popover,
  Menu,
  MenuItem,
  MenuDivider,
  Checkbox,
  Switch,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { reportFieldType } from 'app/proptyping';

import styles from './ReportDynamicScreen.module.css';

export default
function ActionBar({
  fields,
  toggleField,
  filters,
  answersOnly,
  setAnswersOnly,
  filtersVisible,
  setFilterVisible,
  reportType,
}) {
  const { t } = useTranslation();

  const hasFilters = !!Object
    .entries(filters)
    .filter(([, value]) => value)
    .length;
  const checkedCount = fields.filter((f) => f.checked);
  const allChecked = checkedCount.length === fields.length;
  const partAllChecked = !!checkedCount.length && !allChecked;

  return (
    <div className={styles.actionBar}>
      <Popover
        content={(
          <Menu>
            <MenuItem
              key="all_0"
              shouldDismissPopover={false}
              className={styles.fieldMenuItem}
              text={(
                <Checkbox
                  className={styles.fieldCheckbox}
                  checked={allChecked}
                  indeterminate={partAllChecked}
                  onChange={() => toggleField('ALL', !allChecked)}
                >
                  {t('all')}
                </Checkbox>
              )}
            />
            <MenuDivider />
            {fields.map((f) => (
              <MenuItem
                key={f.field}
                shouldDismissPopover={false}
                className={styles.fieldMenuItem}
                text={(
                  <Checkbox
                    className={styles.fieldCheckbox}
                    checked={f.checked}
                    onChange={() => toggleField(f.field, !f.checked)}
                  >
                    {f.name}
                  </Checkbox>
                )}
              />
            ))}
          </Menu>
        )}
        position={Position.BOTTOM_LEFT}
      >
        <Button
          minimal
          icon="cog"
          intent={Intent.SUCCESS}
          className={styles.cogButton}
        />
      </Popover>
      <Button
        onClick={() => { setFilterVisible(!filtersVisible); }}
        minimal
        icon={hasFilters ? 'filter-keep' : 'filter'}
        rightIcon={filtersVisible ? 'chevron-up' : 'chevron-down'}
        text={t('filters')}
        intent={Intent.SUCCESS}
        className={styles.openFiltersButton}
      />
      {reportType === 'questionaries' && (
        <Switch
          label={t('questionnaires with answers only')}
          checked={answersOnly}
          onChange={() => setAnswersOnly(!answersOnly)}
        />
      )}
      <div className={styles.spacer} />
    </div>
  );
};

ActionBar.propTypes = {
  fields: PropTypes.arrayOf(reportFieldType).isRequired,
  toggleField: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  answersOnly: PropTypes.bool.isRequired,
  setAnswersOnly: PropTypes.func.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  setFilterVisible: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
};
