import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, FormGroup, Intent, Position, InputGroup } from '@blueprintjs/core';
import { TimePicker, TimePrecision } from '@blueprintjs/datetime';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { getTradepointBindings, getTPLastUser } from 'app/api';
import { LocalizedDateInput } from 'app/widgets/LocalizedDateInput';
import * as ReferenceSelect from 'app/widgets/ReferenceSelect';
import MerchSelect from 'app/widgets/MerchSelect';
import SinglePhoto from 'app/widgets/Photos/SinglePhoto';
import { showError } from 'app/widgets/toaster';
import { useCallbackWithDeps, classNames } from 'app/utils';

import styles from './CreateForm.module.css';

// 0.001 = 43.496m на параллели 67N/S
// 0.001 = 102.47 m на параллели 23N/S
// Нужна случайная точка в пределах 400m
const randomDistance = () => {
  const sign = Math.random() > 0.5 ? 1 : -1;
  return Math.random() * sign * 0.002;
};

const ShopPhoto = ({ shopPhoto, uploadShopPhoto, clearShopPhoto, title }) => (
  <div className={styles.shopPhoto}>
    <div className={styles.shopPhotoTitle}>
      {title}
    </div>
    <SinglePhoto
      photo={shopPhoto}
      removePhoto={clearShopPhoto}
      uploadPhoto={uploadShopPhoto}
      getSrc={({ key }) => `/files/${key}`}
      getPreview={({ key }) => `/files/previews/${key}`}
    />
  </div>
);

ShopPhoto.propTypes = {
  shopPhoto: PropTypes.shape({
    uuid: PropTypes.string,
    uploading: PropTypes.bool,
    needReupload: PropTypes.bool,
  }).isRequired,
  uploadShopPhoto: PropTypes.func.isRequired,
  clearShopPhoto: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const FormRow = React.memo(({ children, label, error, disabled }) => (
  <FormGroup
    inline
    label={label}
    className={classNames(styles.formRow, disabled && styles.disabled)}
    helperText={error}
    intent={error ? Intent.DANGER : Intent.NONE}
  >
    {children}
  </FormGroup>
));

FormRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

FormRow.defaultProps = {
  error: null,
  disabled: false,
};

const MAX_DATE = moment(new Date())
  .add(30, 'days')
  .set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 0,
  })
  .toDate();

const getDateError = (date, reqStr) => {
  let dateError = null;
  if (!date) {
    dateError = reqStr;
  }
  return dateError;
};

export const isEndBeforeStart = (
  startDate,
  startDateTime,
  endDate,
  endDateTime,
) => {
  if (!startDate || !startDateTime || !endDate || !endDateTime) {
    return false;
  }
  const checkStartDate = moment(startDate);
  const checkEndDate = moment(endDate);
  const checkStartDateTime = moment(startDateTime)
    .year(checkStartDate.year())
    .month(checkStartDate.month())
    .date(checkStartDate.date());
  const checkEndDateTime = moment(endDateTime)
    .year(checkEndDate.year())
    .month(checkEndDate.month())
    .date(checkEndDate.date());
  return checkEndDateTime < checkStartDateTime;
};

export const validateGaps = (
  isEditing,
  gaps,
  startDate,
  startDateTime,
  endDate,
  endDateTime,
) => {
  let invalid = false;
  if (isEditing || !gaps) {
    return invalid;
  }

  const checkStartDate = moment(startDate);
  const checkEndDate = moment(endDate);
  const checkStartDateTime = moment(startDateTime)
    .year(checkStartDate.year())
    .month(checkStartDate.month())
    .date(checkStartDate.date());
  const checkEndDateTime = moment(endDateTime)
    .year(checkEndDate.year())
    .month(checkEndDate.month())
    .date(checkEndDate.date());

  invalid = true;
  gaps.forEach((gap) => {
    const startInGap = checkStartDateTime.isBetween(gap.start, gap.end);
    const endInGap = checkEndDateTime.isBetween(gap.start, gap.end);
    if (startInGap && endInGap) {
      // начало и конец визита
      // входят в один интервал
      invalid = false;
    }
  });
  return invalid;
};

const getEndDateError = (
  isEditing,
  gaps,
  startDate,
  startDateTime,
  endDate,
  endDateTime,
  requiredToFill,
  endBeforeStart,
  intersectsWithVisit,
) => {
  let dateError = null;
  if (!endDate) {
    dateError = requiredToFill;
    return dateError;
  }
  const invalidEndBeforeStart = isEndBeforeStart(startDate, startDateTime, endDate, endDateTime);
  if (invalidEndBeforeStart) {
    dateError = endBeforeStart;
  }

  const invalidGaps = validateGaps(
    isEditing,
    gaps,
    startDate,
    startDateTime,
    endDate,
    endDateTime,
  );
  if (invalidGaps) {
    dateError = intersectsWithVisit;
  }

  return dateError;
};

const onTradepointSelect = async ({ id, updateTask, downloadErrorText }) => {
  let tradepoint;
  if (id) {
    try {
      // TODO use specialized api?
      const response = await getTradepointBindings({ filters: { id__eq: id } });
      [tradepoint] = response.data;
    } catch (e) {
      showError(e.message || downloadErrorText);
    }
  }

  if (tradepoint) {
    const latitude = Number(tradepoint.latitude) || 0;
    const longitude = Number(tradepoint.longitude) || 0;
    const boundQuestionaryIds = _.keys(tradepoint.questionaries).map((questionaryId) => Number(questionaryId));
    updateTask({
      shop: {
        id,
        network: tradepoint.network,
        address: tradepoint.address,
        boundQuestionaryIds,
      },
      taskLocation: {
        latitude,
        longitude,
      },
      startLocation: {
        latitude: latitude + randomDistance(),
        longitude: longitude + randomDistance(),
      },
      endLocation: {
        latitude: latitude + randomDistance(),
        longitude: longitude + randomDistance(),
      },
    });
  } else {
    updateTask({
      shop: {
        id: undefined,
        network: '',
        address: '',
      },
      taskLocation: {
        latitude: null,
        longitude: null,
      },
      startLocation: {
        latitude: null,
        longitude: null,
      },
      endLocation: {
        latitude: null,
        longitude: null,
      },
    });
  }
};

function moveBoundQuestionariesUp({ ids }, items) {
  if (!ids?.length) {
    return items;
  }

  const sorted = [...items];
  return sorted.sort((a, b) => ids.includes(b.id) - ids.includes(a.id));
}

const QuestionarySelectItem = React.memo(({ item, text }) => {
  const boundQuestionaryIds = useSelector((state) => state.restoredTaskEditor.task.shop.boundQuestionaryIds);
  if (!boundQuestionaryIds?.includes(item.id)) {
    return text;
  }

  return <span className={styles.prioritySelectItem}>{text}</span>;
});

QuestionarySelectItem.propTypes = {
  item: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  text: PropTypes.node.isRequired,
};

const randInt = (max) => Math.floor(Math.random() * max);

const fitRandomGap = (gaps) => {
  const gapIndex = randInt(gaps.length);
  const gap = gaps[gapIndex];
  const secondsAttTP = 600 + randInt(600);
  const gapSecondsLeft = gap.length - secondsAttTP;
  const fromGapStart = randInt(gapSecondsLeft);
  const startDateTime = moment(gap.start).add(fromGapStart, 'second').toDate();
  const endDateTime = moment(gap.start).add(fromGapStart + secondsAttTP, 'second').toDate();
  return { startDateTime, endDateTime };
};

const CreateForm = ({
  isEditing,
  isCopying,
  task,
  finishedAtTP,
  updateTask,
  uploadShopPhoto,
  clearShopPhoto,
  gaps,
  invalidDublicateTask,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const reqStr = t('required to fill');
  const shopPhotoTitle = `${t('signboard photo')}:`;
  const endBeforeStart = t('end before start');
  const intersectsWithVisit = ('intersects with visit');
  const downloadErrorText = t('download tt error');
  const taskExistsError = t('task already exist for user');
  const lastExecutor = t('last executor not found');
  const yes = t('yes');
  const no = t('no');

  useEffect(() => {
    if (location.state?.fromSchedule) {
      const { contragentId, userId, tradepointId, date } = location.state;
      // после подгрузки пустого визита
      setTimeout(() => updateTask({
        contragentId,
        date,
        endDate: date,
        startDate: date,
        endDateTime: date,
        startDateTime: date,
        userId,
      }), 1);
      onTradepointSelect({ id: tradepointId, updateTask, t });
    }
  }, [location.state]);

  const moveBoundQuestionariesUpBound = useCallbackWithDeps(
    moveBoundQuestionariesUp,
    { ids: task.shop.boundQuestionaryIds },
  );

  let dateError = getDateError(task.date, reqStr);
  if (invalidDublicateTask) {
    dateError = taskExistsError;
  }
  const startDateError = getDateError(task.startDate, reqStr);
  const endDateError = getEndDateError(
    isEditing,
    gaps,
    task.startDate,
    task.startDateTime,
    task.endDate,
    task.endDateTime,
    reqStr,
    endBeforeStart,
    intersectsWithVisit,
  );

  return (
    <>
      <div className={styles.topRow}>
        <div className={styles.topColumn}>
          <FormRow label={`${t('contractor')}:`}>
            <ReferenceSelect.ContragentSelect
              fill
              disabled={isEditing}
              value={task.contragentId}
              onChange={(id) => updateTask({ contragentId: id })}
              multi={false}
              autoSelectFirst={!isCopying && !isEditing}
            />
          </FormRow>
          <FormRow label={`${t('tradepoint')}:`} error={!task.shop.id ? reqStr : null}>
            <ReferenceSelect.TradepointSelect
              fill
              disabled={!task.contragentId}
              value={task.shop.id}
              onChange={(id) => onTradepointSelect({ id, updateTask, downloadErrorText })}
              multi={false}
              fetchDisabled={!task.contragentId}
              autoSelectFirst
              filters={{ contragent_id__eq: task.contragentId }}
            />
          </FormRow>
          <FormRow
            label={`${t('executor')}:`}
            error={!task.userId ? reqStr : null}
          >
            <div className={styles.userWrapper}>
              <MerchSelect
                disabled={!task.contragentId}
                contragentId={task.contragentId}
                value={task.userId}
                onChange={(id) => updateTask({ userId: id })}
              />
              <Button
                className={styles.rowButton}
                disabled={!task.shop.id}
                intent={Intent.PRIMARY}
                text={t('last')}
                onClick={() => {
                  getTPLastUser(task.shop.id)
                    .then((user) => {
                      if (!user) {
                        showError(lastExecutor);
                      }
                      updateTask({ userId: user ? user.id : undefined });
                    });
                }}
              />
            </div>
          </FormRow>

          <FormRow label={t('supervisor')}>
            <ReferenceSelect.UserSelect
              fill
              disabled={!task.userId}
              fetchDisabled={!task.userId}
              filters={{ subordinate_id__eq: task.userId }}
              value={task.managerId}
              onChange={(id) => updateTask({ managerId: id })}
              multi={false}
              autoSelectFirst
            />
          </FormRow>

          <FormRow
            label={`${t('date of visit')}:`}
            error={dateError}
          >
            <LocalizedDateInput
              value={task.date}
              maxDate={MAX_DATE}
              onChange={(date) => {
                if (isEditing) {
                  updateTask({ date: date || undefined });
                } else if (date) {
                  updateTask({
                    date,
                    endDate: date,
                    startDate: date,
                    endDateTime: date,
                    startDateTime: date,
                  });
                } else {
                  updateTask({ date: undefined });
                }
              }}
              className={styles.dateInput}
            />
          </FormRow>
          <div className={styles.datimeGroupRow}>
            <div className={styles.datimeGroupColumn}>
              <FormRow label={`${t('start time')}:`} error={startDateError}>
                <div className={styles.datimeWrapper}>
                  <LocalizedDateInput
                    disabled={!isEditing}
                    value={task.startDate}
                    maxDate={MAX_DATE}
                    onChange={(date) => updateTask({ startDate: date || undefined })}
                    popoverProps={{ position: Position.BOTTOM_RIGHT }}
                  />
                  <TimePicker
                    precision={TimePrecision.SECOND}
                    maxTime={MAX_DATE}
                    value={task.startDateTime}
                    onChange={(date) => updateTask({ startDateTime: date || undefined })}
                  />
                </div>
              </FormRow>
              <FormRow label={`${t('end time')}:`} error={endDateError}>
                <div className={styles.datimeWrapper}>
                  <LocalizedDateInput
                    disabled={!isEditing}
                    value={task.endDate}
                    maxDate={MAX_DATE}
                    onChange={(date) => updateTask({ endDate: date || undefined })}
                    popoverProps={{ position: Position.BOTTOM_RIGHT }}
                  />
                  <TimePicker
                    precision={TimePrecision.SECOND}
                    maxTime={MAX_DATE}
                    value={task.endDateTime}
                    onChange={(date) => updateTask({ endDateTime: date || undefined })}
                  />
                </div>
              </FormRow>
            </div>
            <Button
              className={styles.datimeRandom}
              intent={Intent.PRIMARY}
              disabled={!gaps}
              text={t('random')}
              onClick={() => {
                const { startDateTime, endDateTime } = fitRandomGap(gaps);
                updateTask({ startDateTime, endDateTime });
              }}
            />
          </div>

          {!isEditing && !isCopying && (
            <FormRow
              label={`${t('questionnaires')}:`}
              error={!task.questionaryIds?.length ? reqStr : null}
            >
              <ReferenceSelect.QuestionarySelect
                fill
                value={task.questionaryIds}
                onChange={(ids) => updateTask({ questionaryIds: ids })}
                filters={{ contragent_id__eq: task.contragentId }}
                fetchDisabled={!task.contragentId}
                itemTextComponent={QuestionarySelectItem}
                preprocessItems={moveBoundQuestionariesUpBound}
              />
            </FormRow>
          )}
          <FormRow label={`${t('problem in the store')}:`}>
            <ReferenceSelect.VisitProblemSelect
              fill
              clearable
              disabled={!task.contragentId}
              fetchDisabled={!task.contragentId}
              filters={{ contragent_id__eq: task.contragentId }}
              value={task.shopProblem}
              onChange={code => updateTask({ shopProblem: code })}
              multi={false}
            />
          </FormRow>
          <FormRow label={`${t('completed in store')}:`}>
            {finishedAtTP ? yes : no}
          </FormRow>
          <FormRow label={`${t('comment for tt')}:`}>
            <InputGroup
              value={task.shopComment}
              onChange={(e) => updateTask({ shopComment: e.target.value })}
            />
          </FormRow>
        </div>
        <ShopPhoto
          title={shopPhotoTitle}
          shopPhoto={task.shopPhoto}
          uploadShopPhoto={uploadShopPhoto}
          clearShopPhoto={clearShopPhoto}
        />
      </div>
    </>
  );
};

CreateForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isCopying: PropTypes.bool.isRequired,
  task: PropTypes.shape({
    contragentId: PropTypes.number,
    userId: PropTypes.number,
    managerId: PropTypes.number,
    shop: PropTypes.shape({
      id: PropTypes.number,
      network: PropTypes.string,
      address: PropTypes.string,
      boundQuestionaryIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    questionaryIds: PropTypes.arrayOf(PropTypes.number),
    date: PropTypes.instanceOf(Date),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    startDateTime: PropTypes.instanceOf(Date),
    endDateTime: PropTypes.instanceOf(Date),
    shopProblem: PropTypes.string,
    shopPhoto: PropTypes.shape({
      uuid: PropTypes.string,
      uploading: PropTypes.bool,
      needReupload: PropTypes.bool,
    }).isRequired,
    shopComment: PropTypes.string,
  }).isRequired,
  finishedAtTP: PropTypes.bool.isRequired,
  updateTask: PropTypes.func.isRequired,
  uploadShopPhoto: PropTypes.func.isRequired,
  clearShopPhoto: PropTypes.func.isRequired,
  gaps: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    length: PropTypes.number,
  })),
  invalidDublicateTask: PropTypes.bool,
};

CreateForm.defaultProps = {
  gaps: undefined,
  invalidDublicateTask: false,
};

export default CreateForm;
