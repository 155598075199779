import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Marker, Tooltip, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';

import { LatLon } from 'app/proptyping';
import { distance } from 'app/utils/distance';
import { startIcon, endIcon, taskIcon, TILE_LAYER } from 'app/utils/map';

import styles from './index.module.css';

const PADDING = 0.001;
const INIT_BOUNDS = [[55.7058, 37.5673], [55.8058, 37.6673]];

const SyncCenter = React.memo(({ location }) => {
  const map = useMap();
  const { latitude, longitude } = location;
  useEffect(() => {
    if (latitude && longitude) {
      map.setView(L.latLng(latitude, longitude), map.getZoom());
    }
  }, [latitude, longitude]);

  return null;
});

SyncCenter.propTypes = {
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
};

const TaskMap = ({
  startLocation,
  endLocation,
  taskLocation,
  updateTask,
}) => {
  const { t } = useTranslation();
  const mapBounds = taskLocation.latitude && taskLocation.longitude ? [
    [
      Math.min(startLocation.latitude, endLocation.latitude, taskLocation.latitude) - PADDING,
      Math.min(startLocation.longitude, endLocation.longitude, taskLocation.longitude) - PADDING,
    ],
    [
      Math.max(startLocation.latitude, endLocation.latitude, taskLocation.latitude) + PADDING,
      Math.max(startLocation.longitude, endLocation.longitude, taskLocation.longitude) + PADDING,
    ],
  ] : INIT_BOUNDS;
  const [startDragging, setStartDragging] = useState(false);
  const [endDragging, setEndDragging] = useState(false);

  return (
    <MapContainer className={styles.map} bounds={mapBounds}>
      {TILE_LAYER}
      <SyncCenter location={taskLocation} />
      {!!taskLocation.latitude && !!taskLocation.longitude && (
        <Marker
          position={[taskLocation.latitude, taskLocation.longitude]}
          icon={taskIcon}
        />
      )}
      {!!startLocation.latitude && !!startLocation.longitude && (
        <Marker
          draggable
          eventHandlers={{
            dragstart: () => setStartDragging(true),
            dragend: (e) => {
              const latLng = e.target.getLatLng();
              updateTask({
                startLocation: {
                  longitude: latLng.lng,
                  latitude: latLng.lat,
                },
              });
              setStartDragging(false);
            },
          }}
          position={[startLocation.latitude, startLocation.longitude]}
          icon={startIcon}
          zIndexOffset={1000}
        >
          {!startDragging && (
            <Tooltip direction="right" permanent>
              {t('start')}: {distance(taskLocation, startLocation).toFixed(0)} {t('m')}
            </Tooltip>
          )}
        </Marker>
      )}
      {!!endLocation.latitude && !!endLocation.longitude && (
        <Marker
          draggable
          eventHandlers={{
            dragstart: () => setEndDragging(true),
            dragend: (e) => {
              const latLng = e.target.getLatLng();
              updateTask({
                endLocation: {
                  longitude: latLng.lng,
                  latitude: latLng.lat,
                },
              });
              setEndDragging(false);
            },
          }}
          position={[endLocation.latitude, endLocation.longitude]}
          icon={endIcon}
          zIndexOffset={2000}
        >
          {!endDragging && (
            <Tooltip direction="right" permanent>
              {t('ending')}: {distance(taskLocation, endLocation).toFixed(0)} {t('m')}
            </Tooltip>
          )}
        </Marker>
      )}
    </MapContainer>
  );
};

TaskMap.propTypes = {
  startLocation: LatLon.isRequired,
  endLocation: LatLon.isRequired,
  taskLocation: LatLon.isRequired,
  updateTask: PropTypes.func.isRequired,
};

export default TaskMap;
