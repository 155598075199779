import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_STATE = {
  imageUploading: false,
  imageError: null,
  imageUUID: undefined,
  sending: false,
  error: null,
  reportId: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.FEEDBACK_IMAGE_UPLOAD_START:
      return _.assign(state, {
        imageUploading: true,
        imageError: null,
      });
    case ACTIONS.FEEDBACK_IMAGE_UPLOAD_ERROR:
      return _.assign(state, {
        imageUploading: false,
        imageError: action.error,
      });
    case ACTIONS.FEEDBACK_IMAGE_UPLOAD_DONE:
      return _.assign(state, {
        imageUploading: false,
        imageUUID: action.payload.imageUUID,
      });
    case ACTIONS.FEEDBACK_SEND_START:
      return _.assign(state, {
        sending: true,
        error: null,
      });
    case ACTIONS.FEEDBACK_SEND_ERROR:
      return _.assign(state, {
        sending: false,
        error: action.error,
      });
    case ACTIONS.FEEDBACK_SEND_DONE:
      return _.assign(state, {
        sending: false,
        reportId: action.payload.reportId,
      });
    case ACTIONS.FEEDBACK_CLEAR:
        return INITIAL_STATE;
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
