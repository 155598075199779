import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_FILTERS = {
  user_id__eq: undefined,
  tradepoint_id__eq: undefined,
  contragent_id__eq: undefined,
  status__eq: undefined,
  date__from: undefined,
  date__to: undefined,
  address__like: undefined,
};

const INITIAL_STATE = {
  fetching: false,
  error: null,
  tasks: [],
  filtersVisible: false,
  filters: INITIAL_FILTERS,
  pagination: {
    itemsPerPage: 20,
    currentPage: 1,
  },
  totalItems: 0,
  totalPages: 0,
  user: null,
  userFetching: false,
  chiefsError: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.TASK_LIST_SET_FILTERS:
      return _.assign(state, { filters: action.filters });
    case ACTIONS.TASK_LIST_CLEAR_FILTERS:
      return _.assign(state, { filters: INITIAL_FILTERS });
    case ACTIONS.TASK_LIST_SET_PAGINATION:
      return _.assign(state, { pagination: action.pagination });
    case ACTIONS.TASK_LIST_SET_FILTER_VISIBLE:
      return _.assign(state, { filtersVisible: action.filtersVisible });
    case ACTIONS.TASK_LIST_FETCH_USER_START:
      return _.assign(state, { userFetching: true });
    case ACTIONS.TASK_LIST_FETCH_USER_DONE:
      return _.assign(state, {
        userFetching: false,
        user: action.user,
        chiefsError: null,
      });
    case ACTIONS.TASK_LIST_FETCH_USER_ERROR:
      return _.assign(state, {
        userFetching: false,
        chiefsError: action.error,
      });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
