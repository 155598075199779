import React from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H4 } from '@blueprintjs/core';

import { useRequestImmediate } from 'app/utils';
import { getTradepoints } from 'app/api';
import DataTable from 'app/widgets/DataTable';

const Tradepoints = ({ t }) => {
  const { id } = useParams();
  const { fetching, error, data } = useRequestImmediate(getTradepoints, { filters: { kladr_id__eq: id } });

  const TRADEPOINT_COLUMNS = [
    { title: t('contractor'), path: ['contragent'], width: 180 },
    {
      title: t('point code'),
      path: ['info', 'code'],
      width: 100,
      valueRenderer: (value, data) => (
        <>
          <Link to={`/tradepoint/${data?.id}`}>
            {value}
          </Link>
        </>
      ),
    },
    { title: t('address'), path: ['address'], width: 500 },
    { title: t('network'), path: ['network'], width: 100 },
    { title: t('affiliate'), path: ['branch'], width: 150 },
    { title: t('name'), path: ['name'], width: 100 },
    { title: t('latitude'), path: ['latitude'], width: 80 },
    { title: t('longitude'), path: ['longitude'], width: 80 },
  ];

  return (
    <>
      <H4>{t('tradepoints')}</H4>
      <DataTable
        columns={TRADEPOINT_COLUMNS}
        data={data ?? []}
        fetching={fetching}
        fetchingRowCount={10}
        error={error}
      />
    </>
  );
};

Tradepoints.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Tradepoints;
