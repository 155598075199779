import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Card, Tag, Elevation, Intent } from '@blueprintjs/core';

import { scheduleDateType, scheduleUserType, scheduleTradepointType } from 'app/proptyping';

import styles from './TasksScreen.module.css';

export default
function Task({ dates, tradepoint, user }) {
  const { t } = useTranslation();

  return (
    <Card elevation={Elevation.TWO} className={styles.resultCard} key={`${tradepoint.id}_${user.id}`}>
      <div className={styles.resultCardRow}>
        <div className={styles.resultCardLabel}>{t('executor')}:</div>
        <div className={styles.resultCardValue}>{`${user.fullname} (${user.phone})`}</div>
      </div>
      <div className={styles.resultCardRow}>
        <div className={styles.resultCardLabel}>{t('address')}:</div>
        <div className={styles.resultCardValue}>{tradepoint.address || ''}</div>
      </div>
      <div className={styles.resultCardRow}>
        <div className={styles.resultCardLabel}>{t('tradepoint')}:</div>
        <div className={styles.resultCardValue}>{`${tradepoint.name || '-'} (${tradepoint.code || '-'})`}</div>
      </div>
      <div className={styles.resultCardRow}>
        <div className={styles.resultCardLabel}>{t('network')}:</div>
        <div className={styles.resultCardValue}>{tradepoint.network || '-'}</div>
      </div>
      <div className={styles.resultCardRow}>
        <div className={styles.resultCardLabel}>{t('dates')}:</div>
        <div className={styles.resultCardValue}>
          {
            (dates || []).map(
            (date, index) => (
              <Tag
                round
                rightIcon={date.visited ? 'small-tick' : false}
                /* eslint-disable-next-line react/no-array-index-key */
                key={`${date.date.toLocaleString()}-${index}`}
                intent={date.visited ? Intent.NONE : Intent.SUCCESS}
                className={styles.resultCardTag}
              >
                {date.date.toLocaleString()}
              </Tag>
            ),
            )
          }
        </div>
      </div>
      <div className={styles.resultCardButtonContainer}>
        <Link to={`/tradepoint/${tradepoint.id}`}>
          <Button
            minimal
            icon="info-sign"
            text={t('tradepoint')}
            intent={Intent.PRIMARY}
            className={styles.resultCardButton}
          />
        </Link>
      </div>
    </Card>
  );
}

Task.propTypes = {
  dates: PropTypes.arrayOf(scheduleDateType).isRequired,
  tradepoint: scheduleTradepointType.isRequired,
  user: scheduleUserType.isRequired,
};
