import React from 'react';
import PropTypes from 'prop-types';
import { H2, Card, Button, Elevation, Spinner, Intent, NonIdealState } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import { getTradepoint } from 'app/api';
import { useRequestImmediate } from 'app/utils';

import Tradepoint from './Tradepoint';
import History from './History';
import styles from './TradepointScreen.module.css';

const TradepointCard = ({ tradepointState, t }) => {
  if (tradepointState.fetching) {
    return <Spinner />;
  }
  if (tradepointState.error) {
    return <NonIdealState title={t('error')} description={tradepointState.error.message} icon="error" />;
  }
  if (!tradepointState.data) {
    return <NonIdealState title={t('empty')} description={t('tt not found')} icon="list" />;
  }

  return (
    <Card elevation={Elevation.TWO}>
      <Tradepoint tradepoint={tradepointState.data} t={t} />
      <History />
    </Card>
  );
};

TradepointCard.propTypes = {
  tradepointState: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const TradepointScreen = ({ match, t }) => {
  const id = parseInt(match.params.id, 10);

  const tradepointState = useRequestImmediate(getTradepoint, id);
  const tradepoint = tradepointState.data;

  let name = tradepoint?.network || tradepoint?.name;
  name = name ? `${name}, ` : '';

  const documentTitle = (
    tradepoint
    ? `${t('tradepoint')} ${name}${tradepoint?.address || ''}`
    : t('view tt')
  );
  return (
    <Page documentTitle={documentTitle}>
      <div className={styles.header}>
        <H2>{t('view tt')}</H2>
        {!tradepointState.fetching && !tradepointState.error && (
          <Link to={`/tradepoint/${id}/edit`}>
            <Button icon="edit" intent={Intent.PRIMARY}>
              {t('edit')}
            </Button>
          </Link>
        )}
      </div>
      <TradepointCard tradepointState={tradepointState} t={t} />
    </Page>
  );
};

TradepointScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(TradepointScreen);
