import React, { useState, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Icon, Card, Elevation, Intent, Tag } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as api from 'app/api';
import * as utils from 'app/utils';
import * as config from 'app/config';
import { dispatch, getState } from 'app/state';
import * as selectors from 'app/state/reducers';
import * as grids from 'app/state/actionCreators/grids';
import alerts from 'app/widgets/alerts';
import NewEventDialog from 'app/widgets/NewEventDialog';
import PhotoPreview from 'app/widgets/Photos/PhotoPreview';
import ImageDescription from 'app/widgets/ImageDescription';
import Lightbox from 'app/widgets/Lightbox';
import NoCamera from 'app/assets/no-camera.svg';

import styles from './FeedScreen.module.css';

function photoTitle(photo, t) {
  let { title } = photo;
  if (photo.moment === 'before') {
    title += ` [${t('photo before')}]`;
  }
  if (photo.moment === 'after') {
    title += ` [${t('photo after')}]`;
  }
  return title;
}

function getLightboxImages({ task, showPhotos, t }) {
  if (!showPhotos) {
    return [];
  }

  let { photos } = task;
  if (showPhotos !== 'all') {
    photos = photos.filter((p) => !p.ir);
  }

  return photos.map((photo) => ({
    title: photoTitle(photo, t),
    src: photo.url ?? `/files/${photo.key}`,
    description: photo,
  }));
}

function renderImage({ canViewFakePhoto, t }, idx, image, toggleLightbox) {
  const { url, uuid, key, is_fake, ir, title } = image.description;
  return (
    <span className={styles.resultCardPhotoWrapper} key={uuid ?? url}>
      <PhotoPreview
        className={styles.resultCardPhoto}
        src={url ?? `/files/previews/${key}`}
        alt={title}
        onClick={toggleLightbox.bind(null, idx)}
      />
      {canViewFakePhoto && is_fake && (
        <Icon
          htmlTitle={t('image changed')}
          icon="error"
          intent={Intent.WARNING}
          className={styles.resultCardPhotoWarning}
        />
      )}
      {ir && (
        <Icon
          htmlTitle="IR"
          icon="camera"
          intent={Intent.PRIMARY}
          className={styles.resultCardPhotoWarning}
        />
      )}
    </span>
  );
}

async function deletePhoto({ lightbox, task, image, t }) {
  lightbox.current.toggleLightbox();

  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deleteTaskPhoto(task.id, image.description.uuid),
    cancelButtonText: t('cancel'),
    confirmButtonText: t('delete'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: (
      <>
        {t('delete photo permanently')}?
        <br />
        <img src={image.src} alt={image.title} className={styles.deletePhotoAlertImg} />
      </>
    ),
  });
  if (confirmed && !error) {
    dispatch(grids.deleteFeedPhoto(task.id, image.description.uuid));
  }
}

function renderDeletePhotoButton({ task, lightbox, t }, image) {
  const canDeletePhotos = selectors.hasRole(
    getState(),
    'teamleader', 'regional_manager', 'merch_director', 'agency_admin', 'admin',
  );
  if (!canDeletePhotos) {
    return null;
  }

  return (
    <Button
      minimal
      large
      icon="trash"
      className={styles.deletePhotoButton}
      intent={Intent.DANGER}
      onClick={() => deletePhoto({ task, lightbox, image, t })}
    />
  );
}

function renderDescription({ task, hideCreatePhotoTime }, photo) {
  if (!photo) {
    return null;
  }

  const renderDateFunc = hideCreatePhotoTime ? utils.toLocaleDateString : utils.toLocaleString;
  const date = (
    photo.ir
      ? utils.parseIrPhotoDate(
        photo.url,
        task.start_utc_offset_minutes,
        task.task_group_start_time_utc,
        task.start_time,
      )
      : photo.create_photo_time
  );

  let coordinates = photo.create_photo_coordinates;
  if (!coordinates && task.start_latitude) {
    coordinates = [task.start_latitude, task.start_longitude];
  }

  return (
    <ImageDescription
      address={task.address}
      network={task.network}
      date={renderDateFunc(date ?? task.start_time)}
      coordinates={coordinates}
    />
  );
}

function renderWithInventory(result, t) {
  if (_.isBoolean(result)) {
    return result ? t('yes') : t('no');
  }
  if (_.isArray(result)) {
    return result.join(', ');
  }

  return _.toString(result);
}

function handleClickCreateEvent({ task, setNewEvent }) {
  setNewEvent({
    contragent_id: task.contragent_id,
    tradepoint_id: task.tradepoint_id,
  });
}

function handleCloseNewEventDialog({ setNewEvent }, created) {
  if (created) {
    dispatch(grids.fetch(api.FEED, {
      raw: true,
      transientFilters: ['agency_id__eq', 'questionary_title__eq', 'question_id__eq'],
    }));
  }
  setNewEvent(null);
}

const TaskCard = React.memo(({ task, showPhotos }) => {
  const lightbox = useRef(null);
  const [newEvent, setNewEvent] = useState(null);
  const { t } = useTranslation();

  const showRestoredTasksMark = utils.useSelectorMemo(selectors.canRestoredTasksMark);
  const canViewFakeGPS = utils.useSelectorMemo(selectors.canViewFakeGPS);
  const canViewFakePhoto = utils.useSelectorMemo(selectors.canViewFakePhoto);
  const hideVisitUsers = utils.useSelectorMemo(selectors.needHideVisitUsers);
  const hideVisitMerch = utils.useSelectorMemo(selectors.needHideVisitMerch);
  const hideWorkTime = utils.useSelectorMemo(selectors.makeHasRight('webapp_contragent_hide_work_time'));
  const hideStartEndDate = utils.useSelectorMemo(selectors.makeHasRight('webapp_contragent_hide_start_end_date'));
  const hideRemoteFinishedTag = utils.useSelectorMemo(selectors.makeHasRight('webapp_contragent_hide_remote_finished'));
  const hideCreatePhotoTime = utils.useSelectorMemo(selectors.makeHasRight('webapp_contragent_hide_create_photo_time'));
  const canViewEvent = utils.useSelectorMemo(selectors.makeHasRight('webapp_events'));
  const canEditEvent = utils.useSelectorMemo(selectors.makeHasRight('webapp_events_edit'));

  const useManagerAsTerritory = utils.useSelectorMemo(selectors.makeHasSetting('contragent_use_manager_as_territory'));

  const showVisibilityRestrictions = task.task_type === 'merchandising';

  const showFakeGPSTag = canViewFakeGPS && (task.start_is_fake_gps || task.end_is_fake_gps);
  const showFakePhotosTag = canViewFakePhoto && task.photos.some((p) => p.is_fake);

  const firstPhotoTime = utils.toLocaleString(
    _.minBy(
      task.photos.filter((p) => p.moment !== 'before'),
      'create_photo_time',
    )?.create_photo_time,
  );
  const lastPhotoTime = utils.toLocaleString(
    _.maxBy(
      task.photos,
      'create_photo_time',
    )?.create_photo_time,
  );

  const lightboxImages = utils.useMemoWithDeps(getLightboxImages, { task, showPhotos, t });
  const renderImageFunc = utils.useCallbackWithDeps(renderImage, { canViewFakePhoto, t });
  const renderCustomModifiers = utils.useCallbackWithDeps(renderDeletePhotoButton, { task, lightbox, t });
  const renderDescriptionFunc = utils.useCallbackWithDeps(renderDescription, { task, hideCreatePhotoTime });

  const onClickCreateEvent = utils.useCallbackWithDeps(handleClickCreateEvent, { task, setNewEvent });
  const onCloseNewEventDialog = utils.useCallbackWithDeps(handleCloseNewEventDialog, { setNewEvent });

  return (
    <Card elevation={Elevation.TWO} className={styles.resultCard} key={task.id}>

      <div className={styles.resultCardHeader}>
        <div><strong>{t(config.TASK_TYPES[task.task_type])} № {task.id}</strong></div>
        <div>{utils.toLocaleString(task.end_time)}</div>
      </div>

      <div className={styles.resultCardInfo}>
        <div className={styles.resultCardInfoGroup}>
          {showRestoredTasksMark && task.restored && (
            <p className={styles.restoredInfo}>
              <Icon icon="snowflake" intent={Intent.PRIMARY} />
              {` ${t('restored visit')}`}
            </p>
          )}
          {!hideVisitUsers && !hideVisitMerch && (
            <p>{t('executor')} — {task.user_fullname} ({task.user_phone})</p>
          )}
          <p>{t('executor id')} — {task.user_id}</p>
          {!hideVisitUsers && !!task.supervisor_phone && (
            <p>{t('supervisor')} — {task.supervisor_fullname} ({task.supervisor_phone})</p>
          )}
          {task.network && (
            <p>{t('network')} ({t('lowercase signboard')}) — {task.network}</p>
          )}
          {task.tradepoint_name && (
            <p>{t('name of tt')} — {task.tradepoint_name}</p>
          )}
          {task.address && (
            <p>{t('address')} — {task.address}</p>
          )}
          {task.branch && (
            <p>{t('affiliate')} — {task.branch}</p>
          )}
          {task.territory && !(useManagerAsTerritory && hideVisitUsers) && (
            <p>{t('territory')} — {task.territory}</p>
          )}
          {task.project && (
            <p>{t('extra project')} — {task.project}</p>
          )}
          <p>{t('point code')} — {task.code}</p>
          {task.ir_code && (
            <p>{t('code tt for ir')} — {task.ir_code}</p>
          )}
          {task.visit_ir_type && (
            <p>{t('type of visit for ir')} — {task.visit_ir_type}</p>
          )}
          {!_.isNil(task.ir_transition) && (
            <p>{t('ir transition')} — {task.ir_transition ? t('yes') : t('no')}</p>
          )}
          <p>{t('type of visit')} — {t(config.TASK_TYPES[task.task_type])}</p>
          {showVisibilityRestrictions && !!task.visibility_restrictions.length && (
            <p>
              {t('visibility')} — {
                task.visibility_restrictions.map((r) => t(config.VISIBILITY_RESTRICTIONS[r])).join('; ')
              }
            </p>
          )}
          {task.comment && (
            <p className={styles.wordBreak}>{t('comment')} — {task.comment}</p>
          )}
          {task.with_inventory && (
            <p>{t('visit with_inventory')}? - {renderWithInventory(task.with_inventory_result, t)}</p>
          )}
          {task.task_type === 'event' && (
            <p>{t('description')} - {task.detail_text}</p>
          )}
          {task.task_type === 'event' && (
            <p>{t('comment')} - {task.result_text}</p>
          )}
        </div>
        <div className={utils.classNames(styles.resultCardInfoGroup, styles.textAlignRight)}>
          {!hideStartEndDate && (
            <>
              <p>{t('start of visit')} — {utils.toLocaleString(task.start_time)}</p>
              <p>{t('end of visit')} — {utils.toLocaleString(task.end_time)}</p>
            </>
          )}
          {!hideCreatePhotoTime && firstPhotoTime && (
            <p>{t('first photo')} — {firstPhotoTime}</p>
          )}
          {!hideCreatePhotoTime && lastPhotoTime && (
            <p>{t('last photo')} — {lastPhotoTime}</p>
          )}
          {!hideWorkTime && (
            <p>{t('time spent in tt')} — {utils.formatIntervalBetween(task.start_time, task.end_time)}</p>
          )}
          {!hideRemoteFinishedTag && (
            <Tag
              round
              rightIcon={task.remote_finished ? false : 'small-tick'}
              intent={task.remote_finished ? Intent.DANGER : Intent.SUCCESS}
              className={styles.tag}
            >
              {task.remote_finished ? t('ended outside tt') : t('ended in tt')}
            </Tag>
          )}
          {showFakeGPSTag && (
            <Tag round rightIcon="error" intent={Intent.WARNING} className={styles.tag}>
              {` ${t('used fake gps')} `}
            </Tag>
          )}
          {showFakePhotosTag && (
            <Tag round rightIcon="error" intent={Intent.WARNING} className={styles.tag}>
              {t('fake photos')}
            </Tag>
          )}
        </div>
      </div>

      <div className={styles.resultCardPhotos}>
        <Lightbox
          ref={lightbox}
          thumbnailWidth="160px"
          thumbnailHeight="160px"
          images={lightboxImages}
          onErrorSrc={NoCamera}
          renderImageFunc={renderImageFunc}
          renderCustomModifiers={renderCustomModifiers}
          renderDescriptionFunc={renderDescriptionFunc}
        />
      </div>

      <div className={styles.actionsGroup}>
        {task.task_type !== 'event' && !!task.photos.length && (
          <a
            href={`/webapi/tasks/${task.id}/photos/export`}
            download={`${task.id}.zip`}
          >
            <Button
              text={t('download photos feed')}
              intent={Intent.PRIMARY}
              minimal
              icon="media"
            />
          </a>
        )}

        <div className={styles.spacer} />

        {!!task.events_count && `${t('eventsInVisit')}: ${task.events_count}`}
        {canViewEvent && (
          <Link to={`/events/tradepoint/${task.tradepoint_id}`} className={styles.eventCount}>
            {t('new tasks in tt')}: {task.new_events_count}
          </Link>
        )}
        {canEditEvent && (
          <Button
            text={t('create task for tt')}
            intent={Intent.PRIMARY}
            icon="notifications"
            onClick={onClickCreateEvent}
          />
        )}
        {task.task_type !== 'event' && (
          <Link to={`/tasks/${task.id}/result`}>
            <Button
              text={t('view report')}
              intent={Intent.PRIMARY}
              minimal
              icon="info-sign"
            />
          </Link>
        )}
        {task.task_type === 'event' && task.group_id && (
          <Link to={`/task-groups/${task.group_id}/result`}>
            <Button
              text={t('view report')}
              intent={Intent.PRIMARY}
              minimal
              icon="info-sign"
            />
          </Link>
        )}
      </div>

      {newEvent && (
        <NewEventDialog
          initialData={newEvent}
          onClose={onCloseNewEventDialog}
        />
      )}
    </Card>
  );
});

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contragent_id: PropTypes.number.isRequired,
    tradepoint_id: PropTypes.number.isRequired,
    user_id: PropTypes.number.isRequired,
    group_id: PropTypes.number,
    task_type: PropTypes.string.isRequired,
    visibility_restrictions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    detail_text: PropTypes.string,
    result_text: PropTypes.string,
    with_inventory: PropTypes.bool.isRequired,
    with_inventory_result: PropTypes.any,
    restored: PropTypes.bool.isRequired,
    user_fullname: PropTypes.string,
    user_phone: PropTypes.string,
    supervisor_fullname: PropTypes.string,
    supervisor_phone: PropTypes.string,
    network: PropTypes.string,
    tradepoint_name: PropTypes.string,
    address: PropTypes.string.isRequired,
    branch: PropTypes.string,
    territory: PropTypes.string,
    project: PropTypes.string,
    code: PropTypes.string.isRequired,
    ir_code: PropTypes.string,
    visit_ir_type: PropTypes.string,
    ir_transition: PropTypes.bool,
    comment: PropTypes.string,
    remote_finished: PropTypes.bool.isRequired,

    start_time: PropTypes.string.isRequired,
    start_latitude: PropTypes.string.isRequired,
    start_longitude: PropTypes.string.isRequired,
    start_is_fake_gps: PropTypes.bool,

    end_time: PropTypes.string.isRequired,
    end_is_fake_gps: PropTypes.bool,

    new_events_count: PropTypes.number,
    events_count: PropTypes.number,

    photos: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string,
      url: PropTypes.string,
      title: PropTypes.string.isRequired,
      ir: PropTypes.bool,
      moment: PropTypes.string,
      is_fake: PropTypes.bool,
      create_photo_time: PropTypes.string,
      create_photo_coordinates: PropTypes.arrayOf(PropTypes.number.isRequired),
    })).isRequired,
  }).isRequired,
  showPhotos: PropTypes.string.isRequired,
};

export default TaskCard;
