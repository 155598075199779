import React from 'react';
import { Icon, Button, FormGroup, Intent, Spinner, NonIdealState } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import * as config from 'app/config';
import { dispatch } from 'app/state';
import * as selectors from 'app/state/reducers';
import * as grids from 'app/state/actionCreators/grids';
import Page from 'app/widgets/Page';
import AbstractGrid from 'app/widgets/Grid/AbstractGrid';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import ComboFilter from 'app/widgets/Filters/ComboFilter';
import InputFilter from 'app/widgets/Filters/InputFilter';
import IdFilter from 'app/widgets/Filters/IdFilter';
import * as Filters from 'app/widgets/Filters';
import FancySelect from 'app/widgets/FancySelect';

import TaskCard from './TaskCard';
import FeedCount from './FeedCount';
import styles from './FeedScreen.module.css';

const INITIAL_SORT = { field: 'end_time', direction: 'desc' };

function handleChangeSort(grid, sort) {
  const [field, direction] = sort.split('.');
  dispatch(grids.setSort(api.FEED, { field, direction }));
  grid.current.fetch();
}

export default React.memo(() => {
  const grid = React.useRef(null);
  const { t } = useTranslation();

  const showAgencyFilter = RRU.useSelector(selectors.canViewAgencies);
  const showCashboxFeedFilter = RRU.useSelector(selectors.showCashboxFeedFilter);
  const isCheckManager = RRU.useSelector((
    state => selectors.hasRole(state, 'check_manager', 'chief_of_check_manager')
  ));
  const hasContragentRole = RRU.useSelector(state => selectors.hasRole(state, 'contragent'));
  const hideFeedTaskType = RRU.useSelector(selectors.hideFeedTaskType);
  const [showPhotos, setShowPhotos] = React.useState(isCheckManager ? 'all' : 'wodo');

  const TASK_TYPE_ITEMS = Object.entries(config.TASK_TYPES).map(([value, title]) => ({ value, title: t(title) }));

  const SORT_ITEMS = [
    {
      id: 'end_time.desc',
      label: <><Icon className={styles.sortIcon} icon="sort-desc" />{` ${t('end of visit')}`}</>,
      unchangeable: true,
    },
    {
      id: 'end_time.asc',
      label: <><Icon className={styles.sortIcon} icon="sort-asc" />{` ${t('end of visit')}`}</>,
      unchangeable: true,
    },
    {
      id: 'start_time.desc',
      label: <><Icon className={styles.sortIcon} icon="sort-desc" />{` ${t('start of visit')}`}</>,
      unchangeable: true,
    },
    {
      id: 'start_time.asc',
      label: <><Icon className={styles.sortIcon} icon="sort-asc" />{` ${t('start of visit')}`}</>,
      unchangeable: true,
    },
  ];
  const onChangeSort = RRU.useCallbackArgs(handleChangeSort, grid);

  const SHOW_PHOTOS_ITEMS = [
    { id: '', label: t('hide') },
    { id: 'all', label: t('all') },
    { id: 'wodo', label: t('only wodo') },
  ];

  const canRestoredTasksEditor = RRU.useSelector(selectors.canRestoredTasksEditor);
  const hideVisitUsers = RRU.useSelector(selectors.needHideVisitUsers);
  const hideVisitMerch = RRU.useSelector(selectors.needHideVisitMerch);
  const hideCrisisManager = RRU.useSelector(selectors.needHideCrisisManager);
  const hideRemoteFinished = RRU.useSelector(selectors.makeHasRight('webapp_contragent_hide_remote_finished'));
  const canRestoredTasksMark = RRU.useSelector(selectors.canRestoredTasksMark);

  const data = RRU.useSelector(`grids.${api.FEED}.data`);
  const fetching = RRU.useSelector(`grids.${api.FEED}.fetching`) ?? true;
  const sort = RRU.useSelector(`grids.${api.FEED}.sort`);
  const sortId = sort ? `${sort?.field}.${sort?.direction}` : 'end_time.desc';

  return (
    <Page title={t('feed')}>
      <AbstractGrid
        ref={grid}
        path={api.FEED}
        raw
        initialSort={INITIAL_SORT}
        initialPageSize={5}
        showTotal
        showItemsPerPage={false}
        footerPagination
      >
        <AbstractGrid.Actions className={styles.actions}>
          {canRestoredTasksEditor && (
            <Link to="/history/create">
              <Button
                icon="add"
                intent={Intent.SUCCESS}
                text={t('create visit')}
                className={styles.filtersButton}
              />
            </Link>
          )}

          <FormGroup inline label={`${t('photo')}:`} className={styles.showPhotoSelect}>
            <FancySelect
              data={SHOW_PHOTOS_ITEMS}
              value={showPhotos}
              onChange={setShowPhotos}
              multi={false}
            />
          </FormGroup>

          <FormGroup inline label={`${t('sorting')}:`} className={styles.sortSelect}>
            <FancySelect
              data={SORT_ITEMS}
              value={sortId}
              onChange={onChangeSort}
              multi={false}
            />
          </FormGroup>

          <FeedCount />
        </AbstractGrid.Actions>

        {showAgencyFilter && (
          <AbstractGrid.Filter
            acceptor={Filters.AgencyFilter}
            path="agency_id__eq"
            transient
            multi={false}
            autoSelectFirst
          />
        )}
        <AbstractGrid.Filter
          acceptor={Filters.ContragentFilter}
          path="contragent_id__eq"
          dependsOn="agency_id__eq"
          multi={false}
          autoSelectFirst
        />
        <AbstractGrid.Filter
          defaultValue={[
            moment().startOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
            moment().endOf('day').format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
          ]}
          acceptor={DaterangeFilter}
          path="end_time__between"
          name={t('date of visit')}
          shortcuts="weekToWeek"
          withTime
        />
        <AbstractGrid.Filter
          acceptor={Filters.UserTaskIdFilter}
          path="id__eq"
          withDebounce
        />
        {!hideVisitUsers && (
          <AbstractGrid.Filter
            acceptor={Filters.UserFilter}
            path="supervisor_id__eq"
            name={t('supervisor')}
            dependsOn="contragent_id__eq"
            filters={{ roles__overlap: ['supervisor', 'teamleader'] }}
            multi={false}
            clearable
          />
        )}
        {hasContragentRole && (
          <AbstractGrid.Filter
            acceptor={IdFilter}
            path="manager_id__eq"
            withDebounce
            name={t('supervisorId')}
            placeholder={t('searchBySupervisorId')}
          />
        )}
        {!hideVisitUsers && !hideVisitMerch && (
          <AbstractGrid.Filter
            acceptor={Filters.UserFilter}
            path="user_id__eq"
            name={t('executor')}
            dependsOn="contragent_id__eq"
            filters={{ roles__overlap: ['merch', 'supervisor', 'contragent', 'agency_contragent'] }}
            multi={false}
            clearable
          />
        )}
        {hasContragentRole && (
          <AbstractGrid.Filter
            acceptor={IdFilter}
            path="user_id__eq"
            withDebounce
            name={t('executor id')}
            placeholder={t('searchByExecutorId')}
          />
        )}
        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="code__fuzzy_like"
          withDebounce
          name={t('point code')}
          placeholder={t('search by point code')}
        />
        {!hideFeedTaskType && (
          <AbstractGrid.Filter
            acceptor={ComboFilter}
            defaultValue="merchandising"
            path="task_type__eq"
            emptiable
            name={t('type of visit')}
            items={TASK_TYPE_ITEMS}
          />
        )}
        <AbstractGrid.Filter
          acceptor={Filters.BranchFilter}
          path="branch_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Filters.TradeNetworkFilter}
          path="network_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Filters.TradepointProjectFilter}
          path="project_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Filters.TradepointFormatFilter}
          path="format_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Filters.TradepointTerritoryFilter}
          path="territory_id__in"
          dependsOn="contragent_id__eq"
        />
        {!hideCrisisManager && canRestoredTasksMark && (
          <AbstractGrid.Filter
            acceptor={Filters.BoolFilter}
            path="restored__eq"
            name={t('restored visit')}
          />
        )}
        <AbstractGrid.Filter
          acceptor={Filters.BoolFilter}
          path="has_ir_module__eq"
          name={t('visit with ir')}
        />
        <AbstractGrid.Filter
          acceptor={Filters.BoolFilter}
          path="has_ir_result__eq"
          name={t('ir result')}
        />
        <AbstractGrid.Filter
          acceptor={Filters.BoolFilter}
          path="has_equipment_answer__eq"
          name={t('repair of equipment')}
        />
        {!hideRemoteFinished && (
          <AbstractGrid.Filter
            acceptor={Filters.BoolFilter}
            path="remote_finished__eq"
            name={t('ended outside tt')}
          />
        )}
        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="ir_code__ilike"
          name={t('extra code tt for ir')}
          placeholder={t('search by extra code tt for ir')}
        />
        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="ir_type__ilike"
          name={t('type of ir visit')}
          placeholder={t('search by type of ir visit')}
        />
        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="address__fuzzy_like"
          name={t('address')}
          placeholder={t('search by address')}
        />
        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="city__fuzzy_like"
          name={t('city')}
          placeholder={t('search by city')}
        />
        <AbstractGrid.Filter
          acceptor={Filters.QuestionaryFilter}
          path="questionary_id__eq"
          dependsOn="contragent_id__eq"
          multi={false}
          clearable
        />
        <AbstractGrid.Filter
          acceptor={Filters.QuestionaryCategoryFilter}
          path="questionary_category_id__in"
          dependsOn="contragent_id__eq"
        />
        <AbstractGrid.Filter
          acceptor={Filters.QuestionaryTitleFilter}
          path="questionary_title__eq"
          dependsOn="questionary_id__eq"
          transient
          multi={false}
          clearable
        />
        <AbstractGrid.Filter
          acceptor={Filters.QuestionFilter}
          path="question_id__eq"
          dependsOn="questionary_title__eq"
          transient
          multi={false}
          clearable
        />
        <AbstractGrid.Filter
          acceptor={InputFilter}
          path="question__ilike"
          name={t('text of question')}
          placeholder={t('search by text of question')}
        />
        <AbstractGrid.Filter
          acceptor={Filters.QuestionVariantFilter}
          path="question_answer__in"
          dependsOn="question_id__eq"
        />

        {showCashboxFeedFilter && (
          <AbstractGrid.Filter
            acceptor={Filters.CheckedEquipmentFilter}
            path="cashboxes_status__eq"
          />
        )}

        <div className={styles.content}>
          {fetching && <Spinner />}
          {!fetching && !data?.length && <NonIdealState title={t('empty')} icon="list" iconSize="60" />}
          {!fetching && data.map((task) => <TaskCard key={task.id} task={task} showPhotos={showPhotos} />)}
        </div>
      </AbstractGrid>
    </Page>
  );
});
