import PropTypes from 'prop-types';

export const kladrType = PropTypes.shape({
  id: PropTypes.number,
  province: PropTypes.string,
  locality: PropTypes.string,
  street: PropTypes.string,
  house: PropTypes.string,
  postal_code: PropTypes.string,
});

export const tradepointInfoType = PropTypes.shape({
  address: PropTypes.string,
  city: PropTypes.string,
  code: PropTypes.string,
  format: PropTypes.string,
  name: PropTypes.string,
  network: PropTypes.string,
  project: PropTypes.string,
  type: PropTypes.string,
  geo: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  kladr: kladrType,
  kladr_status: PropTypes.oneOf([
    'parsed',
    'unparsed',
    'error',
    'blocked',
  ]),
});

export const tradepointType = PropTypes.shape({
  contragent: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  branch: PropTypes.string,
  city: PropTypes.string.isRequired,
  name: PropTypes.string,
  network: PropTypes.string,
  format: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  project: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  ir_code: PropTypes.string,
  last_time: PropTypes.string.isRequired,
  kladr_id: PropTypes.number,
  kladr_status: PropTypes.string.isRequired,
  cashbox_count: PropTypes.number,
});

export const tradepointAudit = PropTypes.shape({
  address: PropTypes.string,
  audit_status: PropTypes.string,
  code: PropTypes.string,
  date: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  expired_time: PropTypes.string,
  has_audit: PropTypes.bool,
  has_quests: PropTypes.bool,
  id: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  format: PropTypes.string,
  name: PropTypes.string,
  network: PropTypes.string,
  status: PropTypes.string,
  task_id: PropTypes.number,
  unscheduled: PropTypes.bool,
  work_time: PropTypes.number,
});

export const taskType = PropTypes.shape({
  id: PropTypes.number,
  comment: PropTypes.string,
  contragentCode: PropTypes.string,
  contragentSettings: PropTypes.any,
  data: PropTypes.object,
  endDate: PropTypes.any,
  endToDate: PropTypes.any,
  photos: PropTypes.array,
  result: PropTypes.object,
  startDate: PropTypes.any,
  startFromDate: PropTypes.any,
  status: PropTypes.string,
  travelTime: PropTypes.any,
  type: PropTypes.string,
  userId: PropTypes.number,
  userName: PropTypes.string,
  userPhone: PropTypes.string,
  eventsCount: PropTypes.number,
});

export const eventType = PropTypes.shape({
  id: PropTypes.number,
  status: PropTypes.string,
  start_time: PropTypes.instanceOf(Date),
  end_time: PropTypes.instanceOf(Date),
  expired_time: PropTypes.instanceOf(Date),
  create_date: PropTypes.instanceOf(Date), // без времени
  tradepoint: PropTypes.shape({
    id: PropTypes.number,
    info: tradepointInfoType,
  }),
  detail: PropTypes.shape({
    text: PropTypes.string,
  }),
  result: PropTypes.shape({
    text: PropTypes.string,
    status: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.any),
  }),
  author: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  executor: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
  }),
  is_expired: PropTypes.bool,
});

export const scheduleDateType = PropTypes.shape({
  id: PropTypes.string,
  date: PropTypes.string,
  visited: PropTypes.bool,
  inwork: PropTypes.bool,
});

export const scheduleTradepointType = PropTypes.shape({
  id: PropTypes.number,
  address: PropTypes.string,
  city: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  network: PropTypes.string,
});

export const scheduleUserType = PropTypes.shape({
  id: PropTypes.string,
  fullname: PropTypes.string,
  phone: PropTypes.string,
});

export const scheduleType = PropTypes.shape({
  dates: PropTypes.arrayOf(scheduleDateType),
  tradepoint: scheduleTradepointType,
  supervisor: scheduleUserType,
  user: scheduleUserType,
});

export const roleType = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const userRefType = PropTypes.shape({
  id: PropTypes.number,
  fullname: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
});

export const userContragentType = PropTypes.shape({
  contragent_id: PropTypes.number,
  contragent_code: PropTypes.string,
  contragent_fullname: PropTypes.string,
  exclusive: PropTypes.bool,
  is_active: PropTypes.bool,
  start_work_time: PropTypes.any,
  end_work_time: PropTypes.any,
  manager: PropTypes.shape({
    id: PropTypes.number,
    fullname: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
});

export const branchType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  contragent_id: PropTypes.number,
  create_time: PropTypes.string,
  last_time: PropTypes.string,
});

export const userType = PropTypes.shape({
  id: PropTypes.number,
  active: PropTypes.bool,
  agencyId: PropTypes.number,
  agreement_accept: PropTypes.bool,
  code: PropTypes.string,
  contragentId: PropTypes.number,
  contragentCode: PropTypes.string,
  contragents: PropTypes.arrayOf(userContragentType),
  email: PropTypes.string,
  firstname: PropTypes.string,
  secondname: PropTypes.string,
  lastname: PropTypes.string,
  fullname: PropTypes.string,
  createTime: PropTypes.any,
  last_time: PropTypes.any,
  parentId: PropTypes.number,
  phone: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  exclusive: PropTypes.bool,
  project: PropTypes.string,
  network: PropTypes.string,
  regional: PropTypes.bool,
  branches: PropTypes.objectOf(PropTypes.arrayOf(branchType)),
});

export const paginationType = PropTypes.shape({
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
});

export const errorType = PropTypes.shape({
  message: PropTypes.string.isRequired,
});

export const bindingType = PropTypes.shape({
  questionaryById: PropTypes.object,
  tradepointById: PropTypes.object,
});

export const contragentType = PropTypes.shape({
  id: PropTypes.number,
  code: PropTypes.string,
  contract: PropTypes.string,
  fullname: PropTypes.string,
  active: PropTypes.bool,
  settings: PropTypes.shape({
    checkProductSignals: PropTypes.bool,
    report_all_signals: PropTypes.bool,
    tradepoint_photo_not_required: PropTypes.bool,
    contragent_view_task_user: PropTypes.oneOf(['user', 'parent_user', 'hide']),
    contragent_hide_restored_tasks: PropTypes.bool,
    contragent_forbid_view_task_map: PropTypes.bool,
    contragent_single_task_per_tradepoint: PropTypes.bool,
    contragent_can_audit: PropTypes.bool,
  }),
});

export const reportFieldType = PropTypes.shape({
  name: PropTypes.string,
  field: PropTypes.string,
  filter_type: PropTypes.oneOf(['text', 'date', 'multycombo', 'combo', 'id', 'number', 'bool']),
  index: PropTypes.number,
  checked: PropTypes.bool,
});

export const kpiType = PropTypes.shape({
  user_id: PropTypes.number,
  user_fullname: PropTypes.string,
  user_tasks_date: PropTypes.instanceOf(Date),
  start_time: PropTypes.instanceOf(Date),
  depth: PropTypes.number,

  total_visits: PropTypes.number,
  total_scheduled_visits: PropTypes.number,
  processed_scheduled_visits: PropTypes.number,
  processed_unscheduled_visits: PropTypes.number,
  total_alert_visits: PropTypes.number,
  processed_alert_visits: PropTypes.number,
  total_signals: PropTypes.number,
  processed_signals: PropTypes.number,
});

export const kpiTotalType = PropTypes.shape({
  total_visits: PropTypes.number,
  processed_visits: PropTypes.number,
  total_tradepoints: PropTypes.number,
  processed_tradepoints: PropTypes.number,
});

export const LatLon = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const planogramsItemType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  contragent_id: PropTypes.number,
  type: PropTypes.oneOf(['text', 'pdf']),
});

export const planogramType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  contragent_id: PropTypes.number,
  detail: PropTypes.object,
  type: PropTypes.oneOf(['text', 'pdf']),
  text: PropTypes.string,
  icon: PropTypes.string,
  pdf: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    uploading: PropTypes.bool,
  })),
});

export const anythingByIdMap = PropTypes.object;
