import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@blueprintjs/core';

import { useSelectorMemo, useDispatchCallback } from 'app/utils';
import { setSettingsField } from 'app/state/actionCreators/photoExport';
import Form from 'app/widgets/Form';
import FancySelect from 'app/widgets/FancySelect';

export default React.memo(() => {
  const { t } = useTranslation();
  const settings = useSelectorMemo((state) => state.photoExport.settings);
  const onFieldChange = useDispatchCallback(setSettingsField, []);

  const OPTIONS = [
    { id: 'branch', label: t('affiliate') },
    { id: 'network', label: t('network') },
    { id: 'address', label: t('address') },
    { id: 'tradepoint_code', label: t('point code') },
    { id: 'date', label: t('date') },
  ];

  return (
    <Form inline value={settings} onFieldChange={onFieldChange}>
      <Form.Item
        label={t('unload folders')}
        name="grouping"
        acceptor={FancySelect}
        data={OPTIONS}
      />
      <Form.Item
        label={t('photo title')}
        name="naming"
        acceptor={FancySelect}
        data={OPTIONS}
      />
      <Form.Item
        label={t('equipment check')}
        name="equipment"
        acceptor={Switch}
      />
    </Form>
  );
});
