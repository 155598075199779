import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchEvents, clearFilters, setFilters, setPagination } from 'app/state/actionCreators/events';
import { hasRight } from 'app/state/reducers';
import { dispatch } from 'app/state';
import { useCallbackWithDeps } from 'app/utils';
import { eventType } from 'app/proptyping';
import EventList from 'app/widgets/EventList';
import NewEventDialog from 'app/widgets/NewEventDialog';

function openNewEventDialog({ setNewEvent }, event) {
  setNewEvent({
    contragent_id: event.tradepoint.contragent_id,
    tradepoint_id: event.tradepoint.id,
    text: event.detail.text,
    expired_time: event.expiredTime,
  });
}

function handleNewEventDialogClose({ setNewEvent, filters, pagination }, created) {
  setNewEvent(null);
  if (created) {
    dispatch(fetchEvents(filters, pagination));
  }
}

const EventsTab = ({
  events,
  pagination,
  filters,
  fetching,
  error,
  tradepointId,
  endDate,
  hideCreatePhotoTime,
}) => {
  useEffect(() => {
    if (filters.tradepoint_id__eq) {
      dispatch(fetchEvents(filters, pagination));
    }
  }, [filters, pagination]);

  useEffect(() => {
    if (tradepointId) {
      dispatch(setPagination({ currentPage: 1, itemsPerPage: 1000 }));
      dispatch(setFilters({
        end_time__from: endDate,
        end_time__to: endDate,
        tradepoint_id__eq: tradepointId,
        status__in: ['COMPLETED', 'SKIPPED'],
      }));
    }
    return () => dispatch(clearFilters());
  }, [tradepointId]);

  const [newEvent, setNewEvent] = useState(null);
  const onCopyEvent = useCallbackWithDeps(openNewEventDialog, { setNewEvent });
  const onNewEventDialogClose = useCallbackWithDeps(handleNewEventDialogClose, { filters, pagination, setNewEvent });

  return (
    <>
      <EventList
        events={events}
        fetching={fetching}
        error={error}
        onCopyEvent={onCopyEvent}
        hideCreatePhotoTime={hideCreatePhotoTime}
      />
      {newEvent && <NewEventDialog copying initialData={newEvent} onClose={onNewEventDialogClose} />}
    </>
  );
};

EventsTab.propTypes = {
  tradepointId: PropTypes.number.isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,

  events: PropTypes.arrayOf(eventType).isRequired,
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  filters: PropTypes.shape({
    status__in: PropTypes.arrayOf(PropTypes.string),
    tradepoint_id__eq: PropTypes.number,
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  hideCreatePhotoTime: PropTypes.bool.isRequired,
};

EventsTab.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  events: state.events.events,
  pagination: state.events.pagination,
  filters: state.events.filters,
  fetching: state.events.fetching,
  error: state.events.error,
  hideCreatePhotoTime: hasRight(state, 'webapp_contragent_hide_create_photo_time'),
});

export default connect(mapStateToProps)(EventsTab);
