import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Alignment,
  Alert,
  H2,
  Button,
  Callout,
  Card,
  Checkbox,
  Elevation,
  FormGroup,
  Intent,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { isAdmin as getIsAdmin, isAgencyAdmin as getIsAgencyAdmin } from 'app/state/reducers';
import { useRequest } from 'app/utils';
import Page from 'app/widgets/Page';
import SimpleSelect from 'app/widgets/SimpleSelect';
import toaster from 'app/widgets/toaster';
import { getContragent, saveContragentSettings } from 'app/api';

import styles from './ContragentEditScreen.module.css';

const ContragentPage = ({ children, t }) => (
  <Page>
    <div className={styles.header}>
      <H2>{t('contractor settings')}</H2>
    </div>
    {children}
  </Page>
);

ContragentPage.propTypes = {
  children: PropTypes.element.isRequired,
  t: PropTypes.func.isRequired,
};

const ContragentEditScreen = ({ isAdmin, isAgencyAdmin }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [{
    fetching,
    error,
    data: contragent,
  }, fetchContragent] = useRequest(getContragent);
  const [{
    fetching: saving,
  }, saveSettings] = useRequest(saveContragentSettings, {
    onSuccess: () => {
      toaster.show({
        intent: Intent.SUCCESS,
        message: t('settings saved'),
        icon: 'tick',
        timeout: 2000,
      });
      history.push('/contragents');
    },
    onError: (err) => toaster.show({
      intent: Intent.DANGER,
      message: err.message || t('error saving settings'),
      icon: 'error',
      timeout: 2000,
    }),
  });

  const TASK_USER_OPTIONS = [
    {
      title: t('showExecutorAndManager'),
      value: 'user',
    },
    {
      title: t('showManagerOnly'),
      value: 'parent_user',
    },
    {
      title: t('doNotShowExecutorAndManager'),
      value: 'hide',
    },
  ];
  const USE_STEPS_OPTIONS = [
    {
      title: t('no'),
      value: false,
    },
    {
      title: t('consecutiveCompletion'),
      value: true,
    },
    {
      title: t('sortOnly'),
      value: 'sort-only',
    },
  ];
  const FORBID_EVENTS_OPTIONS = [
    {
      title: t('viewAndEditEvents'),
      value: false,
    },
    {
      title: t('hideEvents'),
      value: true,
    },
    {
      title: t('viewEvents'),
      value: 'view-only',
    },
  ];
  const TASK_TYPE_OPTIONS = [
    {
      title: t('allTypes'),
      value: null,
    },
    {
      title: t('visit'),
      value: 'merchandising',
    },
    {
      title: t('audit'),
      value: 'audit',
    },
  ];

  const [usersLogoutAlertOpen, setUsersLogoutAlertOpen] = useState(false);
  const [stateSettings, setStateSettings] = useState({});
  const setStateSetting = React.useCallback(
    (k, v) => setStateSettings(old => ({ ...old, [k]: v })),
    [],
  );
  const toggleStateSetting = React.useCallback(
    (k) => setStateSettings(old => ({ ...old, [k]: !old[k] })),
    [],
  );

  useEffect(() => {
    if (id) {
      fetchContragent(id)
        .then((c) => setStateSettings(c?.settings || {}));
    }
  }, [id, fetchContragent]);

  const loading = fetching || saving;

  if (error || (!loading && !contragent)) {
    return (
      <ContragentPage t={t}>
        <NonIdealState title={t('error')} description={error?.message || t('contractor not found')} icon="error" />
      </ContragentPage>
    );
  }

  if (loading) {
    return (
      <ContragentPage t={t}>
        <Spinner />
      </ContragentPage>
    );
  }

  return (
    <ContragentPage t={t}>
      <Card elevation={Elevation.TWO} className={styles.card}>
        <div className={styles.details}>
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('contractor code')}:</div>
            <div className={styles.detailsValue}>{contragent.code || '-'}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('agency')}:</div>
            <div className={styles.detailsValue}>{contragent.agencyName || '-'}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('contractor name')}:</div>
            <div className={styles.detailsValue}>{contragent.fullname || '-'}</div>
          </div>
        </div>

        <Callout title={t('general')} className={styles.section}>
          <Checkbox
            checked={stateSettings.checkProductSignals || false}
            label={t('add mandatory flags to product')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => toggleStateSetting('checkProductSignals')}
          />
          <Checkbox
            checked={stateSettings.report_all_signals || false}
            label={t('display alerts for incomplete visits in BI report')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              report_all_signals: !stateSettings.report_all_signals,
            })}
          />
          <Checkbox
            checked={stateSettings.tradepoint_photo_not_required || false}
            label={t('disable need of signboard photo mobile')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              tradepoint_photo_not_required: !stateSettings.tradepoint_photo_not_required,
            })}
          />
          <Checkbox
            checked={stateSettings.visit_quests_ungroupping || false}
            label={(
              <>
                {t('disable grouping of questionnaires')}
                <br />
                ({t('disables mandatory planograms for tt')})
              </>
            )}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_quests_ungroupping: !stateSettings.visit_quests_ungroupping,
            })}
          />
          <Checkbox
            checked={stateSettings.visit_hide_overlapping_with_tradepoint_interval || false}
            label={t('visit_hide_overlapping_with_tradepoint_interval')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_hide_overlapping_with_tradepoint_interval: (
                !stateSettings.visit_hide_overlapping_with_tradepoint_interval
              ),
            })}
          />
          <Checkbox
            checked={stateSettings.visit_ir_osa_merge || false}
            label={t('combine OSA with IR in mobile')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_ir_osa_merge: !stateSettings.visit_ir_osa_merge,
            })}
          />
          {isAdmin && (
            <Checkbox
              checked={stateSettings.hide_crisis_manager || false}
              label={t('do not display crisis manager functionality')}
              alignIndicator={Alignment.RIGHT}
              onChange={() => setStateSettings({
                ...stateSettings,
                hide_crisis_manager: !stateSettings.hide_crisis_manager,
              })}
            />
          )}
          {(isAdmin || isAgencyAdmin) && (
            <Checkbox
              checked={stateSettings.visit_user_check_timezone || false}
              label={t('perform time check on the client')}
              alignIndicator={Alignment.RIGHT}
              onChange={() => setStateSettings({
                ...stateSettings,
                visit_user_check_timezone: !stateSettings.visit_user_check_timezone,
              })}
            />
          )}
          {(isAdmin || isAgencyAdmin) && (
            <Checkbox
              checked={stateSettings.visit_user_forbid_logout_with_unuploaded_photos || false}
              label={t('do not end session if unsent photos')}
              alignIndicator={Alignment.RIGHT}
              onChange={() => setStateSettings({
                ...stateSettings,
                // eslint-disable-next-line max-len
                visit_user_forbid_logout_with_unuploaded_photos: !stateSettings.visit_user_forbid_logout_with_unuploaded_photos,
              })}
            />
          )}
          {(isAdmin || isAgencyAdmin) && (
            <Checkbox
              checked={stateSettings.visit_require_fresh_photo || false}
              label={t('visit_require_fresh_photo')}
              alignIndicator={Alignment.RIGHT}
              onChange={() => setStateSettings({
                ...stateSettings,
                visit_require_fresh_photo: !stateSettings.visit_require_fresh_photo,
              })}
            />
          )}

          {(isAdmin || isAgencyAdmin) && (
            <Checkbox
              checked={stateSettings.reports_contragent_astix_send || false}
              label={t('send data to Astix')}
              alignIndicator={Alignment.RIGHT}
              onChange={() => setStateSettings({
                ...stateSettings,
                reports_contragent_astix_send:
                  !stateSettings.reports_contragent_astix_send,
              })}
            />
          )}
          <Checkbox
            checked={stateSettings.visit_interval_by_photo || false}
            label={t('visit start end by first and last photo')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_interval_by_photo: !stateSettings.visit_interval_by_photo,
            })}
          />
          <Checkbox
            checked={stateSettings.visit_forbid_distant_completion || false}
            label={t('do not allow end visit in distance')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_forbid_distant_completion: !stateSettings.visit_forbid_distant_completion,
            })}
          />
          <Checkbox
            checked={stateSettings.user_login_twice_denied || false}
            label={t('login to mobile from only one device')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => {
              setStateSettings({
                ...stateSettings,
                user_login_twice_denied: !stateSettings.user_login_twice_denied,
              });
              if (!stateSettings.user_login_twice_denied) {
                setUsersLogoutAlertOpen(true);
              }
            }}
          />
          <Checkbox
            checked={stateSettings.contragent_import_tp_force_update || false}
            label={t('update att of changed tt')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_import_tp_force_update: !stateSettings.contragent_import_tp_force_update,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_count_cashboxes_in_tasks_result || false}
            label={t('cashboxCountInFeed')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_count_cashboxes_in_tasks_result: !stateSettings.contragent_count_cashboxes_in_tasks_result,
            })}
          />

          <Checkbox
            checked={stateSettings.check_ir_required || false}
            label={t('recalculation of IR section')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              check_ir_required: !stateSettings.check_ir_required,
            })}
          />
          <Checkbox
            checked={stateSettings.manager_can_change_started_visit_status || false}
            label={t('right to change visit status for contractor team leaders')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              manager_can_change_started_visit_status: !stateSettings.manager_can_change_started_visit_status,
            })}
          />
          <Checkbox
            checked={stateSettings.superviser_can_edit_users || false}
            label={t('superviserCanEditUserRight')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              superviser_can_edit_users: !stateSettings.superviser_can_edit_users,
            })}
          />
          <Checkbox
            checked={stateSettings.show_reduced_visit_duration || false}
            label={t('showReducedVisitDuration')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              show_reduced_visit_duration: !stateSettings.show_reduced_visit_duration,
            })}
          />

          {(isAdmin || isAgencyAdmin) && (
            <FormGroup inline label={t('visitUseStepsSetting')} className={styles.selectGroup}>
              <SimpleSelect
                items={USE_STEPS_OPTIONS}
                value={stateSettings.visit_use_steps || false}
                onChange={(val) => setStateSettings({
                  ...stateSettings,
                    visit_use_steps: val,
                })}
              />
            </FormGroup>
          )}
          <Checkbox
            checked={stateSettings.visit_forbid_start_with_fake_gps || false}
            label={t('visit_forbid_start_with_fake_gps')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_forbid_start_with_fake_gps: !stateSettings.visit_forbid_start_with_fake_gps,
            })}
          />
          <Checkbox
            checked={stateSettings.hide_create_photo_coordinates}
            label={t('disable display of coordinates on photos of visits')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              hide_create_photo_coordinates: !stateSettings.hide_create_photo_coordinates,
            })}
          />
          <Checkbox
            checked={stateSettings.create_users_from_user_task_import}
            label={t('create users from visits import')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              create_users_from_user_task_import: !stateSettings.create_users_from_user_task_import,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_use_manager_as_territory}
            label={t('autoTerritoryCreationAndAssignment')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_use_manager_as_territory: !stateSettings.contragent_use_manager_as_territory,
            })}
          />
        </Callout>

        <Callout title={t('alerts')} className={styles.section}>
          <Checkbox
            checked={stateSettings.visit_alert_use_virtual_rest || false}
            label={t('specify virtual balance instead of actual')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_alert_use_virtual_rest: !stateSettings.visit_alert_use_virtual_rest,
            })}
          />
          <Checkbox
            checked={stateSettings.visit_alert_unconfirmed_requires_photo || false}
            label={t('adding mandatory photos if signal is not confirmed')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_alert_unconfirmed_requires_photo:
                !stateSettings.visit_alert_unconfirmed_requires_photo,
            })}
          />
          {(isAdmin || isAgencyAdmin) && (
            <Checkbox
              checked={stateSettings.visit_alert_import_not_create_visit || false}
              label={t('add alerts only to scheduled visits')}
              alignIndicator={Alignment.RIGHT}
              onChange={() => setStateSettings({
                ...stateSettings,
                visit_alert_import_not_create_visit:
                  !stateSettings.visit_alert_import_not_create_visit,
              })}
            />
          )}
          {(isAdmin || isAgencyAdmin) && (
            <Checkbox
              checked={stateSettings.visit_alert_disable_photo_before_after || false}
              label={t('disable sections photo before after')}
              alignIndicator={Alignment.RIGHT}
              onChange={() => setStateSettings({
                ...stateSettings,
                visit_alert_disable_photo_before_after:
                  !stateSettings.visit_alert_disable_photo_before_after,
              })}
            />
          )}
          <Checkbox
            checked={stateSettings.visit_alert_problem_required_for_osa || false}
            label={t('problemRequiredForMissingProduct')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              visit_alert_problem_required_for_osa: !stateSettings.visit_alert_problem_required_for_osa,
            })}
          />
        </Callout>

        <Callout title={t('supplier role settings')} className={styles.section}>
          <FormGroup
            inline
            label={t('display executor in feed')}
            className={styles.selectGroup}
          >
            <SimpleSelect
              items={TASK_USER_OPTIONS}
              value={stateSettings.contragent_view_task_user || 'user'}
              onChange={(val) => setStateSettings({
                ...stateSettings,
                contragent_view_task_user: val,
              })}
            />
          </FormGroup>
          <Checkbox
            checked={stateSettings.contragent_hide_restored_tasks || false}
            label={t('do not display restored visits')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_hide_restored_tasks: !stateSettings.contragent_hide_restored_tasks,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_can_audit || false}
            label={t('right to perform an audit in tt')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_can_audit:
                !stateSettings.contragent_can_audit,
            })}
          />
          <FormGroup
            inline
            label={t('forbidEvents')}
            className={styles.selectGroup}
          >
            <SimpleSelect
              items={FORBID_EVENTS_OPTIONS}
              value={stateSettings.contragent_forbid_events || false}
              onChange={v => setStateSetting('contragent_forbid_events', v)}
            />
          </FormGroup>
          <Checkbox
            checked={stateSettings.contragent_single_task_per_tradepoint || false}
            label={t('show only one last tt visit in feed')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_single_task_per_tradepoint:
                !stateSettings.contragent_single_task_per_tradepoint,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_hide_remote_finished || false}
            label={t('hide the flag completed in or outside tt')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_hide_remote_finished: !stateSettings.contragent_hide_remote_finished,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_hide_start_end_date || false}
            label={t('hide date time of visit start and end')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_hide_start_end_date: !stateSettings.contragent_hide_start_end_date,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_hide_work_time || false}
            label={t('hide time in tt')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_hide_work_time: !stateSettings.contragent_hide_work_time,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_hide_create_photo_time || false}
            label={t('hide photo time')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_hide_create_photo_time: !stateSettings.contragent_hide_create_photo_time,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_hide_fake_gps || false}
            label={t('hide sign of using Fake GPS')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_hide_fake_gps: !stateSettings.contragent_hide_fake_gps,
            })}
          />
          <Checkbox
            checked={stateSettings.contragent_forbid_view_task_map || false}
            label={t('hide map')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              contragent_forbid_view_task_map: !stateSettings.contragent_forbid_view_task_map,
            })}
          />
          <FormGroup inline label={t('feedTaskTypeRestriction')} className={styles.selectGroup}>
            <SimpleSelect
              items={TASK_TYPE_OPTIONS}
              value={stateSettings.contragent_feed_task_type_restriction}
              onChange={v => setStateSettings({
                ...stateSettings,
                contragent_feed_task_type_restriction: v,
              })}
            />
          </FormGroup>
        </Callout>

        <Callout title={t('agency provider role settings')} className={styles.section}>
          <FormGroup
            inline
            label={t('display executor in feed')}
            className={styles.selectGroup}
          >
            <SimpleSelect
              items={TASK_USER_OPTIONS}
              value={stateSettings.agency_contragent_view_task_user || 'user'}
              onChange={(val) => setStateSettings({
                ...stateSettings,
                agency_contragent_view_task_user: val,
              })}
            />
          </FormGroup>
          <Checkbox
            checked={stateSettings.agency_contragent_hide_restored_tasks || false}
            label={t('do not display restored visits')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_hide_restored_tasks:
                !stateSettings.agency_contragent_hide_restored_tasks,
            })}
          />
          <Checkbox
            checked={stateSettings.agency_contragent_can_audit || false}
            label={t('right to perform an audit in tt')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_can_audit:
                !stateSettings.agency_contragent_can_audit,
            })}
          />
          <FormGroup
            inline
            label={t('forbidEvents')}
            className={styles.selectGroup}
          >
            <SimpleSelect
              items={FORBID_EVENTS_OPTIONS}
              value={stateSettings.agency_contragent_forbid_events || false}
              onChange={v => setStateSetting('agency_contragent_forbid_events', v)}
            />
          </FormGroup>
          <Checkbox
            checked={stateSettings.agency_contragent_single_task_per_tradepoint || false}
            label={t('show only one last tt visit in feed')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_single_task_per_tradepoint:
                !stateSettings.agency_contragent_single_task_per_tradepoint,
            })}
          />
          <Checkbox
            checked={stateSettings.agency_contragent_hide_remote_finished || false}
            label={t('hide the flag completed in or outside tt')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_hide_remote_finished:
                !stateSettings.agency_contragent_hide_remote_finished,
            })}
          />
          <Checkbox
            checked={stateSettings.agency_contragent_hide_start_end_date || false}
            label={t('hide date time of visit start and end')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_hide_start_end_date:
                !stateSettings.agency_contragent_hide_start_end_date,
            })}
          />
          <Checkbox
            checked={stateSettings.agency_contragent_hide_work_time || false}
            label={t('hide time in tt')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_hide_work_time:
                !stateSettings.agency_contragent_hide_work_time,
            })}
          />
          <Checkbox
            checked={stateSettings.agency_contragent_hide_create_photo_time || false}
            label={t('hide photo time')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_hide_create_photo_time:
                !stateSettings.agency_contragent_hide_create_photo_time,
            })}
          />
          <Checkbox
            checked={stateSettings.agency_contragent_hide_fake_gps || false}
            label={t('hide sign of using Fake GPS')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_hide_fake_gps:
                !stateSettings.agency_contragent_hide_fake_gps,
            })}
          />
          <Checkbox
            checked={stateSettings.agency_contragent_forbid_view_task_map || false}
            label={t('hide map')}
            alignIndicator={Alignment.RIGHT}
            onChange={() => setStateSettings({
              ...stateSettings,
              agency_contragent_forbid_view_task_map:
                !stateSettings.agency_contragent_forbid_view_task_map,
            })}
          />
        </Callout>

        <div className={styles.buttonGroup}>
          <Link to="/contragents">
            <Button icon="cross" className={styles.button}>{t('cancel')}</Button>
          </Link>
          <Button
            className={styles.button}
            disabled={fetching || saving}
            loading={saving}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            onClick={() => {
              saveSettings({ id, settings: stateSettings });
            }}
          >
            {t('save')}
          </Button>
        </div>
      </Card>

      {usersLogoutAlertOpen && (
        <Alert isOpen intent={Intent.DANGER} onClose={() => setUsersLogoutAlertOpen(false)}>
          <p>{t('session will be terminated')}</p>
          <p>{t('enabling setting all users end visits')}</p>
        </Alert>
      )}
    </ContragentPage>
  );
};

ContragentEditScreen.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isAgencyAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: getIsAdmin(state),
  isAgencyAdmin: getIsAgencyAdmin(state),
});

export default connect(mapStateToProps)(ContragentEditScreen);
