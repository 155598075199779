import {
  getUserChiefs,
  getKpiByParent,
  getKpiByUser,
  getKpiTotals,
} from 'app/api';
import * as ACTIONS from 'app/state/actions';

export const fetchKpi = ({
  userId,
  parentId,
  contragentId,
  dateFrom,
  dateTo,
}) => async (dispatch) => {
  dispatch({ type: ACTIONS.KPI_FETCH_START });
  try {
    const kpi = parentId
      ? await getKpiByParent(parentId, contragentId, dateFrom, dateTo)
      : await getKpiByUser(userId, contragentId, dateFrom, dateTo);
    dispatch({ type: ACTIONS.KPI_FETCH_DONE, payload: kpi.data });
  } catch (error) {
    dispatch({ type: ACTIONS.KPI_FETCH_ERROR, error });
  }
};

export const fetchKpiTotals = ({
  userId,
  contragentId,
  dateFrom,
  dateTo,
}) => async (dispatch) => {
  dispatch({ type: ACTIONS.KPI_FETCH_TOTALS_START });
  try {
    const totals = await getKpiTotals(userId, contragentId, dateFrom, dateTo);
    dispatch({ type: ACTIONS.KPI_FETCH_TOTALS_DONE, payload: totals });
  } catch (error) {
    dispatch({ type: ACTIONS.KPI_FETCH_TOTALS_ERROR, error });
  }
};

export const fetchChiefs = (userId, contragentId) => async (dispatch) => {
  dispatch({ type: ACTIONS.KPI_FETCH_CHIEFS_START });
  try {
    const chiefs = await getUserChiefs(userId, contragentId);
    dispatch({ type: ACTIONS.KPI_FETCH_CHIEFS_DONE, payload: chiefs });
  } catch (error) {
    dispatch({ type: ACTIONS.KPI_FETCH_CHIEFS_ERROR, error });
  }
};

export const setContragent = (contragentId) => ({
  type: ACTIONS.KPI_SET_CONTRAGENT,
  payload: { contragentId },
});

export const setDates = ({ dateFrom, dateTo }) => ({
  type: ACTIONS.KPI_SET_DATES,
  payload: { dateFrom, dateTo },
});

export const setSort = ({ field, direction }) => ({
  type: ACTIONS.KPI_SORT,
  payload: { field, direction },
});
