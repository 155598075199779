import React from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { TILE_LAYER } from 'app/utils/map';
import styles from './TradepointScreen.module.css';

const SimpleMap = ({
  latitude,
  longitude,
  zoom,
  popup,
}) => {
  const position = latitude && longitude ? [latitude, longitude] : [55.7558, 37.6173];
  return (
    <MapContainer center={position} zoom={zoom} className={styles.map}>
      {TILE_LAYER}
      {latitude && longitude && (
        <Marker position={position}>
          {popup && <Popup>{popup}</Popup>}
        </Marker>
      )}
    </MapContainer>
  );
};

SimpleMap.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
  popup: PropTypes.string,
};

SimpleMap.defaultProps = {
  zoom: 13,
  latitude: null,
  longitude: null,
  popup: null,
};

export default SimpleMap;
