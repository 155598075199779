import React from 'react';

import i18n from 'i18n';
import store from 'app/state';
import * as api from 'app/api';
import * as toaster from 'app/widgets/toaster';
import history from './history';

function handleClickQueue(e) {
  e.preventDefault();
  history.push('/background-export-tasks');
}

function handleResult(success, error) {
  if (success) {
    toaster.showSuccess(
      (
        <>
          {i18n.t('exportSuccessfullyAddedTo')}
          {' '}
          <a href="/background-export-tasks" onClick={handleClickQueue}>{i18n.t('queue')}</a>
        </>
      ),
      { timeout: 10000 },
    );
  } else {
    toaster.showError(error.message);
  }
}

export async function handleCreateBackgroundExportTaskAction(action) {
  const { success, error } = await store.dispatch(action);
  handleResult(success, error);
}

export async function tryCreateBackgroundExportTask(type, params) {
  try {
    await api.createBackgroundExportTask({ type, params });
    handleResult(true);
  } catch (e) {
    handleResult(false, e);
  }
}
