import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import { Button, Dialog, Classes, Intent, InputGroup, FormGroup } from '@blueprintjs/core';
import { ContragentSelect } from 'app/widgets/ReferenceSelect';
import * as api from 'app/api';
import { showError } from 'app/widgets/toaster';

import styles from './EditDialog.module.css';

const FIELDS = {
  code: 'problem code',
  description: 'problem name',
};

const EditDialog = ({ onClose, problem }) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = React.useState(false);
  const [data, setData] = React.useState({});
  const [changed, setChanged] = React.useState(false);

  const onCloseWithoutEdit = RRU.useCallbackArgs(onClose, false);

  const filled = (field) => {
    return !!(data[field] ?? problem[field]);
  };

  const setValue = (field, value) => {
    setData({ ...data, [field]: value });
    setChanged(true);
  };

  const setContragent = (value) => {
    setValue('contragent_id', value);
  };

  const renderField = ([field, label]) => {
    const intent = !filled(field) ? Intent.DANGER : Intent.NONE;

    return (
      <FormGroup key={field} label={t(label)} labelFor={field} intent={intent}>
        <InputGroup
          id={field}
          defaultValue={problem[field]}
          onChange={(e) => setValue(field, e.target.value)}
          disabled={field === 'code' && !problem.isNew}
        />
      </FormGroup>
    );
  };

  const save = async () => {
    const putData = _.fromPairs(_.keys(FIELDS).map((field) => [field, data[field] ?? problem[field]]));
    putData.contragent_id = data.contragent_id ?? problem.contragent_id;

    setFetching(true);
    try {
      await api.editVisitProblem(putData);
      onClose(true);
    } catch (e) {
      showError(e.message);
    }
    setFetching(false);
  };

  const invalid = !_.every(_.keys(FIELDS).map(filled));
  return (
    <Dialog
      title={t(problem.isNew ? 'new problem' : 'edit problem')}
      isOpen
      onClose={onCloseWithoutEdit}
      canOutsideClickClose={!changed}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label={t('contractor')}
          labelFor="contragent_id"
          intent={!filled('contragent_id') ? Intent.DANGER : Intent.NONE}
        >
          <ContragentSelect
            id="contragent_id"
            defaultValue={problem.contragent_id}
            value={data.contragent_id ?? problem.contragent_id}
            onChange={setContragent}
            multi={false}
            className={styles.contragentCombo}
            disabled={!problem.isNew}
          />
        </FormGroup>
        {_.toPairs(FIELDS).map(renderField)}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={onCloseWithoutEdit}>{t('cancel')}</Button>
          <Button
            disabled={fetching || invalid}
            loading={fetching}
            icon="tick"
            intent={Intent.PRIMARY}
            onClick={save}
            text={t('save')}
          />
        </div>
      </div>
    </Dialog>
  );
};

EditDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  problem: PropTypes.shape({
    contragent_id: PropTypes.number.isRequired,
    code: PropTypes.string,
    description: PropTypes.string,
    isNew: PropTypes.bool,
  }),
};

EditDialog.defaultProps = {
  problem: PropTypes.shape({
    isNew: false,
  }),
};

export default EditDialog;
