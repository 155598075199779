import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Intent, NumericInput, FormGroup } from '@blueprintjs/core';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

const NumInput = React.memo(({ value, onChange, intent, ...props }) => {
  const [textValue, setTextValue] = useState(value ?? '');
  useEffect(() => {
    // FIXME Если выставят null снаружи, мы сбросим textValue
    // но бездумно сбрасывать нельзя, потому что вызываем onChange(null)
    // когда textValue - невалидное число. Нужно как-то разрывать цепочку
    // обновлений
    if (_.isNumber(value) && Number(textValue) !== value) {
      setTextValue(value.toString());
    }
  }, [value]);
  const textValueIsNaN = !!textValue && _.isNaN(Number(textValue));

  return (
    <NumericInput
      fill
      {...props}
      intent={textValueIsNaN ? Intent.DANGER : intent}
      value={textValue}
      onValueChange={(newValue, newTextValue) => {
        const correctNewValue = newTextValue ? Number(newTextValue) : NaN;
        setTextValue(newTextValue);
        onChange(_.isNaN(correctNewValue) ? null : correctNewValue);
      }}
    />
  );
});

NumInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  intent: PropTypes.string,
};

NumInput.defaultProps = {
  value: null,
  intent: Intent.NONE,
};

export default NumInput;

export const NumFormGroup = React.memo(withTranslation()(
  ({ inline, label, groupClassName, valid, t, ...props }) => (
    <FormGroup
      inline={inline}
      label={label}
      labelFor={label}
      className={groupClassName}
      helperText={!valid ? t('incorrect value') : null}
      intent={!valid ? Intent.DANGER : Intent.NONE}
    >
      <NumInput
        id={label}
        intent={!valid ? Intent.DANGER : Intent.NONE}
        {...props}
      />
    </FormGroup>
  ),
));

NumFormGroup.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string.isRequired,
  groupClassName: PropTypes.string,
  valid: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

NumFormGroup.defaultProps = {
  inline: true,
  groupClassName: undefined,
  valid: true,
};
