import React from 'react';
import { MapContainer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { LatLon } from 'app/proptyping';
import { startIcon, endIcon, taskIcon, TILE_LAYER } from 'app/utils/map';

import styles from './TaskResult.module.css';

const PADDING = 0.005;

const TaskMap = ({
  startLocation,
  endLocation,
  taskLocation,
}) => {
  const mapBounds = [
    [
      Math.min(
        startLocation.latitude,
        endLocation.latitude,
        taskLocation && taskLocation.latitude,
      ) - PADDING,
      Math.min(
        startLocation.longitude,
        endLocation.longitude,
        taskLocation && taskLocation.longitude,
      ) - PADDING,
    ],
    [
      Math.max(
        startLocation.latitude,
        endLocation.latitude,
        taskLocation && taskLocation.latitude,
      ) + PADDING,
      Math.max(
        startLocation.longitude,
        endLocation.longitude,
        taskLocation && taskLocation.longitude,
      ) + PADDING,
    ],
  ];

  return (
    <MapContainer className={styles.map} bounds={mapBounds}>
      {TILE_LAYER}
      <Marker
        position={[startLocation.latitude, startLocation.longitude]}
        icon={startIcon}
      />
      <Marker
        position={[endLocation.latitude, endLocation.longitude]}
        icon={endIcon}
      />
      {taskLocation && (
        <Marker
          position={[taskLocation.latitude, taskLocation.longitude]}
          icon={taskIcon}
        />
      )}
    </MapContainer>
  );
};

TaskMap.propTypes = {
  startLocation: LatLon.isRequired,
  endLocation: LatLon.isRequired,
  taskLocation: LatLon.isRequired,
};

export default TaskMap;
