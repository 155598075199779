import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Cell, Column, Table, RenderMode,
} from '@blueprintjs/table';
import { NonIdealState, Button } from '@blueprintjs/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { valueByPath } from 'app/utils/object';
import styles from './DataTable.module.css';

const DataCell = ({
  fetching,
  data,
  path,
  valueRenderer,
}) => {
  const value = fetching ? '' : valueByPath(data, path);
  return (
    <Cell loading={fetching}>
      {valueRenderer(value, data)}
    </Cell>
  );
};

DataCell.propTypes = {
  data: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  valueRenderer: PropTypes.func.isRequired,
};

const getSortIcon = (sortDirection) => {
  if (sortDirection) {
    if (sortDirection === 'asc') {
      return 'sort-asc';
    }
    return 'sort-desc';
  }
  return 'sort';
};

export const getSortableNameRenderer = ({ sortDirection, onSort }) => (name) => (
  // TODO: remove 'bp-table-text-no-measure' and columnHeaderMeasureFix
  // after blueprintjs autosize bug fixed
  <div className={styles.columnHeaderContainer}>
    <div className={classNames(styles.columnHeaderWrapper, 'bp-table-text-no-measure')}>
      <div className={styles.columnHeaderText}>
        {name}
      </div>
      <Button
        small
        minimal
        icon={getSortIcon(sortDirection)}
        className={styles.sortButton}
        onClick={onSort}
      />
    </div>
    <div className={styles.columnHeaderMeasureFix}>
      {name}
      {'\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
    </div>
  </div>
);

const DataColumn = ({
  fetching, data, title, path, valueRenderer,
  sortable, sortDirection, onSort,
}) => (
  <Column
    key={path.join()}
    name={title}
    cellRenderer={(rowIndex) => DataCell({
      fetching,
      data: data[rowIndex],
      path,
      valueRenderer: valueRenderer || ((x) => x),
    })}
    nameRenderer={sortable ? getSortableNameRenderer({ sortDirection, onSort }) : undefined}
  />
);

DataColumn.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetching: PropTypes.bool.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  valueRenderer: PropTypes.func,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  sortable: PropTypes.bool,
};

DataColumn.defaultProps = {
  valueRenderer: (x) => x,
  sortable: false,
  onSort: undefined,
  sortDirection: undefined,
};

const getCellClipboardData = (data, columns) => (row, col) => {
  const { path } = columns[col];
  const value = valueByPath(data[row], path);
  return value;
};

const DataTable = ({
  columns,
  data,
  fetchingRowCount,
  fetching,
  className,
  defaultColumnWidth,
  enableRowHeader,
  error,
}) => {
  const { t } = useTranslation();
  const [columnWidths, setColumnWidths] = useState(() => columns.map(() => null));
  const onColumnWidthChanged = useCallback(
    (i, width) => {
      const newWidths = columnWidths.slice();
      newWidths[i] = width;
      setColumnWidths(newWidths);
    },
    [columnWidths],
  );
  const finalColumnWidth = useMemo(
    () => columns.map((c, i) => columnWidths[i] ?? c.width ?? defaultColumnWidth),
    [columns, columnWidths],
  );

  if (error) {
    return <NonIdealState title={t('error')} description={error.message || t('error')} icon="error" />;
  }

  if (!fetching && data.length === 0) {
    return <NonIdealState title={t('no data')} icon="list" />;
  }

  return (
    <Table
      enableRowHeader={enableRowHeader}
      columnWidths={finalColumnWidth}
      numRows={fetching ? fetchingRowCount : data.length}
      renderMode={RenderMode.NONE}
      className={className}
      getCellClipboardData={getCellClipboardData(data, columns)}
      onColumnWidthChanged={onColumnWidthChanged}
    >
      {columns.map((column) => DataColumn({
        fetching,
        data,
        ...column,
      }))}
    </Table>
  );
};

const columnPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]).isRequired,
  width: PropTypes.number,
});

DataTable.propTypes = {
  columns: PropTypes.arrayOf(columnPropType).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchingRowCount: PropTypes.number,
  fetching: PropTypes.bool,
  defaultColumnWidth: PropTypes.number,
  className: PropTypes.string,
  enableRowHeader: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
};

DataTable.defaultProps = {
  fetchingRowCount: 20,
  fetching: false,
  defaultColumnWidth: 200,
  className: '',
  enableRowHeader: true,
  error: null,
};

export default DataTable;
