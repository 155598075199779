import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Position, Intent } from '@blueprintjs/core';
import { DateInput, DateRangeInput, TimePrecision } from '@blueprintjs/datetime';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import { classNames } from 'app/utils';
import maxDate from 'app/utils/maxDateForCalendar';

function formatDate({ withTime }, date, locale) {
  return moment(date).locale(locale).format(withTime ? 'L LT' : 'L');
}

function parseDate({ withTime }, str, locale) {
  return str ? moment(str, withTime ? 'L LT' : 'L', locale).toDate() : null;
}

export
const LocalizedDateInput = React.memo(({ intent, withTime, ...props }) => {
  const { t, i18n } = useTranslation();
  const formatDateBound = RRU.useCallbackOptions(formatDate, { withTime });
  const parseDateBound = RRU.useCallbackOptions(parseDate, { withTime });

  return (
    <DateInput
      locale={i18n.language}
      localeUtils={MomentLocaleUtils}
      reverseMonthAndYearMenus
      placeholder={t('select a date')}
      inputProps={{ intent }}
      formatDate={formatDateBound}
      parseDate={parseDateBound}
      popoverProps={{ position: Position.BOTTOM }}
      timePickerProps={withTime ? { precision: TimePrecision.MINUTES } : undefined}
      closeOnSelection={!withTime}
      {...props}
      // eslint-disable-next-line
      onChange={(value) => (!value || moment(value)._isValid) && props.onChange && props.onChange(value)}
    />
  );
});

LocalizedDateInput.propTypes = {
  maxDate: PropTypes.instanceOf(Date),
  intent: PropTypes.string,
  withTime: PropTypes.bool,
};

LocalizedDateInput.defaultProps = {
  maxDate,
  intent: Intent.NONE,
  withTime: false,
};

function formatISODate(date, withTime) {
  if (!date) {
    return null;
  }

  const format = withTime ? moment.HTML5_FMT.DATETIME_LOCAL_SECONDS : moment.HTML5_FMT.DATE;
  return (date instanceof moment ? date : moment(date)).format(format);
}

function onISODateChange({ withTime, onChange }, date) {
  onChange(formatISODate(date, withTime));
}

export
const LocalizedISODateInput = React.memo(({ withTime, value, onChange, ...props }) => {
  const parsed = useMemo(() => (value ? moment(value).toDate() : null), [value]);
  const onChangeWrapper = RRU.useCallbackOptions(onISODateChange, { withTime, onChange });

  return <LocalizedDateInput withTime={withTime} value={parsed} onChange={onChangeWrapper} {...props} />;
});

LocalizedISODateInput.propTypes = {
  withTime: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

LocalizedISODateInput.defaultProps = {
  withTime: false,
  value: null,
};

export const LocalizedDateRangeInput = React.memo(({
  className,
  shortcuts,
  intent,
  startIntent,
  endIntent,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const formatDateBound = RRU.useCallbackOptions(formatDate, { withTime: false });
  const parseDateBound = RRU.useCallbackOptions(parseDate, { withTime: false });

  // Месяцы от текущего назад
  const MONTH_SHORTCUTS = _.range(11).map((index) => {
    const month = moment().locale(i18n.language).subtract(index, 'months');
    return {
      label: _.capitalize(month.locale(i18n.language).format('MMMM YYYY')),
      dateRange: [month.startOf('month').toDate(), month.endOf('month').toDate()],
      includeTime: false,
    };
  });

  // С прошлой недели по следующую
  const WEEK_TO_WEEK_SHORTCUTS = [
    {
      label: t('today'),
      dateRange: [new Date(), new Date()],
      includeTime: false,
    },
    {
      label: t('tomorrow'),
      dateRange: [moment().add(1, 'days').toDate(), moment().add(1, 'days').toDate()],
      includeTime: false,
    },
    {
      label: t('next week'),
      dateRange: [new Date(), moment().add(6, 'days').toDate()],
      includeTime: false,
    },
    {
      label: t('yesterday'),
      dateRange: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
      includeTime: false,
    },
    {
      label: t('previous week'),
      dateRange: [moment().subtract(7, 'days').toDate(), moment().subtract(1, 'days').toDate()],
      includeTime: false,
    },
  ];

  const shortcutsMap = {
    months: MONTH_SHORTCUTS,
    weekToWeek: WEEK_TO_WEEK_SHORTCUTS,
  };

  let resolvedShortcuts;
  if (_.isString(shortcuts)) {
    resolvedShortcuts = shortcutsMap[shortcuts];
  } else {
    resolvedShortcuts = shortcuts;
  }

  return (
    <DateRangeInput
      className={classNames(className, 'dateRangeInput')}
      locale={i18n.language}
      localeUtils={MomentLocaleUtils}
      invalidDateMessage={t('invalid date')}
      overlappingDatesMessage={t('dates overlap')}
      startInputProps={{ placeholder: t('date from'), intent: startIntent ?? intent }}
      endInputProps={{ placeholder: t('date to'), intent: endIntent ?? intent }}
      allowSingleDayRange
      formatDate={formatDateBound}
      parseDate={parseDateBound}
      shortcuts={resolvedShortcuts}
      maxDate={maxDate}
      {...props}
    />
  );
});

LocalizedDateRangeInput.propTypes = {
  className: PropTypes.string,
  shortcuts: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['months', 'weekToWeek']),
    PropTypes.arrayOf(PropTypes.object.isRequired),
  ]),
  intent: PropTypes.string,
  startIntent: PropTypes.string,
  endIntent: PropTypes.string,
};

LocalizedDateRangeInput.defaultProps = {
  className: null,
  shortcuts: true,
  intent: Intent.NONE,
  startIntent: null,
  endIntent: null,
};

function handleChange({ value, onChange, clearable, inclusiveEnd }, newValue) {
  const cleared = (
    (!!value?.[0] && !newValue[0])
    || (!!value?.[1] && !newValue[1])
  );
  if (!clearable && cleared) {
    return;
  }

  const formatted = [
    formatISODate(
      newValue[0] && inclusiveEnd ? moment(newValue[0]).startOf('day') : newValue[0],
      inclusiveEnd,
    ),
    formatISODate(
      newValue[1] && inclusiveEnd ? moment(newValue[1]).endOf('day') : newValue[1],
      inclusiveEnd,
    ),
  ];
  onChange(formatted);
}

function parseISODateRange(value) {
  const parsed = [null, null];
  if (value?.[0]) {
    parsed[0] = moment(value[0]).toDate();
  }
  if (value?.[1]) {
    parsed[1] = moment(value[1]).toDate();
  }
  return parsed;
}

function LocalizedISODateRangeInput({ value, onChange, clearable, inclusiveEnd, ...props }) {
  const onChangeWithFormatting = RRU.useCallbackOptions(
    handleChange,
    { value, onChange, clearable, inclusiveEnd },
  );
  const parsedValue = RRU.useMemoArgs(parseISODateRange, value);

  return <LocalizedDateRangeInput value={parsedValue} onChange={onChangeWithFormatting} {...props} />;
}

LocalizedISODateRangeInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  inclusiveEnd: PropTypes.bool,
};

LocalizedISODateRangeInput.defaultProps = {
  clearable: false,
  inclusiveEnd: false,
};

const LocalizedISODateRangeInputMemo = React.memo(LocalizedISODateRangeInput);
export { LocalizedISODateRangeInputMemo as LocalizedISODateRangeInput };
