import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import styles from './UserScreen.module.css';

function Contragent({ contragent }) {
  const { t } = useTranslation();
  return (
    <div className={styles.contragent}>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('contractor')}:</div>
        <div className={styles.detailsValue}>{contragent.name}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('bosses')}:</div>
        <div className={styles.detailsValue}>
          {contragent.managers?.map(m => m.fullname).join(', ') || '-'}
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('branches')}:</div>
        <div className={styles.detailsValue}>{contragent.branches?.map((b) => b.name).join(', ') ?? '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('networks')}:</div>
        <div className={styles.detailsValue}>{contragent.networks?.map((b) => b.name).join(', ') ?? '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('tradepointProjects')}:</div>
        <div className={styles.detailsValue}>{contragent.projects?.map((b) => b.name).join(', ') ?? '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('territories')}:</div>
        <div className={styles.detailsValue}>{contragent.territories?.map((t) => t.name).join(', ') ?? '-'}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsLabel}>{t('questionnaires')}:</div>
        <div className={styles.detailsValue}>{contragent.questionaries?.map((t) => t.name).join(', ') ?? '-'}</div>
      </div>
    </div>
  );
}

Contragent.propTypes = {
  contragent: PropTypes.shape({
    name: PropTypes.string.isRequired,
    managers: PropTypes.arrayOf(PropTypes.shape({
      fullname: PropTypes.string.isRequired,
    }).isRequired),
    branches: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired),
    networks: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired),
    projects: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired),
    territories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired),
    questionaries: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired),
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default React.memo(() => {
  const contragents = useSelector((state) => state.teamUser.user.contragents);
  const { t } = useTranslation();

  return (
    <div className={styles.contragents}>
      <h3 className={styles.contragentsTitle}>
        {t('contractors')}:
      </h3>
      <div className={styles.contragentsContainer}>
        {_.toPairs(contragents).map(([id, c]) => <Contragent key={id} contragent={c} t={t} />)}
      </div>
    </div>
  );
});
