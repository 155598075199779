import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

export const INITIAL_STATE = {
  filters: {
    contragent_id__eq: undefined,
    code__ilike: undefined,
    address__ilike: undefined,
    branch_id__in: undefined,
    network_id__in: undefined,
    project_id__in: undefined,
    ir_code__ilike: undefined,
    questionary_id__in: undefined,
  },
  grids: {},
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.QUESTIONARY_MULTI_BINDING_SET_FILTERS:
      return _.assign(state, { filters: action.filters });

    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
