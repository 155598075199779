import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import DataTable from 'app/widgets/DataTable';
import { getColumn } from 'app/widgets/DataTable/utils';
import { kpiType } from 'app/proptyping';
import {
  setSort as setSortAC,
} from 'app/state/actionCreators/kpi';

const UserTable = ({ kpi, sort, setSort, t }) => {
  const getColumns = () => [
    {
      title: t('date'),
      field: 'user_tasks_date',
      valueRenderer: (value) => (value ? moment(value).format('DD.MM.YYYY') : null),
    },
    {
      title: t('check-in'),
      field: 'start_time',
      width: 140,
      valueRenderer: (value) => (value ? moment(value).format('DD.MM.YYYY HH:mm') : null),
    },
    { title: t('genitive scheduled visits'), field: 'total_scheduled_visits', width: 190 },
    { title: t('genitive actual visits'), field: 'processed_scheduled_visits', width: 190 },
    { title: t('genitive unscheduled visits'), field: 'processed_unscheduled_visits', width: 190 },
    { title: t('genitive alerts'), field: 'total_alert_visits', width: 190 },
    { title: t('genitive alerts done'), field: 'processed_alert_visits', width: 190 },
    { title: t('genitive signals'), field: 'total_signals', width: 190 },
    { title: t('genitive signals done'), field: 'processed_signals', width: 190 },
  ].map(getColumn({ sort, setSort }));
  return (
    <DataTable
      columns={getColumns()}
      data={kpi}
      loadingRowCount={kpi.length}
    />
  )
};

UserTable.propTypes = {
  kpi: PropTypes.arrayOf(kpiType).isRequired,
  setSort: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  kpi: state.kpi.kpi,
  sort: state.kpi.sort,
});

const mapDispatchToProps = (dispatch) => ({
  setSort: ({ field, direction }) => dispatch(setSortAC({ field, direction })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
