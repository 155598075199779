import moment from 'moment';
import _ from 'lodash';

import * as api from 'app/api';
import * as utils from 'app/utils';

import { $setIn } from 'app/state/actionCreators';

export const createTask = () => async (dispatch, getState) => {
  const { filters, settings } = getState().photoExport;

  let requestFilters = _.omit(filters, 'end_time__between', 'agency_id__eq');
  requestFilters = _.fromPairs(_.toPairs(requestFilters).filter(([, value]) => value !== null));
  requestFilters.end_time__ge = utils.ISODate(filters.end_time__between[0]);
  requestFilters.end_time__le = utils.ISODate(moment(filters.end_time__between[1]).add(1, 'days'));

  const requestSettings = _.fromPairs(_.toPairs(settings).map(([key, value]) => [key, value ?? []]));

  dispatch($setIn('photoExport', { fetching: true }));

  try {
    await api.createBackgroundExportTask({
      type: 'photos',
      params: { filters: requestFilters, settings: requestSettings },
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  } finally {
    dispatch($setIn('photoExport', { fetching: false }));
  }
};

export const clearFilters = () => (dispatch, getState) => {
  const { filters } = getState().photoExport;
  dispatch($setIn('photoExport', {
    filters: {
      end_time__between: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
      agency_id__eq: filters.agency_id__eq,
      contragent_id__eq: filters.contragent_id__eq,
    },
  }));
};

export const setSettingsField = (key, value) => $setIn('photoExport.settings', { [key]: value });
