import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Elevation, Button, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import * as utils from 'app/utils';
import Page from 'app/widgets/Page';
import Form from 'app/widgets/Form';

function setFilter(setFilters, key, value) {
  setFilters(current => ({ ...current, [key]: value }));
}

function handleClickDownload({ apiRoute, backgroundExportTaskType, filters, transientFilters }) {
  const prunedFilters = _.omit(filters, transientFilters);
  if (apiRoute) {
    api.download(apiRoute, { filters: prunedFilters });
  } else {
    utils.tryCreateBackgroundExportTask(
      'static-reports',
      { type: backgroundExportTaskType, filter: prunedFilters },
    );
  }
}

function ReportPage({ title, apiRoute, backgroundExportTaskType, initialFilters, children }) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(initialFilters);
  const onFieldChange = RRU.useCallbackArgs(setFilter, setFilters);
  const [valid, setValid] = useState(false);

  const transientFilters = (
    React.Children.toArray(children)
      .filter(c => c.props?.transient)
      .map(c => c.props.name)
  );
  const onClickDownload = RRU.useCallbackOptions(
    handleClickDownload,
    { apiRoute, backgroundExportTaskType, filters, transientFilters },
  );

  return (
    <Page title={title}>
      <Card elevation={Elevation.TWO} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Form inline value={filters} onFieldChange={onFieldChange} onValidChange={setValid}>
          {children}
        </Form>

        <Button
          onClick={onClickDownload}
          disabled={!valid}
          minimal
          icon="download"
          text={t('download')}
          intent={Intent.SUCCESS}
          style={{ marginLeft: 'auto' }}
        />
      </Card>
    </Page>
  );
}

ReportPage.propTypes = {
  title: PropTypes.string.isRequired,
  apiRoute: PropTypes.string,
  backgroundExportTaskType: PropTypes.string,
  initialFilters: PropTypes.object,
  children: PropTypes.node.isRequired,
};

ReportPage.defaultProps = {
  initialFilters: {},
};

export default React.memo(ReportPage);
