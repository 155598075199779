import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Collapse, Elevation, Button, Intent, Spinner, Classes, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import { AgencyContragentFilter } from 'app/widgets/Filters';
import ComboFilter from 'app/widgets/Filters/ComboFilter';
import FancySelectFilter from 'app/widgets/Filters/FancySelectFilter';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import InputFilter from 'app/widgets/Filters/InputFilter';
import NumrangeFilter from 'app/widgets/Filters/NumrangeFilter';
import IdFilter from 'app/widgets/Filters/IdFilter';
import * as utils from 'app/utils';
import { exportData } from 'app/state/actionCreators/reports/dynamic';
import { reportFieldType } from 'app/proptyping';

import { formatFilters } from './common';
import styles from './ReportDynamicScreen.module.css';

const comboValues = (values) => values && values.map(
  (v) => ({ title: v.text, key: v.value, value: v.value }),
);

const Filter = ({
  checked,
  name,
  filterType,
  value,
  onChange,
  values,
  filterable,
}) => {
  const { t } = useTranslation();
  if (!checked || !filterable) {
    return null;
  }
  if (filterType === 'date') {
    return (
      <DaterangeFilter
        name={name}
        value={value}
        onChange={onChange}
        shortcuts="months"
        intent={!value?.[0] ^ !value?.[1] ? Intent.DANGER : Intent.NONE}
        clearable
      />
    );
  }
  if (filterType === 'combo') {
    return (
      <ComboFilter
        emptiable
        name={name}
        items={comboValues(values)}
        value={value}
        onChange={onChange}
      />
    );
  }
  if (filterType === 'multycombo') {
    const data = values.map(v => ({
      id: v.value,
      label: v.text,
    }));
    return (
      <FancySelectFilter
        name={name}
        data={data}
        value={value}
        onChange={onChange}
      />
    );
  }
  if (filterType === 'bool') {
    return (
      <ComboFilter
        emptiable
        name={name}
        items={[
          { title: t('yes'), key: 'true', value: true },
          { title: t('no'), key: 'false', value: false },
        ]}
        value={value}
        onChange={onChange}
      />
    );
  }
  if (filterType === 'number') {
    return (
      <NumrangeFilter
        name={name}
        value={value}
        onChange={onChange}
        withDebounce={false}
        bothRequired
      />
    );
  }
  if (filterType === 'id') {
    return (
      <IdFilter
        name={name}
        value={value}
        onChange={onChange}
        withDebounce={false}
      />
    );
  }
  return (
    <InputFilter
      name={name}
      value={value || ''}
      onChange={onChange}
      withDebounce={false}
    />
  );
};

Filter.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  filterType: PropTypes.oneOf(['text', 'date', 'combo', 'multycombo', 'id', 'number', 'bool']),
  checked: PropTypes.bool.isRequired,
  filterable: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.any),
};

Filter.defaultProps = {
  value: undefined,
  items: null,
  filterable: false,
  filterType: null,
};

const updatedFilter = (filters, field, val) => {
  if (_.isNil(val) || (field.filter_type === 'text' && !val)) {
    return _.omit(filters, field.field);
  }

  return {
    ...filters,
    [field.field]: val,
  };
};

const getFilterValue = (filters, field) => filters[field.field];

const onClear = (setStateFilters, setFilters) => {
  const filters = {
    user_tasks_date: [
      new Date(),
      new Date(),
    ],
  };
  setStateFilters(filters);
  setFilters(filters);
};

function handleClickDownload({ fields, stateFilters, contragentId, answersOnly }) {
  const columns = fields.filter(f => f.checked).map(f => f.field);
  utils.handleCreateBackgroundExportTaskAction(exportData(
    columns,
    formatFilters({
      contragentId,
      fields,
      filters: stateFilters,
    }),
    answersOnly,
  ));
}

const FiltersCard = ({
  filters,
  fields,
  contragentId,
  setContragent,
  setFilters,
  filtersVisible,
  fieldsFetching,
  needReload,
  setNeedReload,
  fetching,
  fileFetching,
  answersOnly,
}) => {
  const { t } = useTranslation();
  const [stateFilters, setStateFilters] = useState({});
  useEffect(() => {
    if (needReload) {
      setNeedReload(false);
      setFilters({ ...stateFilters });
    }
  }, [needReload, setNeedReload, stateFilters, setFilters]);
  useEffect(
    () => setStateFilters(filters),
    [contragentId, filters],
  );

  const dateRange = getFilterValue(stateFilters, { field: 'user_tasks_date' });

  const dateRangeMissing = !dateRange?.[0] || !dateRange?.[1]
  const dateRangeBad = dateRangeMissing || moment(dateRange[1]).diff(dateRange[0], 'days') > 30;
  const dateRangeLargeTitle = !dateRangeMissing && dateRangeBad && t('period more than a month');
  const previewDisabled = fieldsFetching || dateRangeBad || _.isEqual(stateFilters, filters);
  const downloadDisabled = fileFetching || dateRangeBad;

  const onClickDownload = RRU.useCallbackOptions(
    handleClickDownload,
    { fields, stateFilters, contragentId, answersOnly },
  );

  return (
    <Collapse isOpen={filtersVisible}>
      <Card elevation={Elevation.TWO} className={styles.filtersCard}>
        <div className={styles.filters}>
          <AgencyContragentFilter
            value={contragentId}
            onChange={setContragent}
          />
          <DaterangeFilter
            name={t('date of visit')}
            value={dateRange}
            title={dateRangeLargeTitle}
            onChange={(val) => setStateFilters(updatedFilter(stateFilters, { field: 'user_tasks_date' }, val))}
            shortcuts="months"
            intent={dateRangeBad ? Intent.DANGER : Intent.NONE}
          />
          {!fieldsFetching && fields.map((field) => (
            <Filter
              key={field.field}
              name={field.name}
              checked={field.checked}
              filterable={field.filterable}
              filterType={field.filter_type}
              value={getFilterValue(stateFilters, field)}
              values={field.values}
              onChange={(val) => setStateFilters(updatedFilter(stateFilters, field, val))}
            />
          ))}
        </div>

        <div className={styles.filterButtons}>
          <Button
            onClick={() => onClear(setStateFilters, setFilters)}
            minimal
            icon="filter-remove"
            text={t('clear')}
            intent={Intent.SUCCESS}
            className={styles.clearFiltersButton}
          />
          <Button
            onClick={() => setFilters({ ...stateFilters, contragent_id: contragentId })}
            disabled={previewDisabled}
            title={dateRangeLargeTitle}
            loading={fetching}
            icon="filter"
            text={t('preview')}
            intent={Intent.SUCCESS}
          />
          <Button
            onClick={onClickDownload}
            disabled={downloadDisabled}
            title={dateRangeLargeTitle}
            icon={(
              fileFetching
                ? <Spinner className={Classes.BUTTON_SPINNER} size={Icon.SIZE_LARGE} />
                : 'download'
            )}
            text={t('download')}
            intent={Intent.SUCCESS}
          />
        </div>
      </Card>
    </Collapse>
  );
};

FiltersCard.propTypes = {
  fields: PropTypes.arrayOf(reportFieldType).isRequired,
  filters: PropTypes.object.isRequired,
  contragentId: PropTypes.number,
  setContragent: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  fieldsFetching: PropTypes.bool.isRequired,
  needReload: PropTypes.bool.isRequired,
  setNeedReload: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  fileFetching: PropTypes.bool.isRequired,
  answersOnly: PropTypes.bool.isRequired,
};

FiltersCard.defaultProps = {
  contragentId: null,
  fetching: false,
};

export default FiltersCard;
