import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { TILE_LAYER } from 'app/utils/map';
import styles from './TradepointEditScreen.module.css';

const CenterMarker = ({ popup, initialCenter }) => {
  const [position, setPosition] = useState(initialCenter);

  const map = useMapEvents({
    move() {
      const latlng = map.getCenter();
      setPosition([latlng.lat, latlng.lng]);
    },
    moveend() {
      const latlng = map.getCenter();
      setPosition([latlng.lat, latlng.lng]);
    },
  });

  return (
    <Marker position={position}>
      <Popup>{popup}</Popup>
    </Marker>
  );
};

CenterMarker.propTypes = {
  popup: PropTypes.string,
  initialCenter: PropTypes.arrayOf(PropTypes.number).isRequired,
};

CenterMarker.defaultProps = {
  popup: null,
};

const MapCenterCoordinatesTwoWayBinding = ({ position, update }) => {
  /*
   * Замудрённая связка координат точки и центра карты
   * Во избежание рекурсивных обновлений типа
   * широта в поле ввода -> центр карты -> широта в поле ввода
   * (потому что центр карты почти всегда будет немного смещён от вводимых значений)
   * выключаем флагом обновление координат после передвижений карты
   *
   * useState для флага не подойдёт, потому что при вызове события значение будет
   * не актуальное
   */
  let canChangeCoordinates = false;

  const map = useMapEvents({
    mousedown() {
      canChangeCoordinates = true;
    },
    moveend() {
      if (!canChangeCoordinates) {
        return;
      }

      const latlng = map.getCenter();
      const centerLongitude = parseFloat(latlng.lng.toFixed(7));
      const centerLatitude = parseFloat(latlng.lat.toFixed(7));
      update({ latitude: centerLatitude, longitude: centerLongitude });
    },
  });
  useEffect(() => {
    canChangeCoordinates = false;
    map.setView({ lat: position[0], lng: position[1] }, map.getZoom());
  }, position);

  return null;
};

MapCenterCoordinatesTwoWayBinding.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  update: PropTypes.func.isRequired,
};

const EditMap = ({ tradepoint, update }) => {
  let position;
  if (tradepoint.latitude && tradepoint.longitude) {
    position = [tradepoint.latitude, tradepoint.longitude];
  }

  return (
    <MapContainer
      center={position ?? [55.7558, 37.6173]}
      className={styles.map}
      zoom={13}
      keyboard={false}
      scrollWheelZoom="center"
      doubleClickZoom="center"
      touchZoom="center"
    >
      {TILE_LAYER}
      {position && <CenterMarker initialCenter={position} popup={tradepoint.name} />}
      {position && <MapCenterCoordinatesTwoWayBinding position={position} update={update} />}
    </MapContainer>
  );
};

EditMap.propTypes = {
  tradepoint: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  update: PropTypes.func.isRequired,
};

export default EditMap;
