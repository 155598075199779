import { combineReducers } from 'redux';
import { makeRootReducer } from 'react-redux-utils/immer';

import { ROLES_WEIGHT } from 'app/config/rolesHierarchy';

import { makeEmptyReducer } from './utils';
import * as contragents from './contragents';
import * as team from './team';
import * as task from './task';
import * as user from './user';

import tasks from './tasks';
import reports from './reports';
import photoExport from './photoExport';
import teamUser from './teamUser';
import roles from './roles';
import kpi from './kpi';
import events from './events';
import newEvent from './newEvent';
import tradepointsAudit from './tradepointsAudit';
import feedback from './feedback';
import restoredTaskEditor from './restoredTaskEditor';
import planogram from './planogram';
import kladr from './kladr';
import schedule from './schedule';
import questionary from './questionary';
import fixingIR from './fixingIR';
import * as questionaryMultiBinding from './questionaryMultiBinding';

const combinedReducer = combineReducers({
  user: user.reducer,
  questionary,
  contragents: contragents.reducer,
  schedule,
  team: team.reducer,
  task: task.reducer,
  tasks,
  reports,
  photoExport,
  teamUser,
  roles,
  kpi,
  events,
  newEvent,
  tradepointsAudit,
  feedback,
  restoredTaskEditor,
  planogram,
  kladr,
  grids: makeEmptyReducer({}),
  references: makeEmptyReducer({}),
  questionaryTradepointRefs: makeEmptyReducer({
    bindingsInProgress: {},
  }),
  importTasks: makeEmptyReducer({
    cancelsInProgress: {},
  }),
  productProblems: makeEmptyReducer({
    editDialog: {
      isOpen: false,
      isNew: false,
      form: {},
    },
  }),
  fixingIR,
  questionaryMultiBinding: questionaryMultiBinding.reducer,
  planogramTradepointRefs: makeEmptyReducer({
    bindingsInProgress: {},
  }),
  questionaryCategories: makeEmptyReducer({
    editDialog: {
      isOpen: false,
      form: {},
    },
  }),
  rescheduleReasons: makeEmptyReducer({
    editDialog: {
      isOpen: false,
      form: {},
    },
  }),
});

export default makeRootReducer(combinedReducer);

export const getContragents = (state) => contragents.getContragents(state.contragents);
export const getContragentsFetching = (state) => contragents.getFetching(state.contragents);
export const getContragentsError = (state) => contragents.getError(state.contragents);
export const isOnlyOneContragent = (state) => contragents.isOnlyOneContragent(state.contragents);
export const getContragentId = (state) => contragents.getContragentId(state.contragents);
export const needHideCrisisManager = (state) => contragents.hideCrisisManager(state.contragents);

export const getTask = (state) => task.getTask(state.task);
export const getTaskIsFetching = (state) => task.getIsFetching(state.task);
export const getTaskError = (state) => task.getError(state.task);

export const getUser = (state) => state.user;
export const getUserId = (state) => state.user && state.user.user && state.user.user.id;
export const isAdmin = (state) => user.isAdmin(state.user);
export const isAdminOrAgencyAdmin = (state) => user.isAdminOrAgencyAdmin(state.user);

export const canEditTeamUser = (state) => user.canEditTeamUser(state);
export const canViewAgencies = (state) => user.canViewAgencies(state);
export const canImportQustionaries = (state) => user.canImportQustionaries(state.user);

export const canEditShedule = (state) => user.canEditShedule(state.user);
export const canResetStartedVisit = (state) => user.canResetStartedVisit(state.user);
export const canCompleteStartedVisit = (state) => user.canCompleteStartedVisit(state.user);
export const canCreateAudit = (state) => user.canCreateAudit(state.user);
export const isAgencyAdmin = (state) => user.isAgencyAdmin(state.user);
export const hasRight = (state, right) => user.hasRight(state.user, right);
export const makeHasRight = (right) => (state) => user.hasRight(state.user, right);
export const hasRole = (state, ...roles) => user.hasRole(state.user, ...roles);
export const makeHasRole = (...roles) => (state) => user.hasRole(state.user, ...roles);
export const makeHasSetting = (setting) => (state) => user.hasSetting(state.user, setting);
export const canRestoredTasksEditor = (state) => user.canRestoredTasksEditor(state.user);
export const canRestoredTasksMark = (state) => user.canRestoredTasksMark(state.user);
export const canViewFakeGPS = (state) => user.canViewFakeGPS(state.user);
export const canViewFakePhoto = (state) => user.canViewFakePhoto(state.user);
export const canSetPassword = (state) => user.canSetPassword(state.user);
export const needHideVisitUsers = (state) => user.needHideVisitUsers(state.user);
export const needHideVisitMerch = (state) => user.needHideVisitMerch(state.user);
export const canEditProductProblem = (state) => user.canEditProductProblem(state.user);
export const canEditProduct = (state) => user.canEditProduct(state.user);
export const canEditProductBrands = (state) => user.canEditProductBrands(state.user);
export const canEditProductCategories = (state) => user.canEditProductCategories(state.user);
export const canEditQuestionaryCategories = (state) => user.canEditQuestionaryCategories(state.user);
export const canEditRescheduleReasons = (state) => user.canEditRescheduleReasons(state.user);
export const canEditBranches = (state) => user.canEditBranches(state.user);
export const canEditTradepointProjects = (state) => user.canEditTradepointProjects(state.user);
export const canEditTradepointTerritories = (state) => user.canEditTradepointTerritories(state.user);
export const canEditTradepointFormats = (state) => user.canEditTradepointFormats(state.user);
// временный костыль
export const needHidePhotoExport = (state) => user.needHidePhotoExport(state.user);
export const showCheckEquipment = (state) => user.showCheckEquipment(state.user);
export const showCashboxFeedFilter = (state) => user.showCashboxFeedFilter(state.user);
export const hideFeedTaskType = (state) => user.hideFeedTaskType(state.user);

export const rolesToNames = (userRoles, rolesMap) => {
  const sortedRoles = [...userRoles];
  sortedRoles.sort((a, b) => ROLES_WEIGHT[b] - ROLES_WEIGHT[a]);
  return sortedRoles.filter(r => r in rolesMap).map(r => rolesMap[r]).join(', ');
};
