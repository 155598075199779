import React, { useState, useImperativeHandle } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { Alert } from '@blueprintjs/core';

import i18n from 'i18n';

import { showError } from './toaster';

const Alerts = React.memo(React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [{ content, isOpen, ...alertProps }, setAlertProps] = useState({ isOpen: false });
  useImperativeHandle(ref, () => ({
    show: ({ onClose, autoClose, ...newProps }) => setAlertProps({
      isOpen: true,
      cancelButtonText: t('cancel'),
      ...newProps,
      onClose: (...args) => {
        if (autoClose !== false) {
          setAlertProps({ isOpen: false });
        }
        onClose?.(...args);
      },
    }),
    update: (newProps) => setAlertProps((current) => ({ ...current, ...newProps })),
  }));

  if (!isOpen) {
    return null;
  }

  return (
    <Alert isOpen {...alertProps}>
      {content}
    </Alert>
  );
}));

const containerElement = document.createElement('div');
document.body.appendChild(containerElement);
const alertsRef = React.createRef();
const root = createRoot(containerElement);
root.render(<Alerts ref={alertsRef} />);

function show(props) {
  return new Promise((resolve) => alertsRef.current.show({ ...props, onClose: resolve }));
}

function close() {
  alertsRef.current.update({ isOpen: false });
}

async function actionWithConfirm({ action, ...props }) {
  const confirmed = await show({ ...props, autoClose: false });
  try {
    let result;
    if (confirmed) {
      alertsRef.current.update({ loading: true });
      result = await action();
    }
    return { confirmed, result };
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return { confirmed, error };
  } finally {
    close();
  }
}

const alerts = { show, close, actionWithConfirm };
export default alerts;
