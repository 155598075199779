import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  H2,
  NonIdealState,
  Spinner,
  Card,
  Elevation,
  Button,
  Intent,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import Page from 'app/widgets/Page';
import { showSuccess, showError } from 'app/widgets/toaster';
import { kladrType } from 'app/proptyping';
import { useRequest } from 'app/utils';
import { editKladr } from 'app/api';
import { fetchKladrAction, updateKladrAction } from 'app/state/actionCreators/kladr';
import KladrForm from './KladrForm';
import Tradepoints from './Tradepoints';
import styles from './index.module.css';

const KladrEditScreen = ({
  kladr,
  fetching,
  error,
  fetchKladr,
  updateKladr,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [{
    fetching: saving,
  }, saveKladr] = useRequest(editKladr);
  const onSave = () => {
    saveKladr(id, kladr)
      .then(() => {
        showSuccess(t('KLADR saved'));
        history.goBack();
      })
      .catch((e) => {
        showError(e.message || t('saving error'));
      });
  };
  useEffect(() => {
    fetchKladr(id);
  }, [fetchKladr, id]);
  const loading = fetching || saving;

  return (
    <Page>
      <H2>
        {t('editing KLADR')}
      </H2>

      {error && <NonIdealState title={t('error')} description={error.message} icon="error" />}
      {loading && <Spinner />}
      {!error && !loading && (
        <Card elevation={Elevation.TWO}>
          <KladrForm
            kladr={kladr}
            updateKladr={updateKladr}
            t={t}
          />
          <Tradepoints t={t} />
          <div className={styles.buttonGroup}>
            <Button
              icon="cross"
              className={styles.cancelButton}
              onClick={() => history.goBack()}
            >
              {t('cancel')}
            </Button>
            <Button
              icon="tick"
              intent={Intent.PRIMARY}
              onClick={onSave}
            >
              {t('save')}
            </Button>
          </div>
        </Card>
      )}
    </Page>
  );
};

KladrEditScreen.propTypes = {
  kladr: kladrType,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  fetchKladr: PropTypes.func.isRequired,
  updateKladr: PropTypes.func.isRequired,
};

KladrEditScreen.defaultProps = {
  kladr: {},
  error: null,
};

const mapStateToProps = (state) => ({
  fetching: state.kladr.fetching,
  error: state.kladr.error,
  kladr: state.kladr.kladr,
});

const mapDispatchToProps = (dispatch) => ({
  fetchKladr: (id) => dispatch(fetchKladrAction(id)),
  updateKladr: (kladr) => dispatch(updateKladrAction(kladr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KladrEditScreen);
