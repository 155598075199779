import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Card, Elevation, Intent, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import { eventType } from 'app/proptyping';
import alerts from 'app/widgets/alerts';
import * as api from 'app/api';
import { EVENT_STATUS_MAP } from 'app/config/statuses';
import * as selectors from 'app/state/reducers';
import { getPhotoTimeStr } from 'app/state/reducers/task';
import Lightbox from 'app/widgets/Lightbox';
import ImageDescription from 'app/widgets/ImageDescription';
import NoCamera from 'app/assets/no-camera.svg';

import styles from './EventList.module.css';

const renderImage = (event) => (idx, image, toggleLightbox) => (
  <input
    type="image"
    key={`${event.id}_${idx}`}
    className={styles.resultCardPhoto}
    src={image.thumbnail}
    alt={`photo_${event.id}_${idx}.jpg`}
    onClick={toggleLightbox.bind(null, idx)}
  />
);

const Title = ({ title, moment }, t) => (
  <>
    {title}
    {moment === 'before' && ` [${t('photo before')}]`}
    {moment === 'after' && ` [${t('photo after')}]`}
  </>
);
Title.propTypes = {
  title: PropTypes.string.isRequired,
  moment: PropTypes.string.isRequired,
};

const ExpireTime = ({ expiredTime, isExpired, t }) => {
  if (!expiredTime) {
    return null;
  }
  if (!isExpired) {
    return (
      <>
        {t('period of execution')} -
        {' '}
        {expiredTime.toLocaleString()}
      </>
    );
  }
  return (
    <span className={styles.expired} title={t('period of execution expired')}>
      {t('period of execution')} -
      {' '}
      {expiredTime.toLocaleString()}
      {' '}
      <Icon icon="error" intent={Intent.DANGER} />
    </span>
  );
};

ExpireTime.propTypes = {
  expiredTime: PropTypes.instanceOf(Date),
  isExpired: PropTypes.bool,
  t: PropTypes.func,
};

ExpireTime.defaultProps = {
  expiredTime: null,
  isExpired: false,
  t: () => {},
};

const getAuthor = (author, rolesMap) => {
  if (!author) {
    return '';
  }

  let res = ` ${author.name || ''}`;

  if (author.roles) {
    const roles = (
      author.roles
        .filter((r) => !['general_user', 'test_user', 'pilot_tester'].includes(r))
        .map((r) => rolesMap[r])
    );
    res = `[${roles.join(', ')}]` + res;
  }

  if (author.phone) {
    res += ` (${author.phone})`;
  }
  return res;
};

const getUser = (user) => {
  if (!user) {
    return null;
  }

  let res = `${user.name || ''}`;
  if (user.phone) {
    res += ` (${user.phone})`;
  }
  return res;
};

async function handleClickDelete({ t, event, onDeleteEvent, setDeleting }) {
  setDeleting(true);
  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deleteEvent(event.id),
    cancelButtonText: t('cancel'),
    confirmButtonText: t('delete'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: t('deleteEventQuestion'),
  });
  setDeleting(false);
  if (confirmed && !error) {
    onDeleteEvent();
  }
}

export default
function Event({ event, onCopyEvent, onDeleteEvent, hideCreatePhotoTime }) {
  const { t } = useTranslation();
  const canEditEvent = RRU.useSelector(selectors.makeHasRight('webapp_events_edit'));
  const rolesMap = RRU.useSelector('roles.rolesMap');

  const hideVisitUsers = RRU.useSelector(selectors.needHideVisitUsers);
  const hideVisitMerch = RRU.useSelector(selectors.needHideVisitMerch);
  const isSomeAdmin = RRU.useSelector(selectors.isAdminOrAgencyAdmin);
  const userId = RRU.useSelector('user.user.id');
  const canDelete = event.status === 'NEW' && (isSomeAdmin || event.author.id === userId);
  const [deleting, setDeleting] = React.useState(false);
  const onClickDelete = RRU.useCallbackOptions(handleClickDelete, { t, event, onDeleteEvent, setDeleting });

  return (
    <Card elevation={Elevation.TWO} className={styles.resultCard} key={event.id}>

      <div className={styles.resultCardHeader}>
        <div>{t('eventType')} № {event.id}</div>
        <div>
          <ExpireTime expiredTime={event.expired_time} isExpired={event.is_expired} t={t} />
          {canEditEvent && canDelete && onDeleteEvent && (
            <Button
              icon="cross"
              minimal
              loading={deleting}
              intent={Intent.DANGER}
              onClick={onClickDelete}
            />
          )}
        </div>
      </div>

      <div className={styles.resultCardInfo}>
        <div className={styles.resultCardInfoGroup}>
          {t('signboard')} -
          {` ${event.tradepoint.info.network || ''}`}
          <br />
          {t('address')} -
          {` ${event.tradepoint.info.address || ''}`}
          <br />
          {t('affiliate')} -
          {` ${event.tradepoint.branch || ''}`}
          <br />
          {t('point code')} -
          {` ${event.tradepoint.info.code || ''}`}
          <br />
          {t('author')} - {hideVisitUsers ? event.author?.id : getAuthor(event.author, rolesMap)}
          <br />
          {t('create date')} -
          {' '}
          {event.create_date?.toLocaleDateString()}
        </div>
        <div className={[styles.resultCardInfoGroup, styles.textAlignRight].join(' ')}>
          {t('status')} -
          {' '}
          <Icon
            icon="full-circle"
            iconSize={10}
            color={EVENT_STATUS_MAP[event.status].color}
            className={styles.statusIcon}
          />
          {' '}
          {t(EVENT_STATUS_MAP[event.status].label)}
          <br />
          {t('executor')} - {hideVisitUsers || hideVisitMerch ? event.executor?.id : getUser(event.executor)}
          <br />
          {t('supervisor')} - {hideVisitUsers ? event.supervisor?.id : getUser(event.supervisor)}
          <br />
          {t('start date')} -
          {' '}
          {event.start_time?.toLocaleString()}
          <br />
          {t('date of completion')} -
          {' '}
          {event.end_time?.toLocaleString()}
        </div>
      </div>

      <div className={styles.resultCardInfo}>
        {t('description')} -
        {' '}
        {(event.detail && event.detail.text) || ''}
      </div>

      <div className={styles.resultCardInfo}>
        {t('comment')} -
        {' '}
        {(event.result && event.result.text) || ''}
      </div>

      <div className={styles.resultCardPhotos}>
        {event.result && event.result.photos && (
          <Lightbox
            thumbnailWidth="160px"
            thumbnailHeight="160px"
            images={event.result.photos.map((photo) => ({
              src: photo.preview ? photo.original : NoCamera,
                title: Title(photo, t),
                description: {
                  address: event.tradepoint.info.address || '-',
                    network: event.tradepoint.info.network || '-',
                    date: getPhotoTimeStr({
                      photo,
                      hideTime: hideCreatePhotoTime,
                    }),
                    coordinates: photo.coordinates,
                },
                thumbnail: photo.preview || NoCamera,
            }))}
            renderImageFunc={renderImage(event)}
            renderDescriptionFunc={ImageDescription}
          />
        )}
      </div>
      <div className={styles.actionsGroup}>
        {event.group_id && (
          <Link to={`/task-groups/${event.group_id}/result`}>
            <Button
              text={t('view report')}
              intent={Intent.PRIMARY}
              minimal
              icon="info-sign"
            />
          </Link>
        )}
        {canEditEvent && (event.status === 'COMPLETED' || event.status === 'SKIPPED') && (
          <Button
            text={t('resume')}
            intent={Intent.PRIMARY}
            icon="repeat"
            onClick={() => onCopyEvent(event)}
          />
        )}
      </div>
    </Card>
  );
}

Event.propTypes = {
  event: eventType.isRequired,
  onCopyEvent: PropTypes.func.isRequired,
  onDeleteEvent: PropTypes.func,
  hideCreatePhotoTime: PropTypes.bool.isRequired,
};
