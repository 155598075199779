import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';
import { Button, Intent } from '@blueprintjs/core';

import * as api from 'app/api';
import * as utils from 'app/utils';
import { showError, showSuccess } from 'app/widgets/toaster';
import styles from './QuestionaryBindingTradepointsScreen.module.css';

const Toolbar = ({ filters }) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);

  const bind = async ({ filters }) => {
    setFetching(true);
    try {
      await api.bindQuestionaryTradepointsByFilter(filters);
      showSuccess(t('bind successfully'));
    } catch (e) {
      showError(e.message);
    } finally {
      setFetching(false);
    }
  }
  
  const unbind = async ({ filters }) => {
    setFetching(true);
    try {
      await api.unbindQuestionaryTradepointsByFilter(filters);
      showSuccess(t('unbind successfully'));
    } catch (e) {
      showError(e.message);
    } finally {
      setFetching(false);
    }
  }
  const onClickBind = RRU.useCallbackOptions(bind, { filters });
  const onClickUnbind = RRU.useCallbackOptions(unbind, { filters });
  const questionaries = utils.useSelectorMemo((state) => state.questionaryMultiBinding.filters.questionary_id__in);

  return (
    <div className={styles.toolbar}>
      <Button
        icon="tick"
        text={t('bind everithing')}
        intent={Intent.SUCCESS}
        onClick={onClickBind}
        disabled={fetching || !questionaries || !questionaries.length}
      />
      <Button
        icon="cross"
        text={t('unbind everithing')}
        intent={Intent.DANGER}
        onClick={onClickUnbind}
        disabled={fetching || !questionaries || !questionaries.length}
      />
    </div>
  );
}

Toolbar.propTypes = {
  filters: PropTypes.shape({
    agency_id__eq: PropTypes.number,
    contragent_id__eq: PropTypes.number,
    code__ilike: PropTypes.string,
    address__ilike: PropTypes.string,
    branch_id__in: PropTypes.arrayOf(PropTypes.string),
    network_id__in: PropTypes.arrayOf(PropTypes.string),
    project_id__in: PropTypes.arrayOf(PropTypes.string),
    ir_code__ilike: PropTypes.string,
    questionary_id__in: PropTypes.arrayOf(PropTypes.number),
  }),
};

export default Toolbar;
