import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Intent } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import { AgencyContragentFilter, TradeNetworkFilter } from 'app/widgets/Filters';
import { isAdmin as getIsAdmin } from 'app/state/reducers';
import { exportData } from 'app/state/actionCreators/references';
import { USER_TASK_CHECKS_QUESTIONS } from 'app/api';

const INITIAL_SORT = { field: 'order', direction: 'asc' };

class UserTaskCheckQuestionsScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingQuestion: null,
    };
  }

  render() {
    const { t, filters, downloadData } = this.props;

    return (
      <Page title={t('questions to check visits')}>
        <Grid path={USER_TASK_CHECKS_QUESTIONS} initialSort={INITIAL_SORT}>
          <Grid.Actions>
            <Button
              intent={Intent.SUCCESS}
              icon="download"
              onClick={() => downloadData(USER_TASK_CHECKS_QUESTIONS, filters)}
              minimal
            >
              {t('download')}
            </Button>
          </Grid.Actions>

          <Grid.Filter acceptor={AgencyContragentFilter} path="contragent_id__eq" multi={false} autoSelectFirst />
          <Grid.Filter acceptor={TradeNetworkFilter} path="network_id__in" dependsOn="contragent_id__eq" />
          <Grid.Filter
            acceptor={InputFilter}
            path="category__fuzzy_like"
            withDebounce
            name={t('category')}
            placeholder={t('searchByCategory')}
          />
          <Grid.Filter
            acceptor={InputFilter}
            path="text__fuzzy_like"
            withDebounce
            name={t('question')}
            placeholder={t('search by text of question')}
          />

          <Grid.Column path="trade_network" title={t('commercial network')} sortable />
          <Grid.Column path="category" title={t('category')} sortable />
          <Grid.Column path="question" title={t('question')} sortable />
          <Grid.Column path="description" title={t('description')} sortable />
          <Grid.Column path="tooltip" title={t('tooltip')} sortable />
          <Grid.Column path="order" title={t('questionOrder')} sortable />
        </Grid>
      </Page>
    );
  }
}

UserTaskCheckQuestionsScreen.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    contragent_id__eq: PropTypes.number,
    network_id__in: PropTypes.arrayOf(PropTypes.string),
  }),
  downloadData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: getIsAdmin(state),
  filters: state.grids[USER_TASK_CHECKS_QUESTIONS]?.filters,
});

const mapDispatchToProps = (dispatch) => ({
  downloadData: (path, filters) => dispatch(exportData(path, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserTaskCheckQuestionsScreen));
