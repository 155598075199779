import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Page from 'app/widgets/Page';
import {canEditTradepointTerritories as getEditTradepointTerritories} from 'app/state/reducers';
import SingleColumnReference from 'app/widgets/SingleColumnReference';

export default React.memo(() => {
  const { t } = useTranslation();
  const canEdit = useSelector(getEditTradepointTerritories);

  return (
    <Page title={t('territories')}>
      <SingleColumnReference canEdit={canEdit} path="/tradepoint-territories" />
    </Page>
  );
});
