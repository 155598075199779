import React from 'react';
import { Card, Elevation, Button, Intent, Spinner, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import Page from 'app/widgets/Page';
import * as RefSel from 'app/widgets/ReferenceSelect';
import Form, { ReduxForm } from 'app/widgets/Form';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';
import { downloadReport, downloadReportGrouped } from 'app/state/actionCreators/reports/jti';

import styles from './ReportJtiScreen.module.css';

export default React.memo(() => {
  const { t } = useTranslation();
  const fetching = utils.useSelectorMemo((state) => state.reports.jti.fetching);
  const filtersValid = utils.useSelectorMemo((state) => state.reports.jti.filtersValid);
  const onClickDownload = utils.useDispatchCallback(downloadReport);
  const onClickDownloadGrouped = utils.useDispatchCallback(downloadReportGrouped);

  return (
    <Page title={`${t('report')} JTI`}>
      <Card elevation={Elevation.TWO} className={styles.panel}>
        <ReduxForm inline className={styles.form} path="reports.jti.filters">
          <Form.Item
            name="date__between"
            label={t('report date')}
            required
            acceptor={LocalizedDateRangeInput}
            shortcuts="months"
          />
          <Form.Item
            name="branch_id__in"
            label={t('affiliate')}
            acceptor={RefSel.BranchSelect}
          />
          <Form.Item
            name="format_id__in"
            label={t('format')}
            acceptor={RefSel.TradepointFormatSelect}
          />
          <Form.Item
            name="network_id__in"
            label={t('network')}
            acceptor={RefSel.TradeNetworkSelect}
          />
          <Form.Item
            name="territory_id__in"
            label={t('territory')}
            acceptor={RefSel.TradepointTerritorySelect}
          />
        </ReduxForm>

        <div className={styles.buttonsGroup}>
          <Button
            fill={false}
            className={styles.downloadButton}
            onClick={onClickDownload}
            minimal
            disabled={fetching || !filtersValid}
            icon="download"
            rightIcon={fetching && <Spinner size={Icon.SIZE_SMALL} />}
            text={t('download')}
            intent={Intent.SUCCESS}
          />
          <Button
            fill={false}
            className={styles.downloadButton}
            onClick={onClickDownloadGrouped}
            minimal
            disabled={fetching || !filtersValid}
            icon="download"
            rightIcon={fetching && <Spinner size={Icon.SIZE_SMALL} />}
            text={t('download with grouping')}
            intent={Intent.SUCCESS}
          />
        </div>
      </Card>
    </Page>
  );
});
