import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation, Button, Intent } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUserScheduleGaps, hasDublicateTask } from 'app/api';
import { useRequest } from 'app/utils';
import { distance } from 'app/utils/distance';
import { LatLon } from 'app/proptyping';

import styles from './index.module.css';
import TaskMap from './TaskMap';
import CreateForm, { isEndBeforeStart, validateGaps } from './CreateForm';

const MAX_DISTANCE = 400;

const TaskCard = ({
  isEditing,
  isCopying,
  active,
  task,
  updateTask,
  uploadShopPhoto,
  clearShopPhoto,
  onNext,
}) => {
  const [{ data: gaps }, fetchGaps] = useRequest(getUserScheduleGaps);
  const [{ data: invalidDublicateTask }, checkDublicateTask] = useRequest(hasDublicateTask);
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (task.date && task.userId && task.shop.id) {
      checkDublicateTask({
        tradepointId: task.shop.id,
        userId: task.userId,
        date: task.date,
        excludeTaskId: id,
      });
    }
  }, [id, task.date, task.userId, task.shop.id]);
  useEffect(() => {
    if (task.userId && task.date) {
      fetchGaps(task.userId, task.date);
    }
  }, [task.userId, task.date]);

  const endDistance = distance(task.taskLocation, task.endLocation);
  const finishedAtTP = endDistance < MAX_DISTANCE;
  const invalidEndBeforeStart = isEndBeforeStart(
    task.startDate,
    task.startDateTime,
    task.endDate,
    task.endDateTime,
  );
  const invalidGaps = validateGaps(
    isEditing,
    gaps,
    task.startDate,
    task.startDateTime,
    task.endDate,
    task.endDateTime,
  );

  const checks = [
    [!task.userId, t('executor not specified')],
    [!task.shop.id, t('no tt specified')],
    [!isEditing && !isCopying && !task.questionaryIds?.length, t('questionnaires not included')],
    [!task.date, t('date not specified')],
    [!task.startDate, t('start time not specified')],
    [!task.endDate, t('end time not specified')],
    [invalidEndBeforeStart, t('end before start')],
    [invalidGaps, t('does not fit into schedule of visits')],
    [invalidDublicateTask, t('match with an existing visit')],
  ];
  const invalid = checks.some(([bad]) => bad);
  const invalidHint = checks.filter(([bad]) => bad).map(([, hint]) => hint).join('\n') || null;

  return (
    <Card elevation={Elevation.TWO} className={active ? styles.active : styles.inactive}>
      <CreateForm
        gaps={gaps}
        isEditing={isEditing}
        isCopying={isCopying}
        task={task}
        updateTask={updateTask}
        uploadShopPhoto={uploadShopPhoto}
        clearShopPhoto={clearShopPhoto}
        finishedAtTP={finishedAtTP}
        invalidDublicateTask={invalidDublicateTask}
      />
      <TaskMap
        taskLocation={task.taskLocation}
        startLocation={task.startLocation}
        endLocation={task.endLocation}
        updateTask={updateTask}
      />
      <div className={styles.buttonGroup}>
        <div className={styles.spacer} />
        <Button
          disabled={invalid}
          title={invalidHint}
          intent={Intent.PRIMARY}
          text={t('next')}
          onClick={onNext}
          rightIcon="arrow-right"
        />
      </div>
    </Card>
  );
};

TaskCard.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isCopying: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  task: PropTypes.shape({
    userId: PropTypes.number,
    shop: PropTypes.shape({
      id: PropTypes.number,
      network: PropTypes.string,
      address: PropTypes.string,
    }).isRequired,
    shopPhoto: PropTypes.shape({
      uuid: PropTypes.string,
      uploading: PropTypes.bool,
      needReupload: PropTypes.bool,
    }).isRequired,
    questionaryIds: PropTypes.arrayOf(PropTypes.number),
    date: PropTypes.instanceOf(Date),
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    startDateTime: PropTypes.instanceOf(Date),
    endDateTime: PropTypes.instanceOf(Date),
    taskLocation: LatLon.isRequired,
    startLocation: LatLon.isRequired,
    endLocation: LatLon.isRequired,
  }).isRequired,
  updateTask: PropTypes.func.isRequired,
  uploadShopPhoto: PropTypes.func.isRequired,
  clearShopPhoto: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default TaskCard;
