import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { dispatch } from 'app/state';
import { setPagination, INITIAL_GRID_STATE } from 'app/state/actionCreators/questionaryMultiBinding';
import * as utils from 'app/utils';
import PageSelect from 'app/widgets/PageSelect';

import styles from './QuestionaryBindingTradepointsScreen.module.css';

function handlePageSelect({ path, fetchFunc, fetchOptions, pagination }, currentPage) {
  dispatch(setPagination(path, { ...pagination, currentPage }));
  dispatch(fetchFunc(path, fetchOptions));
}

function handleChangeItemsPerPage({ path, fetchFunc, fetchOptions }, itemsPerPage) {
  dispatch(setPagination(path, { itemsPerPage, currentPage: 1 }));
  dispatch(fetchFunc(path, fetchOptions));
}

function Pagination({ path, fetchFunc, fetchOptions, showTotal, showItemsPerPage }) {
  const { pagination, data, totalItems } = useSelector(
    (state) => state.questionaryMultiBinding.grids[path] ?? INITIAL_GRID_STATE
  );
  const onPageSelect = utils.useCallbackWithDeps(handlePageSelect, { path, fetchFunc, fetchOptions, pagination });
  const onChangeItemsPerPage = utils.useCallbackWithDeps(handleChangeItemsPerPage, { path, fetchFunc, fetchOptions });

  let totalPages;
  if (totalItems !== null) {
    totalPages = Math.max(1, Math.ceil(totalItems / pagination.itemsPerPage));
  } else if (data.length < pagination.itemsPerPage) {
    totalPages = pagination.currentPage;
  }

  return (
    <PageSelect
      className={styles.pageSelect}
      showTotal={showTotal && totalItems !== null}
      showItemsPerPageChanger={showItemsPerPage}
      total={totalItems}
      currentPage={pagination.currentPage}
      maxPage={totalPages}
      currentItemsPerPage={pagination.itemsPerPage}
      onPageSelect={onPageSelect}
      onChangeItemsPerPage={onChangeItemsPerPage}
    />
  );
}

Pagination.propTypes = {
  path: PropTypes.string.isRequired,
  fetchFunc: PropTypes.func.isRequired,
  fetchOptions: PropTypes.object.isRequired,
  showTotal: PropTypes.bool.isRequired,
  showItemsPerPage: PropTypes.bool.isRequired,
};

export default React.memo(Pagination);
