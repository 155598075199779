import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styles from './Filters.module.css';
import SimpleSelect from '../SimpleSelect';

const ComboFilter = ({
  name,
  value,
  onChange,
  items,
  filterable,
  emptiable,
  disabled,
  placeholder,
  t,
}) => (
  <div className={styles.filtersGroup}>
    <div className={styles.filtersLabel}>
      {name}
      {':'}
    </div>
    <SimpleSelect
      placeholder={placeholder}
      items={items}
      filterable={filterable}
      emptiable={emptiable}
      emptyText={t('all')}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  </div>
);

ComboFilter.propTypes = {
  filterable: PropTypes.bool,
  emptiable: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
    title: PropTypes.string,
  })).isRequired,
  placeholder: PropTypes.string,
  t: PropTypes.func,
};

ComboFilter.defaultProps = {
  value: undefined,
  filterable: false,
  emptiable: false,
  disabled: false,
  placeholder: undefined,
  t: () => {},
};

export default withTranslation()(ComboFilter);
