import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import styles from './TasksScreen.module.css';
import Task from './Task';

const TaskList = ({ tasks, fetching, error }) => {
  const { t } = useTranslation();
  if (fetching) {
    return <Spinner />;
  }
  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }
  if (!tasks || !tasks.length) {
    return <NonIdealState title={t('empty')} description={t('no routes found matching conditions')} icon="list" />;
  }
  return (
    <div className={styles.resultsPanel}>
      {tasks.map(Task)}
    </div>
  );
};

TaskList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
};

TaskList.defaultProps = {
  error: null,
};

export default TaskList;
