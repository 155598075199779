import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Cell, Column, Table2 as BlueprintTable, RenderMode, SelectionModes } from '@blueprintjs/table';
import { NonIdealState, Button, Menu, MenuItem, Tooltip, Position } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import { memoizeOnChildren } from 'app/utils';

import styles from './Grid.module.css';

const DEFAULT_COLUMN_WIDTH = 150;

const getSortIcon = (direction) => {
  if (direction === 'asc') {
    return 'sort-asc';
  }
  if (direction === 'desc') {
    return 'sort-desc';
  }
  return 'sort';
};

class Table extends React.PureComponent {
  columns = memoizeOnChildren((children) => children)

  constructor(props) {
    super(props);
    this.state = {
      columnWidths: [],
    };
  }

  componentDidMount() {
    this.autosize();
  }

  componentDidUpdate() {
    this.autosize();
  }

  get columnWidths() {
    const { columnWidths } = this.state;
    return _.zip(columnWidths, this.columns()).map(([fromState, column]) => fromState ?? column.props.width);
  }

  bodyContextMenuRenderer = (context) => {
    const { getContextMenu } = this.props;
    const items = getContextMenu(context);
    if (!items?.length) {
      return null;
    }

    return (
      <Menu>
        {items.map((item) => item.tooltip
          ? (
            <Tooltip
              className={styles.tooltip}
              key={item.text}
              content={item.tooltip}
              position={Position.TOP_RIGHT}
              inline={true}
            >
              <MenuItem {...item} />
            </Tooltip>
          )
          : <MenuItem key={item.text} {...item} />
        )}
      </Menu>
    );
  }

  cellRenderer = (row, column) => {
    const { fetching, data } = this.props;
    const { valueRenderer, path } = this.columnProps(column);

    const rowData = data[row];
    const value = fetching ? '' : _.get(rowData, path);
    return (
      <Cell loading={fetching}>
        {valueRenderer && !fetching ? valueRenderer(value, rowData, path) : value}
      </Cell>
    );
  }

  onSort = (index) => {
    const { sort, setSort } = this.props;
    const { path } = this.columnProps(index);

    let newSort = null;
    if (sort?.field !== path) {
      newSort = { field: path, direction: 'asc' };
    } else if (sort.direction === 'asc') {
      newSort = { field: path, direction: 'desc' };
    }

    setSort(newSort);
  }

  sortableNameRenderer = (name, index) => {
    const { sort } = this.props;
    const { path } = this.columnProps(index);
    const sortDirection = sort?.field === path ? sort.direction : null;

    return (
      <div className={styles.columnHeaderContainer}>
        <div className={styles.columnHeaderText}>
          {name}
        </div>
        <Button
          small
          minimal
          icon={getSortIcon(sortDirection)}
          className={styles.sortButton}
          onClick={() => this.onSort(index)}
        />
      </div>
    );
  }

  makeBlueprintColumn = (column, index) => {
    const { path, title, sortable, ...props } = column.props;
    return (
      <Column
        key={path}
        id={path}
        name={title}
        cellRenderer={this.cellRenderer}
        index={index}
        nameRenderer={sortable ? this.sortableNameRenderer : undefined}
        {...props}
      />
    );
  }

  onColumnWidthChanged = (index, width) => {
    const { columnWidths } = this.state;
    const newColumnWidths = columnWidths.slice();
    newColumnWidths[index] = width;
    this.setState({ columnWidths: newColumnWidths });
  }

  autosize() {
    const tableContainer = document.getElementsByClassName('bp4-table-container')[0];
    const scrollableContainer = document.getElementsByClassName('bp4-table-quadrant-scroll-container')[0];
    if (!tableContainer) {
      return;
    }

    let availableWidth = tableContainer.clientWidth - 30; // 30 for "rownumber" column
    const currentColumnWidths = this.columnWidths;
    const currentTotalWidth = currentColumnWidths.reduce((sum, w) => sum + (w ?? DEFAULT_COLUMN_WIDTH), 0);

    if (currentTotalWidth >= availableWidth) {
      if (scrollableContainer) {
        const leftPos = scrollableContainer.scrollLeft;
        const topPos = scrollableContainer.scrollLeft;
        scrollableContainer.scrollTo(topPos, leftPos); // fix for https://github.com/palantir/blueprint/issues/3779
      }
      return;
    }

    const ratio = availableWidth / currentTotalWidth;
    const newColumnWidths = [];
    for (let i = 0; i < currentColumnWidths.length; i += 1) {
      const newColumnWidth = Math.min(
        availableWidth,
        Math.round((currentColumnWidths[i] ?? DEFAULT_COLUMN_WIDTH) * ratio),
      );
      newColumnWidths.push(newColumnWidth);
      availableWidth -= newColumnWidth;
    }

    const { columnWidths: oldColumnWidths } = this.state;
    if (!_.isEqual(newColumnWidths, oldColumnWidths)) {
      this.setState({ columnWidths: newColumnWidths });
    }
  }

  columnProps(index) {
    return this.columns()[index].props;
  }

  render() {
    const {
      data,
      fetching,
      selectionModes,
      className,
      defaultRowHeight,
      error,
      getContextMenu,
      t,
    } = this.props;

    if (error) {
      return <NonIdealState title={t('error')} description={error.message || t('error')} icon="error" />;
    }

    if (!fetching && data.length === 0) {
      return <NonIdealState title={t('no data')} icon="list" />;
    }

    return (
      <BlueprintTable
        numRows={fetching ? 20 : data.length}
        defaultRowHeight={defaultRowHeight}
        renderMode={RenderMode.NONE}
        selectionModes={selectionModes}
        className={className}
        bodyContextMenuRenderer={getContextMenu ? this.bodyContextMenuRenderer : undefined}
        columnWidths={this.columnWidths}
        cellRendererDependencies={[this.columnWidths]}
        defaultColumnWidth={DEFAULT_COLUMN_WIDTH}
        onColumnWidthChanged={this.onColumnWidthChanged}
      >
        {this.columns().map(this.makeBlueprintColumn)}
      </BlueprintTable>
    );
  }
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetching: PropTypes.bool,
  className: PropTypes.string,
  defaultRowHeight: PropTypes.number,
  selectionModes: PropTypes.oneOf(_.values(SelectionModes)),
  error: PropTypes.any,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
  }),
  setSort: PropTypes.func.isRequired,
  getContextMenu: PropTypes.func,
  t: PropTypes.func.isRequired,
};

Table.defaultProps = {
  fetching: false,
  selectionModes: SelectionModes.ALL,
  className: null,
  error: null,
  sort: null,
  getContextMenu: null,
};

export default withTranslation()(Table);
