export const QUESTION_TYPES = {
  PERCENT: '%',
  NUMBER: 'number',
  INTEGER: 'integer',
  PRICE: 'price',
  SELECT: 'select answer',
  TEXT: 'comment',
  PHOTO: 'photography',
  BOOLEAN: 'yes/no',
  SUBTRACTION: 'subtraction',
  ADDITION: 'addition',
  DIVISION: 'division',
  MULTIPLICATION: 'multiplication',
  DATE: 'date',
  MULTICOMBO: 'multiple choice answer',
  QRCODE: 'qr-code',
};

export const NUMBER_TYPES_NAMES = {
  PRICE: 'lowercase the price',
  NUMBER: 'lowercase number',
  INTEGER: 'lowercase integer',
  PERCENT: '%',
};

export function isNumericType(type) {
  return [
    'NUMBER',
    'INTEGER',
    'PERCENT',
    'PRICE',
  ].includes(type);
}

export function isSelectType(type) {
  return [
    'BOOLEAN',
    'SELECT',
    'MULTICOMBO',
  ].includes(type);
}

export function isTypeWithoutDefault(type) {
  return [
    'PHOTO',
    'SUBTRACTION',
    'ADDITION',
    'DIVISION',
    'MULTIPLICATION',
    'QRCODE',
  ].includes(type);
}
