import { getUser } from 'app/api';
import * as ACTIONS from 'app/state/actions';

export const fetchUser = () => async (dispatch) => {
  dispatch({ type: ACTIONS.USER_FETCH_START });
  try {
    const user = await getUser();
    dispatch({ type: ACTIONS.USER_FETCH_DONE, user });
  } catch (error) {
    dispatch({ type: ACTIONS.USER_FETCH_ERROR, error });
  }
};

export const login = () => ({
  type: ACTIONS.LOGIN,
});
