import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { dispatch } from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import * as utils from 'app/utils';
import PageSelect from 'app/widgets/PageSelect';

import styles from './Grid.module.css';

function handlePageSelect({ path, fetchOptions, pagination }, currentPage) {
  dispatch(grids.setPagination(path, { ...pagination, currentPage }));
  dispatch(grids.fetch(path, fetchOptions));
}

function handleChangeItemsPerPage({ path, fetchOptions }, itemsPerPage) {
  dispatch(grids.setPagination(path, { itemsPerPage, currentPage: 1 }));
  dispatch(grids.fetch(path, fetchOptions));
}

function Pagination({ path, fetchOptions, showTotal, showItemsPerPage }) {
  const { pagination, data, totalItems } = useSelector((state) => state.grids[path] ?? grids.INITIAL_STATE);
  const onPageSelect = utils.useCallbackWithDeps(handlePageSelect, { path, fetchOptions, pagination });
  const onChangeItemsPerPage = utils.useCallbackWithDeps(handleChangeItemsPerPage, { path, fetchOptions });

  let totalPages;
  if (totalItems !== null) {
    totalPages = Math.max(1, Math.ceil(totalItems / pagination.itemsPerPage));
  } else if (data.length < pagination.itemsPerPage) {
    totalPages = pagination.currentPage;
  }

  return (
    <PageSelect
      className={styles.pageSelect}
      showTotal={showTotal && totalItems !== null}
      showItemsPerPageChanger={showItemsPerPage}
      total={totalItems}
      currentPage={pagination.currentPage}
      maxPage={totalPages}
      currentItemsPerPage={pagination.itemsPerPage}
      onPageSelect={onPageSelect}
      onChangeItemsPerPage={onChangeItemsPerPage}
    />
  );
}

Pagination.propTypes = {
  path: PropTypes.string.isRequired,
  fetchOptions: PropTypes.object.isRequired,
  showTotal: PropTypes.bool.isRequired,
  showItemsPerPage: PropTypes.bool.isRequired,
};

export default React.memo(Pagination);
