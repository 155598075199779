import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Grid from 'app/widgets/Grid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import {
  AgencyContragentFilter,
  TradeNetworkFilter,
  BranchFilter,
  TradepointProjectFilter,
  QuestionaryFilter,
} from 'app/widgets/Filters';
import * as utils from 'app/utils';
import { dispatch, getState } from 'app/state';
import {
  fetchQuestionaries,
  fetchTradepoints as getTradepoints,
  resetPage,
} from 'app/state/actionCreators/questionaryMultiBinding';
import { QUESTIONARIES, TRADEPOINTS } from 'app/api';

const Filters = ({
  filters,
  filtersVisible,
  setFilters,
  setFiltersVisible,
  clearFilters,
}) => {
  const { t } = useTranslation();

  return (
    <FiltersWrapper
      filters={filters}
      filtersVisible={filtersVisible}
      setFilters={setFilters}
      setFiltersVisible={setFiltersVisible}
      clearFilters={clearFilters}
    >
      <Grid.Filter
        acceptor={AgencyContragentFilter}
        path="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="code__ilike"
        icon="search"
        name={t('point code')}
        placeholder={t('search by point code')}
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="address__ilike"
        icon="search"
        name={t('address')}
        placeholder={t('search by address')}
      />
      <Grid.Filter
        acceptor={BranchFilter}
        path="branch_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={TradeNetworkFilter}
        path="network_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={TradepointProjectFilter}
        path="project_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="ir_code__ilike"
        name={t('extra code tt for ir')}
        placeholder={t('search by extra code tt for ir')}
      />
      <Grid.Filter
        acceptor={QuestionaryFilter}
        path="questionary_id__in"
        dependsOn="contragent_id__eq"
        multi={true}
      />
    </FiltersWrapper>
  );
};

const getFilters = () => {
  const state = getState();
  return state.questionaryMultiBinding.filters;
}

const filtersReady = (childrens) => {
  const filters = getFilters();
  // Фильтры не готовы если есть <Filter> для которого
  return !childrens.some((f) => (
    !f.props.onChange // не указан кастомный onChange
    && !filters[f.props.path] // и нет значения
    && (
      f.props.autoSelectFirst // и указан автовыбор (нужно подождать пока он загрузится)
      || f.props.acceptor === AgencyContragentFilter // или это AgencyContragentFilter (который всегда ждём)
    )
  ));
};

// необходим для отработки filtersReady
const FiltersWrapper = ({
  filters,
  filtersVisible,
  setFilters,
  setFiltersVisible,
  clearFilters,
  children,
}) => {
  const fetchOnFiltersChange = ({ path, fetch }) => {
    dispatch(resetPage(path));
    dispatch(fetch(path));
  };

  const fetchQuestionary = utils.useCallbackWithDeps(
    fetchOnFiltersChange,
    { path: QUESTIONARIES, fetch: fetchQuestionaries, },
  );
  const fetchTradepoints = utils.useCallbackWithDeps(
    fetchOnFiltersChange,
    { path: TRADEPOINTS, fetch: getTradepoints, },
  );

  const onMounted = () => {
    const ready = filtersReady(children);
    if (!ready) {
      return;
    }
    fetchQuestionary();
    fetchTradepoints();
  };

  useEffect(() => onMounted(), [filters]);
  return (
    <Grid.Filters
      visible={filtersVisible}
      filters={filters}
      setFilter={(key, value) => setFilters({ ...filters, [key]: value })}
      clearFilters={clearFilters}
    >
      {children}
    </Grid.Filters>
  );
};

const filterTypes = PropTypes.shape({
  agency_id__eq: PropTypes.number,
  contragent_id__eq: PropTypes.number,
  code__ilike: PropTypes.string,
  address__ilike: PropTypes.string,
  branch_id__in: PropTypes.arrayOf(PropTypes.string),
  network_id__in: PropTypes.arrayOf(PropTypes.string),
  project_id__in: PropTypes.arrayOf(PropTypes.string),
  ir_code__ilike: PropTypes.string,
  // questionary_id__in: PropTypes.arrayOf(PropTypes.number),
  id__in: PropTypes.arrayOf(PropTypes.number),
});

FiltersWrapper.propTypes = {
  filters: filterTypes,
  filtersVisible: PropTypes.bool.isRequired,
  setFilters: PropTypes.func.isRequired,
  setFiltersVisible: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

Filters.propTypes = {
  filters: filterTypes,
  filtersVisible: PropTypes.bool.isRequired,
  setFilters: PropTypes.func.isRequired,
  setFiltersVisible: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

export default Filters;
