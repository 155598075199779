import * as api from 'app/api';
import * as ACTIONS from 'app/state/actions';

import { $setIn, resolveAborting } from '../index';

export const fetchFields = (reportType, contragentId) => async (dispatch, getState) => {
  const { fieldsReportType } = getState().reports.dynamic;
  const reportTypeChanged = fieldsReportType !== reportType;
  dispatch({ type: ACTIONS.REPORT_FETCH_FIELDS_START, payload: { reportType, reportTypeChanged } });
  try {
    const fields = await api.getReporFields(reportType, contragentId);
    dispatch({
      type: ACTIONS.REPORT_FETCH_FIELDS_DONE,
      payload: {
        fields: fields.data,
        reportType,
        reportTypeChanged,
      },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.REPORT_FETCH_FIELDS_ERROR, error });
  }
};

export const fetchData = (reportType, filters, columns, answersOnly) => async (dispatch, getState) => {
  try {
    const { result, aborted } = await resolveAborting(
      api.getReporData(reportType, filters, columns, answersOnly),
      'reports.dynamic',
      dispatch,
      getState,
    );
    if (aborted) {
      return;
    }

    dispatch($setIn('reports.dynamic', {
      error: null,
      data: result.data,
    }));
  } catch (error) {
    dispatch($setIn('reports.dynamic', {
      error,
    }));
  }
};

export const exportData = (columns, filters, answersOnly) => async (dispatch, getState) => {
  const { fieldsReportType } = getState().reports.dynamic;
  dispatch({ type: ACTIONS.REPORT_EXPORT_DATA_START });
  try {
    await api.createBackgroundExportTask({
      type: 'dynamic-reports',
      params: {
        type: `${fieldsReportType.toUpperCase()}_REPORT`,
        columns,
        filter: filters,
        answers_only: answersOnly,
      },
    });
    return { success: true };
  } catch (error) {
    return { success: false, error };
  } finally {
    dispatch({ type: ACTIONS.REPORT_EXPORT_DATA_DONE });
  }
};

export const setFilters = (filters) => (
  { type: ACTIONS.REPORT_SET_FILTERS, payload: { filters } }
);

export const setContragent = (contragentId) => (
  { type: ACTIONS.REPORT_SET_CONTRAGENT, payload: { contragentId } }
);

export const setSort = (sort) => (
  { type: ACTIONS.REPORT_SET_SORT, payload: { sort } }
);

export const setFilterVisible = (filtersVisible) => (
  { type: ACTIONS.REPORT_SET_FILTER_VISIBLE, payload: { filtersVisible } }
);

export const reorderFields = (oldIndex, newIndex) => (
  { type: ACTIONS.REPORT_REORDER_FIELDS, payload: { oldIndex, newIndex } }
);

export const toggleField = (code, checked) => (
  { type: ACTIONS.REPORT_TOGGLE_FIELD, payload: { code, checked } }
);

export const setNeedReload = (needReload) => (
  { type: ACTIONS.REPORT_SET_NEED_RELOAD, payload: { needReload } }
);
