import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_FILTERS = {
  contragentId: undefined,
  date: new Date(),
  merchId: undefined,
};

const INITIAL_STATE = {
  data: [],
  filters: INITIAL_FILTERS,
  fetching: false,
  error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.TRADEPOINT_AUDIT_LIST_SET_FILTERS:
      return _.assign(state, { filters: action.filters });
    case ACTIONS.TRADEPOINT_AUDIT_LIST_CLEAR_FILTERS:
      return _.assign(state, { filters: { ...state.filters, date: new Date(), merchId: undefined } });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
