import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { H5, H4, Spinner, NonIdealState, Button, Intent, RadioGroup, Radio, HTMLSelect } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import * as selectors from 'app/state/reducers';
import { getUserTaskCheckQuestions, postUserTaskCheckResult } from 'app/api';
import { useRequestImmediate, useEffectWithDeps, useCallbackWithDeps } from 'app/utils';
import { dispatch } from 'app/state';
import { markUserTaskChecked } from 'app/state/actionCreators/grids';
import { showError } from 'app/widgets/toaster';
import NewEventDialog from 'app/widgets/NewEventDialog';

import styles from './UserTaskCheckScreen.module.css';

const questionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
});

const Question = React.memo(({ question, answer, setAnswer, t }) => {
  const { id, question: text, tooltip } = question;

  return (
    <div className={styles.question}>
      <div className={styles.questionText} title={tooltip}>{text}</div>

      <RadioGroup
        inline
        onChange={(ev) => setAnswer(id, ev.currentTarget.value)}
        selectedValue={answer}
      >
        <Radio className={styles.optionFalse} label={t('no')} value="NO" />
        <Radio className={styles.optionUndefined} label={t('not rated')} value="UNDEFINED" />
        <Radio className={styles.optionTrue} label={t('yes')} value="YES" />
      </RadioGroup>
    </div>
  );
});

Question.propTypes = {
  question: questionPropType.isRequired,
  answer: PropTypes.string,
  setAnswer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Question.defaultProps = {
  answer: null,
};

const QuestionCategory = React.memo(({ questions, answers, setAnswer, t }) => (
  <div className={styles.questionCategory}>
    <H4 className={styles.questionCategoryTitle}>{questions[0].category}</H4>
    {questions.map((q) => <Question key={q.id} question={q} answer={answers[q.id]} setAnswer={setAnswer} t={t} />)}
  </div>
));

QuestionCategory.propTypes = {
  questions: PropTypes.arrayOf(questionPropType).isRequired,
  answers: PropTypes.objectOf(PropTypes.string).isRequired,
  setAnswer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

function processQuestions({ questions, setQuestionsByCategory, setAnswers }) {
  if (!questions) {
    return;
  }

  const questionsByCategory = _.values(_.groupBy(questions, 'category'));
  setQuestionsByCategory(questionsByCategory);

  const answers = _.fromPairs(
    questions
      .filter(({ answer }) => answer !== null)
      .map(({ id, answer }) => [id, answer]),
  );
  setAnswers(answers);
}

function setSingleAnswer({ setAnswers }, id, value) {
  setAnswers((answers) => ({ ...answers, [id]: value }));
}

function fillAnswers({ questions, answers, setAnswers }, event) {
  const { value } = event.currentTarget;
  const newAnswers = { ...answers };
  for (const { id } of questions) {
    if (!newAnswers[id]) {
      newAnswers[id] = value;
    }
  }

  setAnswers(newAnswers);
}

async function save({ id: taskId, questions, answers, setSaving, history, t }) {
  const data = questions.map(({ id }) => ({ question_id: id, answer: answers[id] }));
  setSaving(true);
  try {
    await postUserTaskCheckResult(taskId, data);
    dispatch(markUserTaskChecked(taskId));
    history.goBack();
  } catch (error) {
    showError(error.message || t('save result error'));
  }
  setSaving(false);
}

function openNewEventDialog({ setNewEvent, task, questions, answers }) {
  const lines = [];
  for (const q of questions) {
    if (answers[q.id] === 'YES') {
      lines.push(q.description);
    }
  }

  setNewEvent({
    contragent_id: task.contragentId,
    tradepoint_id: task.data.shop.id,
    text: lines.join('\n'),
  });
}

export default React.memo(() => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const canEditEvent = RRU.useSelector(selectors.makeHasRight('webapp_events_edit'));

  const { fetching, error, data: questions } = useRequestImmediate(getUserTaskCheckQuestions, id);

  const [questionsByCategory, setQuestionsByCategory] = useState(null);
  const [answers, setAnswers] = useState({});
  useEffectWithDeps(processQuestions, { questions, setQuestionsByCategory, setAnswers });

  const setAnswerBound = useCallbackWithDeps(setSingleAnswer, { setAnswers });
  const fillAnswersBound = useCallbackWithDeps(fillAnswers, { questions, answers, setAnswers });

  const [saving, setSaving] = useState(false);
  const saveBound = useCallbackWithDeps(save, { id, questions, answers, setSaving, history, t });

  const [newEvent, setNewEvent] = useState(null);
  const task = useSelector((state) => state.task.data);
  const openNewEventDialogBound = useCallbackWithDeps(openNewEventDialog, { setNewEvent, task, questions, answers });
  const closeNewEventDialog = useCallback(() => setNewEvent(null), []);

  if (fetching) {
    return <Spinner />;
  }
  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }

  const canSave = _.keys(answers).length === questions?.length;

  return (
    <>
      <H5>{t('quality control of the visit')}</H5>
      <HTMLSelect id="one-for-all" value="" onChange={fillAnswersBound}>
        <option value="" disabled>{t('put down the answer')}</option>
        <option value="NO">{t('no')}</option>
        <option value="UNDEFINED">{t('not rated')}</option>
        <option value="YES">{t('yes')}</option>
      </HTMLSelect>

      {_.toPairs(questionsByCategory).map(([c, q]) => (
        <QuestionCategory key={c} questions={q} answers={answers} setAnswer={setAnswerBound} t={t} />
      ))}

      <div className={styles.buttonsContainer}>
        <Button
          icon="cross"
          text={t('cancel')}
          disabled={saving}
          onClick={() => history.goBack()}
        />
        <Button
          icon="tick"
          text={t('save')}
          title={canSave ? undefined : t('complete all answers')}
          disabled={!canSave || saving}
          loading={saving}
          intent={Intent.PRIMARY}
          onClick={saveBound}
        />
        {canEditEvent && (
          <Button
            icon="notifications"
            text={t('create a task')}
            title={canSave ? undefined : t('complete all answers')}
            disabled={!canSave || !task}
            intent={Intent.PRIMARY}
            onClick={openNewEventDialogBound}
          />
        )}
      </div>

      {newEvent && <NewEventDialog initialData={newEvent} onClose={closeNewEventDialog} />}
    </>
  );
});
