import _ from 'lodash';

import i18n from 'i18n';
import { ISODate } from 'app/utils';
import { dispatch } from 'app/state';
import { LOGOUT_DONE } from 'app/state/actions';

function makeLogoutBroadcastChannel() {
  if (!window.BroadcastChannel) {
    return null;
  }

  const channel = new BroadcastChannel('logout');
  channel.onmessage = () => dispatch({ type: LOGOUT_DONE });
  return channel;
}

export const logoutChannel = makeLogoutBroadcastChannel();

const formatDate = (value) => {
  if (value instanceof Date) {
    return ISODate(value);
  }
  return value;
};

const formatQueryStringValue = (value, formatArrays) => {
  if (Array.isArray(value) && formatArrays) {
    return `[${value.join()}]`;
  }
  return formatDate(value);
};

export const formatDates = (params) => Object.entries(params)
  .reduce((dst, [key, value]) => ({
    ...dst,
    [key]: formatDate(value),
  }), { });

export function normalizeFilters(filters) {
  const result = {};
  for (const [key, value] of _.toPairs(filters)) {
    if (_.isNil(value)) {
      continue;
    }

    if (!key.endsWith('__between')) {
      result[key] = value;
      continue;
    }

    const field = key.split('__')[0];
    for (const [operator, bound] of _.zip(['ge', 'le'], value)) {
      if (!_.isNil(bound)) {
        result[`${field}__${operator}`] = bound;
      }
    }
  }

  return result;
}

export function serializeFilters(filters, formatArrays=true) {
  return _.mapValues(normalizeFilters(filters), v => formatQueryStringValue(v, formatArrays));
}

export const buildQueryParams = ({ filters, pagination, sort }) => {
  const params = _.mapKeys(serializeFilters(filters), (v, key) => `filter[${key}]`);

  if (pagination) {
    params['page[size]'] = pagination.itemsPerPage;
    params['page[number]'] = pagination.currentPage && pagination.currentPage - 1;
  }

  if (sort?.field) {
    params.sort = sort.direction === 'asc' ? sort.field : `-${sort.field}`;
  }

  return params;
};

export const extractError = (response) => {
  const { data } = response;
  if (data?.success === false) {
    const err = new Error(data.mes);
    err.detail = data.detail;
    err.type = data.type;
    err.response = response;
    return err;
  }
  return null;
};

export const extractBlobError = async (error) => {
  if (error && error.response && error.response.data instanceof Blob) {
    try {
      const text = await error.response.data.text();
      const data = JSON.parse(text);
      const serverError = extractError({ data });
      return serverError || error;
    } catch (parseError) {
      // неспарсилось тело блоба
      return error;
    }
  }
  return error;
};

export const errorInterceptor = async (error) => {
  if (error.code === 'ETIMEDOUT') {
    throw new Error(i18n.t('requestTimedOut'));
  }

  if (error.code === 'ERR_CANCELED') {
    throw error;
  }

  if (!error.response) {
    console.log('client side request error', error);
    throw new Error(i18n.t('serverAccessError'));
  }

  let serverError;
  if (error.response.data instanceof Blob) {
    serverError = await extractBlobError(error);
  } else {
    serverError = extractError(error.response);
  }

  const isCheckAuth = error.request.responseURL.endsWith('/api/webapp/auth');
  if (!isCheckAuth && error.isAxiosError && error.response.status === 401) {
    dispatch({ type: LOGOUT_DONE, error: serverError });
  }

  throw serverError || error;
};

export const successInterceptor = (response) => {
  const serverError = extractError(response);
  if (serverError) {
    return Promise.reject(serverError);
  }
  const { data } = response;

  if (data instanceof Blob) {
    return response;
  }
  return data;
};

export function toSnakeCase(obj) {
  if (_.isArray(obj)) {
    return obj.map(toSnakeCase);
  }
  if (_.isPlainObject(obj)) {
    return _.fromPairs(_.toPairs(obj).map(([k, v]) => [_.snakeCase(k), toSnakeCase(v)]));
  }

  return obj;
}
