import React from 'react';
import PropTypes from 'prop-types';
import { Classes, Button, FormGroup, Dialog, Intent, Checkbox } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import actions from 'app/state/directActions';
import { PlanogramSelect } from 'app/widgets/ReferenceSelect';

import styles from './QuestionaryBindingScreen.module.css';

function handleClickBind({ tradepoint, planogramIds, required, onClose }) {
  console.log({
    tradepointId: tradepoint.id,
    planogramIds,
    required,
    bound: false,
  });
  actions.planogramTradepointRefs.toggleBindings({
    tradepointId: tradepoint.id,
    planogramIds,
    required,
    bound: false,
  });

  onClose();
}

export default
function BindingPlanogramDialog({ tradepoint, onClose }) {
  const { t } = useTranslation();
  const [planogramIds, setPlanogramIds] = React.useState(null);
  const [required, setRequired] = React.useState(false);
  const onClickBind = RRU.useCallbackOptions(handleClickBind, { tradepoint, planogramIds, required, onClose });
  const changed = planogramIds !== null;

  return (
    <Dialog
      icon="edit"
      title={t('pin planogram')}
      isOpen
      onClose={onClose}
      enforceFocus={false}
      canOutsideClickClose={!changed}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label={`${t('planogram')}:`} inline className={styles.fieldTitle}>
          <PlanogramSelect
            fill
            inline={false}
            value={planogramIds}
            onChange={setPlanogramIds}
            filters={{ contragent_id__eq: tradepoint.contragent_id }}
          />
        </FormGroup>
        <FormGroup label={`${t('required')}:`} inline className={styles.fieldTitle}>
          <Checkbox
            checked={required || false}
            onChange={() => setRequired(!required)}
          />
        </FormGroup>

        <p>{t('tradepoint')}: {tradepoint.network} {tradepoint.address} {tradepoint.code}</p>

        <p>{t('contractor')}: {tradepoint.contragent}</p>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text={t('cancel')} onClick={onClose} />

          <Button
            text={t('pin')}
            disabled={!planogramIds?.length}
            intent={Intent.PRIMARY}
            onClick={onClickBind}
          />
        </div>
      </div>
    </Dialog>
  );
}

BindingPlanogramDialog.propTypes = {
  tradepoint: PropTypes.shape({
    network: PropTypes.string,
    address: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    contragent_id: PropTypes.number.isRequired,
    contragent: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
