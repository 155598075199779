import i18n from 'i18n';
import store from 'app/state';
import * as api from 'app/api';
import { showError, showSuccess } from 'app/widgets/toaster';

export function openEditDialog() {
  const { filters } = store.getState().grids[api.RESCHEDULE_REASONS] ?? {};
  const { contragent_id__eq: contragent_id } = filters;
  const initial = { contragent_id, code: '', description: '' };

  store.setOne('rescheduleReasons.editDialog', {
    isOpen: true,
    form: initial,
  });
}

export function closeEditDialog() {
  store.setOne('rescheduleReasons.editDialog.isOpen', false);
}

export async function save() {
  const { form } = store.getState().rescheduleReasons.editDialog;
  store.setOne('rescheduleReasons.editDialog.fetching', true);
  try {
    await api.createRescheduleReason(form);
    showSuccess(i18n.t('categoryCreated'));
    return true;
  } catch (e) {
    showError(e.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne('rescheduleReasons.editDialog.fetching', false);
  }
};
