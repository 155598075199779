import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  H2,
  Card,
  Elevation,
  Button,
  Spinner,
  HTMLTable,
  Intent,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { fetchQuestionsAC } from 'app/state/actionCreators/restoredTaskEditor';
import AnswerControl, { getInvalid } from './AnswerControl';
import TaskPhotos from '../TaskPhotos';
import styles from './index.module.css';

function isConditionMet(question, questions) {
  if (!question.parent_id) {
    return true;
  }

  const parent = questions.find((q) => q.id === question.parent_id);
  if (_.isNil(parent?.result?.data)) {
    return false;
  }

  switch (parent.type) {
    case 'BOOLEAN':
      return (question.condition === 'Да') === parent.result.data;
    case 'SELECT':
      return question.condition === parent.result.data;
    case 'MULTICOMBO':
      return parent.result.data.includes(question.condition);
    default:
      return false;
  }
}

const getTaskInvalid = (hasShopProblem, questionaries) => {
  if (hasShopProblem) {
    // Выбрана проблема, можно сохранять невалидные ответы
    return false;
  }

  for (const questionary of questionaries) {
    for (const question of questionary.questions) {
      if (!isConditionMet(question, questionary.questions)) {
        continue
      }
      if (getInvalid(question.result, question.type)) {
        return true;
      }
    }
  }

  return false;
};

const groupByTitles = (data) => {
  const grouped = _.groupBy(data, (item) => {
    const title = item?.titles[0] ?? '';
    const subtitle = item?.titles[1] ?? '';
    const subsubtitle = item?.titles[2] ?? '';
    const swipesubtitle = item?.titles[3] ?? '';
    return `${title} ${subtitle} ${subsubtitle} ${swipesubtitle}`;
  });

  return Object.entries(grouped).map(([title, items]) => ({ title, items }));
};

const Question = ({
  questionaryId,
  question,
  onUploadStart,
  onUploadDone,
}) => (
  <tr className={styles.wordBreak}>
    <td>
      <div>{question.text}</div>
      <TaskPhotos
        questionaryId={questionaryId}
        questionId={question.id}
        photos={question.result.photos || []}
        onUploadStart={onUploadStart}
        onUploadDone={onUploadDone}
      />
    </td>
    <td className={styles.answerCell}>
      <AnswerControl
        questionaryId={questionaryId}
        question={question}
      />
    </td>
  </tr>
);

Question.propTypes = {
  questionaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    titles: PropTypes.arrayOf(PropTypes.string),
    result: PropTypes.shape({
      required: PropTypes.bool,
      data: PropTypes.any,
      variants: PropTypes.arrayOf(PropTypes.string),
      limits: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
      photos: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string,
        uploading: PropTypes.bool,
        needReupload: PropTypes.bool,
      })),
    }),
  }).isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
};

const QuestionGroup = ({
  questionaryId,
  title,
  questions,
  onUploadStart,
  onUploadDone,
  t,
}) => (
  <HTMLTable bordered className={styles.question}>
    <thead>
      <tr>
        <th>{title}</th>
        <th>{t('answer')}</th>
      </tr>
    </thead>
    <tbody>
      {questions.map((q) => (
        <Question
          key={q.id}
          questionaryId={questionaryId}
          question={q}
          onUploadStart={onUploadStart}
          onUploadDone={onUploadDone}
        />
      ))}
    </tbody>
  </HTMLTable>
);

QuestionGroup.propTypes = {
  questionaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    titles: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const Questionary = ({
  id,
  name,
  questions,
  onUploadStart,
  onUploadDone,
  t,
}) => {
  const filtered = questions.filter((q) => isConditionMet(q, questions));
  const groups = groupByTitles(filtered);
  return (
    <div>
      <H2>{name}</H2>
      {groups.map(({ title, items }) => (
        <QuestionGroup
          key={title}
          title={title}
          questionaryId={id}
          questions={items}
          onUploadStart={onUploadStart}
          onUploadDone={onUploadDone}
          t={t}
        />
      ))}
    </div>
  );
};

Questionary.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    titles: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  onUploadStart: PropTypes.func.isRequired,
  onUploadDone: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const QuestionsCard = ({
  isEditing,
  active,
  fetching,
  loaded,
  questionaryIds,
  questionaries,
  hasShopProblem,
  fetchQuestions,
  onBack,
  onSave,
}) => {
  const { t } = useTranslation();
  const [uploadingPhotos, setUploadingPhotos] = useState({});
  const isPhotosUploding = !!Object.values(uploadingPhotos).filter((x) => x).length;
  const invalid = getTaskInvalid(hasShopProblem, questionaries);
  const saveButtonTitle = (
    invalid
      ? t('fill required fields or select the problem')
      : undefined
  );
  useEffect(() => {
    if (!loaded && questionaryIds && questionaryIds.length) {
      fetchQuestions(questionaryIds);
    }
  }, [fetchQuestions, loaded, JSON.stringify(questionaryIds)]);

  return (
    <Card elevation={Elevation.TWO} className={active ? styles.active : styles.inactive}>
      <div className={styles.buttonGroup}>
        <Button
          intent={Intent.PRIMARY}
          text={t('back')}
          onClick={onBack}
          icon="arrow-left"
        />
        <div className={styles.spacer} />
        <Button
          disabled={invalid || isPhotosUploding || fetching || !loaded}
          title={saveButtonTitle}
          intent={Intent.PRIMARY}
          text={isEditing ? t('save') : t('create')}
          onClick={onSave}
          icon="floppy-disk"
        />
      </div>
      { (fetching || !loaded) ? <Spinner /> : (
        questionaries.map((q) => (
          <Questionary
            key={q.id}
            id={q.id}
            name={q.name}
            questions={q.questions}
            onUploadStart={() => setUploadingPhotos((prevUploadingPhotos) => ({
              ...prevUploadingPhotos,
              [q.id]: true,
            }))}
            onUploadDone={() => setUploadingPhotos((prevUploadingPhotos) => ({
              ...prevUploadingPhotos,
              [q.id]: false,
            }))}
            t={t}
          />
        ))
      ) }
      { (fetching || !loaded) ? null : (
        <div className={styles.buttonGroup}>
          <Button
            intent={Intent.PRIMARY}
            text={t('back')}
            onClick={onBack}
            icon="arrow-left"
          />
          <div className={styles.spacer} />
          <Button
            disabled={invalid || isPhotosUploding}
            title={saveButtonTitle}
            intent={Intent.PRIMARY}
            text={isEditing ? t('save') : t('create')}
            onClick={onSave}
            icon="floppy-disk"
          />
        </div>
      ) }
    </Card>
  );
};

QuestionsCard.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  questionaryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  questionaries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    questions: PropTypes.array,
  })).isRequired,
  hasShopProblem: PropTypes.bool.isRequired,
  fetchQuestions: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  questionaryIds: state.restoredTaskEditor.task.questionaryIds,
  fetching: state.restoredTaskEditor.questions.fetching,
  loaded: state.restoredTaskEditor.questions.loaded,
  error: state.restoredTaskEditor.questions.error,
  questionaries: state.restoredTaskEditor.questions.questionaries,
  hasShopProblem: !!state.restoredTaskEditor.task.shopProblem,
});

const mapDispatchToProps = (dispatch) => ({
  fetchQuestions: (questionaryIds) => dispatch(fetchQuestionsAC(questionaryIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsCard);
