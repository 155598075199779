import React from 'react';
import PropTypes from 'prop-types';
import PageSelect from 'app/widgets/PageSelect';
import styles from './TasksScreen.module.css';

const Pager = ({
  pagination,
  totalPages,
  setPagination,
}) => (
  <div className={styles.pageSelectorBlock}>
    <PageSelect
      currentPage={pagination.currentPage}
      maxPage={totalPages}
      onPageSelect={
        (currentPage) => { setPagination({ ...pagination, currentPage }); }
      }
    />
  </div>
);

Pager.propTypes = {
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
  }).isRequired,
  totalPages: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default Pager;
