import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { H2, H5, Card, Icon } from '@blueprintjs/core';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import Lightbox from 'app/widgets/Lightbox';

import styles from './TaskResult.module.css';

function makeLightboxImages(scene) {
  return scene.images.map(i => ({
    src: i.original_url,
    title: scene.name,
    description: scene.short_description,
  }));
}

function Scene({ scene, kpiValues }) {
  const images = RRU.useMemoArgs(makeLightboxImages, scene);
  const osaKpi = kpiValues.find(k => k.kpi_cid === 'CID_OSA' && (
    (k.category.includes('BEFORE') && scene.name.includes('ДО'))
    ||
    (k.category.includes('AFTER') && scene.name.includes('ПОСЛЕ'))
  ));

  return (
    <Card className={styles.inspectorCloudSceneCard} interactive elevation={2}>
      <H5 id={`scene${scene.id}`}><a href={`#scene${scene.id}`}>{scene.name}</a></H5>
      {osaKpi && <div>OSA = {osaKpi.details?.sku_osa_kpi?.value ?? 0}%</div>}

      <Lightbox
          thumbnailWidth="160px"
          thumbnailHeight="160px"
          images={images}
        />
    </Card>
  );
}

Scene.propTypes = {
  scene: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  kpiValues: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    kpi_cid: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

const SCENE_NAME_ORDER = [
  'мороженое до',
  'контроль остатков',
  'мороженое после',
  'фото хо фронери',
];

function sceneOrder(s) {
  return SCENE_NAME_ORDER.indexOf(s.name.toLowerCase());
}

export default
function IrInspectorCloudScenesTab({ result }) {
  const { t } = useTranslation();

  const scenes = _.sortBy(result.scenes, sceneOrder);
  return (
    <div className={styles.inspectorCloudTab}>
      <div className={styles.irTabHeader}>
        <H2>Inspector Cloud — {t('tasks')}</H2>

        <a
          target="_blank"
          rel="noreferrer"
          href={`https://froneri.inspector-cloud.com/portal/visit/${result.id}/`}
        >
          {t('icSiteReport')} <Icon icon="share" />
        </a>
      </div>

      {scenes.filter(s => !!s.images.length).map(s => (
        <Scene key={s.id} scene={s} kpiValues={result.kpi_values} />
      ))}
    </div>
  );
}

IrInspectorCloudScenesTab.propTypes = {
  result: PropTypes.shape({
    id: PropTypes.number.isRequired,
    scenes: PropTypes.arrayOf(PropTypes.shape({
      images: PropTypes.array.isRequired,
    }).isRequired).isRequired,
    kpi_values: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
};
