import React from 'react';
import PropTypes from 'prop-types';

import FancySelect from 'app/widgets/FancySelect';

import styles from './Filters.module.css';

const ReferenceFilter = React.memo(({ name, ...props }) => (
  <div className={styles.filtersGroup}>
    <div className={styles.filtersLabel}>
      { name }
    </div>
    <FancySelect fill {...props} />
  </div>
));

ReferenceFilter.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ReferenceFilter;
