import _ from 'lodash';

import i18n from 'i18n';
import store from 'app/state';
import { resolveAborting } from 'app/state/actionCreators';
import * as api from 'app/api';
import { showError } from 'app/widgets/toaster';

export async function fetchCount(path, { transientFilters, raw } = {}) {
  const { grids } = store.getState();
  const filters = _.omit(grids[path].filters, transientFilters ?? []);

  console.assert(raw);
  const requestFunction = api.getRawGridCount;

  store.setOne(`grids.${path}.fetchingTotalItems`, true);
  try {
    const { result, aborted } = await resolveAborting(
      requestFunction({ path, filters }),
      `grids.${path}`,
      store.dispatch,
      store.getState,
    );
    if (aborted) {
      return;
    }

    store.setOne(`grids.${path}.totalItems`, result);
  } catch (error) {
    showError(error.message || i18n.t('dataRetrievalError'));
  } finally {
    store.setOne(`grids.${path}.fetchingTotalItems`, false);
  }
}

export function toggleSelection(path, id) {
  const { grids } = store.getState();
  const { selected } = grids[path];
  const newSelected = (
    selected.includes(id)
      ? selected.filter(selectedId => selectedId !== id)
      : [...selected, id]
  );
  store.setOne(`grids.${path}.selected`, newSelected);
}

export function toggleSelectionAll(path) {
  const { grids } = store.getState();
  const { selected, data } = grids[path];
  const allSelected = selected.length === data.length;
  const newSelected = allSelected ? [] : data.map(item => item.id);
  store.setOne(`grids.${path}.selected`, newSelected);
}
