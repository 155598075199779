import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import _ from 'lodash';

import { canViewAgencies } from 'app/state/reducers';
import { useSelectorMemo } from 'app/utils';
import * as api from 'app/api';
import { MERCHANDISING_TYPES, CHECKED_EQUIPMENT_TYPES } from 'app/config';

import ReferenceFilter from './ReferenceFilter';
import IdFilter from './IdFilter';
import ComboFilter from './ComboFilter';

const makeFilter = (path, entityName, dativeCaseEntityName) => React.memo(
  withTranslation()(({ t, ...otherProps}) => (
    <ReferenceFilter
      path={path}
      name={t(entityName)}
      placeholder={`${t('search by')} ${t(dativeCaseEntityName)}`}
      {...otherProps}
    />
  ),
));

export const TradeNetworkFilter = makeFilter(api.TRADE_NETWORKS_REFERENCES,'network', 'dativeNetwork');
export const TradepointProjectFilter = makeFilter(
  api.TRADEPOINT_PROJECTS_REFERENCES,
  'extra project',
  'dativeExtra project',
);
export const TradepointFormatFilter = makeFilter(api.TRADEPOINT_FORMATS_REFERENCES, 'format', 'dativeFormat');
export const TradepointTerritoryFilter = makeFilter(
  api.TRADEPOINT_TERRITORIES_REFERENCES,
  'territory',
  'dativeTerritory',
);
export const BranchFilter = makeFilter(api.BRANCHES_REFERENCES, 'affiliate', 'dativeAffiliate');
export const ContragentFilter = makeFilter(api.CONTRAGENTS_REFERENCES, 'contractor', 'dativeContractor');
export const UserTaskCheckQuestionFilter = makeFilter(
  api.USER_TASK_CHECKS_QUESTIONS_REFERENCES,
  'question',
  'dativeQuestion',
);
export const AgencyFilter = makeFilter(api.AGENCIES_REFERENCES, 'agency', 'dativeAgency');
export const QuestionaryFilter = makeFilter(api.QUESTIONARIES_REFERENCES, 'questionnaire', 'dativeQuestionnaire');
export const QuestionaryCategoryFilter = makeFilter(
  api.QUESTIONARY_CATEGORIES_REFERENCES,
  'questionaryCategory',
  'dativeCategory',
);
export const UserFilter = makeFilter(api.USERS_REFERENCES, 'user', 'dativeUser');
export const QuestionaryTitleFilter = makeFilter(api.QUESTIONARY_TITLES_REFERENCES, 'subtitle', 'dativeSubtitle');
export const QuestionFilter = makeFilter(api.QUESTIONS_REFERENCES, 'question', 'dativeQuestion');
export const QuestionVariantFilter = makeFilter(
  api.QUESTION_VARIANTS_REFERENCES,
  'answerVariant',
  'dativeAnswerVariant',
);
export const EventsFilter = makeFilter(api.EVENTS, 'eventType', 'dativeEvent');
export const PlanogrammFilter = makeFilter(api.PLANOGRAM_REFERENCES, 'planogram', 'dativePlanogram');

export const RoleFilter = React.memo(withTranslation()(
  ({ t, ...props }) => (
    <ReferenceFilter
      path={api.ROLES_REFERENCES}
      name={t('role')}
      placeholder={t('search by role')}
      {...props}
    />
  ),
));

export const UserTaskIdFilter = React.memo(withTranslation()(
  ({ t, ...props }) => (
    <IdFilter
      name={t('num of visit')}
      placeholder={t('search by num of visit')}
      maxLength={9}
      {...props}
    />
  ),
));

export const BoolFilter = React.memo(withTranslation()(
  ({ t, trueTitle, falseTitle, ...props }) => (
    <ComboFilter
      emptiable
      items={[
        { title: t(trueTitle), key: 'true', value: true },
        { title: t(falseTitle), key: 'false', value: false },
      ]}
      {...props}
    />
  ),
));

BoolFilter.propTypes = {
  trueTitle: PropTypes.string,
  falseTitle: PropTypes.string,
};

BoolFilter.defaultProps = {
  trueTitle: 'yes',
  falseTitle: 'no',
};

export const MerchandisingFilter = React.memo(withTranslation()(
  ({ t, ...props }) => (
    <ComboFilter
      emptiable
      items={
        MERCHANDISING_TYPES.map((type) => ({
          ...type,
          title: t(type.title),
        }))}
      {...props}
    />
  ),
));

export function CheckedEquipmentFilter(props) {
  const { t } = useTranslation();
  const items = React.useMemo(
    () => _.toPairs(CHECKED_EQUIPMENT_TYPES).map(([value, title]) => ({ value, title: t(title) })),
    [t],
  );

  return (
    <ComboFilter
      name={t('numCheckedEquipment')}
      emptiable
      items={items}
      {...props}
    />
  );
}

export const AgencyContragentFilter = React.memo((props) => {
  const showAgencyFilter = useSelectorMemo(canViewAgencies);
  const [agencyId, setAgencyId] = useState(null);

  return (
    <>
      {showAgencyFilter && (
        <AgencyFilter
          value={agencyId}
          onChange={setAgencyId}
          autoSelectFirst
          multi={false}
        />
      )}
      <ContragentFilter
        autoSelectFirst
        {...props}
        filters={{ agency_id__eq: agencyId }}
        fetchDisabled={showAgencyFilter && !agencyId}
      />
    </>
  );
});

AgencyContragentFilter.defaultProps = {
  multi: false,
};
