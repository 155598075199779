import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  H2,
  Card,
  Elevation,
  Button,
  Intent,
  Position,
  Spinner,
  Classes,
  Icon,
  Toaster,
  Callout,
} from '@blueprintjs/core';

import Page from 'app/widgets/Page';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import ComboFilter from 'app/widgets/Filters/ComboFilter';

import { getContragents, getContragentsFetching } from 'app/state/reducers';
import { transformContagentsByCode as transformContagents } from 'app/state/transform/contragentsSelect';
import { fetchContragents as fetchContragentsAC } from 'app/state/actionCreators/contragents';
import {
  downloadReport as downloadReportAC,
  setFilters as setFiltersAC,
} from 'app/state/actionCreators/reports/diageo';
import styles from './ReportDiageoScreen.module.css';

const FILE_TYPES = ['txt', 'xlsx'].map((x) => ({
  key: x,
  value: x,
  title: x,
}));

const ReportDiageoScreen = ({
  filters,
  fetching,
  contragents,
  contragentsFetching,
  fetchContragents,
  downloadReport,
  setFilters,
  t,
}) => {
  useEffect(() => { fetchContragents(); }, [fetchContragents]);
  const onContragentChange = (val) => setFilters({
    ...filters,
    contragent: val,
  });
  const onReportTypeChange = (val) => setFilters({
    ...filters,
    report_type: val,
  });
  useEffect(() => {
    if (contragents && contragents.length) {
      const contragent = contragents[0];
      onContragentChange(contragent.value);
    }
  }, [contragents && contragents.length]);

  const downloadDisabled = (
    fetching
    || contragentsFetching
    || !filters.from_date
    || !filters.to_date
    || !filters.report_type
  );

  const onDateChange = (val) => setFilters({
    ...filters,
    from_date: val[0],
    to_date: val[1],
  });
  const toaster = React.useRef(null);

  const onError = (error) => {
    let message = '';
    if (error && error.response && error.response.status === 404) {
      message = t('no records found to generate report');
    } else if (error && error.response && error.response.status === 500) {
      message = t('report generation error');
    } else if (error.message) {
      message = error.message;
    } else {
      message = t('an error has occurred');
    }
    toaster.current.show({ intent: Intent.DANGER, message, icon: 'error' });
  };

  return (
    <Page>
      <div className={styles.header}>
        <H2>{t('report')} Diageo</H2>
      </div>
      <Card elevation={Elevation.TWO} className={styles.panel}>
        <div className={styles.filters}>
          <DaterangeFilter
            name={t('report dates')}
            value={[filters.from_date, filters.to_date]}
            onChange={onDateChange}
            shortcuts="months"
          />
          <ComboFilter
            name={t('type of file')}
            value={filters.report_type}
            onChange={onReportTypeChange}
            items={FILE_TYPES}
          />
          <Button
            onClick={() => { downloadReport(filters, onError); }}
            minimal
            disabled={downloadDisabled}
            icon={fetching ? <Spinner className={Classes.BUTTON_SPINNER} size={Icon.SIZE_LARGE} /> : 'download'}
            text={fetching ? t('report generating') : t('download')}
            intent={Intent.SUCCESS}
            className={styles.downloadButtom}
          />
          <div className={styles.clearfix} />
        </div>
        {
          !filters.from_date
          && (
            <Callout intent={Intent.PRIMARY} className={styles.message}>
              {t('select the date of report and type of file')}..
            </Callout>
          )
        }
      </Card>
      <Toaster ref={toaster} position={Position.BOTTOM} />
    </Page>
  );
};

ReportDiageoScreen.propTypes = {
  filters: PropTypes.shape({
    report_type: PropTypes.string,
    from_date: PropTypes.instanceOf(Date),
    to_date: PropTypes.instanceOf(Date),
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
  contragents: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  contragentsFetching: PropTypes.bool.isRequired,
  fetchContragents: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.reports.diageo.filters,
  fetching: state.reports.diageo.fetching,
  contragents: transformContagents(getContragents(state)),
  contragentsFetching: getContragentsFetching(state),
});

const mapDispatchToProps = (dispatch) => ({
  downloadReport: (filters, onError) => dispatch(downloadReportAC(filters, onError)),
  setFilters: (filters) => dispatch(setFiltersAC(filters)),
  fetchContragents: () => dispatch(fetchContragentsAC()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportDiageoScreen));
