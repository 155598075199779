import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { H2 } from '@blueprintjs/core';

import { fetchEvents, setFilters, clearFilters, setPagination, fetchTradepoint, exportData }
  from 'app/state/actionCreators/events';
import { hasRight } from 'app/state/reducers';
import { dispatch } from 'app/state';
import { useCallbackWithDeps, useDispatchCallback, useSelectorMemo, shallowEqual } from 'app/utils';
import NewEventDialog from 'app/widgets/NewEventDialog';
import EventList from 'app/widgets/EventList';
import Page from 'app/widgets/Page';

import ActionBar from './ActionBar';
import styles from './EventsScreen.module.css';

function openDialog({ setNewEvent, tradepoint }) {
  if (!tradepoint) {
    return;
  }

  setNewEvent({
    contragent_id: tradepoint.contragent_id,
    tradepoint_id: tradepoint.id,
  });
}

function handleDialogClose({ setNewEvent, filters, pagination }, created) {
  setNewEvent(null);
  if (created) {
    dispatch(fetchEvents(filters, pagination));
  }
}

export default
function NewTradepointEventsScreen() {
  const { t } = useTranslation();
  const id = Number(useParams().id);
  const {
    events,
    totalItems,
    totalPages,
    pagination,
    filters,
    fetching,
    error,
    tradepoint,
    tradepointFetching,
    tradepointError,
    exportFetching,
  } = useSelectorMemo((state) => state.events, shallowEqual);
  const hideCreatePhotoTime = useSelectorMemo((state) => hasRight(state, 'webapp_contragent_hide_create_photo_time'));

  useEffect(() => {
    dispatch(clearFilters());
    dispatch(fetchTradepoint(id));
  }, []);
  useEffect(() => {
    if (filters.tradepoint_id__eq) {
      dispatch(fetchEvents(filters, pagination));
    }
  }, [filters, pagination]);
  useEffect(() => {
    dispatch(setFilters({
      tradepoint_id__eq: id,
      status__eq: 'new',
    }));
    return () => dispatch(clearFilters());
  }, [id]);

  const [newEvent, setNewEvent] = useState(null);
  const onOpenDialog = useCallbackWithDeps(openDialog, { tradepoint, setNewEvent });
  const onCloseDialog = useCallbackWithDeps(handleDialogClose, { setNewEvent, filters, pagination });
  const onExportData = useDispatchCallback(() => exportData(filters), [filters]);

  return (
    <Page>
      <div className={styles.header}>
        <H2>
          {t('newEventsInShop')}
          {tradepoint && ` ${tradepoint.network || tradepoint.name || ''} - ${tradepoint.address || ''}`}
        </H2>
      </div>
      <ActionBar
        noFilters
        hasFilters={false}
        filtersVisible={false}
        pagination={pagination}
        totalPages={totalPages}
        totalItems={totalItems}
        setPagination={setPagination}
        setFilterVisible={() => {}}
        onOpenDialog={onOpenDialog}
        exportFetching={exportFetching}
        onExportClick={onExportData}
      />
      <EventList
        events={events}
        fetching={fetching || tradepointFetching}
        error={error || tradepointError}
        onCopyEvent={() => {}}
        hideCreatePhotoTime={hideCreatePhotoTime}
      />
      {newEvent && <NewEventDialog initialData={newEvent} onClose={onCloseDialog} />}
    </Page>
  );
}
