
import { makeEmptyReducer } from '../utils';

const INITIAL_STATE = {
  filters: {
    date__between: [new Date(), new Date()],
  },
  filtersValid: false,
  fetching: false,
};

export default makeEmptyReducer(INITIAL_STATE);
