import React from 'react';
import PropTypes from 'prop-types';
import IrGeneral from './IrGeneral';
import IrPhotos from './IrPhotos';
import IrAssortment from './IrAssortment';

const IrTab = ({ task, irPhotoDescription, photos }) => (
  <>
    <IrGeneral task={task} />
    <IrPhotos photos={photos} description={irPhotoDescription} />
    <IrAssortment assortment={task.ir_result.result.report.assortment_achievement} />
  </>
);

IrTab.propTypes = {
  task: PropTypes.shape({
    ir_result: PropTypes.shape({
      result: PropTypes.shape({
        report: PropTypes.shape({
          assortment_achievement: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }),
  }).isRequired,
  irPhotoDescription: PropTypes.object.isRequired,
  photos: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default IrTab;
