import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Page from 'app/widgets/Page';
import {canEditProductCategories as getCanEditProductCategories} from 'app/state/reducers';
import SingleColumnReference from 'app/widgets/SingleColumnReference';
import RecordModal from './RecordModal';

export default React.memo(() => {
  const { t } = useTranslation();
  const canEdit = useSelector(getCanEditProductCategories);

  return (
    <Page title={t('product categories')}>
      <SingleColumnReference canEdit={canEdit} path="/product-categories" modal={RecordModal} />
    </Page>
  );
});
