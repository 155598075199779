import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'app/widgets/Lightbox';
import EditPhoto from './EditPhoto';
import UploadPhoto from './UploadPhoto';

const SinglePhoto = ({
  photo,
  removePhoto,
  uploadPhoto,
  getSrc,
  getPreview,
}) => (
  photo.uuid ? (
    <Lightbox
      thumbnailWidth="160px"
      thumbnailHeight="160px"
      images={[{
        src: getSrc(photo),
        thumbnail: getPreview(photo),
        opts: photo,
      }]}
      renderDescriptionFunc={() => null}
      renderImageFunc={(idx, image, toggleLightbox) => (
        <EditPhoto
          key={image.opts.uuid}
          uuid={image.opts.uuid}
          fetching={image.opts.uploading}
          onPreview={() => toggleLightbox(idx)}
          onDelete={() => removePhoto(image.opts.uuid)}
          onSelect={uploadPhoto}
          thumbnail={getPreview(image.opts)}
        />
      )}
    />
  ) : (
    <UploadPhoto
      disabled={photo.uploading}
      onUpload={uploadPhoto}
    />
  )
);
/* eslint-enable react/jsx-no-bind */

SinglePhoto.propTypes = {
  photo: PropTypes.shape({
    uuid: PropTypes.string,
    uploading: PropTypes.bool,
  }).isRequired,
  removePhoto: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
  getSrc: PropTypes.func.isRequired,
  getPreview: PropTypes.func.isRequired,
};

export default SinglePhoto;
