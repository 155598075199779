import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { H2, H5, HTMLTable, NonIdealState, Spinner, Tab, Tabs, Tag, Icon, Intent } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import Lightbox from 'app/widgets/Lightbox';
import { dispatch } from 'app/state';
import {
  getTask,
  getTaskError,
  getTaskIsFetching,
  needHideVisitUsers,
  needHideVisitMerch,
  canRestoredTasksMark as getCanRestoredTasksMark,
  canViewFakeGPS as getCanViewFakeGPS,
  canViewFakePhoto as getCanViewFakePhoto,
  hasRight,
} from 'app/state/reducers';
import { fetchTask, clearTask } from 'app/state/actionCreators/task';
import { BASE_URL, PLANOGRAM_FILES } from 'app/api';
import * as utils from 'app/utils';
import { formatDistance } from 'app/utils/distance';
import { VISIBILITY_RESTRICTIONS } from 'app/config';
import { taskType } from 'app/proptyping';
import NoCamera from 'app/assets/no-camera.svg';

import Photos from './Photos';
import TaskMap from './TaskMap';
import EquipmentTab from './EquipmentTab';
import IrTab from './IrTab';
import IrInspectorCloudProductsTab from './IrInspectorCloudProductsTab';
import IrInspectorCloudScenesTab from './IrInspectorCloudScenesTab';
import EventsTab from './EventsTab';
import styles from './TaskResult.module.css';

export const PRODUCT_CHECK2LOCALE_KEY = {
  VIRTUAL: 'virtual balance',
  EXPIRED: 'expired goods',
};

export const OSA_VARIANT2LOCALE_KEY = {
  OSA_CONFIRMED: 'productMissingFromShelf',
  OSA_NOT_CONFIRMED: 'productPresentOnShelf',
};

function renderJtiTask({ id, userTask, title, icon }) {
  const task = userTask.jti_service_info[id];

  return (
    <Tab id={id} disabled={!task} className={styles.jtiTaskLink}>
      <Icon icon={icon} />
      <div className={styles.jtiTaskText}>
        <span>{title}</span>
        <span>{task ? utils.toLocaleDateString(task.date) : '-'}</span>
      </div>
    </Tab>
  );
}

class TaskResult extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 'general',
    };
  }

  componentDidMount() {
    const { id } = this.props;
    dispatch(fetchTask(id));
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) {
      dispatch(fetchTask(id));
      this.setState({ selectedTabId: 'general' });
    }
  }

  componentWillUnmount() {
    dispatch(clearTask());
  }

  matchQuestionWithResults = (task) => {
    const { questions } = task.data;
    const results = task.result.questions || [];

    const resultById = Object.fromEntries(results.map((r) => [r.id, r]));

    const groupedByQuestionaryName = _.groupBy(
      questions.map((q) => ({
        question: q,
        result: resultById[q.id] ?? null,
      })),
      'question.name',
    );

    return Object.entries(groupedByQuestionaryName).map(([name, data]) => ({ name, data }));
  };

  matchProducts = (task) => {
    const { t } = this.props;
    // Группируем алерты продуктов для отображения
    const { products } = task.data;
    const { groups, products: results } = task.result;

    if (!products || !products.length || !results) {
      return null;
    }

    const groupedProducts = {};
    for (const product of products) {
      const group = product.info?.group ?? t('without group');
      if (!groupedProducts[group]) {
        groupedProducts[group] = {
          items: [],
        };
      }

      const result = results.find((r) => r.id === product.id);

      const checks = Object.fromEntries((product.checks ?? []).map((check) => {
        const checksResult = result?.checks.find((checkRes) => checkRes.code === check.code);
        let resultValue;
        if (checksResult && check.code === 'OSA') {
          resultValue = t(OSA_VARIANT2LOCALE_KEY[checksResult.data.code]);
        } else if (checksResult) {
          resultValue = checksResult.data.value;
        }
        const title = t(PRODUCT_CHECK2LOCALE_KEY[check.code])
        return [check.code, {
          title,
          check,
          resultValue,
        }];
      }));

      groupedProducts[group].items.push({
        product,
        result,
        checks,
      });
    }

    for (const g of groups) {
      groupedProducts[g.name].details = g;
    }

    return groupedProducts;
  };

  renderProblem = (problem, balance) => {
    let value = problem.description ?? problem.code;
    if (problem.code === 'VIRTUAL') {
      value = `${value}: ${balance + problem.data}`;
    }

    return (
      <Tag
        round
        rightIcon={<Icon icon="warning-sign" iconSize={10} />}
        intent={Intent.DANGER}
      >
        {value}
      </Tag>
    );
  };

  groupByTitles = (data) => {
    const grouped = _.groupBy(data, (item) => (
      _.filter([
        item.question.title,
        item.question.subtitle,
        item.question.subsubtitle,
        item.question.swipesubtitle,
      ]).join(' | ')
    ));

    return Object.entries(grouped).map(([title, items]) => ({ title, items }));
  };

  findPhoto = (photos, id) => photos.find((p) => p.id === id);

  handleChangeTab = (newTabId, prevTabId, e) => {
    const { task } = this.props;
    const jtiTask = task.jti_service_info[newTabId];
    if (jtiTask) {
      utils.history.push(`/tasks/${jtiTask.id}/result`);
    } else {
      this.setState({ selectedTabId: newTabId })
    }
  };

  renderProduct = ({ product, result, checks }) => {
    const { t } = this.props;
    const req = t('requested');
    const notReq = t('not requested');
    const hasPhotos = !!(result && result.photos && result.photos.length);

    const problem = result?.problems[0];

    return (
      <HTMLTable key={product.id} className={styles.productTable} bordered>
        <tbody>
          <tr>
            <td className={styles.productTableLeft}>{t('denomination')}</td>
            <td>{product.info.name}</td>
          </tr>
          <tr>
            <td className={styles.productTableLeft}>{t('product code')}</td>
            <td>{product.id}</td>
          </tr>

          {checks.OSA && (
            <>
              <tr>
                <td className={styles.productTableLeft}>{t('check')}: OSA</td>
                <td>{checks.OSA.check.required ? req : notReq}</td>
              </tr>
              <tr>
                <td className={styles.productTableLeft}>{t('result')}: OSA</td>
                <td>{checks.OSA.resultValue}</td>
              </tr>
            </>
          )}

          <tr>
            <td className={styles.productTableLeft}>{t('accounting balance')}</td>
            <td>{product.info.balance}</td>
          </tr>

          {checks.VIRTUAL && (
            <>
              <tr>
                <td className={styles.productTableLeft}>{t('check')}: {t('virtual balance')}</td>
                <td>{checks.VIRTUAL.check.required ? req : notReq}</td>
              </tr>
              <tr>
                <td className={styles.productTableLeft}>{t('result')}: {t('virtual balance')}</td>
                <td>
                  {
                    (checks.VIRTUAL.resultValue || checks.VIRTUAL.resultValue === 0)
                    && (product.info.balance + checks.VIRTUAL.resultValue)
                  }
                </td>
              </tr>
            </>
          )}
          {checks.EXPIRED && (
            <>
              <tr>
                <td className={styles.productTableLeft}>{t('check')}: {t('expired goods')}</td>
                <td>{checks.EXPIRED.check.required ? req : notReq}</td>
              </tr>
              <tr>
                <td className={styles.productTableLeft}>{t('result')}: {t('expired goods')}</td>
                <td>{checks.EXPIRED.resultValue}</td>
              </tr>
            </>
          )}

          {problem && (
            <tr>
              <td className={styles.productTableLeft}>{t('problem')}</td>
              <td>{this.renderProblem(problem, product.info.balance)}</td>
            </tr>
          )}

          {hasPhotos && (
            <tr>
              <td colSpan={2}>
                <Photos uuids={result.photos} />
              </td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
    );
  };

  renderGroupPhotos = (photos, isBefore) => {
    const { t } = this.props;
    if (!photos || !photos.length) {
      return undefined;
    }
    return (
      <div>
        <H5 className={styles.productGroupPhotoLable}>{isBefore ? `${t('photo before')}:` : `${t('photo after')}:`}</H5>
        <Photos uuids={photos} />
      </div>
    );
  };

  renderProductsGroup = ({ details, items }) => {
    const { t } = this.props;
    return (
      <div className={styles.productGroup} key={items[0].product.info.group}>
        <H2>{items[0].product.info.group}</H2>
        {items.map(this.renderProduct)}
        {this.renderGroupPhotos(details?.photosBefore, true)}
        {this.renderGroupPhotos(details?.photosAfter, false)}
        <div className={styles.productGroupComment}>
          <b>{t('comment by group')}: </b>
          <span>{details?.comment || '-'}</span>
        </div>
      </div>
    );
  };

  splitIrPhotos() {
    const { task } = this.props;
    if (!task.ir_result) {
      return {};
    }

    const irShelf = [];
    const irAfter = [];
    const irCheckout = [];
    const ir = [];
    const irBefore = [];
    for (const photo of _.values(task.ir_result.result.report.photos)) {
      let taskIds;
      if (photo.task_id?.startsWith('[')) {
        taskIds = photo.task_id.slice(1, photo.task_id.length - 1).split(', ');
      } else {
        taskIds = [photo.task_id];
      }

      let matched = false;
      if (task.ir_photo_flag === 'before' && taskIds.includes('5ebe63902279080faaf58da1')) {
        irShelf.push(photo);
        matched = true;
      }

      if (task.ir_photo_flag === 'after' && taskIds.includes('24')) {
        irAfter.push(photo);
        matched = true;
      }

      if (task.ir_checkout_task_ids.some((id) => taskIds.includes(id))) {
        irCheckout.push(photo);
        matched = true;
      }

      if (!photo.task_id && task.ir_photo_flag === 'none') {
        ir.push(photo);
        matched = true;
      }

      if (!photo.task_id && task.ir_photo_flag === 'after') {
        irAfter.push(photo);
        matched = true;
      }

      if (!photo.task_id && task.ir_photo_flag === 'before') {
        irBefore.push(photo);
        matched = true;
      }

      if (!matched) {
        ir.push(photo);
      }
    }

    return {
      irShelf,
      irAfter,
      irCheckout,
      ir,
      irBefore,
    };
  }

  renderProducts(productsByGroup) {
    return Object.values(productsByGroup).map(this.renderProductsGroup);
  }

  renderGeolocationInfo() {
    const { task, canViewMap } = this.props;
    const { startLocation, endLocation, data } = task;
    const geoExists = startLocation && endLocation
      && startLocation.latitude && startLocation.longitude
      && endLocation.latitude && endLocation.longitude;

    if (canViewMap && geoExists) {
      return (
        <TaskMap
          startLocation={startLocation}
          endLocation={endLocation}
          taskLocation={data.shop.info.geo}
        />
      );
    }
    return null;
  }

  renderGeneralInfo() {
    const {
      task,
      showRestoredTasksMark,
      canRestoredTasksMark,
      canViewFakeGPS,
      canViewFakePhoto,
      hideVisitMerch,
      showParentUser,
      hideWorkTime,
      hideStartEndDate,
      hideRemoteFinished,
      hideCreatePhotoTime,
      t,
    } = this.props;

    const unknown = t('unknown');
    const yes = t('yes');
    const no = t('no');
    const showVisibilityRestrictions = task.type === 'merchandising';
    const visibilityRestrictions = _.uniq(task.data.questions.map((q) => q.questVisibilityRestriction));

    const showFakeGPS = (
      canViewFakeGPS && (!!task.startLocation?.isFake || !!task.endLocation?.isFake)
    );
    const showFakePhotos = canViewFakePhoto && task.hasFakePhotos;

    const firstPhotoTime = _.minBy(
      task.photos.filter((p) => p.moment !== 'before'),
      'create_photo_time',
    )?.create_photo_time;
    const lastPhotoTime = _.maxBy(
      task.photos,
      'create_photo_time',
    )?.create_photo_time;

    return (
      <div>
        <HTMLTable className={styles.generalInfoTable}>
          <tbody>
            {showRestoredTasksMark && canRestoredTasksMark && task.restored && (
              <tr>
                <td className={styles.restoredInfo} colSpan={2}>
                  <Icon icon="snowflake" intent={Intent.PRIMARY} />
                  {' '}
                  {t('restored visit')}
                </td>
              </tr>
            )}
            <tr>
              <td>{t('num of visit')}</td>
              <td className={styles.value}>
                {task.id}
              </td>
            </tr>
            {!hideVisitMerch && (
              <tr>
                <td>{t('sent')}:</td>
                <td className={styles.value}>
                  {showParentUser ? task.supervisorName : task.userName}
                  {' ('}
                  {showParentUser ? task.supervisorPhone : task.userPhone}
                  {')'}
                </td>
              </tr>
            )}
            {task.result.device && (
              <tr>
                <td>{t('device')}:</td>
                <td className={styles.value}>{task.result.device.brand_model}</td>
              </tr>
            )}
            {task.result.device && (
              <tr>
                <td>{t('device id')}:</td>
                <td className={styles.value}>{task.result.device.id}</td>
              </tr>
            )}
            <tr>
              <td>{t('point code')}</td>
              <td className={styles.value}>
                {task.data.shop?.info.code || unknown}
              </td>
            </tr>
            <tr>
              <td>{t('address')}</td>
              <td className={styles.value}>
                {task.data.shop?.info.address || unknown}
              </td>
            </tr>
            <tr>
              <td>{`${t('network')} (${t('lowercase signboard')})`}</td>
              <td className={styles.value}>
                {task.data.shop?.info.network || unknown}
              </td>
            </tr>
            <tr>
              <td>{t('name of tt')}</td>
              <td className={styles.value}>
                {task.data.shop?.info.name || unknown}
              </td>
            </tr>
            <tr>
              <td>{t('category and type of tt')}</td>
              <td className={styles.value}>
                {task.data.shop?.info.format || unknown}
              </td>
            </tr>
            <tr>
              <td>{t('territory')}</td>
              <td className={styles.value}>
                {task.data.shop?.info.territory || unknown}
              </td>
            </tr>
            {showVisibilityRestrictions && visibilityRestrictions.length > 0 && (
              <tr>
                <td>{t('visibility')}</td>
                <td className={styles.value}>
                  {visibilityRestrictions.map((r) => t(VISIBILITY_RESTRICTIONS[r])).join('; ')}
                </td>
              </tr>
            )}
            {!hideWorkTime && (
              <tr>
                <td>{t('time in tt fact plan')}</td>
                <td className={styles.value}>
                  {utils.formatIntervalBetween(task.startDate, task.endDate)}
                  {task
                    ? ` / ${utils.formatMinutesToHours(task.planLeadTime || 0)}`
                    : ` / ${unknown}`}
                </td>
              </tr>
            )}
            {!hideStartEndDate && (
              <tr>
                <td>{t('start time of job')}</td>
                <td className={styles.value}>
                  {task.startDate.toLocaleString()}
                </td>
              </tr>
            )}
            {!hideStartEndDate && (
              <tr>
                <td>{t('end time of job')}</td>
                <td className={styles.value}>
                  {task.endDate.toLocaleString()}
                </td>
              </tr>
            )}
            {!hideCreatePhotoTime && firstPhotoTime && (
              <tr>
                <td>{t('first photo')}</td>
                <td className={styles.value}>{utils.toLocaleString(firstPhotoTime)}</td>
              </tr>
            )}
            {!hideCreatePhotoTime && lastPhotoTime && (
              <tr>
                <td>{t('last photo')}</td>
                <td className={styles.value}>{utils.toLocaleString(lastPhotoTime)}</td>
              </tr>
            )}

            <tr>
              <td>{t('date of visit')}</td>
              <td className={styles.value}>
                {task.data.task_date}
              </td>
            </tr>
            <tr>
              <td>{t('problem with tt')}</td>
              <td className={styles.value}>
                {task.problem ?? no}
              </td>
            </tr>
            <tr>
              <td>{t('comment for tt 2')}</td>
              <td className={styles.value}>
                {(task.result.shop && task.result.shop.comment) || no}
              </td>
            </tr>

            {!hideRemoteFinished && (
              <tr>
                <td>{t('ended in tt')}:</td>
                <td className={styles.value}>
                  {task.remoteFinished ? `${no} (${formatDistance(task.endLocation.distance)})` : yes}
                </td>
              </tr>
            )}
            {showFakeGPS && (
              <tr>
                <td>{t('used fake gps')}</td>
                <td className={styles.value}>
                  <Icon icon="error" intent={Intent.WARNING} />
                </td>
              </tr>
            )}
            {showFakePhotos && (
              <tr>
                <td>{t('fake photos')}</td>
                <td className={styles.value}>
                  <Icon icon="error" intent={Intent.WARNING} />
                </td>
              </tr>
            )}
          </tbody>
        </HTMLTable>
        {this.renderGeolocationInfo()}
      </div>
    );
  }

  renderPhotos() {
    const { task, t } = this.props;

    if (task.photos.length === 0) {
      return (
        <NonIdealState
          title={t('no photo')}
          description={t('user didnt make photos')}
          icon="info-sign"
        />
      );
    }

    return <Photos />;
  }

  renderQuestionary(questionary) {
    const groups = this.groupByTitles(questionary.data);
    return (
      <div className={styles.questionaryResult}>
        {groups.map((group) => this.renderQuestionsGroup(group))}
      </div>
    );
  }

  renderQuestionsGroup({ title, items }) {
    const { t } = this.props;
    return (
      <HTMLTable key={title} className={styles.questionsTable} bordered>
        <thead>
          <tr>
            <th className={styles.questionTitle}>
              {title}
            </th>
            <th className={styles.questionResultTitle}>
              {t('answer')}
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => this.renderQuestion(item))}
        </tbody>
      </HTMLTable>
    );
  }

  renderQuestion({ question, result }) {
    const { t } = this.props;
    let resultText = t('without answer');

    const data = result?.data ?? question.defaultAnswer;
    if (!_.isNil(data)) {
      switch (question.result.type) {
        case 'BOOLEAN':
          resultText = data ? t('yes') : t('no');
          break;
        case 'MULTICOMBO':
          resultText = data.join(', ');
          break;
        case 'SUBTRACTION':
        case 'ADDITION':
        case 'DIVISION':
        case 'MULTIPLICATION':
          resultText = data.result;
          break;
        default:
          resultText = data;
      }
    }

    return (
      <tr key={question.id}>
        <td className={styles.questionText}>
          {question.text}
          {!!result?.photos.length && <Photos uuids={result.photos} />}
        </td>
        <td className={styles.questionResultText}>
          {resultText}
        </td>
      </tr>
    );
  }

  renderPlanogramImage = (idx, image, toggleLightbox, height, width) => (
    <input
      key={idx}
      srcSet={`${image.src}, ${NoCamera}`}
      className="lightbox-img-thumbnail"
      style={{ width, height }}
      alt={image.title}
      onClick={toggleLightbox.bind(null, idx)}
    />
  );

  renderPlanograms() {
    const { task } = this.props;

    return task.planograms.map((planogram) => (
      <div className={styles.planogram} key={planogram.id}>
        <H5>{planogram.name}</H5>
        {planogram.text}
        <Lightbox
          thumbnailWidth="160px"
          thumbnailHeight="160px"
          images={_.map(planogram.images, (uuid) => ({
            src: `${BASE_URL}${PLANOGRAM_FILES}/${uuid}`,
            title: planogram.name,
            description: planogram.text,
          }))}
        />
      </div>
    ));
  }

  render() {
    const { fetching, error, task, hasEvents, eventsFetching, t } = this.props;
    const { selectedTabId } = this.state;

    if (fetching) {
      return <Spinner />;
    }
    if (error) {
      return <NonIdealState title={t('error')} description={error.message} icon="error" />;
    }
    if (!task) {
      return null;
    }

    const {
      irShelf,
      irAfter,
      irCheckout,
      ir,
      irBefore,
    } = this.splitIrPhotos();
    const irPhotoDescription = {
      address: task.data.shop.info.address,
      network: task.data.shop.info.network,
      date: task.startDate.toLocaleString(),
      coordinates: task.startLocation?.latitude ? task.startLocation : null,
    };
    const irTabParams = { task, irPhotoDescription };

    const questionaries = this.matchQuestionWithResults(task);
    const products = this.matchProducts(_.cloneDeep(task));
    const hasProducts = !!products;
    const hasPlanograms = !!task.planograms.length;
    const jti = task.contragentCode === 'JTI-SmartProekt';

    return (
      <Tabs vertical className={styles.tabs} selectedTabId={selectedTabId} onChange={this.handleChangeTab}>
        <Tab id="general" panel={this.renderGeneralInfo()}>
          <Icon icon="menu" />
          {t('basic information')}
        </Tab>

        <Tab id="photos" panel={this.renderPhotos()}>
          <Icon icon="media" />
          {t('photos')}
        </Tab>

        {questionaries.map((q) => (
          <Tab id={encodeURIComponent(q.name)} key={q.name} panel={this.renderQuestionary(q)} className={styles.tab}>
            <Icon icon="document" />
            {q.name}
          </Tab>
        ))}

        {!!task.equipment.length && (
          <Tab id="equipment" panel={<EquipmentTab equipment={task.equipment} />}>
            <Icon icon="oil-field" />
            {t('equipment check')}
          </Tab>
        )}

        {hasProducts && (
          <Tab id="alerts" panel={this.renderProducts(products)}>
            <Icon icon="shopping-cart" />
            {t('alerts')}
          </Tab>
        )}

        {!!irShelf?.length && (
          <Tab
            id="int-retail-shelf"
            panel={<IrTab {...irTabParams} photos={irShelf} />}
          >
            <Icon icon="camera" />
            {t('ir shelf and promo before')}
          </Tab>
        )}

        {!!irAfter?.length && (
          <Tab
            id="int-retail-after"
            panel={<IrTab {...irTabParams} photos={irAfter} />}
          >
            <Icon icon="camera" />
            {t('ir photo after')}
          </Tab>
        )}

        {!!irCheckout?.length && (
          <Tab
            id="int-retail-checkout"
            panel={<IrTab {...irTabParams} photos={irCheckout} />}
          >
            <Icon icon="camera" />
            {t('ir checkout before')}
          </Tab>
        )}

        {!!ir?.length && (
          <Tab
            id="int-retail"
            panel={<IrTab {...irTabParams} photos={ir} />}
          >
            <Icon icon="camera" />
            {t('ir')}
          </Tab>
        )}

        {!!irBefore?.length && (
          <Tab
            id="int-retail-before"
            panel={<IrTab {...irTabParams} photos={irBefore} />}
          >
            <Icon icon="camera" />
            {t('ir photo before')}
          </Tab>
        )}

        {task.ir_inspector_cloud_result && (
          <Tab
            id="inspector-cloud-products"
            panel={<IrInspectorCloudProductsTab result={task.ir_inspector_cloud_result} />}
          >
            <Icon icon="camera" />
            Inspector Cloud — {t('products')}
          </Tab>
        )}

        {task.ir_inspector_cloud_result && (
          <Tab
            id="inspector-cloud-scenes"
            panel={<IrInspectorCloudScenesTab result={task.ir_inspector_cloud_result} />}
          >
            <Icon icon="camera" />
            Inspector Cloud — {t('tasks')}
          </Tab>
        )}

        {/* events загружается из EventsTab, поэтому только прячем, если событий (ещё) нет */}
        <Tab
          id="notifications"
          panel={<EventsTab tradepointId={task.data.shop.id} endDate={task.endDate} />}
          disabled={eventsFetching}
          className={!hasEvents ? 'hidden' : null}
        >
          {
            eventsFetching
              ? <Spinner className={styles.spinnerIcon} size={Spinner.SIZE_SMALL} />
              : <Icon icon="notifications" />
          }
          {t('tasks')}
        </Tab>

        {hasPlanograms && (
          <Tab
            id="planograms"
            panel={this.renderPlanograms()}
          >
            <Icon icon="manual" />
            {t('planograms')}
          </Tab>
        )}

        {jti && renderJtiTask({
          id: 'last_monitoring_task',
          userTask: task,
          icon: 'clipboard',
          title: t('monitoringService'),
        })}
        {jti && renderJtiTask({
          id: 'last_posm_task',
          userTask: task,
          icon: 'credit-card',
          title: t('posmPlacement'),
        })}
        {jti && renderJtiTask({
          id: 'last_equipment_task',
          userTask: task,
          icon: 'build',
          title: t('repairRequest'),
        })}
      </Tabs>
    );
  }
}

TaskResult.propTypes = {
  id: PropTypes.number.isRequired,
  task: taskType,
  fetching: PropTypes.bool.isRequired,
  eventsFetching: PropTypes.bool.isRequired,
  hasEvents: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  showRestoredTasksMark: PropTypes.bool,
  canRestoredTasksMark: PropTypes.bool.isRequired,
  canViewFakeGPS: PropTypes.bool.isRequired,
  canViewFakePhoto: PropTypes.bool.isRequired,
  canViewMap: PropTypes.bool.isRequired,
  hideVisitMerch: PropTypes.bool.isRequired,
  showParentUser: PropTypes.bool.isRequired,
  hideWorkTime: PropTypes.bool.isRequired,
  hideStartEndDate: PropTypes.bool.isRequired,
  hideRemoteFinished: PropTypes.bool.isRequired,
  hideCreatePhotoTime: PropTypes.bool.isRequired,
  t: PropTypes.func,
};

TaskResult.defaultProps = {
  error: null,
  task: null,
  showRestoredTasksMark: true,
  t: () => {},
};

const mapStateToProps = (state) => ({
  task: getTask(state),
  fetching: getTaskIsFetching(state),
  eventsFetching: state.events.fetching,
  hasEvents: !!state.events.events?.length && hasRight(state, 'webapp_events'),
  error: getTaskError(state),
  canRestoredTasksMark: getCanRestoredTasksMark(state),
  canViewMap: !!state.user.user && !hasRight(state, 'webapp_contragent_forbid_view_task_map'),
  canViewFakeGPS: getCanViewFakeGPS(state),
  canViewFakePhoto: getCanViewFakePhoto(state),
  hideVisitMerch: needHideVisitUsers(state),
  showParentUser: needHideVisitMerch(state),
  hideWorkTime: hasRight(state, 'webapp_contragent_hide_work_time'),
  hideStartEndDate: hasRight(state, 'webapp_contragent_hide_start_end_date'),
  hideRemoteFinished: hasRight(state, 'webapp_contragent_hide_remote_finished'),
  hideCreatePhotoTime: hasRight(state, 'webapp_contragent_hide_create_photo_time'),
});

export default connect(mapStateToProps)(withTranslation()(TaskResult));
