import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Translation } from 'react-i18next';
import styles from './ImageDescription.module.css';

import { getState } from 'app/state';
import { hasRight } from 'app/state/reducers';

const ImageDescription = ({ network, address, date, coordinates } = {}) => (
  <div className={styles.photoDescriptionContainer}>
    <div className={styles.photoDescriptionWrapper}>
      <div className={styles.photoDescriptionRow}>
        <div className={styles.photoDescriptionLabel}>
          <Translation>{(t) => t('network')}</Translation>:
        </div>
        <div className={styles.photoDescriptionValue}>{network || '—'}</div>
      </div>
      <div className={styles.photoDescriptionRow}>
        <div className={styles.photoDescriptionLabel}>
          <Translation>{(t) => t('address')}</Translation>:
        </div>
        <div className={styles.photoDescriptionValue}>{address || '—'}</div>
      </div>
      <div className={styles.photoDescriptionRow}>
        <div className={styles.photoDescriptionLabel}>
          <Translation>{(t) => t('photo date')}</Translation>:
        </div>
        <div className={styles.photoDescriptionValue}>{date ?? '—'}</div>
      </div>
      {!hasRight(getState(), 'webapp_hide_create_photo_coordinates') && (
        <div className={styles.photoDescriptionRow}>
          <div className={styles.photoDescriptionLabel}>
            <Translation>{(t) => t('coordinates')}</Translation>:
          </div>
          <div className={styles.photoDescriptionValue}>
            {coordinates && (
              <>
                {_.round(coordinates.latitude ?? coordinates[0], 3)}°,
                {_.round(coordinates.longitude ?? coordinates[1], 3)}°
              </>
            )}
            {!coordinates && '—'}
          </div>
        </div>
      )}
    </div>
  </div>
);

ImageDescription.propTypes = {
  network: PropTypes.string,
  address: PropTypes.string,
  date: PropTypes.string,
  coordinates: PropTypes.oneOfType([
    PropTypes.shape({
      latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
    PropTypes.arrayOf(PropTypes.number.isRequired),
  ]),
};

ImageDescription.defaultProps = {
  network: null,
  address: null,
  date: null,
  coordinates: null,
};

export default ImageDescription;
