import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Icon, Spinner } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import * as api from 'app/api';
import * as utils from 'app/utils';
import * as toaster from 'app/widgets/toaster';

import styles from './ImportTasksScreen.module.css';

async function handleChange({ onDone, setFetching }, event) {
  if (!event.target.files.length) {
    return;
  }

  setFetching(true);
  const start = performance.now();
  try {
    await api.createImportTask(event.target.files[0]);
    toaster.showSuccess(i18n.t('importTaskCreated'));
    onDone(true);
  } catch (e) {
    const timeout = (
      performance.now() - start > 2000
        ? 0
        : 10000
    );

    toaster.showError(e.message || i18n.t('error'), { timeout });
    onDone(false);
  }

  setFetching(false);
  event.target.value = null;
}

function FileUploadButton({ onDone }) {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const onChangeFiles = RRU.useCallbackOptions(handleChange, { onDone, setFetching });

  return (
    <label className={styles.label} htmlFor="file">
      <input
        id="file"
        type="file"
        onChange={onChangeFiles}
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <span
        className={utils.classNames(
          'bp4-button bp4-minimal bp4-intent-success',
          fetching && 'bp4-disabled bp4-loading',
        )}
      >
        {fetching && <Spinner className="bp4-button-spinner" size={Spinner.SIZE_SMALL} />}
        <Icon icon="upload" />
        <span className="bp4-button-text">{t('upload')}</span>
      </span>
    </label>
  );
}

FileUploadButton.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default React.memo(FileUploadButton);
