import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Intent, Icon } from '@blueprintjs/core';
import { useDrag, useDrop, } from 'react-dnd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import * as config from 'app/config';
import * as utils from 'app/utils';
import { dispatch } from 'app/state';
import * as questionary from 'app/state/actionCreators/questionary';

import styles from './QuestionaryEditScreen.module.css';

const QuestionPlanogram = React.memo(({ planogram }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Link to={`/planograms/${planogram.id}`}>{planogram.name}</Link>
      &nbsp;
      {planogram.forced && <Icon icon="eye-open" intent="SUCCESS" htmlTitle={t('force display')} />}
    </div>
  );
});

QuestionPlanogram.propTypes = {
  planogram: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    forced: PropTypes.bool,
  }).isRequired,
};

function handleHover({ groupIndex, id: afterId }, { id }) {
  dispatch(questionary.moveQuestion({
    id,
    groupIndex,
    afterId,
  }));
}

function handleCollectIsDragging({ id }, monitor) {
  return monitor.getItem()?.id === id;
}

function handleCopy(params) {
  dispatch(questionary.copyQuestion(params));
}

function handleEdit(params) {
  dispatch(questionary.editQuestion(params));
}

function handleRemove(params) {
  dispatch(questionary.removeQuestion(params));
}

function renderDefaultAnswer({ has_default, default_answer }) {
  if (!has_default) {
    return '-';
  }
  if (_.isString(default_answer)) {
    return default_answer;
  }

  return JSON.stringify(default_answer);
}

const Question = React.memo(({ groupIndex, id }) => {
  const { t } = useTranslation();
  const ref = useRef();

  const onHover = utils.useCallbackWithDeps(handleHover, { groupIndex, id });
  const [, drop] = useDrop({
    accept: 'question',
    hover: onHover,
  });
  drop(ref);

  const dragItem = utils.useMemoWithDeps(_.identity, { id });
  const onCollectIsDragging = utils.useCallbackWithDeps(handleCollectIsDragging, { id });
  const [isDragging, drag, preview] = useDrag(() => ({
    type: 'question',
    item: dragItem,
    collect: onCollectIsDragging,
  }));
  preview(ref);
  const className = isDragging ? styles.draggingQuestion : null;

  const onClickCopy = utils.useCallbackWithDeps(handleCopy, { groupIndex, id });
  const onClickEdit = utils.useCallbackWithDeps(handleEdit, { groupIndex, id });
  const onClickRemove = utils.useCallbackWithDeps(handleRemove, { groupIndex, id });

  const question = useSelector((state) => state.questionary.questions[id]);
  const additionalProperties = _.filter([
    question.required && question.field_type !== 'PHOTO' && t('required'),
    question.photo_required && t('required photos'),
    question.photo_count && `${t('photo')}: ${question.photo_count}`,
  ]);

  return (
    <tr ref={ref} className={className}>
      <td className={styles.textColumn}>
        <span ref={drag} className={styles.dragHandle}>
          <Icon icon="drag-handle-horizontal" intent={Intent.PRIMARY} />
        </span>
        {question.text}
      </td>
      <td>{t(config.QUESTION_TYPES[question.field_type])}</td>
      <td>{additionalProperties.join(', ')}</td>
      <td>{renderDefaultAnswer(question)}</td>
      <td>{utils.toLocaleDateString(question.start_time) || '-'}</td>
      <td>{utils.toLocaleDateString(question.end_time) || '-'}</td>
      <td className={styles.planogramColumn}>
        {
          question.planograms &&
          question.planograms.map((p) => <QuestionPlanogram key={id} planogram={p} />)
        }
      </td>
      <td className={styles.actionColumn}>
        <Button
          minimal
          title={t('copy to end')}
          intent={Intent.PRIMARY}
          icon="duplicate"
          onClick={onClickCopy}
        />
        <Button
          minimal
          title={t('edit')}
          intent={Intent.PRIMARY}
          icon="edit"
          onClick={onClickEdit}
        />
        <Button
          minimal
          title={t('delete')}
          intent={Intent.DANGER}
          icon="cross"
          onClick={onClickRemove}
        />
      </td>
    </tr>
  );
});

Question.propTypes = {
  groupIndex: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default Question;
