import { useState, useEffect, useLayoutEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

export { shallowEqual } from 'react-redux';

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value],
  );

  return debouncedValue;
}

export function useRequestImmediate(func, args) {
  const [state, setState] = useState({ fetching: true, data: null, error: null });
  let arrayArgs = args;
  if (!_.isArray(args)) {
    arrayArgs = [args];
  }

  const [deps, setDeps] = useState(arrayArgs);
  if (!_.isEqual(deps, arrayArgs)) {
    setDeps(arrayArgs);
  }

  useEffect(() => {
    func(...arrayArgs)
      .then((data) => setState({ fetching: false, data, error: null }))
      .catch((error) => setState({ fetching: false, data: null, error }));
  }, deps);

  return state;
}

export function useDeepEqualValue(value) {
  const previous = useRef(value);
  return useMemo(
    () => {
      if (!_.isEqual(previous.current, value)) {
        previous.current = value;
      }
      return previous.current;
    },
    [value],
  );
}

export function useCallbackWithDeps(f, deps) {
  return useCallback((...args) => f(deps, ...args), _.values(deps));
}

export function useDispatchCallback(f, deps) {
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(f(...args)), deps);
}

export function useMemoWithDeps(f, deps) {
  return useMemo(() => f(deps), _.values(deps));
}

export function useEffectWithDeps(f, deps) {
  useEffect(() => f(deps), _.values(deps));
}

export function useDispatchEffect(action, deps) {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(action); }, deps);
}

export function useSelectorMemo(f, ...args) {
  let resolvedSelector = f;
  if (_.isString(f)) {
    resolvedSelector = (state) => _.get(state, f);
  }
  const memoizedSelector = useCallback(resolvedSelector, []);
  return useSelector(memoizedSelector, ...args);
}

const not = (v) => !v;

export function useToggle(defaultValue = false) {
  const [value, setValue] = useState(defaultValue);
  const toggle = useCallback(() => setValue(not), [setValue]);
  return [value, toggle];
}

export function useInit(f, ...args) {
  useLayoutEffect(() => { f(...args); }, []);
}
