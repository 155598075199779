import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { SelectionModes } from '@blueprintjs/table';
import _ from 'lodash';

import { dispatch } from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import * as utils from 'app/utils';

import { Filters, Filter } from './Filters';
import AbstractGrid from './AbstractGrid';
import Table from './Table';
import styles from './Grid.module.css';

const Column = utils.makePlaceholderComponentType('Grid.Column');

function handleSortChange({ path }, sort) {
  dispatch(grids.setSort(path, sort));
  dispatch(grids.fetch(path));
}

function splitChildren({ children }) {
  const columns = [];
  const others = [];
  for (const child of React.Children.toArray(children)) {
    if (child.type === Column) {
      columns.push(child);
    } else {
      others.push(child);
    }
  }

  return [columns, others];
}

const Grid = React.memo(React.forwardRef(
  function Grid(
    { path, children, selectionModes, getContextMenu, defaultRowHeight, ...props },
    ref,
  ) {
    const gridProps = useSelector((state) => state.grids[path] ?? grids.INITIAL_STATE);
    const [columnChildren, otherChildren] = utils.useMemoWithDeps(splitChildren, { children });
    const onSortChange = utils.useCallbackWithDeps(handleSortChange, { path });

    return (
      <AbstractGrid ref={ref} path={path} {...props}>
        {otherChildren}

        <Table
          className={styles.table}
          defaultRowHeight={defaultRowHeight}
          selectionModes={selectionModes}
          getContextMenu={getContextMenu}
          setSort={onSortChange}
          {...gridProps}
        >
          {columnChildren}
        </Table>
      </AbstractGrid>
    );
  }
));

Grid.Actions = AbstractGrid.Actions;
Grid.Filter = Filter;
Grid.Column = Column;

Grid.ActionsBar = AbstractGrid.ActionsBar;
Grid.Filters = Filters;
Grid.Table = Table;

Grid.propTypes = {
  path: PropTypes.string.isRequired,
  getContextMenu: PropTypes.func,
  selectionModes: PropTypes.oneOf(_.values(SelectionModes)),
  defaultRowHeight: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Grid.defaultProps = {
  selectionModes: SelectionModes.ALL,
  getContextMenu: null,
};

export default Grid;
