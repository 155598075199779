import _ from 'lodash';
import { Intent } from '@blueprintjs/core';

import i18n from 'i18n';
import * as api from 'app/api';
import * as grids from 'app/state/actionCreators/grids';
import store from 'app/state';
import { $setIn } from './index';
import { showError } from 'app/widgets/toaster';
import alerts from 'app/widgets/alerts';

const NEW_RECORD = {
  contragent_id: null,
};

export const fetch = (path, filters) => async (dispatch, getState) => {
  let state = getState().references[path];
  if (_.isEqual(state?.last?.filters, filters)) {
    return;
  }

  if (state?.ongoingRequests && state?.ongoingRequests.some((r) => _.isEqual(r.filters, filters))) {
    return;
  }

  dispatch($setIn(`references.${path}`, {
    ongoingRequests: _.concat(state?.ongoingRequests ?? [], { filters }),
  }));

  let data = null;
  let error = null;
  try {
    const response = await api.getReferences({ path, filters });
    data = response.data;
  } catch (e) {
    error = e;
  }

  state = getState().references[path];
  dispatch($setIn(`references.${path}`, {
    last: { filters, data, error },
    ongoingRequests: state.ongoingRequests.filter((r) => !_.isEqual(r.filters, filters)),
  }));
};

export function findReference(state, path, id) {
  return state.references[path]?.last?.data?.find((item) => item.id === id);
}

export function getContragentSetting(state, contragentId, key) {
  const contragent = findReference(state, api.CONTRAGENTS_REFERENCES, contragentId);
  return !!contragent?.settings[key];
}

export const flushFilters = (path) => async (dispatch, getState) => {
  let state = getState().references[path];
  if (!state?.last?.filters) {
    return;
  }
  dispatch($setIn(`references.${path}`, {
    last: { filteres: {} },
  }));
}

export const exportData = (path, filters) => async (dispatch) => {
  try {
    await api.exportReference(path, filters);
  } catch (error) {
    showError(error.message);
  }
};

export const newRecord = (path, record) =>
  $setIn(`references.${path}`, { editRecord: record ?? NEW_RECORD, fetching: false });

export const setRecordField = (path, key, value) =>
  $setIn(`references.${path}.editRecord`, { [key]: value, modified: true });

export const saveRecord = async (path) => {
  const { editRecord } = store.getState().references[path];
  store.setOne(`references.${path}.fetching`, true);
  const data = {
    id: editRecord.id,
    contragent_id: editRecord.contragent_id,
    name: editRecord.name,
  };

  try {
    let url = path;
    if (editRecord.id) {
      url += `/${editRecord.id}`;
    }
    await api.saveReferenceRecord(url, data);

    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne(`references.${path}.fetching`, false);
  }
};

export const moveToArchive = (path, ids) => async (dispatch, getState) => {
  const state = getState();
  const { filters } = state.grids[path];

  const confirmed = await alerts.show({
    confirmButtonText: i18n.t('yes'),
    cancelButtonText: i18n.t('no'),
    icon: 'trash',
    intent: Intent.DANGER,
    content: (
      <>
        <div>{i18n.t('sure to delete reference record')}</div>
      </>
    ),
  });

  if (!confirmed) {
    return null;
  }

  try {
    await api.deleteReferenceRecord(path, {
      filter: {
        contragent_id__eq: filters.contragent_id__eq,
        id__in: ids,
      }
    });
    store.unsetIn('references', path);
  } catch (e) {
    showError(e.message);
  } finally {
    dispatch(grids.fetch(path, { transientFilters: ['agency_id__eq'] }));
  }
};

export const saveProductRecord = async (path) => {
  const { editRecord } = store.getState().references[path];
  store.setOne(`references.${path}.fetching`, true);
  const data = {
    id: editRecord.id,
    contragent_id: editRecord.contragent_id,
    name: editRecord.name,
    code: editRecord.code,
    brand_id: editRecord.brand_id,
  };

  try {
    let url = path;
    if (editRecord.id) {
      url += `/${editRecord.id}`;
    }
    await api.saveReferenceRecord(url, data);
    store.unsetIn('references', path);
    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne(`references.${path}.fetching`, false);
  }
};

export const saveProjectRecord = async (path) => {
  const { editRecord } = store.getState().references[path];
  store.setOne(`references.${path}.fetching`, true);
  const data = {
    id: editRecord.id,
    contragent_id: editRecord.contragent_id,
    name: editRecord.name,
    can_skip_facade_photo: !!editRecord.can_skip_facade_photo,
    can_reschedule_past_tasks: !!editRecord.can_reschedule_past_tasks,
    ailet_userid_prefix: editRecord.ailet_userid_prefix ?? null,
  };

  try {
    let url = path;
    if (editRecord.id) {
      url += `/${editRecord.id}`;
    }
    await api.saveReferenceRecord(url, data);
    store.unsetIn('references', path);
    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne(`references.${path}.fetching`, false);
  }
};

export const saveBrandRecord = async (path) => {
  const { editRecord } = store.getState().references[path];
  store.setOne(`references.${path}.fetching`, true);
  const data = {
    id: editRecord.id,
    contragent_id: editRecord.contragent_id,
    name: editRecord.name,
    category_id: editRecord.category_id,
  };

  try {
    let url = path;
    if (editRecord.id) {
      url += `/${editRecord.id}`;
    }
    await api.saveReferenceRecord(url, data);
    store.unsetIn('references', path);
    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne(`references.${path}.fetching`, false);
  }
};

export const saveProductCategoryRecord = async (path) => {
  const { editRecord } = store.getState().references[path];
  store.setOne(`references.${path}.fetching`, true);
  const data = {
    id: editRecord.id,
    contragent_id: editRecord.contragent_id,
    name: editRecord.name,
    code: editRecord.code,
  };

  try {
    let url = path;
    if (editRecord.id) {
      url += `/${editRecord.id}`;
    }
    await api.saveReferenceRecord(url, data);
    store.unsetIn('references', path);
    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne(`references.${path}.fetching`, false);
  }
};

export const resetAllFacadePhotoAttr = (path) => async (dispatch, getState) => {
  const state = getState();
  const { filters } = state.grids[path];

  const confirmed = await alerts.show({
    confirmButtonText: i18n.t('yes'),
    cancelButtonText: i18n.t('no'),
    icon: 'small-square',
    intent: Intent.DANGER,
    content: (
      <>
        <div>{i18n.t('sure to reset all facade photo')}</div>
      </>
    ),
  });

  if (!confirmed) {
    return null;
  }

  try {
    await api.resetAllFacadePhotoAttr({
      filter: _.omit(filters, 'agency_id__eq'),
      value: false,
    });
    store.unsetIn('references', path);
  } catch (e) {
    showError(e.message);
  } finally {
    dispatch(grids.fetch(path, { transientFilters: ['agency_id__eq'] }));
  }
};
