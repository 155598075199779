import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Elevation } from '@blueprintjs/core';
import { tradepointAudit } from 'app/proptyping';
import { formatMinutesToHours } from 'app/utils/date';
import styles from './TasksScreen.module.css';

const locales = {
  ru: 'ru-RU',
  en: 'en-US',
  fr: 'fr-FR',
};

const localFormatDate = (date, lang) => (
  date ? (new Date(date && date.slice(0, -1)).toLocaleString(locales[lang])) : '-'
);

const TradepointInfo = ({ tradepoint, t, lang }) => (
  <div className={styles.tradepointInfoContainer}>
    <Card elevation={Elevation.TWO} className={styles.filtersPanel}>
      <div className={styles.detailsWrapper}>
        <div className={styles.details}>
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('num of visit')}:</div>
            <div className={styles.detailsValue}>{tradepoint.task_id || '-'}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('date of visit')}:</div>
            <div className={styles.detailsValue}>{localFormatDate(tradepoint.date, lang)}</div>
          </div>
          {(tradepoint.status === 'COMPLETED') && (
          <>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>{t('start date and time')}:</div>
              <div className={styles.detailsValue}>{localFormatDate(tradepoint.start_time, lang)}</div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>{t('end date and time')}:</div>
              <div className={styles.detailsValue}>{localFormatDate(tradepoint.end_time, lang)}</div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>{t('time spent at the point')}:</div>
              <div className={styles.detailsValue}>
                {formatMinutesToHours(75) || '-'}
              </div>
            </div>
          </>
          )}
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('signboard')}:</div>
            <div className={styles.detailsValue}>{tradepoint.network || '-'}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('address of tt')}:</div>
            <div className={styles.detailsValue}>{tradepoint.address || '-'}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('affiliate of tt')}:</div>
            <div className={styles.detailsValue}>{tradepoint.branch || '-'}</div>
          </div>
          {tradepoint && tradepoint.ir_code && (
          <div className={styles.detailsRow}>
            <div className={styles.detailsLabel}>{t('code tt for ir')}:</div>
            <div className={styles.detailsValue}>{tradepoint.ir_code || '-'}</div>
          </div>
          )}
          {tradepoint && tradepoint.visit_ir_type && (
            <div className={styles.detailsRow}>
              <div className={styles.detailsLabel}>{t('type of visit for ir')}:</div>
              <div className={styles.detailsValue}>{tradepoint.visit_ir_type || '-'}</div>
            </div>
          )}
        </div>
        <div className={styles.detailsActions}>
          <Link
            to={`/tradepoint/${tradepoint.id}`}
            className={styles.detailsLink}
          >
            {t('point of sale data')}
          </Link>
          {(tradepoint.status === 'COMPLETED') && (
          <Link
            to={`/tasks/${tradepoint.task_id}/result`}
            className={styles.detailsLink}
          >
            {t('the result of the visit')}
          </Link>
          )}
        </div>
      </div>
    </Card>
  </div>
);

TradepointInfo.propTypes = {
  tradepoint: PropTypes.shape(tradepointAudit).isRequired,
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default TradepointInfo;
