import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_FILTERS = {
  // код контрагента
  contragent: undefined,
  from_date: undefined,
  to_date: undefined,
  visits_only: undefined,
};

const INITIAL_STATE = {
  filters: INITIAL_FILTERS,
  fetching: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.REPORTS_BI_SET_FILTERS:
      return _.assign(state, { filters: action.payload.filters });
    case ACTIONS.REPORTS_BI_FETCH_START:
      return _.assign(state, { fetching: true });
    case ACTIONS.REPORTS_BI_FETCH_DONE:
      return _.assign(state, { fetching: false });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
