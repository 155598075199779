import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'app/widgets/Lightbox';
import EditPhoto from './EditPhoto';
import UploadPhoto from './UploadPhoto';
import styles from './EditPhoto.module.css';

function renderDescription(size) {
  if (!size) {
    return;
  }

  if (size > 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(1)}MiB`;
  }

  return `${(size / 1024).toFixed(0)}KiB`;
}

const Photos = ({
  photos,
  removePhoto,
  uploadPhoto,
  replacePhoto,
  getSrc,
  getPreview,
}) => {
  if (!photos || !photos.length) {
    return (
      <UploadPhoto
        multiple
        key="upload"
        onUpload={(im) => uploadPhoto(im)}
      />
    );
  }

  const isUploading = photos.filter((p) => p.uploading).length > 0;

  return (
    <div className={styles.images}>
      <Lightbox
        thumbnailWidth="160px"
        thumbnailHeight="160px"
        images={photos.map((photo) => ({
          src: getSrc(photo),
          thumbnail: getPreview(photo),
          opts: photo,
          description: photo.size,
        }))}
        renderDescriptionFunc={renderDescription}
        renderImageFunc={(idx, image, toggleLightbox) => {
          const isLast = idx === photos.length - 1;
          return (
            <React.Fragment key={image.opts.uuid}>
              <EditPhoto
                key={image.opts.uuid}
                uuid={image.opts.uuid}
                fetching={image.opts.uploading}
                onPreview={() => toggleLightbox(idx)}
                onDelete={() => removePhoto(image.opts.uuid)}
                onSelect={(im) => replacePhoto(im, image.opts.uuid)}
                thumbnail={image.thumbnail}
              />
              {isLast && (
                <UploadPhoto
                  multiple
                  key="upload"
                  disabled={isUploading}
                  onUpload={(im) => uploadPhoto(im)}
                />
              )}
            </React.Fragment>
          );
        }}
      />
    </div>
  );
  /* eslint-enable react/jsx-no-bind */
};

Photos.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string,
    size: PropTypes.number,
    uploading: PropTypes.bool,
  })).isRequired,
  removePhoto: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
  replacePhoto: PropTypes.func.isRequired,
  getSrc: PropTypes.func.isRequired,
  getPreview: PropTypes.func.isRequired,
};

export default Photos;
