import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_FILTERS = {
  expired_time__from: new Date(),
  expired_time__to: new Date(),
};

const INITIAL_STATE = {
  fetching: true,
  error: null,
  events: [],
  filtersVisible: false,
  filters: INITIAL_FILTERS,
  pagination: {
    itemsPerPage: 20,
    currentPage: 1,
  },
  totalItems: 0,
  totalPages: 0,

  tradepoint: null,
  tradepointFetching: false,
  tradepointError: null,

  exportFetching: false,
  exportError: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.EVENT_LIST_SET_FILTERS:
      return _.assign(state, { filters: action.payload.filters });
    case ACTIONS.EVENT_LIST_CLEAR_FILTERS:
      return _.assign(state, {
        filters: {
          contragent_id__eq: state.filters.contragent_id__eq,
          ...INITIAL_FILTERS,
        },
        tradepoint: null,
        pagination: {
          itemsPerPage: 20,
          currentPage: 1,
        },
      });
    case ACTIONS.EVENT_LIST_SET_PAGINATION:
      return _.assign(state, { pagination: action.payload.pagination });
    case ACTIONS.EVENT_LIST_SET_FILTER_VISIBLE:
      return _.assign(state, { filtersVisible: action.payload.filtersVisible });
    case ACTIONS.EVENT_TRADEPOINT_FETCH_START:
      return _.assign(state, { tradepointFetching: true });
    case ACTIONS.EVENT_TRADEPOINT_FETCH_DONE:
      return _.assign(state, {
        tradepointFetching: false,
        tradepointError: null,
        tradepoint: action.payload,
      });
    case ACTIONS.EVENT_TRADEPOINT_FETCH_ERROR:
      return _.assign(state, {
        tradepointFetching: false,
        tradepointError: action.error,
      });
    case ACTIONS.EVENT_EXPORT_START:
      return _.assign(state, {
        exportFetching: true,
        exportError: null,
      });
    case ACTIONS.EVENT_EXPORT_ERROR:
      return _.assign(state, {
        exportFetching: false,
        exportError: action.error,
      });
    case ACTIONS.EVENT_EXPORT_DONE:
      return _.assign(state, {
        exportFetching: false,
        exportError: null,
      });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
