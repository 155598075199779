import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Intent, Spinner, Colors } from '@blueprintjs/core';
import moment from 'moment';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import FancySelectFilter from 'app/widgets/Filters/FancySelectFilter';
import { AgencyContragentFilter, UserFilter } from 'app/widgets/Filters';
import { showError } from 'app/widgets/toaster';
import store from 'app/state';
import * as selectors from 'app/state/reducers';
import * as grids from 'app/state/actionCreators/grids';
import * as api from 'app/api';
import * as utils from 'app/utils';

import styles from './index.module.css';

const TYPE2I18N_KEY = {
  PHOTOS: 'photos',
  VISITS_REPORT: 'visit report',
  QUESTIONARIES_REPORT: 'questionnaire report',
  SIGNALS_REPORT: 'alert report',
  EQUIPMENT_REPORT: 'equipment report',
  VISITS_WORKING_TIME_REPORT: 'workingTimeReport',
  TERRITORY_WORKING_TIME_REPORT: 'territoryWorkingTimeReport',
  USERS_TEAM_REPORT: 'teamReport',
  TRADEPOINTS_WITHOUT_VISITS_REPORT: 'tradepointsWithoutVisitsReport',
  SCHEDULE_BY_USERS: 'scheduleByUsers',
  SCHEDULE_BY_TRADEPOINTS: 'scheduleByTradepoints',
  TRADEPOINTS: 'tradepoints',
};

function renderType(type, rowData) {
  if (type === 'SCHEDULE_BY_USERS' && rowData.params.show_plan_lead_time) {
    return i18n.t('scheduleWithPlanLeadTime');
  }
  return i18n.t(TYPE2I18N_KEY[type]);
}

function formatFileSize(fileSize) {
  if (!fileSize) {
    return '—';
  }

  if (fileSize < 1024) {
    return `${fileSize} B`;
  }

  if (fileSize < 1048576) {
    return `${(fileSize / 1024).toFixed(1)} KB`;
  }

  if (fileSize < 1073741824) {
    return `${(fileSize / 1048576).toFixed(1)} MB`;
  }

  return `${(fileSize / 1073741824).toFixed(1)} GB`;
}

function renderStatus(status) {
  return {
    NEW: i18n.t('in queue'),
    ACTIVE: i18n.t('in progress'),
    DONE: i18n.t('doneFeminine'),
    CANCELED: i18n.t('canceledFeminine'),
    FAILED: i18n.t('error'),
  }[status];
}

function renderPeriod(dummy, task) {
  let start, end;
  if (task.params.filters?.end_time__ge) {
    const { end_time__ge, end_time__le } = task.params.filters;
    start = end_time__ge;
    end = moment(end_time__le).subtract(1, 'day');
  }

  if (task.params.filter?.user_tasks_date) {
    [start, end] = task.params.filter.user_tasks_date;
    end = moment(end).subtract(1, 'day');
  }

  if (task.params.filter?.date__ge) {
    const { date__ge, date__le } = task.params.filter;
    start = date__ge;
    end = date__le;
  }

  if (start && end) {
    return [
        utils.toLocaleDateString(start),
        '—',
        utils.toLocaleDateString(end),
    ].join(' ');
  }

}

function renderProgress(progress, task) {
  return ['ACTIVE', 'DONE'].includes(task.status) ? `${Math.round(task.progress * 100)}%` : '—';
}

async function handleClickCancel(task, setCanceling) {
  setCanceling(true);
  try {
    await api.cancelBackgroundExportTask(task.id);
    store.dispatch(grids.fetch(api.BACKGROUND_EXPORT_TASKS));
  } catch (e) {
    showError(e.message);
  }
  setCanceling(false);
}

function CancelButton({ task }) {
  const [canceling, setCanceling] = React.useState(false);
  const onClickCancel = RRU.useCallbackArgs(handleClickCancel, task, setCanceling);

  return (
    <Button
      disabled={canceling}
      rightIcon={canceling && <Spinner size={Spinner.SIZE_SMALL} />}
      intent={Intent.DANGER}
      onClick={onClickCancel}
      text={i18n.t('cancel')}
      small
    />
  );
}

CancelButton.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

function renderActions(dummy, task) {
  if (['NEW', 'ACTIVE'].includes(task.status)) {
    return <CancelButton task={task} />;
  }

  if (task.status === 'DONE' && task.file_size) {
    const filename = [
      i18n.t(TYPE2I18N_KEY[task.type]),
      ' ',
      task.id,
      ' ',
      utils.ISODate(task.create_time),
      task.key.match(/\.\w+$/)[0],
    ].join('');
    return (
      <a href={`/webapi/background-export-tasks/${task.id}/file`} download={filename}>
        {i18n.t('downloadAlter')}
      </a>
    );
  }

  if (task.status === 'DONE' && !task.file_size) {
    return <span style={{ color: Colors.RED3 }}>{i18n.t('noFilesForExport')}</span>;
  }

  if (task.status === 'FAILED') {
    return <span style={{ color: Colors.RED3 }}>{i18n.t('error')}</span>;
  }

}

function setupLiveFeed({ grid, liveFeed }) {
  if (!liveFeed) {
    return;
  }

  grid.current?.fetch({ silent: true });
  const interval = setInterval(() => grid.current?.fetch({ silent: true }), 5000);
  return () => clearInterval(interval);
}

const INITIAL_SORT = { field: 'create_time', direction: 'desc' };

export default
function BackgroundExportTasksScreen() {
  const { t } = useTranslation();
  const grid = React.useRef();

  const [liveFeed, toggleLiveFeed] = RRU.useLocalStorageToggle('backgroundExportTasks.liveFeed', true);
  RRU.useEffectOptions(setupLiveFeed, { grid, liveFeed });

  const isSomeAdmin = RRU.useSelector(selectors.isAdminOrAgencyAdmin);

  const typeOptions = React.useMemo(
    () => _.toPairs(TYPE2I18N_KEY).map(option => ({ id: option[0], label: t(option[1]) })),
    [t],
  );

  return (
    <Page title={t('exportsQueue')} wide>
      <Grid ref={grid} path={api.BACKGROUND_EXPORT_TASKS} initialSort={INITIAL_SORT} defaultRowHeight={25}>
        <Grid.Actions>
          <Checkbox
            checked={liveFeed}
            label={t('liveFeed')}
            onClick={toggleLiveFeed}
            className={styles.liveFeedCheckbox}
          />
        </Grid.Actions>

        <Grid.Filter
          acceptor={AgencyContragentFilter}
          path="contragent_id__eq"
          clearable
        />
        {isSomeAdmin && (
          <Grid.Filter
            acceptor={UserFilter}
            path="user_id__eq"
            dependsOn="contragent_id__eq?"
            multi={false}
            clearable
          />
        )}
        <Grid.Filter
          acceptor={FancySelectFilter}
          path="type__eq"
          name={t('type')}
          data={typeOptions}
          multi={false}
          clearable
        />

        <Grid.Column path="id" title="ID" />
        <Grid.Column path="type" title={t('type')} valueRenderer={renderType} />
        <Grid.Column path="status" title={t('status')} valueRenderer={renderStatus} />
        {isSomeAdmin && <Grid.Column path="user" title={t('user')} />}
        <Grid.Column path="contragent" title={t('contractor')} />
        <Grid.Column path="period" title={t('period')} valueRenderer={renderPeriod} />
        <Grid.Column path="create_time" title={t('createTime')} valueRenderer={utils.toLocaleString} />
        <Grid.Column path="start_time" title={t('start date')} valueRenderer={utils.toLocaleString} />
        <Grid.Column path="end_time" title={t('date of completion')} valueRenderer={utils.toLocaleString} />
        <Grid.Column path="progress" title={t('progress')} valueRenderer={renderProgress} />
        <Grid.Column path="file_size" title={t('size')} valueRenderer={formatFileSize} />
        <Grid.Column path="actions" title={t('actions')} valueRenderer={renderActions} />
      </Grid>
    </Page>
  );
}
