import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Page from 'app/widgets/Page';
import {canEditProduct as getCanEditProduct} from 'app/state/reducers';
import SingleColumnReference from 'app/widgets/SingleColumnReference';
import InputFilter from 'app/widgets/Filters/InputFilter';
import RecordModal from './RecordModal';

export default React.memo(() => {
  const { t } = useTranslation();
  const canEdit = useSelector(getCanEditProduct);

  return (
    <Page title={t('products')}>
      <SingleColumnReference
        path="/products"
        modal={RecordModal}
        canEdit={canEdit}
      >
        <SingleColumnReference.Filter
          acceptor={InputFilter}
          path="code__ilike"
          name={t('product code')}
          placeholder={t('search by product code')}
        />
        <SingleColumnReference.Filter
          acceptor={InputFilter}
          path="product_brand_name__ilike"
          name={t('product brand')}
          placeholder={t('search by product brand')}
        />

        <SingleColumnReference.Column path="code" title={t('product code')} sortable />
        <SingleColumnReference.Column path="product_brand_name" title={t('product brand')} sortable />
      </SingleColumnReference>
    </Page>
  );
});
