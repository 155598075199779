import { Intent } from '@blueprintjs/core';
import _ from 'lodash';

import i18n from 'i18n';
import store from 'app/state';
import * as api from 'app/api';
import alerts from 'app/widgets/alerts';
import { showError, showSuccess } from 'app/widgets/toaster';

export async function toggleUser() {
  const { user } = store.getState().teamUser;

  store.setOne('teamUser.fetching', true);
  try {
    await api.toggleUserActive(user.id);
    if (user.active) {
      showSuccess(i18n.t('userBlocked'));
    } else {
      showSuccess(i18n.t('userUnblocked'));
    }
    store.setOne('teamUser.user.active', !user.active);
    store.setOne(`references.${api.USERS_REFERENCES}`, null);
    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne('teamUser.fetching', false);
  }
};

async function requestSave(id, body) {
  if (id) {
    await api.editUser(id, body);
    showSuccess(i18n.t('userSaved'));
    return id;
  }

  const response = await api.createUser(body);
  showSuccess(i18n.t('userSaved'));
  return response.data.id;
}

export async function saveUser() {
  const { user } = store.getState().teamUser;

  const data = {
    ..._.pick(
      user,
      'lastname',
      'firstname',
      'secondname',
      'email',
      'roles',
      'snils',
      'group',
      'multi_manager',
    ),

    phone: user.phone.replace(/[() -]+/g, ''),
    home_phone: user.home_phone ? user.home_phone.replace(/[() -]+/g, '') : null,
    contragents: user.contragentIds?.map((id) => ({
      id,
      manager_ids: user.contragents[id]?.manager_ids ?? [],
      branch_ids: user.contragents[id]?.branch_ids ?? [],
      network_ids: user.contragents[id]?.network_ids ?? [],
      project_ids: user.contragents[id]?.project_ids ?? [],
      territory_ids: user.contragents[id]?.territory_ids ?? [],
      questionary_ids: user.contragents[id]?.questionary_ids ?? [],
    })) ?? [],
  };

  let error;
  store.setOne('teamUser.fetching', true);
  try {
    return await requestSave(user.id, { data, allow_same_fullname_duplicate_snils: false });
  } catch (e) {
    if (e.type !== 'UserSnilsDuplicate') {
      showError(e.detail || e.message || i18n.t('userEditError'));
      return null;
    }

    error = e;
  } finally {
    store.setOne('teamUser.fetching', false);
  }

  const confirmed = await alerts.show({
    confirmButtonText: i18n.t('yes'),
    icon: 'trash',
    intent: Intent.DANGER,
    content: (
      <>
        <div>{error.message}</div>
        <div>{i18n.t('allowUserCreationQuestion')}</div>
      </>
    ),
  });

  if (!confirmed) {
    return null;
  }

  store.setOne('teamUser.fetching', true);
  try {
    return await requestSave(user.id, { data, allow_same_fullname_duplicate_snils: true });
  } catch (e) {
    showError(e.detail || e.message || i18n.t('userEditError'));
    return null;
  } finally {
    store.setOne('teamUser.fetching', true);
  }
};
