import React from 'react';
import PropTypes from 'prop-types';
import { H3 } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import { toLocaleString } from 'app/utils';
import { tradepointType } from 'app/proptyping';
import * as selectors from 'app/state/reducers';

import SimpleMap from './SimpleMap';
import styles from './TradepointScreen.module.css';
import KladrDetails from './KladrDetails';

function lastEditUserString(tt, hideVisitUsers, hideVisitMerch, t) {
  switch (tt?.last_edit_source) {
    case 'quest_import':
      return t('file import');
    case 'api_import':
      return t('file magnit alerts');
    case 'user':
      if (!tt.last_edit_user_fullname) {
        return `—`;
      }
      if (hideVisitUsers || hideVisitMerch) {
        return tt.last_edit_user_id;
      }

      return `${tt.last_edit_user_fullname} (${tt.last_edit_user_phone})`;
    default:
      return '—';
  }
}

function TradepointDetails({ tradepoint }) {
  const { t } = useTranslation();
  const hideVisitUsers = RRU.useSelector(selectors.needHideVisitUsers);
  const hideVisitMerch = RRU.useSelector(selectors.needHideVisitMerch);

  return (
  <div className={styles.details}>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('contractor')}:</div>
      <div className={styles.detailsValue}>{tradepoint.contragent ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('point code')}:</div>
      <div className={styles.detailsValue}>{tradepoint.code ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('extra point code')}:</div>
      <div className={styles.detailsValue}>{tradepoint.extended_code ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('extra retailer code')}:</div>
      <div className={styles.detailsValue}>{tradepoint.network_extended_code ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('affiliate')}:</div>
      <div className={styles.detailsValue}>{tradepoint.branch ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('city')}:</div>
      <div className={styles.detailsValue}>{tradepoint.city ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('territory')}:</div>
      <div className={styles.detailsValue}>{tradepoint.territory ?? '—'}</div>
    </div>
    <div className={styles.spacer} />
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('name')}:</div>
      <div className={styles.detailsValue}>{tradepoint.name ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('network')}:</div>
      <div className={styles.detailsValue}>{tradepoint.network ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('format')}:</div>
      <div className={styles.detailsValue}>{tradepoint.format ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('type')}:</div>
      <div className={styles.detailsValue}>{tradepoint.type ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('extra-project')}:</div>
      <div className={styles.detailsValue}>{tradepoint.project ?? '—'}</div>
    </div>
    <div className={styles.spacer} />
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('latitude')}:</div>
      <div className={styles.detailsValue}>{tradepoint.latitude ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('longitude')}:</div>
      <div className={styles.detailsValue}>{tradepoint.longitude ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('code for IR')}:</div>
      <div className={styles.detailsValue}>{tradepoint.ir_code ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('ir visit type prefix')}:</div>
      <div className={styles.detailsValue}>{tradepoint.visit_ir_type ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('number of cash desks')}:</div>
      <div className={styles.detailsValue}>{tradepoint.cashbox_count ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('visitIntervalDays')}:</div>
      <div className={styles.detailsValue}>{tradepoint.visit_interval_days ?? '—'}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('change attr on import')}:</div>
      <div className={styles.detailsValue}>{tradepoint.update_with_user_tasks_import ? t('yes') : t('no')}</div>
    </div>
    <div className={styles.spacer} />
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('was changed')}:</div>
      <div className={styles.detailsValue}>{toLocaleString(tradepoint.last_time)}</div>
    </div>
    <div className={styles.detailsRow}>
      <div className={styles.detailsLabel}>{t('who changed')}:</div>
      <div className={styles.detailsValue}>
        {lastEditUserString(tradepoint, hideVisitUsers, hideVisitMerch, t)}
      </div>
    </div>
    <div className={styles.spacer} />
    <KladrDetails
      id={tradepoint.kladr_id}
      status={tradepoint.kladr_status}
    />
  </div>
);
}

TradepointDetails.propTypes = {
  tradepoint: tradepointType.isRequired,
  t: PropTypes.func.isRequired,
};

const Tradepoint = ({ tradepoint, t }) => (
  <div>
    <H3>{`${tradepoint.network || tradepoint.name || ''} - ${tradepoint.address || ''}`}</H3>
    <div className={styles.wrapper}>
      <TradepointDetails tradepoint={tradepoint} />
      <SimpleMap
        latitude={tradepoint.latitude}
        longitude={tradepoint.longitude}
        popup={tradepoint.name}
      />
    </div>
  </div>
);

Tradepoint.propTypes = {
  tradepoint: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default Tradepoint;
