import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar,
  Button,
  Alignment,
  NavbarHeading,
  NavbarDivider,
  Popover,
  Menu,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import * as selectors from 'app/state/reducers';
import * as utils from 'app/utils';
import actions from 'app/state/directActions';
import { userType } from 'app/proptyping';

import routes from '../routes';
import styles from './NavBar.module.css';

export function canVisit(user, route) {
  if (!user || !route.requiredRight) {
    return true;
  }

  const rights = _.isArray(route.requiredRight) ? route.requiredRight : [route.requiredRight];
  return rights.some(r => user.rights.includes(r));
}

const RoutePropType = PropTypes.shape({
  path: PropTypes.string.isRequired,
  icon: PropTypes.string,
  name: PropTypes.string,
});

const NavBarLink = ({ children, to, onClick }) => (
  <Link to={to} style={{ textDecoration: 'none', color: 'inherit' }} onClick={onClick}>
    {children}
  </Link>
);
NavBarLink.propTypes = {
  children: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

NavBarLink.defaultProps = {
  onClick: () => {},
};

const NavBarButton = ({ path, user }) => {
  const route = findRoute(path);
  const hasRights = canVisit(user, route);
  const match = useRouteMatch(route);
  const { t } = useTranslation();
  if (!hasRights) {
    return null;
  }

  return (
    <NavBarLink to={route.path}>
      <Button
        minimal
        active={match}
        icon={route.icon}
        text={t(route.name)}
        className={styles.button}
      />
    </NavBarLink>
  );
};

NavBarButton.propTypes = {
  path: PropTypes.string.isRequired,
  user: userType,
};

NavBarButton.defaultProps = {
  user: null,
};

const NavBarMenuItem = ({
  route,
  params,
  popoverRef,
  user,
}) => {
  const hasRights = canVisit(user, route);
  const match = useRouteMatch(route);
  const { t } = useTranslation();
  if (!hasRights) {
    return null;
  }

  let { path } = route;
  if (params) {
    Object.keys(params).forEach((k) => {
      path = path.replace(`:${k}`, params[k]);
    });
  }

  return (
    <NavBarLink
      to={route.path}
      onClick={() => {
        if (popoverRef && popoverRef.current && popoverRef.current.setOpenState) {
          popoverRef.current.setOpenState(false);
        }
      }}
    >
      <MenuItem
        tagName="div"
        active={match}
        icon={route.icon}
        text={t(route.name)}
      />
    </NavBarLink>
  );
};

NavBarMenuItem.propTypes = {
  route: RoutePropType.isRequired,
  params: PropTypes.object,
  user: userType,
  popoverRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

NavBarMenuItem.defaultProps = {
  params: null,
  user: null,
};

const findRoute = (path) => routes.find((x) => x.path === path);

function makeNavBarMenuItems({ user, popoverRef, paths }) {
  const resolved = paths.map((r) => findRoute(r)).filter((r) => canVisit(user, r));
  if (resolved.length === 0) {
    return null;
  }

  return resolved.map((r) => <NavBarMenuItem key={r.path} user={user} popoverRef={popoverRef} route={r} />);
}

const PersonMenu = ({ popoverRef, user }) => {
  const { t } = useTranslation();
  return (
    <Menu>
      <MenuItem
        onClick={() => window.open('https://help.mywodo.ru')}
        tagName="div"
        icon="help"
        text={t('reference')}
      />

      <NavBarMenuItem user={user} popoverRef={popoverRef} route={findRoute('/feedback')} />

      <MenuItem
        tagName="div"
        icon="log-out"
        text={t('logout')}
        onClick={actions.user.logout}
      />
    </Menu>
  );
};

PersonMenu.propTypes = {
  popoverRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  user: userType.isRequired,
};

const VisitsMenu = ({ user }) => {
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  const items = makeNavBarMenuItems({
    user,
    popoverRef,
    paths: [
      '/tasks',
      '/schedule',
      '/user-task-checks',
    ],
  });
  if (!items) {
    return null;
  }

  return (
    <Popover
      ref={popoverRef}
      content={(
        <Menu>
          {items}
        </Menu>
      )}
      position={Position.BOTTOM_LEFT}
    >
      <Button
        minimal
        icon="calendar"
        text={t('visits')}
        rightIcon="chevron-down"
        className={styles.button}
      />
    </Popover>
  );
};

VisitsMenu.propTypes = {
  user: userType.isRequired,
};

const RefsMenu = ({ user }) => {
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  const items = makeNavBarMenuItems({
    user,
    popoverRef,
    paths: [
      '/tradepoints',
      '/questionaries',
      '/questionary-categories',
      '/contragents',
      '/team',
      '/planograms',
      '/user-task-check-questions',
      '/equipment',
      '/reschedule-reasons',
    ],
  });
  const geoItems = makeNavBarMenuItems({
    user,
    popoverRef,
    paths: [
      '/geography/networks',
      '/geography/branches',
      '/geography/formats',
      '/geography/projects',
      '/geography/territories'
    ]
  });
  const problemsItems = makeNavBarMenuItems({
    user,
    popoverRef,
    paths: [
      '/visit-problems',
      '/product-problems'
    ]
  });
  const productItems = makeNavBarMenuItems({
    user,
    popoverRef,
    paths: [
      '/products',
      '/product/brands',
      '/product/categories',
      '/product-groups'
    ]
  });
  if (!items) {
    return null;
  }

  return (
    <Popover
      ref={popoverRef}
      content={(
        <Menu>
          {items}
          {productItems && (
            <MenuItem text={t('products')} icon="cube">
              {productItems}
            </MenuItem>
          )}
          {geoItems && (
            <MenuItem text={t('geography')} icon="globe">
              {geoItems}
            </MenuItem>
          )}
          {problemsItems && (
            <MenuItem text={t('problems')} icon="warning-sign">
              {problemsItems}
            </MenuItem>
          )}
        </Menu>
      )}
      position={Position.BOTTOM_LEFT}
    >
      <Button
        minimal
        icon="list"
        text={t('reference books')}
        rightIcon="chevron-down"
        className={styles.button}
      />
    </Popover>
  );
};

RefsMenu.propTypes = {
  user: userType.isRequired,
};

const ReportsMenu = ({ user }) => {
  const popoverRef = useRef(null);
  const { t } = useTranslation();

  const needHidePhotoExport = utils.useSelectorMemo(selectors.needHidePhotoExport);
  const showCheckEquipment = utils.useSelectorMemo(selectors.showCheckEquipment);
  const paths = _.filter([
    // '/reports/bi',
    '/reports/jti',
    '/reports/diageo',
    '/reports/diageo-pilot',
    '/reports/visits',
    '/reports/signals',
    '/reports/questionaries',
    '/reports/kpi',
    !needHidePhotoExport && '/reports/photos',
    '/reports/user-task-checks',
    showCheckEquipment && '/reports/equipment',
    '/reports/jti-equipment-identification',
    '/reports/working-time',
    '/reports/territory-working-time',
    '/reports/reschedule-reasons',
    '/background-export-tasks',
  ]);

  const items = makeNavBarMenuItems({ user, popoverRef, paths });
  if (!items) {
    return null;
  }

  return (
    <Popover
      ref={popoverRef}
      content={(
        <Menu>
          {items}
        </Menu>
      )}
      position={Position.BOTTOM_LEFT}
    >
      <Button
        minimal
        icon="th-filtered"
        text={t('reports')}
        rightIcon="chevron-down"
        className={styles.button}
      />
    </Popover>
  );
};

ReportsMenu.propTypes = {
  user: userType.isRequired,
};

const QuestionaryMenu = ({ user }) => {
  const popoverRef = useRef(null);
  const { t } = useTranslation();
  const bindingRoute = findRoute('/questionaries/binding');
  const multiBindingRoute = findRoute('/questionaries/binding/multi');
  const IRBindingRoute = findRoute('/ir-tradepoint');
  const EventsBindingRoute = findRoute('/events');
  const MultiTradepointsBindingRoute = findRoute('/questionaries/binding/tradepoints');
  const canViewBindingRoute = canVisit(user, bindingRoute);
  const canViewMultiBindingRoute = canVisit(user, multiBindingRoute);
  const canViewIRBindingRoute = canVisit(user, IRBindingRoute);
  const canViewEventsBindingRoute = canVisit(user, EventsBindingRoute);
  if (
    !canViewBindingRoute
    && !canViewMultiBindingRoute
    && !canViewIRBindingRoute
    && !canViewEventsBindingRoute
  ) {
    return null;
  }

  return (
    <Popover
      ref={popoverRef}
      content={(
        <Menu>
          <NavBarMenuItem user={user} popoverRef={popoverRef} route={EventsBindingRoute} />
          <NavBarMenuItem user={user} popoverRef={popoverRef} route={IRBindingRoute} />
          <NavBarMenuItem user={user} popoverRef={popoverRef} route={multiBindingRoute} />
          <NavBarMenuItem user={user} popoverRef={popoverRef} route={bindingRoute} />
          <NavBarMenuItem user={user} popoverRef={popoverRef} route={MultiTradepointsBindingRoute} />
        </Menu>
      )}
      position={Position.BOTTOM_LEFT}
    >
      <Button
        minimal
        icon="manually-entered-data"
        text={t('section pinning')}
        rightIcon="chevron-down"
        className={styles.button}
      />
    </Popover>
  );
};

QuestionaryMenu.propTypes = {
  user: userType.isRequired,
};

const NavigationMenu = ({ user }) => {
  return (
    <>
      <NavBarButton user={user} path="/feed" />
      <QuestionaryMenu user={user} />
      <VisitsMenu user={user} />
      <RefsMenu user={user} />
      <NavBarButton user={user} path="/import-tasks" />
      <ReportsMenu user={user} />
    </>
  );
};

NavigationMenu.propTypes = {
  user: userType.isRequired,
};

const NavBar = ({ showNavigation, user }) => {
  const location = useLocation();
  const isLoginScreen = location.pathname === '/login';
  const isRecoveryScreen = location.pathname === '/recovery';
  const popoverRef = useRef(null);
  let fullName = '';
  if (user && user.firstname) {
    fullName = user.firstname;
  }
  if (user && user.lastname) {
    fullName += ` ${user.lastname}`;
  }

  return (
    <Navbar className={['bp4-dark', styles.navBar]} fixedToTop>
      <Navbar.Group align={Alignment.CENTER} className={styles.navBarGroup}>
        <NavbarHeading>WODO</NavbarHeading>
        {showNavigation && !isLoginScreen && !isRecoveryScreen && user && (
          <>
            <NavbarDivider />
            <NavigationMenu user={user} />
            <NavbarDivider />
            <Popover
              ref={popoverRef}
              content={<PersonMenu user={user} popoverRef={popoverRef} />}
              position={Position.BOTTOM_RIGHT}
            >
              <Button
                minimal
                icon="person"
                title={fullName || user.phone || user.email}
                text={fullName || user.phone || user.email}
                rightIcon="chevron-down"
                className={styles.userButton}
              />
            </Popover>
          </>
        )}
      </Navbar.Group>
    </Navbar>
  );
};

NavBar.propTypes = {
  showNavigation: PropTypes.bool,
  user: userType,
};

NavBar.defaultProps = {
  showNavigation: null,
  user: null,
};

export default NavBar;
