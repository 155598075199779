import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_STATE = {
  fetching: false,
  error: null,
  kladr: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.KLADR_FETCH_START:
      return _.assign(state, { fetching: true });
    case ACTIONS.KLADR_FETCH_DONE:
      return _.assign(state, { fetching: false, kladr: action.payload.kladr });
    case ACTIONS.KLADR_FETCH_ERROR:
      return _.assign(state, { fetching: false, error: action.error });
    case ACTIONS.KLADR_UPDATE_KLADR:
      return _.assign(state, {
        kladr: {
          ...state.kladr,
          ...action.payload.kladr,
        },
      });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
