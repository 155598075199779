import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Checkbox, Button, Intent } from '@blueprintjs/core';

import Page from 'app/widgets/Page';
import {canEditTradepointProjects as getCanEditTradepointProjects} from 'app/state/reducers';
import { dispatch } from 'app/state';
import { resetAllFacadePhotoAttr } from 'app/state/actionCreators/references';
import SingleColumnReference from 'app/widgets/SingleColumnReference';
import RecordModal from './RecordModal';

const path = '/tradepoint-projects';


function renderCheckbox(value) {
  return <Checkbox checked={!!value} disabled />;
}

export default React.memo(() => {
  const { t } = useTranslation();
  const canEdit = useSelector(getCanEditTradepointProjects);

  const resetAll = () => {
    dispatch(resetAllFacadePhotoAttr(path));
  }

  return (
    <Page title={t('tradepointProjects')}>
      <SingleColumnReference canEdit={canEdit} path={path} modal={RecordModal} defaultRowHeight={25}>
        <SingleColumnReference.Actions>
        {canEdit && (
          <Button
            icon="small-square"
            text={t('reset all')}
            intent={Intent.DANGER}
            onClick={resetAll}
          />
        )}
        </SingleColumnReference.Actions>

        <SingleColumnReference.Column
          path="can_skip_facade_photo"
          title={t('skip facade photo')}
          sortable
          valueRenderer={renderCheckbox}
        />

        <SingleColumnReference.Column
          path="can_reschedule_past_tasks"
          title={t('canReschedulePastTasks')}
          sortable
          valueRenderer={renderCheckbox}
        />

        <SingleColumnReference.Column
          path="ailet_userid_prefix"
          title={t('ailetUseridPrefix')}
        />
      </SingleColumnReference>
    </Page>
  );
});
