import _ from 'lodash';
import moment from 'moment';

import * as api from 'app/api';
import { ISODate } from 'app/utils';
import store from 'app/state';
import { hasRole } from 'app/state/reducers';
import { showError } from 'app/widgets/toaster';

import { $setIn } from './index';
import { findReference } from './references';
import * as grids from './grids';

export const closeDialog = (key) => $setIn(`schedule.${key}`, { isOpen: false });

export const openCompleteVisitDialog = (task) => (dispatch) => {
  const midday = moment(task.date).startOf('day').add(12, 'hours').toDate();
  dispatch($setIn(
    'schedule.completeVisitDialog',
    {
      isOpen: true,
      task,
      form: {
        start_time: midday,
        end_time: midday,
      },
      formInitial: {
        start_time: midday,
        end_time: midday,
      },
      fetching: false,
    },
  ));
};

export const completeVisit = () => async (dispatch, getState) => {
  const { task, form } = getState().schedule.completeVisitDialog;
  dispatch($setIn('schedule.completeVisitDialog', { fetching: true }));
  try {
    await api.completeTask(task.id, form);
    dispatch(grids.setScheduleTaskStatus(task.id, 'COMPLETED'));
    dispatch($setIn('schedule.completeVisitDialog', { isOpen: false, fetching: false }));
  } catch (e) {
    showError(e.message);
    dispatch($setIn('schedule.completeVisitDialog', { fetching: false }));
  }
};

export function openEditDialog(tasks) {
  let date = tasks[0].date;
  let user_id = tasks[0].user_id;

  for (const t of tasks) {
    if (t.date !== date) {
      date = null;
    }
    if (t.user_id !== user_id) {
      user_id = null;
    }
  }

  const form = {
    date: date ? moment(date).toDate() : null,
    user_id,
    manager_id: null,
    reschedule_reason_id: null,
  };

  store.setIn('schedule.editDialog', { isOpen: true, tasks, form, formInitial: form, fetching: false });
}

export const setEditDialogFormValue = (key, value) => store.setIn('schedule.editDialog.form', { [key]: value });

export const edit = (force) => async (dispatch, getState) => {
  const { tasks, form } = getState().schedule.editDialog;

  dispatch($setIn('schedule.editDialog', { fetching: true }));
  try {
    await api.editTasks({
      task_ids: tasks.map((t) => t.id),
      date: ISODate(form.date),
      user_id: form.user_id,
      manager_id: form.manager_id,
      reschedule_reason_id: form.reschedule_reason_id,
      force,
    });
  } finally {
    dispatch($setIn('schedule.editDialog', { fetching: false }));
  }
};

export const openCreateVisitDialog = () => (dispatch, getState) => {
  const { filters } = getState().grids[api.SCHEDULE];
  const initValue = {
    contragent_id__eq: filters.contragent_id__eq,
    user_id: filters.user_id,
    manager_id: null,
    branch_id__eq: filters.branch_id__in?.[0],
    network_id__eq: filters.network_id__in?.[0],
    tradepoint_ids: null,
    date: new Date(),
    repeat_count: 0,
    repeat_interval: 7,
    plan_travel_time_minutes: 0,
    plan_lead_time: 120,
  };
  dispatch($setIn('schedule', {
    createVisitDialog: {
      isOpen: true,
      formChanged: false,
      form: initValue,
      formInitial: initValue,
      fetching: false,
    },
  }));
};

export const createVisit = (force) => async (dispatch, getState) => {
  const state = getState();
  const { form } = state.schedule.createVisitDialog;

  const task = {
    contragent_id: form.contragent_id__eq,
    manager_id: form.manager_id,
    user_id: form.user_id,
    plan_travel_time_minutes: form.plan_travel_time_minutes,
    plan_lead_time: form.plan_lead_time,
    dates: [ISODate(form.date)],
    tradepoints: [],
  };

  const { date, repeat_count, repeat_interval, tradepoint_ids } = form;
  for (const i of _.range(repeat_count ?? 0)) {
    task.dates.push(ISODate(moment(date).add((i + 1) * repeat_interval, 'days')));
  }

  for (const id of tradepoint_ids) {
    task.tradepoints.push({
      id,
      code: findReference(state, api.TRADEPOINTS_REFERENCES, id).code,
    });
  }

  dispatch($setIn('schedule.createVisitDialog', { fetching: true }));
  try {
    await api.createTask({ task, force });
  } finally {
    dispatch($setIn('schedule.createVisitDialog', { fetching: false }));
  }
};

export const openCreateAuditDialog = () => (dispatch, getState) => {
  const state = getState();
  const { user } = state.user;
  const { filters } = state.grids[api.SCHEDULE];
  const form = {
    contragentId: filters.contragent_id__eq,
    supervisorId: hasRole(state, 'supervisor') ? user.id : null,
    managerId: null,
  };

  dispatch($setIn(
    'schedule.createAuditDialog',
    {
      isOpen: true,
      form,
      formChanged: false,
      fetching: false,
      formInitial: form,
    },
  ));
};

export const setCreateAuditDialogForm = (form) => (dispatch, getState) => {
  const state = getState();
  const { formInitial } = state.schedule.createAuditDialog;
  dispatch($setIn('schedule.createAuditDialog', {
    form,
    formChanged: !_.isEqual(form, formInitial),
  }));
};

export const createAudit = () => async (dispatch, getState) => {
  const { form } = getState().schedule.createAuditDialog;
  dispatch($setIn('schedule.createAuditDialog', { fetching: true }));
  try {
    const result = await api.createAuditCheck(form);
    if (result.mes) {
      throw new Error(result.mes);
    }
    await api.createAudit(form);
  } finally {
    dispatch($setIn('schedule.createAuditDialog', { fetching: false }));
  }
};
