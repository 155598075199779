import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import ReportPage from 'app/widgets/ReportPage';
import Form from 'app/widgets/Form';
import * as api from 'app/api';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';
import * as RefSel from 'app/widgets/ReferenceSelect';
import { canViewAgencies } from 'app/state/reducers';

const INITIAL_FILTERS = {
  agency_id__eq: 1,
  contragent_id__eq: 28,
  date__between: [moment().subtract(7, 'days').toDate(), new Date()],
};

function validateVisitPeriod([start, end]) {
  if (!start || !end) {
    return i18n.t('not specified');
  }
  if (moment(end).diff(start, 'days') > 31) {
    return i18n.t('period more than a month');
  }
  return true;
}

export default
function ReportJtiEquipmentIdentification() {
  const { t } = useTranslation();
  const showAgencyFilter = RRU.useSelector(canViewAgencies);

  return (
    <ReportPage
      title={t('jtiEquipmentIdentificationReport')}
      apiRoute={api.JTI_EQUIPMENT_IDENTIFICATION_REPORT}
      initialFilters={INITIAL_FILTERS}
    >
        {showAgencyFilter && (
          <Form.Item
            name="agency_id__eq"
            label={t('agency')}
            acceptor={RefSel.AgencySelect}
            autoSelectFirst
            multi={false}
          />
        )}
        <Form.Item
          name="contragent_id__eq"
          label={t('contractor')}
          dependsOn="agency_id__eq"
          acceptor={RefSel.ContragentSelect}
          autoSelectFirst
          multi={false}
          disabled={!showAgencyFilter}
        />
        <Form.Item
          name="date__between"
          label={t('visit period')}
          required
          acceptor={LocalizedDateRangeInput}
          validator={validateVisitPeriod}
          shortcuts="months"
        />
        <Form.Item
          name="tradepoint_code__ilike"
          label={t('point code')}
          placeholder={t('search by point code')}
        />
        <Form.Item
          name="extended_code__ilike"
          label={t('extra point code')}
          placeholder={t('searchByCode')}
        />
        <Form.Item
          name="tradepoint_name__ilike"
          label={t('name of tt')}
          placeholder={t('search by name of tt')}
        />
        <Form.Item
          name="address__ilike"
          label={t('address')}
          placeholder={t('search by address')}
        />
        <Form.Item
          name="city__ilike"
          label={t('city')}
          placeholder={t('search by city')}
        />
        <Form.Item
          name="branch_id__in"
          label={t('affiliate')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.BranchSelect}
        />
        <Form.Item
          name="network_id__in"
          label={t('network')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.TradeNetworkSelect}
        />
        <Form.Item
          name="project_id__in"
          label={t('project')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.TradepointProjectSelect}
        />
        <Form.Item
          name="format_id__in"
          label={t('format')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.TradepointFormatSelect}
        />
        <Form.Item
          name="user_id__in"
          label={t('executor')}
          dependsOn="contragent_id__eq"
          acceptor={RefSel.UserSelect}
          placeholder={t('search by executor')}
          filters={{ roles__overlap: ['merch'] }}
        />
    </ReportPage>
  );
}
