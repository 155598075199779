import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  InputGroup,
} from '@blueprintjs/core';
import { kladrType } from 'app/proptyping';
import styles from './index.module.css';

const KladrForm = ({ kladr, updateKladr, t }) => (
  <div className={styles.details}>
    <FormGroup
      inline
      label="ID:"
      labelFor="kladr-id"
      className={styles.detailsRow}
    >
      <InputGroup
        disabled
        id="kladr-id"
        value={kladr.id || ''}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('region')}:`}
      labelFor="kladr-province"
      className={styles.detailsRow}
    >
      <InputGroup
        id="kladr-province"
        value={kladr.province || ''}
        onChange={(e) => updateKladr({ province: e.target.value })}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('city')}:`}
      labelFor="kladr-locality"
      className={styles.detailsRow}
    >
      <InputGroup
        id="kladr-locality"
        value={kladr.locality || ''}
        onChange={(e) => updateKladr({ locality: e.target.value })}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('street')}:`}
      labelFor="kladr-street"
      className={styles.detailsRow}
    >
      <InputGroup
        id="kladr-street"
        value={kladr.street || ''}
        onChange={(e) => updateKladr({ street: e.target.value })}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('house')}:`}
      labelFor="kladr-house"
      className={styles.detailsRow}
    >
      <InputGroup
        id="kladr-house"
        value={kladr.house || ''}
        onChange={(e) => updateKladr({ house: e.target.value })}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('postal code')}:`}
      labelFor="kladr-postal-code"
      className={styles.detailsRow}
    >
      <InputGroup
        id="kladr-postal-code"
        value={kladr.postal_code || ''}
        onChange={(e) => updateKladr({ postal_code: e.target.value })}
      />
    </FormGroup>
  </div>
);

KladrForm.propTypes = {
  kladr: kladrType.isRequired,
  updateKladr: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default KladrForm;
