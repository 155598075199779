import React from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';
import { Spinner, Button } from '@blueprintjs/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import { useDispatchEffect, history } from 'app/utils';
import { fetchUser } from 'app/state/actionCreators/user';

import routes from './routes';
import NavBar, { canVisit } from './NavBar/NavBar';
import FancySelect from 'app/widgets/FancySelect';
import styles from './RootContainer.module.css';

const LANGUAGES = [
  { id: 'en', label: 'English' },
  { id: 'ru', label: 'Русский' },
  { id: 'fr', label: 'Français' },
];

const mediaMatch = window.matchMedia('(prefers-color-scheme: dark)');

function updateBodyTheme() {
  const forcedTheme = window.localStorage.getItem('theme');
  if (forcedTheme === 'dark' || (!forcedTheme && mediaMatch.matches)) {
    document.body.classList.add('bp4-dark');
  } else {
    document.body.classList.remove('bp4-dark');
  }
}

function getCurrentForcedTheme() {
  const forcedTheme = window.localStorage.getItem('theme');
  if ((forcedTheme === 'dark' && mediaMatch.matches) || (forcedTheme === 'light' && !mediaMatch.matches)) {
    window.localStorage.removeItem('theme');
    return null;
  }
  return forcedTheme;
}

function handleMediaMatchChange({ setForcedTheme, setPrefersDark }) {
  setPrefersDark(mediaMatch.matches);
  setForcedTheme(getCurrentForcedTheme());
  updateBodyTheme();
}

function handleStorage({ setForcedTheme }) {
  setForcedTheme(getCurrentForcedTheme());
  updateBodyTheme();
}

function setupThemeListeners({ setForcedTheme, setPrefersDark }) {
  updateBodyTheme();

  const mediaMatchListener = () => handleMediaMatchChange({ setForcedTheme, setPrefersDark });
  if (mediaMatch.addEventListener) {
    mediaMatch.addEventListener('change', mediaMatchListener);
  } else {
    // Safari < 14
    mediaMatch.addListener(mediaMatchListener);
  }
  const storageListener = () => handleStorage({ setForcedTheme });
  window.addEventListener('storage', storageListener);

  return () => {
    if (mediaMatch.removeEventListener) {
      mediaMatch.removeEventListener('change', mediaMatchListener);
    } else {
      // Safari < 14
      mediaMatch.removeListener(mediaMatchListener);
    }
    window.removeEventListener('storage', storageListener);
  };
}

function changeForcedTheme(setForcedTheme) {
  const forcedTheme = window.localStorage.getItem('theme');
  if (forcedTheme) {
    window.localStorage.removeItem('theme');
    setForcedTheme(null);
  } else if (mediaMatch.matches) {
    window.localStorage.setItem('theme', 'light');
    setForcedTheme('light');
  } else {
    window.localStorage.setItem('theme', 'dark');
    setForcedTheme('dark');
  }
  updateBodyTheme();
}

const Footer = React.memo(() => {
  const [forcedTheme, setForcedTheme] = React.useState(window.localStorage.getItem('theme'));
  const [prefersDark, setPrefersDark] = React.useState(mediaMatch.matches);
  RRU.useEffectOptions(setupThemeListeners, { setForcedTheme, setPrefersDark });
  const onClick = RRU.useCallbackArgs(changeForcedTheme, setForcedTheme);
  const { t, i18n } = useTranslation();

  let icon;
  let title;
  if (forcedTheme) {
    icon = 'delete';
    title = t('defaultTheme');
  } else if (prefersDark) {
    icon = 'flash';
    title = t('lightTheme');
  } else {
    icon = 'moon';
    title = t('darkTheme');
  }

  const version = process.env.REACT_APP_VERSION;
  return (
    <div className={styles.footer}>
      {version && <span className={styles.version}>v{version}</span>}

      <span className={styles.copyright}> © WODO 2019</span>

      <Button minimal icon={icon} title={title} onClick={onClick} />

      {window.location.host !== 'app.mywodo.ru' && (
        <FancySelect
          data={LANGUAGES}
          value={i18n.language}
          onChange={(v) => i18n.changeLanguage(v)}
          multi={false}
        />
      )}
    </div>
  );
});

const PrivateRoute = ({ ...route }) => {
  const loggedIn = RRU.useSelector((state) => state.user.loggedIn);
  const user = RRU.useSelector((state) => state.user.user);

  if (!loggedIn && !route.path.startsWith('/login') && route.path !== '/recovery') {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: route.location }
        }}
      />
    );
  }
  if (route.redirect) {
    return <Redirect to={route.redirect} />;
  }
  if (!canVisit(user, route)) {
    return <Redirect to="/" />;
  }
  return <Route {...route} />
}

export default React.memo(() => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  useDispatchEffect(fetchUser(), []);

  const loggedIn = RRU.useSelector('user.loggedIn');
  const user = RRU.useSelector('user.user');
  const fetching = RRU.useSelector('user.fetching');

  return (
    <Router history={history}>
      <NavBar routes={routes} showNavigation={loggedIn} user={user} />

      <div
        className={styles.content}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
      >
        <Switch>
          {
            fetching
              ? <Spinner />
              : routes.map((route) => <PrivateRoute key={route.path} {...route} />)
          }
          <Redirect to="/" />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
});
