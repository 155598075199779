import _ from 'lodash';

import i18n from 'i18n';
import * as api from 'app/api';
import * as utils from 'app/utils';
import * as references from 'app/state/actionCreators/references';

import { $set, $setIn } from './index';

const QUESTIONARY_TEMPLATE = {
  type: 'questionary',
  code: '',
  visibility_restriction: 'NONE',
  category_id: null,
  mandatory_period_start_date: null,
  mandatory_period_days: 30,
  mandatory_period_count: null,
  mandatory_period_type: 'first_visit',
  num_tasks_with_questionary: null,
};

function fixSteps(groups) {
  let lastStep = 0;
  for (let i = 0; i < groups.length; i += 1) {
    const step = groups[i].step;
    if (_.isNil(step)) {
      groups[i] = { ...groups[i], step: lastStep + 1 };
    } else {
      lastStep = step;
    }
  }
}

export const init = (id, copying) => async (dispatch) => {
  if (id === 'new') {
    dispatch($setIn('questionary', {
      questionary: { ...QUESTIONARY_TEMPLATE, name: i18n.t('new questionnaire') },
      groups: [],
      questions: {},
    }));

    return;
  }

  dispatch($setIn('questionary', { fetching: true }));
  try {
    const data = await api.getQuestionary(id);
    if (copying) {
      data.name += ` — ${i18n.t('copy')}`;
      data.code += ` - ${i18n.t('copy')}`;
    }

    const groups = [];
    for (const q of _.sortBy(data.questions, 'step', 'id')) {
      let found = false;
      for (let i = 0; i < groups.length; i += 1) {
        if (groups[i].step === q.step && _.isEqual(groups[i].titles, q.titles)) {
          groups[i].questions.push(q.id);
          found = true;
          break;
        }
      }

      if (!found) {
        groups.push({
          questions: [q.id],
          step: q.step,
          titles: q.titles,
        });
      }
    }
    fixSteps(groups);

    const questions = _.fromPairs(data.questions.map((q) => [q.id, q]));

    dispatch($setIn('questionary', { questionary: data, groups, questions, fetching: false }));
  } catch (error) {
    dispatch($setIn('questionary', { error, fetching: false }));
  }
};

export const addGroup = () => (dispatch, getState) => {
  let { groups } = getState().questionary;
  const step = (groups[groups.length - 1]?.step ?? 0) + 1;
  groups = [...groups, { step, titles: [null, null, null, null], questions: [] }];
  dispatch($setIn('questionary', { groups }));
};

export const setGroupTitle = ({ index, titleIndex, title }) => $set({
  [`questionary.groups[${index}].titles[${titleIndex}]`]: title,
});

export const setGroupStep = ({ groupIndex, step }) => $setIn(`questionary.groups[${groupIndex}]`, { step });

export const removeGroup = (index) => (dispatch, getState) => {
  let { groups, questions } = getState().questionary;
  const toRemove = groups[index].questions || [];
  groups = utils.splice(groups, index, 1);
  fixSteps(groups);

  questions = _.omit(questions, ...toRemove);
  dispatch($setIn('questionary', { groups, questions }));
};

export const resortGroups = () => (dispatch, getState) => {
  const groups = _.sortBy(getState().questionary.groups, 'step');
  fixSteps(groups);
  dispatch($setIn('questionary', { groups }));
};

export function getVariants({ field_type, variants }) {
  if (field_type === 'BOOLEAN') {
    return ['Да', 'Нет'];
  }
  return variants ?? [];
}

function getTreePath({ question, questions }) {
  const path = [];
  let id = question.id;
  while (id) {
    path.push(id);
    id = questions[id].parent_id;
  }
  return path;
}

function makeEditQuestion(state, { groupIndex, id }) {
  const { questions } = state.questionary;

  let question;
  if (_.isNil(id)) {
    question = {
      groupIndex,

      parent_id: null,
      condition: null,
      text: '',
      field_type: 'TEXT',
      variants: null,
      required: false,
      photo_required: false,
      photo_count: 0,
      preserve_photos: false,
      min_value: null,
      max_value: null,
      has_default: false,
      default_answer: null,
      start_time: null,
      end_time: null,
      activity_type_id: null,

      planograms: [],
    };
  } else {
    question = {
      groupIndex,
      ...questions[id],
    };
  }

  const variants = getVariants(question);
  question.dependendQuestions = _.slice(variants).map((v) => []);
  for (const q of _.values(questions)) {
    if (!q.condition || q.parent_id !== question.id) {
      continue;
    }

    const index = variants.indexOf(q.condition);
    if (index !== -1) {
      question.dependendQuestions[index].push(q.id);
    }
  }

  const treePath = getTreePath({ question, questions });
  question.questionSelectItems = (
    _.values(questions)
      .filter((q) => !treePath.includes(q.id))
      .map(({ id, text }) => ({ id, label: text }))
  );

  return question;
}

export const addQuestion = (groupIndex) => (dispatch, getState) => {
  const editQuestion = makeEditQuestion(getState(), { groupIndex });
  const editQuestionInitial = makeEditQuestion(getState(), { groupIndex });
  dispatch($setIn('questionary', { editQuestion, editQuestionInitial }));
};

export const copyQuestion = ({ groupIndex, id }) => (dispatch, getState) => {
  const { groups, questions } = getState().questionary;
  const newId = Date.now();
  const question = { ...questions[id], id: newId };
  const stepQuestions = [...groups[groupIndex].questions, newId];
  dispatch($set({
    [`questionary.groups[${groupIndex}].questions`]: stepQuestions,
    [`questionary.questions.${newId}`]: question,
  }));
};

export const editQuestion = (params) => (dispatch, getState) => {
  const editQuestion = makeEditQuestion(getState(), params);
  const editQuestionInitial = makeEditQuestion(getState(), params);
  dispatch($setIn('questionary', { editQuestion, editQuestionInitial }));
};

export const removeQuestion = ({ groupIndex, id }) => (dispatch, getState) => {
  const { groups, questions } = getState().questionary;
  const newStepQuestions = _.without(groups[groupIndex].questions, id);
  const newQuestions = _.omit(questions, id);
  dispatch($set({
    [`questionary.groups[${groupIndex}].questions`]: newStepQuestions,
    'questionary.questions': newQuestions,
  }));
};

export const moveQuestion = ({ id, groupIndex, afterId }) => (dispatch, getState) => {
  if (id === afterId) {
    return;
  }

  const changes = {};
  const { groups } = getState().questionary;
  for (let i = 0; i < groups.length; i += 1) {
    let questions = _.without(groups[i].questions, id);
    if (i === groupIndex) {
      questions = utils.splice(questions, questions.indexOf(afterId) + 1, 0, id);
    }
    changes[`questionary.groups[${i}].questions`] = questions;
  }
  dispatch($set(changes));
}

export const setEditQuestionVariant = ({ index, variant }) => (dispatch, getState) => {
  const variants = _.slice(getState().questionary.editQuestion.variants);
  variants[index] = variant;
  // FIXME update default_answer?
  dispatch($setIn('questionary.editQuestion', { variants }));
}

export const removeEditQuestionVariant = (index) => (dispatch, getState) => {
  const q = getState().questionary.editQuestion;

  const variants = utils.splice(q.variants, index, 1);
  const dependendQuestions = utils.splice(q.variants, index, 1);

  // FIXME update default_answer?
  dispatch($setIn('questionary.editQuestion', {
    variants,
    dependendQuestions,
  }));
}

export const setEditQuestionDependendQuestions = ({ index, dependendQuestions }) => (dispatch, getState) => {
  const allDependandQuestions = _.slice(getState().questionary.editQuestion.dependendQuestions);
  allDependandQuestions[index] = dependendQuestions;
  for (let i = 0; i < allDependandQuestions.length; i += 1) {
    if (i !== index) {
      allDependandQuestions[i] = _.without(allDependandQuestions[i], ...(dependendQuestions ?? []));
    }
  }

  dispatch($set({
    'questionary.editQuestion.dependendQuestions': allDependandQuestions,
  }));
};

export const setEditQuestionField = (params) => (dispatch, getState) => {
  const { editQuestion } = getState().questionary;
  dispatch($set({
    'questionary.editQuestion': { ...editQuestion, ...params},
  }));
};

export const resetEditQuestion = () => $setIn('questionary', { editQuestion: null });

export const resetEditQuestionDefaultAnswer = () => $setIn('questionary.editQuestion', { default_answer: null });

export const saveQuestion = () => (dispatch, getState) => {
  const state = getState();
  const { editQuestion, groups } = state.questionary;
  const { dependendQuestions, groupIndex, ...question } = _.omit(editQuestion, 'questionSelectItems');
  const stepQuestions = _.slice(groups[groupIndex].questions);
  if (!question.id) {
    question.id = Date.now();
    stepQuestions.push(question.id);
  }
  question.planograms = question.planogram_ids?.map((id) => ({
    forced: !!question.planogram_forced,
    id,
    name: references.findReference(state, api.PLANOGRAM_REFERENCES, id)?.label
  })) ?? [];

  const changes = {
    'questionary.editQuestion': null,
    [`questionary.groups[${groupIndex}].questions`]: stepQuestions,
    [`questionary.questions.${question.id}`]: question,
  };

  const variants = getVariants(question);
  for (const [variant, questionIds] of _.zip(variants, dependendQuestions)) {
    for (const id of (questionIds ?? [])) {
      changes[`questionary.questions.${id}.parent_id`] = question.id;
      changes[`questionary.questions.${id}.condition`] = variant;
    }
  }

  dispatch($set(changes));
};
