import _ from 'lodash';

import i18n from 'i18n';
import store from 'app/state';
import * as api from 'app/api';
import { showError, showSuccess } from 'app/widgets/toaster';

export function openEditDialog(problem) {
  const { filters } = store.getState().grids[api.PRODUCT_PROBLEMS] ?? {};
  const { contragent_id__eq: contragent_id } = filters;
  const initial = _.pick(problem, 'contragent_id', 'code', 'network_id', 'description') ?? { contragent_id }

  store.setOne('productProblems.editDialog', {
    isOpen: true,
    isNew: !problem,
    form: initial,
    formInitial: initial,
  });
}

export function closeEditDialog() {
  store.setOne('productProblems.editDialog.isOpen', false);
}

export async function save() {
  const { form, isNew } = store.getState().productProblems.editDialog;
  store.setOne('productProblems.editDialog.fetching', true);
  try {
    await api.editProductProblem({
      network_id: null,
      ...form
    });
    showSuccess(isNew ? i18n.t('problemCreated') : i18n.t('problemUpdated'));
    return true;
  } catch (e) {
    showError(e.message || i18n.t('error'));
    return false;
  } finally {
    store.setOne('productProblems.editDialog.fetching', false);
  }
};
