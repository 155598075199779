import { downloadDiageoReport } from 'app/api';
import { extractBlobError } from 'app/api/utils';
import * as ACTIONS from 'app/state/actions';

export const downloadReport = (filters, onError) => async (dispatch) => {
  dispatch({ type: ACTIONS.REPORTS_DIAGEO_FETCH_START });
  try {
    await downloadDiageoReport(filters);
  } catch (error) {
    const blobError = await extractBlobError(error);
    onError(blobError);
  }
  dispatch({ type: ACTIONS.REPORTS_DIAGEO_FETCH_DONE });
};

export const setFilters = (filters) => (
  { type: ACTIONS.REPORTS_DIAGEO_SET_FILTERS, payload: { filters } }
);
