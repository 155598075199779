import moment from 'moment';
import _ from 'lodash';

import * as ACTIONS from 'app/state/actions';

export const INITIAL_STATE = {
  dateFrom: moment(new Date()).startOf('month').toDate(),
  dateTo: moment(new Date()).endOf('month').toDate(),
  kpi: [],
  sort: {
    field: 'user_fullname',
    direction: 'asc',
  },
  totals: null,
  chiefs: [],
  fetching: false,
  totalsFetching: false,
  userFetching: false,
  error: null,
  totalsError: null,
  chiefsError: null,
  contragentId: null,
};

const formatKpi = (x) => ({
  ...x,
  user_tasks_date: x.user_tasks_date ? new Date(x.user_tasks_date) : null,
  start_time: x.start_time ? new Date(x.start_time) : null,
});

const getDescSorter = (field) => (a, b) => {
  if (a[field] < b[field]) {
    return 1;
  }
  if (a[field] > b[field]) {
    return -1;
  }
  return (a.user_tasks_date >= b.user_tasks_date ? 1 : -1);
};

const getAscSorter = (field) => (a, b) => {
  if (a[field] > b[field]) {
    return 1;
  }
  if (a[field] < b[field]) {
    return -1;
  }
  return (a.user_tasks_date >= b.user_tasks_date ? 1 : -1);
};

const sorted = (kpi, sort) => {
  const sorter = sort.direction === 'asc' ? getAscSorter(sort.field) : getDescSorter(sort.field);
  kpi.sort(sorter);
  return kpi;
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.KPI_SET_CONTRAGENT:
      return _.assign(state, { contragentId: action.payload.contragentId });
    case ACTIONS.KPI_SET_DATES:
      return _.assign(state, {
        dateFrom: action.payload.dateFrom,
        dateTo: action.payload.dateTo,
      });
    case ACTIONS.KPI_SORT:
      return _.assign(state, {
        sort: action.payload,
        kpi: sorted(state.kpi, action.payload),
      });
    case ACTIONS.KPI_FETCH_START:
      return _.assign(state, {
        fetching: true,
        error: null,
      });
    case ACTIONS.KPI_FETCH_DONE:
      return _.assign(state, {
        fetching: false,
        kpi: action.payload.map(formatKpi),
        sort: {
          field: 'user_fullname',
          direction: 'asc',
        },
      });
    case ACTIONS.KPI_FETCH_ERROR:
      return _.assign(state, {
        fetching: false,
        error: action.error,
      });
    case ACTIONS.KPI_FETCH_TOTALS_START:
      return _.assign(state, {
        totalsFetching: true,
        totalsError: null,
      });
    case ACTIONS.KPI_FETCH_TOTALS_DONE:
      return _.assign(state, {
        totalsFetching: false,
        totals: action.payload,
      });
    case ACTIONS.KPI_FETCH_TOTALS_ERROR:
      return _.assign(state, {
        totalsFetching: false,
        totalsError: action.error,
      });
    case ACTIONS.KPI_FETCH_CHIEFS_START:
      return _.assign(state, {
        userFetching: true,
        chiefsError: null,
      });
    case ACTIONS.KPI_FETCH_CHIEFS_DONE:
      return _.assign(state, {
        userFetching: false,
        chiefs: action.payload,
      });
    case ACTIONS.KPI_FETCH_CHIEFS_ERROR:
      return _.assign(state, {
        userFetching: false,
        chiefsError: action.error,
      });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
