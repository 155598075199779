import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Elevation, Spinner, NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import User from './User';
import Contragents from './Contragents';
import styles from './UserScreen.module.css';

export default React.memo(() => {
  const fetching = useSelector((state) => state.teamUser.fetching || state.roles.fetching);
  const error = useSelector((state) => state.teamUser.error);
  const { t } = useTranslation();

  if (fetching) {
    return <Spinner />;
  }

  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }

  return (
    <Card elevation={Elevation.TWO} className={styles.card}>
      <div className={styles.cardRow}>
        <User />
      </div>
      <Contragents />
    </Card>
  );
});
