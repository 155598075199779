import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Classes, Button, Intent, Spinner } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import * as ReferenceSelect from 'app/widgets/ReferenceSelect';
import Form, { ReduxForm } from 'app/widgets/Form';
import * as api from 'app/api';
import store from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import actions from 'app/state/directActions';

async function handleClickSave() {
  if (await actions.productProblems.save()) {
    store.dispatch(grids.fetch(api.PRODUCT_PROBLEMS));
    actions.productProblems.closeEditDialog();
  }
};

function EditForm() {
  const { t } = useTranslation();
  const isNew = RRU.useSelector('productProblems.editDialog.isNew');
  const contragentId = RRU.useSelector('productProblems.editDialog.form.contragent_id');

  return (
    <ReduxForm path="productProblems.editDialog.form">
      <Form.Item
        label={t('contractor')}
        name="contragent_id"
        required
        acceptor={ReferenceSelect.ContragentSelect}
        multi={false}
        autoSelectFirst
        disabled={!isNew}
      />
      <Form.Item
        label={t('code')}
        name="code"
        required
        disabled={!isNew}
      />
      <Form.Item
        label={t('network')}
        name="network_id"
        acceptor={ReferenceSelect.TradeNetworkSelect}
        filters={{ contragent_id__eq: contragentId }}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('description')}
        name="description"
        required
      />
    </ReduxForm>
  );
}

export default
function EditDialog() {
  const { t } = useTranslation();
  const { isOpen, isNew, formValid, fetching, formChanged } = RRU.useSelector('productProblems.editDialog');

  return (
    <Dialog
      icon="edit"
      title={t(isNew ? 'new problem' : 'edit problem')}
      isOpen={isOpen}
      onClose={actions.productProblems.closeEditDialog}
      canOutsideClickClose={!formChanged}
    >
      <div className={Classes.DIALOG_BODY}>
        {fetching ? <Spinner /> : <EditForm />}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={actions.productProblems.closeEditDialog}>{t('cancel')}</Button>
          <Button
            disabled={fetching || !formValid}
            loading={fetching}
            icon="tick"
            intent={Intent.PRIMARY}
            onClick={handleClickSave}
            text={t('save')}
          />
        </div>
      </div>
    </Dialog>
  );
}
