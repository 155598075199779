
import { makeEmptyReducer } from './utils';

const INITIAL_STATE = {
  fetching: true,
  error: null,
  user: null,
};

export default makeEmptyReducer(INITIAL_STATE);
