import { getReferences, ROLES_REFERENCES } from 'app/api';
import { $set } from './index';

export const fetchRoles = () => async (dispatch, getState) => {
  const { roles } = getState();
  if (roles.roles.length) {
    return;
  }

  dispatch($set({ 'roles.fetching': true }));
  try {
    const data = await getReferences({ path: ROLES_REFERENCES });
    dispatch($set({
      'roles.roles': data.data,
      'roles.rolesMap': Object.fromEntries(data.data.map((r) => [r.id, r.label])),
      'roles.fetching': false,
    }));
  } catch (error) {
    dispatch($set({
      'roles.error': error,
      'roles.fetching': false,
    }));
  }
};
