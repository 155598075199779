import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import PageSelect from 'app/widgets/PageSelect';
import * as selectors from 'app/state/reducers';

import styles from './EventsScreen.module.css';

export default
function ActionBar({
  hasFilters,
  filtersVisible,
  totalItems,
  totalPages,
  pagination,
  setFilterVisible,
  setPagination,
  onOpenDialog,
  noFilters,
  onExportClick,
  exportFetching,
  exportError,
}) {
  const { t } = useTranslation();
  const canEdit = RRU.useSelector(selectors.makeHasRight('webapp_events_edit'));

  return (
    <div className={styles.actionBar}>
      {
        noFilters
          ? (
            <Link to="/events">
              <Button
                minimal
                text={t('all tasks')}
                intent={Intent.SUCCESS}
                className={styles.openFiltersButton}
              />
            </Link>
          )
          : (
            <Button
              onClick={() => { setFilterVisible(!filtersVisible); }}
              minimal
              icon={hasFilters ? 'filter-keep' : 'filter'}
              rightIcon={filtersVisible ? 'chevron-up' : 'chevron-down'}
              text={t('filters')}
              intent={Intent.SUCCESS}
              className={styles.openFiltersButton}
            />
          )
      }
      {canEdit && (
        <Button
          onClick={onOpenDialog}
          icon="add"
          text={t('create event')}
          intent={Intent.SUCCESS}
          className={styles.openFiltersButton}
        />
      )}
      <Button
        minimal
        onClick={onExportClick}
        icon="download"
        text={t('download')}
        intent={Intent.SUCCESS}
        className={styles.openFiltersButton}
        loading={exportFetching}
      />
      {exportError && (
        <span className={styles.actionBarErrorMessage}>
          {exportError.message}
        </span>
      )}
      <div className={styles.spacer} />
      <PageSelect
        showTotal
        showItemsPerPageChanger
        total={totalItems}
        currentPage={pagination.currentPage}
        maxPage={totalPages}
        currentItemsPerPage={pagination.itemsPerPage}
        onPageSelect={
          (currentPage) => { setPagination({ ...pagination, currentPage }); }
        }
        onChangeItemsPerPage={
          (itemsPerPage) => { setPagination({ currentPage: 1, itemsPerPage }); }
        }
      />
    </div>
  );
}

ActionBar.propTypes = {
  hasFilters: PropTypes.bool.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  setFilterVisible: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  noFilters: PropTypes.bool,
  exportFetching: PropTypes.bool.isRequired,
  exportError: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.bool,
  ]),
  onExportClick: PropTypes.func.isRequired,
  t: PropTypes.func,
};

ActionBar.defaultProps = {
  noFilters: false,
  exportError: null,
  t: () => {},
};
