import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Intent } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import Page from 'app/widgets/Page';
import SelectableGrid from 'app/widgets/Grid/SelectableGrid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import {
  AgencyContragentFilter,
  TradeNetworkFilter,
  BranchFilter,
  TradepointTerritoryFilter,
  TradepointProjectFilter,
  TradepointFormatFilter,
} from 'app/widgets/Filters';
import alerts from 'app/widgets/alerts';
import KLADR_STATUS_MAP from 'app/config/kladrStatusMap';
import * as api from 'app/api';
import * as utils from 'app/utils';
import * as grids from 'app/state/actionCreators/grids';
import * as store from 'app/state';

function renderCode(code, rowData) {
  return (
    <Link to={`/tradepoint/${rowData.id}`}>
      {code}
    </Link>
  );
}

function renderKladrStatus(status, t = (s) => s) {
  return t(KLADR_STATUS_MAP[status]?.label);
}

function renderLastTask(task) {
  if (!task) {
    return null;
  }

  return (
    <Link to={`/tasks/${task.id}/result`}>
      {utils.toLocaleDateString(task.date)}
    </Link>
  );
}

async function handleClickDelete({ filters, selected }) {
  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deactivateTradepoints({ filter: { ...filters, id__in: selected } }),
    confirmButtonText: i18n.t('yes'),
    cancelButtonText: i18n.t('no'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: <div>{i18n.t('sure to delete tradepoints')}</div>,
  });

  if (confirmed && !error) {
    store.dispatch(grids.fetch(api.TRADEPOINTS));
  }
}

export default
function TradepointListScreen() {
  const { t } = useTranslation();
  const { filters, selected } = RRU.useSelector(`grids.${api.TRADEPOINTS}`) ?? grids.INITIAL_STATE;

  const onClickDownload = RRU.useCallbackArgs(
    utils.tryCreateBackgroundExportTask,
    'static-reports',
    { type: 'TRADEPOINTS', filter: filters },
  );
  const onClickDelete = RRU.useCallbackOptions(handleClickDelete, { filters, selected });

  return (
    <Page title={t('tradepoints')}>
      <SelectableGrid path={api.TRADEPOINTS}>
        <SelectableGrid.Filter
          acceptor={AgencyContragentFilter}
          path="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="address__fuzzy_like"
          name={t('address')}
          placeholder={t('search by address')}
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="code__like"
          name={t('point code')}
          placeholder={t('search by point code')}
        />
        <SelectableGrid.Filter
          acceptor={TradeNetworkFilter}
          path="network_id__in"
          dependsOn="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={BranchFilter}
          path="branch_id__in"
          dependsOn="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={TradepointTerritoryFilter}
          path="territory_id__in"
          dependsOn="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={TradepointProjectFilter}
          path="project_id__in"
          dependsOn="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={TradepointFormatFilter}
          path="format_id__in"
          dependsOn="contragent_id__eq"
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="type__ilike"
          name={t('type')}
          placeholder={t('search by type')}
        />
        <SelectableGrid.Filter
          acceptor={InputFilter}
          path="ir_code__ilike"
          name={t('extra code tt for ir')}
          placeholder={t('search by extra code tt for ir')}
        />

        <SelectableGrid.Actions>
          <Button
            intent={Intent.SUCCESS}
            icon="download"
            onClick={onClickDownload}
            minimal
          >
            {t('download')}
          </Button>
          <Button
            intent={Intent.DANGER}
            icon="delete"
            disabled={!selected.length}
            onClick={onClickDelete}
            minimal
          >
            {t('delete')}
          </Button>
        </SelectableGrid.Actions>

        <SelectableGrid.Column path="contragent" title={t('contractor')} sortable />
        <SelectableGrid.Column path="code" title={t('point code')} sortable valueRenderer={renderCode} />
        <SelectableGrid.Column path="address" title={t('address')} sortable />
        <SelectableGrid.Column path="network" title={t('network')} sortable />
        <SelectableGrid.Column path="branch" title={t('affiliate')} sortable />
        <SelectableGrid.Column path="territory" title={t('territory')} sortable />
        <SelectableGrid.Column path="name" title={t('name of tt')} sortable />
        <SelectableGrid.Column path="latitude" title={t('latitude')} sortable />
        <SelectableGrid.Column path="longitude" title={t('longitude')} sortable />
        <SelectableGrid.Column path="last_task" title={t('visit')} valueRenderer={renderLastTask} />
        <SelectableGrid.Column path="project" title={t('extra project')} sortable />
        <SelectableGrid.Column path="format" title={t('format')} sortable />
        <SelectableGrid.Column path="type" title={t('type')} sortable />
        <SelectableGrid.Column
          path="kladr_status"
          title={t('status KLADR')}
          sortable
          valueRenderer={(s) => renderKladrStatus(s, t)}
        />
        <SelectableGrid.Column path="ir_code" title={t('code for IR')} sortable />
      </SelectableGrid>
    </Page>
  )
}
