import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Icon } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import * as selectors from 'app/state/reducers';
import * as api from 'app/api';
import * as utils from 'app/utils';
import actions from 'app/state/directActions';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import FancySelectFilter from 'app/widgets/Filters/FancySelectFilter';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import * as Filters from 'app/widgets/Filters';

import FileUploadButton from './FileUploadButton';
import styles from './ImportTasksScreen.module.css';

const INITIAL_SORT = { field: 'last_time', direction: 'desc' };

function handleUploadDone(grid) {
  grid.current.fetch();
}

function renderFilename(filename, rowData) {
  return (
    <a href={`/webapi/import-tasks/${rowData.id}/file`} target="_blank" rel="noreferrer">
      {filename}
    </a>
  );
}

function CancelButton({ id }) {
  const loading = RRU.useSelector(`importTasks.cancelsInProgress.${id}`);
  const onClick = RRU.useCallbackArgs(actions.importTasks.cancelImportTask, id);

  return (
    <Button
      className={styles.cancelButton}
      icon="cross"
      intent="DANGER"
      loading={loading}
      onClick={onClick}
    />
  );
}

CancelButton.propTypes = {
  id: PropTypes.number.isRequired,
};

const STATUS2I18N_KEY = {
  PENDING: 'in queue',
  RUNNING: 'in progress',
  CANCELED: 'canceledMasculine',
  FINISHED: 'done',
  ABORTED: 'error',
  CANCELED_DUE_ERRORS: 'upload canceled due errors',
};

function renderStatus(status, row) {
  const text = i18n.t(STATUS2I18N_KEY[status]);

  if ((status === 'FINISHED' || status === 'CANCELED_DUE_ERRORS') && row.has_errors) {
    return (
      <>
        {text} (
        <Link to={`/import-tasks/${row.id}`}>
          {i18n.t('importErrors')} <Icon icon="share" size={14} />
        </Link>
        )
      </>
    );
  }

  if (status !== 'RUNNING' && status !== 'PENDING') {
    return text;
  }

  return (
    <>{text} <CancelButton id={row.id} /></>
  );
}

const IMPORT_TYPES = {
    QUESTIONARIES: 'quests',
    PLANOGRAM_TRADEPOINT_REFS: 'plgrefs',
    QUESTIONARY_TRADEPOINT_REFS: 'refs',
    IR_TRADEPOINT_REFS: 'refs-ir',
    USER_TASKS: 'visits',
    PRODUCTS: 'products',
    SIGNALS: 'alert',
    USER_TASK_CHECK_QUESTIONS: 'user-task-check-questions',
    MERCH_AUDITS: 'merch-audits',
    QUESTIONARY_UNBIND: 'quest-unbind',
    EQUIPMENT: 'equipment',
    PRODUCT_GROUPS: 'product-groups',
    PRODUCT_GROUP_REFS: 'product-group-refs',
    QUESTIONARY_PRODUCT_GROUP_REFS: 'questionary-product-group-refs',
    VISIT_TARGET_STATS: 'visit-target-stats',
    TRADEPOINTS: 'tradepoints',
    USERS: 'users-attributes',
    EVENTS: 'events',
};

const IMPORT_TYPE_OPTIONS = _.toPairs(IMPORT_TYPES).map(([id, label]) => ({ id, label }));

function renderImportType(importType) {
  return IMPORT_TYPES[importType];
}

function setupLiveFeed({ grid, liveFeed }) {
  if (!liveFeed) {
    return;
  }

  grid.current?.fetch({ silent: true });
  const interval = setInterval(() => grid.current?.fetch({ silent: true }), 10000);
  return () => clearInterval(interval);
}

export default
function ImportTasksScreen() {
  const grid = React.useRef();
  const { t } = useTranslation();
  const showAgencyFilter = RRU.useSelector(selectors.canViewAgencies);

  const onUploadDone = RRU.useCallbackArgs(handleUploadDone, grid);

  const [liveFeed, toggleLiveFeed] = RRU.useLocalStorageToggle('importTasks.liveFeed', true);
  RRU.useEffectOptions(setupLiveFeed, { grid, liveFeed });

  const statusOptions = React.useMemo(
    () => _.toPairs(STATUS2I18N_KEY).map(([status, key]) => ({ id: status, label: t(key) })),
    [t],
  );

  return (
    <Page title={t('imports')}>
      <Grid ref={grid} path={api.IMPORT_TASKS} initialSort={INITIAL_SORT} defaultRowHeight={25}>
        <Grid.Actions>
          <FileUploadButton onDone={onUploadDone} />

          <Checkbox
            checked={liveFeed}
            label={t('liveFeed')}
            onClick={toggleLiveFeed}
            className={styles.liveFeedCheckbox}
          />
        </Grid.Actions>

        {showAgencyFilter && (
          <Grid.Filter
            acceptor={Filters.AgencyFilter}
            path="agency_id__eq"
            transient
            multi={false}
            autoSelectFirst
          />
        )}
        <Grid.Filter
          acceptor={Filters.ContragentFilter}
          path="contragent_id__eq"
          dependsOn="agency_id__eq"
          multi={false}
          autoSelectFirst
          clearable
        />
        <Grid.Filter
          acceptor={Filters.UserFilter}
          path="user_id__eq"
          name={t('user')}
          dependsOn="contragent_id__eq?"
          multi={false}
          clearable
        />
        <Grid.Filter
          acceptor={FancySelectFilter}
          path="import_type__eq"
          name={t('type')}
          data={IMPORT_TYPE_OPTIONS}
          multi={false}
          clearable
        />
        <Grid.Filter
          acceptor={DaterangeFilter}
          path="create_time__between"
          name={t('createTime')}
          shortcuts="weekToWeek"
          withTime
        />
        <Grid.Filter
          acceptor={FancySelectFilter}
          path="status__eq"
          name={t('status')}
          data={statusOptions}
          multi={false}
          clearable
        />

        <Grid.Column path="contragent" title={t('contractor')} sortable />
        <Grid.Column path="user" title={t('user')} sortable />
        <Grid.Column path="filename" title={t('filename')} sortable valueRenderer={renderFilename} />
        <Grid.Column path="source" title={t('source')} sortable />
        <Grid.Column path="status" title={t('status')} sortable width={220} valueRenderer={renderStatus} />
        <Grid.Column path="import_type" title={t('type')} sortable valueRenderer={renderImportType} />
        <Grid.Column path="create_time" title={t('createTime')} sortable valueRenderer={utils.toLocaleString} />
        <Grid.Column path="start_time" title={t('start time')} sortable valueRenderer={utils.toLocaleString} />
        <Grid.Column path="last_time" title={t('lastTime')} sortable valueRenderer={utils.toLocaleString} />
      </Grid>
    </Page>
  );
}
