import { v4 as uuidv4 } from 'uuid';
import {
  uploadImage,
  sendFeedback,
} from 'app/api';
import * as ACTIONS from 'app/state/actions';

export const uploadImageAC = (image) => async (dispatch) => {
  const imageUUID = uuidv4();
  dispatch({ type: ACTIONS.FEEDBACK_IMAGE_UPLOAD_START });
  try {
    await uploadImage({ image, uuid: imageUUID });
    dispatch({ type: ACTIONS.FEEDBACK_IMAGE_UPLOAD_DONE, payload: { imageUUID } });
  } catch (error) {
    dispatch({ type: ACTIONS.FEEDBACK_IMAGE_UPLOAD_ERROR, error });
  }
};

export const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const sendFeedbackAC = ({
  message,
  feedbackType,
  imageUUID,
}) => async (dispatch, getState) => {
  const { phone, email } = getState().user.user;
  const data = {
    reportId: uuidv4(),
    feedbackType,
    date: new Date(),
    image: imageUUID || null,
    message,
    documentsDirectoryListing: [],
    buildInfo: {
      appName: 'web',
    },
    userInfo: {
      login: phone || email,
    },
    deviceInfo: {
      OS: window.navigator.userAgent,
      locale: window.navigator.language,
    },
  };
  dispatch({ type: ACTIONS.FEEDBACK_SEND_START });
  try {
    const result = await sendFeedback(data);
    dispatch({ type: ACTIONS.FEEDBACK_SEND_DONE, payload: { reportId: result.id } });
  } catch (error) {
    dispatch({ type: ACTIONS.FEEDBACK_SEND_ERROR, error });
  }
};

export const clearFeedbackAC = () => ({ type: ACTIONS.FEEDBACK_CLEAR });
