import i18n from 'i18n';
import * as api from 'app/api';
import { showError, showSuccess } from 'app/widgets/toaster';
import * as ACTIONS from 'app/state/actions';

export const fetchTask = (id) => async (dispatch) => {
  dispatch({ type: ACTIONS.TASK_FETCH_START });
  try {
    const task = await api.getTask(id);
    dispatch({ type: ACTIONS.TASK_FETCH_DONE, task });
  } catch (error) {
    dispatch({ type: ACTIONS.TASK_FETCH_ERROR, error });
  }
};

export const clearTask = () => ({ type: ACTIONS.TASK_CLEAR });

export const deleteTask = (id) => async (dispatch) => {
  dispatch({ type: ACTIONS.TASK_DELETE_START });
  try {
    await api.deleteTasks([id]);
    dispatch({ type: ACTIONS.TASK_DELETE_DONE });
    showSuccess(i18n.t('visitDeleted'));
    return true;
  } catch (error) {
    dispatch({ type: ACTIONS.TASK_DELETE_ERROR, error });
    showError(error.message || i18n.t('deletionError'));
    return false;
  }
};

export default fetchTask;
