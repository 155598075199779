import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import * as filters from 'app/widgets/Filters';
import { PRODUCT_GROUPS } from 'app/api';


export default React.memo(() => {
  const { t } = useTranslation();

  return (
    <Page title={t('product groups')}>
      <Grid path={PRODUCT_GROUPS}>
        <Grid.Filter
          acceptor={filters.AgencyContragentFilter}
          path="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={filters.BranchFilter}
          path="branch_id__in"
          dependsOn="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={filters.TradeNetworkFilter}
          path="network_id__in"
          dependsOn="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="tradepoint_code__fuzzy_like"
          name={t('point code')}
          placeholder={t('search by point code')}
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="tradepoint_address__fuzzy_like"
          name={t('address')}
          placeholder={t('search by address')}
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="name__fuzzy_like"
          name={t('name')}
          placeholder={t('search by name')}
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="product_code__fuzzy_like"
          name={t('product code')}
          placeholder={t('search by product code')}
        />

        <Grid.Column path="branch" title={t('affiliate')} sortable />
        <Grid.Column path="trade_network" title={t('network')} sortable />
        <Grid.Column path="tradepoint_code" title={t('point code')} sortable />
        <Grid.Column path="tradepoint_address" title={t('address')} sortable />
        <Grid.Column path="name" title={t('name')} sortable />
        <Grid.Column path="type" title={t('type')} sortable />
        <Grid.Column path="product_name" title={t('product name')} sortable />
        <Grid.Column path="product_code" title={t('product code')} sortable />
      </Grid>
    </Page>
  );
});
