import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumericInput, Intent } from '@blueprintjs/core';
import { useDebounce } from 'app/utils';
import { useTranslation } from 'react-i18next';
import styles from './Filters.module.css';

const NumrangeFilter = ({
  name,
  value,
  onChange,
  withDebounce,
  bothRequired,
}) => {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState(value);
  const [displayValue, setDisplayValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);
  const invalidFrom = (
    bothRequired
    && displayValue[1] !== undefined
    && displayValue[0] === undefined
  );
  const invalidTo = (
    bothRequired
    && displayValue[0] !== undefined
    && displayValue[1] === undefined
  );
  useEffect(
    () => {
      if (!withDebounce && (value[0] !== stateValue[0] || value[1] !== stateValue[1])) {
        onChange(stateValue);
      }
    },
    [stateValue],
  );
  useEffect(
    () => {
      if (withDebounce && (value[0] !== debouncedValue[0] || value[1] !== debouncedValue[1])) {
        onChange(debouncedValue);
      }
    },
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); setDisplayValue(value); },
    [value],
  );
  return (
    <div className={styles.filtersGroup}>
      <div className={styles.filtersLabel}>
        {name}
        {':'}
      </div>
      <div className={styles.filtersNums}>
        <NumericInput
          className={styles.filtersNumsFrom}
          fill
          buttonPosition="none"
          placeholder={t('range start')}
          value={displayValue[0] ?? ''}
          intent={invalidFrom ? Intent.DANGER : Intent.NONE}
          onValueChange={(val, strVal) => {
            setStateValue([strVal === '' ? undefined : val, stateValue[1]]);
            setDisplayValue([strVal, displayValue[1]]);
          }}
          onBlur={() => {
            setDisplayValue([
              !stateValue[0] && stateValue[0] !== 0 ? undefined : stateValue[0],
              displayValue[1],
            ]);
          }}
        />
        <NumericInput
          className={styles.filtersNumsTo}
          fill
          buttonPosition="none"
          placeholder={t('range end')}
          value={displayValue[1] ?? ''}
          intent={invalidTo ? Intent.DANGER : Intent.NONE}
          onValueChange={(val, strVal) => {
            setStateValue([stateValue[0], strVal === '' ? undefined : val]);
            setDisplayValue([displayValue[0], strVal]);
          }}
          onBlur={() => {
            setDisplayValue([
              displayValue[0],
              !stateValue[1] && stateValue[1] !== 0 ? undefined : stateValue[1],
            ]);
          }}
        />
      </div>
    </div>
  );
};

NumrangeFilter.propTypes = {
  value: PropTypes.any,
  withDebounce: PropTypes.bool,
  bothRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

NumrangeFilter.defaultProps = {
  value: [undefined, undefined],
  withDebounce: true,
  bothRequired: false,
};

export default NumrangeFilter;
