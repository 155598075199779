import _ from 'lodash';

import store from 'app/state';
import * as api from 'app/api';
import { t } from 'i18n';
import { showError } from 'app/widgets/toaster';

function toggleBindingsInProgress(keys) {
  let { bindingsInProgress } = store.getState().questionaryTradepointRefs;

  if (bindingsInProgress[keys[0]]) {
    bindingsInProgress = _.omit(bindingsInProgress, keys);
  } else {
    bindingsInProgress = {
      ...bindingsInProgress,
      ..._.fromPairs(keys.map((k) => [k, true])),
    };
  }

  store.setOne('questionaryTradepointRefs.bindingsInProgress', bindingsInProgress);
}

function updateTradepointQuestionaries({ tradepointId, updater }) {
  const { data } = store.getState().grids[api.TRADEPOINTS_BINDINGS];
  const index = data.findIndex(t => t.id === tradepointId);
  if (index === -1) {
    return;
  }

  const newQuestionaries = updater(data[index].questionaries);
  store.setOne(`grids.${api.TRADEPOINTS_BINDINGS}.data[${index}].questionaries`, newQuestionaries);
}

async function unbind({ tradepointId, questionaryId }) {
  await api.unbindQuestionaryFromTradepoint({ tradepointId, questionaryId });
  updateTradepointQuestionaries({
    tradepointId,
    updater: (questionaries) => _.omit(questionaries, questionaryId),
  });
}

async function bind({ tradepointId, questionaryIds }) {
  const refs = questionaryIds.map((questionaryId) => ({ tradepointId, questionaryId }));
  const response = await api.bindQuestionariesToTradepoints(refs);
  updateTradepointQuestionaries({
    tradepointId,
    updater: (questionaries) => ({ ...questionaries, ...response.data }),
  });
}

export async function toggleBindings({ tradepointId, questionaryIds, bound }) {
  const keys = questionaryIds.map((id) => `${tradepointId}_${id}`);
  toggleBindingsInProgress(keys);

  try {
    if (bound) {
      await unbind({ tradepointId, questionaryId: questionaryIds[0] });
    } else {
      await bind({ tradepointId, questionaryIds });
    }
  } catch (e) {
    showError(e.message || t('binding change error'));
  }

  toggleBindingsInProgress(keys);
}
