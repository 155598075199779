import _ from 'lodash';

import i18n from 'i18n';
import { getUserById, setUserPassword, setUserWebPassword } from 'app/api';
import { showError, showSuccess } from 'app/widgets/toaster';

import { $set, $setIn } from './index';

export const fetchUser = (id) => async (dispatch, getState) => {
  const { teamUser } = getState();
  if (teamUser.user?.id === id && !teamUser.user.modified) {
    return;
  }

  dispatch($setIn('teamUser', { fetching: true }));
  try {
    const response = await getUserById(id);
    const user = {
      ...response,
      contragentIds: response.contragents.map((c) => c.id),
      contragents: _.fromPairs(response.contragents.map((c) => [
        c.id,
        {
          ...c,
          manager_ids: c.managers?.map((m) => m.id),
          branch_ids: c.branches?.map((b) => b.id),
          network_ids: c.networks?.map((b) => b.id),
          project_ids: c.projects?.map((b) => b.id),
          territory_ids: c.territories?.map((b) => b.id),
          questionary_ids: c.questionaries?.map((b) => b.id),
        },
      ])),
    };
    dispatch($setIn('teamUser', { fetching: false, user }));
  } catch (error) {
    dispatch($setIn('teamUser', { fetching: false, error }));
  }
};

export const setPassword = (password) => async (dispatch, getState) => {
  const { user } = getState().teamUser;

  dispatch($setIn('teamUser', { fetching: true }));
  try {
    await setUserPassword(user.id, password);
    showSuccess(i18n.t('passwordUpdated'));
  } catch (error) {
    showError(error.message || i18n.t('passwordUpdateError'));
  } finally {
    dispatch($setIn('teamUser', { fetching: false }));
  }
};

export const setWebPassword = (password) => async (dispatch, getState) => {
  const { user } = getState().teamUser;

  dispatch($setIn('teamUser', { fetching: true }));
  try {
    await setUserWebPassword(user.id, password);
    showSuccess(i18n.t('webPasswordUpdated'));
  } catch (error) {
    showError(error.message || i18n.t('passwordUpdateError'));
  } finally {
    dispatch($setIn('teamUser', { fetching: false }));
  }
};

export const newUser = () => $setIn('teamUser', {
  user: {
    lastname: null,
    firstname: null,
    secondname: null,
    phone: null,
    home_phone: null,
    email: null,
    roles: null,
    snils: null,
    contragentIds: null,
    contragents: {},
    group: null,
    multi_manager: false,
  },
  fetching: false,
});

export const setUserField = (key, value) => $setIn('teamUser.user', { [key]: value, modified: true });

export const editContragent = (id, field, value) => $set({
  [`teamUser.user.contragents.${id}.${field}`]: value,
  'teamUser.user.modified': true,
});
