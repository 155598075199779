import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  H2,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import Page from 'app/widgets/Page';
import {
  fetchTaskAC,
  editTaskAC,
  newTaskAC,
  updateTaskAC,
  uploadShopPhotoAC,
  clearShopPhotoAC,
  clearQuestionsAC,
  createTaskAC,
} from 'app/state/actionCreators/restoredTaskEditor';
import TaskCard from './TaskCard';
import QuestionsCard from './QuestionsCard';

const RestoredTaskEditorScreen = ({
  history,
  location,
  isEditing,
  isCopying,
  task,
  fetching,
  saving,
  error,
  loadTask,
  updateTask,
  saveTask,
  clearQuestions,
  clearShopPhoto,
  uploadShopPhoto,
}) => {
  const { t } = useTranslation();
  const fromSchedule = location.state && location.state.fromSchedule;
  useEffect(() => { loadTask(); }, []);
  useEffect(() => {
    clearQuestions();
  }, [clearQuestions, JSON.stringify(task.questionaryIds)]);
  const [step, setStep] = useState('task');
  let title = t('create completed visit');
  if (isEditing) {
    title =  t('edit visit');
  } else if (isCopying) {
    title =  t('copy visit');
  }
  const hasSpinner = fetching || saving;

  if (error) {
    return (
      <Page>
        <H2>{title}</H2>
        <NonIdealState title={t('error')} description={error.message} icon="error" />
      </Page>
    );
  }

  return (
    <Page>
      <H2>{title}</H2>
      { hasSpinner && <Spinner /> }
      <TaskCard
        isEditing={isEditing}
        isCopying={isCopying}
        active={step === 'task' && !hasSpinner}
        task={task}
        updateTask={updateTask}
        uploadShopPhoto={uploadShopPhoto}
        clearShopPhoto={clearShopPhoto}
        onNext={() => {
          setStep('questions');
          window.scrollTo(0, 0);
        }}
      />
      <QuestionsCard
        isEditing={isEditing}
        active={step === 'questions' && !hasSpinner}
        onBack={() => setStep('task')}
        onSave={() => saveTask(() => (
          fromSchedule
            ? history.push('/schedule', { reload: true })
            : history.push('/feed', { reload: true })
        ))}
      />
    </Page>
  );
};

RestoredTaskEditorScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
  task: PropTypes.shape({
    questionaryIds: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  saving: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isCopying: PropTypes.bool.isRequired,
  loadTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  saveTask: PropTypes.func.isRequired,
  clearQuestions: PropTypes.func.isRequired,
  clearShopPhoto: PropTypes.func.isRequired,
  uploadShopPhoto: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Error),
};

RestoredTaskEditorScreen.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  task: state.restoredTaskEditor.task,
  fetching: state.restoredTaskEditor.fetch.running,
  error: state.restoredTaskEditor.fetch.error,
  saving: state.restoredTaskEditor.save.running,
  questionaries: state.restoredTaskEditor.questions.questionaries,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  updateTask: (patch) => dispatch(updateTaskAC(patch)),
  uploadShopPhoto: (image) => dispatch(uploadShopPhotoAC(image)),
  clearShopPhoto: () => dispatch(clearShopPhotoAC()),
  clearQuestions: () => dispatch(clearQuestionsAC()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id, copyId } = ownProps.match.params;
  const { dispatch } = dispatchProps;
  const isEditing = !!id;
  const isCopying = !!copyId;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    isEditing,
    isCopying,
    loadTask: (
      (isEditing || isCopying)
        ? () => dispatch(fetchTaskAC(id || copyId, isCopying))
        : () => dispatch(newTaskAC())
    ),
    saveTask: isEditing ? (onSuccess) => dispatch(editTaskAC({
      id,
      task: stateProps.task,
      questionaries: stateProps.questionaries,
      onSuccess,
    })) : (onSuccess) => dispatch(createTaskAC({
      task: stateProps.task,
      questionaries: stateProps.questionaries,
      onSuccess,
    })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RestoredTaskEditorScreen);
