import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FileInput } from '@blueprintjs/core';

import * as utils from 'app/utils';

import styles from './UploadPhoto.module.css';

const UploadPhoto = ({ disabled, multiple, onUpload }) => {
  const { t } = useTranslation();
  const [isDragover, setDragover] = useState(false);

  const title = `${t('attach an image')}...`;

  return (
    <FileInput
      onDragOver={(e) => {
        if (e.dataTransfer && e.dataTransfer.files) {
          setDragover(true);
        }
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        if (e.dataTransfer && e.dataTransfer.files) {
          setDragover(true);
        }
      }}
      onDragLeave={(e) => {
        if (e.dataTransfer && e.dataTransfer.files) {
          setDragover(false);
        }
      }}
      onDrop={(e) => {
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
          if (multiple) {
            for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
              const file = e.dataTransfer.files[i];
              onUpload(file);
            }
          } else {
            const file = e.dataTransfer.files[0];
            onUpload(file);
          }
        }
        setDragover(false);
        e.preventDefault();
      }}
      disabled={disabled}
      className={isDragover ? utils.classNames(styles.uploadPhoto, styles.dragover) : styles.uploadPhoto}
      title={title}
      onChange={(e) => {
        if (e.target.files && e.target.files.length) {
          if (multiple) {
            for (let i = 0; i < e.target.files.length; i += 1) {
              const file = e.target.files[i];
              onUpload(file);
            }
          } else {
            const file = e.target.files[0];
            onUpload(file);
          }
        }
      }}
      inputProps={{ accept: 'image/*', title, multiple }}
    />
  );
};

UploadPhoto.propTypes = {
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
};

UploadPhoto.defaultProps = {
  multiple: false,
  disabled: false,
};

export default UploadPhoto;
