
import { makeEmptyReducer } from './utils';

const INITIAL_STATE = {
  event: {},
  fetching: false,
  valid: false,
};

export default makeEmptyReducer(INITIAL_STATE);
