import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Classes, Button, Intent, Spinner } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import * as ReferenceSelect from 'app/widgets/ReferenceSelect';
import Form, { ReduxForm } from 'app/widgets/Form';
import * as api from 'app/api';
import store from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import actions from 'app/state/directActions';

async function handleClickSave() {
  if (await actions.questionaryCategories.save()) {
    store.dispatch(grids.fetch(api.QUESTIONARY_CATEGORIES));
    actions.questionaryCategories.closeEditDialog();
  }
};

function EditForm() {
  const { t } = useTranslation();

  return (
    <ReduxForm path="questionaryCategories.editDialog.form">
      <Form.Item
        label={t('contractor')}
        name="contragent_id"
        required
        acceptor={ReferenceSelect.ContragentSelect}
        multi={false}
        autoSelectFirst
      />
      <Form.Item
        label={t('code')}
        name="code"
        required
      />
      <Form.Item
        label={t('name')}
        name="name"
        required
      />
    </ReduxForm>
  );
}

export default
function EditDialog() {
  const { t } = useTranslation();
  const { isOpen, formValid, fetching, formChanged } = RRU.useSelector('questionaryCategories.editDialog');

  return (
    <Dialog
      icon="edit"
      title={t('newCategory')}
      isOpen={isOpen}
      onClose={actions.questionaryCategories.closeEditDialog}
      canOutsideClickClose={!formChanged}
    >
      <div className={Classes.DIALOG_BODY}>
        {fetching ? <Spinner /> : <EditForm />}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            icon="cross"
            onClick={actions.questionaryCategories.closeEditDialog}
            text={t('cancel')}
          />
          <Button
            disabled={fetching || !formValid}
            loading={fetching}
            icon="tick"
            intent={Intent.PRIMARY}
            onClick={handleClickSave}
            text={t('save')}
          />
        </div>
      </div>
    </Dialog>
  );
}
