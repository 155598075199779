import React from 'react';
import { Dialog, Classes, Button, Intent, TextArea } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import * as schedule from 'app/state/actionCreators/schedule';
import Form, { ReduxForm } from 'app/widgets/Form';
import { LocalizedDateInput } from 'app/widgets/LocalizedDateInput';

import styles from './CreateVisitDialog.module.css';

export default React.memo(() => {
  const isOpen = utils.useSelectorMemo((state) => state.schedule.completeVisitDialog.isOpen);
  const fetching = utils.useSelectorMemo((state) => state.schedule.completeVisitDialog.fetching);
  const valid = utils.useSelectorMemo((state) => !!state.schedule.completeVisitDialog.formValid);
  const changed = utils.useSelectorMemo((state) => state.schedule.completeVisitDialog.formChanged);
  const { t } = useTranslation();
  const onSave = utils.useDispatchCallback(schedule.completeVisit, []);
  const onClose = utils.useDispatchCallback(() => schedule.closeDialog('completeVisitDialog'), []);

  return (
    <Dialog
      icon="edit"
      title={t('complete the visit')}
      isOpen={isOpen}
      style={{ width: 550 }}
      onClose={onClose}
      enforceFocus={false}
      canOutsideClickClose={!changed}
    >
      <div className={Classes.DIALOG_BODY}>
        <ReduxForm className={styles.form} path="schedule.completeVisitDialog.form">
          <Form.Item
            label={t('start')}
            name="start_time"
            required
            acceptor={LocalizedDateInput}
            withTime
          />
          <Form.Item
            label={t('end')}
            name="end_time"
            required
            acceptor={LocalizedDateInput}
            withTime
          />
          <Form.Item
            label={t('reason of ending')}
            name="comment"
            required
            acceptor={TextArea}
          />
        </ReduxForm>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" disabled={fetching} onClick={onClose} text={t('cancel')} />
          <Button
            disabled={!valid}
            loading={fetching}
            icon="tick"
            intent={Intent.PRIMARY}
            text={t('save')}
            onClick={onSave}
          />
        </div>
      </div>
    </Dialog>
  );
});
