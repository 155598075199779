import _ from 'lodash';

import store from 'app/state';
import * as api from 'app/api';
import { t } from 'i18n';
import { showError } from 'app/widgets/toaster';

function toggleBindingsInProgress(keys) {
  let { bindingsInProgress } = store.getState().planogramTradepointRefs;

  if (bindingsInProgress[keys[0]]) {
    bindingsInProgress = _.omit(bindingsInProgress, keys);
  } else {
    bindingsInProgress = {
      ...bindingsInProgress,
      ..._.fromPairs(keys.map((k) => [k, true])),
    };
  }

  store.setOne('planogramTradepointRefs.bindingsInProgress', bindingsInProgress);
}

function updateTradepointList({ tradepointId, updater }) {
  const { data } = store.getState().grids[api.TRADEPOINTS_BINDINGS];
  const index = data.findIndex(t => t.id === tradepointId);
  if (index === -1) {
    return;
  }

  const newPlanograms = updater(data[index].planograms);
  store.setOne(`grids.${api.TRADEPOINTS_BINDINGS}.data[${index}].planograms`, newPlanograms);
}

async function unbind({ tradepointId, planogramId }) {
  await api.unbindPlanogramFromTradepoint([{ tradepointId, planogramId }]);
  updateTradepointList({
    tradepointId,
    updater: (planograms) => _.omit(planograms, planogramId),
  });
}

async function bind({ tradepointId, planogramIds, required }) {
  // const refs = [{
  //   tradepointId,
  //   planogramId: planogramIds.map((id) => id),
  //   forced: required,
  // }];
  const refs = planogramIds.map(
    (planogramId) => ({ tradepoint_id: tradepointId, planogram_id: planogramId, forced: required, })
  );
  const response = await api.bindPlanogramToTradepoints(refs);
  updateTradepointList({
    tradepointId,
    updater: (planogram) => ({ ...planogram, ...response.data }),
  });
}

export async function toggleBindings({ tradepointId, planogramIds, required, bound }) {
  const keys = planogramIds.map((id) => `${tradepointId}_${id}`);
  toggleBindingsInProgress(keys);

  try {
    if (bound) {
      await unbind({ tradepointId, planogramId: planogramIds[0] });
    } else {
      await bind({ tradepointId, planogramIds, required });
    }
  } catch (e) {
    showError(e.message || t('binding change error'));
  }

  toggleBindingsInProgress(keys);
}
