export const transformContragents = (contragents) => {
  const ids = [];
  const byId = {};

  for (const contragent of contragents) {
    ids.push(contragent.id);
    byId[contragent.id] = contragent;
  }

  return {
    ids, byId,
  };
};

export default transformContragents;
