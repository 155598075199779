import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@blueprintjs/core';

import * as utils from 'app/utils';
import NoCamera from 'app/assets/no-camera.svg';
import styles from './PhotoPreview.module.css';

const PhotoPreview = React.memo(({ src, alt, className, ...props }) => {
  const [loading, toggleLoading] = utils.useToggle(true);
  const [error, toggleError] = utils.useToggle(false);

  return (
    <div className={utils.classNames(className, styles.container)} {...props}>
      <img
        className={loading ? 'hidden' : styles.img}
        src={error ? NoCamera : src}
        alt={alt}
        onLoad={toggleLoading}
        onError={error ? null : toggleError}
      />
      {loading && <Spinner />}
    </div>
  );
});

PhotoPreview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PhotoPreview.defaultProps = {
  className: null,
};

export default PhotoPreview;
