import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement, Title, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { kpiTotalType } from 'app/proptyping';

import styles from './KpiScreen.module.css';

ChartJS.register(ArcElement, Title, Legend);

const drawPercentPlugin = {
  beforeDraw(chart) {
    if (!chart?.ctx) {
      return;
    }

    const { ctx } = chart;
    // Get options from the config
    const centerConfig = chart.config.options.plugins.percent;
    const fontStyle = centerConfig.fontStyle || 'Arial';
    const fontDecoration = centerConfig.bold ? 'bold ' : '';
    const { fontSize } = centerConfig;
    const txt = centerConfig.text;
    const color = centerConfig.color || '#000';
    // Start with a base font of 30px
    ctx.font = `${fontDecoration}${fontSize} ${fontStyle}`;
    // Set font settings to draw it correctly.
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    ctx.fillStyle = color;

    // Draw text in center
    ctx.fillText(txt, centerX, centerY);
  },
};

function generateLabels(chart) {
  return chart.data.labels.map((label, i) => {
    const meta = chart.getDatasetMeta(0);
    const style = meta.controller.getStyle(i);

    return {
      // Хачное выравнивание по-центру но чтобы прямоуголники
      // были друг под другом
      text: label + ['\x0a\x0a\x0a\x0a\x0a\x0a', '\x0a'][i],
      fillStyle: style.backgroundColor,
      strokeStyle: style.borderColor,
      lineWidth: style.borderWidth,
      hidden: false,
      index: i,
    };
  });
}

export const KpiDoughnut = ({
  data,
  labels,
  percent,
  title,
}) => {
  const chartData = {
    labels,
    datasets: [{
      data,
      backgroundColor: ['#8bc34a', '#f44336'],
    }],
  };
  return (
    <div className={styles.chart}>
      <Doughnut
        width={250}
        height={250}
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: title,
            },
            percent: {
              text: `${percent} %`,
              fontStyle: 'Arial',
              fontSize: '20px',
              color: '#8bc34a',
              bold: true,
            },
            legend: {
              position: 'bottom',
              labels: {
                generateLabels,
              },
            },
          },
        }}
        plugins={[drawPercentPlugin]}
      />
    </div>
  );
};

KpiDoughnut.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  percent: PropTypes.string,
  title: PropTypes.string.isRequired,
};

KpiDoughnut.defaultProps = {
  percent: 0,
};

export const Charts = ({ totals }) => {
  const { t } = useTranslation();
  if (!totals) {
    return null;
  }
  const data1 = [
    totals.processed_visits,
    totals.total_visits - totals.processed_visits,
  ];
  const labels1 = [t('visits done'), t('no visits done')];
  const percent1 = (((totals.processed_visits * 100.0) / totals.total_visits) || 0).toFixed(1);
  const data2 = [
    totals.processed_tradepoints,
    totals.total_tradepoints - totals.processed_tradepoints,
  ];
  const labels2 = [t('points completed'), t('points not completed')];
  const percent2 = (
    ((totals.processed_tradepoints * 100.0) / totals.total_tradepoints)
    || 0
  ).toFixed(1);
  return (
    <div className={styles.chartContainer}>
      <KpiDoughnut
        data={data1}
        labels={labels1}
        percent={percent1}
        title={t('visits per period')}
      />
      <KpiDoughnut
        data={data2}
        labels={labels2}
        percent={percent2}
        title={t('tt per period')}
      />
    </div>
  );
};

Charts.propTypes = {
  totals: kpiTotalType,
};

Charts.defaultProps = {
  totals: null,
};

export default Charts;
