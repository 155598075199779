import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { H4, Spinner, HTMLTable } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import * as api from 'app/api';
import * as utils from 'app/utils';
import * as selectors from 'app/state/reducers';

import styles from './TradepointScreen.module.css';

export default React.memo(() => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { fetching, data } = utils.useRequestImmediate(api.getLastCompletedInTradepoint, id);
  const hideVisitUsers = utils.useSelectorMemo(selectors.needHideVisitUsers);
  const hideVisitMerch = utils.useSelectorMemo(selectors.needHideVisitMerch);

  return (
    <div className={styles.history}>
      <H4>{t('visit history')}</H4>
      {fetching && <Spinner />}
      {!!data?.length && (
        <HTMLTable>
           <thead>
            <tr>
              <th>{t('date')}</th>
              <th>{t('executor')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((task) => (
              <tr key={task.id}>
                <td>
                  <Link to={`/tasks/${task.id}/result`}>
                    {utils.toLocaleDateString(task.end_time)}
                  </Link>
                </td>
                {
                  !hideVisitUsers && !hideVisitMerch
                    ? <td>{task.user_fullname} ({task.user_phone})</td>
                    : <td>{task.user_id}</td>
                }
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      )}
      {!fetching && !data?.length && <div className={styles.emptyHistory}>{t('no visits found')}</div>}
    </div>
  );
});
