import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dialog, Classes, Button, Intent, FormGroup, InputGroup, Tooltip } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { setPassword as setUserPassword, setWebPassword } from 'app/state/actionCreators/teamUser';

// только цифры, 6-10 символов
const RX_VALIDATE_PW = /^\d{6,10}$/;
const validatePassword = (text) => !!text.match(RX_VALIDATE_PW);

const SetPasswordDialog = React.memo(({ web, close }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const dispatch = useDispatch();
  const changed = password !== '' || repeatPassword !== '';

  const invalidMismatch = password !== repeatPassword;
  const invalidPassword = password && !validatePassword(password);
  const invalid = (
    !password
    || !repeatPassword
    || invalidMismatch
    || invalidPassword
  );

  return (
    <Dialog
      icon="key"
      title={web ? t('setting password web') : t('setting password')}
      isOpen
      onClose={close}
      canOutsideClickClose={!changed}
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup
          label={t('enter password')}
          helperText={invalidPassword ? t('only numbers length from 6 to 10') : undefined}
          intent={invalidPassword ? Intent.DANGER : null}
        >
          <InputGroup
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="set-user-password"
            name="set-user-password"
            autoComplete="new-password"
            type={showPassword ? 'text' : 'password'}
            rightElement={(
              <Tooltip content={`${showPassword ? t('hide') : t('show')} ${t('lowercase password')}`}>
                <Button
                  onClick={() => setShowPassword(!showPassword)}
                  icon={showPassword ? 'eye-off' : 'eye-open'}
                  intent={Intent.WARNING}
                  minimal
                />
              </Tooltip>
            )}
            large
          />
        </FormGroup>
        <FormGroup
          label={t('repeat password')}
          helperText={invalidMismatch ? t('passwords do not match') : undefined}
          intent={invalidMismatch ? Intent.DANGER : null}
        >
          <InputGroup
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            id="set-user-repeat-password"
            name="set-user-repeat-password"
            autoComplete="new-password"
            type={showRepeatPassword ? 'text' : 'password'}
            rightElement={(
              <Tooltip content={`${showRepeatPassword ? t('hide') : t('show')} ${t('lowercase password')}`}>
                <Button
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  icon={showRepeatPassword ? 'eye-off' : 'eye-open'}
                  intent={Intent.WARNING}
                  minimal
                />
              </Tooltip>
            )}
            large
          />
        </FormGroup>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={close}>{t('cancel')}</Button>
          <Button
            disabled={invalid}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            onClick={() => {
              close();
              const action = web ? setWebPassword : setUserPassword;
              dispatch(action(password));
            }}
          >
            {t('set password')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});

SetPasswordDialog.propTypes = {
  web: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default SetPasswordDialog;
