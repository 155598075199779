import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Page from 'app/widgets/Page';
import {canEditTradepointFormats as getEditTradepointFormats} from 'app/state/reducers';
import SingleColumnReference from 'app/widgets/SingleColumnReference';

export default React.memo(() => {
  const { t } = useTranslation();
  const canEdit = useSelector(getEditTradepointFormats);

  return (
    <Page title={t('formats')}>
      <SingleColumnReference canEdit={canEdit} path="/tradepoint-formats" />
    </Page>
  );
});
