import _ from 'lodash';

import i18n from 'i18n';
import store from 'app/state';
import * as api from 'app/api';
import { showError } from 'app/widgets/toaster';
import { isNumericType } from 'app/config';
import { getContragentSetting } from 'app/state/actionCreators/references';

export async function save(questionaryId) {
  const state = store.getState();
  const { questionary, groups, questions } = state.questionary;
  const useSteps = getContragentSetting(state, questionary.contragent_id, 'visit_use_steps');

  const data = { ...questionary, questions: [] };
  for (const s of groups) {
    const titles = s.titles.map((t) => t || null);
    for (const id of s.questions) {
      const q = questions[id];
      const numeric = isNumericType(q.field_type);
      data.questions.push({
        ..._.omit(q, ['planogram_ids', 'planogram_forced']),
        variants: ['SELECT', 'MULTICOMBO'].includes(q.field_type) ? q.variants : null,
        min_value: numeric ? q.min_value : null,
        max_value: numeric ? q.max_value : null,
        default_answer: q.has_default ? q.default_answer : null,
        step: useSteps ? s.step : null,
        titles,
        product_category_ids: q.product_category_ids ?? [],
      });
    }
  }

  store.setOne('questionary.fetching', true);
  try {
    let result;
    if (questionaryId) {
      result = await api.putQuestionary(questionaryId, data);
    } else {
      result = await api.postQuestionary(data);
    }

    store.unsetIn('grids', api.QUESTIONARIES, api.TRADEPOINTS_BINDINGS);
    store.unsetIn('references', api.QUESTIONARIES_REFERENCES);

    return result.data;
  } catch (e) {
    showError(e.message || i18n.t('saving error'));
  } finally {
    store.setOne('questionary.fetching', false);
  }
}
