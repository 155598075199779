import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H2, Button, Intent } from '@blueprintjs/core';
import _ from 'lodash';

import Page from 'app/widgets/Page';
import { userType, scheduleType } from 'app/proptyping';

import {
  fetchTaskList as fetchTaskListAC,
  setFilters as setFiltersAC,
  clearFilters as clearFiltersAC,
  setPagination as setPaginationAC,
  fetchTaskUser as fetchTaskUserAC,
} from 'app/state/actionCreators/tasks';
import TaskList from './TaskList';
import Pager from './Pager';

import styles from './TasksScreen.module.css';

const UserTodayTasksScreen = ({
  match,
  tasks,
  fetching,
  error,
  filters,
  pagination,
  totalPages,
  user,
  fetchTaskList,
  setFilters,
  clearFilters,
  setPagination,
  fetchTaskUser,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const userId = parseInt(match.params.id, 10);
    if (filters.user_id__eq === userId) {
      fetchTaskList(filters, pagination);
    }
  }, [filters, pagination, fetchTaskList]);
  useEffect(() => {
    const userId = parseInt(match.params.id, 10);
    const newFilters = {
      user_id__eq: userId,
      date__ge: new Date(),
      date__le: new Date(),
    };
    setPagination({ ...pagination, currentPage: 1 });
    setFilters(newFilters);
    fetchTaskUser(userId);
  }, [match.params.id]);

  const fullname = _.filter([user?.lastname, user?.firstname, user?.secondname]).join(' ');

  return (
    <Page>
      <div className={styles.header}>
        {user && <H2>{t('userXitineraryForToday', { fullname, phone: user.phone })}</H2>}
      </div>
      <div className={styles.allTasksWrapper}>
        <Link to="/tasks">
          <Button
            minimal
            text={t('allItineraries')}
            intent={Intent.SUCCESS}
            className={styles.allTasksButton}
            onClick={() => { clearFilters(); }}
          />
        </Link>
        <Pager
          pagination={pagination}
          totalPages={totalPages}
          setPagination={setPagination}
        />
      </div>

      <TaskList
        tasks={tasks}
        fetching={fetching}
        error={error}
      />
    </Page>
  );
};

UserTodayTasksScreen.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
  tasks: PropTypes.arrayOf(scheduleType).isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  filters: PropTypes.shape({
    user_id__eq: PropTypes.number,
    date__ge: PropTypes.instanceOf(Date),
    date__le: PropTypes.instanceOf(Date),
  }).isRequired,
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  totalPages: PropTypes.number.isRequired,
  user: userType,
  fetchTaskList: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  fetchTaskUser: PropTypes.func.isRequired,
};

UserTodayTasksScreen.defaultProps = {
  error: null,
  user: null,
};

const mapStateToProps = (state) => ({
  tasks: state.tasks.tasks,
  fetching: state.tasks.fetching || state.tasks.userFetching,
  error: state.tasks.error || state.tasks.userError,
  filters: state.tasks.filters,
  pagination: state.tasks.pagination,
  totalPages: state.tasks.totalPages,
  user: state.tasks.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTaskList: (filters, pagination) => dispatch(fetchTaskListAC(filters, pagination)),
  setFilters: (filters) => dispatch(setFiltersAC(filters)),
  clearFilters: () => dispatch(clearFiltersAC()),
  setPagination: (pagination) => dispatch(setPaginationAC(pagination)),
  fetchTaskUser: (id) => dispatch(fetchTaskUserAC(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTodayTasksScreen);
