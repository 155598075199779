import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import * as utils from 'app/utils';

import styles from './Grid.module.css';

const ActionsBar = React.memo(function ActionsBar({
  className,
  hasFilters,
  filtersVisible,
  toggleFiltersVisible,
  children,
  t,
}) {
  return (
    <div className={utils.classNames(styles.actionsBar, className)}>
      {hasFilters && (
        <Button
          onClick={toggleFiltersVisible}
          minimal
          icon={hasFilters ? 'filter-keep' : 'filter'}
          rightIcon={filtersVisible ? 'chevron-up' : 'chevron-down'}
          text={t('filters')}
          intent={Intent.SUCCESS}
          className={styles.openFiltersButton}
        />
      )}

      {children}
    </div>
  );
});

ActionsBar.propTypes = {
  className: PropTypes.string,
  hasFilters: PropTypes.bool.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  toggleFiltersVisible: PropTypes.func.isRequired,
  t: PropTypes.func,
  children: PropTypes.node,
};

ActionsBar.defaultProps = {
  className: null,
  children: undefined,
  t: () => {},
};

export default withTranslation()(ActionsBar);
