import _ from 'lodash';
import axios from 'axios';
import { $SET, $SET_IN } from 'react-redux-utils/genericActions';

export const $set = $SET;
export const $setIn = $SET_IN;

export async function resolveAborting(promise, path, dispatch, getState, silent) {
  const state = getState();
  const currentAbortRequest = _.get(state, `${path}.abortRequest`);
  if (currentAbortRequest) {
    currentAbortRequest();
  }

  const changesBefore = silent ? {} : { fetching: true };
  const changesAfter = silent ? {} : { fetching: false };

  try {
    dispatch($setIn(path, {
      abortRequest: promise.cancel,
      ...changesBefore
    }));

    const result = await promise;
    dispatch($setIn(path, {
      abortRequest: null,
      ...changesAfter
    }));

    return { result };
  } catch (error) {
    if (axios.isCancel(error)) {
      return { aborted: true };
    }

    dispatch($setIn(path, {
      abortRequest: null,
      ...changesAfter
    }));

    throw error;
  }
}
