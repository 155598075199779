import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

export const INITIAL_STATE = {
  fetching: false,
  error: null,
  users: [],
  filters: {
    active: true,
    roleCode: undefined,
    name: '',
  },
  contragentId: undefined,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.TEAM_USERS_SET_FILTERS:
      return _.assign(state, { filters: action.filters });

    case ACTIONS.TEAM_USERS_SET_CONTRAGENT:
      return _.assign(state, { contragentId: action.contragentId });

    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
