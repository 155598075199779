import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import * as utils from 'app/utils';

import styles from './Page.module.css';

function computeClassNames({ fullScreen, wide, flexible, heightBound }) {
  const result = [];
  if (fullScreen) {
    result.push(styles.fullScreen);
  } else if (wide) {
    result.push(styles.wide);
  } else if (flexible) {
    result.push(styles.flexible);
  } else {
    result.push(styles.page);
  }

  if (flexible || heightBound) {
    result.push(styles.heightBound);
  }

  return result;
}

function setTitle({ title, documentTitle }) {
  if (documentTitle || title) {
    document.title = `Панель управления WODO — ${documentTitle || title}`;
  }

  return () => { document.title = 'Панель управления WODO'; };
}

function Page({ className, children, fullScreen, wide, flexible, heightBound, title, documentTitle }) {
  const classes = RRU.useMemoOptions(computeClassNames, { fullScreen, wide, flexible, heightBound });
  RRU.useEffectOptions(setTitle, { title, documentTitle });

  return (
    <div className={utils.classNames(className, ...classes)}>
      {title && <H2>{title}</H2>}
      {children}
    </div>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  fullScreen: PropTypes.bool,
  wide: PropTypes.bool,
  flexible: PropTypes.bool,
  heightBound: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string,
  documentTitle: PropTypes.string,
};

Page.defaultProps = {
  className: null,
  fullScreen: false,
  wide: false,
  flexible: false,
  heightBound: false,
  title: null,
};

export default Page;
