import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { InputGroup, Position } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import NumInput from 'app/widgets/NumInput';
import SimpleSelect from 'app/widgets/SimpleSelect';
import FancySelect from 'app/widgets/FancySelect';
import { LocalizedISODateInput } from 'app/widgets/LocalizedDateInput';
import { updateQuestionAC } from 'app/state/actionCreators/restoredTaskEditor';
import { NUMBER_TYPES_NAMES } from 'app/config/questionTypes';

import styles from './AnswerControl.module.css';

const getSelectItems = (variants) => variants.map((v) => ({
  label: v,
  id: v,
}));

const OPERATOR_FUNCTIONS = {
  ADDITION: (a, b) => a + b,
  SUBTRACTION: (a, b) => a - b,
  DIVISION: (a, b) => a / b,
  MULTIPLICATION: (a, b) => a * b,
};

const OPERATORS = {
  ADDITION: '+',
  SUBTRACTION: '-',
  DIVISION: '/',
  MULTIPLICATION: '*',
};

const OperatorAnswer = ({ value, onChange, type }) => {
  const [a, setA] = useState(value?.a);
  const [b, setB] = useState(value?.b);
  useEffect(() => {
    if (!_.isFinite(a) || !_.isFinite(b)) {
      onChange(null);
      return;
    }
    const result = OPERATOR_FUNCTIONS[type](a, b);
    onChange(_.isFinite(result) ? { a, b, result } : null);
  }, [a, b]);

  return (
    <>
      <NumInput value={a} onChange={setA} />
      { OPERATORS[type] }
      <NumInput value={b} onChange={setB} />
      =
      { value?.result }
    </>
  );
};

OperatorAnswer.propTypes = {
  value: PropTypes.shape({
    a: PropTypes.number,
    b: PropTypes.number,
    result: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

OperatorAnswer.defaultProps = {
  value: null,
};

const RequiredMsg = ({ t }) => <div className={styles.error}>{t('required to fill')}</div>;

RequiredMsg.propTypes = {
  t: PropTypes.func.isRequired,
};

const RequiredPhotosMsg = ({ count, t }) => (
  <div className={styles.error}>
    {t('photo required')}: {count}
  </div>
);

RequiredPhotosMsg.propTypes = {
  count: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

const MinMaxMsg = ({ min, max, t }) => (
  <div className={styles.error}>
    {t('enter value from')}
    {' '}
    {min || ''}
    {' '}
    {t('to')}
    {' '}
    {max || ''}
  </div>
);

MinMaxMsg.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  t: PropTypes.func.isRequired,
};

MinMaxMsg.defaultProps = {
  min: null,
  max: null,
};

const getInvalidRequired = (result) => result.required && _.isNil(result.data);

const getInvalidRequiredPhotosCount = (result) => (
  result.photoRequired
  && (
    !result.photos
    || result.photos.length < result.photoCount
  )
);

const getInvalidMinMax = (result) => (
  (
    result.limits.min
    && (result.data || result.data === 0)
    && result.data < result.limits.min
  )
  || (
    result.limits.max
    && (result.data || result.data === 0)
    && result.data > result.limits.max
  )
);

export const getInvalid = (result, type) => {
  const invalidRequired = getInvalidRequired(result) && type !== 'PHOTO';
  const invalidRequiredPhotosCount = getInvalidRequiredPhotosCount(result);
  const invalidMinMax = getInvalidMinMax(result);
  const invalid = (
    invalidRequired
    || invalidRequiredPhotosCount
    || invalidMinMax
  );
  return invalid;
};

const Validator = ({ result, type }) => {
  const { t } = useTranslation();
  const invalidRequired = getInvalidRequired(result) && type !== 'PHOTO';
  const invalidRequiredPhotosCount = getInvalidRequiredPhotosCount(result);
  const invalidMinMax = getInvalidMinMax(result);
  return (
    <>
      { invalidRequired ? <RequiredMsg t={t} /> : null }
      { invalidRequiredPhotosCount ? (
        <RequiredPhotosMsg
          count={result.photoCount}
          t={t}
        />
      ) : null }
      { invalidMinMax ? (
        <MinMaxMsg
          min={result.limits.min}
          max={result.limits.max}
          t={t}
        />
      ) : null }
    </>
  );
};

Validator.propTypes = {
  result: PropTypes.shape({
    required: PropTypes.bool,
    photoRequired: PropTypes.bool,
    photoCount: PropTypes.number,
    data: PropTypes.any,
    variants: PropTypes.arrayOf(PropTypes.string),
    limits: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
    photos: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string,
      uploading: PropTypes.bool,
      needReupload: PropTypes.bool,
    })),
  }).isRequired,
  type: PropTypes.string.isRequired,
};

const AnswerControl = ({ question, updateQuestion }) => {
  const { t } = useTranslation();
  let control = null;
  const BOOL_ITEMS = [
    {
      title: t('yes'),
      key: 'true',
      value: true,
    },
    {
      title: t('no'),
      key: 'false',
      value: false,
    },
  ];
  switch (question.type) {
    case 'SELECT':
    case 'MULTICOMBO':
      control = (
        <FancySelect
          multi={question.type === 'MULTICOMBO'}
          data={getSelectItems(question.result?.variants ?? [])}
          value={question.result.data}
          onChange={updateQuestion}
          menuAlign={Position.BOTTOM_RIGHT}
        />
      );
      break;
    case 'BOOLEAN':
      control = (
        <SimpleSelect
          emptiable
          items={BOOL_ITEMS}
          value={question.result.data}
          onChange={updateQuestion}
          menuAlign={Position.BOTTOM_RIGHT}
        />
      );
      break;
    case 'TEXT':
      control = (
        <InputGroup
          fill
          type="text"
          value={question.result.data}
          onChange={(e) => updateQuestion(e.target.value)}
        />
      );
      break;
    case 'PRICE':
    case 'NUMBER':
    case 'INTEGER':
    case 'PERCENT':
      control = (
        <NumInput
          placeholder={`${t('enter')} ${t(NUMBER_TYPES_NAMES[question.type])}`}
          min={question.result.limits.min || 0}
          max={question.result.limits.max}
          value={question.result.data}
          onChange={updateQuestion}
        />
      );
      break;
    case 'PHOTO':
      control = <div>-</div>;
      break;
    case 'DATE':
      control = <LocalizedISODateInput value={question.result.data} onChange={updateQuestion} />;
      break;
    case 'ADDITION':
    case 'SUBTRACTION':
    case 'DIVISION':
    case 'MULTIPLICATION':
      control = <OperatorAnswer value={question.result.data} onChange={updateQuestion} type={question.type} />;
      break;
    default:
      break;
  }

  if (control) {
    return (
      <>
        {control}
        <Validator result={question.result} type={question.type} />
      </>
    );
  }

  return question.result.data || '';
};

AnswerControl.propTypes = {
  questionaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  question: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    result: PropTypes.shape({
      photos: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string,
        uploading: PropTypes.bool,
        needReupload: PropTypes.bool,
      })),
      required: PropTypes.bool,
      photoRequired: PropTypes.bool,
      photoCount: PropTypes.number,
      data: PropTypes.any,
      variants: PropTypes.arrayOf(PropTypes.string),
      limits: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
  }).isRequired,
  updateQuestion: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateQuestion: (data) => dispatch(updateQuestionAC(
    ownProps.questionaryId,
    ownProps.question.id,
    data,
  )),
});

export default connect(null, mapDispatchToProps)(AnswerControl);
