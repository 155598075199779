export const getSortDirection = (sort, field) => {
  if (sort.field === field) {
    if (sort.direction === 'asc') {
      return 'desc';
    }
  }
  return 'asc';
};

export const getColumn = ({
  sort,
  setSort,
}) => ({
  title,
  field,
  sortable,
  width,
  valueRenderer,
}) => ({
  title,
  path: [field],
  width: width || 100,
  valueRenderer: valueRenderer || ((x) => x),
  sortable: sortable !== false,
  sortDirection: sort.field === field ? sort.direction : undefined,
  onSort: () => setSort({
    field,
    direction: getSortDirection(sort, field),
  }),
});
