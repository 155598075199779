import React from 'react';
import PropTypes from 'prop-types';
import { Card, Elevation, HTMLTable, H5 } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n';
import * as utils from 'app/utils';

import Photos from './Photos';
import styles from './TaskResult.module.css';

function listTree(node, result, level) {
  node.level = level;
  result.push(node);
  for (const child of node.children) {
    listTree(child, result, level + 1);
  }
}

function prepareQuestions(questions) {
  const nodes = (
    questions
    .filter(q => q.type !== 'PHOTO' || q.parent_id)
    .map(q => ({ ...q, children: [] }))
  );
  const byId = _.fromPairs(nodes.map(q => [q.id, q]));

  for (const q of nodes) {
    const parent = byId[q.parent_id];
    console.assert(parent || !q.parent_id);
    if (parent) {
      parent.children.push(q);
    }
  }

  const result = [];
  for (const q of nodes) {
    if (!q.parent_id) {
      listTree(q, result, 0);
    }
  }

  return result;
}

function renderAnswer(answer) {
  if (_.isBoolean(answer)) {
    return answer ? i18n.t('yes') : i18n.t('no');
  }
  if (_.isArray(answer)) {
    return <Photos uuids={answer} />;
  }
  return answer;
}

const Equipment = React.memo(function Equipment({ equipment }) {
  const { t } = useTranslation();
  const questions = prepareQuestions(equipment.questions);
  const photoQuestion = equipment.questions.find(q => q.type === 'PHOTO' && !q.parent_id);
  const standalonePhotoUUIDs = photoQuestion?.answer;

  return (
    <Card className={styles.equipmentCard} elevation={Elevation.TWO}>
      <H5>{equipment.name}</H5>
      <table>
        <tbody>
          <tr>
            <td className={styles.infoRowLabel}>{t('type')}:</td>
            <td>{equipment.type_name}</td>
          </tr>

          <tr>
            <td className={styles.infoRowLabel}>Asset ID:</td>
            <td>{equipment.asset_id}</td>
          </tr>
        </tbody>
      </table>

      {!!standalonePhotoUUIDs?.length && <Photos uuids={standalonePhotoUUIDs} />}

      <HTMLTable>
        <thead>
          <tr>
            <th>{t('question')}</th>
            <th>{t('answer')}</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((q) => (
            <tr key={q.id}>
              <td style={{ paddingLeft: q.level * 15 }}>{q.text}</td>
              <td className={styles.tdCell}>{renderAnswer(q.answer)}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </Card>
  );
});

Equipment.propTypes = {
  equipment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type_name: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    asset_id: PropTypes.string.isRequired,
    questions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      parent_id: PropTypes.number,
      answer: PropTypes.any.isRequired,
    }).isRequired).isRequired,
  }).isRequired,
};

function EquipmentTab() {
  const equipment = utils.useSelectorMemo('task.data.equipment');
  return equipment.map((e) => <Equipment key={e.id} equipment={e} />);
}

export default EquipmentTab;
