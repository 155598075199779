import React from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'app/api';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import InputFilter from 'app/widgets/Filters/InputFilter';
import { AgencyContragentFilter } from 'app/widgets/Filters';

export default
function TradeNetworksListScreen() {
  const { t } = useTranslation();

  return (
    <Page title={t('tradeNetworks')}>
      <Grid path={api.TRADE_NETWORKS}>
        <Grid.Filter
          acceptor={AgencyContragentFilter}
          path="contragent_id__eq"
          multi={false}
          autoSelectFirst
        />

        <Grid.Filter
          acceptor={InputFilter}
          path="name__ilike"
          name={t('name')}
          placeholder={t('search by name')}
        />

        <Grid.Column path="contragent" title={t('contractor')} sortable />
        <Grid.Column path="name" title={t('commercial network')} sortable />
      </Grid>
    </Page>
  )
}
