import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { H2 } from '@blueprintjs/core';

import Page from 'app/widgets/Page';
import {
  fetchEvents as fetchEventsAC,
  setFilters as setFiltersAC,
  clearFilters as clearFiltersAC,
  setPagination as setPaginationAC,
  setFilterVisible as setFilterVisibleAC,
  exportData as exportDataAC,
} from 'app/state/actionCreators/events';
import { fetchRoles as fetchRolesAC } from 'app/state/actionCreators/roles';
import { hasRight } from 'app/state/reducers';
import { useCallbackWithDeps } from 'app/utils';
import { eventType } from 'app/proptyping';
import EventList from 'app/widgets/EventList';
import NewEventDialog from 'app/widgets/NewEventDialog';

import Filters from './Filters';
import ActionBar from './ActionBar';
import styles from './EventsScreen.module.css';

function openDialog({ setNewEvent, event }) {
  console.log('openDialog', event);
  setNewEvent({
    copying: !!event,
    event: {
      contragent_id: event?.tradepoint.contragent_id,
      tradepoint_id: event?.tradepoint.id,
      text: event?.detail.text,
      expired_time: event?.expired_time,
    },
  });
}

function handleDialogClose({ setNewEvent, fetchEvents, filters, pagination }, created) {
  setNewEvent(null);
  if (created) {
    fetchEvents(filters, pagination);
  }
}

const EventsScreen = ({
  events,
  totalItems,
  totalPages,
  pagination,
  filters,
  filtersVisible,
  fetching,
  error,
  fetchEvents,
  setFilters,
  clearFilters,
  setPagination,
  setFilterVisible,
  exportData,
  exportFetching,
  exportError,
  hideCreatePhotoTime,
  fetchRoles,
}) => {
  const { t } = useTranslation();

  useEffect(
    () => {
      if (filters.contragent_id__eq) {
        fetchEvents(filters, pagination);
      }
    },
    [filters, pagination, fetchEvents],
  );
  useEffect(() => { setFilterVisible(false); }, [setFilterVisible]);
  useEffect(() => { fetchRoles(); }, []);
  const hasFilters = Object.values(filters).filter((x) => x).length > 0;

  const [newEvent, setNewEvent] = useState(null);
  const onOpenDialog = useCallback(() => openDialog({ setNewEvent }), [setNewEvent]);
  const onCopyEvent = useCallback(event => openDialog({ event, setNewEvent }), [setNewEvent]);
  const onCloseDialog = useCallbackWithDeps(handleDialogClose, { setNewEvent, fetchEvents, filters, pagination });
  const onExportClick = useCallback(() => exportData(filters), [filters]);
  const onDeleteEvent = useCallback(() => fetchEvents(filters, pagination), [filters, pagination]);

  return (
    <Page>
      <div className={styles.header}>
        <H2>{t('events pinning')}</H2>
      </div>
      <ActionBar
        hasFilters={hasFilters}
        filtersVisible={filtersVisible}
        pagination={pagination}
        totalPages={totalPages}
        totalItems={totalItems}
        setPagination={setPagination}
        setFilterVisible={setFilterVisible}
        onOpenDialog={onOpenDialog}
        exportFetching={exportFetching}
        exportError={exportError}
        onExportClick={onExportClick}
      />
      <Filters
        filters={filters}
        filtersVisible={filtersVisible}
        setFilters={(f) => {
          if (_.isEqual(f, filters)) {
            return;
          }
          setPagination({ ...pagination, currentPage: 1 });
          setFilters(f);
        }}
        clearFilters={() => {
          setPagination({ ...pagination, currentPage: 1 });
          clearFilters();
        }}
      />
      <EventList
        events={events}
        fetching={fetching}
        error={error}
        onCopyEvent={onCopyEvent}
        onDeleteEvent={onDeleteEvent}
        hideCreatePhotoTime={hideCreatePhotoTime}
      />
      {newEvent && (
        <NewEventDialog
          copying={newEvent.copying}
          initialData={newEvent.event}
          onClose={onCloseDialog}
        />
      )}
    </Page>
  );
};

EventsScreen.propTypes = {
  events: PropTypes.arrayOf(eventType).isRequired,
  totalItems: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
  }).isRequired,
  filters: PropTypes.shape({
    contragent_id__eq: PropTypes.number,
    address__like: PropTypes.string,
    expired_time__from: PropTypes.instanceOf(Date),
    expired_time__to: PropTypes.instanceOf(Date),
    end_time__from: PropTypes.instanceOf(Date),
    end_time__to: PropTypes.instanceOf(Date),
    status__eq: PropTypes.string,
    network__in: PropTypes.arrayOf(PropTypes.string),
    tradepoint_id__eq: PropTypes.number,
  }).isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  fetchEvents: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  setFilterVisible: PropTypes.func.isRequired,
  exportData: PropTypes.func.isRequired,
  exportFetching: PropTypes.bool.isRequired,
  exportError: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.bool,
  ]),
  hideCreatePhotoTime: PropTypes.bool.isRequired,
  fetchRoles: PropTypes.func.isRequired,
};

EventsScreen.defaultProps = {
  error: null,
  exportError: null,
};

const mapStateToProps = (state) => ({
  events: state.events.events,
  totalItems: state.events.totalItems,
  totalPages: state.events.totalPages,
  pagination: state.events.pagination,
  filters: state.events.filters,
  filtersVisible: state.events.filtersVisible,
  fetching: state.events.fetching,
  error: state.events.error,
  exportFetching: state.events.exportFetching,
  exportError: state.events.exportError,
  hideCreatePhotoTime: hasRight(state, 'webapp_contragent_hide_create_photo_time'),
});

const mapDispatchToProps = (dispatch) => ({
  fetchEvents: (filters, pagination) => dispatch(fetchEventsAC(filters, pagination)),
  setFilters: (filters) => dispatch(setFiltersAC(filters)),
  clearFilters: () => dispatch(clearFiltersAC()),
  setPagination: (pagination) => dispatch(setPaginationAC(pagination)),
  setFilterVisible: (visible) => dispatch(setFilterVisibleAC(visible)),
  exportData: (filters) => dispatch(exportDataAC(filters)),
  fetchRoles: () => dispatch(fetchRolesAC()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsScreen);
