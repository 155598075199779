export const EVENT_STATUS_MAP = {
  NEW: {
    label: 'new',
    color: 'rgba(92, 112, 128, 0.6)',
  },
  SKIPPED: {
    label: 'failed to execute',
    color: '#c23030',
  },
  COMPLETED: {
    label: 'completed',
    color: '#0f9960',
  },
};

export default EVENT_STATUS_MAP;
