import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  H2,
  NonIdealState,
  Spinner,
  Breadcrumb,
  Breadcrumbs,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { kpiTotalType } from 'app/proptyping';
import Page from 'app/widgets/Page';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import { AgencyContragentFilter } from 'app/widgets/Filters';
import {
  setContragent as setContragentAC,
  setDates as setDatesAC,
  fetchKpi as fetchKpiAC,
  fetchKpiTotals as fetchKpiTotalsAC,
  fetchChiefs as fetchChiefsAC,
} from 'app/state/actionCreators/kpi';
import UserTable from './UserTable';
import TeamTable from './TeamTable';
import { Charts } from './Charts';
import styles from './KpiScreen.module.css';

const BreadcrumbRenderer = ({
  id,
  fullname,
  selected,
  contragentId,
  topRoute,
}) => (
  selected ? (
    <Breadcrumb current>{fullname}</Breadcrumb>
  ) : (
    <Link to={topRoute ? '/reports/kpi' : `/reports/kpi/contragent/${contragentId}/team/${id}`}>
      <Breadcrumb>{fullname}</Breadcrumb>
    </Link>
  )
);
BreadcrumbRenderer.propTypes = {
  id: PropTypes.number.isRequired,
  fullname: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  contragentId: PropTypes.number.isRequired,
  topRoute: PropTypes.bool.isRequired,
};

const KpiScreen = ({
  currentUserId,
  match,
  dateFrom,
  dateTo,
  totals,
  chiefs,
  fetching,
  error,
  contragentId,
  setContragent,
  setDates,
  fetchChiefs,
  fetchTotals,
  fetchKpi,
}) => {
  const { t } = useTranslation();
  const isTeam = (
    match.path === '/reports/kpi'
    || match.path === '/reports/kpi/contragent/:contragentId/team/:id'
  );
  const isUser = match.path === '/reports/kpi/contragent/:contragentId/user/:id';
  const title = isTeam ? t('kpi by team') : t('kpi user');
  const userId = match.params.id ? parseInt(match.params.id, 10) : currentUserId;
  const routeContragentId = (
    match.params.contragentId
      ? parseInt(match.params.contragentId, 10)
      : null
  );
  useEffect(() => {
    if (routeContragentId) {
      setContragent(routeContragentId);
    }
  }, [routeContragentId]);
  useEffect(() => {
    if (userId && contragentId) {
      fetchChiefs(userId, contragentId);
    }
  }, [userId, contragentId, fetchChiefs]);
  useEffect(() => {
    if (userId && contragentId && dateFrom && dateTo) {
      fetchTotals({
        userId,
        contragentId,
        dateFrom,
        dateTo,
      });
      fetchKpi({
        parentId: isTeam ? userId : undefined,
        userId: isUser ? userId : undefined,
        contragentId,
        dateFrom,
        dateTo,
      });
    }
  }, [userId, contragentId, dateFrom, dateTo, fetchTotals, fetchKpi]);

  return (
    <Page flexible>
      <H2>{title}</H2>
      <Breadcrumbs
        items={chiefs.map((x) => ({
          topRoute: x.user_id === currentUserId,
          contragentId,
          selected: x.user_id === userId,
          id: x.user_id,
          ...x,
        }))}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <div className={styles.container}>
        <div className={styles.filters}>
          <DaterangeFilter
            name={t('period')}
            value={[dateFrom, dateTo]}
            onChange={setDates}
            shortcuts="months"
          />
          <AgencyContragentFilter
            disabled={!!routeContragentId}
            value={contragentId}
            onChange={setContragent}
          />
        </div>
        { fetching && <Spinner />}
        { error && <NonIdealState title={t('error')} description={error.message} icon="error" />}
        { !fetching && !error && (
          <>
            <Charts totals={totals} />
            {isTeam && <TeamTable t={t} />}
            {isUser && <UserTable t={t} />}
          </>
        )}
      </div>
    </Page>
  );
};

KpiScreen.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
  dateFrom: PropTypes.instanceOf(Date).isRequired,
  dateTo: PropTypes.instanceOf(Date).isRequired,
  chiefs: PropTypes.arrayOf(PropTypes.shape({
    user_id: PropTypes.number,
    fullname: PropTypes.string,
  })).isRequired,
  totals: kpiTotalType,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  contragentId: PropTypes.number,
  setContragent: PropTypes.func.isRequired,
  setDates: PropTypes.func.isRequired,
  fetchChiefs: PropTypes.func.isRequired,
  fetchTotals: PropTypes.func.isRequired,
  fetchKpi: PropTypes.func.isRequired,
};

KpiScreen.defaultProps = {
  totals: null,
  error: null,
  contragentId: null,
};

const mapStateToProps = (state) => ({
  currentUserId: state.user.user.id,
  kpi: state.kpi.kpi,
  totals: state.kpi.totals,
  chiefs: state.kpi.chiefs,
  fetching: (
    state.kpi.fetching
    || state.kpi.totalsFetching
    || state.kpi.userFetching
  ),
  error: (
    state.kpi.error
    || state.kpi.totalsError
    || state.kpi.chiefsError
  ),
  dateFrom: state.kpi.dateFrom,
  dateTo: state.kpi.dateTo,
  contragentId: state.kpi.contragentId,
});

const mapDispatchToProps = (dispatch) => ({
  setContragent: (contragentId) => dispatch(setContragentAC(contragentId)),
  setDates: ([dateFrom, dateTo]) => dispatch(setDatesAC({ dateFrom, dateTo })),
  fetchChiefs: (id, contragentId) => dispatch(fetchChiefsAC(id, contragentId)),
  fetchTotals: ({
    userId,
    contragentId,
    dateFrom,
    dateTo,
  }) => dispatch(fetchKpiTotalsAC({
    userId,
    contragentId,
    dateFrom,
    dateTo,
  })),
  fetchKpi: ({
    userId,
    parentId,
    contragentId,
    dateFrom,
    dateTo,
  }) => dispatch(fetchKpiAC({
    userId,
    parentId,
    contragentId,
    dateFrom,
    dateTo,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(KpiScreen);
