import React from 'react';
import PropTypes from 'prop-types';
import { Button, ControlGroup, InputGroup, Intent } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import * as questionary from 'app/state/actionCreators/questionary';
import { dispatch } from 'app/state';
import FancySelect from 'app/widgets/FancySelect';

import styles from './QuestionaryEditScreen.module.css';

const NEW_VARIANT_PLACEHOLDER = Symbol('NEW_VARIANT_PLACEHOLDER');

function handleChange({ index }, e) {
  dispatch(questionary.setEditQuestionVariant({ index, variant: e.target.value }));
}

function handleRemove({ index }) {
  dispatch(questionary.removeEditQuestionVariant(index));
}

function handleChangeDependendQuestions({ index }, dependendQuestions) {
  dispatch(questionary.setEditQuestionDependendQuestions({ index, dependendQuestions }));
}

const Variant = React.memo(withTranslation()(({ label, index, intent, t }) => {
  const question = utils.useSelectorMemo((state) => state.questionary.editQuestion);
  const editable = question.field_type !== 'BOOLEAN';
  const onChange = utils.useCallbackWithDeps(handleChange, { index });
  const onClickRemove = utils.useCallbackWithDeps(handleRemove, { index });
  const onChangeDependendQuestions = utils.useCallbackWithDeps(handleChangeDependendQuestions, { index });

  let warning;
  if (editable) {
    if (question.variants?.some((v, i) => i !== index && v === label)) {
      warning = t('recurring variant');
    }
    if (!label) {
      warning = t('empty variant');
    }
  }

  return (
    <ControlGroup fill className={styles.answerVariant}>
      {editable && label !== NEW_VARIANT_PLACEHOLDER && <Button minimal icon="cross" onClick={onClickRemove} />}
      {editable && (
        <InputGroup
          value={label === NEW_VARIANT_PLACEHOLDER ? '' : label}
          onChange={onChange}
          placeholder={`${t('enter an answer')}...`}
          title={warning}
          intent={warning ? Intent.WARNING : intent}
        />
      )}
      {!editable && (
        <span className={styles.booleanVariant}>
          {label}:
        </span>
      )}
      {label !== NEW_VARIANT_PLACEHOLDER && (
        <FancySelect
          fill
          className={styles.dependendQuestionsSelect}
          placeholder={t('link to question')}
          data={question.questionSelectItems}
          value={question.dependendQuestions[index]}
          onChange={onChangeDependendQuestions}
        />
      )}
    </ControlGroup>
  );
}));

Variant.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol]).isRequired,
  index: PropTypes.number.isRequired,
  intent: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

const VariantsInput = React.memo(withTranslation()(({ intent, t }) => {
  const question = utils.useSelectorMemo((state) => state.questionary.editQuestion);
  const variants = (
    question.field_type === 'BOOLEAN'
      ? [t('yes'), t('no')]
      : [...(question.variants ?? []), NEW_VARIANT_PLACEHOLDER]
  );

  return variants.map((v, i) => <Variant key={i} label={v} index={i} intent={intent} />);
}));

VariantsInput.propTypes = {
  intent: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default VariantsInput;
