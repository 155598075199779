import React from 'react';
import PropTypes from 'prop-types';

import { LocalizedISODateRangeInput } from 'app/widgets/LocalizedDateInput';

import styles from './Filters.module.css';

function DaterangeFilter({ name, title, withTime, ...props }) {
  return (
    <div className={styles.filtersGroup} title={title}>
      <div className={styles.filtersLabel}>
        {name}:
      </div>
      <LocalizedISODateRangeInput
        className={styles.filtersDates}
        inclusiveEnd={withTime}
        {...props}
      />
    </div>
  );
}

DaterangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  withTime: PropTypes.bool,
};

DaterangeFilter.defaultProps = {
  value: null,
  withTime: false,
};

export default React.memo(DaterangeFilter);
