import * as ACTIONS from 'app/state/actions';
import { getContragents } from 'app/api';
import { $setIn } from './index';

export const fetchContragents = () => async (dispatch) => {
  dispatch({ type: ACTIONS.CONTRAGENTS_FETCH_START });
  try {
    const data = await getContragents();
    dispatch({ type: ACTIONS.CONTRAGENTS_FETCH_DONE, data });
    return data;
  } catch (error) {
    dispatch({ type: ACTIONS.CONTRAGENTS_FETCH_ERROR, error });
  }
  return undefined;
};

export const openCreateDialog = () => (dispatch, getState) => {
  dispatch($setIn('contragents', {
    openCreateDialog: {
      isOpen: true,
    }
  }));
};

export const closeDialog = () => $setIn(`contragents.openCreateDialog`, { isOpen: false });

export default fetchContragents;
