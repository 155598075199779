import i18n from 'i18n';
import { getAuditTradepoints } from 'app/api';
import * as ACTIONS from 'app/state/actions';
import { showError } from 'app/widgets/toaster';

import { $setIn, resolveAborting } from './index';

export const fetchTradepointsList = (filters) => async (dispatch, getState) => {
  try {
    const { result, aborted } = await resolveAborting(
      getAuditTradepoints(filters),
      'tradepointsAudit',
      dispatch,
      getState,
    );
    if (aborted) {
      return;
    }

    dispatch($setIn('tradepointsAudit', {
      error: null,
      data: result.data,
    }));
  } catch (error) {
    dispatch($setIn('tradepointsAudit', {
      error,
    }));
    showError(error.message || i18n.t('error get list of tt'));
  }
};

export const setFilters = (filters) => (
  { type: ACTIONS.TRADEPOINT_AUDIT_LIST_SET_FILTERS, filters }
);

export const clearFilters = () => (
  { type: ACTIONS.TRADEPOINT_AUDIT_LIST_CLEAR_FILTERS }
);
