import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Intent, Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import moment from 'moment';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import * as api from 'app/api';
import * as utils from 'app/utils';
import toaster from 'app/widgets/toaster';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import ComboFilter from 'app/widgets/Filters/ComboFilter';
import InputFilter from 'app/widgets/Filters/InputFilter';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import {
  AgencyFilter,
  ContragentFilter,
  TradeNetworkFilter,
  BranchFilter,
  UserFilter,
  TradepointProjectFilter,
} from 'app/widgets/Filters';
import { getState } from 'app/state';
import * as selectors from 'app/state/reducers';
import * as schedule from 'app/state/actionCreators/schedule';

import styles from './ScheduleScreen.module.css';
import * as contextMenu from './contextMenu';
import CreateVisitDialog from './dialogs/CreateVisitDialog';
import CreateAuditDialog from './dialogs/CreateAuditDialog';
import CompleteVisitDialog from './dialogs/CompleteVisitDialog';
import EditDialog from './dialogs/EditDialog';

const INITIAL_SORT = { field: 'code', direction: 'asc' };

async function fetchRescheduleReasons({ setRescheduleReasons }) {
  try {
    const response = await api.getReferences({ path: api.RESCHEDULE_REASONS_REFERENCES, filters: {} });
    setRescheduleReasons(response.data);
  } catch (e) {
    toaster.showError(`${i18n.t('errorFetchingRescheduleReasons')}: ${e.message}`);
  }
}

function renderPlanned(dummy, rowData) {
  return _.keys(rowData.tasks).length;
}

function renderDone(dummy, rowData) {
  return _.values(rowData.tasks).filter((t) => t.status === 'COMPLETED').length;
}

function renderDateColumnTitle(date) {
  const m = moment(date);
  return (
    <div title={m.format('LL')}>
      <div>{m.format('DD')}</div>
      <div className={utils.classNames('bp4-text-disabled', styles.dateColumnHeaderWeekday)}>{m.format('dd')}</div>
    </div>
  );
}

function handleSelectDownloadItem({ id }) {
  const params = { type: id };
  const gridState = getState().grids[api.SCHEDULE];
  switch (id) {
    case 'SCHEDULE_BY_USERS_WITH_PLAN_LEAD_TIME':
      params.show_plan_lead_time = true;
      params.type = 'SCHEDULE_BY_USERS';
      // fallthrough
    case 'SCHEDULE_BY_USERS':
    case 'SCHEDULE_BY_TRADEPOINTS':
      params.filter = gridState.filters;
      params.sort = gridState.sort;
      break;

    case 'TRADEPOINTS_WITHOUT_VISITS_REPORT':
      params.filter = _.pick(
        gridState.filters,
        'contragent_id__eq',
        'address__fuzzy_like',
        'branch_id__in',
        'network_id__in',
        'code__ilike',
        'name__ilike',
        'ir_code__ilike',
      );
      params.filter.visit_ir_type__ilike = gridState.filters.ir_type__ilike;
      params.filter.date__ge = gridState.filters.date__between[0];
      break;


    default:
      console.error(`unimplemented download type: ${id}`);
  };

  utils.tryCreateBackgroundExportTask('static-reports', params);
}

const DOWNLOAD_TYPE2I18N_KEY = {
  SCHEDULE_BY_USERS: 'visiting schedule',
  SCHEDULE_BY_USERS_WITH_PLAN_LEAD_TIME: 'scheduleWithPlanLeadTime',
  SCHEDULE_BY_TRADEPOINTS: 'scheduleByPos',
  TRADEPOINTS_WITHOUT_VISITS_REPORT: 'posWithoutVisits',
};

function renderDownloadItem(item, props) {
  return (
    <MenuItem
      icon="download"
      disabled={item.disabled}
      key={item.id}
      text={item.label}
      onClick={props.handleClick}
      onFocus={props.handleFocus}
      roleStructure="listoption"
    />
  );
}

function renderDateCell(rescheduleReasons, dummy, rowData, isoDate) {
  const task = rowData.tasks[isoDate];
  const rescheduleCode = rowData.reschedules[isoDate];
  if (!task && rescheduleCode) {
    const text = rescheduleCode.length > 2 ? `${rescheduleCode.substring(0, 2)}…` : rescheduleCode;
    const reason = rescheduleReasons.find(r => (
      r.contragent_id === rowData.contragent_id
      && r.code === rescheduleCode
    ));
    return <span title={reason?.label}>{text}</span>;
  }

  if (!task) {
    return null;
  }

  if (task.status === 'COMPLETED') {
    return (
      <Icon
        htmlTitle={task.unscheduled ? i18n.t('completedUnscheduled') : i18n.t('completedScheduled')}
        icon="full-circle"
        iconSize={10}
        intent={Intent.PRIMARY}
        className={utils.classNames(
          styles.dateColumnIcon,
          task.unscheduled && styles.dateColumnUnscheduled,
        )}
      />
    );
  }

  const inwork = task.status === 'INWORK';
  return (
    <Icon
      htmlTitle={inwork ? i18n.t('downloaded') : i18n.t('a new')}
      icon="circle"
      iconSize={10}
      intent={Intent.PRIMARY}
      className={utils.classNames(
        styles.dateColumnIcon,
        inwork && styles.dateColumnIconInwork,
        task.unscheduled && styles.dateColumnUnscheduled,
      )}
    />
  );
}

export default
function ScheduleScreen() {
  const { t } = useTranslation();
  const showAgencyFilter = RRU.useSelector(selectors.canViewAgencies);

  const [rescheduleReasons, setRescheduleReasons] = React.useState([]);
  RRU.useAsyncEffectOptions(fetchRescheduleReasons, { setRescheduleReasons });
  const renderDateCellBound = RRU.useCallbackArgs(renderDateCell, rescheduleReasons);

  const filters = RRU.useSelector(`grids.${api.SCHEDULE}.filters`);
  const taskType = filters?.task_type__eq ?? 'merchandising';

  const canEditShedule = RRU.useSelector(selectors.canEditShedule);
  const onClickCreateVisit = utils.useDispatchCallback(schedule.openCreateVisitDialog);
  const canCreateAudit = RRU.useSelector(selectors.canCreateAudit);
  const onClickCreateAudit = utils.useDispatchCallback(schedule.openCreateAuditDialog);

  const dates = RRU.useSelector(contextMenu.scheduleDates);

  const TASK_TYPE_ITEMS = React.useMemo(
    () => [
      { value: 'merchandising', title: t('visit') },
      { value: 'audit', title: t('audit') },
    ],
    [t],
  );

  const downloadItems = React.useMemo(
    () => _.toPairs(DOWNLOAD_TYPE2I18N_KEY).map(([id, key]) => ({
      id,
      label: t(key),
      disabled: id === 'TRADEPOINTS_WITHOUT_VISITS_REPORT' && !filters?.contragent_id__eq,
    })),
    [t, !filters?.contragent_id__eq],
  );

  return (
    <Page flexible title={t('visiting schedule')}>
      <Grid
        path={api.SCHEDULE}
        initialSort={INITIAL_SORT}
        getContextMenu={contextMenu.getContextMenu}
      >
        {showAgencyFilter && (
          <Grid.Filter
            acceptor={AgencyFilter}
            path="agency_id__eq"
            multi={false}
            autoSelectFirst
          />
        )}
        <Grid.Filter
          acceptor={ContragentFilter}
          path="contragent_id__eq"
          dependsOn="agency_id__eq"
          multi={false}
          autoSelectFirst
          clearable={showAgencyFilter}
        />
        <Grid.Filter
          acceptor={DaterangeFilter}
          path="date__between"
          name={t('visit dates')}
          shortcuts="months"
          defaultValue={[new Date(), moment().add(1, 'month').toDate()]}
        />
        <Grid.Filter
          acceptor={ComboFilter}
          path="task_type__eq"
          name={t('type of task')}
          items={TASK_TYPE_ITEMS}
          defaultValue="merchandising"
        />
        <Grid.Filter
          acceptor={UserFilter}
          path="manager_id__eq"
          name={t('supervisor')}
          dependsOn="contragent_id__eq"
          filters={{ roles__overlap: [taskType === 'merchandising' ? 'supervisor' : 'teamleader'] }}
          multi={false}
          clearable
        />
        <Grid.Filter
          acceptor={UserFilter}
          path="user_id__eq"
          name={t('executor')}
          dependsOn="contragent_id__eq manager_id__eq?"
          filters={{ roles__overlap: [taskType === 'merchandising' ? 'merch' : 'supervisor'] }}
          multi={false}
          clearable
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="address__fuzzy_like"
          name={t('search by address')}
        />
        <Grid.Filter
          acceptor={BranchFilter}
          path="branch_id__in"
          dependsOn="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={TradeNetworkFilter}
          path="network_id__in"
          dependsOn="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="code__ilike"
          name={t('search by point code')}
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="name__ilike"
          name={t('name of tt')}
          placeholder={t('search by name of tt')}
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="ir_code__ilike"
          name={t('extra code tt for ir')}
          placeholder={t('search by extra code tt for ir')}
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="ir_type__ilike"
          name={t('type of visit for ir')}
          placeholder={t('search by type of visit for ir')}
        />
        <Grid.Filter
          acceptor={TradepointProjectFilter}
          path="project_id__in"
          dependsOn="contragent_id__eq"
        />

        <Grid.Actions>
          {canEditShedule && (
            <Button
              onClick={onClickCreateVisit}
              icon="add"
              text={t('create visit')}
              intent={Intent.SUCCESS}
            />
          )}
          {canCreateAudit && (
            <Button
              onClick={onClickCreateAudit}
              icon="add"
              text={t('create a joint audit')}
              intent={Intent.SUCCESS}
            />
          )}
          <Select
            items={downloadItems}
            itemRenderer={renderDownloadItem}
            onItemSelect={handleSelectDownloadItem}
            filterable={false}
            popoverProps={{ minimal: true }}
          >
            <Button
              minimal
              rightIcon="caret-down"
              text={t('download')}
              intent={Intent.SUCCESS}
            />
          </Select>
        </Grid.Actions>

        <Grid.Column path="contragent" title={t('contractor')} sortable width={115} />
        <Grid.Column path="code" title={t('point code')} sortable width={115} />
        <Grid.Column path="name" title={t('name of tt')} sortable width={115} />
        <Grid.Column path="address" title={t('address')} sortable width={200} />
        <Grid.Column path="branch" title={t('affiliate')} sortable width={115} />
        <Grid.Column path="network" title={t('network')} sortable width={115} />
        <Grid.Column path="ir_code" title={t('extra code tt for ir')} sortable width={115} />
        <Grid.Column path="ir_type" title={t('type of visit for ir')} sortable width={115} />
        <Grid.Column path="manager" title={t('supervisor')} sortable width={115} />
        <Grid.Column path="manager_phone" title={t('supervisor phone')} sortable width={125} />
        <Grid.Column path="user" title={t('executor')} sortable width={115} />
        <Grid.Column path="user_phone" title={t('executor phone')} sortable width={125} />
        <Grid.Column path="planned" title={t('established')} valueRenderer={renderPlanned} sortable width={78} />
        <Grid.Column path="done" title={t('in fact')} valueRenderer={renderDone} sortable width={78} />
        {dates.map(date => (
          <Grid.Column
            key={utils.ISODate(date)}
            path={utils.ISODate(date)}
            className={styles.dateColumn}
            title={renderDateColumnTitle(date)}
            width={30}
            valueRenderer={renderDateCellBound}
          />
        ))}
      </Grid>

      <CreateVisitDialog />
      <CreateAuditDialog />
      <CompleteVisitDialog />
      <EditDialog />
    </Page>
  );
}
