import moment from 'moment';

import { makeEmptyReducer } from 'app/state/reducers/utils';

const INITIAL_STATE = {
  filters: {
    end_time__between: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
  },
  filtersValid: false,
  settings: {
    grouping: ['branch', 'date', 'tradepoint_code'],
    naming: ['tradepoint_code', 'date'],
    equipment: false,
  },
  fetching: false,
};

export default makeEmptyReducer(INITIAL_STATE);
