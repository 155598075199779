import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Elevation, Spinner, NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import User from './User';
import styles from './UserEditScreen.module.css';

export default React.memo(() => {
  const { fetching, error, user } = useSelector((state) => state.teamUser);
  const { t } = useTranslation();

  if (fetching) {
    return <Spinner />;
  }

  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }

  if (!user) {
    return <NonIdealState title={t('empty')} description={t('user is not found')} icon="list" />;
  }

  return (
    <Card elevation={Elevation.TWO} className={styles.card}>
      <User />
    </Card>
  );
});
