import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

export const INITIAL_STATE = {
  data: null,
  fetching: false,
  error: null,
  deleting: false,
  deleteError: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.TASK_CLEAR:
      return INITIAL_STATE;
    case ACTIONS.TASK_FETCH_START:
      return _.assign(state, { fetching: true, error: null });
    case ACTIONS.TASK_FETCH_ERROR:
      return _.assign(state, { fetching: false, error: action.error });

    case ACTIONS.TASK_FETCH_DONE:
    {
      const inTask = action.task;
      const task = {
        ...action.task,
        endDate: new Date(inTask.endDate && inTask.endDate.slice(0, -1)),
        endToDate: new Date(inTask.endToDate),
        startDate: new Date(inTask.startDate && inTask.startDate.slice(0, -1)),
        startFromDate: new Date(inTask.startFromDate),
        photos: action.task.photos.map((photo) => ({
          ...photo,
          date: photo.date ? new Date(photo.date) : null,
          create_photo_time: photo.create_photo_time ? new Date(photo.create_photo_time) : null,
        })),
      };
      return _.assign(state, { fetching: false, error: null, data: task });
    }
    case ACTIONS.TASK_DELETE_START:
      return _.assign(state, { deleting: true });
    case ACTIONS.TASK_DELETE_DONE:
      return _.assign(state, { deleting: false, data: null });
    case ACTIONS.TASK_DELETE_ERROR:
      return _.assign(state, { deleting: false, deleteError: action.error });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getPhotoTimeStr = ({ photo, hideTime }) => {
  if (photo.create_photo_time) {
    if (hideTime) {
      return photo.create_photo_time.toLocaleDateString();
    }
    return photo.create_photo_time.toLocaleString();
  }
  return '-';
};

export const getTask = (state) => state.data;
export const getIsFetching = (state) => state.fetching;
export const getError = (state) => state.error;
