import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux-utils';

import { dispatch } from 'app/state';
import * as utils from 'app/utils';
import { checkInit, setSort, INITIAL_GRID_STATE } from 'app/state/actionCreators/questionaryMultiBinding';

import Table from 'app/widgets/Grid/Table';
import Pagination from './Pagination';
import styles from './QuestionaryBindingTradepointsScreen.module.css';

const init = (path) => {
  dispatch(checkInit({ path }));
};

const handleSortChange = ({ path, fetchFunc }, sort) => {
  dispatch(setSort(path, sort));
  dispatch(fetchFunc(path));
};

const SubordinateGrid = React.memo(({ path, fetchFunc, children, ...props }) => {
  const { t } = useTranslation();
  const gridProps = useSelector((state) => state.questionaryMultiBinding.grids[path] ?? INITIAL_GRID_STATE);
  const onSortChange = utils.useCallbackWithDeps(handleSortChange, { path, fetchFunc });
  const grids = utils.useSelectorMemo((state) => state.questionaryMultiBinding.grids);
  const data = (grids[path] && grids[path].data) || [];
  const hasData = !!data.length;

  useEffect(() => {
    init(path);
  }, []);

  return (
    <div className={styles.wrapper}>
      {hasData && (
        <div className={styles.actionsBar}>
          <Pagination
            path={path}
            fetchFunc={fetchFunc}
            fetchOptions={{ raw: false, transientFilters: [] }}
            showTotal={true}
            showItemsPerPage={true}
          />
        </div>
      )}
      
      <Table
        className={styles.table}
        data={data}
        setSort={onSortChange}
        t={t}
        {...gridProps}
      >
        {children}
      </Table>
    </div>
  );
});

SubordinateGrid.propTypes = {
  path: PropTypes.string.isRequired,
  fetchFunc: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SubordinateGrid;
