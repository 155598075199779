import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { H5, Icon, Spinner } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import KLADR_STATUS_MAP from 'app/config/kladrStatusMap';
import { getKladr } from 'app/api';

import styles from './TradepointScreen.module.css';

class KladrDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: !!props.id,
      kladr: null,
    };
    if (props.id) {
      this.fetchInitial();
    }
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) {
      this.fetch();
    }
  }

  async fetchInitial() {
    const { id } = this.props;
    const kladr = await getKladr(id);
    this.setState({ kladr, fetching: false });
  }

  async fetch() {
    const { id } = this.props;
    if (!id) {
      this.setState({ kladr: null });
      return;
    }

    this.setState({ fetching: true });
    const kladr = await getKladr(id);
    this.setState({ kladr, fetching: false });
  }

  render() {
    const { id, status, t } = this.props;
    const { fetching, kladr } = this.state;

    if (!id) {
      return null;
    }

    const kladrStatusInfo = KLADR_STATUS_MAP[status];

    const FIELDS = {
      id: t('ID'),
      province: t('region'),
      locality: t('city'),
      street: t('street'),
      house: t('house'),
      postal_code: t('postal code'),
    };

    return (
      <>
        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel} />
          <div className={styles.detailsValue}><H5>{t('KLADR')}</H5></div>
        </div>

        <div className={styles.detailsRow}>
          <div className={styles.detailsLabel}>{t('status')}:</div>
          <div className={styles.detailsValue}>
            {fetching && <Spinner size={Spinner.SIZE_SMALL} className={styles.spinnerIcon} />}
            {!fetching && kladrStatusInfo?.icon && (
              <Icon
                icon={kladrStatusInfo.icon}
                intent={kladrStatusInfo.intent}
              />
            )}
            &nbsp;
            {fetching ? `${t('loading')}...` : t(kladrStatusInfo?.label)}
          </div>
        </div>

        {!fetching && kladr && _.toPairs(FIELDS).map(([key, label]) => (
          <div className={styles.detailsRow} key={key}>
            <div className={styles.detailsLabel}>
              {label}
              :
            </div>
            <div className={styles.detailsValue}>{kladr[key] || '-'}</div>
          </div>
        ))}
      </>
    );
  }
}

KladrDetails.propTypes = {
  id: PropTypes.number,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
};

KladrDetails.defaultProps = {
  id: null,
  status: null,
};

export default withTranslation()(KladrDetails);
