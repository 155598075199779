import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  H2,
  Card,
  Elevation,
  Button,
  Intent,
  Position,
  Spinner,
  Switch,
  Classes,
  Icon,
  Toaster,
  Callout,
} from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import { AgencyContragentFilter } from 'app/widgets/Filters';

import {
  downloadReport as downloadReportAC,
  setFilters as setFiltersAC,
} from 'app/state/actionCreators/reports/bi';
import styles from './ReportBiScreen.module.css';

const ReportBiScreen = ({
  filters,
  fetching,
  downloadReport,
  setFilters,
  t,
}) => {
  const onContragentChange = (val) => setFilters({
    ...filters,
    contragent: val,
  });

  const downloadDisabled = (
    fetching
    || !filters.from_date
    || !filters.to_date
    || !filters.contragent
  );

  const onDateChange = (val) => setFilters({
    ...filters,
    from_date: val[0],
    to_date: val[1],
  });
  const onVisitsOnlyChange = () => setFilters({
    ...filters,
    visits_only: !filters.visits_only,
  });

  const toaster = React.useRef(null);

  const onError = (error) => {
    let message = '';
    if (error && error.response && error.response.status === 404) {
      message = t('no records found to generate report');
    } else if (error && error.response && error.response.status === 500) {
      message = t('report generation error');
    } else if (error.message) {
      message = error.message;
    } else {
      message = t('an error has occurred');
    }
    toaster.current.show({ intent: Intent.DANGER, message, icon: 'error' });
  };

  return (
    <Page>
      <div className={styles.header}>
        <H2>{t('report')} BI</H2>
      </div>
      <Card elevation={Elevation.TWO} className={styles.panel}>
        <div className={styles.filters}>
          <AgencyContragentFilter
            value={filters.contragent}
            onChange={onContragentChange}
          />
          <DaterangeFilter
            name={t('report dates')}
            value={[filters.from_date, filters.to_date]}
            onChange={onDateChange}
            shortcuts="months"
          />
          <Switch
            className={styles.switchFilter}
            label={t('reference only')}
            checked={!!filters.visits_only}
            onChange={onVisitsOnlyChange}
          />
          <Button
            onClick={() => { downloadReport(filters, onError); }}
            minimal
            disabled={downloadDisabled}
            icon={fetching ? <Spinner className={Classes.BUTTON_SPINNER} size={Icon.SIZE_LARGE} /> : 'download'}
            text={fetching ? t('report generating') : t('download')}
            intent={Intent.SUCCESS}
            className={styles.downloadButtom}
          />
        </div>
        {
          !filters.from_date
          && (
            <Callout intent={Intent.PRIMARY} className={styles.message}>
              {t('select the date of report and contractor')}.
            </Callout>
          )
        }
      </Card>
      <Toaster ref={toaster} position={Position.BOTTOM} />
    </Page>
  );
};

ReportBiScreen.propTypes = {
  filters: PropTypes.shape({
    contragent: PropTypes.number,
    from_date: PropTypes.instanceOf(Date),
    to_date: PropTypes.instanceOf(Date),
    visits_only: PropTypes.bool,
  }).isRequired,
  fetching: PropTypes.bool.isRequired,
  downloadReport: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.reports.bi.filters,
  fetching: state.reports.bi.fetching,
});

const mapDispatchToProps = (dispatch) => ({
  downloadReport: (filters, onError) => dispatch(downloadReportAC(filters, onError)),
  setFilters: (filters) => dispatch(setFiltersAC(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportBiScreen));
