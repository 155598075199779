import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dialog, Classes, Button, Intent, TextArea, Switch } from '@blueprintjs/core';
import moment from 'moment';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import { dispatch } from 'app/state';
import { newEvent, createEvent } from 'app/state/actionCreators/newEvent';
import {
  ContragentSelect,
  TradepointSelect,
  BranchSelect,
  TradeNetworkSelect,
  TradepointProjectSelect,
  TradepointFormatSelect,
} from 'app/widgets/ReferenceSelect';
import { LocalizedDateInput } from 'app/widgets/LocalizedDateInput';
import Form, { ReduxForm } from 'app/widgets/Form';

import styles from './NewEventDialog.module.css';

function maybeFetchScheduledDates({ setScheduledDates, tradepoint_ids }) {
  if (!tradepoint_ids?.length) {
    return;
  }

  const promise = api.getScheduledDates(tradepoint_ids);
  promise.then(dates => setScheduledDates(_.keys(dates)));
  return promise.cancel;
}

function makeDateInputModifiers({ scheduledDates }) {
  return {
    'scheduled-date': (date) => scheduledDates.includes(moment(date).format('YYYY-MM-DD')),
  };
}

function useDateInputModifiers({ tradepoint_ids }) {
  const [scheduledDates, setScheduledDates] = useState([]);
  RRU.useEffectOptions(maybeFetchScheduledDates, { setScheduledDates, tradepoint_ids });

  return RRU.useMemoOptions(makeDateInputModifiers, { scheduledDates });
}

function EventForm() {
  const { t } = useTranslation();
  const fetching = RRU.useSelector('newEvent.fetching');
  const event = RRU.useSelector('newEvent.event');

  const dateInputModifiers = useDateInputModifiers(event);

  const now = new Date();
  const minDate = event.default_expired_time < now ? event.default_expired_time : now;

  return (
    <ReduxForm className={styles.form} path="newEvent.event" disabled={fetching}>
      <Form.Item
        label={t('contractor')}
        name="contragent_id__eq"
        required
        acceptor={ContragentSelect}
        multi={false}
        autoSelectFirst
      />
      <Form.Item
        label={t('affiliate')}
        name="branch_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={BranchSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('network')}
        name="network_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={TradeNetworkSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('project')}
        name="project_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={TradepointProjectSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('format')}
        name="format_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={TradepointFormatSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('tradepoints')}
        name="tradepoint_ids"
        dependsOn="contragent_id__eq branch_id__eq? network_id__eq? project_id__eq? format_id__eq?"
        required
        acceptor={TradepointSelect}
      />
      <Form.Item
        label={t('period of execution')}
        name="expired_time"
        dependsOn="contragent_id__eq tradepoint_ids"
        required
        acceptor={LocalizedDateInput}
        className={styles.dateInput}
        minDate={minDate}
        modifiers={dateInputModifiers}
      />
      <Form.Item
        label={t('task description')}
        name="text"
        required
        acceptor={TextArea}
        placeholder={t('enter a description')}
        className={styles.input}
      />
      <Form.Item
        label={t('photo required')}
        name="photo_required"
        acceptor={Switch}
      />
    </ReduxForm>
  );
};

function initialize(initialData) {
  dispatch(newEvent(initialData));
  return () => dispatch(newEvent());
}

async function handleSave(onClose) {
  if (await dispatch(createEvent())) {
    onClose(true);
  }
}

const NewEventDialog = React.memo(function NewEventDialog({ copying, initialData, onClose }) {
  const { t } = useTranslation();
  const { fetching, event, eventInitial, eventValid } = RRU.useSelector('newEvent');
  const changed = !_.isEqual(
    _.omit(event, 'contragent_id__eq'),
    _.omit(eventInitial, 'contragent_id__eq'),
  );

  useEffect(() => initialize(initialData), []);
  const closeWithoutSave = useCallback(() => onClose(false), [onClose]);
  const onClickSave = RRU.useCallbackArgs(handleSave, onClose);

  const title = copying ? t('resuming event') : t('creating event');
  const icon = copying ? 'repeat' : 'add';
  const buttonText = copying ? t('resume') : t('create');

  return (
    <Dialog
      isOpen
      icon={icon}
      title={title}
      onClose={closeWithoutSave}
      enforceFocus={false}
      canOutsideClickClose={!changed}
    >
      <div className={Classes.DIALOG_BODY}>
        <EventForm t={t} />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={fetching}
            icon="cross"
            onClick={closeWithoutSave}
            text={t('cancel')}
          />
          <Button
            disabled={!eventValid}
            loading={fetching}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            onClick={onClickSave}
            text={buttonText}
          />
        </div>
      </div>
    </Dialog>
  );
});

NewEventDialog.propTypes = {
  copying: PropTypes.bool,
  initialData: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

NewEventDialog.defaultProps = {
  copying: false,
  initialData: null,
};

export default NewEventDialog;
