import _ from 'lodash';

import i18n from 'i18n';
import * as api from 'app/api';
import { ISODateTime } from 'app/utils';
import { showError } from 'app/widgets/toaster';

import { $set, $setIn, resolveAborting } from './index';

export const INITIAL_STATE = {
  data: [],
  totalItems: 0,
  fetching: true,
  currentRequestCancel: null,
  filters: {},
  sort: null,
  pagination: {
    currentPage: 1,
    itemsPerPage: 20,
  },
  error: null,
  selected: [],
};

export const checkInit = ({ path, filters, sort, pagination }) => (dispatch, getState) => {
  const { grids } = getState();
  if (!_.has(grids, path)) {
    dispatch($setIn(`grids.${path}`, {
      ...INITIAL_STATE,
      filters: filters ?? {},
      sort: sort ?? null,
      pagination: pagination ?? { currentPage: 1, itemsPerPage: 20 },
    }));
  }
};

const set = (path, changes) => (dispatch) => dispatch($setIn(`grids.${path}`, changes));

export const setPagination = (path, pagination) => set(path, { pagination });

export const resetPage = (path) => set(`${path}.pagination`, { currentPage: 1 });

export const setFilter = (path, key, value) => (dispatch, getState) => {
  const { grids } = getState();
  const { filters } = grids[path];
  const oldValue = filters[key];
  if (oldValue === value || (_.isNil(oldValue) && _.isNil(value))) {
    return false;
  }

  if (_.isNil(value)) {
    dispatch($setIn(`grids.${path}`, { filters: _.omit(filters, key) }));
  } else {
    dispatch($setIn(`grids.${path}`, { filters: { ...filters, [key]: value } }));
  }

  return true;
};

export const setFilters = (path, filters) => set(path, { filters });

export const setSort = (path, sort) => set(path, { sort });

const fetchAndSet = (path, options) => async (dispatch, getState) => {
  const { grids } = getState();
  const { sort, pagination } = grids[path];
  const filters = _.omit(grids[path].filters, options.transientFilters ?? []);

  const requestFunction = options.raw ? api.getRawGrid : api.getGrid;

  const { result, aborted } = await resolveAborting(
    requestFunction({ path, filters, sort, pagination }),
    `grids.${path}`,
    dispatch,
    getState,
    options.silent,
  );
  if (aborted) {
    return;
  }

  const { data, rowcount } = result;
  dispatch($setIn(`grids.${path}`, {
    data,
    totalItems: rowcount,
    selected: [],
  }));
};

export const fetch = (path, options = {}) => async (dispatch) => {
  try {
    await dispatch(fetchAndSet(path, options));
  } catch (error) {
    if (!options.silent) {
      dispatch($setIn(`grids.${path}`, { error }));
      showError(error.message || i18n.t('dataRetrievalError'));
    }
  }
};

export const update = (path, id, updateData) => async (dispatch, getState) => {
  dispatch($setIn(`grids.${path}`, { fetching: true }));
  const { grids } = getState();
  const { data } = grids[path];

  try {
    await api.updateGrid({ path, id, data: updateData });

    const changes = {
      [`grids.${path}.fetching`]: false,
    };
    if (id) {
      const index = data.findIndex((row) => row.id === id);
      if (index !== -1) {
        for (const [key, value] of _.toPairs(updateData)) {
          changes[`grids.${path}.data[${index}].${key}`] = value;
        }
      }
    }
    dispatch($set(changes));

    return true;
  } catch (error) {
    dispatch($setIn(`grids.${path}`, {
      fetching: false,
      error,
    }));

    showError(error.message || i18n.t('recordUpdateError'));

    return false;
  }
};

export const markUserTaskChecked = (id) => (dispatch, getState) => {
  const { grids, user } = getState();
  const { data } = grids[api.USER_TASK_CHECKS] ?? {};
  if (!data) {
    return;
  }

  const index = data.findIndex((row) => row.id === id);
  if (index === -1) {
    return;
  }

  dispatch($setIn(`grids.${api.USER_TASK_CHECKS}.data[${index}]`, {
    last_time: ISODateTime(new Date()),
    check_manager_fullname: user.user.fullName,
  }));
};

export const toggleTradepointsBindings = (refs) => (dispatch, getState) => {
  const data = getState().grids[api.TRADEPOINTS_BINDINGS]?.data;
  if (!data?.length) {
    return;
  }

  const changes = {};
  for (const { tradepointId, questionaryId } of refs) {
    const index = data.findIndex((row) => row.id === tradepointId);
    if (index === -1) {
      continue;
    }

    let questionaries = changes[index] ?? data[index].questionaries;
    if (questionaries[questionaryId]) {
      questionaries = _.omit(questionaries, questionaryId);
    } else {
      questionaries = { ...questionaries, [questionaryId]: {} };
    }
    changes[index] = questionaries;
  }

  dispatch($set(_.mapKeys(changes, (v, index) => `grids.${api.TRADEPOINTS_BINDINGS}.data[${index}].questionaries`)));
};

function getScheduleTask(state, taskId) {
  const { data } = state.grids[api.SCHEDULE];
  let row;
  let taskKey;
  for (const i of _.range(data.length)) {
    const item = data[i];
    for (const [key, task] of _.toPairs(item.tasks)) {
      if (task.id === taskId) {
        row = i;
        taskKey = key;
        break;
      }
    }

    if (taskKey) {
      break;
    }
  }

  return {
    row,
    taskKey,
    item: data[row],
  };
}

export const setScheduleTaskStatus = (taskId, status) => (dispatch, getState) => {
  const { row, taskKey } = getScheduleTask(getState(), taskId);
  dispatch($setIn(`grids.${api.SCHEDULE}.data[${row}].tasks.${taskKey}`, { status }));
};

export const deleteScheduleTask = (taskId) => (dispatch, getState) => {
  const { row, item, taskKey } = getScheduleTask(getState(), taskId);
  const tasks = _.omit(item.tasks, taskKey);
  if (_.isEmpty(tasks)) {
    dispatch(fetch(api.SCHEDULE));
  } else {
    dispatch($setIn(`grids.${api.SCHEDULE}.data[${row}]`, { tasks }));
  }
};

export const deleteFeedPhoto = (taskId, photoUUID) => (dispatch, getState) => {
  const { data } = getState().grids[api.FEED];

  const index = data.findIndex((t) => t.id === taskId);
  if (index === -1) {
    return;
  }

  const photos = data[index].photos.filter((p) => p.uuid !== photoUUID);
  dispatch($setIn(`grids.${api.FEED}.data[${index}]`, { photos }));
};
