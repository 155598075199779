import { Intent } from '@blueprintjs/core';
import { v4 as uuidv4 } from 'uuid';

import i18n from 'i18n';
import alerts from 'app/widgets/alerts';
import * as ACTIONS from 'app/state/actions';
import * as api from 'app/api';
import { transformPlanogramIn } from 'app/state/transform/planogram';
import { showError } from 'app/widgets/toaster';
import * as grids from 'app/state/actionCreators/grids';

export const fetchPlanogramAction = (id) => async (dispatch) => {
  dispatch({ type: ACTIONS.PLANOGRAM_FETCH_START });
  try {
    const planogram = await api.getPlanogram(id);
    dispatch({
      type: ACTIONS.PLANOGRAM_FETCH_DONE,
      payload: {
        planogram: transformPlanogramIn(planogram),
      },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.PLANOGRAM_FETCH_ERROR, error });
  }
};

export const uploadPhotoAction = (
  image,
  replaceUuid,
) => async (dispatch) => {
  if (image.size > 2097152) {
    showError(i18n.t('imageMaxSizeIs2mb'));
    return;
  }
  const imageUUID = uuidv4();
  dispatch({
    type: ACTIONS.PLANOGRAM_UPLOAD_PHOTO_START,
    payload: {
      uuid: imageUUID,
      replaceUuid,
    },
  });
  try {
    const response = await api.uploadPlanogramFile(image, imageUUID);
    dispatch({
      type: ACTIONS.PLANOGRAM_UPLOAD_PHOTO_DONE,
      payload: {
        uuid: imageUUID,
        key: response.key,
      },
    });
  } catch (error) {
    showError(error.message || i18n.t('photoSavingError'));
    dispatch({
      type: ACTIONS.PLANOGRAM_UPLOAD_PHOTO_ERROR,
      error,
      payload: {
        uuid: imageUUID,
      },
    });
  }
};

export const updatePlanogramAction = (planogram) => (
  { type: ACTIONS.PLANOGRAM_UPDATE_PLANOGRAM, payload: { planogram } }
);

export const newPlanogramAction = (planogram) => (
  { type: ACTIONS.PLANOGRAM_NEW_PLANOGRAM, payload: { planogram } }
);

export const removePhotoAction = (imageUuid) => ({
  type: ACTIONS.PLANOGRAM_REMOVE_PHOTO,
  payload: {
    uuid: imageUuid,
  },
});

export const exportData = (filters) => async (dispatch) => {
  dispatch({ type: ACTIONS.PLANOGRAMS_EXPORT_START });
  try {
    await api.exportPlanograms(filters);
    dispatch({ type: ACTIONS.PLANOGRAMS_EXPORT_DONE });
  } catch (error) {
    dispatch({ type: ACTIONS.PLANOGRAMS_EXPORT_ERROR, error });
  }
};

export const removePlanograms = (ids) => async (dispatch, getState) => {
  const state = getState();
  const { filters } = state.grids[api.PLANOGRAMS];

  const confirmed = await alerts.show({
    confirmButtonText: i18n.t('yes'),
    cancelButtonText: i18n.t('no'),
    icon: 'trash',
    intent: Intent.DANGER,
    content: (
      <>
        <div>{i18n.t('sure to delete planograms')}</div>
      </>
    ),
  });

  if (!confirmed) {
    return null;
  }

  try {
    await api.deactivatePlanograms({
      filter: {
        ...filters,
        id__in: ids,
      }
    });
  } finally {
    dispatch(grids.fetch(api.PLANOGRAMS));
  }
};
