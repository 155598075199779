import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Intent, FormGroup, InputGroup, TextArea, Checkbox } from '@blueprintjs/core';
import _ from 'lodash';

import { parseKladr } from 'app/api';
import { useRequest } from 'app/utils';
import { tradepointType } from 'app/proptyping';
import {
  BranchSelect,
  TradeNetworkSelect,
  TradepointProjectSelect,
  TradepointFormatSelect,
  TradepointTerritorySelect,
} from 'app/widgets/ReferenceSelect';
import { NumFormGroup } from 'app/widgets/NumInput';

import EditMap from './EditMap';
import styles from './TradepointEditScreen.module.css';
import KladrDetails from '../TradepointScreen/KladrDetails';

const TradepointDetails = React.memo(({
  tradepoint,
  latitudeInvalid,
  longitudeInvalid,
  irInvalid,
  cashboxCountInvalid,
  visitIntervalInvalid,
  update,
  t,
}) => (
  <div className={styles.details}>
    <FormGroup inline label={`${t('address')}:`} labelFor="tp-address" className={styles.detailsRow}>
      <TextArea
        id="tp-address"
        growVertically
        value={tradepoint.address}
        onChange={(e) => update({ address: e.target.value })}
      />
    </FormGroup>
    <FormGroup inline label={`${t('point code')}:`} labelFor="tp-code" className={styles.detailsRow}>
      <InputGroup
        id="tp-code"
        value={tradepoint.code}
        disabled
      />
    </FormGroup>
    <FormGroup inline label={`${t('extra point code')}:`} labelFor="tp-extended-code" className={styles.detailsRow}>
      <InputGroup
        id="tp-extended-code"
        value={tradepoint.extended_code}
        onChange={(e) => update({ extended_code: e.target.value })}
      />
    </FormGroup>
    <FormGroup inline label={`${t('extra retailer code')}:`} labelFor="rt-extended-code" className={styles.detailsRow}>
      <InputGroup
        id="rt-extended-code"
        value={tradepoint.network_extended_code}
        onChange={(e) => update({ network_extended_code: e.target.value })}
      />
    </FormGroup>
    <FormGroup inline label={`${t('affiliate')}:`} className={styles.detailsRow}>
      <BranchSelect
        fill
        multi={false}
        clearable
        value={tradepoint.branch_id}
        onChange={(value) => update({ branch_id: value })}
        filters={{ contragent_id__eq: tradepoint.contragent_id }}
      />
    </FormGroup>
    <FormGroup inline label={`${t('city')}:`} labelFor="tp-city" className={styles.detailsRow}>
      <InputGroup
        id="tp-city"
        value={tradepoint.city}
        onChange={(e) => update({ city: e.target.value })}
      />
    </FormGroup>
    <FormGroup inline label={`${t('territory')}:`} className={styles.detailsRow}>
      <TradepointTerritorySelect
        fill
        multi={false}
        clearable
        value={tradepoint.territory_id}
        onChange={(value) => update({ territory_id: value })}
        filters={{ contragent_id__eq: tradepoint.contragent_id }}
      />
    </FormGroup>
    <div className={styles.spacer} />
    <FormGroup inline label={`${t('name')}:`} labelFor="tp-name" className={styles.detailsRow}>
      <InputGroup
        id="tp-name"
        value={tradepoint.name ?? ''}
        onChange={(e) => update({ name: e.target.value })}
      />
    </FormGroup>
    <FormGroup inline label={`${t('network')}:`} className={styles.detailsRow}>
      <TradeNetworkSelect
        fill
        multi={false}
        clearable
        value={tradepoint.network_id}
        onChange={(value) => update({ network_id: value })}
        filters={{ contragent_id__eq: tradepoint.contragent_id }}
      />
    </FormGroup>
    <FormGroup inline label={`${t('type')}:`} labelFor="tp-type" className={styles.detailsRow}>
      <InputGroup
        id="tp-type"
        value={tradepoint.type}
        onChange={(e) => update({ type: e.target.value })}
      />
    </FormGroup>
    <FormGroup inline label={`${t('extra-project')}:`} className={styles.detailsRow}>
      <TradepointProjectSelect
        fill
        multi={false}
        clearable
        value={tradepoint.project_id}
        onChange={(value) => update({ project_id: value })}
        filters={{ contragent_id__eq: tradepoint.contragent_id }}
      />
    </FormGroup>
    <FormGroup inline label={`${t('format')}:`} className={styles.detailsRow}>
      <TradepointFormatSelect
        fill
        multi={false}
        clearable
        value={tradepoint.format_id}
        onChange={(value) => update({ format_id: value })}
        filters={{ contragent_id__eq: tradepoint.contragent_id }}
      />
    </FormGroup>
    <div className={styles.spacer} />
    <NumFormGroup
      label={`${t('latitude')}:`}
      groupClassName={styles.detailsRow}
      valid={!latitudeInvalid}
      min={-85}
      max={85}
      minorStepSize={0.001}
      stepSize={0.01}
      majorStepSize={0.1}
      value={tradepoint.latitude}
      onChange={(latitude) => update({ latitude })}
    />
    <NumFormGroup
      label={`${t('longitude')}:`}
      groupClassName={styles.detailsRow}
      valid={!longitudeInvalid}
      min={-180}
      max={180}
      minorStepSize={0.001}
      stepSize={0.01}
      majorStepSize={0.1}
      value={tradepoint.longitude}
      onChange={(longitude) => update({ longitude })}
    />
    <FormGroup
      inline
      label={`${t('code for IR')}:`}
      labelFor="tp-code-ir"
      className={styles.detailsRow}
      helperText={irInvalid ? t('incorrect value') : null}
      intent={irInvalid ? Intent.DANGER : Intent.NONE}
    >
      <InputGroup
        id="tp-code-ir"
        value={tradepoint.ir_code ?? ''}
        onChange={(e) => update({ ir_code: e.target.value })}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('ir visit type prefix')}:`}
      labelFor="tp-visit-ir-type"
      className={styles.detailsRow}
    >
      <InputGroup
        id="tp-visit-ir-type"
        value={tradepoint.visit_ir_type ?? ''}
        onChange={(e) => update({ visit_ir_type: e.target.value })}
      />
    </FormGroup>
    <NumFormGroup
      label={`${t('number of cash desks')}:`}
      groupClassName={styles.detailsRow}
      valid={!cashboxCountInvalid}
      min={0}
      max={100}
      minorStepSize={null}
      value={tradepoint.cashbox_count}
      onChange={(v) => update({ cashbox_count: v })}
    />
    <FormGroup
      inline
      label={`${t('change attr on import')}:`}
      labelFor="change-attr"
      className={styles.detailsRow}
    >
      <Checkbox
        id="change-attr"
        checked={tradepoint.update_with_user_tasks_import ?? ''}
        onChange={() => update({
          update_with_user_tasks_import: !tradepoint.update_with_user_tasks_import,
        })}
      />
    </FormGroup>
    <NumFormGroup
      label={`${t('visitIntervalDays')}:`}
      groupClassName={styles.detailsRow}
      valid={!visitIntervalInvalid}
      min={0}
      max={3653}
      minorStepSize={null}
      value={tradepoint.visit_interval_days}
      onChange={(v) => update({ visit_interval_days: v })}
    />
    <div className={styles.spacer} />
    <KladrDetails
      id={tradepoint.kladr_id}
      status={tradepoint.kladr_status}
    />
  </div>
));

TradepointDetails.propTypes = {
  tradepoint: tradepointType.isRequired,
  latitudeInvalid: PropTypes.bool.isRequired,
  longitudeInvalid: PropTypes.bool.isRequired,
  irInvalid: PropTypes.bool.isRequired,
  cashboxCountInvalid: PropTypes.bool.isRequired,
  visitIntervalInvalid: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const Tradepoint = ({ tradepoint, update, save, t }) => {
  const { latitude, longitude, ir_code, cashbox_count, visit_interval_days } = tradepoint;
  const latitudeInvalid = (latitude === null && longitude !== null) || Math.abs(latitude ?? 0) > 85;
  const longitudeInvalid = (longitude === null && latitude !== null) || Math.abs(longitude ?? 0) > 180;
  const irInvalid = !(/^[A-Za-zА-Яа-я0-9_-]*$/.test(ir_code ?? ''));
  const cashboxCountInvalid = !_.inRange(cashbox_count ?? 0, 0, 101) || !_.isInteger(cashbox_count ?? 0);
  const visitIntervalInvalid = !_.isInteger(visit_interval_days ?? 0) || visit_interval_days < 0;
  const invalid = (
    latitudeInvalid || longitudeInvalid || irInvalid || cashboxCountInvalid || visitIntervalInvalid
  );

  const [kladrState, fetchKladr] = useRequest(parseKladr);
  useEffect(() => {
    if (kladrState.data) {
      update({ kladr_id: kladrState.data.id, kladr_status: 'parsed' });
    } else if (kladrState.error) {
      update({ kladr_id: null, kladr_status: 'error' });
    }
  }, [kladrState]);

  return (
    <div>
      <div className={styles.wrapper}>
        <TradepointDetails
          tradepoint={tradepoint}
          latitudeInvalid={latitudeInvalid}
          longitudeInvalid={longitudeInvalid}
          irInvalid={irInvalid}
          cashboxCountInvalid={cashboxCountInvalid}
          visitIntervalInvalid={visitIntervalInvalid}
          update={update}
          t={t}
        />
        <EditMap tradepoint={tradepoint} update={update} />
      </div>
      <div className={styles.buttonGroup}>
        <Button
          disabled={!tradepoint.address || kladrState.fetching}
          className={styles.kladrButton}
          icon="map-marker"
          loading={kladrState.fetching}
          onClick={() => fetchKladr(tradepoint.address)}
          text={t('recognize KLADR')}
        />
        <Button
          icon="lock"
          className={styles.kladrButton}
          intent={Intent.DANGER}
          disabled={tradepoint.kladr_status === 'blocked'}
          onClick={() => update({ kladr_id: null, kladr_status: 'blocked' })}
          text={t('block KLADR')}
        />

        <div className={styles.buttonSpacer} />

        <Link to={`/tradepoint/${tradepoint.id}`}>
          <Button icon="cross" className={styles.cancelButton} text={t('cancel')} />
        </Link>
        <Button
          disabled={invalid}
          icon="tick"
          intent={Intent.PRIMARY}
          onClick={save}
          className={styles.saveButton}
          text={t('save')}
        />
      </div>
    </div>
  );
};

Tradepoint.propTypes = {
  tradepoint: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Tradepoint;
