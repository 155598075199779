import { create, CancelToken } from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import _ from 'lodash';

import i18n from 'i18n';
import { ISODate } from 'app/utils/date';
import {
  successInterceptor,
  errorInterceptor,
  buildQueryParams,
  serializeFilters,
  normalizeFilters,
  formatDates,
  toSnakeCase,
} from './utils';

export { logoutChannel } from './utils';

export const BASE_URL = '/webapi';

const API = create({
  baseURL: BASE_URL,
  timeout: 80 * 1000,
  transitional: {
    clarifyTimeoutError: true,
  },
});

const IMPORT_EXPORT_TIMEOUT = 3600 * 1000;

export const USER_TASK_CHECKS = '/user-task-checks';
export const USER_TASK_CHECKS_QUESTIONS = '/user-task-checks/questions';
export const USER_TASK_CHECKS_QUESTIONS_REFERENCES = '/user-task-checks/questions/references';
export const USER_TASK_CHECKS_REPORT = '/webapp/reports/user-task-checks/export';
export const QUESTIONARY_TRADEPOINT_REFS_REPORT = '/webapp/reports/questionary-tradepoint-refs/export';
export const RESCHEDULE_REASONS_REPORT = '/webapp/reports/user-tradepoint-reschedule-reasons/export';
export const JTI_EQUIPMENT_IDENTIFICATION_REPORT = '/webapp/reports/jti-equipment-identification/export';
export const USERS_REFERENCES = '/users/references';
export const TRADEPOINTS = '/tradepoints';
export const TRADEPOINTS_REFERENCES = '/tradepoints/references';
export const TRADEPOINTS_BINDINGS = '/tradepoints/bindings';
export const TRADE_NETWORKS = '/trade-networks';
export const TRADE_NETWORKS_REFERENCES = '/trade-networks/references';
export const TRADEPOINT_PROJECTS_REFERENCES = '/tradepoint-projects/references';
export const TRADEPOINT_FORMATS_REFERENCES = '/tradepoint-formats/references';
export const TRADEPOINT_TERRITORIES_REFERENCES = '/tradepoint-territories/references';
export const BRANCHES_REFERENCES = '/branches/references';
export const CONTRAGENTS_REFERENCES = '/contragents/references';
export const AGENCIES_REFERENCES = '/agencies/references';
export const QUESTIONARIES = '/questionaries';
export const QUESTIONARIES_REFERENCES = '/questionaries/references';
export const QUESTIONARY_CATEGORIES = '/questionary-categories';
export const QUESTIONARY_CATEGORIES_REFERENCES = '/questionary-categories/references';
export const QUESTIONARY_TITLES_REFERENCES = '/questionaries/titles/references';
export const QUESTIONS_REFERENCES = '/questionaries/questions/references';
export const QUESTION_VARIANTS_REFERENCES = '/questionaries/questions/variants/references';
export const ASTIX_ACTIVITIES_REFERENCES = '/questionaries/astix-activities/references';
export const ROLES_REFERENCES = '/roles/references';
export const VISIT_PROBLEMS_REFERENCES = '/visit-problems/references';
export const IMPORT_TASKS = '/import-tasks';
export const PLANOGRAMS = '/planograms';
export const PLANOGRAM_REFERENCES = '/planograms/references';
export const PLANOGRAM_FILES = '/planograms/files';
export const PRODUCT_GROUPS = '/product-groups';
export const SCHEDULE = '/tasks/schedule';
export const RESCHEDULE_REASONS = '/reschedule-reasons';
export const RESCHEDULE_REASONS_REFERENCES = '/reschedule-reasons/references';
export const FEED = '/tasks/feed';
export const PRODUCT_CATEGORIES_REFERENCES = '/questionaries/product-categories/references';
export const CONTRAGENTS = '/webapp/contragents';
export const VISIT_PROBLEMS = '/visit-problems';
export const PRODUCT_PROBLEMS = '/product-problems';
export const BINDING_IR = '/ir-tradepoint-refs';
export const EVENTS = '/events/combined';
export const EQUIPMENT = '/equipment';
export const PRODUCT_BRANDS = '/product-brands';
export const BACKGROUND_EXPORT_TASKS = '/background-export-tasks';

API.interceptors.request.use((config) => ({
  ...config,
  headers: {
    'X-Language': i18n.resolvedLanguage,
  },
}));

API.interceptors.response.use(successInterceptor, errorInterceptor);

function request(method, url, params1, params2) {
  const cancelTokenSource = CancelToken.source();
  const cancelToken = cancelTokenSource.token;
  let promise;
  if (method === 'post' || method === 'put') {
    promise = API[method](url, params1, { ...params2, cancelToken });
  } else {
    promise = API[method](url, { ...params1, cancelToken });
  }
  promise.cancel = cancelTokenSource.cancel.bind(cancelTokenSource);
  return promise;
}

const get = (...args) => request('get', ...args);
const del = (...args) => request('delete', ...args);
const post = (...args) => request('post', ...args);
const put = (...args) => request('put', ...args);

export const getGrid = ({ path, filters, sort, pagination }) => {
  const params = buildQueryParams({ filters, pagination, sort });
  return get(path, { params });
};

export const getRawGrid = ({ path, filters, sort, pagination }) => {
  const params = {};
  if (filters) {
    params.filters = JSON.stringify(normalizeFilters(filters));
  }

  if (sort?.field) {
    params.sort = JSON.stringify([[sort.direction.toUpperCase(), sort.field]]);
  }

  if (pagination) {
    params.page = JSON.stringify({
      size: pagination.itemsPerPage,
      number: pagination.currentPage - 1,
    });
  }

  return get(path, { params });
};

export const getRawGridCount = ({ path, filters }) => {
  const params = {};
  if (filters) {
    params.filters = JSON.stringify(normalizeFilters(filters));
  }

  return get(`${path}/count`, { params });
};

export const updateGrid = ({ path, id, data }) => put(`${path}/${id}`, data);

export const signup = (data) => post('/webapp/auth/signup', data);

export const login = (data) => post('/webapp/auth/login', data);

export const loginOpenID = (code) => post('/webapp/auth/openid', { code });

export const postLogout = async () => {
  const response = await post('/webapp/auth/logout');
  return response;
};

export const getUser = () => get('/webapp/auth');

export const getIRTypes = () => get('/webapp/ir_types');

export const getQuestionariesByIds = (ids) => {
  const filters = { id__in: ids };
  const params = buildQueryParams({ filters });
  return get('/webapp/quests', { params });
};

export const putQuestionary = (id, questionary) => put(`/questionaries/${id}`, questionary);

export const postQuestionary = (questionary) => post('/questionaries', questionary);

export const getQuestionary = (id) => get(`/questionaries/${id}`);

export const deleteQuestionary = (id) => del(`/questionaries/${id}`);

export const getImportTask = (id) => get(`${IMPORT_TASKS}/${id}`);

export const createImportTask = (file) => {
  const data = new FormData();
  data.append('xlsFile', file);
  return post(IMPORT_TASKS, data, { timeout: IMPORT_EXPORT_TIMEOUT });
};

export const cancelImportTask = (id) => post(`${IMPORT_TASKS}/${id}/cancel`);

export const getTradepoints = ({ filters }) => {
  const params = buildQueryParams({ filters });
  return get(TRADEPOINTS, { params });
};

export const getTradepointBindings = ({ filters }) => {
  const params = buildQueryParams({ filters });
  return get(TRADEPOINTS_BINDINGS, { params });
};

export const getTradepoint = (id) => API.get(`/tradepoints/${id}`);

export const updateTradepoint = (id, data) => updateGrid({ path: TRADEPOINTS, id, data });

export const parseKladr = (address) => post('/kladr', { address });

export const getKladr = (id) => get(`/kladr/${id}`);

export const editKladr = (id, kladr) => put(`/kladr/${id}`, kladr);

export const getContragents = () => get('webapp/contragents');

export const getContragent = async (id) => {
  const response = await get('webapp/contragents', { params: { 'filter[id__eq]': id } });
  return response?.data[0] ?? null;
};

export const saveContragentSettings = ({ id, settings }) => put(`webapp/contragents/${id}`, { settings });

export const createContragent = ({ code, shortname, exclusive, agencyId }) => post('webapp/contragents', {
  code,
  shortname,
  fullname: shortname,
  exclusive,
  agency_id: agencyId,
});

export const bindQuestionariesToTradepoints = (refs) => post(
  '/questionary-tradepoint-refs',
  toSnakeCase(refs),
);

export const bindPlanogramToTradepoints = (refs) => post(
  '/planogram-tradepoint-refs',
  toSnakeCase(refs),
);

export const bindQuestionaryToTradepoint = (ref) => bindQuestionariesToTradepoints([ref]);

export const unbindQuestionariesFromTradepoints = (refs) => post(
  '/questionary-tradepoint-refs/delete',
  toSnakeCase(refs),
);

export const unbindPlanogramFromTradepoint = (refs) => post(
  '/planogram-tradepoint-refs/delete',
  toSnakeCase(refs),
);

export const unbindQuestionaryFromTradepoint = (ref) => unbindQuestionariesFromTradepoints([ref]);

export const getScheduledDates = (tradepointIds) => {
  const today = moment().startOf('day');
  const filters = {
    tradepoint_id__in: tradepointIds,
    date__ge: today.toDate(),
    date__le: today.add(30, 'days').toDate(),
    task_type__eq: 'merchandising',
  };

  const params = buildQueryParams({ filters });
  return get('tasks/schedule/dates/v2', { params });
};

export const getLastCompletedInTradepoint = (tradepointId) => get(`tasks/last-completed-in-tradepoint/${tradepointId}`);


export function download(path, { filters, pagination, sort, ...other }) {
  const params = { ...other, ...buildQueryParams({ filters, pagination, sort }), language: i18n.resolvedLanguage };
  const query = _.map(params, (value, key) => `${key}=${value}`).join('&');
  window.open(`${BASE_URL}${path}?${query}`);
}

export const getTaskList = (newFilters, pagination) => {
  const filters = { ...newFilters, task_type: newFilters.task_type ? newFilters.task_type : 'merchandising' };
  const params = buildQueryParams({ filters, pagination });
  return get(SCHEDULE, { params });
};

export const getSupervisors = (contragentId) => {
  const filters = { contragent_id: contragentId, role: 'supervisor' };
  const params = buildQueryParams({ filters });
  return get('/users/all/team', { params });
};

export const getAuditTeam = ({ contragentId, supervisorId, date }) => {
  const params = {
    contragent_id: contragentId,
    supervisor_id: supervisorId,
    from_date: ISODate(date),
    date: ISODate(date),
  };
  return get('/audit/team', { params });
};

/* eslint-disable camelcase */
export const getAuditTradepoints = ({ ir_code__like, ir_type__like, onlyNew, contragentId, merchId, date }) => {
  const filters = {};
  if (ir_code__like) {
    filters.ir_code__like = ir_code__like;
  }
  if (ir_type__like) {
    filters.ir_type__like = ir_type__like;
  }
  if (onlyNew) {
    filters.status__eq = 'new';
  }
  const query = buildQueryParams({ filters });
  const params = {
    contragent_id: contragentId,
    merch_id: merchId,
    date: ISODate(date),
    ...query,
  };
  return get('/audit/tradepoints', { params });
};
/* eslint-enable camelcase */

export const createAuditCheck = ({ contragentId, supervisorId, merchId, date, taskIds }) => {
  const params = {
    contragent_id: contragentId,
    supervisor_id: supervisorId,
    merch_id: merchId,
    date: ISODate(date),
    task_ids: taskIds,
  };
  return post('/audit/check', params);
};

export const createAudit = ({ contragentId, supervisorId, managerId, taskIds }) => {
  const params = {
    contragent_id: contragentId,
    supervisor_id: supervisorId,
    manager_id: managerId,
    task_ids: taskIds,
  };
  return post('/audit/', params);
};

export const getEventList = (filters, pagination) => {
  const formatedFilters = {
    ...filters,
    expired_time__to: filters.expired_time__to && moment(filters.expired_time__to).add(1, 'days').toDate(),
    end_time__to: filters.end_time__to && moment(filters.end_time__to).add(1, 'days').toDate(),
  };
  const params = buildQueryParams({ filters: formatedFilters, pagination });
  return get('/events/combined', { params });
};

export const getEvent = async (id) => {
  const response = await get('/events/combined', { params: { 'filter[id__eq]': id } });
  return response?.data[0] ?? null;
};

export const createEvent = (data) => post('/events', data);

export const exportEvents = async (filters) => {
  const formatedFilters = formatDates({
    ...filters,
    expired_time__to: filters.expired_time__to && moment(filters.expired_time__to).add(1, 'days').toDate(),
    end_time__to: filters.end_time__to && moment(filters.end_time__to).add(1, 'days').toDate(),
  });

  const { data, headers } = await post(
    '/webapp/reports/events/export',
    { filter: formatedFilters },
    { responseType: 'blob', timeout: IMPORT_EXPORT_TIMEOUT },
  );
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'events.xlsx'
  );
  await saveAs(data, fileName);
};

export const deleteEvent = (id) => del(`/events/${id}`);

export const getTeamUsers = (contragentId) => {
  const params = { contragent_id: contragentId };
  return get('/webapp/users/team', { params });
};

export const getContragentMerchandisers = async (contragentId) => {
  const params = { contragent_id: contragentId };
  const users = await get('/webapp/users/team', { params });
  const merchandisers = users.data.filter((user) => user.roles.includes('merch'));
  return _.sortBy(merchandisers, ['fullname']);
};

export const getUserById = (id) => get(`/webapp/users/${id}`);

export const getUserChiefs = (id, contragentId) => {
  const params = { contragent_id: contragentId };
  return get(`/webapp/users/${id}/chiefs`, { params });
};

export const createUser = (data) => post('/webapp/users', data);

export const editUser = (id, data) => put(`/webapp/users/${id}`, data);

export const logoutTeamUser = (id) => post(`/auth/user/${id}/logout`);

export const setUserPassword = (id, password) => put(`/webapp/users/${id}/password`, { password });

export const setUserWebPassword = (id, password) => put(`/webapp/users/${id}/webpass`, { password });

export const toggleUserActive = (id) => post(`/webapp/users/${id}/toggle`);

export const getTask = (id) => get(`/tasks/team/${id}`);

export const getTaskGroupVisitId = (id) => get(`/task-groups/${id}/visit-id`);

export const createTask = ({ task, force }) => post(
  '/tasks',
  task,
  { params: { force } },
);

export const createRestoredTask = (task) => post('/tasks/restored', task);

export const editRestoredTask = (id, task) => put(`/tasks/restored/${id}`, task);

export const hasDublicateTask = async ({ tradepointId, userId, date, excludeTaskId }) => {
  const response = await get('/tasks/dublicate_check', {
    params: {
      tradepoint_id: tradepointId,
      user_id: userId,
      date: ISODate(date),
      exclude_task_id: excludeTaskId,
    },
  });
  return response.has_dublicate;
};

export const getTPLastUser = (tradePointId) => get(`/tradepoints/${tradePointId}/last_task/user`);

export const getUserScheduleGaps = (userId, date) => get(`/webapp/users/${userId}/schedule/gaps`, {
  params: {
    date: ISODate(date),
  },
});

export const editTasks = (data) => post('/tasks/schedule/edit', data);

export const resetTask = (id) => post(`/tasks/${id}/reset`, {});

export const completeTask = (id, data) => post(`/tasks/${id}/complete`, data);

export const deleteTasks = (task_ids) => post('/tasks/schedule/delete', { task_ids });

export const deleteTaskPhoto = (id, photoUUID) => del(`/tasks/${id}/photos/${photoUUID}`);

export const downloadBiReport = async (filters) => {
  const params = { };
  if (filters.contragent) {
    params.contragent = filters.contragent;
  }
  if (filters.from_date) {
    params.from_date = ISODate(filters.from_date);
  }
  if (filters.to_date) {
    params.to_date = ISODate(moment(filters.to_date).add(1, 'days').toDate());
  }
  if (filters.visits_only) {
    params.visits_only = true;
  }
  const { data, headers } = await post('/tasks/download_xls_report', params, {
    responseType: 'blob',
    timeout: 2400000,
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'report.zip'
  );
  await saveAs(data, fileName);
};

export const downloadJtiReport = async (filters) => {
  const params = buildQueryParams({ filters });
  const { data, headers } = await get('/tasks/download_jti_report', {
    params,
    responseType: 'blob',
    timeout: IMPORT_EXPORT_TIMEOUT,
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'report.zip'
  );
  await saveAs(data, fileName);
};

export const downloadJtiReportGrouped = async (filters) => {
  const params = buildQueryParams({ filters });
  const { data, headers } = await get('/tasks/download_jti_report_v2', {
    params,
    responseType: 'blob',
    timeout: IMPORT_EXPORT_TIMEOUT,
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'report.zip'
  );
  await saveAs(data, fileName);
};

export const downloadDiageoReport = async (filters) => {
  const params = {
    report_type: filters.report_type,
  };
  if (filters.from_date) {
    params.from_date = ISODate(filters.from_date);
  }
  if (filters.to_date) {
    params.to_date = ISODate(moment(filters.to_date).add(1, 'days').toDate());
  }
  const { data, headers } = await post('/webapp/reports/diageo/export', params, {
    responseType: 'blob',
    timeout: 2400000,
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'report.xlsx'
  );
  await saveAs(data, fileName);
};

export const downloadDiageoPilotReport = async (filters) => {
  const params = {
    report_type: filters.report_type,
  };
  if (filters.from_date) {
    params.from_date = ISODate(filters.from_date);
  }
  if (filters.to_date) {
    params.to_date = ISODate(moment(filters.to_date).add(1, 'days').toDate());
  }
  const { data, headers } = await post('/webapp/reports/diageo-visits/export', params, {
    responseType: 'blob',
    timeout: 2400000,
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'report.xlsx'
  );
  await saveAs(data, fileName);
};

export const createBackgroundExportTask = ({ type, params }) => {
  const serializedParams = { ...params };
  if (params.filter) {
    serializedParams.filter = serializeFilters(params.filter, false);
  }

  if (params.sort?.field) {
    serializedParams.sort = [[params.sort.direction.toUpperCase(), params.sort.field]];
  }

  return post(`${BACKGROUND_EXPORT_TASKS}/${type}`, serializedParams);
};

export const cancelBackgroundExportTask = (id) => post(`${BACKGROUND_EXPORT_TASKS}/${id}/cancel`);

export const getReporFields = (reportType, contragentId) => {
  const params = buildQueryParams({ filters: { contragent_id: contragentId } });
  return get(`/webapp/reports/${reportType}/fields`, { params });
};

export const getReporData = (reportType, filters, columns, answersOnly) => {
  const data = {
    filter: filters,
    columns,
    answers_only: answersOnly,
  };
  return post(`/webapp/reports/${reportType}/data`, data, { timeout: IMPORT_EXPORT_TIMEOUT });
};

export const downloadReport = async (reportType, columns, filters, answersOnly) => {
  const params = {
    columns,
    filter: filters,
    answers_only: answersOnly,
  };
  const { data, headers } = await post(`/webapp/reports/${reportType}/export`, params, {
    responseType: 'blob',
    timeout: IMPORT_EXPORT_TIMEOUT,
  });
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'report.xlsx'
  );
  await saveAs(data, fileName);
};

export const getKpiByParent = (parentId, contragentId, dateFrom, dateTo) => {
  const params = {
    date_from: ISODate(dateFrom),
    date_to: ISODate(dateTo),
    contragent_id: contragentId,
  };
  return get(`/kpi/combined/team/${parentId}`, { params, timeout: 180000 });
};

export const getKpiByUser = (userId, contragentId, dateFrom, dateTo) => {
  const params = {
    date_from: ISODate(dateFrom),
    date_to: ISODate(dateTo),
    contragent_id: contragentId,
  };
  return get(`/kpi/combined/user/${userId}`, { params, timeout: 180000 });
};

export const getKpiTotals = (userId, contragentId, dateFrom, dateTo) => {
  const params = {
    date_from: ISODate(dateFrom),
    date_to: ISODate(dateTo),
    contragent_id: contragentId,
  };
  return get(`/kpi/combined/user/${userId}/totals`, { params, timeout: 180000 });
};

export const getNetworks = ({ filters, pagination }) => {
  const params = buildQueryParams({ filters, pagination });
  return get('/tradepoints/networks', { params });
};

export const getFilials = ({ filters, pagination }) => {
  const params = buildQueryParams({ filters, pagination });
  return get('/tradepoints/filials', { params });
};

export const uploadImage = (params) => {
  const body = new FormData();
  body.append('is_fake', 'false');
  for (const [key, value] of _.toPairs(params)) {
    body.append(key, value);
  }
  return post('/tasks/photos', body);
};

export const sendFeedback = (data) => post('/feedback', data);

export const getPlanograms = ({ filters, pagination }) => {
  const params = buildQueryParams({ filters, pagination });
  return get('/planograms', { params });
};

export const getPlanogram = (id) => get(`/planograms/${id}`);

export const createPlanogram = (data) => post('/planograms', data);

export const editPlanogram = (id, data) => put(`/planograms/${id}`, data);

export const deletePlanogram = (id) => del(`/planograms/${id}`);

export const uploadPlanogramFile = (file, uuid) => {
  const body = new FormData();
  body.append('data', file);
  body.append('uuid', uuid);
  return post('/planograms/files', body);
};

export const getUserTaskCheckQuestions = (id) => get(`${USER_TASK_CHECKS}/for-task/${id}`);

export const postUserTaskCheckResult = (id, result) => post(`${USER_TASK_CHECKS}/for-task/${id}`, result);

export const getReferences = ({ path, filters, limit }) => {
  const params = {
    ...buildQueryParams({ filters }),
    limit,
  };
  return get(path, { params });
};

export const getAuditActiveDates = ({ contragentId, supervisorId, date }) => {
  const params =  {
    contragent_id: contragentId,
    supervisor_id: supervisorId,
    from_date: ISODate(moment(date).startOf('month')),
    to_date: ISODate(moment(date).endOf('month')),
  };
  return get('/audit/calendar', { params });
};

export const editVisitProblem = (data) => put(VISIT_PROBLEMS, data);

export const deleteVisitProblem = (contragentId, code) => del(VISIT_PROBLEMS, {
  params: {
    contragent_id: contragentId,
    code,
  },
});

export const createQuestionaryCategory = (data) => post(QUESTIONARY_CATEGORIES, data);

export const createRescheduleReason = (data) => post(RESCHEDULE_REASONS, data);

export const editProductProblem = (data) => put(PRODUCT_PROBLEMS, data);

export const deleteProductProblem = (contragentId, code) => del(PRODUCT_PROBLEMS, {
  params: {
    contragent_id: contragentId,
    code,
  },
});

export const exportFixedIR = async (filters) => {
  const formatedFilters = formatDates({
    ...filters,
    active_from_date__ge: filters.active_from_date__ge && moment(filters.active_from_date__ge).add(1, 'days').toDate(),
    active_to_date__le: filters.active_to_date__le && moment(filters.active_to_date__le).add(1, 'days').toDate(),
  });

  const { data, headers } = await post(
    '/ir-tradepoint-refs/export',
    { filter: formatedFilters },
    { responseType: 'blob', timeout: IMPORT_EXPORT_TIMEOUT },
  );
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'events.xlsx'
  );
  await saveAs(data, fileName);
};

export const exportPlanograms = async (filters) => {
  const { data, headers } = await post(
    '/planograms/export',
    { filter: filters },
    { responseType: 'blob', timeout: IMPORT_EXPORT_TIMEOUT },
  );
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'events.xlsx'
  );
  await saveAs(data, fileName);
};

export const exportTeam = async (filters) => {
  const { data, headers } = await post(
    '/webapp/reports/users-team/export',
    { filter: filters },
    { responseType: 'blob', timeout: IMPORT_EXPORT_TIMEOUT },
  );
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'events.xlsx'
  );
  await saveAs(data, fileName);
};

export const exportQuestionaries = async (filters) => {
  const { data, headers } = await post(
    '/questionaries/export',
    { filter: filters },
    { responseType: 'blob', timeout: IMPORT_EXPORT_TIMEOUT },
  );
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'events.xlsx'
  );
  await saveAs(data, fileName);
};

export const exportQuestionariesWithQuestions = async (filters) => {
  const { data, headers } = await post(
    '/questionaries/questions/export',
    { filter: filters },
    { responseType: 'blob', timeout: IMPORT_EXPORT_TIMEOUT },
  );
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'questionaries_extended.xlsx'
  );
  await saveAs(data, fileName);
};

export const saveFixation = async (payload) => post(
  '/ir-tradepoint-refs',
  payload,
);

export const deactivateQuestionaries = (data) => post('/questionaries/deactivate', data);
export const deactivateTradepoints = (data) => post('/tradepoints/remove', data);
export const deactivatePlanograms = (data) => post('/planograms/deactivate', data);
export const deactivateIRBindings = (data) => post('/ir-tradepoint-refs/disable-refs', data);

export const exportReference = async (path, filters) => {
  const { data, headers } = await post(
    `${path}/export`,
    { filter: filters },
    { responseType: 'blob', timeout: IMPORT_EXPORT_TIMEOUT },
  );
  const fileName = (
    headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].replace(/^["]+|["]+$/g, '')
      : 'reference.xlsx'
  );
  await saveAs(data, fileName);
};

export const saveReferenceRecord = async (path, payload) => {
  let method = post;
  if (payload.id) {
    method = put;
  }
  delete payload.id;
  return method(
    path,
    payload,
  );
}

export const deleteReferenceRecord = (path, data) => post(`${path}/delete`, data);
export const resetAllFacadePhotoAttr = (data) => post('/tradepoint-projects/set-skip-photo', data);

export const bindQuestionaryTradepointsByFilter = (filter) => post(
  '/questionary-tradepoint-refs/bind-by-filters',
  { filter },
);

export const unbindQuestionaryTradepointsByFilter = (filter) => post(
  '/questionary-tradepoint-refs/unbind-by-filters',
  { filter: filter },
);

export const getQuestionariesByPost = ({ filters, sort, pagination }) => {
  // const params = buildQueryParams({ filters, pagination, sort });
  const params = {
    filter: filters,
  };
  if (pagination) {
    params.page = {};
    params.page.size = pagination.itemsPerPage;
    params.page.number = pagination.currentPage && pagination.currentPage - 1;
  }
  if (sort?.field) {
    params.sort = sort.direction === 'asc' ? sort.field : `-${sort.field}`;
  }

  return post('/questionaries/get', params);
};
