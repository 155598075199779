import { combineReducers } from 'redux';

import bi from './bi';
import jti from './jti';
import diageo from './diageo';
import diageoPilot from './diageoPilot';
import dynamic from './dynamic';

export default combineReducers({
  bi,
  jti,
  diageo,
  diageoPilot,
  dynamic,
});
