import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDrop, } from 'react-dnd';
import { H4, Button, Card, Elevation, FormGroup, InputGroup, HTMLTable, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import { dispatch } from 'app/state';
import * as questionary from 'app/state/actionCreators/questionary';
import Form from 'app/widgets/Form';

import { getUseSteps } from './common';
import Question from './Question';
import styles from './QuestionaryEditScreen.module.css';

function handleChangeStep({ index }, step) {
  dispatch(questionary.setGroupStep({ groupIndex: index, step }));
}

function handleRemove({ index }) {
  dispatch(questionary.removeGroup(index));
}

function handleAddQuestion({ index }) {
  dispatch(questionary.addQuestion(index));
}

function handleMoveQuestions({ index }, { id }) {
  dispatch(questionary.moveQuestion({ id, groupIndex: index, afterId: null}));
}

function collectIsDragging(monitor) {
  return monitor.canDrop();
}

function getHasDuplicate(state, index) {
  const { groups } = state.questionary;
  const group = groups[index];
  for (let i = 0; i < groups.length; i += 1) {
    if (i !== index && group.step === groups[i].step && _.isEqual(group.titles, groups[i].titles)) {
      return true;
    }
  }
  return false;
}

const Group = React.memo(({ index }) => {
  const group = useSelector((state) => state.questionary.groups[index]);
  const useSteps = useSelector(getUseSteps);
  const { t } = useTranslation();

  const TITLE_LABELS = [
    t('title'),
    t('subtitle'),
    t('sub subtitle'),
    t('subtitle (swipe)'),
  ];

  const hasDuplicate = useSelector((state) => getHasDuplicate(state, index));
  const intent = hasDuplicate ? Intent.WARNING : Intent.NONE;
  const title = hasDuplicate ? t('matches with other step') : null;
  const onChangeStep = utils.useCallbackWithDeps(handleChangeStep, { index });
  const onChangeTitles = TITLE_LABELS.map((label, titleIndex) => utils.useDispatchCallback(
    (e) => questionary.setGroupTitle({ index, titleIndex, title: e.target.value }),
  ));
  const onClickRemove = utils.useCallbackWithDeps(handleRemove, { index });
  const onBlur = utils.useDispatchCallback(questionary.resortGroups);

  const onHover = utils.useCallbackWithDeps(handleMoveQuestions, { index });
  const [isDragging, drop] = useDrop(() => ({
    accept: 'question',
    hover: onHover,
    collect: collectIsDragging,
  }));

  const onClickAdd = utils.useCallbackWithDeps(handleAddQuestion, { index });

  return (
    <Card elevation={Elevation.TWO} className={styles.groupCard}>
      {!useSteps && <H4>{t('step')} ({t('screen')}) {index + 1}:</H4>}

      <div className={styles.groupHeader}>
        {useSteps && (
          <FormGroup label={t('step')}>
            <Form.NumberInput
              fill
              intent={intent}
              title={title}
              integer
              min={1}
              value={group.step}
              onChange={onChangeStep}
              onButtonClick={onBlur}
              onBlur={onBlur}
            />
          </FormGroup>
        )}
        {TITLE_LABELS.map((label, titleIndex) => (
          <FormGroup key={label} label={label}>
            <InputGroup
              intent={intent}
              title={title}
              value={group.titles[titleIndex] ?? ''}
              onChange={onChangeTitles[titleIndex]}
              onBlur={onBlur}
            />
          </FormGroup>
        ))}

        <Button
          minimal
          text={`${t('delete step')} (${t('screen')})`}
          intent={Intent.DANGER}
          icon="cross"
          onClick={onClickRemove}
        />
      </div>

      <HTMLTable className={utils.classNames(styles.groupQuestions, isDragging && styles.dropTarget)} bordered>
        <thead ref={drop}>
          <tr>
            <th>{t('question')}</th>
            <th>{t('answer type')}</th>
            <th>{t('additionally')}</th>
            <th>{t('default')}</th>
            <th>{t('date from second')}</th>
            <th>{t('date by')}</th>
            <th>{t('planograms')}</th>
            <th>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>
          {group.questions.map((id) => <Question key={id} groupIndex={index} id={id} />)}
        </tbody>
      </HTMLTable>

      <Button
        minimal
        text={t('add question')}
        intent={Intent.PRIMARY}
        icon="add"
        onClick={onClickAdd}
      />
    </Card>
  );
});

Group.propTypes = {
  index: PropTypes.number.isRequired,
};

export default Group;
