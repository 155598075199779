import _ from 'lodash';

import i18n from 'i18n';
import * as ACTIONS from 'app/state/actions';
import * as api from 'app/api';
import { showError } from 'app/widgets/toaster';

import { $setIn, resolveAborting } from './index';

export const INITIAL_GRID_STATE = {
  data: [],
  totalItems: 0,
  fetching: true,
  currentRequestCancel: null,
  filters: {},
  sort: null,
  pagination: {
    currentPage: 1,
    itemsPerPage: 20,
  },
  error: null,
};

export const checkInit = ({ path, sort, pagination }) => (dispatch, getState) => {
  const state = getState();
  const { grids, filters } = state.questionaryMultiBinding;
  if (!_.has(grids, path)) {
    dispatch($setIn(`questionaryMultiBinding.grids.${path}`, {
      ...INITIAL_GRID_STATE,
      filters: filters ?? {},
      sort: sort ?? null,
      pagination: pagination ?? { currentPage: 1, itemsPerPage: 20 },
    }));
  }
};

export const setFilters = (filters) => (
  { type: ACTIONS.QUESTIONARY_MULTI_BINDING_SET_FILTERS, filters }
);

const set = (path, changes) => (dispatch) => dispatch($setIn(`questionaryMultiBinding.grids.${path}`, changes));

export const setPagination = (path, pagination) => set(path, { pagination });

export const resetPage = (path) => set(`${path}.pagination`, { currentPage: 1 });

export const setSort = (path, sort) => set(path, { sort });

export const fetch = (path, method, filters) => async (dispatch, getState) => {
  const state = getState();
  const { grids } = state.questionaryMultiBinding;
  const { sort, pagination } = grids[path];

  dispatch($setIn(`questionaryMultiBinding.grids.${path}`, { fetching: true }));
  try {
    const { result, aborted } = await resolveAborting(
      method({ path, filters, sort, pagination }),
      `questionaryMultiBinding.grids.${path}`,
      dispatch,
      getState,
    );
    if (aborted) {
      return;
    }

    const { data, rowcount } = result;
    dispatch($setIn(`questionaryMultiBinding.grids.${path}`, {
      data,
      totalItems: rowcount,
      fetching: false,
    }));
  } catch (error) {
    dispatch($setIn(`questionaryMultiBinding.grids.${path}`, {
      error,
      fetching: false,
    }));

    showError(error.message || i18n.t('dataRetrievalError'));
  }
};

export const fetchQuestionaries = () => async (dispatch, getState) => {
  const state = getState();
  const { filters } = state.questionaryMultiBinding;
  dispatch(
    fetch(api.QUESTIONARIES, api.getQuestionariesByPost, {
      agency_id__eq: filters.agency_id__eq,
      contragent_id__eq: filters.contragent_id__eq,
      id__in: filters.questionary_id__in,
    })
  );
}

export const fetchTradepoints = () => async (dispatch, getState) => {
  const state = getState();
  const { filters } = state.questionaryMultiBinding;
  dispatch(
    fetch(api.TRADEPOINTS, api.getGrid, {
      agency_id__eq: filters.agency_id__eq,
      contragent_id__eq: filters.contragent_id__eq,
      code__ilike: filters.code__ilike,
      address__ilike: filters.address__ilike,
      branch_id__in: filters.branch_id__in,
      network_id__in: filters.network_id__in,
      project_id__in: filters.project_id__in,
      ir_code__ilike: filters.ir_code__ilike,
    })
  );
}
