import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Intent } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import * as api from 'app/api';
import { AgencyContragentFilter } from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';
import { isAdminOrAgencyAdmin } from 'app/state/reducers';
import store from 'app/state';
import { hasRight } from 'app/state/reducers';
import * as grids from 'app/state/actionCreators/grids';
import alerts from 'app/widgets/alerts';
import EditDialog from './EditDialog';

class VisitProblemsListScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editingProblem: null,
    };
  }

  deleteProblem = async ({ contragent_id, code }) => {
    const { t } = this.props;

    const { confirmed, error } = await alerts.actionWithConfirm({
      action: () => api.deleteVisitProblem(contragent_id, code),
      cancelButtonText: t('cancel'),
      confirmButtonText: t('delete'),
      intent: Intent.DANGER,
      icon: 'trash',
      content: t('delete problem'),
    });
    if (confirmed && !error) {
      store.dispatch(grids.fetch(api.VISIT_PROBLEMS));
    }
  };

  renderActions = (dummy, rowData) => (
    <>
      <Button icon="edit" onClick={() => this.setState({ editingProblem: rowData })} />
      <Button icon="trash" onClick={() => this.deleteProblem(rowData)} />
    </>
  );

  onCloseEditDialog = (changed) => {
    if (changed) {
      store.dispatch(grids.fetch(api.VISIT_PROBLEMS));
    }
    this.setState({ editingProblem: null });
  };

  addNew = () => {
    const { filters } = this.props;
    const { contragent_id__eq: contragent_id } = filters;
    this.setState({ editingProblem: { contragent_id, isNew: true } });
  };

  render() {
    const { isAdmin, t, canEdit } = this.props;
    const { editingProblem } = this.state;

    return (
      <Page title={t('visit problems')}>
        <Grid path={api.VISIT_PROBLEMS}>
          <Grid.Filter acceptor={AgencyContragentFilter} path="contragent_id__eq" />
          <Grid.Filter
            acceptor={InputFilter}
            path="code__ilike"
            name={t('problem code')}
            placeholder={t('search by problem code')}
          />

          {canEdit && (
            <Grid.Actions>
              <Button intent={Intent.SUCCESS} icon="add" onClick={this.addNew}>
                {t('create')}
              </Button>
            </Grid.Actions>
          )}

          <Grid.Column path="contragent" title={t('contractor')} sortable />
          <Grid.Column path="description" title={t('problem name')} sortable />
          <Grid.Column path="code" title={t('problem code')} sortable />
          {isAdmin && canEdit && <Grid.Column path="actions" title={t('actions')} valueRenderer={this.renderActions} />}
        </Grid>
        {editingProblem && (
          <EditDialog
            onClose={this.onCloseEditDialog}
            problem={editingProblem}
          />
        )}
      </Page>
    );
  }
};

VisitProblemsListScreen.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    contragent_id__eq: PropTypes.number,
  }),
  canEdit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAdmin: isAdminOrAgencyAdmin(state),
  filters: state?.grids[api.VISIT_PROBLEMS]?.filters,
  canEdit: hasRight(state, 'webapp_visit_problems_edit'),
});

export default connect(mapStateToProps)(withTranslation()(VisitProblemsListScreen));
