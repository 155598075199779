import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { H4, Card, Elevation } from '@blueprintjs/core';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import { CONTRAGENTS_REFERENCES } from 'app/api';
import { editContragent } from 'app/state/actionCreators/teamUser';
import { findReference } from 'app/state/actionCreators/references';
import { useDispatchCallback } from 'app/utils';
import * as reference from 'app/widgets/ReferenceSelect';
import Form from 'app/widgets/Form';

import styles from './UserEditScreen.module.css';

function validateMultiManager(multiManager, t, managerIds) {
  if (!managerIds?.length) {
    return true;
  }
  if (!multiManager && managerIds.length > 1) {
    return t('specifyExactlyOneManager');
  }
  return true;
}

const Contragent = React.memo(({ id, isManagerRequired, canEdit }) => {
  const { t } = useTranslation();
  const name = RRU.useSelector(state => findReference(state, CONTRAGENTS_REFERENCES, id)?.label);
  const value = RRU.useSelector(`teamUser.user.contragents.${id}`);
  const multiManager = RRU.useSelector(`teamUser.user.multi_manager`);
  const managerIdsValidator = RRU.useCallbackArgs(validateMultiManager, multiManager, t);
  const onFieldChange = useDispatchCallback(_.partial(editContragent, id), [id]);
  const onValidChange = useDispatchCallback((valid) => editContragent(id, 'invalid', !valid), [id]);

  return (
    <Card elevation={Elevation.ONE} className={styles.contragentCard}>
      <H4>{name}</H4>
      <Form className={styles.contragentForm} value={value} onFieldChange={onFieldChange} onValidChange={onValidChange}>
        <Form.Item
          label={t('bosses')}
          name="manager_ids"
          required={isManagerRequired}
          disabled={!canEdit}
          acceptor={reference.UserSelect}
          validator={managerIdsValidator}
          filters={{ contragent_id__eq: id }}
        />
        <Form.Item
          label={t('branches')}
          name="branch_ids"
          disabled={!canEdit}
          acceptor={reference.BranchSelect}
          filters={{ contragent_id__eq: id }}
        />
        <Form.Item
          label={t('tradepointProjects')}
          name="project_ids"
          disabled={!canEdit}
          acceptor={reference.TradepointProjectSelect}
          filters={{ contragent_id__eq: id }}
        />
        <Form.Item
          label={t('networks')}
          name="network_ids"
          disabled={!canEdit}
          acceptor={reference.TradeNetworkSelect}
          filters={{ contragent_id__eq: id }}
        />
        <Form.Item
          label={t('territories')}
          name="territory_ids"
          disabled={!canEdit}
          acceptor={reference.TradepointTerritorySelect}
          filters={{ contragent_id__eq: id }}
        />
        <Form.Item
          label={t('questionnaires')}
          name="questionary_ids"
          disabled={!canEdit}
          acceptor={reference.QuestionarySelect}
          filters={{ contragent_id__eq: id }}
        />
      </Form>
    </Card>
  );
});

Contragent.propTypes = {
  id: PropTypes.number.isRequired,
  isManagerRequired: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

const Contragents = React.memo(({ isManagerRequired, canEdit }) => {
  const contragentIds = useSelector((state) => state.teamUser.user.contragentIds);

  return (
    <div className={styles.contragentsContainer}>
      {contragentIds?.map((id) => (
        <Contragent key={id} id={id} isManagerRequired={isManagerRequired} canEdit={canEdit} />
      ))}
    </div>
  );
});

Contragents.propTypes = {
  isManagerRequired: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default Contragents;
