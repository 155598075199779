import { Intent } from '@blueprintjs/core';

const KLADR_STATUS_MAP = {
  parsed: {
    code: 'parsed',
    label: 'recognized successfully',
    icon: 'tick-circle',
    intent: Intent.SUCCESS,
  },
  unparsed: {
    code: 'unparsed',
    label: 'not recognized',
    icon: 'help',
    intent: Intent.PRIMARY,
  },
  error: {
    code: 'error',
    label: 'recognition error',
    icon: 'error',
    intent: Intent.DANGER,
  },
  blocked: {
    code: 'blocked',
    label: 'blocked by user',
    icon: 'lock',
    intent: Intent.DANGER,
  },
};

export default KLADR_STATUS_MAP;
