import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RRU from 'react-redux-utils';
import actions from 'app/state/directActions';

import styles from './QuestionaryBindingScreen.module.css';

function Planogram({ tradepointId, id, planogram }) {
  const { t } = useTranslation();
  const inProgress = RRU.useSelector(state => (
    !!state.planogramTradepointRefs.bindingsInProgress[`${tradepointId}_${id}`]
  ));
  const onClickUnbind = RRU.useCallbackArgs(
    actions.planogramTradepointRefs.toggleBindings,
    {
      tradepointId,
      planogramIds: [id],
      bound: true,
    },
  );

  return (
    <tr>
      <td>
        <Link to={`/planograms/${id}`}>{planogram.name}</Link>
      </td>

      <td>{t('planogram')} {planogram.forced && `(${t('required')})`}</td>

      <td>
        <Button
          minimal
          loading={inProgress}
          intent={Intent.DANGER}
          icon="cross"
          className={styles.removeQuestionaryButton}
          onClick={onClickUnbind}
        />
      </td>
    </tr>
  );
}

Planogram.propTypes = {
  tradepointId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  planogram: PropTypes.shape({
    name: PropTypes.string.isRequired,
    forced: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Planogram;
