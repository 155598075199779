import _ from 'lodash';

export const transformContagents = (contragents) => _.sortBy(contragents, 'fullname').map((c) => ({
  key: c.id,
  value: c.id,
  title: c.fullname,
}));

export const transformContagentsWithAgency = (contragents) => contragents.map((c) => ({
  key: c.id,
  value: c.id,
  title: c.fullname,
  agencyId: c.agency_id,
}));

export const transformContagentsByCode = (contragents) => contragents.map((c) => ({
  key: c.code,
  value: c.code,
  title: c.fullname,
}));

export default transformContagents;
