import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Card, Elevation, Intent, Spinner, NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import toaster from 'app/widgets/toaster';
import { getTradepoint, updateTradepoint } from 'app/api';
import { useRequestImmediate } from 'app/utils';

import Tradepoint from './Tradepoint';

const TradepointCard = ({ fetching, error, tradepoint, update, save, t }) => {
  if (fetching) {
    return <Spinner />;
  }
  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }
  if (!tradepoint) {
    return <NonIdealState title={t('empty')} description={t('tt not found')} icon="list" />;
  }

  return (
    <Card elevation={Elevation.TWO}>
      <Tradepoint tradepoint={tradepoint} update={update} save={save} t={t} />
    </Card>
  );
};

TradepointCard.propTypes = {
  tradepoint: PropTypes.object,
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.instanceOf(Error),
  update: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

TradepointCard.defaultProps = {
  error: null,
  tradepoint: null,
};

const TradepointEditScreen = ({ match, history }) => {
  const id = parseInt(match.params.id, 10);
  const { t } = useTranslation();
  const tradepointState = useRequestImmediate(getTradepoint, id);

  const tradepointInitial = tradepointState.data;
  let name = tradepointInitial?.network || tradepointInitial?.name;
  name = name ? `${name}, ` : '';

  const title = (
    tradepointInitial
    ? `${t('tt edit')} ${name}${tradepointInitial?.address || ''}`
    : t('tt edit')
  );

  const [tradepoint, setTradepoint] = useState(null);
  useEffect(() => setTradepoint(tradepointState.data), [tradepointState.fetching]);
  const update = (changes) => setTradepoint({ ...tradepoint, ...changes });

  const [saving, setSaving] = useState(false);
  const save = useCallback(async () => {
    setSaving(true);
    try {
      await updateTradepoint(
        id,
        _.pick(
          tradepoint,
          'address',
          'branch_id',
          'city',
          'extended_code',
          'network_extended_code',
          'format_id',
          'territory_id',
          'ir_code',
          'visit_ir_type',
          'kladr_id',
          'kladr_status',
          'latitude',
          'longitude',
          'name',
          'network_id',
          'project_id',
          'type',
          'cashbox_count',
          'update_with_user_tasks_import',
          'visit_interval_days',
        ),
      );
      toaster.show({ intent: Intent.SUCCESS, message: t('tt was changed'), icon: 'tick', timeout: 2000 });
      history.push(`/tradepoint/${id}`);
    } catch (e) {
      toaster.show({ intent: Intent.DANGER, message: e.message || t('error'), icon: 'error' });
      setSaving(false);
    }
  }, [tradepoint]);


  return (
    <Page title={title}>
      <TradepointCard
        fetching={tradepointState.fetching || saving}
        error={tradepointState.error}
        tradepoint={tradepoint}
        update={update}
        save={save}
        t={t}
      />
    </Page>
  );
};

TradepointEditScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default TradepointEditScreen;
