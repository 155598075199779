import i18n from 'i18n';
import store from 'app/state';
import * as ACTIONS from 'app/state/actions';
import * as api from 'app/api';
import * as utils from 'app/utils';
import { showError } from 'app/widgets/toaster';

export async function logout() {
  try {
    await api.postLogout();
    api.logoutChannel?.postMessage('logout');
    utils.history.push('/login');
    // требуется время чтобы произошёл переход на /login, прежде чем мы сбросим все данные
    // иначе рендер может сломаться, наткнувшись на неожиданный null где-нибудь
    setTimeout(() => store.dispatch({ type: ACTIONS.LOGOUT_DONE }), 1);
    return true;
  } catch (error) {
    showError(error.message || i18n.t('error'));
  }
};

