import { getTaskList, getUserById } from 'app/api';
import * as ACTIONS from 'app/state/actions';

import { $setIn, resolveAborting } from './index';

export const fetchTaskList = (filters, pagination) => async (dispatch, getState) => {
  try {
    const { result, aborted } = await resolveAborting(
      getTaskList(filters, pagination),
      'tasks',
      dispatch,
      getState,
    );
    if (aborted) {
      return;
    }

    const totalPages = Math.max(1, Math.ceil(result.rowcount / pagination.itemsPerPage));
    dispatch($setIn('tasks', {
      error: null,
      tasks: result.data,
      totalItems: result.rowcount,
      totalPages,
    }));
  } catch (error) {
    dispatch($setIn('tasks', {
      error,
    }));
  }
};

export const fetchTaskUser = (id) => async (dispatch) => {
  dispatch({ type: ACTIONS.TASK_LIST_FETCH_USER_START });
  try {
    const user = await getUserById(id);
    dispatch({ type: ACTIONS.TASK_LIST_FETCH_USER_DONE, user });
  } catch (error) {
    dispatch({ type: ACTIONS.TASK_LIST_FETCH_USER_ERROR, error });
  }
};

export const setFilters = (filters) => (
  { type: ACTIONS.TASK_LIST_SET_FILTERS, filters }
);

export const clearFilters = () => (
  { type: ACTIONS.TASK_LIST_CLEAR_FILTERS }
);

export const setPagination = (pagination) => (
  { type: ACTIONS.TASK_LIST_SET_PAGINATION, pagination }
);

export const setFilterVisible = (filtersVisible) => (
  { type: ACTIONS.TASK_LIST_SET_FILTER_VISIBLE, filtersVisible }
);
