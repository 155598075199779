import React from 'react';
import PropTypes from 'prop-types';
import { H5, Button, Card, Elevation, HTMLTable, Intent } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import { QUEST_TYPES } from 'app/config';
import actions from 'app/state/directActions';

import styles from './QuestionaryBindingScreen.module.css';
import BindingDialog from './BindingDialog';
import BindingPlanogramDialog from './BindingPlanogramDialog';
import Planogram from './Planogram';

function Questionary({ tradepointId, id, questionary }) {
  const { t } = useTranslation();
  const inProgress = RRU.useSelector(state => (
    !!state.questionaryTradepointRefs.bindingsInProgress[`${tradepointId}_${id}`]
  ));
  const onClickUnbind = RRU.useCallbackArgs(
    actions.questionaryTradepointRefs.toggleBindings,
    {
      tradepointId,
      questionaryIds: [id],
      bound: true,
    },
  );

  return (
    <tr>
      <td>
        <Link to={`/questionary/${id}`}>{questionary.name}</Link>

        <span className={styles.questionaryCode}>
          {' '}
          {questionary.code}
        </span>
      </td>

      <td>{t(QUEST_TYPES[questionary.type])}</td>

      <td>
        <Button
          minimal
          loading={inProgress}
          intent={Intent.DANGER}
          icon="cross"
          className={styles.removeQuestionaryButton}
          onClick={onClickUnbind}
        />
      </td>
    </tr>
  );
}

Questionary.propTypes = {
  tradepointId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  questionary: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default
function TradepointCard({ tradepoint }) {
  const { t } = useTranslation();

  const hasBindings = !_.isEmpty(tradepoint.questionaries);

  const inProgress = RRU.useSelector(state => (
    _.keys(state.questionaryTradepointRefs.bindingsInProgress)
      .some(key => key.startsWith(`${tradepoint.id}_`))
  ));
  const bindingInProgress = RRU.useSelector(state => (
    _.keys(state.planogramTradepointRefs.bindingsInProgress)
      .some(key => key.startsWith(`${tradepoint.id}_`))
  ));

  const [bindingDialogVisible, toggleBindingDialogVisible] = RRU.useToggle();
  const [bindingPlanogramDialogVisible, toggleBindingPlanogramDialogVisible] = RRU.useToggle();

  return (
    <Card elevation={Elevation.ONE} className={styles.tradepointCard}>
      <H5>
        {_.filter([tradepoint.network, tradepoint.address]).join(' - ')}

        <span className={styles.tradepointId}>
          {_.filter([tradepoint.code, tradepoint.ir_code]).join(' / ')}
        </span>
      </H5>

      <div className={styles.infoRow}>{t('contractor')}: {tradepoint.contragent}</div>

      {hasBindings && (
        <HTMLTable className={styles.boundQuestionariesTable} bordered>
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('type')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>

          <tbody>
            {_.toPairs(tradepoint.questionaries).map(([id, questionary]) => (
              <Questionary
                key={id}
                tradepointId={tradepoint.id}
                id={Number(id)}
                questionary={questionary}
              />
            ))}
            {_.toPairs(tradepoint.planograms).map(([id, planogram]) => (
              <Planogram
                key={id}
                tradepointId={tradepoint.id}
                id={Number(id)}
                planogram={planogram}
              />
            ))}
          </tbody>
        </HTMLTable>
      )}

      {!hasBindings && <p className={styles.emptyCard}>{t('without fixed questionnaires')}</p>}

      <Button
        minimal
        loading={inProgress}
        intent={Intent.PRIMARY}
        icon="add"
        text={t('pin questionnaire')}
        onClick={toggleBindingDialogVisible}
      />

      <Button
        minimal
        loading={bindingInProgress}
        intent={Intent.PRIMARY}
        icon="add"
        text={t('pin planogram')}
        onClick={toggleBindingPlanogramDialogVisible}
      />

      {bindingDialogVisible && (
        <BindingDialog tradepoint={tradepoint} onClose={toggleBindingDialogVisible} />
      )}

      {bindingPlanogramDialogVisible && (
        <BindingPlanogramDialog tradepoint={tradepoint} onClose={toggleBindingPlanogramDialogVisible} />
      )}
    </Card>
  );
}

TradepointCard.propTypes = {
  tradepoint: PropTypes.shape({
    id: PropTypes.number.isRequired,
    network: PropTypes.string,
    address: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    ir_code: PropTypes.string,
    contragent: PropTypes.string.isRequired,
    questionaries: PropTypes.object.isRequired,
    planograms: PropTypes.object.isRequired,
  }).isRequired,
};
