import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Intent } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import * as selectors from 'app/state/reducers';
import actions from 'app/state/directActions';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import * as Filters from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';

import EditDialog from './EditDialog';

export default
function QuestionaryCategoriesListScreen() {
  const { t } = useTranslation();
  const canEdit = RRU.useSelector(selectors.canEditQuestionaryCategories);

  return (
    <Page title={t('questionaryCategories')}>
      <Grid path={api.QUESTIONARY_CATEGORIES}>
        <Grid.Filter
          acceptor={Filters.AgencyContragentFilter}
          path="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="code__ilike"
          name={t('code')}
          placeholder={t('searchByCode')}
        />

        {canEdit && (
          <Grid.Actions>
            <Button
              intent={Intent.SUCCESS}
              icon="add"
              onClick={actions.questionaryCategories.openEditDialog}
              text={t('create')}
            />
          </Grid.Actions>
        )}

        <Grid.Column path="contragent" title={t('contractor')} sortable />
        <Grid.Column path="code" title={t('code')} sortable />
        <Grid.Column path="name" title={t('name')} sortable />
      </Grid>

      <EditDialog />
    </Page>
  );
};

