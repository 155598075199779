import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumericInput } from '@blueprintjs/core';
import { useDebounce } from 'app/utils';
import styles from './Filters.module.css';

const IdFilter = ({
  name,
  value,
  onChange,
  withDebounce,
  placeholder,
  maxLength,
}) => {
  const [stateValue, setStateValue] = useState(value);
  const debouncedValue = useDebounce(stateValue, 500);
  useEffect(
    () => {
      if (!withDebounce && value !== stateValue) {
        onChange(stateValue);
      }
    },
    [stateValue],
  );
  useEffect(
    () => {
      if (withDebounce && value !== debouncedValue) {
        onChange(debouncedValue);
      }
    },
    [debouncedValue],
  );
  useEffect(
    () => { setStateValue(value); },
    [value],
  );
  return (
    <div className={styles.filtersGroup}>
      <div className={styles.filtersLabel}>{name}:</div>
      <NumericInput
        leftIcon="filter"
        fill
        buttonPosition="none"
        placeholder={placeholder || name}
        value={stateValue ?? ''}
        onValueChange={(val) => setStateValue(val || undefined)}
        maxLength={maxLength}
      />
    </div>
  );
};

IdFilter.propTypes = {
  value: PropTypes.any,
  withDebounce: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

IdFilter.defaultProps = {
  value: undefined,
  withDebounce: true,
  placeholder: undefined,
  maxLength: undefined,
};

export default IdFilter;
