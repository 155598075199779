import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Button, Intent, InputGroup, Icon, Switch } from '@blueprintjs/core';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import { dispatch } from 'app/state';
import { USERS_REFERENCES } from 'app/api';
import actions from 'app/state/directActions';
import { flushFilters } from 'app/state/actionCreators/references';
import { isAdmin, isAgencyAdmin, getContragents, canSetPassword as getCanSetPassword } from 'app/state/reducers';
import { setUserField } from 'app/state/actionCreators/teamUser';
import { fetchContragents } from 'app/state/actionCreators/contragents';
import { RoleSelect, ContragentSelect } from 'app/widgets/ReferenceSelect';
import Form from 'app/widgets/Form';
import { showError } from 'app/widgets/toaster';

import SetPasswordDialog from './SetPasswordDialog';
import Contragents from './Contragents';
import styles from './UserEditScreen.module.css';

function MaskedInput({ mask, value, onChange, ...props }) {
  const { t } = useTranslation();

  return (
    <InputMask
      mask={mask.replaceAll('_', '9')}
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value, !e.target.value.includes('_') || t('not completely filled'))}
      {...props}
    >
      {(inputProps) => <InputGroup placeholder={mask} {...inputProps} />}
    </InputMask>
  );
}

MaskedInput.propTypes = {
  mask: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

MaskedInput.defaultProps = {
  value: null,
};

function handleContragentsChange({ exclusive, onChange }, newValue) {
  if (exclusive) {
    onChange(newValue ? [newValue] : null);
  } else {
    onChange(newValue);
  }
}

const ExclusiveContragentSelect = React.memo(({ exclusive, value, onChange, ...props }) => {
  const coercedValue = exclusive ? (value?.[0] ?? null) : value;
  const onChangeWrapper = utils.useCallbackWithDeps(handleContragentsChange, { exclusive, onChange });

  return <ContragentSelect multi={!exclusive} value={coercedValue} onChange={onChangeWrapper} {...props} />;
});

ExclusiveContragentSelect.propTypes = {
  exclusive: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.number.isRequired),
  onChange: PropTypes.func.isRequired,
};

ExclusiveContragentSelect.defaultProps = {
  exclusive: false,
  value: null,
};

async function onToggleConfirm(history, userId) {
  if (await actions.teamUser.toggleUser()) {
    history.push(`/user/${userId}`);
  }
}

const ExistingUserActions = React.memo(() => {
  const [alertIsOpen, toggleAlertIsOpen] = utils.useToggle();
  const [pwDialogIsOpen, togglePwDialogIsOpen] = utils.useToggle();
  const [webPwDialogIsOpen, toggleWebPwDialogIsOpen] = utils.useToggle();
  const { t } = useTranslation();

  const user = useSelector((state) => state.teamUser.user);
  const webPasswordForbidden = (
    user.roles?.includes('merch')
    &&
    user.roles?.every(r => ['merch', 'general_user', 'pilot_tester', 'trainee', 'trainee_2'].includes(r))
  );
  const canSetPassword = useSelector(getCanSetPassword);

  const history = useHistory();

  return (
    <>
      <Button
        icon={user.active ? 'lock' : 'unlock'}
        intent={user.active ? Intent.DANGER : Intent.PRIMARY}
        onClick={toggleAlertIsOpen}
        text={user.active ? t('block') : t('unblock')}
      />

      {canSetPassword && (
        <Button
          icon="key"
          intent={Intent.PRIMARY}
          onClick={togglePwDialogIsOpen}
          text={t('set password')}
        />
      )}
      {pwDialogIsOpen && <SetPasswordDialog web={false} close={togglePwDialogIsOpen} />}

      {canSetPassword && (
        <Button
          icon="key"
          intent={Intent.PRIMARY}
          onClick={toggleWebPwDialogIsOpen}
          text={t('set password web')}
          disabled={webPasswordForbidden}
        />
      )}
      {webPwDialogIsOpen && <SetPasswordDialog web close={toggleWebPwDialogIsOpen} />}

      <Alert
        cancelButtonText={t('no')}
        confirmButtonText={t('yes')}
        icon={user.active ? 'lock' : 'unlock'}
        intent={user.active ? Intent.DANGER : Intent.PRIMARY}
        isOpen={alertIsOpen}
        onCancel={toggleAlertIsOpen}
        onConfirm={() => onToggleConfirm(history, user.id)}
      >
        {user.active ? t('block') : t('unblock')} {t('user s')} <b>{user.fullname}</b>?
      </Alert>
    </>
  );
});

const rxEmail = /^[^\s@]+@[^\s@]+$/;

function onFieldChange(key, value) {
  dispatch(setUserField(key, value));
  if (key === 'contragentIds') {
    dispatch(flushFilters(USERS_REFERENCES));
  }
}

export default React.memo(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const [valid, setValid] = useState(true);
  const user = utils.useSelectorMemo((state) => state.teamUser.user);
  const isManagerRequired = utils.useSelectorMemo((state) => (
    !isAdmin(state)
    && !isAgencyAdmin(state)
    && state.user.user.id !== state.teamUser.user.id
  ));
  const isContragentRequired = !user.roles?.includes('admin');
  // админ, или редактируем не себя
  const canEditRoles = utils.useSelectorMemo((state) => (
    isAdmin(state)
    || isAgencyAdmin(state)
    || state.user.user.id !== state.teamUser.user.id
  ));
  const contragents = utils.useSelectorMemo(getContragents);
  const userContragentIds = user?.contragentIds || [];
  const invalidContragents = userContragentIds.some((id) => user.contragents[id]?.invalid);
  const snilsRequired = userContragentIds.some(
    id => !contragents.find(c => c.id === id)?.settings.create_users_from_user_task_import,
  );

  utils.useDispatchEffect(fetchContragents(), []);

  async function onSave(history) {
    let exclusiveCount = 0;

    const exclusiveOnlyIds = (contragents || [])
      .filter((el) => el.exclusive)
      .map((el) => el.id);

    userContragentIds.forEach((id) => {
      if (exclusiveOnlyIds.includes(id)) {
        exclusiveCount++
      }
    });

    if (exclusiveCount > 1) {
      showError(t('selected more than 1 exclusives'));
      return;
    }

    const userId = await actions.teamUser.saveUser();
    if (userId) {
      history.push(`/user/${userId}`);
    }
  }

  const validateEmail = (string) => {
    if (!rxEmail.test(string)) {
      return t('invalid e-mail address');
    }
    return null;
  };

  const validateRoles = (roles) => {
    if (!roles?.includes('trainee') && !roles?.includes('trainee_2')) {
      return true;
    }

    if (!roles.includes('merch')) {
      return t('without the merchandiser role will be inactive');
    }

    const allowedAdjoinedRoles = [
      'trainee',
      'trainee_2',
      'merch',
      'general_user',
      'test_user',
      'pilot_tester',
    ];
    if (roles.some((r) => !allowedAdjoinedRoles.includes(r))) {
      return t('intern can only be merchandiser');
    }

    return true;
  };

  return (
    <>
      <div className={styles.formContainer}>
        <Form className={styles.userForm} value={user} onFieldChange={onFieldChange} onValidChange={setValid}>
          <Form.Item label={t('surname')} name="lastname" />
          <Form.Item label={t('first name')} name="firstname" required />
          <Form.Item label={t('middle name')} name="secondname" />
          <Form.Item
            label={t('phone number')}
            name="phone"
            acceptor={MaskedInput}
            required
            mask="+7 (___) ___-__-__"
          />
          <Form.Item
            label={t('additional phone number')}
            name="home_phone"
            acceptor={MaskedInput}
            mask="+7 (___) ___-__-__"
          />
          <Form.Item label={t('email')} name="email" validator={validateEmail} />
          {canEditRoles && (
            <Form.Item
              label={t('roles')}
              name="roles"
              required
              validator={validateRoles}
              acceptor={RoleSelect}
              filters={{ managed: true }}
            />
          )}
          <Form.Item
            label={t('snils')}
            name="snils"
            acceptor={MaskedInput}
            required={snilsRequired}
            mask="___-___-___ __"
          />
          <Form.Item
            label={t('group')}
            name="group"
          />

          <Form.Item
            label={t('contractors')}
            name="contragentIds"
            required={isContragentRequired}
            acceptor={ExclusiveContragentSelect}
            exclusive={user.exclusive}
          />

          <Form.Item
            label={t('multiManager')}
            name="multi_manager"
            acceptor={Switch}
          />
        </Form>
        <Icon icon="person" size={80} />
      </div>

      <Contragents isManagerRequired={isManagerRequired} canEdit={canEditRoles} />

      <div className={styles.buttonGroup}>
        <Link to={user.id ? `/user/${user.id}` : '/team'}>
          <Button icon="cross" text={t('cancel')} />
        </Link>

        {user.id && <ExistingUserActions />}

        <Button
          disabled={!valid || invalidContragents}
          icon="tick"
          intent={Intent.PRIMARY}
          onClick={() => onSave(history)}
          text={t('save')}
        />
      </div>
    </>
  );
});
