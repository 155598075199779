import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Switch } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import * as selectors from 'app/state/reducers';
import DaterangeFilter from 'app/widgets/Filters/DaterangeFilter';
import ComboFilter from 'app/widgets/Filters/ComboFilter';
import InputFilter from 'app/widgets/Filters/InputFilter';
import Grid from 'app/widgets/Grid';
import {
  AgencyContragentFilter,
  BranchFilter,
  TradeNetworkFilter,
  TradepointProjectFilter,
  TradepointFormatFilter,
  UserFilter,
} from 'app/widgets/Filters';
import IdFilter from 'app/widgets/Filters/IdFilter';

import styles from './EventsScreen.module.css';

function Filters({ filters, filtersVisible, setFilters, clearFilters, }) {
  const { t } = useTranslation();
  const hideVisitUsers = RRU.useSelector(selectors.needHideVisitUsers);
  const hideVisitMerch = RRU.useSelector(selectors.needHideVisitMerch);
  const hasContragentRole = RRU.useSelector(state => selectors.hasRole(state, 'contragent'));

  return (
    <Grid.Filters
      visible={filtersVisible}
      filters={filters}
      setFilter={(key, value) => setFilters({ ...filters, [key]: value })}
      clearFilters={clearFilters}
    >
      <Grid.Filter
        acceptor={AgencyContragentFilter}
        path="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={DaterangeFilter}
        path="expired_time"
        name={t('period of execution')}
        value={[filters.expired_time__from, filters.expired_time__to]}
        onChange={(selectedRange) => {
          setFilters({
            ...filters,
            expired_time__from: selectedRange[0],
            expired_time__to: selectedRange[1],
          });
        }}
        shortcuts="weekToWeek"
      />
      <Grid.Filter
        acceptor={DaterangeFilter}
        path="end_time"
        name={t('date of completion')}
        value={[filters.end_time__from, filters.end_time__to]}
        onChange={(selectedRange) => {
          setFilters({
            ...filters,
            end_time__from: selectedRange[0],
            end_time__to: selectedRange[1],
          });
        }}
        shortcuts="weekToWeek"
      />
      <Grid.Filter
        acceptor={DaterangeFilter}
        path="create_time"
        name={t('create date')}
        value={[filters.create_time__from, filters.create_time__to]}
        onChange={(selectedRange) => {
          setFilters({
            ...filters,
            create_time__from: selectedRange[0],
            create_time__to: selectedRange[1],
          });
        }}
        shortcuts="weekToWeek"
      />
      <Grid.Filter
        acceptor={ComboFilter}
        path="status__eq"
        name={t('task status')}
        items={[
          {
            key: 'new',
              value: 'new',
              title: t('new'),
          },
            {
              key: 'SKIPPED',
              value: 'SKIPPED',
              title: t('failed to execute'),
            },
            {
              key: 'COMPLETED',
              value: 'COMPLETED',
              title: t('completed'),
            },
        ]}
      />
      {!hideVisitUsers && (
        <Grid.Filter
          acceptor={UserFilter}
          path="author_id__eq"
          name={t('author')}
          dependsOn="contragent_id__eq"
        />
      )}
      {!hideVisitUsers && !hideVisitMerch && (
        <Grid.Filter
          acceptor={UserFilter}
          path="user_id__eq"
          name={t('executor')}
          dependsOn="contragent_id__eq"
        />
      )}
      {hasContragentRole && hideVisitUsers && (
        <Grid.Filter
          acceptor={IdFilter}
          path="author_id__eq"
          withDebounce
          name={t('authorId')}
          placeholder={t('searchByAuthorId')}
        />
      )}
      {hasContragentRole && (hideVisitUsers || hideVisitMerch) && (
        <Grid.Filter
          acceptor={IdFilter}
          path="user_id__eq"
          withDebounce
          name={t('executor id')}
          placeholder={t('searchByExecutorId')}
        />
      )}
      <Grid.Filter
        acceptor={BranchFilter}
        path="branch_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={TradeNetworkFilter}
        path="network_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={TradepointProjectFilter}
        path="project_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={TradepointFormatFilter}
        path="format_id__in"
        dependsOn="contragent_id__eq"
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="address__like"
        name={t('search by address')}
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="code__like"
        withDebounce
        name={t('point code')}
        placeholder={t('search by point code')}
      />
      <Grid.Filter
        acceptor={InputFilter}
        path="id__eq"
        onlyDigits
        name={t('task id')}
        placeholder={t('search by task id')}
      />
      <Grid.Filter
        acceptor={Switch}
        path="is_expired"
        className={styles.switchFilter}
        label={t('is expired')}
        onChange={() => setFilters({ ...filters, is_expired: !filters.is_expired || undefined })}
      />
    </Grid.Filters>
  );
}

Filters.propTypes = {
  filters: PropTypes.shape({
    agency_id__eq: PropTypes.number,
    contragent_id__eq: PropTypes.number,
    expired_time__from: PropTypes.instanceOf(Date),
    expired_time__to: PropTypes.instanceOf(Date),
    end_time__from: PropTypes.instanceOf(Date),
    end_time__to: PropTypes.instanceOf(Date),
    create_time__from: PropTypes.instanceOf(Date),
    create_time__to: PropTypes.instanceOf(Date),
    address__like: PropTypes.string,
    code__like: PropTypes.string,
    status__eq: PropTypes.string,
    network_id__in: PropTypes.arrayOf(PropTypes.number),
    author_id__eq: PropTypes.number,
    user_id__eq: PropTypes.number,
    supervisor_id__eq: PropTypes.number,
    id__eq: PropTypes.number,
    is_expired: PropTypes.bool,
  }).isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

export default Filters;
