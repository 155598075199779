import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { H2, Tree, Classes, Spinner, NonIdealState, Icon, Intent } from '@blueprintjs/core';
import { withTranslation } from 'react-i18next';

import { rolesToNames } from 'app/state/reducers';
import { dispatch } from 'app/state';
import { fetchRoles } from 'app/state/actionCreators/roles';
import { fetchTeamUsers, setFilters, setContragent } from 'app/state/actionCreators/team';
import { userType } from 'app/proptyping';
import Page from 'app/widgets/Page';

import Filters from './Filters';
import styles from './TeamScreen.module.css';

const treeContent = (users, collapsedIds, rolesMap) => {
  const mapById = {};
  const mapByParentId = {};
  const mapByKey = {};
  users.forEach((user) => {
    if (!mapByParentId[user.parentId]) {
      mapByParentId[user.parentId] = [];
    }
    mapByParentId[user.parentId].push(user);
    mapByKey[`${user.id}-${user.parentId}`] = user;
    mapById[user.id] = user;
  });

  function userToTreeItem(user) {
    const role = rolesToNames(user.roles, rolesMap);
    const label = _.filter([
      role,
      user.fullname,
      user.phone && `(${user.phone})`,
      user.email && `(${user.email})`,
    ]).join(' ');

    return {
      id: `${user.id}-${user.parentId}`,
      key: user.id,
      hasCaret: !!mapByParentId[user.id],
      icon: user.active ? 'person' : (
        <Icon
          icon="lock"
          intent={Intent.DANGER}
          className={Classes.TREE_NODE_ICON}
        />
      ),
      intent: user.active ? Intent.NONE : Intent.DANGER,
      label: (
        <div className={styles.userLabelRow}>
          <Link to={`/user/${user.id}`} className={styles.userLabel}>
            {label}
          </Link>
        </div>
      ),
      childNodes: [],
      isExpanded: collapsedIds.indexOf(`${user.id}-${user.parentId}`) === -1,
    };
  }

  function buildChilds(id) {
    if (!mapByParentId[id]) {
      return [];
    }
    return mapByParentId[id].map((user) => ({
      ...userToTreeItem(user),
      childNodes: buildChilds(user.id),
    }));
  }

  const root = [];
  users.forEach((user) => {
    if (!mapById[user.parentId]) {
      root.push(userToTreeItem(mapByKey[`${user.id}-${user.parentId}`]));
    }
  });

  return root.map((node) => ({ ...node, childNodes: buildChilds(node.key) }));
};

class TeamScreen extends Component {
  constructor() {
    super();
    this.state = {
      filtersVisible: true,
      collapsedIds: [],
    };
  }

  componentDidMount() {
    const { contragentId } = this.props;
    dispatch(fetchRoles());
    if (contragentId) {
      dispatch(fetchTeamUsers(contragentId));
    }
  }

  onChangeContragent = (id) => {
    if (id) {
      dispatch(setContragent(id));
      dispatch(fetchTeamUsers(id));
    }
  }

  onNodeExpand(node) {
    const { collapsedIds } = this.state;
    this.setState({ collapsedIds: collapsedIds.filter((id) => id !== node.id) });
  }

  onNodeCollapse(node) {
    const { collapsedIds } = this.state;
    this.setState({ collapsedIds: [...collapsedIds, node.id] });
  }

  onNodeDoubleClick(node) {
    const { history } = this.props;
    history.push(`/user/${node.id}`);
  }

  setFiltersVisible = (filtersVisible) => {
    this.setState({ filtersVisible });
  }

  clearFilters = () => {
    const filters = { showBlocked: false, name: '' };
    dispatch(setContragent(null));
    dispatch(setFilters(filters));
  }

  filterUsers = (users, filters) => {
    let filtered = users;

    if (!filters.showBlocked) {
      filtered = filtered.filter((u) => u.active);
    }

    if (filters.name) {
      filtered = filtered.filter((u) => {
        const name = _.filter([
          u.fullname,
          u.phone && `(${u.phone})`,
          u.email && `(${u.email})`,
        ]).join(' ');
        return name.toUpperCase().indexOf(filters.name.toUpperCase()) !== -1;
      });
    }

    if (filters.roleCode) {
      filtered = filtered.filter((u) => u.roles.includes(filters.roleCode));
    }

    return filtered;
  }

  renderTree() {
    const { collapsedIds } = this.state;
    const {
      filters,
      fetching,
      error,
      users,
      rolesMap,
      t,
    } = this.props;

    if (fetching) {
      return <Spinner />;
    }
    if (error) {
      return <NonIdealState title={t('error')} description={error.message} icon="error" />;
    }

    const filtered = this.filterUsers(users, filters);
    const contents = treeContent(filtered, collapsedIds, rolesMap);

    if (!filtered || !filtered.length) {
      return (
        <NonIdealState
          title={t('empty')}
          description={t('no matching users found')}
          icon="list"
        />
      );
    }

    return (
      <Tree
        contents={contents}
        onNodeCollapse={(node) => this.onNodeCollapse(node)}
        onNodeExpand={(node) => this.onNodeExpand(node)}
        onNodeDoubleClick={(node) => this.onNodeDoubleClick(node)}
        className={Classes.ELEVATION_0}
      />
    );
  }

  render() {
    const { canEdit, filters, contragentId, t } = this.props;
    const { filtersVisible } = this.state;

    return (
      <Page>
        <div className={styles.header}>
          <H2>{t('team')}</H2>
        </div>
        <Filters
          canEdit={canEdit}
          filters={filters}
          filtersVisible={filtersVisible}
          setFilters={(value) => dispatch(setFilters(value))}
          setFiltersVisible={this.setFiltersVisible}
          clearFilters={this.clearFilters}
          contragentId={contragentId}
          onChangeContragent={this.onChangeContragent}
          t={t}
        />
        {this.renderTree()}
      </Page>
    );
  }
}

TeamScreen.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.instanceOf(Error),
    PropTypes.bool,
  ]),
  users: PropTypes.arrayOf(userType).isRequired,
  filters: PropTypes.shape({
    active: PropTypes.bool,
    roleCode: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  contragentId: PropTypes.number,
  rolesMap: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

TeamScreen.defaultProps = {
  error: null,
  contragentId: undefined,
};

const mapStateToProps = (state) => ({
  users: state.team.users,
  filters: state.team.filters,
  contragentId: state.team.contragentId,
  fetching: state.team.fetching || state.roles.fetching,
  error: state.team.error || state.roles.error,
  rolesMap: state.roles.rolesMap,
  canEdit: state.user.user?.rights.includes('webapp_users_edit') ?? false,
});

export default connect(mapStateToProps)(withTranslation()(TeamScreen));
