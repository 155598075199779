import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_STATE = {
  running: false,
  error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_RESTORED_TASK_CREATE_START:
      return _.assign(state, { running: true, error: null });
    case ACTIONS.CREATE_RESTORED_TASK_EDIT_START:
      return _.assign(state, { running: true, error: null });
    case ACTIONS.CREATE_RESTORED_TASK_CREATE_DONE:
      return _.assign(state, { running: false, error: null });
    case ACTIONS.CREATE_RESTORED_TASK_EDIT_DONE:
      return _.assign(state, { running: false, error: null });
    case ACTIONS.CREATE_RESTORED_TASK_CREATE_ERROR:
      return _.assign(state, { running: false, error: action.error });
    case ACTIONS.CREATE_RESTORED_TASK_EDIT_ERROR:
      return _.assign(state, { running: false, error: action.error });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
