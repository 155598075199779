import React, { PureComponent } from 'react';
import { Select } from '@blueprintjs/select';
import { Button, MenuItem, Menu, Intent, Position, Spinner } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { escapeRegExpChars, highlightSearch } from 'app/utils/strings';
import styles from './index.module.css';

class SimpleSelect extends PureComponent {
  constructor() {
    super();

    this.state = {
      search: '',
    };
  }

  onSearchChange(search) {
    this.setState({ search });
  }

  filterItem = (query, item) => {
    if (query.trim() === '') {
      return true;
    }
    if (!item.title) {
      return false;
    }
    return item.title.toString().match(new RegExp(escapeRegExpChars(query), 'i')) !== null;
  }

  highlightSearch(text) {
    const { search } = this.state;
    return highlightSearch(text, search);
  }

  renderEmptier(active) {
    const { emptyText, onChange, t } = this.props;
    const emptyTextExact = emptyText ? emptyText : t('not chosen');
    return (
      <MenuItem
        active={active}
        intent={active ? Intent.PRIMARY : undefined}
        key="emptier"
        text={emptyTextExact}
        onClick={() => onChange(null)}
      />
    );
  }

  renderItem(item, { handleClick, modifiers }) {
    if (item.isEmptier) {
      return this.renderEmptier(modifiers.active);
    }
    return (
      <MenuItem
        icon={item.icon}
        active={modifiers.active}
        intent={modifiers.active ? Intent.PRIMARY : undefined}
        key={_.toString(item.key ?? item.value)}
        text={this.highlightSearch(item.title)}
        onClick={handleClick}
        multiline
      />
    );
  }

  renderItemList = ({ items, itemsParentRef, renderItem }) => {
    const { error, t } = this.props;
    let renderedItems = items.map(renderItem).filter((item) => item != null);
    if (renderedItems.length === 0) {
      renderedItems = (<MenuItem disabled text={t('not found')} />);
    }
    if (error) {
      const message = error.message || t('error');
      renderedItems = <MenuItem className={styles.error} disabled text={message} />;
    }
    return (
      <Menu ulRef={itemsParentRef}>
        {renderedItems}
      </Menu>
    );
  }

  render() {
    const { search } = this.state;
    const {
      items,
      icon,
      emptyText: propEmptyText,
      filterable,
      emptiable,
      value,
      onChange,
      menuAlign,
      fetching,
      disabled,
      placeholder: propPlaceholder,
      t,
    } = this.props;

    const placeholder = propPlaceholder ? propPlaceholder : `${t('searching')}...`;
    const emptyText = propEmptyText ? propEmptyText : t('not chosen');

    const selectedItem = items.find((i) => i.value === value);
    let itemsToRender = items.filter((item) => ['number', 'string'].includes(typeof item.title));
    if (filterable && search) {
      itemsToRender = itemsToRender.filter((item) => this.filterItem(search, item));
    }
    if (emptiable && !search) {
      itemsToRender = [
        { isEmptier: true },
        ...itemsToRender,
      ];
    }

    const inputProps = {
      placeholder,
      rightElement: fetching ? <Spinner size={Spinner.SIZE_SMALL} /> : undefined,
    };

    return (
      <Select
        disabled={disabled}
        items={itemsToRender}
        itemRenderer={(item, opts) => this.renderItem(item, opts)}
        onItemSelect={(item) => onChange(item.value)}
        onQueryChange={(query) => this.onSearchChange(query)}
        itemPredicate={(query, item) => this.filterItem(query, item)}
        filterable={filterable}
        itemListRenderer={(params) => this.renderItemList(params)}
        popoverProps={{ position: menuAlign }}
        resetOnClose
        inputProps={inputProps}
      >
        <Button
          disabled={disabled}
          icon={icon}
          rightIcon="caret-down"
          text={selectedItem ? selectedItem.title : emptyText}
        />
      </Select>
    );
  }
}

SimpleSelect.propTypes = {
  icon: PropTypes.string,
  emptyText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    value: PropTypes.any,
    key: PropTypes.any,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  filterable: PropTypes.bool,
  value: PropTypes.any,
  emptiable: PropTypes.bool,
  menuAlign: PropTypes.string,
  placeholder: PropTypes.string,
  fetching: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  t: PropTypes.func.isRequired,
};

SimpleSelect.defaultProps = {
  emptyText: '',
  icon: null,
  filterable: false,
  emptiable: false,
  fetching: false,
  disabled: false,
  value: null,
  error: null,
  menuAlign: Position.BOTTOM_LEFT,
  placeholder: '',
};

export default withTranslation()(SimpleSelect);
