import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import ReportPage from 'app/widgets/ReportPage';
import Form from 'app/widgets/Form';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';
import * as RefSel from 'app/widgets/ReferenceSelect';
import { canViewAgencies } from 'app/state/reducers';

const INITIAL_FILTERS = { date__between: [moment().subtract(7, 'days').toDate(), new Date()] };

export default
function ReportTerritoryWorkingTime() {
  const { t } = useTranslation();
  const showAgencyFilter = RRU.useSelector(canViewAgencies);

  return (
    <ReportPage
      title={t('territoryWorkingTimeReport')}
      backgroundExportTaskType="TERRITORY_WORKING_TIME_REPORT"
      initialFilters={INITIAL_FILTERS}
    >
      {showAgencyFilter && (
        <Form.Item
          name="agency_id__eq"
          label={t('agency')}
          acceptor={RefSel.AgencySelect}
          autoSelectFirst
          multi={false}
        />
      )}
      <Form.Item
        name="contragent_id__eq"
        label={t('contractor')}
        dependsOn="agency_id__eq"
        acceptor={RefSel.ContragentSelect}
        autoSelectFirst
        multi={false}
      />
      <Form.Item
        name="date__between"
        label={t('visit period')}
        acceptor={LocalizedDateRangeInput}
        shortcuts="months"
      />
      <Form.Item
        name="territory_id__in"
        label={t('territory')}
        acceptor={RefSel.TradepointTerritorySelect}
      />
      <Form.Item
        name="user_id__in"
        label={t('executor')}
        dependsOn="contragent_id__eq"
        acceptor={RefSel.UserSelect}
        placeholder={t('search by executor')}
        filters={{ roles__overlap: ['merch'] }}
      />
    </ReportPage>
  );
}
