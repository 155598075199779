
import { makeEmptyReducer } from './utils';

export const INITIAL_STATE = {
  createVisitDialog: {
    isOpen: false,
    form: {},
    fetching: false,
  },
  createAuditDialog: {
    isOpen: false,
    form: {},
  },
  completeVisitDialog: {
    isOpen: false,
    task: null,
    form: {},
    fetching: false,
  },
  editDialog: {
    isOpen: false,
    tasks: null,
    form: {},
    fetching: false,
  },
};

export default makeEmptyReducer(INITIAL_STATE);
