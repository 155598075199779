/* eslint-disable max-len */
export const LOGIN = 'LOGIN';

export const USER_FETCH_START = 'USER_FETCH_START';
export const USER_FETCH_DONE = 'USER_FETCH_DONE';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';

export const TEAM_USERS_SET_FILTERS = 'TEAM_USERS_SET_FILTERS';
export const TEAM_USERS_SET_CONTRAGENT = 'TEAM_USERS_SET_CONTRAGENT';
export const TEAM_EXPORT_START = 'TEAM_EXPORT_START';
export const TEAM_EXPORT_DONE = 'TEAM_EXPORT_DONE';
export const TEAM_EXPORT_ERROR = 'TEAM_EXPORT_ERROR';

export const CREATE_RESTORED_TASK_NEW = 'CREATE_RESTORED_TASK_NEW';
export const CREATE_RESTORED_TASK_FETCH_START = 'CREATE_RESTORED_TASK_FETCH_START';
export const CREATE_RESTORED_TASK_FETCH_DONE = 'CREATE_RESTORED_TASK_FETCH_DONE';
export const CREATE_RESTORED_TASK_FETCH_ERROR = 'CREATE_RESTORED_TASK_FETCH_ERROR';
export const CREATE_RESTORED_TASK_MARK_PHOTOS_FOR_REUPLOAD = 'CREATE_RESTORED_TASK_MARK_PHOTOS_FOR_REUPLOAD';
export const CREATE_RESTORED_TASK_CLEAR_QUESTIONS = 'CREATE_RESTORED_TASK_CLEAR_QUESTIONS';
export const CREATE_RESTORED_TASK_UPDATE = 'CREATE_RESTORED_TASK_UPDATE';
export const CREATE_RESTORED_TASK_UPDATE_QUESTION = 'CREATE_RESTORED_TASK_UPDATE_QUESTION';
export const CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_START = 'CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_START';
export const CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_DONE = 'CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_DONE';
export const CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_ERROR = 'CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_ERROR';
export const CREATE_RESTORED_TASK_CLEAR_SHOP_PHOTO = 'CREATE_RESTORED_TASK_CLEAR_SHOP_PHOTO';
export const CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_START = 'CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_START';
export const CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_DONE = 'CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_DONE';
export const CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_ERROR = 'CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_ERROR';
export const CREATE_RESTORED_TASK_REMOVE_QUESTION_PHOTO = 'CREATE_RESTORED_TASK_REMOVE_QUESTION_PHOTO';
export const CREATE_RESTORED_TASK_LOAD_QUESTIONS_START = 'CREATE_RESTORED_TASK_LOAD_QUESTIONS_START';
export const CREATE_RESTORED_TASK_LOAD_QUESTIONS_DONE = 'CREATE_RESTORED_TASK_LOAD_QUESTIONS_DONE';
export const CREATE_RESTORED_TASK_LOAD_QUESTIONS_ERROR = 'CREATE_RESTORED_TASK_LOAD_QUESTIONS_ERROR';
export const CREATE_RESTORED_TASK_CREATE_START = 'CREATE_RESTORED_TASK_CREATE_START';
export const CREATE_RESTORED_TASK_CREATE_DONE = 'CREATE_RESTORED_TASK_CREATE_DONE';
export const CREATE_RESTORED_TASK_CREATE_ERROR = 'CREATE_RESTORED_TASK_CREATE_ERROR';
export const CREATE_RESTORED_TASK_EDIT_START = 'CREATE_RESTORED_TASK_EDIT_START';
export const CREATE_RESTORED_TASK_EDIT_DONE = 'CREATE_RESTORED_TASK_EDIT_DONE';
export const CREATE_RESTORED_TASK_EDIT_ERROR = 'CREATE_RESTORED_TASK_EDIT_ERROR';

export const TASK_LIST_SET_FILTERS = 'TASK_LIST_SET_FILTERS';
export const TASK_LIST_CLEAR_FILTERS = 'TASK_LIST_CLEAR_FILTERS';
export const TASK_LIST_SET_PAGINATION = 'TASK_LIST_SET_PAGINATION';
export const TASK_LIST_SET_FILTER_VISIBLE = 'TASK_LIST_SET_FILTER_VISIBLE';
export const TASK_LIST_FETCH_USER_START = 'TASK_LIST_FETCH_USER_START';
export const TASK_LIST_FETCH_USER_DONE = 'TASK_LIST_FETCH_USER_DONE';
export const TASK_LIST_FETCH_USER_ERROR = 'TASK_LIST_FETCH_USER_ERROR';

export const EVENT_LIST_SET_FILTERS = 'EVENT_LIST_SET_FILTERS';
export const EVENT_LIST_CLEAR_FILTERS = 'EVENT_LIST_CLEAR_FILTERS';
export const EVENT_LIST_SET_PAGINATION = 'EVENT_LIST_SET_PAGINATION';
export const EVENT_LIST_SET_FILTER_VISIBLE = 'EVENT_LIST_SET_FILTER_VISIBLE';

export const EVENT_TRADEPOINT_FETCH_START = 'EVENT_TRADEPOINT_FETCH_START';
export const EVENT_TRADEPOINT_FETCH_DONE = 'EVENT_TRADEPOINT_FETCH_DONE';
export const EVENT_TRADEPOINT_FETCH_ERROR = 'EVENT_TRADEPOINT_FETCH_ERROR';
export const EVENT_EXPORT_START = 'EVENT_EXPORT_START';
export const EVENT_EXPORT_ERROR = 'EVENT_EXPORT_ERROR';
export const EVENT_EXPORT_DONE = 'EVENT_EXPORT_DONE';

export const TRADEPOINT_FETCH_START = 'TRADEPOINT_FETCH_START';
export const TRADEPOINT_FETCH_DONE = 'TRADEPOINT_FETCH_DONE';
export const TRADEPOINT_FETCH_ERROR = 'TRADEPOINT_FETCH_ERROR';

export const KLADR_FETCH_START = 'KLADR_FETCH_START';
export const KLADR_FETCH_DONE = 'KLADR_FETCH_DONE';
export const KLADR_FETCH_ERROR = 'KLADR_FETCH_ERROR';
export const KLADR_UPDATE_KLADR = 'KLADR_UPDATE_KLADR';

export const QUESTIONARY_ACTIVITY_TYPES_FETCH_START = 'QUESTIONARY_ACTIVITY_TYPES_FETCH_START';
export const QUESTIONARY_ACTIVITY_TYPES_FETCH_DONE = 'QUESTIONARY_ACTIVITY_TYPES_FETCH_DONE';
export const QUESTIONARY_ACTIVITY_TYPES_FETCH_ERROR = 'QUESTIONARY_ACTIVITY_TYPES_FETCH_ERROR';

export const TRADEPOINT_AUDIT_LIST_SET_FILTERS = 'TRADEPOINT_AUDIT_LIST_SET_FILTERS';
export const TRADEPOINT_AUDIT_LIST_CLEAR_FILTERS = 'TRADEPOINT_AUDIT_LIST_CLEAR_FILTERS';

export const REPORTS_BI_SET_FILTERS = 'REPORTS_BI_SET_FILTERS';
export const REPORTS_BI_FETCH_START = 'REPORTS_BI_FETCH_START';
export const REPORTS_BI_FETCH_DONE = 'REPORTS_BI_FETCH_DONE';

export const REPORTS_JTI_SET_FILTERS = 'REPORTS_JTI_SET_FILTERS';
export const REPORTS_JTI_FETCH_START = 'REPORTS_JTI_FETCH_START';
export const REPORTS_JTI_FETCH_DONE = 'REPORTS_JTI_FETCH_DONE';

export const REPORTS_IR_SET_FILTERS = 'REPORTS_IR_SET_FILTERS';
export const REPORTS_IR_FETCH_START = 'REPORTS_IR_FETCH_START';
export const REPORTS_IR_FETCH_DONE = 'REPORTS_IR_FETCH_DONE';

export const REPORTS_DIAGEO_SET_FILTERS = 'REPORTS_DIAGEO_SET_FILTERS';
export const REPORTS_DIAGEO_FETCH_START = 'REPORTS_DIAGEO_FETCH_START';
export const REPORTS_DIAGEO_FETCH_DONE = 'REPORTS_DIAGEO_FETCH_DONE';

export const REPORTS_DIAGEO_PILOT_SET_FILTERS = 'REPORTS_DIAGEO_PILOT_SET_FILTERS';
export const REPORTS_DIAGEO_PILOT_FETCH_START = 'REPORTS_DIAGEO_PILOT_FETCH_START';
export const REPORTS_DIAGEO_PILOT_FETCH_DONE = 'REPORTS_DIAGEO_PILOT_FETCH_DONE';

export const CONTRAGENTS_FETCH_START = 'CONTRAGENTS_FETCH_START';
export const CONTRAGENTS_FETCH_DONE = 'CONTRAGENTS_FETCH_DONE';
export const CONTRAGENTS_FETCH_ERROR = 'CONTRAGENTS_FETCH_ERROR';

export const TASK_CLEAR = 'TASK_CLEAR';
export const TASK_FETCH_START = 'TASK_FETCH_START';
export const TASK_FETCH_DONE = 'TASK_FETCH_DONE';
export const TASK_FETCH_ERROR = 'TASK_FETCH_ERROR';
export const TASK_DELETE_START = 'TASK_DELETE_START';
export const TASK_DELETE_DONE = 'TASK_DELETE_DONE';
export const TASK_DELETE_ERROR = 'TASK_DELETE_ERROR';

export const REPORT_FETCH_FIELDS_START = 'REPORT_FETCH_FIELDS_START';
export const REPORT_FETCH_FIELDS_DONE = 'REPORT_FETCH_FIELDS_DONE';
export const REPORT_FETCH_FIELDS_ERROR = 'REPORT_FETCH_FIELDS_ERROR';
export const REPORT_TOGGLE_FIELD = 'REPORT_TOGGLE_FIELD';
export const REPORT_REORDER_FIELDS = 'REPORT_REORDER_FIELDS';
export const REPORT_CLEAR_DATA = 'REPORT_CLEAR_DATA';
export const REPORT_SET_SORT = 'REPORT_SET_SORT';
export const REPORT_SET_FILTERS = 'REPORT_SET_FILTERS';
export const REPORT_SET_CONTRAGENT = 'REPORT_SET_CONTRAGENT';
export const REPORT_SET_NEED_RELOAD = 'REPORT_SET_NEED_RELOAD';
export const REPORT_SET_FILTER_VISIBLE = 'REPORT_SET_FILTER_VISIBLE';
export const REPORT_EXPORT_DATA_START = 'REPORT_EXPORT_DATA_START';
export const REPORT_EXPORT_DATA_DONE = 'REPORT_EXPORT_DATA_DONE';

export const KPI_SET_CONTRAGENT = 'KPI_SET_CONTRAGENT';
export const KPI_SET_DATES = 'KPI_SET_DATES';
export const KPI_SORT = 'KPI_SORT';
export const KPI_FETCH_START = 'KPI_FETCH_START';
export const KPI_FETCH_DONE = 'KPI_FETCH_DONE';
export const KPI_FETCH_ERROR = 'KPI_FETCH_ERROR';
export const KPI_FETCH_TOTALS_START = 'KPI_FETCH_TOTALS_START';
export const KPI_FETCH_TOTALS_DONE = 'KPI_FETCH_TOTALS_DONE';
export const KPI_FETCH_TOTALS_ERROR = 'KPI_FETCH_TOTALS_ERROR';
export const KPI_FETCH_CHIEFS_START = 'KPI_FETCH_CHIEFS_START';
export const KPI_FETCH_CHIEFS_DONE = 'KPI_FETCH_CHIEFS_DONE';
export const KPI_FETCH_CHIEFS_ERROR = 'KPI_FETCH_CHIEFS_ERROR';

export const LOGOUT_DONE = 'LOGOUT_DONE';

export const FEEDBACK_IMAGE_UPLOAD_START = 'FEEDBACK_IMAGE_UPLOAD_START';
export const FEEDBACK_IMAGE_UPLOAD_ERROR = 'FEEDBACK_IMAGE_UPLOAD_ERROR';
export const FEEDBACK_IMAGE_UPLOAD_DONE = 'FEEDBACK_IMAGE_UPLOAD_DONE';
export const FEEDBACK_SEND_START = 'FEEDBACK_SEND_START';
export const FEEDBACK_SEND_ERROR = 'FEEDBACK_SEND_ERROR';
export const FEEDBACK_SEND_DONE = 'FEEDBACK_SEND_DONE';
export const FEEDBACK_CLEAR = 'FEEDBACK_CLEAR';

export const PLANOGRAM_FETCH_START = 'PLANOGRAM_FETCH_START';
export const PLANOGRAM_FETCH_ERROR = 'PLANOGRAM_FETCH_ERROR';
export const PLANOGRAM_FETCH_DONE = 'PLANOGRAM_FETCH_DONE';
export const PLANOGRAM_NEW_PLANOGRAM = 'PLANOGRAM_NEW_PLANOGRAM';
export const PLANOGRAM_UPDATE_PLANOGRAM = 'PLANOGRAM_UPDATE_PLANOGRAM';
export const PLANOGRAM_UPLOAD_PHOTO_START = 'PLANOGRAM_UPLOAD_PHOTO_START';
export const PLANOGRAM_UPLOAD_PHOTO_DONE = 'PLANOGRAM_UPLOAD_PHOTO_DONE';
export const PLANOGRAM_UPLOAD_PHOTO_ERROR = 'PLANOGRAM_UPLOAD_PHOTO_ERROR';
export const PLANOGRAM_REMOVE_PHOTO = 'PLANOGRAM_REMOVE_PHOTO';

export const FIXED_IR_EXPORT_START = 'FIXED_IR_EXPORT_START';
export const FIXED_IR_EXPORT_DONE = 'FIXED_IR_EXPORT_DONE';
export const FIXED_IR_EXPORT_ERROR = 'FIXED_IR_EXPORT_ERROR';
export const PLANOGRAMS_EXPORT_START = 'PLANOGRAMS_EXPORT_START';
export const PLANOGRAMS_EXPORT_DONE = 'PLANOGRAMS_EXPORT_DONE';
export const PLANOGRAMS_EXPORT_ERROR = 'PLANOGRAMS_EXPORT_ERROR';
export const QUESTIONARY_MULTI_BINDING_SET_FILTERS = 'QUESTIONARY_MULTI_BINDING_SET_FILTERS';
