import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Translation } from 'react-i18next';
import {
  Button,
  Card,
  Elevation,
  Intent,
  Collapse,
  Switch,
} from '@blueprintjs/core';

import { getDependentItemProps } from 'app/widgets/Form';

import styles from './Grid.module.css';

export const Filter = React.memo(() => <></>);

export class Filters extends React.PureComponent {
  setFilter(path, value) {
    const { filters, setFilter } = this.props;

    let normalizedValue = value;
    if (path.endsWith('__in') && !value?.length) {
      normalizedValue = undefined;
    }

    if ((path.endsWith('__like') || path.endsWith('__ilike')) && !value) {
      normalizedValue = undefined;
    }

    if (value === null) {
      normalizedValue = undefined;
    }

    if (filters[path] === normalizedValue) {
      return;
    }

    setFilter(path, normalizedValue);
  }

  renderFilter = (element, childrenByPath) => {
    if (element?.type !== Filter) {
      return element;
    }

    const { filters } = this.props;
    const {
      acceptor: Component,
      path,
      dependsOn,
      filters: subFilters,
      disabled,
      selectAllPath,
      ...props
    } = element.props;

    let valueProps;
    if (Component === Switch) {
      const checked = filters[path];
      valueProps = {
        checked: checked ?? false,
        onChange: () => this.setFilter(path, !checked),
        className: props.className ?? styles.switchFilter,
        disabled,
      };
    } else {
      const dependentItemProps = getDependentItemProps({
        value: filters,
        childrenByName: childrenByPath,
        dependsOn,
        filters: subFilters,
        disabled,
      });
      valueProps = {
        value: filters[path],
        selectAllValue: filters[selectAllPath],
        onChange: (newValue) => this.setFilter(path, newValue),
        selectAllPath,
        ...dependentItemProps,
      };
    }

    return <Component key={path} {...valueProps} {..._.omit(props, 'defaultValue')} />;
  }

  render() {
    const { visible, clearFilters, children } = this.props;

    const childrenByPath = _.fromPairs(React.Children.toArray(children).map((c) => [c.props.path, c]));

    return (
      <Collapse className={styles.filters} isOpen={visible} keepChildrenMounted>
        <Card elevation={Elevation.TWO} className={styles.filtersCard}>
          <div className={styles.filtersContainer}>
            {React.Children.map(children, (c) => this.renderFilter(c, childrenByPath))}
          </div>
          <Button
            onClick={clearFilters}
            minimal
            disabled={!clearFilters}
            icon="filter-remove"
            text={<Translation>{(t) => t('clear')}</Translation>}
            intent={Intent.SUCCESS}
            className={styles.clearFiltersButton}
          />
        </Card>
      </Collapse>
    );
  }
}

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Filters.defaultProps = {
  clearFilters: null,
};
