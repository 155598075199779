import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import actions from 'app/state/directActions';
import * as grids from 'app/state/actionCreators/grids';

import Grid from '.';
import styles from './Grid.module.css';

function renderSelectionTitle({ data, selected, toggleAll, selectTitle }) {
  const allSelected = selected.length === data.length;
  const allUnselected = selected.length === 0;

  return (
    <Checkbox
      className={styles.toggleAllCheckbox}
      checked={allSelected}
      indeterminate={!allSelected && !allUnselected}
      onClick={toggleAll}
    >
      {selectTitle ?? i18n.t('select')}
    </Checkbox>
  );
}

function renderSelection({ selected, toggleSelection }, dummy, rowData) {
  return (
    <Checkbox
      className={styles.bindingCheckbox}
      checked={selected.includes(rowData.id)}
      onChange={() => toggleSelection(rowData.id)}
    />
  );
}

const SelectableGrid = React.forwardRef(function SelectableGrid(
  { path, children, selectTitle, ...props },
  ref,
) {
  const { t } = useTranslation();
  const { data, selected } = RRU.useSelector(`grids.${path}`) ?? grids.INITIAL_STATE;

  const toggleAll = RRU.useCallbackArgs(actions.grids.toggleSelectionAll, path);
  const renderSelectionTitleBound = RRU.useCallbackOptions(
    renderSelectionTitle,
    { data, selected, toggleAll, selectTitle },
  );

  const toggleSelection = RRU.useCallbackArgs(actions.grids.toggleSelection, path);
  const renderSelectionBound = RRU.useCallbackOptions(
    renderSelection, { selected, toggleSelection }
  );

  return (
    <Grid ref={ref} path={path} {...props}>
      <Grid.Column
        path="selection"
        title={selectTitle ?? t('select')}
        width={150}
        nameRenderer={renderSelectionTitleBound}
        valueRenderer={renderSelectionBound}
      />
      {children}
    </Grid>
  );
});

SelectableGrid.Actions = Grid.Actions;
SelectableGrid.Filter = Grid.Filter;
SelectableGrid.Column = Grid.Column;

SelectableGrid.ActionsBar = Grid.ActionsBar;
SelectableGrid.Filters = Grid.Filters;
SelectableGrid.Table = Grid.Table;

SelectableGrid.propTypes = {
  path: PropTypes.string.isRequired,
  selectTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SelectableGrid;
