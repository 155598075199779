import * as ACTIONS from 'app/state/actions';

const INITIAL_STATE = {
  running: false,
  error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_RESTORED_TASK_FETCH_START:
      return { running: true, error: null };
    case ACTIONS.CREATE_RESTORED_TASK_FETCH_DONE:
      return { running: false, error: null };
    case ACTIONS.CREATE_RESTORED_TASK_FETCH_ERROR:
      return { running: false, error: action.error };
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
