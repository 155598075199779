import React from 'react';
import historyDefault from './history';

export * from './memoize';
export * from './object';
export * from './array';
export * from './date';
export * from './hooks';
export * from './strings';
export * from './useRequest';
export * from './backgroundExportTasks';
export const history = historyDefault;

export function makePlaceholderComponentType(name) {
  const result = React.memo(() => null);
  result.displayName = name ?? 'Placeholder';
  return result;
}
