import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { H2, NonIdealState } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import Page from 'app/widgets/Page';
import { tradepointAudit } from 'app/proptyping';
import { isOnlyOneContragent, getContragentId, getUserId } from 'app/state/reducers';
import {
  fetchTradepointsList as fetchTradepointsListAC,
  setFilters as setFiltersAC,
  clearFilters as clearFiltersAC,
} from 'app/state/actionCreators/tradepointsAudit';
import MerchRouteMap from './merchMapRoutes';
import Filters from './Filters';
import styles from './TasksScreen.module.css';
import TradepointInfo from './TradepointInfo';

const TasksScreen = ({
  tradepoints,
  fetching,
  filters,
  fetchTradepointsList,
  setFilters,
  clearFilters,
  contragentId,
  userId,
}) => {
  const [selectedTradepoint, setSelectedTradepoint] = useState({});
  const { t, i18n } = useTranslation();
  const hasFilters = !!(filters.merchId && filters.date && filters.contragentId);
  useEffect(() => {
    if (hasFilters) {
      fetchTradepointsList(filters);
      setSelectedTradepoint({});
    }
  }, [hasFilters, filters]);
  useEffect(() => {
    if (contragentId && !filters.contragentId) {
      const obj = { ...filters, contragentId };
      setFilters(obj);
    }
  }, [contragentId, filters]);

  const onClickItem = (item) => {
    setSelectedTradepoint(item);
  };

  return (
    <Page>
      <div className={styles.header}>
        <H2>{t('routes')}</H2>
      </div>
      <Filters
        filters={filters}
        supervisorId={userId}
        setFilters={(f) => setFilters(f)}
        clearFilters={() => clearFilters()}
        t={t}
      />
      {hasFilters && !fetching && (tradepoints.length > 0) && (
        <MerchRouteMap
          items={tradepoints}
          onClickItem={onClickItem}
        />
      )}
      {hasFilters && !fetching && (tradepoints.length < 1) && (
        <NonIdealState
          title={t('empty')}
          description={t('no visits for date')}
          icon="list"
        />
      )}
      {(!hasFilters || fetching) && (
        <NonIdealState
          title={t('empty')}
          description={t('complete all filters')}
          icon="list"
        />
      )}
      {hasFilters && selectedTradepoint?.id
        && <TradepointInfo tradepoint={selectedTradepoint} t={t} lang={i18n.language} />}
    </Page>
  );
};

TasksScreen.propTypes = {
  tradepoints: PropTypes.arrayOf(tradepointAudit).isRequired,
  fetching: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    contragentId: PropTypes.number,
    date: PropTypes.instanceOf(Date),
    merchId: PropTypes.number,
    ir_code__like: PropTypes.string,
    ir_type__like: PropTypes.string,
  }).isRequired,
  fetchTradepointsList: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  contragentId: PropTypes.number,
  userId: PropTypes.number,
};

TasksScreen.defaultProps = {
  contragentId: undefined,
  userId: undefined,
};

const mapStateToProps = (state) => ({
  tradepoints: state.tradepointsAudit.data,
  fetching: state.tradepointsAudit.fetching,
  error: state.tradepointsAudit.error,
  filters: state.tradepointsAudit.filters,
  contragentId: isOnlyOneContragent(state) ? getContragentId(state) : undefined,
  userId: getUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchTradepointsList:
    (filters, onSuccess, onError) => dispatch(fetchTradepointsListAC(filters, onSuccess, onError)),
  setFilters: (filters) => dispatch(setFiltersAC(filters)),
  clearFilters: () => dispatch(clearFiltersAC()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksScreen);
