import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import actions from 'app/state/directActions';

function handleClick() {
  actions.grids.fetchCount(api.FEED, {
    raw: true,
    transientFilters: ['agency_id__eq', 'questionary_title__eq', 'question_id__eq'],
  })
}

export default
function FeedCount() {
  const { t } = useTranslation();

  const count = RRU.useSelector(`grids.${api.FEED}.totalItems`);
  const fetching = RRU.useSelector(`grids.${api.FEED}.fetching`);
  const fetchingCount = RRU.useSelector(`grids.${api.FEED}.fetchingTotalItems`);

  if (_.isNil(fetching) || !_.isNil(count)) {
    return null;
  }

  return (
    <Button
      intent={Intent.PRIMARY}
      text={`${t('total')}: ?`}
      loading={fetching || fetchingCount}
      onClick={handleClick}
    />
  );
}
