import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Intent, InputGroup, TextArea, Switch } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import * as utils from 'app/utils';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';

import styles from './Form.module.css';

function validate({ name, value, required, validator, onErrorChange, customInputError, t }) {
  function toErrorString(validationResult) {
    if (_.isString(validationResult)) {
      return validationResult;
    }
    if (validationResult === false) {
      return t('invalid value');
    }

    return null;
  }

  if (customInputError) {
    onErrorChange(name, toErrorString(customInputError));
    return;
  }

  const nil = _.isNil(value);
  if (required && (nil || value === '' || _.isEqual(value, []))) {
    onErrorChange(name, t('required to fill'));
    return;
  }

  if (!nil && validator) {
    const result = validator(value);
    onErrorChange(name, toErrorString(result));
  } else {
    onErrorChange(name, null);
  }
}

function handleChange({ name, onFieldChange, setCustomInputError }, valueOrEvent, newError) {
  let value = valueOrEvent;
  if (valueOrEvent?.target instanceof HTMLElement) {
    if (valueOrEvent.target.type === 'checkbox') {
      value = valueOrEvent.target.checked;
    } else {
      value = valueOrEvent.target.value;
    }
    if (value === '') {
      value = null;
    }
  }

  onFieldChange(name, value);
  setCustomInputError(newError ?? null);
}

const Item = React.memo(({
  name,
  label,
  className,
  inline,
  disabled,
  warning,
  value,
  onFieldChange,
  error,
  onErrorChange,
  required,
  validator,
  acceptor,
  ...props
}) => {
  const [customInputError, setCustomInputError] = useState(null);
  const { t } = useTranslation();
  const onChange = utils.useCallbackWithDeps(handleChange, { name, onFieldChange, setCustomInputError });
  utils.useEffectWithDeps(validate, { name, value, required, validator, onErrorChange, customInputError, t });

  let intent = Intent.NONE;
  if (error) {
    intent = Intent.DANGER;
  } else if (warning) {
    intent = Intent.WARNING;
  }
  const formGroupLabel = inline ? `${label}:` : <span className="bp4-text-disabled">{label}</span>;

  const Component = acceptor ?? InputGroup;
  let coalescedValue = value;
  if ([TextArea, InputGroup].includes(Component)) {
    coalescedValue = value ?? '';
  } else if (Component === LocalizedDateRangeInput) {
    coalescedValue = value ?? [null, null];
  } else if (Component === Switch) {
    coalescedValue = !!value;
  }

  const filteredProps = Component.name?.startsWith('ReferenceSelect') ? props : _.omit(props, 'fetchDisabled');
  if (Component !== Switch) {
    filteredProps.fill = true;
    filteredProps.value = coalescedValue;
  } else {
    filteredProps.checked = coalescedValue;
  }

  return (
    <FormGroup
      className={utils.classNames(styles.formGroup, className)}
      contentClassName={styles.formGroupContent}
      inline={inline && Component !== TextArea}
      disabled={disabled}
      helperText={error || warning}
      intent={intent}
      label={formGroupLabel}
      labelFor={name}
    >
      <Component
        id={name}
        disabled={disabled}
        intent={intent}
        onChange={onChange}
        {...filteredProps}
      />
    </FormGroup>
  );
});

Item.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  inline: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  warning: PropTypes.string,
  value: PropTypes.any,
  onFieldChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  onErrorChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  validator: PropTypes.func,
  acceptor: PropTypes.elementType,
};

Item.defaultProps = {
  className: null,
  disabled: false,
  value: null,
  error: null,
  required: false,
  validator: null,
  acceptor: null,
};

export default Item;
