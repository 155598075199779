import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '@blueprintjs/core';

const BreadcrumbRenderer = ({ text, href }) => (
  href
    ? (
      <Link to={href}>
        <Breadcrumb>{text}</Breadcrumb>
      </Link>
    )
    : <Breadcrumb current>{text}</Breadcrumb>
);

BreadcrumbRenderer.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
};

BreadcrumbRenderer.defaultProps = {
  href: '',
};

export default BreadcrumbRenderer;
