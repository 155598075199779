import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner, HTMLTable, H4 } from '@blueprintjs/core';
import { useParams } from 'react-router-dom';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import Page from 'app/widgets/Page';

async function fetchImport({ id, setState, t }) {
  try {
    const data = await api.getImportTask(id);
    setState({ fetching: false, error: null, data });
  } catch (e) {
    setState({ fetching: false, error: e.message || t('error'), data: null });
  }
}

export default
function ImportTaskScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [{ fetching, error, data }, setState] = React.useState({
    fetching: true,
    error: null,
    data: null,
  });
  RRU.useAsyncEffectOptions(fetchImport, { id, setState }, { t });

  if (fetching) {
    return <Page title={t('importErrors')}><Spinner /></Page>;
  }

  if (error) {
    return <Page title={t('importErrors')}>{error}</Page>;
  }

  return (
    <Page title={`${t('importErrors')} ${data.filename}`}>
      {!data.errors.length && <H4>{t('importWithoutErrors')}</H4>}
      {!!data.errors.length && (
        <HTMLTable width="100%">
          <thead>
            <tr>
              <th>{t('row')}</th>
              <th>{t('error')}</th>
            </tr>
          </thead>
          <tbody>
            {data.errors.map(e => (
              <tr key={e.id}>
                <td>{e.row ?? '—'}</td>
                <td>{e.text}</td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      )}
    </Page>
  );
}
