import React from 'react';
import { createRoot } from 'react-dom/client';
import { Position, Toaster, Intent } from '@blueprintjs/core';

const containerElement = document.createElement('div');
document.body.appendChild(containerElement);
const toasterRef = React.createRef();
const root = createRoot(containerElement);
root.render(<Toaster position={Position.BOTTOM} ref={toasterRef} />);

function show(...args) {
  return toasterRef.current.show(...args);
}

function dismiss(...args) {
  return toasterRef.current.dismiss(...args);
}

export const showError = (message, params) => show({ intent: Intent.DANGER, message, icon: 'error', ...params });

export const showWarning = (message, params) => show({ intent: Intent.WARNING, message, icon: 'tick', ...params });

export const showSuccess = (message, params) => show({ intent: Intent.SUCCESS, message, icon: 'tick', ...params });

const toaster = { show, dismiss, showError, showWarning, showSuccess };
export default toaster;
