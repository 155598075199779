import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const NEW_PLANOGRAM = {
  id: undefined,
  name: '',
  contragent_id: undefined,
  detail: null,
  type: 'text',
  text: '',
  pdf: undefined,
  images: [],
};

const INITIAL_STATE = {
  fetching: false,
  error: null,
  planogram: NEW_PLANOGRAM,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.PLANOGRAM_FETCH_START:
      return _.assign(state, { fetching: true });
    case ACTIONS.PLANOGRAM_FETCH_DONE:
      return _.assign(state, { fetching: false, planogram: action.payload.planogram });
    case ACTIONS.PLANOGRAM_FETCH_ERROR:
      return _.assign(state, { fetching: false, error: action.error });
    case ACTIONS.PLANOGRAM_NEW_PLANOGRAM:
      return _.assign(state, {
        planogram: NEW_PLANOGRAM,
      });
    case ACTIONS.PLANOGRAM_UPDATE_PLANOGRAM:
      return _.assign(state, {
        planogram: {
          ...state.planogram,
          ...action.payload.planogram,
        },
      });
    case ACTIONS.PLANOGRAM_UPLOAD_PHOTO_START: {
      let images = state.planogram.images || [];
      const newImage = {
        uploading: true,
        uuid: action.payload.uuid,
      };
      if (action.payload.replaceUuid) {
        images = images.map((im) => (im.uuid === action.payload.replaceUuid ? newImage : im));
      } else {
        images = [...images, newImage];
      }
      return _.assign(state, {
        planogram: {
          ...state.planogram,
          images,
        },
      });
    }
    case ACTIONS.PLANOGRAM_UPLOAD_PHOTO_DONE: {
      const newImage = state.planogram.images.find((im) => im.uuid === action.payload.uuid);
      newImage.uploading = false;
      newImage.key = action.payload.key;
      return state;
    }
    case ACTIONS.PLANOGRAM_UPLOAD_PHOTO_ERROR: {
      const images = (state.planogram.images || []).filter(
        (im) => im.uuid !== action.payload.uuid,
      );
      return _.assign(state, {
        planogram: {
          ...state.planogram,
          images,
        },
      });
    }
    case ACTIONS.PLANOGRAM_REMOVE_PHOTO: {
      const images = (state.planogram.images || []).filter(
        (im) => im.uuid !== action.payload.uuid,
      );
      return _.assign(state, {
        planogram: {
          ...state.planogram,
          images,
        },
      });
    }
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
