import moment from 'moment';
import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INIT_DATE = moment(new Date()).subtract(1, 'day').toDate();

const INITIAL_STATE = {
  userId: undefined,
  managerId: null,
  date: INIT_DATE,
  questionaryIds: [],

  startDate: INIT_DATE,
  startDateTime: INIT_DATE,
  endDate: INIT_DATE,
  endDateTime: INIT_DATE,
  taskLocation: {
    latitude: null,
    longitude: null,
  },
  startLocation: {
    latitude: null,
    longitude: null,
  },
  endLocation: {
    latitude: null,
    longitude: null,
  },

  shop: {
    id: undefined,
    network: '',
    address: '',
  },
  shopComment: '',
  shopPhoto: {
    uploading: false,
    uuid: null,
    needReupload: false,
  },
  shopProblem: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_RESTORED_TASK_FETCH_DONE:
      return action.payload.task;
    case ACTIONS.CREATE_RESTORED_TASK_NEW: {
      const date = moment(new Date()).subtract(1, 'day').toDate();
      const secondsAttTP = 900 + Math.floor(Math.random() * 900);
      return {
        ...INITIAL_STATE,
        date,
        startDate: moment(date).subtract(secondsAttTP, 'second').toDate(),
        startDateTime: moment(date).subtract(secondsAttTP, 'second').toDate(),
        endDate: date,
        endDateTime: date,
      };
    }
    case ACTIONS.CREATE_RESTORED_TASK_UPDATE:
      return _.assign(state, action.payload);
    case ACTIONS.CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_START:
      return _.assign(state, {
        shopPhoto: {
          uploading: true,
          uuid: action.payload.uuid,
          needReupload: false,
        },
      });
    case ACTIONS.CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_DONE:
      return _.assign(state, {
        shopPhoto: {
          uploading: false,
          uuid: action.payload.uuid,
          key: action.payload.key,
          needReupload: false,
        },
      });
    case ACTIONS.CREATE_RESTORED_TASK_UPLOAD_SHOP_PHOTO_ERROR:
      return _.assign(state, {
        shopPhoto: {
          uploading: false,
          uuid: null,
          needReupload: false,
        },
      });
    case ACTIONS.CREATE_RESTORED_TASK_CLEAR_SHOP_PHOTO:
      return _.assign(state, {
        shopPhoto: {
          uploading: false,
          uuid: null,
          needReupload: false,
        },
      });
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
