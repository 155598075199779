import React from 'react';
import PropTypes from 'prop-types';

import { AgencyContragentFilter } from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';
import Grid from 'app/widgets/Grid';
import LocalizedDateFilter from 'app/widgets/Filters/LocalizedDateFilter';

import MerchOnDateFilter from './MerchOnDateFilter';

const Filters = ({
  filters,
  setFilters,
  clearFilters,
  supervisorId,
  t,
}) => (
  <Grid.Filters
    visible
    filters={filters}
    setFilter={(key, value) => setFilters({ ...filters, [key]: value })}
    clearFilters={clearFilters}
  >
    <Grid.Filter
      acceptor={AgencyContragentFilter}
      path="contragentId"
      onChange={(value) => setFilters({ date: filters.date || new Date(), contragentId: value })}
    />
    <Grid.Filter
      acceptor={LocalizedDateFilter}
      path="date"
      name={t('date of visit')}
      disabled={!filters.contragentId}
      onChange={(date) => setFilters({ contragentId: filters.contragentId, date: date || undefined })}
    />

    <Grid.Filter
      acceptor={MerchOnDateFilter}
      path="merchId"
      disabled={!filters.contragentId || !filters.date}
      contragentId={filters.contragentId}
      supervisorId={supervisorId}
      date={filters.date}
    />

    <Grid.Filter
      acceptor={InputFilter}
      path="ir_code__like"
      name={t('extra code tt for ir')}
      placeholder={t('search by extra code tt for ir')}
    />
    <Grid.Filter
      acceptor={InputFilter}
      path="ir_type__like"
      name={t('type of visit for ir')}
      placeholder={t('search by type of visit for ir')}
    />
  </Grid.Filters>
);

Filters.defaultProps = {
  supervisorId: undefined,
  t: () => {},
};

Filters.propTypes = {
  filters: PropTypes.shape({
    contragentId: PropTypes.number,
    date: PropTypes.instanceOf(Date),
    merchId: PropTypes.number,
    ir_code__like: PropTypes.string,
    ir_type__like: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  supervisorId: PropTypes.number,
  t: PropTypes.func,
};

export default Filters;
