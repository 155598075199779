import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  Classes,
  Button,
  Intent,
  Spinner,
  FormGroup,
  InputGroup,
  Position,
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';

import { dispatch } from 'app/state';
import * as contragents from 'app/state/actionCreators/contragents';
import * as grids from 'app/state/actionCreators/grids';
import { CONTRAGENTS } from 'app/api';
import { canViewAgencies } from 'app/state/reducers';
import { getDialogIsOpen } from 'app/state/reducers/contragents';
import { createContragent } from 'app/api';
import { AgencySelect } from 'app/widgets/ReferenceSelect';
import toaster from 'app/widgets/toaster';
import SimpleSelect from 'app/widgets/SimpleSelect';
import { MERCHANDISING_TYPES } from 'app/config';

import styles from './NewContragentDialog.module.css';

const ContragentForm = ({
  canSelectAgency,
  contragent,
  updateContragent,
  t,
}) => (
  <>
    <FormGroup
      inline
      label={`${t('agency')}:`}
      className={styles.detailsRow}
    >
      <AgencySelect
        value={contragent.agencyId}
        onChange={(agencyId) => updateContragent({...contragent, agencyId})}
        disabled={!canSelectAgency}
        fill
        multi={false}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('contractor code')}:`}
      className={styles.detailsRow}
      helperText={!contragent.code ? t('required to fill') : null}
      intent={!contragent.code ? Intent.DANGER : Intent.NONE}
    >
      <InputGroup
        fill
        type="text"
        className={styles.input}
        value={contragent.code}
        onChange={(e) => updateContragent({...contragent, code: e.target.value})}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('contractor name')}:`}
      className={styles.detailsRow}
      helperText={!contragent.shortname ? t('required to fill') : null}
      intent={!contragent.shortname ? Intent.DANGER : Intent.NONE}
    >
      <InputGroup
        fill
        type="text"
        className={styles.input}
        value={contragent.shortname}
        onChange={(e) => updateContragent({...contragent, shortname: e.target.value})}
      />
    </FormGroup>
    <FormGroup
      inline
      label={`${t('merchandising type')}:`}
      className={styles.detailsRow}
    >
      <SimpleSelect
        items={MERCHANDISING_TYPES.map((type) => ({
          ...type,
          title: t(type.title),
        }))}
        value={contragent.exclusive}
        onChange={(val) => updateContragent({...contragent, exclusive: val})}
        menuAlign={Position.BOTTOM_RIGHT}
      />
    </FormGroup>
  </>
);

ContragentForm.propTypes = {
  canSelectAgency: PropTypes.bool.isRequired,
  contragent: PropTypes.shape({
    shortname: PropTypes.string,
    code: PropTypes.string,
    exclusive: PropTypes.bool,
    agencyId: PropTypes.number,
  }).isRequired,
  updateContragent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const NewContragentDialog = ({
  agencyId,
  canSelectAgency,
  isOpen,
}) => {
  const [contragent, setContragent] = useState({});
  const [fetching, setFetching] = useState(false);
  const [changed, setChanged] = useState(false);
  const { t } = useTranslation();
  const { code, shortname, agencyId: cAgencyId } = contragent;

  useEffect(() => {
    if (isOpen) {
      setContragent({ code: '', shortname: '', exclusive: false, agencyId });
    }
  }, [isOpen]);

  const codeInvalid = !code || ((code.trim()).length < 1);
  const codeShortName = !shortname || ((shortname.trim()).length < 1);

  const invalid = (!contragent || codeInvalid || codeShortName || !cAgencyId);

  const onChange = (c) => {
    setChanged(true);
    setContragent(c);
  };

  const onSaveSuccess = () => {
    const message = t('contractor created');
    toaster.show({
      intent: Intent.SUCCESS,
      message,
      icon: 'tick',
      timeout: 2000,
    });
  };

  const closeDialog = (created) => {
    dispatch(contragents.closeDialog());
    if (created) {
      dispatch(grids.fetch(CONTRAGENTS));
    }
  };

  const onSaveError = (saveError) => {
    let message = t('error');
    if (saveError && saveError.message) {
      message = saveError.message;
    }
    toaster.show({ intent: Intent.DANGER, message, icon: 'error' });
  };

  const onSave = () => {
    if (invalid) {
      return;
    }

    setFetching(true);
    createContragent(contragent)
      .then(() => {
        setFetching(false);
        closeDialog(true);
        onSaveSuccess();
      })
      .catch((err) => {
        setFetching(false);
        onSaveError(err);
      });
  };

  return (
    <Dialog
      icon="add"
      title={t('create contractor')}
      isOpen={isOpen}
      onClose={() => closeDialog(false)}
      canOutsideClickClose={!changed}
    >
      {
        fetching
          ? (
            <div className={Classes.DIALOG_BODY}>
              <Spinner />
            </div>
          )
          : (
            <div className={Classes.DIALOG_BODY}>
              <ContragentForm
                agencyId={agencyId}
                canSelectAgency={canSelectAgency}
                contragent={contragent}
                updateContragent={onChange}
                t={t}
              />
            </div>
          )
      }
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={() => closeDialog(false)}>{t('cancel')}</Button>
          <Button
            disabled={fetching || invalid}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            onClick={onSave}
          >
            {t('create')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

NewContragentDialog.propTypes = {
  agencyId: PropTypes.string,
  canSelectAgency: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

NewContragentDialog.defaultProps = {
  agencyId: null,
};

const mapStateToProps = (state) => ({
  agencyId: state.user.user.agencyId,
  canSelectAgency: canViewAgencies(state),
  isOpen: getDialogIsOpen(state),
});

export default connect(mapStateToProps)(NewContragentDialog);
