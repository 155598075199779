import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Intent } from '@blueprintjs/core';
import RRU from 'react-redux-utils';

import i18n from 'i18n';
import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import * as api from 'app/api';
import * as Filters from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';
import * as selectors from 'app/state/reducers';
import store from 'app/state';
import * as grids from 'app/state/actionCreators/grids';
import actions from 'app/state/directActions';
import alerts from 'app/widgets/alerts';

import EditDialog from './EditDialog';

function handleClickAdd() {
  actions.productProblems.openEditDialog(null);
}

async function deleteProblem({ contragent_id, code }) {
  const { confirmed, error } = await alerts.actionWithConfirm({
    action: () => api.deleteProductProblem(contragent_id, code),
    cancelButtonText: i18n.t('cancel'),
    confirmButtonText: i18n.t('delete'),
    intent: Intent.DANGER,
    icon: 'trash',
    content: i18n.t('delete problem'),
  });
  if (confirmed && !error) {
    store.dispatch(grids.fetch(api.PRODUCT_PROBLEMS));
  }
}

function renderActions(dummy, rowData) {
  return (
    <>
      <Button small icon="edit" onClick={() => actions.productProblems.openEditDialog(rowData)} />
      <Button small icon="trash" onClick={() => deleteProblem(rowData)} />
    </>
  );
}

export default
function ProductProblemsListScreen() {
  const { t } = useTranslation();
  const isAdmin = RRU.useSelector(selectors.isAdminOrAgencyAdmin);
  const canEdit = RRU.useSelector(selectors.canEditProductProblem);

  return (
    <Page title={t('product problems')}>
      <Grid path={api.PRODUCT_PROBLEMS}>
        <Grid.Filter
          acceptor={Filters.AgencyContragentFilter}
          path="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={Filters.TradeNetworkFilter}
          path="network_id__eq"
          multi={false}
          clearable
          dependsOn="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="code__ilike"
          name={t('problem code')}
          placeholder={t('search by problem code')}
        />

        {canEdit && (
          <Grid.Actions>
            <Button intent={Intent.SUCCESS} icon="add" onClick={handleClickAdd}>
              {t('create')}
            </Button>
          </Grid.Actions>
        )}

        <Grid.Column path="contragent" title={t('contractor')} sortable />
        <Grid.Column path="network" title={t('network')} sortable />
        <Grid.Column path="description" title={t('problem name')} sortable />
        <Grid.Column path="code" title={t('problem code')} sortable />
        {isAdmin && canEdit && <Grid.Column path="actions" title={t('actions')} valueRenderer={renderActions} />}
      </Grid>

      <EditDialog />
    </Page>
  );
};

