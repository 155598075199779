import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { H2, HTMLTable, Icon } from '@blueprintjs/core';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import Form from 'app/widgets/Form';
import FancySelect from 'app/widgets/FancySelect';

import styles from './TaskResult.module.css';

function ProductRow({ product }) {
  const { t } = useTranslation();

  return (
    <tr>
      <td>{product.name}</td>
      <td>{product.sku_cid}</td>
      <td>{product.category_name}</td>
      <td>{product.reason}</td>
      <td className={product.present ? styles.irAssortmentSuccess : styles.irAssortmentError}>
        {product.present ? 'OK' : t('NOT FOUND')}
      </td>
    </tr>
  );
};

ProductRow.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sku_cid: PropTypes.string.isRequired,
    category_name: PropTypes.string,
    reason: PropTypes.string,
    present: PropTypes.bool.isRequired,
  }).isRequired,
};

function handleFiltersFieldChange(setFilters, key, value) {
  setFilters((filters) => ({ ...filters, [key]: value }));
}

function collectFilterProducts({ result, filters }) {
  let products = {};
  for (const s of result.scenes) {
    for (const p of s.assortment) {
      if (!products[p.sku_id]) {
        products[p.sku_id] = { ...p, present: true };
      }
    }
  }
  for (const p of result.oos_reasons) {
      if (products[p.sku_id]) {
        products[p.sku_id].reason = p.reason;
      } else {
        products[p.sku_id] = { ...p, present: false };
      }
  }
  for (const p of (result.absent_products ?? [])) {
      if (!products[p.sku_id]) {
        products[p.sku_id] = { ...p, present: false };
      }
  }

  products = _.values(products);
  products = products.filter(p => !_.has(p, 'manufacturer_name') || p.manufacturer_name?.toLowerCase() === 'froneri');

  if (filters.name) {
    const name = filters.name.toLowerCase();
    products = products.filter(p => p.name.toLowerCase().includes(name));
  }

  if (filters.ean) {
    products = products.filter(p => p.sku_cid.includes(filters.ean));
  }

  if (!_.isNil(filters.result)) {
    products = products.filter(p => p.present === filters.result);
  }

  products = _.sortBy(products, 'name');

  return products;
}

export default
function IrInspectorCloudProductsTab({ result }) {
  const { t } = useTranslation();

  const resultOptions = [
    { id: true, label: 'OK' },
    { id: false, label: t('NOT FOUND'), },
  ];

  const [filters, setFilters] = React.useState({});
  const onFiltersFieldChange = RRU.useCallbackArgs(handleFiltersFieldChange, setFilters);
  const products = RRU.useMemoOptions(collectFilterProducts, { result, filters });

  return (
    <div className={styles.inspectorCloudTab}>
      <div className={styles.irTabHeader}>
        <H2>Inspector Cloud — {t('products')}</H2>

        <a
          target="_blank"
          rel="noreferrer"
          href={`https://froneri.inspector-cloud.com/portal/visit/${result.id}/`}
        >
          {t('icSiteReport')} <Icon icon="share" />
        </a>
      </div>

      <Form value={filters} onFieldChange={onFiltersFieldChange} inline>
        <Form.Item
          name="name"
          label={t('denomination')}
        />
        <Form.Item
          name="ean"
          label="EAN"
        />
        <Form.Item
          name="result"
          label={t('result')}
          acceptor={FancySelect}
          data={resultOptions}
          multi={false}
          clearable
        />
      </Form>

      <HTMLTable className={styles.productTable}>
        <thead>
          <tr>
            <th>{t('product name')} <Icon icon="sort-asc" /></th>
            <th>EAN</th>
            <th>{t('group')}</th>
            <th>{t('oosReason')}</th>
            <th>{t('recognition result')}</th>
          </tr>
        </thead>

        <tbody>
          {products.map(p => <ProductRow key={p.sku_id} product={p} />)}
        </tbody>
      </HTMLTable>
    </div>
  );
}

IrInspectorCloudProductsTab.propTypes = {
  result: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
