import React from 'react';
import _ from 'lodash';

export function escapeRegExpChars(text) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
}

function coalesceIeYo(string) {
  return string.replace('ё', '[её]');
}

function getGroupIndices({ string, match, parts }) {
  if (match.indices) {
    return match.indices.slice(1);
  }

  // Safari < 15
  const indices = [];
  let i = match.index;
  for (const part of parts) {
    const start = string.indexOf(part, i);
    if (start === -1) {
      // what??
      continue;
    }

    indices.push([start, start + part.length]);
    i = start;
  }

  return indices;
}

export function filterSomewhatFuzzy({ items, query, keys }) {
  if (!query.trim()) {
    return items;
  }

  const parts = query.trim().split(/\s+/);
  const groups = parts.map((word) => `(${coalesceIeYo(_.escapeRegExp(word))})`);
  let regexp;
  try {
    regexp = new RegExp(groups.join('.*'), 'id');
  } catch (e) {
    // Safari < 15
    regexp = new RegExp(groups.join('.*'), 'i');
  }

  const filteredItems = [];
  for (const item of items) {
    const matches = {};
    for (const key of keys) {
      const string = item[key];
      if (!string) {
        continue;
      }

      const match = regexp.exec(string);
      if (match) {
        matches[key] = getGroupIndices({ string, match, parts });
        regexp.lastIndex = 0;
      }
    }

    if (!_.isEmpty(matches)) {
      filteredItems.push({ item, matches });
    }
  }

  return filteredItems;
}

export function highlightSearch(text, search) {
  if (!text || !search) {
    return text;
  }

  const words = search
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);

  if (words.length === 0) {
    return text;
  }

  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens = [];
  let match = regexp.exec(text);
  let lastIndex = 0;
  while (match) {
    const { length } = match[0];
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    match = regexp.exec(text);
  }

  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }

  return tokens;
}

export function classNames(...args) {
  return _.filter(args).join(' ') || null;
}

export function copyToClipboard(str) {
  // https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  // backup selection
  const selected = (
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  );
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    // restore selection
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}
