
const INITIAL_STATE = {
  roles: [],
  rolesMap: {},
  fetching: false,
  error: false,
};

const reducer = (state = INITIAL_STATE) => state;
export default reducer;
