import React from 'react';
import { Dialog, Classes, Button, Intent, Spinner } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import RRU from 'react-redux-utils';

import * as api from 'app/api';
import * as schedule from 'app/state/actionCreators/schedule';
import Form, { ReduxForm } from 'app/widgets/Form';
import { UserSelect, RescheduleReasonSelect } from 'app/widgets/ReferenceSelect';

import * as common from './common';

function isSubstanceChanged({ form, formInitial }) {
  return (
    form.date?.valueOf() !== formInitial?.date?.valueOf()
    || form.user_id !== formInitial?.user_id
  );
}

function closeDialog() {
  common.handleCloseDialog('editDialog', false);
}

function handleSave() {
  common.handleSaveWithConflicts(schedule.edit, 'editDialog');
}

function EditForm() {
  const { t } = useTranslation();
  const { tasks, form, formInitial } = RRU.useSelector('schedule.editDialog');

  const substanceChanged = isSubstanceChanged({ form, formInitial });

  const user = RRU.useSelector('user.user');
  const hasRescheduleReasons = !!RRU.useSelector(
    `references.${api.RESCHEDULE_REASONS_REFERENCES}.last.data.length`,
  );

  return (
    <ReduxForm path="schedule.editDialog.form">
      <Form.Item
        label={t('date of visit')}
        name="date"
        acceptor={Form.DateInput}
      />

      <Form.Item
        label={t('executor')}
        name="user_id"
        acceptor={UserSelect}
        multi={false}
        clearable
        filters={{ roles__contains: ['merch'], contragent_id__eq: tasks[0].contragent_id }}
      />

      <Form.Item
        label={t('supervisor')}
        name="manager_id"
        acceptor={UserSelect}
        autoSelectFirst
        multi={false}
        dependsOn="subordinate_id__eq=user_id"
        defaultValue={user.id}
      />

      <Form.Item
        label={t('reason')}
        name="reschedule_reason_id"
        acceptor={RescheduleReasonSelect}
        multi={false}
        clearable
        required={substanceChanged && hasRescheduleReasons}
        filters={{ contragent_id__eq: tasks[0].contragent_id }}
        disabled={!substanceChanged}
      />
    </ReduxForm>
  );
}

export default React.memo(function EditDialog() {
  const { t } = useTranslation();
  const {
    isOpen,
    tasks,
    form,
    formInitial,
    formValid,
    fetching,
  } = RRU.useSelector('schedule.editDialog');
  const formFilled = !!form.date || !!form.user_id;
  const formChanged = (
    isSubstanceChanged({ form, formInitial })
    || form.manager_id !== formInitial?.manager_id
  );

  const title = tasks?.length === 1
    ? t('edit visit')
    : `${t('edit visits')} (${tasks?.length} ${t('selected')})`;

  return (
    <Dialog
      icon="edit"
      title={title}
      isOpen={isOpen}
      onClose={closeDialog}
      enforceFocus={false}
      canOutsideClickClose={!formChanged}
    >
      <div className={Classes.DIALOG_BODY}>
        {fetching ? <Spinner /> : <EditForm />}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" onClick={closeDialog}>{t('cancel')}</Button>
          <Button
            disabled={!formValid || !formChanged || !formFilled}
            loading={fetching}
            icon="tick"
            intent={Intent.PRIMARY}
            onClick={handleSave}
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
});
