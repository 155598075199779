import React from 'react';
import { Dialog, Classes, Button, Intent } from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import RRU from 'react-redux-utils';

import * as schedule from 'app/state/actionCreators/schedule';
import Form, { ReduxForm } from 'app/widgets/Form';
import {
  ContragentSelect,
  TradepointSelect,
  UserSelect,
  BranchSelect,
  TradeNetworkSelect,
  TradepointProjectSelect,
  TradepointFormatSelect,
} from 'app/widgets/ReferenceSelect';

import styles from './CreateVisitDialog.module.css';
import * as common from './common';

const CreateForm = React.memo(() => {
  const { t } = useTranslation();
  const form = RRU.useSelector('schedule.createVisitDialog.form');

  return (
    <ReduxForm className={styles.form} path="schedule.createVisitDialog.form">
      <Form.Item
        label={t('contractor')}
        name="contragent_id__eq"
        required
        acceptor={ContragentSelect}
        multi={false}
        autoSelectFirst
      />
      <Form.Item
        label={t('executor')}
        name="user_id"
        required
        dependsOn="contragent_id__eq"
        acceptor={UserSelect}
        multi={false}
        autoSelectFirst
        filters={{ roles__contains: ['merch'] }}
      />
      <Form.Item
        label={t('supervisor')}
        name="manager_id"
        dependsOn="contragent_id__eq"
        acceptor={UserSelect}
        multi={false}
        autoSelectFirst
        filters={{ subordinate_id__eq: form.user_id }}
      />
      <Form.Item
        label={t('affiliate')}
        name="branch_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={BranchSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('network')}
        name="network_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={TradeNetworkSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('project')}
        name="project_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={TradepointProjectSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('format')}
        name="format_id__eq"
        dependsOn="contragent_id__eq"
        acceptor={TradepointFormatSelect}
        multi={false}
        clearable
      />
      <Form.Item
        label={t('tradepoints')}
        name="tradepoint_ids"
        dependsOn="contragent_id__eq branch_id__eq? network_id__eq? project_id__eq? format_id__eq?"
        required
        acceptor={TradepointSelect}
      />
      <Form.Item
        label={t('date of visit')}
        name="date"
        required
        acceptor={Form.DateInput}
        minDate={new Date()}
      />
      <Form.Item
        label={t('repeat count')}
        name="repeat_count"
        acceptor={Form.NumberInput}
        min={0}
        max={100}
      />
      <Form.Item
        label={t('every x days')}
        name="repeat_interval"
        required={!!form.repeat_count}
        acceptor={Form.NumberInput}
        min={1}
        max={100}
      />
      <Form.Item
        label={t('time to move plan min')}
        name="plan_travel_time_minutes"
        required
        acceptor={Form.NumberInput}
        min={0}
        max={1440}
      />
      <Form.Item
        label={t('time in tt plan min')}
        name="plan_lead_time"
        required
        acceptor={Form.NumberInput}
        min={0}
        max={1440}
      />
    </ReduxForm>
  );
});

function closeDialog() {
  common.handleCloseDialog('createVisitDialog', false);
}

function handleSave() {
  common.handleSaveWithConflicts(schedule.createVisit, 'createVisitDialog');
}

export default React.memo(() => {
  const { t } = useTranslation();
  const { isOpen, fetching, form, formInitial, formValid } = RRU.useSelector('schedule.createVisitDialog');
  const changed = !_.isEqual(
    _.omit(form, 'user_id', 'manager_id'),
    _.omit(formInitial, 'user_id', 'manager_id'),
  );

  return (
    <Dialog
      icon="edit"
      title={t('creating visit')}
      isOpen={isOpen}
      style={{ width: 550 }}
      onClose={closeDialog}
      enforceFocus={false}
      canOutsideClickClose={!changed}
    >
      <div className={Classes.DIALOG_BODY}>
        <CreateForm />
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button icon="cross" disabled={fetching} onClick={closeDialog} text={t('cancel')} />
          <Button
            disabled={!formValid}
            loading={fetching}
            icon="tick"
            intent={Intent.PRIMARY}
            text={t('save')}
            onClick={handleSave}
          />
        </div>
      </div>
    </Dialog>
  );
});
