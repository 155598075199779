import _ from 'lodash';

import * as ACTIONS from 'app/state/actions';

const INITIAL_STATE = {
  fetching: false,
  fieldsFetching: false,
  fileFetching: false,
  error: null,
  fieldsError: null,
  fields: [],
  data: [],
  contragentId: null,
  filters: {},
  filtersVisible: true,
  fieldsReportType: '',
  sort: {
    code: null,
    direction: 'asc',
  },
  needReload: false,
};

const DEFAULT_COLUMNS_BY_TYPE = {
  'visits': [
    'contragent_shortname',
    'user_tasks_date',
    'user_tasks_id',
    'tradepoints_code',
    'tradepoints_address',
    'user_tasks_expired_time',
    'user_tasks_start_time',
    'user_tasks_end_time',
    'user_tasks_status',
    'user_tasks_unscheduled',
    'user_tasks_task_type',
    'users_id',
    'users_phone',
    'user',
    'role',
  ],
  'questionaries': [
    'contragent_shortname',
    'user_tasks_date',
    'user_tasks_id',
    'tradepoints_code',
    'tradepoints_address',
    'user_tasks_expired_time',
    'user_tasks_start_time',
    'user_tasks_end_time',
    'users_phone',
    'user',
    'questionary',
    'questionary_type',
    'visibility_restriction',
    'question_title',
    'question_subtitle',
    'question_subsubtitle',
    'question_swipe_subtitle',
    'question_text',
    'question_result',
    'user_tasks_restored',
  ],
  'signals': [
    'contragent_shortname',
    'user_tasks_date',
    'user_tasks_id',
    'tradepoints_address',
    'tt_ext_code',
    'tradepoints_name',
    'user_tasks_expired_time',
    'user_tasks_start_time',
    'user_tasks_end_time',
    'user_tasks_status',
    'user_tasks_comment',
    'visit_problem',
    'users_phone',
    'user',
    'product_group',
    'product_code',
    'product_name',
    'virtual_balance',
    'stock',
    'price_color',
    'price',
    'last_change_price',
    'receipt_date',
    'osa_required',
    'osa_result',
    'virtual_required',
    'virtual_result',
    'expired_required',
    'expired_result',
    'product_problem',
    'problem_virtual',
    'product_group_comment',
    'signal_source',
  ],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.REPORT_FETCH_FIELDS_START:
      return _.assign(state, {
        fieldsFetching: true,
        fieldsReportType: action.payload.reportType,
      });
    case ACTIONS.REPORT_FETCH_FIELDS_DONE:
    {
      const { reportType, reportTypeChanged } = action.payload;
      let fields;
      const filters = { ...state.filters };
      if (!reportTypeChanged) {
        // дозагрузка выпадающих списков при смене контрагента
        // сохраняем выбранные колонки
        const newFields = _.fromPairs(action.payload.fields.map(f => [f.field, f]));
        fields = state.fields.map((f) => ({
          ...f,
          name: newFields[f.field].name,
          values: newFields[f.field].values,
        }));
        // очистка выпадающих списков при смене контрагента
        const combos = state.fields.filter((x) => !!x.values);
        combos.forEach((f) => {
          filters[f.field] = undefined;
        });
      } else {
        const defaultColumns = (
          JSON.parse(localStorage.getItem('reportsDynamicColumns'))?.[reportType]
          ?? DEFAULT_COLUMNS_BY_TYPE[reportType]
        );

        fields = action.payload.fields.map((f, index) => ({
          ...f,
          checked: defaultColumns.includes(f.field),
          index,
        }));
      }
      return _.assign(state, {
        fieldsFetching: false,
        fields,
        fieldsError: null,
        filters,
      });
    }
    case ACTIONS.REPORT_FETCH_FIELDS_ERROR:
      return _.assign(state, {
        fieldsFetching: false,
        error: action.error,
      });
    case ACTIONS.REPORT_TOGGLE_FIELD:
    {
      const { code, checked } = action.payload;
      for (const f of state.fields) {
        if (code === 'ALL' || f.field === code) {
          f.checked = checked;
        }
      }

      localStorage.setItem('reportsDynamicColumns', JSON.stringify({
        ...JSON.parse(localStorage.getItem('reportsDynamicColumns')),
        [state.fieldsReportType]: state.fields.filter(f => f.checked).map(f => f.field),
      }));

      return state;
    }
    case ACTIONS.REPORT_REORDER_FIELDS:
    {
      // перемещение колонок в таблице
      const { oldIndex, newIndex } = action.payload;
      if (oldIndex === newIndex) {
        return state;
      }
      const visibleFields = state.fields.filter((f) => f.checked);
      // индексы с учетом скрытых
      const oldColumnIndex = visibleFields[oldIndex].index;
      const newColumnIndex = visibleFields[newIndex].index;
      const fields = _.cloneDeep(state.fields);
      if (newColumnIndex > oldColumnIndex) {
        // перемещение вперед
        for (let i = 0; i < fields.length; i += 1) {
          const f = fields[i];
          if (f.index > oldColumnIndex && f.index <= newColumnIndex) {
            f.index -= 1;
          }
        }
      } else {
        // перемещение назад
        for (let i = 0; i < fields.length; i += 1) {
          const f = fields[i];
          if (f.index < oldColumnIndex && f.index >= newColumnIndex) {
            f.index += 1;
          }
        }
      }
      fields[oldColumnIndex].index = newColumnIndex;
      fields.sort((a, b) => a.index - b.index);
      state.fields = fields;
      return state;
    }
    case ACTIONS.REPORT_CLEAR_DATA: {
      return _.assign(state, {
        fetching: false,
        error: null,
        data: [],
      });
    }
    case ACTIONS.REPORT_SET_SORT:
    {
      const { sort } = action.payload;
      return _.assign(state, { sort });
    }
    case ACTIONS.REPORT_SET_CONTRAGENT:
      return _.assign(state, {
        contragentId: action.payload.contragentId,
      });
    case ACTIONS.REPORT_SET_FILTERS:
    {
      const { filters } = action.payload;
      return _.assign(state, {
        filters,
      });
    }
    case ACTIONS.REPORT_SET_NEED_RELOAD:
    {
      if (state.fetching && action.payload.needReload) {
        return state;
      }
      return _.assign(state, { needReload: action.payload.needReload });
    }
    case ACTIONS.REPORT_SET_FILTER_VISIBLE:
    {
      const { filtersVisible } = action.payload;
      return _.assign(state, { filtersVisible });
    }
    case ACTIONS.REPORT_EXPORT_DATA_START:
      return _.assign(state, { fileFetching: true });
    case ACTIONS.REPORT_EXPORT_DATA_DONE:
      return _.assign(state, { fileFetching: false });
    default:
      return state;
  }
};

export default reducer;
