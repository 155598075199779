import moment from 'moment';
import _ from 'lodash';

import i18n from 'i18n';

export function timeBetween(date1, date2) {
  return date2.getTime() - date1.getTime();
}

export function formatMinutesToHours(mins) {
  const hours = Math.floor(mins / 60);
  const minutes = Math.floor(mins - hours * 60);

  let result = '';
  if (hours > 0) {
    result += `${hours} ${i18n.t('h')} `;
  }

  result += ` ${minutes} ${i18n.t('min')}`;

  return result;
}

export function formatIntervalBetween(start, end) {
  const intervalSeconds = moment(end).diff(start, 'seconds');
  // в отчетах на бэке используется extract(epoch ), который возвращает double precision
  // поэтому нужно использовать окргуление к ближайшему целому
  const intervalMinutes = Math.round(intervalSeconds / 60);
  const hours = Math.floor(intervalMinutes / 60);
  const minutes = Math.floor(intervalMinutes - hours * 60);

  let result = '';
  if (hours > 0) {
    result += `${hours} ${i18n.t('h')} `;
  }

  result += ` ${minutes} ${i18n.t('min')}`;

  return result;
}

export function endOfDay(date) {
  if (!date) {
    return null;
  }

  return moment(date).set({
    hour: 23,
    minute: 59,
    second: 0,
    millisecond: 0,
  }).toDate();
}

export function startOfDay(date) {
  if (!date) {
    return null;
  }

  return moment(date).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  }).toDate();
}

export function isToday(date) {
  if (!date) {
    return false;
  }

  const now = new Date();
  return moment(date).isBetween(startOfDay(now), endOfDay(now));
}

export function ISODate(date) {
  if (!date) {
    return null;
  }

  return moment(date).format('YYYY-MM-DD');
}

export function parseISODate(str) {
  if (!str) {
    return null;
  }
  return moment(str, 'YYYY-MM-DD').toDate();
}

export function ISODateTime(date) {
  if (!date) {
    return null;
  }

  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

export function dateString(date, delimiter = '.') {
  if (!date) {
    return null;
  }

  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthString = month > 9 ? month : `0${month}`;
  const dayString = day > 9 ? day : `0${day}`;

  return `${dayString}${delimiter}${monthString}`;
}

export function fullDateString(date, delimiter = '.') {
  if (!date) {
    return null;
  }

  return `${date.getFullYear()}${delimiter}${dateString(date, delimiter)}`;
}

export function dateRange(startDateInclusive, endDateInclusive, interval = 1, scale = 'days') {
  const result = [];

  let date = moment(startDateInclusive);
  while (date.isSameOrBefore(endDateInclusive)) {
    result.push(date.toDate());
    date = date.add(interval, scale);
  }

  return result;
}

export function toLocaleString(date) {
  if (!date) {
    return date;
  }
  return moment(date).toDate().toLocaleString();
}

export function toLocaleDateString(date) {
  if (!date) {
    return date;
  }
  return moment(date).toDate().toLocaleDateString();
}

const IR_DATE_REGEXP = /(\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2})-\d+-o.jpg$/;
export function parseIrPhotoDate(url, startUtcOffsetMinutes, startTimeUtc, startTime) {
  const date = url?.match(IR_DATE_REGEXP)?.[1];
  if (!date) {
    return null;
  }

  let utcOffsetHours;
  if (!_.isNil(startUtcOffsetMinutes)) {
    utcOffsetHours = startUtcOffsetMinutes / 60;
  } else {
    const startMoment = moment(startTime);
    const startMomentUtc = moment(startTimeUtc?.replace(/Z$/, '') ?? startTime);
    utcOffsetHours = Math.round(startMoment.diff(startMomentUtc, 'hours', true));
  }

  const result = moment(date, 'YYYY-MM-DD-hh-mm-ss');
  result.add(utcOffsetHours, 'hours');

  return result;
}
