export * from './questionTypes';

export const QUEST_TYPES = {
  questionary: 'poll',
  audit: 'audit',
  event: 'eventType',
};

export const TASK_TYPES = {
  audit: 'audit',
  merchandising: 'visit',
  event: 'eventType',
};

export const VISIBILITY_RESTRICTIONS = {
  NONE: 'for all',
  TRAINEE: 'survey for intern',
  TRAINEE_2: 'survey for intern 2',
  MERCH: 'survey for merchandiser',
};

export const BINDING_IR_COMBO_ITEMS = {
  notrequired: 'pinned',
  required: 'mandatory',
  notneed: 'not pinned',
};

export const OVERHEAD_BACKWALL_SHOW_TYPES = {
  overhead_backwall: 'overhead backwall',
  pos: 'pos',
  fixed_pos: 'fixed pos',
};

export const MERCHANDISING_TYPES = [
  {
    title: 'combined',
    value: false,
    key: 'false',
  },
  {
    title: 'exclusive',
    value: true,
    key: 'true',
  },
];

export const MANDATORY_PERIOD_TYPE_ITEMS = {
  first_visit: 'on the first visit',
  last_visit: 'in the last visit',
};

export const CHECKED_EQUIPMENT_TYPES = {
  all: 'allEquipmentChecked',
  not_all: 'lessThanRequiredWasChecked',
  more_than_all: 'moreThanRequiredWasChecked',
};
