import React from 'react';
import { useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { H3, H4, Spinner, NonIdealState, Button, Intent } from '@blueprintjs/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import * as api from 'app/api';
import * as utils from 'app/utils';
import { dispatch } from 'app/state';
import { VISIBILITY_RESTRICTIONS } from 'app/config';
import * as questionary from 'app/state/actionCreators/questionary';
import * as references from 'app/state/actionCreators/references';
import actions from 'app/state/directActions';
import Page from 'app/widgets/Page';
import FancySelect from 'app/widgets/FancySelect';
import { ContragentSelect, QuestionaryCategorySelect } from 'app/widgets/ReferenceSelect';
import { LocalizedISODateInput } from 'app/widgets/LocalizedDateInput';
import Form, { ReduxForm } from 'app/widgets/Form';

import Group from './Group';
import QuestionEditor from './QuestionEditor';
import styles from './QuestionaryEditScreen.module.css';

function fetchPlanogramReferences({ contragent_id }) {
  if (contragent_id) {
    dispatch(references.fetch(api.PLANOGRAM_REFERENCES, { contragent_id__eq: contragent_id }));
  }
}

function useEditState() {
  const { id } = useParams();
  const copying = utils.history.location.pathname.endsWith('copy');
  return { id, copying, editing: !copying && id !== 'new' };
}

const QuestionaryForm = React.memo(() => {
  const { editing } = useEditState();
  const { t } = useTranslation();

  const VISIBILITY_RESTRICTION_ITEMS = _.toPairs(VISIBILITY_RESTRICTIONS).map(([id, label]) => (
    { id, label: t(label) }
  ));

  const QUESTIONARY_TYPE_ITEMS = [
    { id: 'questionary', label: t('poll') },
    { id: 'audit', label: t('audit') },
  ];

  const MANDATORY_PERIOD_TYPE_ITEMS = [
    { id: 'first_visit', label: t('on the first visit') },
    { id: 'last_visit', label: t('in the last visit') },
  ];

  const q = utils.useSelectorMemo('questionary.questionary');
  utils.useEffectWithDeps(fetchPlanogramReferences, { contragent_id: q.contragent_id });

  return (
    <ReduxForm path="questionary.questionary" className={styles.questionaryForm}>
      <Form.Item
        label={t('questionnaire name')}
        name="name"
        required
      />

      <Form.Item
        label={t('questionnaire type')}
        name="type"
        acceptor={FancySelect}
        data={QUESTIONARY_TYPE_ITEMS}
        multi={false}
      />

      <Form.Item
        label={t('contractor')}
        name="contragent_id"
        disabled={editing}
        required
        acceptor={ContragentSelect}
        multi={false}

      />

      <Form.Item
        label={t('id of questionnaire')}
        name="code"
        required
        disabled={editing}
      />

      <Form.Item
        label={t('category')}
        name="category_id"
        acceptor={QuestionaryCategorySelect}
        filters={{ contragent_id__eq: q.contragent_id }}
        multi={false}
        clearable
      />

      {q.type === 'questionary' && (
        <Form.Item
          label={t('visibility')}
          name="visibility_restriction"
          acceptor={FancySelect}
          data={VISIBILITY_RESTRICTION_ITEMS}
          multi={false}
        />
      )}

      <H4>{t('obligatory')}</H4>

      <Form.Item
        label={t('date of start')}
        name="mandatory_period_start_date"
        acceptor={LocalizedISODateInput}
      />

      <Form.Item
        label={t('days count')}
        name="mandatory_period_days"
        required
        disabled={!q.mandatory_period_start_date}
        acceptor={Form.NumberInput}
        min={1}
        max={60}
      />

      <Form.Item
        label={t('visit count')}
        name="mandatory_period_count"
        disabled={!q.mandatory_period_start_date}
        acceptor={Form.NumberInput}
        min={1}
      />

      <Form.Item
        label={t('display type')}
        name="mandatory_period_type"
        acceptor={FancySelect}
        disabled={!q.mandatory_period_start_date || q.mandatory_period_count !== null}
        data={MANDATORY_PERIOD_TYPE_ITEMS}
        multi={false}
      />

      <H4>{t('displayForMonth')}</H4>

      <Form.Item
        label={t('visit count')}
        name="num_tasks_with_questionary"
        acceptor={Form.NumberInput}
        min={1}
      />
    </ReduxForm>
  );
});

async function handleSave({ id, editing }) {
  const questionaryIdToUpdate = editing ? id : null;
  const newId = await actions.questionary.save(questionaryIdToUpdate);
  if (newId) {
    utils.history.replace(`/questionary/${newId}/edit`);
    utils.history.push('/questionaries');
  }
}

const SaveButton = React.memo(() => {
  const { id, editing } = useEditState();
  const { t } = useTranslation();
  const fetching = utils.useSelectorMemo('questionary.fetching');
  const valid = utils.useSelectorMemo((state) => (
    state.questionary.questionaryValid && !_.isEmpty(state.questionary.questions)
  ));
  const onClickSave = utils.useCallbackWithDeps(handleSave, { id, editing });

  return (
    <Button
      text={t('save')}
      large
      intent={Intent.PRIMARY}
      icon="floppy-disk"
      rightIcon={fetching && <Spinner size={Spinner.SIZE_SMALL} />}
      disabled={fetching || !valid}
      onClick={onClickSave}
    />
  );
});

const Questionary = React.memo(() => {
  const { t } = useTranslation();
  const name = utils.useSelectorMemo('questionary.questionary.name');
  const numGroups = utils.useSelectorMemo('questionary.groups.length');
  const onClickAddGroup = utils.useDispatchCallback(questionary.addGroup);

  return (
    <Page>
      <div className={styles.header}>
        <H3>{t('questionnaire')} «{name}»</H3>

        <SaveButton />
      </div>

      <QuestionaryForm />

      <div>
        {_.range(numGroups).map((i) => <Group key={i} index={i} />)}
        <Button
          minimal
          text={`${t('add step')} (${t('screen')})`}
          intent={Intent.PRIMARY}
          icon="add"
          onClick={onClickAddGroup}
        />
      </div>

      <div className={styles.footer}>
        <SaveButton />
      </div>
    </Page>
  );
});

function init(id, copying) {
  dispatch(questionary.init(id, copying));
}

export default React.memo(() => {
  const { id, copying } = useEditState();
  const { t } = useTranslation();
  utils.useInit(init, id, copying);

  const error = utils.useSelectorMemo('questionary.error');
  const initialFetching = utils.useSelectorMemo(
    (state) => state.questionary.fetching && !state.questionary.questionary.id,
  );
  const editingQuestion = utils.useSelectorMemo((state) => !!state.questionary.editQuestion);

  if (error) {
    return <NonIdealState title={t('error')} description={error.message} icon="error" />;
  }

  if (initialFetching) {
    return <Spinner />
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Questionary />

      {editingQuestion && <QuestionEditor />}
    </DndProvider>
  );
});
