import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { H2, Intent, Button, Alert } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import TaskResult from 'app/widgets/TaskResult';
import { dispatch } from 'app/state';
import {
  getTask,
  getTaskIsFetching,
  canRestoredTasksEditor as getCanRestoredTasksEditor,
  getTaskError,
} from 'app/state/reducers';
import { deleteTask } from 'app/state/actionCreators/task';
import { taskType } from 'app/proptyping';

import styles from './TaskResultScreen.module.css';

class TaskResultScreen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmDelete: false,
    };
  }

  get id() {
    const { match } = this.props;
    return parseInt(match.params.id);
  }

  openConfirmDelete = () => this.setState({ confirmDelete: true });

  closeConfirmDelete = () => this.setState({ confirmDelete: false });

  deleteTask = async () => {
    const { history } = this.props;

    this.closeConfirmDelete();

    if (await dispatch(deleteTask(this.id))) {
      history.push('/feed', { reload: true });
    }
  }

  renderDeletePrompt() {
    const { canRestoredTasksEditor, t } = this.props;
    const { confirmDelete } = this.state;

    if (!canRestoredTasksEditor) {
      return undefined;
    }

    return (
      <Alert
        cancelButtonText={t('cancel')}
        confirmButtonText={t('delete')}
        icon="trash"
        intent={Intent.DANGER}
        isOpen={confirmDelete}
        onCancel={this.closeConfirmDelete}
        onConfirm={this.deleteTask}
      >
        <p>{t('sure to delete visit')}?</p>
      </Alert>
    );
  }

  render() {
    const { task, fetching, canRestoredTasksEditor, t, error } = this.props;
    const isDeleted = task?.status === 'ARCHIVED';
    const canShow = !fetching && !error && !isDeleted;

    return (
      <Page heightBound>
        <div className={styles.actionBar}>
          <H2>
            {t('tradepoint report')}
            {isDeleted && ` (${t('removed')})`}
          </H2>
          <div className={styles.spacer} />
          {canRestoredTasksEditor && canShow && (
            <div>
              <Button
                icon="trash"
                text={t('delete')}
                intent={Intent.DANGER}
                className={styles.actionButton}
                onClick={this.openConfirmDelete}
              />
            </div>
          )}
          {canRestoredTasksEditor && canShow && task?.type === 'merchandising' && (
            <Link to={`/tasks/${this.id}/copy`}>
              <Button
                icon="duplicate"
                text={t('copy')}
                intent={Intent.PRIMARY}
                className={styles.actionButton}
              />
            </Link>
          )}
          {canRestoredTasksEditor && canShow && (
            <Link to={`/tasks/${this.id}/edit`}>
              <Button
                icon="edit"
                text={t('edit')}
                intent={Intent.SUCCESS}
                className={styles.actionButton}
              />
            </Link>
          )}
        </div>
        <div className={styles.taskResultContainer}>
          <TaskResult id={this.id} />
        </div>
        {this.renderDeletePrompt()}
      </Page>
    );
  }
}

TaskResultScreen.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  task: taskType,
  fetching: PropTypes.bool.isRequired,
  canRestoredTasksEditor: PropTypes.bool.isRequired,
  t: PropTypes.func,
  error: PropTypes.object.isRequired,
};

TaskResultScreen.defaultProps = {
  task: null,
  t: () => {},
};

const mapStateToProps = (state) => ({
  task: getTask(state),
  fetching: getTaskIsFetching(state),
  error: getTaskError(state),
  canRestoredTasksEditor: getCanRestoredTasksEditor(state),
});

export default connect(mapStateToProps)(withTranslation()(TaskResultScreen));
