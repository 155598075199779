import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import ReportPage from 'app/widgets/ReportPage';
import Form from 'app/widgets/Form';
import FancySelect from 'app/widgets/FancySelect';
import { LocalizedDateRangeInput } from 'app/widgets/LocalizedDateInput';
import {
  AgencySelect,
  ContragentSelect,
  TradeNetworkSelect,
  UserTaskCheckQuestionSelect,
  TradepointProjectSelect,
  BranchSelect,
  UserSelect,
} from 'app/widgets/ReferenceSelect';
import { canViewAgencies } from 'app/state/reducers';
import { useSelectorMemo } from 'app/utils';
import * as api from 'app/api';

const INITIAL_FILTERS = { date__between: [moment().subtract(7, 'days').toDate(), new Date()] };

export default React.memo(() => {
  const { t } = useTranslation();
  const checkedOptions = React.useMemo(
    () => [
      { id: true, label: t('checked') },
      { id: false, label: t('not checked') },
    ],
    [t],
  );
  const showAgencyFilter = useSelectorMemo(canViewAgencies);

  return (
    <ReportPage
      title={t('visit verification report')}
      apiRoute={api.USER_TASK_CHECKS_REPORT}
      initialFilters={INITIAL_FILTERS}
    >
      {showAgencyFilter && (
        <Form.Item
          name="agency_id__eq"
          label={t('agency')}
          acceptor={AgencySelect}
          autoSelectFirst
          multi={false}
          transient
        />
      )}
      <Form.Item
        name="contragent_id__eq"
        label={t('contractor')}
        dependsOn="agency_id__eq"
        acceptor={ContragentSelect}
        autoSelectFirst
        multi={false}
      />
      <Form.Item
        name="date__between"
        label={t('visit period')}
        acceptor={LocalizedDateRangeInput}
        shortcuts="months"
      />
      <Form.Item
        name="branch_id__in"
        label={t('affiliate')}
        dependsOn="contragent_id__eq"
        acceptor={BranchSelect}
      />
      <Form.Item
        name="network_id__in"
        label={t('network')}
        dependsOn="contragent_id__eq"
        acceptor={TradeNetworkSelect}
      />
      <Form.Item
        name="project_id__in"
        label={t('project')}
        dependsOn="contragent_id__eq"
        acceptor={TradepointProjectSelect}
      />
      <Form.Item
        name="check_manager_id__in"
        label={t('checker')}
        dependsOn="contragent_id__eq"
        acceptor={UserSelect}
        placeholder={t('search by checker')}
        filters={{ roles__overlap: ['admin', 'agency_admin', 'check_manager', 'chief_of_check_manager'] }}
      />
      <Form.Item
        name="question_id__in"
        label={t('question')}
        dependsOn="contragent_id__eq network_id__in"
        acceptor={UserTaskCheckQuestionSelect}
      />
      <Form.Item
        name="check_date__between"
        label={t('visitCheckPeriod')}
        acceptor={LocalizedDateRangeInput}
        shortcuts="months"
      />
      <Form.Item
        name="checked__eq"
        label={t('status')}
        acceptor={FancySelect}
        autoSelectFirst
        multi={false}
        clearable
        data={checkedOptions}
      />
    </ReportPage>
  );
});
