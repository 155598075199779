import React from 'react';
import PropTypes from 'prop-types';
import { Button, Intent, Switch } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { useTranslation, Translation } from 'react-i18next';
import RRU from 'react-redux-utils';

import * as utils from 'app/utils';
import Grid from 'app/widgets/Grid';
import * as selectors from 'app/state/reducers';
import { AgencyFilter, ContragentFilter, RoleFilter } from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';

import styles from './TeamScreen.module.css';

export default
function Filters({
  canEdit,
  contragentId,
  onChangeContragent,
  filters,
  filtersVisible,
  setFilters,
  setFiltersVisible,
  clearFilters,
}) {
  const { t } = useTranslation();
  const hasFilters = !!(filters.roleCode || filters.name);
  const canViewAgencies = RRU.useSelector(selectors.isAdminOrAgencyAdmin);

  const reportFilters = {
    agency_id__eq: filters.agency_id__eq,
    roles__overlap: filters.roleCode ? [filters.roleCode] : undefined,
    active__eq: filters.showBlocked ? undefined : true,
  };
  const onClickDownload = RRU.useCallbackArgs(
    utils.tryCreateBackgroundExportTask,
    'static-reports',
    { type: 'USERS_TEAM_REPORT', filter: { ...reportFilters, contragent_id__eq: contragentId } },
  );
  const onClickDownloadAgency = RRU.useCallbackArgs(
    utils.tryCreateBackgroundExportTask,
    'static-reports',
    { type: 'USERS_TEAM_REPORT', filter: reportFilters },
  );

  return (
    <>
      <div className={styles.actionBar}>
        <Button
          onClick={() => { setFiltersVisible(!filtersVisible); }}
          minimal
          icon={hasFilters ? 'filter-keep' : 'filter'}
          rightIcon={filtersVisible ? 'chevron-up' : 'chevron-down'}
          text={t('filters')}
          intent={Intent.SUCCESS}
          className={styles.filtersButton}
        />
        <Button
          onClick={onClickDownload}
          minimal
          disabled={!contragentId}
          icon="download"
          text={t('download')}
          intent={Intent.SUCCESS}
          className={styles.filtersButton}
        />
        {canViewAgencies && (
          <Button
            onClick={onClickDownloadAgency}
            minimal
            disabled={!filters.agency_id__eq}
            icon="download"
            text={t('downloadByAgency')}
            intent={Intent.SUCCESS}
            className={styles.filtersButton}
          />
        )}
        {canEdit && (
          <Link to="/user/new/edit" className={styles.editButton}>
            <Button
              icon="new-person"
              text={t('create user')}
              intent={Intent.PRIMARY}
            />
          </Link>
        )}
      </div>
      <Grid.Filters
        visible={filtersVisible}
        filters={filters}
        setFilter={(key, value) => setFilters({ ...filters, [key]: value })}
        clearFilters={clearFilters}
      >
        {canViewAgencies && (
          <Grid.Filter
            acceptor={AgencyFilter}
            path="agency_id__eq"
            multi={false}
            autoSelectFirst
          />
        )}
        <Grid.Filter
          acceptor={ContragentFilter}
          path="contragentId"
          dependsOn="agency_id__eq"
          multi={false}
          autoSelectFirst
          value={contragentId}
          onChange={onChangeContragent}
        />
        <Grid.Filter
          acceptor={RoleFilter}
          path="roleCode"
          multi={false}
        />
        <Grid.Filter
          acceptor={InputFilter}
          path="name"
          name={t('searching')}
        />
        <Grid.Filter
          acceptor={Switch}
          path="showBlocked"
          className={styles.switchFilter}
          label={
            <Translation>
              {(t) => <span>{t('to show')}<br/>{t('lowercase is blocked')}</span>}
            </Translation>
          }
        />
      </Grid.Filters>
    </>
  );
}

Filters.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  contragentId: PropTypes.number,
  filters: PropTypes.shape({
    agency_id__eq: PropTypes.number,
    roleCode: PropTypes.string,
    name: PropTypes.string,
    showBlocked: PropTypes.bool,
  }).isRequired,
  onChangeContragent: PropTypes.func.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  setFilters: PropTypes.func.isRequired,
  setFiltersVisible: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Filters.defaultProps = {
  contragentId: undefined,
};
