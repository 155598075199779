import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';

import { TILE_LAYER } from 'app/utils/map';
import { tradepointAudit } from 'app/proptyping';

import styles from './merchMapRoutes.module.css';

const BLUE = '#137cbd';
const RED = '#c23030';
const GREEN = '#0f9960';
const YELLOW = '#ffc107';

const INWORK = 'INWORK';
const COMPLETED = 'COMPLETED';

const MerchRouteMap = ({ items, onClickItem }) => {
  const [completedTasks, setCompletedTasks] = useState([]);

  const filterCompleted = (arr) => {
    let onlyCompleted = [...arr];

    onlyCompleted = onlyCompleted
      .filter((item) => item.status === COMPLETED)
      .sort((a, b) => new Date(a.end_time) - new Date(b.end_time))
      .map((item) => item.id);

    setCompletedTasks(onlyCompleted);
  };

  useEffect(() => {
    if (items && items.length > 0) {
      filterCompleted(items);
    }
  }, [items]);

  const getItemData = (item) => {
    let color = RED;
    let text = '';

    if (item.status === INWORK) {
      color = YELLOW;
    } else if (item.status === COMPLETED && item.unscheduled) {
      const idx = completedTasks.indexOf(item.id) + 1;
      text = `${idx}`;
      color = BLUE;
    } else if (item.status === COMPLETED && !item.unscheduled) {
      const idx = completedTasks.indexOf(item.id) + 1;
      text = `${idx}`;
      color = GREEN;
    }

    return { color, text };
  };

  const createMarkerIcon = (item) => {
    const { color, text } = getItemData(item);
    return L.divIcon({
      className: styles.pinContainer,
      html: ReactDOMServer.renderToString(
        <div className={styles.pin} style={{ borderColor: color }}>
          <span className={(text.length > 1) ? styles.bigPinText : styles.smallPinText}>
            {text}
          </span>
          <div className={styles.pinAfter}>
            <div className={styles.pinAfterAfter} style={{ borderTopColor: color }} />
          </div>
        </div>,
      ),
    });
  };

  const mapParams = useMemo(
    () => {
      // bounds require multiple points
      if (items.length > 1) {
        return { bounds: L.latLngBounds(items.map((item) => [item.latitude, item.longitude])) };
      }

      return { zoom: 12, center: [items[0].latitude, items[0].longitude] };
    },
    [items],
  );

  return (
    <MapContainer className={styles.merchMap} {...mapParams}>
      {TILE_LAYER}
      {items.map((item) => (
        <Marker
          key={item.id}
          position={[item.latitude, item.longitude]}
          icon={createMarkerIcon(item)}
          eventHandlers={{
            click: () => onClickItem(item),
          }}
        />
      ))}
    </MapContainer>
  );
};

MerchRouteMap.propTypes = {
  items: PropTypes.arrayOf(tradepointAudit).isRequired,
  onClickItem: PropTypes.func.isRequired,
};

export default MerchRouteMap;
