import _ from 'lodash';
import * as ACTIONS from 'app/state/actions';

const INITIAL_STATE = {
  loaded: false,
  fetching: false,
  error: null,
  questionaries: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.CREATE_RESTORED_TASK_CLEAR_QUESTIONS:
      return INITIAL_STATE;
    case ACTIONS.CREATE_RESTORED_TASK_LOAD_QUESTIONS_START:
      return _.assign(state, {
        fetching: true,
        error: null,
      });
    case ACTIONS.CREATE_RESTORED_TASK_LOAD_QUESTIONS_DONE:
      return _.assign(state, {
        fetching: false,
        error: null,
        questionaries: action.payload.questionaries,
        loaded: true,
      });
    case ACTIONS.CREATE_RESTORED_TASK_LOAD_QUESTIONS_ERROR:
      return _.assign(state, {
        fetching: false,
        error: action.error,
      });
    case ACTIONS.CREATE_RESTORED_TASK_UPDATE_QUESTION: {
      const questionaryIndex = state.questionaries.findIndex(
        (q) => q.id === action.payload.questionaryId,
      );
      const { questions } = state.questionaries[questionaryIndex];
      const questionIndex = questions.findIndex((q) => q.id === action.payload.questionId);
      const question = {
        ...questions[questionIndex],
        result: {
          ...questions[questionIndex].result,
          data: action.payload.data,
        },
      };
      return _.set(state, ['questionaries', questionaryIndex, 'questions', questionIndex], question);
    }
    case ACTIONS.CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_START: {
      const questionaryIndex = state.questionaries.findIndex(
        (q) => q.id === action.payload.questionaryId,
      );
      const { questions } = state.questionaries[questionaryIndex];
      const questionIndex = questions.findIndex((q) => q.id === action.payload.questionId);
      let photos = questions[questionIndex].result.photos || [];
      const newPhoto = {
        uploading: true,
        uuid: action.payload.uuid,
      };
      if (action.payload.replaceUuid) {
        photos = photos.map((p) => (p.uuid === action.payload.replaceUuid ? newPhoto : p));
      } else {
        photos = [...photos, newPhoto];
      }
      const question = {
        ...questions[questionIndex],
        result: {
          ...questions[questionIndex].result,
          photos,
        },
      };
      return _.set(state, ['questionaries', questionaryIndex, 'questions', questionIndex], question);
    }
    case ACTIONS.CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_DONE: {
      const questionary = state.questionaries.find(q => q.id === action.payload.questionaryId);
      const question = questionary.questions.find(q => q.id === action.payload.questionId);
      const newPhoto = question.result.photos.find((p) => p.uuid === action.payload.uuid);
      newPhoto.uploading = false;
      newPhoto.key = action.payload.key;
      return state;
    }
    case ACTIONS.CREATE_RESTORED_TASK_UPLOAD_QUESTION_PHOTO_ERROR: {
      const questionary = state.questionaries.find(q => q.id === action.payload.questionaryId);
      const question = questionary.questions.find(q => q.id === action.payload.questionId);
      question.result.photos = (question.result.photos || []).filter(
        (p) => p.uuid !== action.payload.uuid,
      );
      return state;
    }
    case ACTIONS.CREATE_RESTORED_TASK_REMOVE_QUESTION_PHOTO: {
      const questionaryIndex = state.questionaries.findIndex(
        (q) => q.id === action.payload.questionaryId,
      );
      const { questions } = state.questionaries[questionaryIndex];
      const questionIndex = questions.findIndex((q) => q.id === action.payload.questionId);
      const photos = (questions[questionIndex].result.photos || []).filter(
        (p) => p.uuid !== action.payload.uuid,
      );
      const question = {
        ...questions[questionIndex],
        result: {
          ...questions[questionIndex].result,
          photos,
        },
      };
      return _.set(state, ['questionaries', questionaryIndex, 'questions', questionIndex], question);
    }
    case ACTIONS.LOGOUT_DONE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
