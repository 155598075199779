import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from 'app/widgets/Page';
import Grid from 'app/widgets/Grid';
import { Checkbox } from '@blueprintjs/core';
import {
  AgencyContragentFilter,
  TradeNetworkFilter,
  TradepointProjectFilter,
  BranchFilter
} from 'app/widgets/Filters';
import InputFilter from 'app/widgets/Filters/InputFilter';
import { EQUIPMENT } from 'app/api';

function renderActive(value) {
  const bound = !!value;
  return (
    <Checkbox
      checked={bound}
      disabled
    />
  );
}

export default React.memo(() => {
  const { t } = useTranslation();

  return (
    <Page title={t('equipment')}>
      <Grid path={EQUIPMENT}>
        <Grid.Filter acceptor={AgencyContragentFilter} path="contragent_id__eq" multi={false} autoSelectFirst />
        <Grid.Filter
          acceptor={InputFilter}
          path="tradepoint_code__fuzzy_like"
          name={t('point code')}
          placeholder={t('search by point code')}
        />
        <Grid.Filter
          acceptor={TradeNetworkFilter}
          path="network_id__in"
          dependsOn="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={BranchFilter}
          path="branch_id__in"
          dependsOn="contragent_id__eq"
        />
        <Grid.Filter
          acceptor={TradepointProjectFilter}
          path="project_id__in"
          dependsOn="contragent_id__eq"
        />

        <Grid.Column path="tradepoint_code" title={t('point code')} sortable />
        <Grid.Column path="network" title={t('network')} sortable />
        <Grid.Column path="branch" title={t('affiliate')} sortable />
        <Grid.Column path="tradepoint_address" title={t('address')} sortable />
        <Grid.Column path="asset_id" title={t('asset id')} sortable />
        <Grid.Column path="equipment_name" title={t('equipment name')} sortable />
        <Grid.Column path="equipment_type_name" title={t('equipment type')} sortable />

        <Grid.Column path="question_type_of_work" title={t('type of work')} sortable />
        <Grid.Column path="question_text" title={t('question')} sortable />
        <Grid.Column path="is_active" title={t('is active')} sortable valueRenderer={renderActive} />
      </Grid>
    </Page>
  )
});
